import './style.css';

import { Link } from 'react-router-dom';

import HorizontalLogo from '../../assets/images/svg/HorizontalLogo.svg';

const AuthNav = () => {
    return (
        <div className="w-full flex justify-between px-[1.52rem] pt-[2.53rem]">
            {/* <img src={WhiteLogo} alt="Jumbleng_logo" /> */}
            <img src={HorizontalLogo} alt="Jumbleng_logo" height={45} width={188} />
            <p className="text-[0.78125rem] font-normal text-jumbleng-gray-main md:block hidden">
                Don&apos;t have an account?
                <span className="ml-2 text-jumbleng-primary">
                    <Link to="/register">Create account</Link>
                </span>
            </p>
        </div>
    );
};

export default AuthNav;
