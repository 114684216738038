import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from '../store';

interface CustomError {
    data: {
        code: number;
        error: any;
        message: string;
        status: boolean;
    };
    status: number;
}

const { REACT_APP_BACKEND_API } = process.env;

const baseUrl = `${REACT_APP_BACKEND_API}/v1/`;

export const inviteApi = createApi({
    reducerPath: 'inviteApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).authStore.authorization.access_token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, unknown>>,
    endpoints: (builder) => ({
        acceptInvite: builder.mutation({
            query: ({ id, password }) => {
                return {
                    url: `invitations/${id}/accept`,
                    method: 'patch',
                    body: { password: password },
                };
            },
        }),
        verifyInvite: builder.query({
            query: (id) => {
                return {
                    url: `invitations/${id}`,
                    method: 'get',
                };
            },
        }),
    }),
});

export const { useVerifyInviteQuery, useAcceptInviteMutation } = inviteApi;
