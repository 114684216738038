import persistReducer from 'redux-persist/es/persistReducer';
import storageSession from 'redux-persist/lib/storage/session';

import { initialPaymentsState, initialSinglePaymentstate } from '../dtos';
import { IPayments, PaymentType } from '../interfaces';
import { types } from './PaymentActionTypes';

export const listMyPaymentsReducer = (state: IPayments = initialPaymentsState, action: any) => {
    switch (action.type) {
        case types.setPStatus:
            return { ...state, currentStatus: action.payload };
        case types.setActiveTab:
            return { ...state, activeTab: action.payload };
        case types.setPIsCustomer:
            return { ...state, iscustomer: action.payload };

        default:
            return state;
    }
};

export const listCustomerPaymentsReducer = persistReducer(
    { storage: storageSession, key: 'payment-activeTab', whitelist: ['activeTab', 'iscustomer'] },
    (state: IPayments = initialPaymentsState, action: any) => {
        switch (action.type) {
            case types.setCustomerPStatus:
                return { ...state, currentStatus: action.payload };
            case types.setActiveTab:
                return { ...state, activeTab: action.payload };
            case types.setPIsCustomer:
                return { ...state, iscustomer: action.payload };
            default:
                return state;
        }
    },
);

export const getPaymentReducer = persistReducer(
    { storage: storageSession, key: 'jumble-single-payment', whitelist: ['iscustomer'] },
    (state: PaymentType = initialSinglePaymentstate, action: any) => {
        switch (action.type) {
            case types.setPIsCustomer:
                return { ...state, iscustomer: action.payload };
            default:
                return state;
        }
    },
);

export const payment = {
    listMyPaymentsReducer,
    listCustomerPaymentsReducer,
    getPaymentReducer,
};
