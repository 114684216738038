/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useEffect } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemState,
} from 'react-accessible-accordion';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import CaretDown from '../../../assets/images/svg/caret-down.svg';
import CaretUp from '../../../assets/images/svg/caret-up.svg';
import Checked from '../../../assets/images/svg/checked.svg';
import FileIcon from '../../../assets/images/svg/file-icon.svg';
import Radioed from '../../../assets/images/svg/radioed.svg';
import Unchecked from '../../../assets/images/svg/unchecked.svg';
import Unradioed from '../../../assets/images/svg/unradioed.svg';
import { useCreateStoreMutation, useGetProductsQuery, useGetSingleProductQuery } from '../../../redux/services/storeApi';
import { RootState } from '../../../redux/store';
import { TemplateType } from '../types';
import { Modaled } from './styles';

type CreateModalFormType = {
    store_name: string;
    description: string;
    brand_logo: string;
    store_link: string;
    email: string;
    phone_number: string;
    whatsapp: string;
    facebook: string;
    instagram: string;
    twitter: string;
    preferences: {
        theme: 'light' | 'dark';
        layout: Lowercase<TemplateType>
    };
};
const schemaCheck = yup.object().shape({
    store_name: yup.string().required('Please enter store name/company name'),
    description: yup.string().required('Please enter description'),
    email: yup.string().email('Invalid email address').required('Please enter email'),
    brand_logo: yup.string().required('Please select logo'),
    preferences: yup.object().shape({
        theme: yup.string().required('Please pick a theme'),
    }),
});

const initialValues: CreateModalFormType = {
    store_name: '',
    description: '',
    brand_logo: '',
    store_link: '',
    email: '',
    phone_number: '',
    whatsapp: '',
    facebook: '',
    instagram: '',
    twitter: '',
    preferences: {
        theme: 'light',
        layout: 'retail',
    },
};

type CreateStorefrontRequestType = {
    store_name: string
    brand_logo?: string
    description?: string
    sub_domain: string

    contact_info: {
        phone_number: string[],
        email: string[]
    }

    social_link?: {
        whatsapp: string[]
        instagram: string
        facebook: string
        twitter: string
    }

    category: Lowercase<TemplateType>

    preference: {
        theme: 'light'|'dark'
        layout: Lowercase<TemplateType>
    }

    new_arrival: number

    products?: {
        catalogue_id: string
        name?: string
        price?: number
    }[]

}

const CreateModal = ({ setAddOpen, category }: { setAddOpen: any; category: Lowercase<TemplateType> }) => {

    // const [phoneNumberValue, setPhoneNumberValue] = useState('')
    // useEffect(() => {
    //     console.log(phoneNumberValue)
    // }, [phoneNumberValue]);

    const { authorization } = useSelector((state: RootState) => state.authStore);
    const [arrivals, setArrivals] = useState(1);
    const { REACT_APP_BACKEND_API } = process.env;
    const [createStore, { isLoading, isSuccess, isError, error }] = useCreateStoreMutation();
    const [selectedFile, setSelectedFile] = useState();
    const [copyAlert, setCopyAlert] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [randomUrl, setRandomUrl] = useState('');
    const [pickedLogo, setPickedLogo] = useState<any>();
    const storeName = pickedLogo?.name;
    const storeUrl =
        randomUrl.split(' ').join('').toLowerCase() || randomUrl.split('-').join('').toLowerCase() || randomUrl.split('_').join('').toLowerCase();

    const [text, setText] = useState('');

    useEffect(() => {
        setText(storeUrl);
    }, [storeUrl]);

    const copy = async () => {
        await navigator.clipboard.writeText(text);
        setCopyAlert(true);
        setTimeout(
            function () {
                setCopyAlert(false);
            }.bind(this),
            3000,
        );
    };

    const { data } = useGetProductsQuery();

    const [productsPool, setProductsPool] = useState<any[]>([]);

    useEffect(() => {
        const filteredData = data?.data?.filter((item: any) => item?.product?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase()));
        setProductsPool(filteredData);
    }, [searchTerm, data]);

    const [step, setStep] = useState(1);

    const [update, setUpdate] = useState('auto');

    const handleSelect = (values: string) => {
        selectedProducts.filter(function (e) {
            return e?.catalogue_id === values;
        }).length > 0
            ? setSelectedProducts(
                  selectedProducts.filter((object) => {
                      return object?.catalogue_id !== values;
                  }),
              )
            : setSelectedProducts((prev) => [...prev, { catalogue_id: values }]);
    };

    const onSelectFile = async (event: any, setFieldValue: any) => {
        const instance = axios.create();
        delete instance.defaults.headers.common.authorization;
        const imgData = new FormData();
        imgData.append('file', event.target.files![0]);
        imgData.append('upload_preset', 'jumbleng-test');
        await instance.post('https://api.cloudinary.com/v1_1/dduma7jcf/image/upload', imgData).then(({ data }) => {
            setFieldValue('brand_logo', data.secure_url);
        });
        if (!event.target.files || event.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }
        setSelectedFile(event.target.files[0]);
    };

    const doSubmit = async ({ brand_logo,  ...rest }: CreateModalFormType) => {

        const social_link = (rest.whatsapp || rest.facebook || rest.instagram || rest.twitter )?
        {
            whatsapp: [rest.whatsapp] ||  [],
            facebook: rest.facebook || '' ,
            instagram: rest.instagram || '' ,
            twitter: rest.twitter || '',
        } :  undefined

        const storeData:CreateStorefrontRequestType = {
            store_name: rest.store_name,
            brand_logo: brand_logo,
            category: category,
            description: rest.description,
            sub_domain:
                rest.store_name.split(' ').join('').toLowerCase() ||
                rest.store_name.split('-').join('').toLowerCase() ||
                rest.store_name.split('_').join('').toLowerCase(),
            contact_info: {
                email: [rest.email],
                phone_number: [rest.phone_number],
            },
            preference: {
                layout: category,
                theme: rest.preferences.theme,
            },
            social_link: social_link,
            products: selectedProducts,
            new_arrival: arrivals,
        };
        await createStore(storeData);
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Store Successfully Created');
            setAddOpen(false);
            window.location.reload();
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message?.replaceAll('_', ' ')?.replaceAll(':', ' '));
        }
    }, [isSuccess, isError, error, setAddOpen]);

    return (
        <Modaled>
            <div className="darkBG" onClick={() => setAddOpen(false)} />
            <div className="centered">
                <Formik
                    validationSchema={schemaCheck}
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={(values) => {
                        doSubmit(values);
                    }}
                    validateOnMount
                >
                    {({ errors, touched, submitForm, handleChange, handleBlur, values, setFieldValue }) => (
                        <Form>
                            <div className="modal">
                                <div className="modalHeader">
                                    <div className={step === 1 ? 'step__id' : 'step__id__inactive'}>
                                        <p>
                                            <span>1</span> Basic Information
                                        </p>
                                    </div>
                                    <div className="divider" />
                                    <div className={step === 2 ? 'step__id' : 'step__id__inactive'}>
                                        <p>
                                            <span>2</span> Customisation
                                        </p>
                                    </div>
                                    <div className={step >= 2 ? 'divider' : 'divider__inactive'} />
                                    <div className={step === 3 ? 'step__id' : 'step__id__inactive'}>
                                        <p>
                                            <span>3</span> Products
                                        </p>
                                    </div>
                                    <div className={step >= 3 ? 'divider' : 'divider__inactive'} />
                                </div>
                                {step === 1 && (
                                    <>
                                        <div className="section__intro">
                                            <h1>Basic Information</h1>
                                            <p>The following Information would help in setting up your storefront</p>
                                        </div>
                                        <Accordion allowZeroExpanded className="accordion__container">
                                            <AccordionItem className="option__container">
                                                <AccordionItemState>
                                                    {({ expanded }) =>
                                                        !expanded ? (
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton className="option__trigger">
                                                                    <p>General</p>

                                                                    <img src={CaretUp} alt="" width="9px" />
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                        ) : (
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton className="option__trigger">
                                                                    <p>General</p>

                                                                    <img src={CaretDown} alt="" width="9px" />
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                        )
                                                    }
                                                </AccordionItemState>
                                                <AccordionItemPanel className="option__content">
                                                    <div className="form__container">
                                                        <div className="form__input">
                                                            <div className="field__label">
                                                                <p>
                                                                    Storefront/Company Name<sup>*</sup>
                                                                </p>
                                                                {/* <p>{`15`}</p> */}
                                                            </div>
                                                            <input
                                                                id="store_name"
                                                                name="store_name"
                                                                placeholder="Brand Name"
                                                                type="text"
                                                                onChange={handleChange}
                                                                onBlur={(e) => {
                                                                    handleBlur;
                                                                    setRandomUrl(e.target.value);
                                                                }}
                                                                value={values.store_name}
                                                            />
                                                            <p className="sub__label">Give your storefront a short and clear name</p>
                                                            {errors.store_name && touched.store_name && (
                                                                <p className="errored">{errors.store_name}</p>
                                                            )}
                                                        </div>
                                                        <div className="form__input">
                                                            <div className="field__label">
                                                                <p>
                                                                    Description<sup>*</sup>
                                                                </p>
                                                                {/* <p>{`122`}</p> */}
                                                            </div>
                                                            <Field
                                                                name="description"
                                                                component="textarea"
                                                                placeholder="Brand Description"
                                                                onBlur={handleBlur}
                                                            />
                                                            {errors.description && touched.description && (
                                                                <p className="errored">{errors.description}</p>
                                                            )}
                                                        </div>
                                                        <div className="form__input">
                                                            <div className="field__label">
                                                                <p>
                                                                    Brand Logo<sup>*</sup>
                                                                </p>
                                                            </div>
                                                            <div className="upload__container">
                                                                <label htmlFor="upload" className="file__label">
                                                                    <input
                                                                        id="upload"
                                                                        name="brand_logo"
                                                                        type="file"
                                                                        accept="image/*"
                                                                        onChange={(event) => {
                                                                            setFieldValue('brand_logo', event?.currentTarget.files![0]);
                                                                            onSelectFile(event, setFieldValue);
                                                                            setPickedLogo(event?.currentTarget.files![0]);
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                    <img src={FileIcon} alt="" />

                                                                    <p>
                                                                        {storeName?.length ? storeName : 'Drop your images here or click to browse'}
                                                                    </p>
                                                                </label>
                                                            </div>

                                                            {errors.brand_logo && touched.brand_logo && (
                                                                <p className="errored">{errors.brand_logo}</p>
                                                            )}
                                                        </div>
                                                        {storeUrl.length && (
                                                            <div className="form__input">
                                                                <div className="field__label">
                                                                    <p>Store Link</p>
                                                                </div>
                                                                <div className="link__container copy__container">
                                                                    <p>{storeUrl}.jumbleng.store</p>
                                                                    <p className="link__copy" onClick={copy}>
                                                                        {copyAlert ? 'copied' : 'copy'}
                                                                    </p>
                                                                </div>

                                                                {errors.store_link && touched.store_link && (
                                                                    <p className="errored">{errors.store_link}</p>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem className="option__container">
                                                <AccordionItemState>
                                                    {({ expanded }) =>
                                                        !expanded ? (
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton className="option__trigger">
                                                                    <p>Contact Information</p>

                                                                    <img src={CaretUp} alt="" width="9px" />
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                        ) : (
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton className="option__trigger">
                                                                    <p>Contact Information</p>

                                                                    <img src={CaretDown} alt="" width="9px" />
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                        )
                                                    }
                                                </AccordionItemState>
                                                <AccordionItemPanel className="option__content">
                                                    <div className="form__container">
                                                        <div className="form__input">
                                                            <div className="field__label">
                                                                <p>
                                                                    Email<sup>*</sup>
                                                                </p>
                                                            </div>
                                                            <input
                                                                id="email"
                                                                name="email"
                                                                placeholder="Email"
                                                                type="email"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.email}
                                                            />
                                                            {errors.email && touched.email && <p className="errored">{errors.email}</p>}
                                                        </div>
                                                        <div className="form__input">
                                                            <div className="field__label">
                                                                <p>
                                                                    Phone Number<sup>*</sup>
                                                                </p>
                                                            </div>


                                                            <Field name="phone_number" />
                                                            {errors.phone_number && touched.phone_number && (
                                                                <p className="errored">{errors.phone_number}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem className="option__container">
                                                <AccordionItemState>
                                                    {({ expanded }) =>
                                                        !expanded ? (
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton className="option__trigger">
                                                                    <p>Social Links</p>

                                                                    <img src={CaretUp} alt="" width="9px" />
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                        ) : (
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton className="option__trigger">
                                                                    <p>Social Links</p>

                                                                    <img src={CaretDown} alt="" width="9px" />
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                        )
                                                    }
                                                </AccordionItemState>
                                                <AccordionItemPanel className="option__content" >
                                                    <div className="form__container">
                                                        <div className="form__input">
                                                            <div className="field__label">
                                                                <p>Whatsapp</p>
                                                            </div>
                                                            <Field name="whatsapp" placeholder="Whatsapp Number" onBlur={handleBlur} />
                                                            {errors?.whatsapp && touched?.whatsapp && <p className="errored">{errors.whatsapp}</p>}
                                                        </div>
                                                        <div className="form__input">
                                                            <div className="field__label">
                                                                <p>Facebook</p>
                                                            </div>
                                                            <Field name="facebook" placeholder="Facebook Url" onBlur={handleBlur} />
                                                            {errors.facebook && touched.facebook && <p className="errored">{errors.facebook}</p>}
                                                        </div>
                                                        <div className="form__input">
                                                            <div className="field__label">
                                                                <p>Instagram</p>
                                                            </div>
                                                            <Field name="instagram" placeholder="Instagram Url" onBlur={handleBlur} />
                                                            {errors.instagram && touched.instagram && <p className="errored">{errors.instagram}</p>}
                                                        </div>
                                                        <div className="form__input">
                                                            <div className="field__label">
                                                                <p>Twitter</p>
                                                            </div>
                                                            <Field name="twitter" placeholder="Twitter Url" onBlur={handleBlur} />
                                                            {errors.twitter && touched.twitter && <p className="errored">{errors.twitter}</p>}
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </>
                                )}
                                {step === 2 && (
                                    <>
                                        <div className="section__intro">
                                            <h1>Customization</h1>
                                            <p>Customize your storefront with your desired theme, typeface and colour scheme</p>
                                        </div>
                                        <Accordion allowZeroExpanded className="accordion__container">
                                            <AccordionItem className="option__container">
                                                <AccordionItemState>
                                                    {({ expanded }) =>
                                                        !expanded ? (
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton className="option__trigger">
                                                                    <p>Page Theme</p>

                                                                    <img src={CaretUp} alt="" width="9px" />
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                        ) : (
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton className="option__trigger">
                                                                    <p>Page Theme</p>

                                                                    <img src={CaretDown} alt="" width="9px" />
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                        )
                                                    }
                                                </AccordionItemState>
                                                <AccordionItemPanel className="option__content">
                                                    <div role="group" className="radio__grid" aria-labelledby="my-radio-group">
                                                        <label>
                                                            <Field type="radio" name="preferences.theme" value="dark" />
                                                            Dark Mode
                                                        </label>
                                                        <label>
                                                            <Field type="radio" name="preferences.theme" value="light" />
                                                            Light Mode
                                                        </label>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                            <AccordionItem className="option__container">
                                                <AccordionItemState>
                                                    {({ expanded }) =>
                                                        !expanded ? (
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton className="option__trigger">
                                                                    <p>Page Update Settings</p>

                                                                    <img src={CaretUp} alt="" width="9px" />
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                        ) : (
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton className="option__trigger">
                                                                    <p>Page Update Settings</p>

                                                                    <img src={CaretDown} alt="" width="9px" />
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                        )
                                                    }
                                                </AccordionItemState>
                                                <AccordionItemPanel className="option__content">
                                                    <div className="panel__header">
                                                        <p>New Arrivals</p>
                                                    </div>
                                                    <div className="panel__selection">
                                                        <div
                                                            className={update === 'auto' ? 'selected' : 'unselected'}
                                                            onClick={() => {
                                                                setUpdate('auto');
                                                                setArrivals(259200);
                                                            }}
                                                        >
                                                            <div>
                                                                <h3>Automatic</h3>
                                                                <p>Auto Populate Page at selected interval</p>
                                                            </div>
                                                            {update === 'auto' ? <img src={Radioed} alt="" /> : <img src={Unradioed} alt="" />}
                                                        </div>

                                                        <div
                                                            className={update === 'manual' ? 'selected' : 'unselected'}
                                                            onClick={() => {
                                                                setUpdate('manual');
                                                                setArrivals(1);
                                                            }}
                                                        >
                                                            <div>
                                                                <h3>Manual</h3>
                                                                <p>Populate Page Individually</p>
                                                            </div>
                                                            {update === 'manual' ? <img src={Radioed} alt="" /> : <img src={Unradioed} alt="" />}
                                                        </div>
                                                    </div>
                                                    {update === 'auto' && (
                                                        <div className="arrivals__selector">
                                                            <label htmlFor="standard-select">Duration Select</label>
                                                            <div className="select">
                                                                <select id="standard-select" onChange={(e) => setArrivals(Number(e.target.value))}>
                                                                    <option value="259200">3 Days</option>
                                                                    <option value="604800">1 Week</option>
                                                                    <option value="1209600">2 Weeks</option>
                                                                    <option value="1814400">3 Weeks</option>
                                                                    <option value="2678400">1 Month</option>
                                                                </select>
                                                                <span className="focus"></span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </>
                                )}
                                {step === 3 && (
                                    <>
                                        <div className="section__intro">
                                            <h1>Products</h1>
                                            <p>Add Products from your Jumble B2B to your storefront</p>
                                        </div>
                                        <div className="option__container">
                                            <div className="products__indicator">
                                                <p>{selectedProducts.length} Selected</p>
                                                <input
                                                    type="text"
                                                    placeholder="Search products"
                                                    onChange={(text) => setSearchTerm(text?.target?.value)}
                                                />
                                            </div>
                                            <div className="produce__list">
                                                <div className="list__header">
                                                    <div className="name__cta">
                                                        <h3>Name</h3>
                                                    </div>
                                                    <h3>Price</h3>
                                                </div>
                                                {productsPool?.map((product: any) => (
                                                    <div className="list__item" key={product.id} onClick={() => handleSelect(product?.id)}>
                                                        <div className="name__cta">
                                                            {selectedProducts.filter(function (e) {
                                                                return e?.catalogue_id === product?.id;
                                                            }).length > 0 ? (
                                                                <img src={Checked} alt="" />
                                                            ) : (
                                                                <img src={Unchecked} alt="" />
                                                            )}
                                                            <div className="product__img">
                                                                <img src={product?.product?.picture} alt="" />
                                                            </div>
                                                            <p>{product?.product?.name}</p>
                                                        </div>
                                                        <p>NGN {product?.pricing?.price}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="modal__cta">
                                    {step > 1 ? (
                                        <div className="modal__cancel" onClick={() => setStep(step - 1)}>
                                            Back
                                        </div>
                                    ) : (
                                        <div className="modal__cancel" onClick={() => setAddOpen(false)}>
                                            Cancel
                                        </div>
                                    )}

                                    <div className="cta__grid">
                                        {step === 2 && <p onClick={() => setStep(step + 1)}>Skip for Later</p>}
                                        {step < 3 ? (
                                            <div className="modal__continue" onClick={() => setStep(step + 1)}>
                                                Continue
                                            </div>
                                        ) : isLoading ? (
                                            <div className="loader__container">
                                                <div className="loader" />
                                            </div>
                                        ) : (
                                            <div className="modal__continue" onClick={submitForm}>
                                                Create Store
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modaled>
    );
};

export default CreateModal;
