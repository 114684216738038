import { FC } from 'react';

import Spinner from '../UI/Spinner/Spinner';

type IProps = { loading: boolean; onClick: any; content: any };

const ButtonLoad: FC<IProps> = ({ loading, onClick, content }) => {
    return (
        <div
            className="flex rounded-xl text-white cursor-pointer items-center justify-center bg-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[12rem]"
            onClick={onClick}
        >
            {loading ? <Spinner /> : <div className="font-normal text-[1rem] text-white ">{content}</div>}
        </div>
    );
};

export default ButtonLoad;
