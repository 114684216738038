import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

import { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import LockIcon from './assets/images/svg/lock.svg';
import AuthRoute from './HOC/AuthRoute';
import Wrapper from './HOC/Wrapper';
import useConnectSocket from './hooks/useWebSocket';
import { ForgotPassword, PasswordResetComplete, PasswordResetConfirm, PasswordResetDone } from './pages/auth/passwordReset';
import Registration from './pages/auth/Registration';
import Verify from './pages/auth/Registration/verification/Verification';
import Signin from './pages/auth/signin/Signin';
import Dashboard from './pages/dashboard/Dashboard';
import Feed from './pages/feed/Feed';
import ExpandedInvoice from './pages/invoice/ExpandedInvoice/ExpandedInvoice';
import Invoice from './pages/invoice/Invoice';
import Notification from './pages/Notification/Notification';
import PurchaseOrder from './pages/order/ExpandedOrder/ExpandedOrder';
import Order from './pages/order/Order';
import ExpandedPayment from './pages/payment/ExpandedPayment/ExpandedPayment';
import Payment from './pages/payment/payment';
import Product from './pages/product/Product';
import RequestPage from './pages/requests/RequestPage';
import Settings from './pages/settings/Settings';
import Verification from './pages/settings/Verification';
import StorePreview from './pages/store-preview';
import StoreFront from './pages/storefront';
import ExpandedVendorCatalogue from './pages/vendorCatalogue/ExpandedVendorCatalogue';
import VendorCatalogue from './pages/vendorCatalogue/VendorCatalogue';
import ExpandedCustomer from './pages/vendorlist/Customers/ExpandedCustomer';
import GroupVendorList from './pages/vendorlist/Groups/ExpandedGroup';
import VendorList from './pages/vendorlist/VendorList';
import { retrieveNotification } from './redux/slices/notifications';
import { persistor } from './redux/store';
import { SCOPES } from './utils/permission';
const PersistGateFixed = PersistGate as unknown as React.FC<PropsWithChildren<{ loading: any; persistor: any }>>;

function App() {
    const dispatch = useDispatch();
    const {} = useConnectSocket();

    useEffect(() => {
        dispatch(retrieveNotification({ page: 1, limit: 10 }));
    }, [dispatch]);

    return (
        <PersistGateFixed loading={null} persistor={persistor}>
            <Routes>
                <Route element={<Signin />} path="/" />
                <Route element={<Registration />} path="/register" />
                <Route
                    element={
                        <AuthRoute
                            scopes={[
                                SCOPES.can_view_company_order_analytics,
                                SCOPES.can_view_company_sales_analytics,
                                SCOPES.can_view_company_revenue_analytics,
                                SCOPES.can_view_company_purchase_analytics,
                            ]}
                        />
                    }
                >
                    <Route element={<Dashboard />} path="/dashboard" />
                </Route>
                <Route element={<AuthRoute scopes={[SCOPES.can_view_all_feed, SCOPES.can_view_connected_vendors_feed]} />}>
                    <Route element={<Feed />} path="/feed" />
                </Route>
                <Route element={<AuthRoute scopes={[SCOPES.can_manage_connections]} />}>
                    <Route element={<RequestPage />} path="/requestsent" />
                </Route>
                <Route element={<VendorCatalogue />} path="/vendorcatalogue/:id/catalogues" />
                <Route element={<GroupVendorList />} path="/vendorlist/group/:id" />
                <Route element={<ExpandedCustomer />} path="/vendorlist/customer/:id" />
                <Route element={<Invoice />} path="/invoice" />
                <Route element={<Settings />} path="/settings" />
                <Route element={<Verification />} path="/verification" />
                <Route element={<Verify />} path="/verify-company" />
                <Route element={<ExpandedInvoice />} path="/invoice/:id" />
                <Route element={<ExpandedVendorCatalogue />} path="/expandedvendor" />
                <Route element={<Payment />} path="/payment" />
                <Route element={<Unauthorized />} path="/unauthorized" />
                <Route element={<ExpandedPayment />} path="/payment/:id" />
                <Route element={<VendorList />} path="/vendorlist" />
                <Route element={<AuthRoute scopes={[SCOPES.can_manage_storefront]} />}>
                    <Route element={<StoreFront />} path="/storefront" />
                </Route>
                <Route element={<Notification />} path="/notifications" />
                <Route element={<StorePreview />} path="/storepreview" />
                <Route element={<Product />} path="/product" />
                <Route element={<AuthRoute scopes={[SCOPES.can_manage_company_orders, SCOPES.can_manage_customer_orders]} />}>
                    <Route element={<Order />} path="/order" />
                    <Route element={<PurchaseOrder />} path="/order/:id" />
                </Route>
                {/* Reset Password Routes */}
                <Route element={<ForgotPassword />} path="/forgot-password" />
                <Route element={<PasswordResetConfirm />} path="/reset-password-confirm/:token" />
                <Route element={<PasswordResetDone />} path="/reset-password-done" />
                <Route element={<PasswordResetComplete />} path="/reset-password-complete" />
                <Route path={'*'} element={<NotFound />} />
            </Routes>
            <ToastContainer />
        </PersistGateFixed>
    );
}

//all these are temporary
const NotFound = () => {
    return (
        <>
            {' '}
            <div>You&apos;ve entered a black hole, find your way out</div>
        </>
    );
};

export const Unauthorized = () => {
    return (
        <Wrapper>
            <div className="flex items-center justify-center flex-col h-[500px]">
                <div>
                    <div className="grid justify-center">
                        <img src={LockIcon} alt="lockicon" />
                    </div>
                    <p className="text-center">Unauthorized access</p>
                    <p className="text-center">Make use of the appropriate tabs available to this user</p>
                </div>
            </div>
        </Wrapper>
    );
};

export default App;
