import axios from 'axios';

const { REACT_APP_BACKEND_API } = process.env;

const getCreditDue = (): Promise<any> => {
    return axios.get(`${REACT_APP_BACKEND_API}/v1/payments/due`);
};

const SaleService = {
    getCreditDue,
};

export default SaleService;
