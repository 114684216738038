import { ErrorMessage, Field, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import cancel from '../../../assets/images/svg/cancelcheck.svg';
import check from '../../../assets/images/svg/check2.svg';
import Button from '../../../components/Button';
import { closeModal, openModal } from '../../../redux/modal/modalRedux';
import { useSubmitComplianceMutation } from '../../../redux/services/complianceApi';

const Review = ({ setStep, validation }: any) => {
    const [submitCompliance, { isLoading, isSuccess, isError, error }] = useSubmitComplianceMutation();
    const [checkedState, setCheckedState] = useState<boolean>(false);

    const dispatch = useDispatch();

    const handleOnSubmit = () => {
        submitCompliance({});
    };

    const handleChecked = () => {
        setCheckedState((prev) => !prev);
    };

    useEffect(() => {
        if (isLoading) {
            dispatch(openModal());
        }
        if (isSuccess) {
            setStep(1);
            toast.success('Business Info saved');
            dispatch(closeModal());
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
            dispatch(closeModal());
        }
    }, [isLoading, isSuccess, isError, error, dispatch, setStep]);
    return (
        <div className="md:w-[50%] w-full">
            <div className="flex items-start">
                <Formik onSubmit={handleOnSubmit} initialValues={{}} validationSchema={''}>
                    {({ handleSubmit }) => (
                        <div className="w-full flex flex-col gap-6">
                            <div className="flex gap-8">
                                {validation?.business_info.is_valid ? (
                                    <img src={check} alt="profile_image" />
                                ) : (
                                    <img src={cancel} alt="profile_image" />
                                )}
                                <div className="w-[400px] bg-white border border-jumbleng-gray text-jumbleng-gray pl-5 py-7 font-bold">
                                    Company Information
                                </div>
                            </div>
                            <div className="flex gap-8">
                                {validation?.owner_info.is_valid ? <img src={check} alt="profile_image" /> : <img src={cancel} alt="profile_image" />}
                                <div className="w-[400px] bg-white border border-jumbleng-gray text-jumbleng-gray pl-5 py-7 font-bold">
                                    Owners Information
                                </div>
                            </div>
                            <div className="flex gap-8">
                                {validation?.business_document_info.is_valid ? (
                                    <img src={check} alt="profile_image" />
                                ) : (
                                    <img src={cancel} alt="profile_image" />
                                )}
                                <div className="w-[400px] bg-white border border-jumbleng-gray text-jumbleng-gray pl-5 py-7 font-bold">
                                    Documentation{' '}
                                </div>
                            </div>
                            <div>
                                <div className="w-[32.7rem] mt-3 ">
                                    <div className="flex items-center">
                                        <Field
                                            type="checkbox"
                                            onChange={handleChecked}
                                            checked={checkedState || false}
                                            name={'confirm'}
                                            className={' placeholder:text-[0.78125rem] h-[15px] w-[15px]  focus:outline-none mr-2'}
                                        />
                                        <p className="text-jumbleng-gray">I confirm that the information provided are accurate and truthful.</p>
                                    </div>
                                    <ErrorMessage
                                        name={'main'}
                                        render={(msg) => (
                                            <div className="text-[0.7812rem] text-red-600 text-left font-normal fon text-jumbleng-gray-main">
                                                {msg}
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <Button
                                type={'submit'}
                                onClick={handleSubmit}
                                disabled={
                                    !validation?.owner_info.is_valid ||
                                    !validation?.business_info.is_valid ||
                                    !validation?.business_document_info.is_valid ||
                                    !checkedState
                                }
                                loading={false}
                                message={`Submit Application`}
                                className={`px-[1.56rem] py-[0.78125rem] ${
                                    !validation?.owner_info.is_valid ||
                                    !validation?.business_info.is_valid ||
                                    !validation?.business_document_info.is_valid ||
                                    !checkedState
                                        ? 'bg-jumbleng-primary-light text-white md:py-[0.78125rem]  py-[0.45rem] h-[56px] max-w-[200px]'
                                        : ' bg-[#2F2E54] text-white md:py-[0.78125rem]  py-[0.45rem] h-[56px] max-w-[200px]'
                                }  border `}
                            ></Button>
                        </div>
                    )}
                </Formik>{' '}
            </div>
        </div>
    );
};

export default Review;
