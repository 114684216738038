import React from 'react';

import EmptyRequest from '../../assets/images/svg/invoiceBrown.svg';

interface Iprops {
    content: string;
    invoice?: boolean;
    payment?: boolean;
}

const EmptyContent: React.FC<Iprops> = ({ content, invoice, payment }) => {
    return (
        <div className="mt-8">
            <div className="grid justify-center">
                <img src={EmptyRequest} alt="empty" />
            </div>
            <div className="grid justify-center mt-4 mb-4">
                <div className={`grid justify-center mt-4 text-center ${payment ? 'md:w-[500px]' : 'md:376px'}`}>
                    <p className="grid justify-center  text-[#131221] font-semibold text-[28px]">
                        {content === 'Catalogue'
                            ? `Vendor's ${content} is Empty`
                            : content === 'Invoice'
                            ? 'Invoice is not available'
                            : content === 'proof'
                            ? 'No proof of payment available'
                            : content === 'Archive'
                            ? 'Your Archive is Empty'
                            : content === 'Payment'
                            ? 'Receipt is not available'
                            : payment
                            ? `You have no ${content} payment`
                            : invoice
                            ? `No ${content} invoice available`
                            : `You have no ${content} order`}{' '}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EmptyContent;
