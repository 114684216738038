import { Link } from 'react-router-dom';

import Frame from '../../../../assets/images/svg/frame.svg';
import PermissionsGate from '../../../../HOC/PermissionGate';
import { SCOPES } from '../../../../utils/permission';

const EmptyProducts = () => {
    return (
        <div className="p-2 pt-0 mt-12">
            <div className="w-auto text-center md:mx-auto  mr-2">
                <img src={Frame} alt="frame" className="mx-auto" />

                <h5 className="text-center font-semibold mb-6 text-[28px] text-jumbleng-gray">You have no products.</h5>

                <p className="font-normal text-base text-jumbleng-gray mb-[60px]">
                    You can't add discount to any product yet, add products to apply discount.
                </p>
                <PermissionsGate scopes={[SCOPES.can_create_products]}>
                    <Link
                        to="/product"
                        className="md:px-[32.5px] md:py-4 p-2 border border-jumbleng-primary rounded-lg text-white bg-jumbleng-primary"
                    >
                        Create product
                    </Link>
                </PermissionsGate>
            </div>
        </div>
    );
};

export default EmptyProducts;
