import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import NotFound from '../../../assets/images/png/no-image.jpg';
import EmptyRequest from '../../../assets/images/svg/norequest.svg';
import Loading from '../../../components/UI/Spinner/Loading';
import PermissionsGate from '../../../HOC/PermissionGate';
import { useApproveRequestMutation, useDeclineRequestMutation, useGetReceivedRequestsQuery } from '../../../redux/services/supplyChainApi';
import { SCOPES } from '../../../utils/permission';

const Requests = () => {
    const [approveRequest, { isLoading, isSuccess, isError, error }] = useApproveRequestMutation();
    const [declineRequest, { isLoading: loading, isSuccess: success, isError: iserror, error: err }] = useDeclineRequestMutation();
    const { data, isLoading: loader } = useGetReceivedRequestsQuery();

    const handleApprove = (id: string) => {
        approveRequest(id);
    };

    const handleDecline = (id: string) => {
        declineRequest(id);
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Request accepted successfully');
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
        }
    }, [error, isError, isLoading, isSuccess]);

    useEffect(() => {
        if (success) {
            toast.success('Request declined successfully');
        }
        if (iserror && err && 'status' in err) {
            toast.error(err?.data?.message);
        }
    }, [err, iserror, loading, success]);

    return (
        <div className="w-full">
            {loader ? (
                <Loading />
            ) : data.data.length === 0 ? (
                <div data-testid="empty-message">
                    <div className="grid justify-center mt-4">
                        <img src={EmptyRequest} alt="empty" />
                    </div>
                    <div className="grid justify-center mt-4">
                        <p className="text-[28px]">No Request Received</p>
                    </div>
                    <div style={{ width: '458px' }} className="grid justify-center mt-4 text-center">
                        <p>if you have not received a request yet, visit the feed or search to find a vendor to make a request</p>
                    </div>
                    <div>
                        <PermissionsGate scopes={[SCOPES.can_view_all_feed, SCOPES.can_view_connected_vendors_feed]}>
                            <Link to="/feed">
                                <div className="grid justify-center mt-4 cursor-pointer">
                                    <div style={{ backgroundColor: '#5E5BA7', borderRadius: '12px' }} className="px-[50px] py-[16px]">
                                        <p className="text-white">Visit Feed</p>
                                    </div>
                                </div>
                            </Link>
                        </PermissionsGate>
                    </div>
                </div>
            ) : (
                <>
                    {' '}
                    <div className="w-full flex justify-between text-[16px] px-4 cursor-pointer ">
                        <p className="text-jumbleng-gray">{data.data?.length} new requests</p>
                    </div>
                    {data.data.map((item: any) => (
                        <div key={item.id} data-testid={'received-requests'}>
                            <div className="flex flex-col gap-4 mt-4">
                                <div className="flex items-center justify-between w-full">
                                    <div className="flex gap-2 items-center w-[10rem] ">
                                        <div className="flex gap-2 items-center rounded-full min-w-[3.2rem]">
                                            <img
                                                src={item.requesting_company.logo ? item.requesting_company.logo : NotFound}
                                                alt="avatar"
                                                className="h-[3.2rem] w-[3.2rem] object-contain"
                                            />
                                        </div>
                                        <div className="max-w-[6.8rem] flex flex-col">
                                            <p className="font-bold w-full break-words">{item.requesting_company.name}</p>
                                            <p>{item.requesting_company.industry_type}</p>
                                        </div>
                                    </div>
                                    <div className="flex gap-2" data-testid="decline-button">
                                        <div
                                            onClick={() => handleDecline(item.id)}
                                            className="flex rounded-xl cursor-pointer items-center justify-center  border border-jumbleng-red text-jumbleng-red  px-[1.1rem] py-[0.7rem] min-w-[7rem]"
                                        >
                                            <div className="font-normal text-[0.8rem] ">
                                                <i className="fa fa-xmark mr-2"></i>Decline
                                            </div>
                                        </div>
                                        <div
                                            onClick={() => handleApprove(item.id)}
                                            data-testid="accept-button"
                                            className="flex rounded-xl cursor-pointer text-white  items-center justify-center bg-jumbleng-primary  px-[1.1rem] py-[0.7rem] w-[7rem]"
                                        >
                                            <div className="font-normal text-[0.8rem]">
                                                <i className="fa fa-user mr-2 text-white"></i>Accept
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default Requests;
