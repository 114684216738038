import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

enum Eaction {
    SetMyCatalogueData = '[SetMyCatalogueData] action',
    SetMyCatalogueTotalPages = '[SetMyCatalogueTotalPages] action',
    SetMyCatalogueRecordsPerPage = '[SetMyCatalogueRecordsPerPage] action',
    SetMyCatalogueCurrentPage = '[SetMyCatalogueCurrentPage] action',
    SetMyCataloguePages = '[SetMyCataloguePages] action',
    SetMyCatalogueLoader = '[SetMyCatalogueLoader] action',
    SetCreateProductField = '[SetProductField] action',
    SetEditProductField = '[SetEditProductField] action',
    UpdateCatalogueList = '[UpdateCatalogueList] action',
    SetSelectedPublishProductId = '[SetSelectedPublishProductId] action',
    SetSelectedPublishProductField = '[SetSelectedPublishProductField] action',
    setNextSKU = 'SET_SKU',
}

//interface
export interface Iaction {
    type: Eaction;
    payload?: any;
}

export type productType = {
    id: string;
    name: string;
    description: string;
    company_id: string;
    bar_code: number;
    sku: string;
    picture: string;
    certificates?: string;
    uom: string;
    pack_size: string;
    quantity: number;
    vatable: boolean;
    unit_price: number;
    vat: string;
    discount: string;
    product_status: string;
    created_at: Date;
    updated_at: Date;
    deleted_at?: Date;
};

export type productFieldType = {
    bar_code: string;
    sku: string;
    name: string;
    description: string;
    quantity: string;
    uom: string;
    unit_price: string;
    picture?: any;
    product_category: '';
    weight: '';
    min_order: '';
    vat: '';
    discount: '';
    max_order: '';
    sub_uom: string;
};

export type selectedPublishProductFieldType = {
    bar_code: string;
    sku: string;
    name: string;
    description: string;
    quantity: string;
    uom: string;
    unit_price: string;
    picture?: any;
    product_category: '';
    weight: '';
    min_order: '';
    max_order: '';
    sub_uom: string;
    vat: '';
    discount: '';
};

export interface IProductInterface {
    nextSKU: any;
    selectedPublishProductId: string;
    selectedPublishProductField: selectedPublishProductFieldType;
    my_catalogue: myCatalogueType;
    product_field: productFieldType;
}

export type myCatalogueType = {
    data: Array<myCatalogueDataType>;
    total_pages: number;
    records_per_page: number;
    current_page: number;
    pages: number[];
    loader: boolean;
};

export type catalogueProductType = {
    id: string;
    created_at: Date;
    updated_at: Date;
    product_category: string;
    company_id: string;
    images?: string[];
    bar_code: string;
    sku: string;
    name: string;
    description: string;
    quantity: string;
    product_status: string;
    uom: string;
    vat: number;
    discount: number;
    unit_price: string;
    picture: any;
    vat_percent: number;
};

export type myCatalogueDataType = {
    id: string;
    created_at: Date;
    updated_at: Date;
    product_id: string;
    company_id: string;
    qty: number;
    published: boolean;
    created_by: string;
    product: catalogueProductType;
    can_view?: boolean;
    can_order?: boolean;
};
export type myCatalogueDataResponse = {
    catalogue: Array<myCatalogueDataType>;
    isPrivate: boolean;
};

export const initialProductState: IProductInterface = {
    nextSKU: {},
    selectedPublishProductId: '',
    selectedPublishProductField: {
        bar_code: '',
        sku: '',
        name: '',
        description: '',
        quantity: '',
        uom: '',
        unit_price: '',
        picture: '',
        product_category: '',
        weight: '',
        min_order: '',
        max_order: '',
        sub_uom: '',
        vat: '',
        discount: '',
    },
    my_catalogue: {
        data: [],
        total_pages: 0,
        records_per_page: 12,
        current_page: 1,
        pages: [],
        loader: true,
    },
    product_field: {
        bar_code: '',
        sku: '',
        name: '',
        description: '',
        quantity: '',
        uom: '',
        vat: '',
        discount: '',
        unit_price: '',
        picture: '',
        product_category: '',
        weight: '',
        min_order: '',
        max_order: '',
        sub_uom: '',
    },
};

export const ProductReducer = persistReducer(
    {
        storage,
        key: 'jumble-product',
    },
    (state: IProductInterface = initialProductState, action: Iaction) => {
        switch (action.type) {
            case Eaction.SetSelectedPublishProductField:
                return { ...state, selectedPublishProductField: action.payload };
            case Eaction.SetSelectedPublishProductId:
                return { ...state, selectedPublishProductId: action.payload };
            case Eaction.UpdateCatalogueList:
                return {
                    ...state,
                    my_catalogue: {
                        ...state.my_catalogue,
                        data: state.my_catalogue.data.map((catalogue) =>
                            catalogue.product.id === action.payload.id ? { ...catalogue, product: action.payload } : catalogue,
                        ),
                    },
                };
            case Eaction.SetCreateProductField:
                return { ...state, product_field: action.payload };
            case Eaction.SetEditProductField:
                return { ...state, selectedPublishProductField: action.payload };
            case Eaction.SetMyCatalogueData:
                return { ...state, my_catalogue: { ...state.my_catalogue, data: action.payload } };
            case Eaction.SetMyCatalogueCurrentPage:
                return { ...state, my_catalogue: { ...state.my_catalogue, current_page: action.payload } };
            case Eaction.SetMyCatalogueRecordsPerPage:
                return { ...state, my_catalogue: { ...state.my_catalogue, records_per_page: action.payload } };
            case Eaction.SetMyCatalogueTotalPages:
                return { ...state, my_catalogue: { ...state.my_catalogue, total_pages: action.payload } };
            case Eaction.SetMyCataloguePages:
                return { ...state, my_catalogue: { ...state.my_catalogue, pages: action.payload } };
            case Eaction.SetMyCatalogueLoader:
                return { ...state, my_catalogue: { ...state.my_catalogue, loader: action.payload } };
            case Eaction.setNextSKU:
                return { ...state, nextSKU: action.payload };
            default:
                return state;
        }
    },
);

export const actions = {
    setSelectedPublishProductField: (payload: selectedPublishProductFieldType) => ({ type: Eaction.SetSelectedPublishProductField, payload }),
    setSelectedPublishProductId: (payload: string) => ({ type: Eaction.SetSelectedPublishProductId, payload }),
    setCreateProductField: (payload: productFieldType) => ({ type: Eaction.SetCreateProductField, payload }),
    setEditProductField: (payload: productFieldType) => ({ type: Eaction.SetEditProductField, payload }),
    setMyCatalogueData: (payload: productType[]) => ({ type: Eaction.SetMyCatalogueData, payload }),
    setMyCatalogueCurrentPage: (payload: number) => ({ type: Eaction.SetMyCatalogueCurrentPage, payload }),
    setMyCatalogueRecordsPerPage: (payload: number) => ({ type: Eaction.SetMyCatalogueRecordsPerPage, payload }),
    setMyCatalogueTotalPages: (payload: number) => ({ type: Eaction.SetMyCatalogueTotalPages, payload }),
    setMyCataloguePages: (payload: number[]) => ({ type: Eaction.SetMyCataloguePages, payload }),
    setMyCatalogueLoader: (payload: boolean) => ({ type: Eaction.SetMyCatalogueLoader, payload }),
    updateCatalogueList: (payload: productFieldType) => ({ type: Eaction.UpdateCatalogueList, payload }),
};
