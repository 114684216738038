import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import NotFound from '../../../assets/images/png/no-image.jpg';
import AshArrow from '../../../assets/images/svg/arrowleftAsh.svg';
import Cancel from '../../../components/CancelButton/Cancel';
import Pagination from '../../../components/Pagination/Pagination';
import { IinitialGroupStateInterface } from '../../../redux/features/interfaces';
import { useAddCompanyToGroupMutation } from '../../../redux/services';
import { addAllToList, addToList, removeCustomer } from '../../../redux/slices/global';
import EmptyCustomer from '../EmptyContent/EmptyCustomer';
import EmptySearch from '../EmptyContent/EmptySearch';

const Customerslist = ({ close, data, handleChange, query, setQuery, customers, isnew, group_id }: any) => {
    const dispatch = useDispatch();
    const [addToGroup, { isLoading, isSuccess, isError, error }] = useAddCompanyToGroupMutation();

    useEffect(() => {
        if (isSuccess) {
            toast.success('Customer added to group  successfully');
            close();
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
            close();
        }
    }, [isLoading, isSuccess, isError, error, close]);

    return (
        <>
            <div className="mt-4 mr-4">
                <Cancel close={close} />
            </div>
            <div className="px-[64px] py-[36px] w-[702px] h-[640px] ">
                <div className="flex gap-1 mb-4 cursor-pointer" onClick={close}>
                    <img src={AshArrow} alt="arrowleft" />
                    <p className="text-[#525171] text-[16px]"> Back</p>
                </div>
                <div className="h-[2.3rem] relative  ">
                    <i className="fa fa-search icon absolute top-1/2 -translate-y-1/2 px-[0.79rem] " />
                    <input
                        className="w-full bg-[#F8F8FB] border-b-2 border-[#BAB9C5] focus:outline-none pl-[2.08rem] h-full placeholder:text-[16px] text-[16px] text-jumbleng-gray placeholder:text-[#525171]"
                        placeholder="Search"
                        onChange={(e: any) => {
                            handleChange(e);
                        }}
                    />
                </div>

                {data?.data.length === 0 ? (
                    <EmptyCustomer />
                ) : (
                    <div className="flex justify-between">
                        <p className="text-[#5E5BA7] text-[16px] mt-6 mb-5">
                            <span className="font-bold">{customers.length}</span> Vendor(s) selected
                        </p>
                        <p
                            className="text-[#5E5BA7] text-[16px] mt-6 mb-5 cursor-pointer"
                            onClick={() => dispatch(addAllToList(data?.data.map((company: any) => company.company_id)))}
                        >
                            Add <span className="font-bold">{data?.data.length}</span> Vendors
                        </p>
                    </div>
                )}
                <div className="h-[360px] overflow-auto">
                    {data?.data.length === 0 && query.search_query !== '' ? (
                        <EmptySearch content={'customers'} />
                    ) : isnew ? (
                        data?.data.map((company: any, index: number) => (
                            <div className="grid grid-cols-6 gap-2 mb-4" key={index}>
                                <div className="text-sm flex gap-2 col-span-4 ">
                                    <div className="flex gap-2 items-center rounded-full">
                                        <img
                                            src={company.logo ? company.logo : NotFound}
                                            alt="avatar"
                                            className="h-[3.2rem] w-[3.2rem] object-contain"
                                        />
                                    </div>{' '}
                                    <div>
                                        <div>{company.name}</div>
                                        <div> {company.email}</div>
                                    </div>
                                </div>
                                {customers.includes(company.company_id) ? (
                                    <>
                                        <div className={`grid content-center ${isnew && 'justify-end col-span-2'}`}>
                                            <div className="flex gap-1 text-[#5E5BA7] items-center">
                                                <i className="fa fa-check"></i>
                                                <p className="font-semibold  text-[16px]"> Added </p>
                                            </div>
                                        </div>
                                        {!isnew && (
                                            <div
                                                className="grid content-center cursor-pointer"
                                                onClick={() => dispatch(removeCustomer(company.company_id))}
                                            >
                                                <div className="flex gap-1 text-jumbleng-red items-center">
                                                    <i className="fa fa-xmark"></i>
                                                    <p className="font-semibold text-[16px]"> Remove </p>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="col-span-2 flex justify-end ">
                                        <div className="grid content-center cursor-pointer" onClick={() => dispatch(addToList(company.company_id))}>
                                            <div className="flex items-center justify-center gap-1 py-[5px] px-[24px] w-[80px] border text-[#5E5BA7] hover:text-white rounded-xl border-jumbleng-primary hover:bg-jumbleng-primary">
                                                <i className="fa-solid fa-user-plus"></i>
                                                <p className="font-semibold  text-[16px] "> Add </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        data?.data.map((company: any, index: number) => (
                            <div className="grid grid-cols-6 gap-2 mb-4" key={index}>
                                <div className="text-sm flex gap-2 col-span-4 ">
                                    <div className="flex gap-2 items-center rounded-full">
                                        <img
                                            src={company.logo ? company.logo : NotFound}
                                            alt="avatar"
                                            className="h-[3.2rem] w-[3.2rem] object-contain"
                                        />
                                    </div>{' '}
                                    <div>
                                        <div>{company.name}</div>
                                        <div> {company.email}</div>
                                    </div>
                                </div>
                                {customers.includes(company.company_id) ? (
                                    <>
                                        <div className={`grid content-center ${isnew && 'justify-end col-span-2'}`}>
                                            <div className="flex gap-1 text-[#5E5BA7] items-center">
                                                <i className="fa fa-check"></i>
                                                <p className="font-semibold  text-[16px]"> Added </p>
                                            </div>
                                        </div>
                                        {!isnew && (
                                            <div
                                                className="grid content-center cursor-pointer"
                                                onClick={() => dispatch(removeCustomer(company.company_id))}
                                            >
                                                <div className="flex gap-1 text-jumbleng-red items-center">
                                                    <i className="fa fa-xmark"></i>
                                                    <p className="font-semibold text-[16px]"> Remove </p>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="col-span-2 flex justify-end ">
                                        <div className="grid content-center cursor-pointer" onClick={() => dispatch(addToList(company.company_id))}>
                                            <div className="flex items-center justify-center gap-1 py-[5px] px-[24px] w-[80px] border text-[#5E5BA7] hover:text-white rounded-xl border-jumbleng-primary hover:bg-jumbleng-primary">
                                                <i className="fa-solid fa-user-plus"></i>
                                                <p className="font-semibold  text-[16px] "> Add </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))
                    )}
                    {(data as IinitialGroupStateInterface)?.data.length > 10 && (
                        <Pagination query={query} setQuery={setQuery} data={data?.data} count={data?.count} />
                    )}{' '}
                </div>

                <div className="flex justify-between gap-2 mt-8">
                    <div
                        onClick={close}
                        className="border border-[#5E5BA7] rounded-lg grid justify-center py-2 items-center w-[168px] h-[48px] cursor-pointer"
                    >
                        <p className="font-semibold text-[#5E5BA7] text-[16px] "> Cancel</p>
                    </div>
                    {isnew ? (
                        <div
                            onClick={() => addToGroup({ id: group_id, vendors: customers })}
                            className="bg-[#5E5BA7] rounded-lg grid justify-center py-2 items-center w-[168px] h-[48px] cursor-pointer"
                        >
                            <p className="font-semibold text-[#FFFFFF] text-[16px] "> Add to the group</p>
                        </div>
                    ) : (
                        <div
                            onClick={close}
                            className="bg-[#5E5BA7] rounded-lg grid justify-center py-2 items-center w-[168px] h-[48px] cursor-pointer"
                        >
                            <p className="font-semibold text-[#FFFFFF] text-[16px] "> Continue</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Customerslist;
