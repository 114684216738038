import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { useWindowSize } from 'usehooks-ts';

import More from '../assets/images/svg/More.svg';
import MoreWhite from '../assets/images/svg/More-White.svg';
import PopUpModal from '../components/PopModal/PopUpModal';
import Loading from '../components/UI/Spinner/Loading';
import ViewInvoice from '../pages/invoice/InvoicePopUp/ViewInvoice';
import AcceptContent from '../pages/order/OrderPopUp/AcceptContent';
import CancelContent from '../pages/order/OrderPopUp/CancelContent';
import MarkFulfilled from '../pages/order/OrderPopUp/MarkFulfilled';
import { OrderItemsType, OrderType } from '../redux/features/interfaces';
import { RootState } from '../redux/store';
import { convertDate, toLocaleFixed } from '../utils/utils';

interface OrderProps {
    customertab: string;
    data: OrderType[];
    currentStatus: string;
    loading: boolean;
}
const OrderWrapper: FC<OrderProps> = ({ customertab, children, data, loading, currentStatus }) => {
    const navigate = useNavigate();
    const [checkedState, setCheckedState] = useState<boolean[]>(new Array(data.length).fill(false));
    const { width } = useWindowSize();
    const { iscustomer } = useSelector((state: RootState) => state.listCustomerOrdersReducer);

    const handleClick = (id: string) => {
        navigate(`/order/${id}`);
    };

    useEffect(() => {
        setCheckedState(new Array(data.length).fill(false));
    }, [data]);
    if (loading)
        return (
            <div className="min-h-[450px] flex justify-center flex-col">
                <Loading />
            </div>
        );
    return (
        <div>
            {data.map((order: OrderType, index: number) => (
                <div
                    className={`grid grid-cols-1 ${
                        iscustomer && customertab === 'cancelled' ? 'md:grid-cols-7' : 'md:grid-cols-8'
                    } pt-4 p-5   hover:bg-[#5D5BA7] hover:text-white cursor-pointer`}
                    key={order.id}
                    onClick={() => handleClick(order.id)}
                >
                    <>
                        <div className="text-sm grid justify-center">{convertDate(order.created_at)}</div>
                        <div className="text-sm grid justify-center text-center font-bold md:font-normal  ">
                            {iscustomer ? order.ordering_company.name : order.vendor.name}
                        </div>
                        <div className="text-sm grid justify-center">ORD-{order.order_no}</div>
                        <div className="text-sm grid justify-center"> {Number(order.order_items.length).toLocaleString()}</div>
                        <div className="text-sm grid justify-center font-bold md:font-normal ">
                            ₦
                            {toLocaleFixed(
                                order.order_items.reduce((total: number, item: OrderItemsType) => item.qty * item.buying_price + total, 0),
                                2,
                            )}
                        </div>
                        <div className="text-sm grid justify-center"> {order.invoice_issued ? 'Yes' : 'No'}</div>

                        <div className=" grid justify-center">
                            <p
                                className={`text-sm  py-2 px-5 rounded-2xl 
                                    ${
                                        order.status === 'unfulfilled'
                                            ? 'text-[#084F21] bg-green-100'
                                            : order.status === 'fulfilled'
                                            ? 'text-[#084F21] bg-green-400'
                                            : order.status === 'pending'
                                            ? 'bg-amber-100 text-[#D29C17]'
                                            : '  bg-red-100 text-[#840B0B]'
                                    }`}
                            >
                                {order.status}
                            </p>
                        </div>
                        <Popup
                            trigger={
                                order.status == 'cancelled' && iscustomer ? (
                                    <></>
                                ) : (
                                    <div
                                        className={`grid justify-center ${
                                            iscustomer && (order.status === 'unfulfilled' || order.status === 'accepted') && 'hidden'
                                        }`}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <img src={checkedState[index] ? MoreWhite : More} alt="more" />
                                    </div>
                                )
                            }
                            on="hover"
                            position={`${width < 768 ? 'top center' : 'bottom left'}`}
                            mouseLeaveDelay={50}
                            mouseEnterDelay={0}
                            closeOnDocumentClick
                            arrow={false}
                            nested
                            contentStyle={{
                                padding: '10px',
                                borderRadius: '12px',
                                color: '#5E5BA7',
                                width: `${width < 768 ? 'auto' : '167px'}`,
                            }}
                        >
                            {(close: () => void) => (
                                <div className=" grid justify-center">
                                    <div className="grid justify-center p-2 pb-2">
                                        {order.status === 'pending' ? (
                                            <div className="flex flex-col  gap-2 items-center">
                                                {iscustomer && (
                                                    <PopUpModal
                                                        content={
                                                            <div className="flex flex-col">
                                                                <div>Accept Order</div>
                                                            </div>
                                                        }
                                                        className=""
                                                        popupcontent={false}
                                                        onClose={close}
                                                    >
                                                        {(close: () => void) => (
                                                            <AcceptContent id={order.id} order_no={order.order_no} close={close} />
                                                        )}
                                                    </PopUpModal>
                                                )}
                                                <PopUpModal
                                                    content={
                                                        <div className="flex flex-col">
                                                            <div>Cancel Order</div>
                                                        </div>
                                                    }
                                                    className=""
                                                    popupcontent={false}
                                                    onClose={close}
                                                >
                                                    {(close: () => void) => <CancelContent id={order.id} order_no={order.order_no} close={close} />}
                                                </PopUpModal>
                                            </div>
                                        ) : order.status === 'fulfilled' ? (
                                            <>
                                                <Popup
                                                    trigger={
                                                        <div>
                                                            <div className={`cursor-pointer mb-2`}>View invoice</div>
                                                        </div>
                                                    }
                                                    modal
                                                    className=""
                                                    contentStyle={{ width: order.invoice_issued || iscustomer ? '70%' : '376px' }}
                                                >
                                                    {(close: () => void) => (
                                                        <ViewInvoice
                                                            ishome={true}
                                                            id={order.id}
                                                            close={close}
                                                            order_no={order.order_no}
                                                            currentStatus={currentStatus}
                                                            ordering_company={order.ordering_company}
                                                            vendor={order.vendor}
                                                            created_at={order.created_at}
                                                            invoice_no={order.invoice_no}
                                                            invoice_issued={order.invoice_issued}
                                                            iscustomer={iscustomer}
                                                            status={order.status}
                                                            order_items={order.order_items}
                                                            invoice_id={order.invoice_id}
                                                        />
                                                    )}
                                                </Popup>
                                                {!iscustomer && (
                                                    <PopUpModal
                                                        content={'Reorder'}
                                                        className="text-center"
                                                        popupcontent={false}
                                                        onClose={close}
                                                    ></PopUpModal>
                                                )}
                                            </>
                                        ) : order.status === 'unfulfilled' || order.status === 'accepted' ? (
                                            <>
                                                {iscustomer ? (
                                                    <div className="flex flex-col items-center gap-2">
                                                        {order.invoice_status !== 'paid' && (
                                                            <PopUpModal onClose={close} content={'Cancel Order'} className="" popupcontent={false}>
                                                                {(close: () => void) => (
                                                                    <CancelContent id={order.id} order_no={order.order_no} close={close} />
                                                                )}
                                                            </PopUpModal>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="flex flex-col gap-2 items-center">
                                                        <Popup
                                                            trigger={
                                                                <div>
                                                                    <div className={`cursor-pointer `}>View invoice</div>
                                                                </div>
                                                            }
                                                            modal
                                                            className=""
                                                            contentStyle={{ width: order.invoice_issued || iscustomer ? '70%' : '376px' }}
                                                        >
                                                            {(close: () => void) => (
                                                                <ViewInvoice
                                                                    ishome={true}
                                                                    id={order.id}
                                                                    close={close}
                                                                    order_no={order.order_no}
                                                                    currentStatus={currentStatus}
                                                                    ordering_company={order.ordering_company}
                                                                    vendor={order.vendor}
                                                                    created_at={order.created_at}
                                                                    invoice_no={order.invoice_no}
                                                                    invoice_issued={order.invoice_issued}
                                                                    iscustomer={iscustomer}
                                                                    status={order.status}
                                                                    order_items={order.order_items}
                                                                    invoice_id={order.invoice_id}
                                                                />
                                                            )}
                                                        </Popup>
                                                        {order.invoice_status !== 'paid' && (
                                                            <PopUpModal onClose={close} content={'Cancel order'} className="" popupcontent={false}>
                                                                {(close: () => void) => (
                                                                    <CancelContent id={order.id} order_no={order.order_no} close={close} />
                                                                )}
                                                            </PopUpModal>
                                                        )}
                                                        <PopUpModal onClose={close} content={'Order Fulfilled'} className="" popupcontent={false}>
                                                            {' '}
                                                            <MarkFulfilled id={order.id} order_no={order.order_no} close={close} />
                                                        </PopUpModal>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            order.status === 'cancelled' && (
                                                <PopUpModal onClose={close} content={'Reorder'} className="" popupcontent={false}></PopUpModal>
                                            )
                                        )}
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </>
                </div>
            ))}
            {children}
        </div>
    );
};

export default OrderWrapper;
