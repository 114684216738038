import { FC } from 'react';

import Spinner from '../../../components/UI/Spinner/Spinner';

interface CProps {
    action?: any;
    id: string;
    close: () => void;
    actionName: string;
    actionTitle: string;
    name: string;
    loader?: boolean;
    repId?: string;
    refetch?: any;
}

const CustomerAction: FC<CProps> = ({ loader, id, close, action, actionName, name, repId, refetch }) => {
    const handleAction = async (id: string) => {
        if (actionName === 'unassign') {
            await action(id, repId, close, refetch);
        } else {
            await action(id);
        }
    };
    return (
        <>
            <div>
                <button className="close" onClick={close}>
                    &times;
                </button>
                <div className="flex items-center justify-center flex-col mt-8 mx-4">
                    <p className="border rounded-[50%] bg-[#F5E7E7] w-[40px] h-[40px] p-1 flex items-center justify-center">
                        <i className="fa fa-triangle-exclamation text-[#9F0C0D] "></i>
                    </p>
                    <p className="text-[#5E5BA7] mt-8 text-center">
                        Are you sure you want to {actionName === 'unassign' ? 'unassign sales rep from' : actionName} {name}
                    </p>
                </div>
            </div>
            <div className="grid grid-cols-2 my-8 gap-4 mx-4">
                <div className="grid justify-center py-4 border-2 border-[#5E5BA7] rounded-[12px]">
                    <p className="text-jumbleng-primary cursor-default" onClick={close}>
                        No, go back
                    </p>
                </div>
                <div
                    className={`grid justify-center py-4 ${
                        actionName !== 'reactivate' && actionName !== 'reassign sales rep to' ? 'bg-[#9F0C0D]' : 'bg-jumbleng-primary'
                    } rounded-[12px] cursor-pointer`}
                    onClick={() => handleAction(id)}
                >
                    {loader ? <Spinner /> : <p className="text-white ">Yes, {actionName}</p>}
                </div>
            </div>
        </>
    );
};

export default CustomerAction;
