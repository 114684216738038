import * as Yup from 'yup';

const FILE_SIZE = 5072 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

export interface IBioInterface {
    bio?: string;
    banner?: string;
    private?: boolean;
}

export const IinitialBio: IBioInterface = {
    bio: '',
    banner: '',
    private: false,
};
export const BioSchema = Yup.object().shape(
    {
        bio: Yup.string().notRequired(),
        banner: Yup.lazy((value) => {
            if (value) {
                switch (typeof value) {
                    case 'string':
                        return Yup.mixed().test('url', (value) => value && value.includes('http')); // schema for image url
                    case 'object':
                        return Yup.mixed()
                            .test('fileFormat', 'Unsupported Image Format', (value) => value && SUPPORTED_FORMATS.includes(value.type))
                            .test('fileSize', 'File is too large', (value) => value && value.size <= FILE_SIZE); // schema for image file
                    default:
                        return Yup.mixed().test('url', (value) => value && value.includes('http')); // here you can decide what is the default
                }
            } else {
                return Yup.string().notRequired();
            }
        }),
    },
    [['banner', 'banner']],
);
