import styled from 'styled-components';

export const Modaled = styled.div`
    .darkBG {
        background-color: rgba(0, 0, 0, 0.2);
        width: 250vh;
        height: 250vh;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: fixed;
    }

    .centered {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .modal {
        width: 502px;
        height: fit-content;
        background: white;
        padding: 30px;
        color: white;
        z-index: 10;
        border-radius: 16px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    }

    .modalHeader {
        height: 50px;
        background: white;
        overflow: hidden;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }

    .heading {
        margin: 0;
        padding: 10px;
        color: #2c3e50;
        font-weight: bolder;
        font-size: 18px;
        text-align: center;
    }

    .modalContent {
        padding: 10px;
        font-size: 14px;
        color: #2c3e50;
        text-align: center;

        .form__container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;
            padding: 0 20px;

            .form__input {
                display: flex;
                flex-direction: column;
                gap: 20px;
                align-items: flex-start;

                label {
                    font-size: 13px;
                    color: #525171;
                }

                input {
                    border-bottom: 1px solid #00000050;
                    width: 100%;
                    outline: none;
                }

                select {
                    // A reset of styles, including removing the default dropdown arrow
                    appearance: none;
                    // Additional resets for further consistency
                    background-color: transparent;
                    border: none;
                    border-bottom: 1px solid #00000050;
                    padding: 0 1em 0 0;
                    margin: 0;
                    width: 100%;
                    font-family: inherit;
                    font-size: inherit;
                    cursor: inherit;
                    line-height: inherit;
                    outline: none;
                }
                .select {
                    width: 100%;
                    min-width: 15ch;
                    border: 1px solid var(--select-border);
                    border-radius: 0.25em;
                    cursor: pointer;
                    line-height: 1.1;
                    display: grid;
                    grid-template-areas: 'select';
                    align-items: center;
                    position: relative;

                    // &::after {
                    //     content: '';
                    //     width: 0.8em;
                    //     height: 0.5em;
                    //     background-color: #706f77;
                    //     clip-path: polygon(100% 0%, 0 0%, 50% 100%);
                    //     justify-self: end;
                    // }
                }
                select,
                .select:after {
                    grid-area: select;
                }
            }
        }
    }

    .actionsContainer {
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 50px;

        .loader__container {
            background-color: #5e5ba7;
            height: 44px;
            width: 100px;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            margin-top: 10px;
            cursor: not-allowed;

            .loader {
                width: 4px;
                aspect-ratio: 1;
                border-radius: 50%;
                box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
                transform: translateX(-38px);
                animation: d1 0.5s infinite alternate linear;
                margin: auto;
            }

            @keyframes d1 {
                50% {
                    box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
                }
                100% {
                    box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
                }
            }
        }
    }

    .closeBtn {
        cursor: pointer;
        font-weight: 500;
        padding: 4px 8px;
        border-radius: 8px;
        border: none;
        font-size: 18px;
        color: #2c3e50;
        background: white;
        transition: all 0.25s ease;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
        position: absolute;
        right: 0;
        top: 0;
        align-self: flex-end;
        margin-top: 10px;
        margin-right: 10px;

        img {
            width: 15px;
        }
    }

    .closeBtn:hover {
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
        transform: translate(-4px, 4px);
    }

    .cancelBtn {
        margin-top: 10px;
        cursor: pointer;
        font-weight: 500;
        padding: 14px 20px;
        border-radius: 12px;
        font-size: 0.8rem;
        border: none;
        color: #5e5ba7;
        font-weight: bold;
        background: transparent;
        transition: all 0.25s ease;
        border: 1px solid #5e5ba7;
    }

    .cancelBtn:hover {
        box-shadow: none;
        transform: none;
        background: whitesmoke;
    }
    .sendBtn {
        margin-top: 10px;
        cursor: pointer;
        font-weight: 500;
        padding: 14px 20px;
        border-radius: 12px;
        font-size: 0.8rem;
        border: none;
        font-weight: bold;
        color: #fff;
        background: #5e5ba7;
        transition: all 0.25s ease;
    }

    .sendBtn:hover {
        box-shadow: none;
        transform: none;
        background: #5e5ba750;
    }
`;
