import { ErrorMessage, Field, Formik } from 'formik';
import _ from 'lodash';
import { FC, useMemo, useRef, useState } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import BackArrow from '../../../../assets/images/svg/arrow-left.svg';
import Button from '../../../../components/Button';
import SignupSteps from '../../../../components/SignupSteps/SignupSteps';
import GuestRoute from '../../../../HOC/GuestRoute';
import { ICompanyDetails } from '../../../../interfaces';
import { actions } from '../../../../redux/features/auth/authRedux';
import { register } from '../../../../redux/features/auth/authService';
import { authorizationType, companyType, EregistrationSteps, Iaction, registrationDataType, userType } from '../../../../redux/features/interfaces';
import { CompanyDetailsSchema, initialCompanyDetailsValues } from '../../../../schema/company-details.schema';
import { phones } from '../../../../utils/phone_number';

//
export type industryTypesTypes = { key: string; value: string };
type companyTypesTypes = { key: string; value: string };
type companySizeTypes = { key: string; value: string };

interface CompanyDetailsProps {
    industryTypes: industryTypesTypes[];
    companyTypes: companyTypesTypes[];
    companySize: companySizeTypes[];
    credentialsLoad: boolean;
    registration_data: registrationDataType;
    setRegistrationStep: (data: EregistrationSteps) => Iaction;
    setTokenObj: (payload: authorizationType) => Iaction;
    setAllUserDetails: (payload: { user: userType; company: companyType; authorization: authorizationType }) => Iaction;
    clearRegistrationData: () => Iaction;
}

const CompanyDetails: FC<CompanyDetailsProps> = ({
    industryTypes,
    companyTypes,
    companySize,
    registration_data,
    setRegistrationStep,
    clearRegistrationData,
    setAllUserDetails,
}) => {
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
    const navigate = useNavigate();
    const valuesRef = useRef<any>(null);
    const [country, setCountry] = useState<any>();
    const [region, setRegion] = useState<any>();
    const [fieldError, setFieldError] = useState({ nationality: false, region: false, country: false });

    const selectCountry = (val: any) => {
        setCountry(val);
        setFieldError({ ...fieldError, country: false });
        setRegion('');
    };
    const selectRegion = (val: any) => {
        if (val) {
            setRegion(val);
            setFieldError({ ...fieldError, region: false });
        } else {
            setRegion('');
        }
    };

    const handleCompanyDetailsSubmit = async (values: ICompanyDetails): Promise<void> => {
        if (!country) {
            setFieldError({ ...fieldError, country: true });
            return;
        } else if (!region) {
            setFieldError({ ...fieldError, region: true });
            return;
        } else {
            try {
                setFieldError({ ...fieldError, country: false, region: false });
                setLoadingBtn(true);
                const cleanedUserEmail = registration_data.email.toLowerCase();
                const cleanedCompanyEmail = values.email.toLowerCase();
                const response = await register({
                    user: {
                        ...registration_data,
                        email: cleanedUserEmail,
                    },
                    company: {
                        ...values,
                        email: cleanedCompanyEmail,
                        phone_number: valuesRef.current?.values?.code + values.phone_number,
                        country: country,
                        state: region,
                    },
                });
                setAllUserDetails({ ...response, authorization: {} });
                toast.success('Signup Successful');
                navigate('/verify-company');
                setRegistrationStep(EregistrationSteps.PresonalDetails);
            } catch (error: any) {
                setLoadingBtn(false);
                setRegistrationStep(EregistrationSteps.PresonalDetails);
                const message = error.response && error.response.data?.message ? error.response.data?.message : error.message;
                toast.error(message);
            }
        }
    };
    const phone_numbers = useMemo(() => {
        return _.sortBy(phones.map((item: any) => item.code));
    }, []);

    return (
        <GuestRoute>
            <div className="w-full min-h-screen flex">
                <SignupSteps step={2} />
                <div className="w-[62%] px-[2.93rem] mb-[5.22rem]">
                    <div className="mt-[2.146rem] mb-[3.12rem] flex items-center justify-between">
                        <span
                            className="flex items-center text-[0.781rem] ml-[4px] text-jumbleng-primary"
                            onClick={() => setRegistrationStep(EregistrationSteps.PresonalDetails)}
                        >
                            <img src={BackArrow} /> Back
                        </span>

                        <p className="text-[0.78125rem] text-jumbleng-gray">
                            Already have an account?{' '}
                            <button
                                onClick={() => {
                                    clearRegistrationData();
                                    setRegistrationStep(EregistrationSteps.PresonalDetails);
                                    navigate('/');
                                }}
                            >
                                Log-in
                            </button>
                        </p>
                    </div>

                    <div className="w-[23.5rem] mx-auto text-[1.784rem] font-semibold">
                        <h2 className="">Company details</h2>
                        <p className="text-[0.78125rem] font-normal text-jumbleng-gray mb-[2.34rem]">Please fill in the details below correctly</p>
                        <Formik
                            onSubmit={handleCompanyDetailsSubmit}
                            innerRef={valuesRef}
                            initialValues={{
                                ...initialCompanyDetailsValues,
                                code: initialCompanyDetailsValues.phone_number.substring(0, initialCompanyDetailsValues.phone_number.length - 10),
                                phone_number: initialCompanyDetailsValues.phone_number.slice(-10).replace(/\s+/g, ''),
                            }}
                            validationSchema={CompanyDetailsSchema}
                        >
                            {({ handleSubmit, handleChange, handleBlur, values, isValid, setFieldTouched }) => (
                                <>
                                    <div className="mb-[1.953rem]">
                                        <p className="text-[0.7812rem] font-normal text-jumbleng-gray-main">Company Email</p>
                                        <Field
                                            name={'email'}
                                            onKeyUp={() => setFieldTouched('email', true)}
                                            type={'email'}
                                            placeholder={'Company Email'}
                                            className="w-full placeholder:text-[0.78125rem] text-base focus:outline-none py-1 pr-4 pl-2 border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]"
                                        />
                                        <ErrorMessage
                                            name={'email'}
                                            render={(msg) => <div className="text-[0.7812rem] text-red-400 text-left font-normal ">{msg}</div>}
                                        />
                                    </div>

                                    <div className="mb-[1.953rem]">
                                        <p className="text-[0.7812rem] font-normal text-jumbleng-gray-main">Where is your company located?</p>
                                        <Field
                                            name={'address'}
                                            onKeyUp={() => setFieldTouched('address', true)}
                                            placeholder={'Company Address'}
                                            className="w-full placeholder:text-[0.78125rem] text-base focus:outline-none py-1 pr-4 pl-2 border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]"
                                        />
                                        <ErrorMessage
                                            name={'address'}
                                            render={(msg) => <div className="text-[0.7812rem] text-red-400 text-left font-normal ">{msg}</div>}
                                        />
                                    </div>

                                    <div className="mb-[1.953rem]">
                                        <p className="text-[0.7812rem] font-normal text-jumbleng-gray-main">Country</p>
                                        <div>
                                            <CountryDropdown
                                                value={country}
                                                onChange={(val) => selectCountry(val)}
                                                classes="w-full placeholder:text-[0.78125rem] text-base focus:outline-none py-1 pr-4 pl-2 border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]"
                                            />
                                            {fieldError.country && (
                                                <div className="text-[0.7812rem] text-red-400 text-left font-normal fon">
                                                    country field is required
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mb-[1.953rem]">
                                        <p className="text-[0.7812rem] font-normal text-jumbleng-gray-main">Your company&apos;s phone number?</p>
                                        <div className="flex gap-2">
                                            <div className="mt-1 relative flex ">
                                                <Field
                                                    as={'select'}
                                                    name={'code'}
                                                    className="w-[6.5rem] placeholder:text-[0.78125rem] text-base focus:outline-none py-1 pr-4 pl-2 border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]"
                                                >
                                                    {phone_numbers?.map((item: any, index: number) => (
                                                        <option key={index} value={item}>
                                                            {item}
                                                        </option>
                                                    ))}{' '}
                                                </Field>
                                            </div>
                                            <Field
                                                name={'phone_number'}
                                                type={'tel'}
                                                onKeyUp={() => setFieldTouched('phone_number', true)}
                                                placeholder={'Phone Number'}
                                                className="w-[17rem] placeholder:text-[0.78125rem] text-base focus:outline-none py-1 pr-4 pl-2 border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]"
                                            />
                                        </div>

                                        <ErrorMessage
                                            name={'phone_number'}
                                            render={(msg) => <div className="text-[0.7812rem] text-red-400 text-left font-normal">{msg}</div>}
                                        />
                                    </div>

                                    <div className="mb-[1.953rem]">
                                        <p className="text-[0.7812rem] font-normal text-jumbleng-gray-main">
                                            Your company is categorized under which industry?
                                        </p>
                                        <select
                                            name={'industry_type'}
                                            onKeyUp={() => setFieldTouched('industry_type', true)}
                                            onChange={handleChange('industry_type')}
                                            onBlur={handleBlur('industry_type')}
                                            defaultValue={values.industry_type}
                                            className="w-full placeholder:text-[0.78125rem] text-base focus:outline-none py-1 pr-4 pl-2 border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]"
                                        >
                                            <option value="">Select industry type</option>
                                            {industryTypes.map((value: industryTypesTypes, index: number) => (
                                                <option key={index} value={value.key}>
                                                    {value.value}
                                                </option>
                                            ))}
                                        </select>
                                        <ErrorMessage
                                            name={'industry_type'}
                                            render={(msg) => <div className="text-[0.7812rem] text-red-400 text-left font-normal ">{msg}</div>}
                                        />
                                    </div>

                                    <div className="mb-[1.953rem]">
                                        <p className="text-[0.7812rem] font-normal text-jumbleng-gray-main">State</p>
                                        <div>
                                            <RegionDropdown
                                                defaultOptionLabel="Select State"
                                                country={country}
                                                value={region}
                                                onChange={(val) => selectRegion(val)}
                                                classes="w-full placeholder:text-[0.78125rem] text-base focus:outline-none py-1 pr-4 pl-2 border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]"
                                            />
                                            {fieldError.region && (
                                                <div className="text-[0.7812rem] text-red-400 text-left font-normal fon">state field is required</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="mb-[1.953rem]">
                                        <p className="text-[0.7812rem] font-normal text-jumbleng-gray-main">Company Type</p>
                                        <select
                                            name={'company_type'}
                                            onKeyUp={() => setFieldTouched('company_type', true)}
                                            onChange={handleChange('company_type')}
                                            onBlur={handleBlur('company_type')}
                                            defaultValue={values.company_type}
                                            className="w-full placeholder:text-[0.78125rem] text-base focus:outline-none py-1 pr-4 pl-2 border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]"
                                        >
                                            <option value="">Select company type</option>
                                            {companyTypes.map((value: companyTypesTypes, index: number) => (
                                                <option key={index} value={value.key}>
                                                    {value.value}
                                                </option>
                                            ))}
                                        </select>
                                        <ErrorMessage
                                            name={'company_type'}
                                            render={(msg) => <div className="text-[0.7812rem] text-red-400 text-left font-normal ">{msg}</div>}
                                        />
                                    </div>

                                    <div className="mb-[1.953rem]">
                                        <p className="text-[0.7812rem] font-normal text-jumbleng-gray-main">What is your company size</p>
                                        <select
                                            name={'work_force'}
                                            onKeyUp={() => setFieldTouched('work_force', true)}
                                            onChange={handleChange('work_force')}
                                            onBlur={handleBlur('work_force')}
                                            defaultValue={values.work_force}
                                            className="w-full placeholder:text-[0.78125rem] text-base focus:outline-none py-1 pr-4 pl-2 border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]"
                                        >
                                            <option value="">Select work force</option>
                                            {companySize.map((value: companySizeTypes, index: number) => (
                                                <option key={index} value={value.key}>
                                                    {value.value}
                                                </option>
                                            ))}
                                        </select>
                                        <ErrorMessage
                                            name={'work_force'}
                                            render={(msg) => <div className="text-[0.7812rem] text-red-400 text-left font-normal ">{msg}</div>}
                                        />
                                    </div>
                                    <Button
                                        type={'submit'}
                                        disabled={!isValid || !values?.email}
                                        onClick={handleSubmit}
                                        message={'Create account'}
                                        loading={loadingBtn}
                                        className={`w-[23.5rem] h-[3.125rem] mb-[1.953rem] text-white  ${
                                            !isValid || !values?.email || !country || !region ? 'bg-[#CFCFD1] cursor-default' : 'bg-jumbleng-primary'
                                        } text-[0.78125rem] font-semibold rounded-[12px]`}
                                    />
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </GuestRoute>
    );
};

export default connect((state: any) => {
    const { registration_data } = state.authStore;
    return { registration_data };
}, actions)(CompanyDetails);
