import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LeftArrow from '../../assets/images/svg/arrow-left.svg';
import NotificationBox from '../../components/Notification/NotificationBox';
import Pagination from '../../components/Pagination/Pagination';
import WrapperVendor from '../../HOC/WrapperVendor';
import { useGetNotificationQuery } from '../../redux/services/notificationApi';
import {
    retrieveNotification,
    setNotificationId,
    setQuery,
    toggleNotificationStatusTab,
    toggleNotificationTab,
} from '../../redux/slices/notifications';
import { RootState } from '../../redux/store';
import { IContextTab, IstatusTab, notificationContext } from './interface';

const ListItem = ({ tabName, activeTab, toggleTab }: { tabName: IContextTab; activeTab: string; toggleTab: (tabName: IContextTab) => void }) => {
    return (
        <li className="flex items-center">
            <a
                href="#"
                onClick={() => toggleTab(tabName)}
                className={`${
                    activeTab === tabName && 'font-bold border-b text-jumbleng-primary'
                } w-full px-4 py-[0.78125rem] h-full text-[0.78125] capitalize`}
            >
                {tabName}
            </a>
        </li>
    );
};

const ListItem2 = ({
    tabName,
    activeTab,
    toggleTab,
    readCount,
    unreadCount,
}: {
    tabName: IstatusTab;
    activeTab: string;
    toggleTab: (tabName: IstatusTab) => void;
    readCount: string;
    unreadCount: number;
}) => {
    return (
        <div
            className={`${
                activeTab === tabName ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
            }  flex gap-2 cursor-pointer  border  ${tabName === 'all' ? 'rounded-full' : 'rounded-3xl'} py-1.5 px-2 capitalize`}
            onClick={() => toggleTab(tabName)}
        >
            {tabName}
            {tabName !== 'all' && (
                <p className={`px-2 ${activeTab === tabName ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'} border rounded-full`}>
                    {tabName === 'read' ? readCount : unreadCount}
                </p>
            )}
        </div>
    );
};

const Notification = () => {
    const {
        notificationTab: activeTab,
        notificationStatusTab,
        query,
        newNotification,
        notificationId,
    } = useSelector((state: RootState) => state.notificationReducer);

    const { data: readCount, refetch } = useGetNotificationQuery(notificationContext(activeTab, true), { refetchOnMountOrArgChange: true });
    const { data: unreadCount, refetch: Refetch } = useGetNotificationQuery(notificationContext(activeTab, false), {
        refetchOnMountOrArgChange: true,
    });
    const [unread, setUnread] = useState(0);
    const tabList: IContextTab[] = ['all', 'order', 'payment', 'invoice', 'feed', 'reminder'];
    const statusList: IstatusTab[] = ['all', 'read', 'unread'];
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const toggleTab = (tabName: IContextTab) => {
        dispatch(toggleNotificationTab(tabName));
        dispatch(toggleNotificationStatusTab('all'));
        dispatch(setQuery({ page: 1, limit: 10 }));
        refetch();
        Refetch();
    };
    const toggleStatus = (tabName: IstatusTab) => {
        dispatch(toggleNotificationStatusTab(tabName));
    };

    useEffect(() => {
        dispatch(retrieveNotification(query));
    }, [dispatch, activeTab, notificationStatusTab, query]);

    const updateQuery = (query: { limit: number; page: number }) => {
        dispatch(setQuery(query));
    };

    useEffect(() => {
        if (newNotification !== null && newNotification?.context_data?.id?.toLowerCase() !== notificationId) {
            if (
                (newNotification?.context_data?.context?.toLowerCase() === activeTab && notificationStatusTab !== 'read') ||
                (newNotification?.context_data?.context?.toLowerCase() !== activeTab && activeTab === 'all' && notificationStatusTab !== 'read')
            ) {
                setUnread(unread + 1);
                dispatch(setNotificationId(newNotification?.context_data?.id?.toLowerCase()));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, newNotification, notificationStatusTab, notificationId]);

    useEffect(() => {
        setUnread(unreadCount);
    }, [unreadCount]);

    return (
        <WrapperVendor>
            <div className="grid justify-center mt-4 ">
                <div className="flex text-[#5E5BA7] cursor-pointer" onClick={() => navigate(-1)}>
                    <img src={LeftArrow} alt="leftarrow" className="pr-2" />
                    Back
                </div>
                <div className="flex justify-between border-b  mb-[1.5rem] mt-4 w-[600px]">
                    <ul className="flex">
                        {tabList.map((tab) => (
                            <ListItem tabName={tab} activeTab={activeTab} toggleTab={toggleTab} key={tab} />
                        ))}
                    </ul>
                </div>
                <div className="w-full flex gap-6 mb-6">
                    {statusList.map((tab: IstatusTab) => (
                        <ListItem2
                            tabName={tab}
                            activeTab={notificationStatusTab}
                            toggleTab={toggleStatus}
                            key={tab}
                            readCount={readCount}
                            unreadCount={unread}
                        />
                    ))}
                </div>
                <div className="bg-white pt-2">
                    <NotificationBox />
                </div>
                <Pagination query={query} setQuery={updateQuery} count={notificationStatusTab === 'read' ? readCount : unreadCount} />
            </div>
        </WrapperVendor>
    );
};

export default Notification;
