import { isEmpty } from 'lodash';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import useGetRoles from '../hooks/useGetRoles';
import { RootState } from '../redux/store';

const GuestRoute: FC = (props: any) => {
    const { children } = props;
    const location = useLocation();
    const { user_role } = useGetRoles();

    const { authorization } = useSelector((state: RootState) => state.authStore);

    if (!isEmpty(authorization.access_token)) {
        if (user_role == 'buying_officer') {
            return <Navigate to="/order" state={{ from: location }} />;
        }
        if (user_role == 'finance_officer') {
            return <Navigate to="/payment" state={{ from: location }} />;
        }
        return <Navigate to="/dashboard" state={{ from: location }} />;
    }

    return children;
};

export default GuestRoute;
