import persistReducer from 'redux-persist/es/persistReducer';
import storageSession from 'redux-persist/lib/storage/session';

import { initialInvoicesState, initialSingleInvoicestate } from '../dtos';
import { IInvoices, InvoiceType } from '../interfaces';
import { types } from './InvoiceActionTypes';

export const listMyInvoicesReducer = (state: IInvoices = initialInvoicesState, action: any) => {
    switch (action.type) {
        case types.setIStatus:
            return { ...state, currentStatus: action.payload };
        case types.setActiveTab:
            return { ...state, activeTab: action.payload };
        case types.setIIsCustomer:
            return { ...state, iscustomer: action.payload };
        default:
            return state;
    }
};

export const listCustomerInvoicesReducer = persistReducer(
    { storage: storageSession, key: 'invoice-activeTab', whitelist: ['iscustomer', 'activeTab'] },
    (state: IInvoices = initialInvoicesState, action: any) => {
        switch (action.type) {
            case types.setCustomerIStatus:
                return { ...state, currentStatus: action.payload };
            case types.setActiveTab:
                return { ...state, activeTab: action.payload };
            case types.setIIsCustomer:
                return { ...state, iscustomer: action.payload };
            default:
                return state;
        }
    },
);

export const getInvoiceReducer = persistReducer(
    { storage: storageSession, key: 'jumble-single-invoice', whitelist: ['iscustomer'] },
    (state: InvoiceType = initialSingleInvoicestate, action: any) => {
        switch (action.type) {
            case types.setIIsCustomer:
                return { ...state, iscustomer: action.payload };
            default:
                return state;
        }
    },
);

export const invoice = {
    listMyInvoicesReducer,
    listCustomerInvoicesReducer,
    getInvoiceReducer,
};
