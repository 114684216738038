import useGetRoles from '../hooks/useGetRoles';
import { COMPANYPERMISSIONS, USERPERMISSIONS } from '../utils/permission';

export const hasPermission = ({ permissions, scopes }: any) => {
    const scopesMap: any = {};
    scopes.forEach((scope: any) => {
        scopesMap[scope] = true;
    });

    return permissions?.some((permission: any) => scopesMap[permission]);
};

export default function PermissionsGate({ children, scopes = [] }: any) {
    const { user_role, company_type } = useGetRoles();
    const userpermissions = USERPERMISSIONS[user_role];
    const companypermissions = COMPANYPERMISSIONS[company_type];
    const userPermissionGranted = hasPermission({ permissions: userpermissions, scopes });
    const companyPermissionGranted = hasPermission({ permissions: companypermissions, scopes });
    if (userPermissionGranted && companyPermissionGranted) return <>{children}</>;
    return <></>;
}

export const managePermissions = (user_role: any, company_type: any, rightTab: any, leftTab: any) => {
    const userpermissions = USERPERMISSIONS[user_role];
    const companypermissions = COMPANYPERMISSIONS[company_type];
    const userPermissionGranted = hasPermission({ permissions: userpermissions, scopes: [rightTab] });
    const companyPermissionGranted = hasPermission({ permissions: companypermissions, scopes: [rightTab] });
    const userPermissionGranted2 = hasPermission({ permissions: userpermissions, scopes: [leftTab] });
    const companyPermissionGranted2 = hasPermission({ permissions: companypermissions, scopes: [leftTab] });
    return userPermissionGranted && companyPermissionGranted && !(userPermissionGranted2 && companyPermissionGranted2);
};
