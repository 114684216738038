import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';

import NotFound from '../../../assets/images/png/no-image.jpg';
import LeftArrow from '../../../assets/images/svg/arrow-left.svg';
import RedDelete from '../../../assets/images/svg/redDelete.svg';
import XIcon from '../../../assets/images/svg/xicon.svg';
import PopUpModal from '../../../components/PopModal/PopUpModal';
import { useDeleteGroupMutation, useGetCustomersQuery, useReactivateGroupMutation, useSuspendGroupMutation } from '../../../redux/services';
import { RootState } from '../../../redux/store';
import { transformedDateWithTime } from '../../../utils/utils';
import Customerslist from './Customerslist';
import GroupAction from './GroupActionModal';

export type CustomerQuery = {
    page: number;
    limit: number;
    addedToGroup: boolean;
    search_query: string;
};
export const initialCustomerQuery: CustomerQuery = {
    page: 1,
    limit: 12,
    addedToGroup: true,
    search_query: '',
};
const ExpandedGroupHeader = ({ data }: any) => {
    const [query, setQuery] = useState<CustomerQuery>(initialCustomerQuery);
    const [suspendGroup, { isLoading: suspendloading, isSuccess, isError, error }] = useSuspendGroupMutation();
    const [deleteGroup, { isLoading: deleteloading, isSuccess: issuccess, isError: iserror, error: err }] = useDeleteGroupMutation();
    const [reactivateGroup, { isLoading: reactivateloading, isSuccess: Success, isError: Iserror, error: Err }] = useReactivateGroupMutation();
    const { customers } = useSelector((state: RootState) => state.globalReducer);
    const { data: customerslist } = useGetCustomersQuery(query);

    const navigate = useNavigate();

    const handleChange = (e: any) => {
        setQuery({ ...query, search_query: e.target.value, page: 1 });
    };
    useEffect(() => {
        if (isSuccess) {
            toast.success('Group suspended successfully');
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
        }
    }, [error, isError, suspendloading, isSuccess]);

    useEffect(() => {
        if (issuccess) {
            toast.success('Group deleted successfully');
            navigate(-1);
        }
        if (iserror && err && 'status' in err) {
            toast.error(err?.data?.message);
        }
    }, [err, iserror, issuccess, deleteloading, navigate]);

    useEffect(() => {
        if (Success) {
            toast.success('Group reactivated successfully');
        }
        if (Iserror && Err && 'status' in Err) {
            toast.error(Err?.data?.message);
        }
    }, [Err, Iserror, Success, reactivateloading]);

    return (
        <div className="mt-8">
            <Link to="/vendorlist">
                <div className="flex text-[#5E5BA7]">
                    <img src={LeftArrow} alt="leftarrow" className="pr-2" />
                    Back
                </div>
            </Link>

            <div className="mt-8 mb-4 grid  grid-cols-4">
                <div className="col-span-1">
                    <div className="text-sm flex gap-2 col-span-4 rounded-full">
                        <div className="flex gap-2 items-center  rounded-full">
                            <img src={data.logo ? data.logo : NotFound} alt="avatar" className="h-[3.2rem] w-[3.2rem] rounded-full object-contain" />
                        </div>{' '}
                        <div className="text-[#525171]  text-[18px] flex items-center">{data.name}</div>
                    </div>
                </div>
                <div className="col-span-1">
                    <div className="font-bold text-[#525171] text-[16px]">Date Created</div>
                    <div className="text-[#525171] text-[16px]">{transformedDateWithTime(data.created_at)}</div>
                </div>

                <div className="col-span-1">
                    <div className="font-bold text-[#525171] text-[16px]">Status</div>
                    <div className=" grid justify-start">
                        {data.status === 'active' ? (
                            <div className=" flex items-center capitalize gap-2 text-[#084F21] text-sm bg-[#DEEAE2] py-1 px-5 rounded-2xl h-[30px]">
                                <div className="h-3 w-3 rounded-full border border-[#327a4b] object-contain">
                                    <div className="h-2 w-2 border rounded-full bg-[#327a4b] my-[1px] mx-auto" />
                                </div>
                                {data.status}
                            </div>
                        ) : (
                            <div className=" flex items-center gap-2 text-[#840B0B] text-sm bg-[#F5E7E7] py-1 px-5 rounded-2xl h-[30px]">
                                Suspended
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <hr className="mb-4" />

            <div className="mt-4 grid justify-end mb-4">
                <div className="flex gap-4 items-center mr-4">
                    <Popup
                        trigger={
                            <div className="flex gap-2 items-center mt-[36px] mb-8 cursor-pointer text-[#5E5BA7]">
                                <i className="fa-solid fa-user-plus"></i>
                                <p onClick={() => setQuery({ ...query, addedToGroup: false })} className=" font-semibold">
                                    {' '}
                                    Add new customers
                                </p>
                            </div>
                        }
                        position="right center"
                        modal
                        nested
                        contentStyle={{ width: 'fit-content' }}
                    >
                        {(close: () => void) => (
                            <Customerslist
                                close={close}
                                data={customerslist}
                                handleChange={handleChange}
                                query={query}
                                setQuery={setQuery}
                                customers={customers}
                                isnew={true}
                                group_id={data.id}
                            />
                        )}
                    </Popup>
                    {data.status !== 'active' ? (
                        <PopUpModal
                            content={
                                <div
                                    className="flex gap-1 items-center justify-center rounded-xl  py-[10px] px-[8px] text-[#5E5BA7] text-[16px] cursor-pointer"
                                    style={{ border: '1px solid #5E5BA7' }}
                                >
                                    <i className="fa fa-check"></i>Reactivate group
                                </div>
                            }
                            className=""
                            popupcontent={false}
                            onClose={close}
                        >
                            {(close: () => void) => (
                                <GroupAction
                                    action={reactivateGroup}
                                    id={data.id}
                                    close={close}
                                    actionName={'reactivate'}
                                    actionTitle={'GROUP'}
                                    name={data.name}
                                    loader={reactivateloading}
                                />
                            )}
                        </PopUpModal>
                    ) : (
                        <PopUpModal
                            content={
                                <div
                                    className="flex gap-1 rounded-xl items-center  py-[10px] px-[8px] text-[#5E5BA7] text-[16px] cursor-pointer"
                                    style={{ border: '1px solid #5E5BA7' }}
                                >
                                    <img src={XIcon} alt="xicon" width={16} height={16} /> Suspend group
                                </div>
                            }
                            className=""
                            popupcontent={false}
                            onClose={close}
                        >
                            {(close: () => void) => (
                                <GroupAction
                                    action={suspendGroup}
                                    id={data.id}
                                    close={close}
                                    actionName={'suspend'}
                                    actionTitle={'GROUP'}
                                    name={data.name}
                                    loader={suspendloading}
                                />
                            )}
                        </PopUpModal>
                    )}
                    <PopUpModal
                        content={
                            <div
                                className="flex gap-1 rounded-xl items-center  py-[10px] px-[8px] text-[#840B0B] text-[16px] mr-2 cursor-pointer"
                                style={{ border: '1px solid #BF5E5E' }}
                            >
                                <img src={RedDelete} alt="reddeleteicon" width={16} height={16} /> Delete group
                            </div>
                        }
                        className=""
                        popupcontent={false}
                        onClose={close}
                    >
                        {(close: () => void) => (
                            <GroupAction
                                action={deleteGroup}
                                id={data.id}
                                close={close}
                                actionName={'delete'}
                                actionTitle={'GROUP'}
                                name={data.name}
                                loader={deleteloading}
                            />
                        )}
                    </PopUpModal>
                </div>
            </div>
        </div>
    );
};

export default ExpandedGroupHeader;
