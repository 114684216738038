import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import NotFound from '../../../assets/images/png/no-image.jpg';
import avatar from '../../../assets/images/png/noImage.png';
import ArrowDown from '../../../assets/images/svg/arrow-down.svg';
import LeftArrow from '../../../assets/images/svg/arrow-left.svg';
import Pagination from '../../../components/Pagination/Pagination';
import PopUpModal from '../../../components/PopModal/PopUpModal';
import Loading from '../../../components/UI/Spinner/Loading';
import Wrapper from '../../../HOC/Wrapper';
import { initialSingleInvoicestate, initialSingleOrderState } from '../../../redux/features/dtos';
import { OrderItemsType } from '../../../redux/features/interfaces';
import { decrement, increment, remove, updateqty } from '../../../redux/features/order/OrderActions';
import { useGetAnInvoiceQuery } from '../../../redux/services';
import { useGetAnOrderQuery } from '../../../redux/services/orderApi';
import { RootState } from '../../../redux/store';
import { toLocaleFixed, transformedDateWithTime } from '../../../utils/utils';
import { IPaginationQuery, paginationQuery } from '../../invoice/ExpandedInvoice/ExpandedInvoice';
import EmptySearch from '../../vendorlist/EmptyContent/EmptySearch';
import OrderItemDetails from '../OrderItemDetails';
import OrderActions from './OrderActions';
const _ = require('lodash');
import './styles.css';

const PurchaseOrder = () => {
    const dispatch = useDispatch();

    const [itemqty, setItemQty] = useState<number[]>([]);
    const [skip, setSkip] = useState(true);
    const [maxQtyFetched, setMaxQtyFetched] = useState(true);

    const navigate = useNavigate();
    const id = useParams()?.id as string;
    const { iscustomer } = useSelector((state: RootState) => state.getOrderReducer);
    const { data: orderdetails = initialSingleOrderState, isFetching } = useGetAnOrderQuery(id);
    const { order_items: items } = useSelector((state: RootState) => state.getOrderReducer);

    const [query, setQuery] = useState<IPaginationQuery>(paginationQuery);
    const { ordering_company, vendor, invoice_id, invoice_status, created_at, status, order_no, invoice_issued, order_items, cancel_reason } =
        orderdetails;
    const { data: invoicedetails = initialSingleInvoicestate, isLoading } = useGetAnInvoiceQuery(invoice_id, {
        skip,
    });
    const { invoice_no, status: invoiceStatus, created_at: invoiceDate } = invoicedetails;
    const [q, setQ] = useState('');

    const orderItems = items.map(function (value: any, index: number) {
        return [value, itemqty[index]];
    });

    useEffect(() => {
        ((iscustomer && status === 'unfulfilled') || status === 'fulfilled' || invoice_issued) && setSkip((prev) => !prev);
    }, [iscustomer, invoice_issued, status]);

    useEffect(() => {
        dispatch({ type: 'SET_ORDER_ITEMS', payload: order_items });
    }, [dispatch, order_items]);

    useEffect(() => {
        if (maxQtyFetched) setItemQty(items.map((item: OrderItemsType) => item.qty));
        setItemQty((state) => {
            if (!_.isEqual(state, [])) {
                setMaxQtyFetched(false);
            }
            return state;
        });
    }, [maxQtyFetched, items]);

    const OrderItems = useMemo(() => {
        return orderItems?.filter((item: any) => {
            if (q === '') {
                return item;
            } else if (item[0].catalogue.product.name.toLowerCase().includes(q.toLowerCase())) {
                return item;
            }
        });
    }, [q, orderItems]);

    const paginatedOrderItems = useMemo(() => {
        const startIndex = query.page * query.limit - query.limit;
        const endIndex = startIndex + query.limit;
        return OrderItems.slice(startIndex, endIndex);
    }, [OrderItems, query.page, query.limit]);

    return isFetching || isLoading ? (
        <div className="min-h-screen flex justify-center flex-col">
            <Loading />
        </div>
    ) : (
        <Wrapper>
            <div className="mt-8">
                <div onClick={() => navigate(-1)} className="cursor-pointer w-[5rem] ">
                    <div className="flex text-[#5E5BA7]">
                        <img src={LeftArrow} alt="leftarrow" className="pr-2" />
                        Back
                    </div>
                </div>

                <div className="flex items-center justify-between mt-[1.35rem] mb-12">
                    <h3 className="font-semibold text-[21px]"> {iscustomer ? 'Customer Order' : 'My Order'}</h3>
                </div>
                {/* mt-8 mb-4 grid  grid-cols-6 gap-12 */}
                <div className="order__header__container">
                    <div className="col-span-2">
                        <div className="text-sm  flex gap-2 ">
                            <div className="flex space-x-2">
                                <div className=" w-12 h-12">
                                    <img
                                        className="rounded-full border border-gray-100 shadow-sm"
                                        src={
                                            iscustomer
                                                ? vendor.image
                                                    ? vendor.image
                                                    : `${avatar}`
                                                : ordering_company.image
                                                ? ordering_company.image
                                                : `${avatar}`
                                        }
                                        alt="user image"
                                    />{' '}
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="text-[#131221] text-[16px]">{iscustomer ? ordering_company.name : vendor.name}</div>
                                <div className="text-[14px] text-[#706F77]"> {iscustomer ? ordering_company.company_type : vendor.company_type}</div>
                                <div className="text-[14px] text-[#706F77]"> {iscustomer ? ordering_company.address : vendor.address}.</div>
                                <div className="text-[14px] text-[#706F77]">{iscustomer ? ordering_company.phone_number : vendor.phone_number}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div>
                            <div className="font-bold text-[#525171] text-[16px]">Order date:</div>
                            <div className="text-[#525171] text-[16px]">{transformedDateWithTime(created_at)}</div>
                        </div>

                        <div className="mt-6">
                            <div className="font-bold text-[#525171] text-[16px]">Order number:</div>
                            <div className="text-[#525171] text-[16px]">
                                <span className="font-bold text-[#131221]">ORD</span>-{order_no}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div>
                            <div className="font-bold text-[#525171] text-[16px]">Status</div>
                            <div className=" grid justify-start">
                                <div
                                    className={`
                                      ${
                                          status === 'unfulfilled'
                                              ? 'text-[#084F21] bg-green-100'
                                              : status === 'fulfilled'
                                              ? 'text-[#084F21] bg-green-400'
                                              : status === 'pending'
                                              ? 'bg-amber-100 text-[#D29C17]'
                                              : '  bg-red-100 text-[#840B0B]'
                                      } py-2 px-5 rounded-2xl flex gap-1`}
                                >
                                    <p className="text-sm ">{status}</p>
                                </div>
                            </div>
                        </div>

                        {status === 'cancelled' && (
                            <div>
                                <div className="mt-3">
                                    <div className="font-bold text-[#525171] text-[16px]"> Reasons for cancelling</div>
                                    <div className="text-[#525171] text-[16px]">{cancel_reason ? cancel_reason : 'Reasons not provided'} </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-span-2">
                        <div>
                            <div className="font-bold text-[#525171] text-[16px]">Total Order value:</div>
                            <div className="text-[#131221] text-[25px] font-black">
                                N{' '}
                                {toLocaleFixed(
                                    order_items.reduce((total: number, item: OrderItemsType) => item.qty * item.buying_price + total, 0),
                                    2,
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="mb-4" />
                <div className="mt-4 mb-4 flex justify-end">
                    <OrderActions
                        status={status}
                        invoice_status={invoice_status}
                        id={id}
                        iscustomer={iscustomer}
                        order_no={order_no}
                        order_items={order_items}
                        name={vendor.name}
                        address={vendor.address}
                        phone={vendor.phone_number}
                        vendor={vendor}
                        created_at={invoiceDate}
                        invoice_no={invoice_no}
                        invoiceStatus={invoiceStatus}
                        invoice_id={invoice_id}
                        invoice_issued={invoice_issued}
                        ordering_company={ordering_company}
                    />
                </div>
                <div className="flex">
                    <div className="text-[#5E5BA7] text-[16px] ">
                        You have <span className="font-bold">{order_items.length}</span> items
                    </div>

                    <div className="flex items-center relative" style={{ marginLeft: 'auto' }}>
                        <div className="w-[7.7rem] h-[2.3rem] relative mr-[45px]">
                            <i className="fa fa-search icon absolute top-1/2 -translate-y-1/2 px-[0.79rem]" />
                            <input
                                className="w-full bg-[#F8F8FB] border-b-2 focus:outline-none pl-[2.08rem] h-full placeholder:text-[0.78165rem] text-[0.78125rem] text-jumbleng-gray placeholder:text-jumbleng-gray"
                                placeholder="Search products"
                                onChange={(e) => setQ(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className=" p-5 mt-5">
                    <div className="grid grid-cols-8 pt-4 bg-white p-5 font-bold">
                        <div className="jumbleng-gray-main text-base col-span-1 ">SKU</div>
                        <div className="jumbleng-gray-main text-base grid  col-span-1 ">Items Ordered</div>
                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1">Quantity</div>
                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1"> UOM</div>

                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1">Price</div>
                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1">
                            <div className="flex gap-2 items-center">
                                <p> VAT</p>
                                <img src={ArrowDown} alt="a-down" />
                            </div>
                        </div>
                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1">Total Order Value</div>
                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1"></div>
                    </div>

                    {isEmpty(paginatedOrderItems) ? (
                        <EmptySearch content="Item" />
                    ) : (
                        paginatedOrderItems.map((item: any, index: number) => (
                            <div
                                className={`grid grid-cols-8 pt-4 p-5 gap-1 cursor-pointer  hover:bg-[#5D5BA7] hover:text-white items-center ${
                                    index % 2 == 0 ? 'bg-[#fff]' : 'bg-[#F9F9FA]'
                                }`}
                                key={item[0].id}
                            >
                                <div className="text-sm col-span-1 flex gap-2 ">
                                    <div className="grid content-center">
                                        <input type="checkbox" />
                                    </div>
                                    <div className="grid content-center">
                                        <div>{item[0].catalogue.product.sku}</div>
                                    </div>
                                </div>
                                <div className="text-sm col-span-1">
                                    <PopUpModal
                                        content={
                                            <div className="flex gap-2  items-center ">
                                                <img
                                                    src={item[0].catalogue.product.picture ? item[0].catalogue.product.picture : NotFound}
                                                    alt="avatar"
                                                    className="h-[2.2rem] w-[2.2rem] object-contain"
                                                />
                                                <span className="truncate w-[135px] "> {item[0].catalogue.product.name}</span>
                                            </div>
                                        }
                                        className="text-sm grid "
                                        popupcontent={true}
                                    >
                                        {(close: () => void) => (
                                            <div>
                                                <button className="close" onClick={close}>
                                                    &times;
                                                </button>
                                                <OrderItemDetails
                                                    Props={{
                                                        created_at: item[0].created_at,
                                                        order_no: order_no,
                                                        iscustomer: iscustomer,
                                                        id: id,
                                                        order_items: order_items,
                                                        qty: item[0].qty,
                                                        product: item[0].catalogue.product,
                                                        status: status,
                                                        images: item[0].catalogue.product.images,
                                                        picture: item[0].catalogue.product.picture,
                                                        ordering_company: ordering_company,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </PopUpModal>
                                </div>
                                <div className="text-sm grid justify-center col-span-1">
                                    <div className="flex">
                                        <span className="cursor-default z-50 px-2" onClick={() => dispatch(decrement(item[0].id))}>
                                            -
                                        </span>
                                        <input
                                            onChange={(e) => dispatch(updateqty(item[0].id, e.target.value))}
                                            className="ml-4 mr-4 pl-2 pr-2 text-center w-12 focus:outline-none"
                                            style={{
                                                border: '1px solid #706F77',
                                                color: 'black',
                                            }}
                                            value={item[0].qty}
                                        />
                                        {!iscustomer || item[0].qty < item[0]?.catalogue?.product?.max_order ? (
                                            <span className="cursor-default z-50 px-2" onClick={() => dispatch(increment(item[0].id))}>
                                                +
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                                <div className="text-sm grid justify-center col-span-1">{item[0].catalogue.product.uom}</div>
                                <div className="text-sm grid justify-center col-span-1">₦ {toLocaleFixed(item[0].buying_price, 2)}</div>
                                <div className="text-sm grid justify-center col-span-1">
                                    {item[0].vat_percent
                                        ? item[0].vat_percent + '%'
                                        : item[0].catalogue.product.vat
                                        ? item[0].catalogue.product.vat + '%'
                                        : '-'}
                                </div>
                                <div className="text-sm grid justify-center col-span-1">
                                    ₦ {toLocaleFixed(item[0].qty * Number(item[0].buying_price), 2)}
                                </div>
                                <div className="text-sm grid justify-center col-span-1" onClick={() => dispatch(remove(item[0].id))}>
                                    <i className="fa fa-circle-minus fa-outline text-white "></i>
                                </div>
                            </div>
                        ))
                    )}
                </div>
                <Pagination query={query} setQuery={setQuery} data={OrderItems} count={OrderItems.length} />
            </div>
        </Wrapper>
    );
};

export default PurchaseOrder;
