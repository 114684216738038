import EmptyRequest from '../../../assets/images/svg/emptyRequest.svg';

const EmptyMembers = () => {
    return (
        <div className="p-2 pt-0 flex flex-col h-full mt-8">
            {/* EMPTY CROUP */}
            <div className="grid justify-center">
                <img src={EmptyRequest} alt="empty" />
            </div>
            <div className="grid justify-center mt-4">
                <p className="text-[28px] grid justify-center text-center">No members added yet</p>
                <div className="grid justify-center mt-4  md:w-[376px]">
                    <p className="grid justify-center text-center">
                        There are no members in this group, you can add new customers to the group to have them displayed here.
                    </p>
                </div>
            </div>

            <div></div>
        </div>
    );
};

export default EmptyMembers;
