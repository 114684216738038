import axios from 'axios';
import { ErrorMessage, Field, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ButtonLoad from '../../components/ButtonLoad/ButtonLoad';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import { ICompanyProfile } from '../../interfaces/schema-interface';
import { getCompanyIndustries } from '../../redux/features/auth/authService';
import { deleteLogo, updateCompanyProfile } from '../../redux/slices/company_profile';
import { RootState } from '../../redux/store';
import { CompanyProfileSchema, initialCompanyProfileValues } from '../../schema/company-details.schema';
import { industryTypesTypes } from '../auth/Registration/companyDetails/CompanyDetails';

const Profile = () => {
    const [error, setError] = useState('');
    const [editable, setEditable] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const authStore = useSelector((state: RootState) => state.authStore);
    const { company } = authStore;
    const [preview, setPreview] = useState(company.logo);
    const dispatch = useDispatch();
    const [industryTypes, setIndustryTypes] = useState<{ key: string; value: string }[]>([]);
    const { loading } = useSelector((state: RootState) => state.companyProfileReducer);

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }
        const objectUrl: any = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    useEffect(() => {
        try {
            const getCredentials = async () => {
                const response = await getCompanyIndustries();
                setPreview(company.logo);
                setIndustryTypes(response);
            };
            getCredentials();
        } catch (e) {
            //flash the reason here
        }
    }, [company.logo]);
    const onSelectFile = async (event: any, setFieldValue: any) => {
        const instance = axios.create();
        delete instance.defaults.headers.common.authorization;
        const formData = new FormData();
        if (!event.target.files) return;
        formData.append('file', event.target.files[0]);
        formData.append('upload_preset', 'jumbleng-test');
        await instance.post('https://api.cloudinary.com/v1_1/dduma7jcf/image/upload', formData).then(({ data }) => {
            setFieldValue('logo', data.secure_url);
        });
        if (!event.target.files || event.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }
        setSelectedFile(event.target.files[0]);
    };

    const handleEditable = (name: string) => {
        setEditable(name);
    };

    const handleOnSubmit = (values: ICompanyProfile) => {
        dispatch(updateCompanyProfile(values));
    };

    const handleDelete = (setFieldValue: any) => {
        company.logo && dispatch(deleteLogo());
        setFieldValue('logo', '');
        setSelectedFile(undefined);
    };

    useEffect(() => {
        const timeId = setTimeout(() => {
            setError('');
        }, 3000);
        return () => {
            clearTimeout(timeId);
        };
    }, [error]);
    return (
        <>
            <LoadingModal />
            <Formik
                onSubmit={handleOnSubmit}
                initialValues={{
                    ...initialCompanyProfileValues,
                    logo: company.logo,
                    name: company.name,
                    email: company.email,
                    address: company.address,
                    phone_number: company.phone_number,
                    industry_type: company.industry_type,
                }}
                validationSchema={CompanyProfileSchema}
            >
                {({ handleSubmit, setFieldValue }) => (
                    <div className="h-full mr-8">
                        <p className="text-jumbleng-primary">Manage your company&apos;s profile</p>
                        <div className="bg-white mt-[1.5rem] p-16 pr-40">
                            <div className=" mb-[2.35rem] w-full">
                                <div className="flex items-center justify-between gap-16 mb-[2.35rem]">
                                    <div className="basis-[50%]">
                                        <p className="text-jumbleng-gray font-semibold">Company logo</p>
                                    </div>
                                    <div className="flex justify-between items-center w-full">
                                        <div className="w-[300px] h-[100px]">
                                            {!selectedFile && !company.logo ? (
                                                <>
                                                    <label htmlFor="imageUpload">
                                                        <div className="flex flex-col  gap-1 p-2 items-center justify-center border-dashed border-jumbleng-primary border-[1px] h-[5rem]">
                                                            <p className="text-[10px] text-jumbleng-gray">Image should be in jpg or png format</p>
                                                            <p className="text-xs text-jumbleng-primary">
                                                                <i className="fa-solid fa-image mr-1"></i>
                                                                Upload Image{' '}
                                                            </p>
                                                        </div>
                                                    </label>
                                                    <input
                                                        id="imageUpload"
                                                        type="file"
                                                        className="w-0.1 h-0.1 overflow-hidden hidden"
                                                        onChange={(event) => {
                                                            if (!event.target.files) return;
                                                            setFieldValue('logo', event.target.files[0]);
                                                            onSelectFile(event, setFieldValue);
                                                        }}
                                                    />
                                                    <ErrorMessage
                                                        name={'logo'}
                                                        render={(msg) => (
                                                            <div className="text-[0.7812rem] text-red-400 text-left font-normal">{msg}</div>
                                                        )}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <img src={preview} alt="profile_image" className="object-contain w-[auto] h-[100px]" />
                                                </>
                                            )}{' '}
                                        </div>
                                        {(selectedFile || company.logo) && (
                                            <div className="flex gap-6 ">
                                                <div className="font-normal text-[1rem] cursor-pointer text-jumbleng-primary ">
                                                    <label htmlFor="imageUpload">
                                                        <i className="fa fa-pencil mr-1"></i>Change
                                                    </label>
                                                    <input
                                                        id="imageUpload"
                                                        type="file"
                                                        className="w-0.1 h-0.1 overflow-hidden hidden"
                                                        onChange={(event) => {
                                                            onSelectFile(event, setFieldValue);
                                                        }}
                                                    />
                                                    <ErrorMessage
                                                        name={'picture'}
                                                        render={(msg) => (
                                                            <div className="text-[0.7812rem] text-red-400 text-left font-normal">{msg}</div>
                                                        )}
                                                    />
                                                </div>
                                                <div
                                                    className="font-normal text-[1rem] text-[#9F0D0D] cursor-pointer"
                                                    onClick={() => handleDelete(setFieldValue)}
                                                >
                                                    <i className="fa fa-trash-can mr-1"></i>Delete
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-between gap-16 mb-[2.35rem]">
                                <div className="basis-[50%]">
                                    <p className="text-jumbleng-gray font-semibold">Company name</p>
                                    <p className="text-sm text-jumbleng-gray mt-2">
                                        This would be displayed on your profile annd be seen by others on the feed.
                                    </p>
                                </div>
                                <div className="mb-[1.953rem]  w-full">
                                    <div className="border mb-1  border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]">
                                        <div className="field__container">
                                            <Field
                                                onKeyDown={(e: any) => {
                                                    e.key === 'Enter' && handleSubmit();
                                                }}
                                                type={`text`}
                                                name={'name'}
                                                disabled={editable !== 'name'}
                                                placeholder={'Modern distributor'}
                                                className={'w-full  placeholder:text-[0.78125rem] focus:outline-none'}
                                            />
                                            {
                                                <i
                                                    className={`${
                                                        editable !== 'name' ? 'text-jumbleng-primary' : 'text-jumbleng-primary-light'
                                                    } fa-solid fa-edit mr-6`}
                                                    onClick={() => handleEditable('name')}
                                                ></i>
                                            }
                                            {error && <i className="fa-solid  fa-circle-exclamation text-[20px] text-red-500"></i>}
                                        </div>
                                    </div>
                                    <ErrorMessage
                                        name={'name'}
                                        render={(msg) => <div className="text-[0.7812rem] text-red-600 text-left font-normal">{msg}</div>}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-between gap-16 mb-[2.35rem]">
                                <div className="basis-[50%]">
                                    <p className="text-jumbleng-gray font-semibold">Company email</p>
                                </div>
                                <div className="mb-[1.953rem]  w-full">
                                    <div className="border mb-1  border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]">
                                        <div className="field__container">
                                            <Field
                                                onKeyDown={(e: any) => {
                                                    e.key === 'Enter' && handleSubmit();
                                                }}
                                                type="text"
                                                disabled={editable !== 'email'}
                                                name={'email'}
                                                placeholder={'Morderndistrubutorsltd@info.org'}
                                                className={'w-full  placeholder:text-[0.78125rem] focus:outline-none'}
                                            />
                                            {
                                                <i
                                                    className={`${
                                                        editable !== 'email' ? 'text-jumbleng-primary' : 'text-jumbleng-primary-light'
                                                    } fa-solid fa-edit mr-6`}
                                                    onClick={() => handleEditable('email')}
                                                ></i>
                                            }
                                            {error && <i className="fa-solid  fa-circle-exclamation text-[20px] text-red-500"></i>}
                                        </div>
                                    </div>
                                    <ErrorMessage
                                        name={'email'}
                                        render={(msg) => <div className="text-[0.7812rem] text-red-600 text-left font-normal">{msg}</div>}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-between gap-16 mb-[2.35rem]">
                                <div className="basis-[50%]">
                                    <p className="text-jumbleng-gray font-semibold">Company phone</p>
                                </div>
                                <div className="mb-[1.953rem]  w-full">
                                    <div className="border mb-1  border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]">
                                        <div className="field__container">
                                            {<i className={`text-gray-500 fa-solid fa-mobile-screen-button`}></i>}
                                            <Field
                                                onKeyDown={(e: any) => {
                                                    e.key === 'Enter' && handleSubmit();
                                                }}
                                                disabled={editable !== 'tel'}
                                                type={`tel`}
                                                name={'phone_number'}
                                                placeholder={'+234 767 1647 02020'}
                                                className={'w-full  placeholder:text-[0.78125rem] focus:outline-none'}
                                            />
                                            {
                                                <i
                                                    className={`${
                                                        editable !== 'tel' ? 'text-jumbleng-primary' : 'text-jumbleng-primary-light'
                                                    } fa-solid fa-edit mr-6`}
                                                    onClick={() => handleEditable('tel')}
                                                ></i>
                                            }
                                            {error && <i className="fa-solid  fa-circle-exclamation text-[20px] text-red-500"></i>}
                                        </div>
                                    </div>
                                    <ErrorMessage
                                        name={'phone_number'}
                                        render={(msg) => <div className="text-[0.7812rem] text-red-600 text-left font-normal">{msg}</div>}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-between gap-16 mb-[2.35rem]">
                                <div className="basis-[50%]">
                                    <p className="text-jumbleng-gray font-semibold">Company industry</p>
                                </div>
                                <div className="mb-[1.953rem]  w-full">
                                    <div className="border mb-1  border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]">
                                        <div className="field__container ">
                                            <Field
                                                name={'industry_type'}
                                                as={'select'}
                                                disabled={editable !== 'industry_type'}
                                                className=" w-full focus:outline-none p-[0.649rem]"
                                            >
                                                <option value="">-- Select --</option>
                                                {industryTypes &&
                                                    industryTypes?.map((value: industryTypesTypes, index: number) => (
                                                        <option key={index} value={value.key}>
                                                            {value.value}
                                                        </option>
                                                    ))}{' '}
                                            </Field>
                                            {
                                                <i
                                                    className={`${
                                                        editable !== 'industry_type' ? 'text-jumbleng-primary' : 'text-jumbleng-primary-light'
                                                    } fa-solid fa-edit mr-6`}
                                                    onClick={() => handleEditable('industry_type')}
                                                ></i>
                                            }
                                            {error && <i className="fa-solid  fa-circle-exclamation text-[20px] text-red-500"></i>}
                                        </div>
                                    </div>
                                    <ErrorMessage
                                        name={'industry_type'}
                                        render={(msg) => <div className="text-[0.7812rem] text-red-600 text-left font-normal">{msg}</div>}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-between gap-16 mb-[2.35rem]">
                                <div className="basis-[50%]">
                                    <p className="text-jumbleng-gray font-semibold">Company adddress</p>
                                </div>
                                <div className="mb-[1.953rem]  w-full">
                                    <div className="border mb-1  border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]">
                                        <div className="field__container">
                                            <Field
                                                onKeyDown={(e: any) => {
                                                    e.key === 'Enter' && handleSubmit();
                                                }}
                                                disabled={editable !== 'address'}
                                                type="text"
                                                name={'address'}
                                                placeholder={' 3 Salvation Rd, Opebi 100271, Ikeja'}
                                                className={'w-full  placeholder:text-[0.78125rem] focus:outline-none'}
                                            />
                                            {
                                                <i
                                                    className={`${
                                                        editable !== 'address' ? 'text-jumbleng-primary' : 'text-jumbleng-primary-light'
                                                    } fa-solid fa-edit mr-6`}
                                                    onClick={() => handleEditable('address')}
                                                ></i>
                                            }
                                            {error && <i className="fa-solid  fa-circle-exclamation text-[20px] text-red-500"></i>}
                                        </div>
                                    </div>
                                    <ErrorMessage
                                        name={'address'}
                                        render={(msg) => <div className="text-[0.7812rem] text-red-600 text-left font-normal">{msg}</div>}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end items-center gap-6">
                                {editable && (
                                    <div
                                        onClick={() => handleEditable('')}
                                        className="flex rounded-xl cursor-pointer  items-center justify-center border border-jumbleng-primary  px-[1.18rem] py-[0.7rem] w-[10rem]"
                                    >
                                        <div className="font-normal text-[1rem] text-jumbleng-primary ">
                                            <i className="fa fa-xmark mr-2"></i>Cancel
                                        </div>
                                    </div>
                                )}
                                <ButtonLoad loading={loading} onClick={handleSubmit} content="Save" />
                            </div>
                            <p className="mt-2 text-[#9F0D0D] font-semibold">Deactivate account</p>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    );
};

export default Profile;
