import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';

import NotFound from '../../../assets/images/png/no-image.jpg';
import More from '../../../assets/images/svg/More.svg';
import Filter from '../../../assets/images/svg/sliders.svg';
import Pagination from '../../../components/Pagination/Pagination';
import PopUpModal from '../../../components/PopModal/PopUpModal';
import Loading from '../../../components/UI/Spinner/Loading';
import { useDeleteGroupMutation, useGetGroupsQuery, useReactivateGroupMutation, useSuspendGroupMutation } from '../../../redux/services';
import { RootState } from '../../../redux/store';
import { transformedDateFull } from '../../../utils/utils';
import EmptySearch from '../EmptyContent/EmptySearch';
import EmptyGroup from './EmptyGroup';
import GroupAction from './GroupActionModal';

export type groupQueryType = { page: number; limit: number; search_query: string };
export const initialGroupQuery: groupQueryType = {
    page: 1,
    limit: 10,
    search_query: '',
};

const Group = () => {
    const [query, setQuery] = useState<groupQueryType>(initialGroupQuery);
    const { data, isFetching } = useGetGroupsQuery(query);
    const { company } = useSelector((state: RootState) => state.authStore);
    const [suspendGroup, { isLoading, isSuccess, isError, error }] = useSuspendGroupMutation();
    const [deleteGroup, { isLoading: loading, isSuccess: issuccess, isError: iserror, error: err }] = useDeleteGroupMutation();
    const [reactivateGroup, { isLoading: loader, isSuccess: Success, isError: Iserror, error: Err }] = useReactivateGroupMutation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isSuccess) {
            toast.success('Group suspended successfully');
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
        }
    }, [error, isError, isLoading, isSuccess]);

    const handleChange = (e: any) => {
        setQuery({ ...query, search_query: e.target.value, page: 1 });
    };

    useEffect(() => {
        if (issuccess) {
            toast.success('Group deleted successfully');
        }
        if (iserror && err && 'status' in err) {
            toast.error(err?.data?.message);
        }
    }, [err, iserror, issuccess, loading]);

    useEffect(() => {
        if (Success) {
            toast.success('Group reactivated successfully');
        }
        if (Iserror && Err && 'status' in Err) {
            toast.error(Err?.data?.message);
        }
    }, [Err, Iserror, Success, loader]);

    if (isFetching && query.search_query == '') {
        return (
            <div className="min-h-[calc(100vh-20rem)] flex justify-center flex-col">
                <Loading />
            </div>
        );
    }
    return data?.data?.length === 0 && query.search_query === '' ? (
        <EmptyGroup />
    ) : (
        <div>
            <div className="flex">
                <div className="text-[#5E5BA7] text-[16px] ">
                    You have <span className="font-bold">{data?.count}</span> Groups
                </div>

                <div className="flex items-center relative" style={{ marginLeft: 'auto' }}>
                    <div className="w-[7.7rem] h-[2.3rem] relative mr-[45px]">
                        <i className="fa fa-search icon absolute top-1/2 -translate-y-1/2 px-[0.79rem]" />
                        <input
                            className="w-full bg-[#F8F8FB] border-b-2 focus:outline-none pl-[2.08rem] h-full placeholder:text-[0.78165rem] text-[0.78125rem] text-jumbleng-gray placeholder:text-jumbleng-gray"
                            placeholder="Search group"
                            onChange={handleChange}
                        />
                    </div>

                    <div className="flex hover:cursor-pointer text-[0.78125rem] mr-[45px] font-normal text-jumbleng-gray items-center">
                        <span className="mr-[9px]">Filter</span>
                        <img src={Filter} className="w-[1.17rem] h-[1.17rem]" alt="Filter" />
                    </div>
                </div>
            </div>

            <div className=" p-5 mt-5">
                <div className="grid grid-cols-9 pt-4 bg-white p-5   font-bold">
                    <div className="jumbleng-gray-main text-base col-span-3 ">Group Name</div>

                    <div className="jumbleng-gray-main text-base grid justify-center col-span-2">Number of Members</div>
                    <div className="jumbleng-gray-main text-base grid justify-center col-span-2"> Date of Group Creation</div>

                    <div className="jumbleng-gray-main text-base grid justify-center col-span-1">State</div>
                    <div className="jumbleng-gray-main text-base grid justify-center col-span-1">Action</div>
                </div>

                {data?.data?.length === 0 && query.search_query !== '' ? (
                    <EmptySearch content={'groups'} />
                ) : (
                    data?.data.map((group: any, i: number) => (
                        <div className={`grid grid-cols-9 pt-4 p-5 gap-1  ${i % 2 == 0 ? 'bg-[#fff]' : 'bg-[#F9F9FA]'} items-center`} key={i}>
                            <div className="text-sm col-span-3 flex gap-2 ">
                                {' '}
                                <div className="grid content-center">
                                    <input type="checkbox" />
                                </div>
                                <div className="flex gap-2 items-center rounded-full">
                                    <img src={company.logo ? company.logo : NotFound} alt="avatar" className="h-[3.2rem] w-[3.2rem] object-contain" />
                                </div>{' '}
                                <div>
                                    <div>{group.name}</div>
                                    <div> {company.email}</div>
                                </div>
                            </div>
                            <div className="text-sm grid justify-center col-span-2">{group.vendorCount}</div>

                            <div className="text-sm grid justify-center col-span-2">{transformedDateFull(group.created_at)}</div>

                            <div className=" grid justify-center col-span-1">
                                {group.status === 'active' ? (
                                    <div className=" flex items-center capitalize gap-2 text-[#084F21] text-sm bg-[#DEEAE2] py-1 px-5 rounded-2xl h-[30px]">
                                        <div className="h-3 w-3 rounded-full border border-[#327a4b] object-contain">
                                            <div className="h-2 w-2 border rounded-full bg-[#327a4b] my-[1px] mx-auto" />
                                        </div>
                                        {group.status}
                                    </div>
                                ) : (
                                    <div className=" flex items-center gap-2 text-[#840B0B] text-sm bg-[#F5E7E7] py-1 px-5 rounded-2xl h-[30px]">
                                        Suspended
                                    </div>
                                )}
                            </div>

                            <Popup
                                trigger={
                                    <div className="grid justify-center col-span-1">
                                        <img src={More} alt="more" />
                                    </div>
                                }
                                on="hover"
                                closeOnDocumentClick
                                nested
                                arrow={false}
                                contentStyle={{
                                    padding: '10px',
                                    borderRadius: '12px',
                                    width: '167px',
                                }}
                            >
                                {group.status === 'suspended' ? (
                                    <div className=" flex flex-col justify-center text-jumbleng-primary ">
                                        <PopUpModal
                                            content={
                                                <div className=" grid justify-center mt-2 hover:text-jumbleng-gray">
                                                    <div className="grid justify-center hover:text-jumbleng-gray cursor-pointer">
                                                        Reactivate Group
                                                    </div>{' '}
                                                </div>
                                            }
                                            className=""
                                            popupcontent={false}
                                            onClose={close}
                                        >
                                            {(close: () => void) => (
                                                <GroupAction
                                                    action={reactivateGroup}
                                                    id={group.id}
                                                    close={close}
                                                    actionName={'reactivate'}
                                                    actionTitle={'GROUP'}
                                                    name={group.name}
                                                    loader={loader}
                                                />
                                            )}
                                        </PopUpModal>
                                        <PopUpModal
                                            content={
                                                <div className="grid justify-center mt-4 mb-4 hover:text-jumbleng-gray cursor-pointer">
                                                    Delete Group
                                                </div>
                                            }
                                            className=""
                                            popupcontent={false}
                                            onClose={close}
                                        >
                                            {(close: () => void) => (
                                                <GroupAction
                                                    action={deleteGroup}
                                                    id={group.id}
                                                    close={close}
                                                    actionName={'delete'}
                                                    actionTitle={'GROUP'}
                                                    name={group.name}
                                                    loader={loading}
                                                />
                                            )}
                                        </PopUpModal>
                                    </div>
                                ) : (
                                    <div className=" grid justify-center text-jumbleng-primary ">
                                        <div className=" grid justify-center pt-2 hover:text-jumbleng-gray cursor-pointer overflow-hidden">
                                            <p onClick={() => navigate(`/vendorlist/group/${group.id}`)}>View details</p>
                                        </div>

                                        <PopUpModal
                                            content={
                                                <div className="grid justify-center mt-4 hover:text-jumbleng-gray cursor-pointer">Suspend Group</div>
                                            }
                                            className=""
                                            popupcontent={false}
                                            onClose={close}
                                        >
                                            {(close: () => void) => (
                                                <GroupAction
                                                    action={suspendGroup}
                                                    id={group.id}
                                                    close={close}
                                                    actionName={'suspend'}
                                                    actionTitle={'GROUP'}
                                                    name={group.name}
                                                    loader={isLoading}
                                                />
                                            )}
                                        </PopUpModal>
                                        <PopUpModal
                                            content={
                                                <div className="grid justify-center mt-4 mb-2 hover:text-jumbleng-gray cursor-pointer">
                                                    Delete Group
                                                </div>
                                            }
                                            className=""
                                            popupcontent={false}
                                            onClose={close}
                                        >
                                            {(close: () => void) => (
                                                <GroupAction
                                                    action={deleteGroup}
                                                    id={group.id}
                                                    close={close}
                                                    actionName={'delete'}
                                                    actionTitle={'GROUP'}
                                                    name={group.name}
                                                    loader={loading}
                                                />
                                            )}
                                        </PopUpModal>
                                    </div>
                                )}
                            </Popup>
                        </div>
                    ))
                )}
                {data?.data.length !== 0 && <Pagination query={query} setQuery={setQuery} data={data?.data} count={data?.count} />}
            </div>
        </div>
    );
};

export default Group;
