import { FC } from 'react';
import Popup from 'reactjs-popup';

import PlusIcon from '../../../assets/images/svg/cancel.svg';
import PopUpModal from '../../../components/PopModal/PopUpModal';
import { OrderItemsType } from '../../../redux/features/interfaces';
import { companyType } from '../../../redux/features/interfaces';
import ViewInvoice from '../../invoice/InvoicePopUp/ViewInvoice';
import AcceptContent from '../OrderPopUp/AcceptContent';
import CancelContent from '../OrderPopUp/CancelContent';
import MarkFulfilled from '../OrderPopUp/MarkFulfilled';

interface Iprops {
    status: string;
    id: string;
    order_no: string;
    iscustomer?: boolean;
    name: string;
    address: string;
    ordering_company: companyType;
    phone: string;
    order_items: OrderItemsType[];
    vendor: companyType;
    created_at: string;
    invoice_no: string;
    invoiceStatus: string;
    invoice_id: string;
    invoice_issued: boolean;
    invoice_status: string;
}

const OrderActions: FC<Iprops> = ({
    ordering_company,
    status,
    invoice_status,
    id,
    iscustomer,
    order_no,
    order_items,
    name,
    address,
    phone,
    vendor,
    invoice_no,
    invoice_issued,
}) => {
    return (
        <div className="flex justify-end w-full">
            {status === 'pending' ? (
                <div className="flex gap-8 w-[40%] justify-end">
                    {iscustomer && (
                        <PopUpModal
                            content={
                                <div className="flex rounded-xl cursor-pointer  items-center justify-center border border-jumbleng-primary text-jumbleng-primary  px-[1.18rem] py-[0.7rem] w-[10rem]">
                                    <div className="font-normal text-[1rem] ">
                                        <i className="fa fa-check mr-2"></i>Accept Order
                                    </div>
                                </div>
                            }
                            className="w-[9rem]"
                            popupcontent={false}
                        >
                            {(close: () => void) => <AcceptContent id={id} order_no={order_no} close={close} />}
                        </PopUpModal>
                    )}
                    <PopUpModal
                        content={
                            <div className="flex rounded-xl cursor-pointer items-center justify-center bg-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                                <img src={PlusIcon} className="mr-1" alt="cancel" />
                                <div className="font-normal text-[1rem] text-white">Cancel Order</div>
                            </div>
                        }
                        className="w-[9rem]"
                        popupcontent={false}
                    >
                        {(close: () => void) => <CancelContent id={id} order_no={order_no} close={close} />}
                    </PopUpModal>
                </div>
            ) : status === 'fulfilled' ? (
                <PopUpModal
                    content={
                        <div className="flex rounded-xl cursor-pointer items-center justify-center  text-white bg-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                            <div className="font-normal text-[1rem] ">
                                <i className="fa-solid fa-file mr-2"></i>View invoice
                            </div>
                        </div>
                    }
                    className=""
                    invoicecontent={true}
                    popupcontent={false}
                >
                    {' '}
                    <ViewInvoice
                        order_items={order_items}
                        id={id}
                        close={close}
                        order_no={order_no}
                        name={name}
                        iscustomer={iscustomer}
                        address={address}
                        phone={phone}
                        ordering_company={ordering_company}
                        vendor={vendor}
                        invoice_no={invoice_no}
                        invoice_issued={invoice_issued}
                    />
                </PopUpModal>
            ) : status === 'unfulfilled' || status === 'accepted' ? (
                <>
                    {iscustomer ? (
                        <div className="flex gap-8 mr-2">
                            {/* <PopUpModal
                                content={
                                    <div className="flex rounded-xl cursor-pointer items-center justify-center border border-jumbleng-primary text-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                                        <div className="font-normal text-[1rem] ">
                                            <i className="fa-solid fa-file mr-2"></i>Send invoice
                                        </div>
                                    </div>
                                }
                                className="w-[9rem]"
                                popupcontent={false}
                                invoicecontent={true}
                            >
                                {(close: ()=>void) => (
                                    <ViewInvoice
                                        iscustomer={iscustomer}
                                        order_items={order_items}
                                        id={id}
                                        close={close}
                                        order_no={order_no}
                                        name={name}
                                        address={address}
                                        phone={phone}
                                        ordering_company={ordering_company}
                                        vendor={vendor}
                                        invoice_no={invoice_no}
                                    />
                                )}
                            </PopUpModal> */}
                            {invoice_status !== 'paid' && (
                                <PopUpModal
                                    content={
                                        <div className="flex rounded-xl cursor-pointer items-center justify-center border border-jumbleng-primary text-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                                            <div className="font-normal text-[1rem] ">
                                                {' '}
                                                <i className="fa-solid fa-xmark mr-2"></i>Cancel order
                                            </div>
                                        </div>
                                    }
                                    className="w-[9rem]"
                                    popupcontent={false}
                                >
                                    {(close: () => void) => <CancelContent id={id} order_no={order_no} close={close} />}
                                </PopUpModal>
                            )}
                        </div>
                    ) : (
                        <div className="flex mr-2 gap-8">
                            <Popup
                                trigger={
                                    <div className="flex rounded-xl cursor-pointer items-center justify-center border border-jumbleng-primary text-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                                        <div className="font-normal text-[1rem] ">
                                            <i className="fa-solid fa-file mr-2"></i>View invoice
                                        </div>
                                    </div>
                                }
                                className="w-[9rem]"
                                modal
                                contentStyle={{ width: invoice_issued ? '70%' : '376px' }}
                            >
                                <ViewInvoice
                                    order_items={order_items}
                                    id={id}
                                    close={close}
                                    order_no={order_no}
                                    name={name}
                                    iscustomer={iscustomer}
                                    address={address}
                                    phone={phone}
                                    ordering_company={ordering_company}
                                    vendor={vendor}
                                    invoice_no={invoice_no}
                                    invoice_issued={invoice_issued}
                                />
                            </Popup>
                            {invoice_status !== 'paid' && (
                                <PopUpModal
                                    content={
                                        <div className="flex rounded-xl cursor-pointer items-center justify-center border border-jumbleng-primary text-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                                            <div className="font-normal text-[1rem] ">
                                                {' '}
                                                <i className="fa-solid fa-xmark mr-2"></i>Cancel order
                                            </div>
                                        </div>
                                    }
                                    className="w-[9rem]"
                                    popupcontent={false}
                                >
                                    {(close: () => void) => <CancelContent id={id} order_no={order_no} close={close} />}
                                </PopUpModal>
                            )}
                            <PopUpModal
                                content={
                                    <div className="flex rounded-xl cursor-pointer items-center justify-center bg-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                                        <div className="font-normal text-[1rem] text-white">
                                            <i className="fa fa-check mr-2"></i>Order fulfilled
                                        </div>
                                    </div>
                                }
                                className="w-[9rem]"
                                popupcontent={false}
                            >
                                {(close: () => void) => <MarkFulfilled id={id} order_no={order_no} close={close} />}
                            </PopUpModal>
                        </div>
                    )}
                </>
            ) : (
                !iscustomer &&
                status === 'cancelled' && (
                    <div>
                        <div className="flex mr-4 rounded-xl cursor-pointer items-center justify-center bg-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                            <span className="font-normal text-[1rem] text-white">
                                <i className="fa text-white mr-2 fa-repeat"></i>Reorder
                            </span>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default OrderActions;
