import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';

import InfoIcon from '../../assets/images/svg/info.svg';
import PlusIcon from '../../assets/images/svg/plus.svg';
import AddProductForm from '../../components/AddProductForm/AddProductForm';
import Catalogue from '../../components/catalogue/Catalogue';
import PurchasedProducts from '../../components/purchasedProducts/PurchasedProducts';
import Stocks from '../../components/stocks/Stocks';
import Modal from '../../components/UI/Backdrop/Backdrop';
import PermissionsGate from '../../HOC/PermissionGate';
import Wrapper from '../../HOC/Wrapper';
import { actions, Iaction, myCatalogueType, productType } from '../../redux/features/product/productRedux';
import { getCategories, getMyCatalogue, getNextSKU, getuom } from '../../redux/features/product/productService';
import { initialAddProductValues } from '../../schema';
import { SCOPES } from '../../utils/permission';
import { getPaginationData } from '../../utils/utils';

interface IProductProps {
    my_catalogue: myCatalogueType;
    setCreateProductField: (payload: any) => Iaction;
    setMyCatalogueData: (payload: productType[]) => Iaction;
    setMyCatalogueRecordsPerPage: (payload: number) => Iaction;
    setMyCatalogueCurrentPage: (payload: number) => Iaction;
    setMyCatalogueTotalPages: (payload: number) => Iaction;
    setMyCataloguePages: (payload: number[]) => Iaction;
    setMyCatalogueLoader: (payload: boolean) => Iaction;
}
//my implementation
export type uomType = { key: string; value: string };
export type categoriesType = { id: string; created_at: string; updated_at: string; name: string };

const Product: FC<IProductProps> = ({
    my_catalogue,
    setMyCatalogueData,
    setMyCatalogueCurrentPage,
    setCreateProductField,
    setMyCatalogueRecordsPerPage,
    setMyCatalogueTotalPages,
    setMyCataloguePages,
    setMyCatalogueLoader,
}) => {
    const [addProduct, setAddProduct] = useState(false);
    const [editProduct, setEditProduct] = useState(false);
    const [productCount, setProductCount] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [uom, setUom] = useState<Array<uomType>>([]);
    const [categories, setCategories] = useState<Array<categoriesType>>([]);
    const [modal, setModal] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<'catalogue' | 'stocks' | 'products'>('catalogue');
    const [productForm, setProductForm] = useState('FORM1');
    const dispatch = useDispatch();

    const fetchProducts = async (current_page = 1, records_per_page = 10) => {
        try {
            setMyCatalogueLoader(true);
            const { data, count } = await getMyCatalogue(`page=${current_page}&limit=${records_per_page}`);
            setProductCount(count);
            const response = await Promise.all([getCategories(), getuom(), getNextSKU()]);
            setUom(response[1].data);
            setCategories(response[0].data);
            const res = { sku: response[2].data };
            dispatch({ type: 'SET_SKU', payload: res });
            setCreateProductField({ initialAddProductValues, ...res });
            const { total_pages, pages } = getPaginationData({
                data,
                total_records: count,
                records_per_page,
            });
            setMyCatalogueData(data);
            setMyCataloguePages(pages);
            setMyCatalogueTotalPages(total_pages);
            setMyCatalogueRecordsPerPage(records_per_page);
            setMyCatalogueCurrentPage(current_page);
            setMyCatalogueLoader(false);
        } catch (error) {
            setMyCatalogueLoader(false);
        }
    };

    useEffect(() => {
        fetchProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const toggleFilter = () => {
        setShowFilter((prvState) => !prvState);
    };
    const toggleTab = (tabName: 'catalogue' | 'stocks' | 'products') => {
        setActiveTab(tabName);
    };
    const toggleAddproductModal = () => {
        setAddProduct((prevState) => !prevState);
    };
    const toggleEditProductModal = () => {
        setEditProduct((prevState) => !prevState);
    };

    useEffect(() => {
        modal ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset');
        return () => {
            document.body.style.overflow = 'unset';
            document.body.style.overflow = 'auto';
        };
    }, [modal]);
    const handleToggleModal = () => {
        toggleAddproductModal();
        setModal(true);
    };
    return (
        <Wrapper>
            {modal && (
                <Modal
                    setProductForm={setProductForm}
                    showModal={addProduct || editProduct}
                    setCreateProductField={setCreateProductField}
                    setModal={setModal}
                    setAddProduct={setAddProduct}
                    setEditProduct={setEditProduct}
                />
            )}
            <AddProductForm
                setModal={setModal}
                uom={uom}
                setProductForm={setProductForm}
                productForm={productForm}
                categories={categories}
                showAddProduct={addProduct}
                setAddProduct={toggleAddproductModal}
                fetchProducts={fetchProducts}
            />

            <AddProductForm
                setModal={setModal}
                uom={uom}
                setProductForm={setProductForm}
                productForm={productForm}
                isEdit={true}
                categories={categories}
                showEditProduct={editProduct}
                setEditProduct={toggleEditProductModal}
                fetchProducts={fetchProducts}
            />

            {/* <CatalogueSearchModal /> */}
            <div className="flex items-center justify-between my-6 mb-[0.7rem]">
                <h3 className="font-semibold text-[1.25rem] text-jumbleng-gray">Product</h3>
                <PermissionsGate scopes={[SCOPES.can_create_products]}>
                    <button
                        onClick={handleToggleModal}
                        className="mr-2 md:hidden flex h-full rounded-md ml-4 items-center bg-jumbleng-primary px-[1.18rem] py-[0.42125rem]"
                    >
                        <img src={PlusIcon} className="mr-1" alt="plus" />{' '}
                        <span className="font-semibold text-[0.78125rem] text-white">Add new product</span>
                    </button>
                </PermissionsGate>{' '}
                {/* <p className="font-normal text-jumbleng-gray text-[0.78125rem] px-4 bg-[#EAE9F3] py-[0.78125rem] rounded-[2.9rem]">You have 2 uncompleted products in your draft . View all</p> */}
            </div>

            <div className="flex justify-between border-b py-1 mb-[1.5rem]">
                <ul className="flex">
                    <li className="flex items-center">
                        <span className="">
                            <img src={InfoIcon} alt="info" />
                        </span>
                        <a
                            href="#"
                            onClick={() => toggleTab('catalogue')}
                            className={`${
                                activeTab === 'catalogue' && 'font-bold border-b border-b-jumbleng-primary'
                            } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                        >
                            My catalogue
                        </a>
                    </li>
                </ul>
                <div className="flex h-fit">
                    <PermissionsGate scopes={[SCOPES.can_create_products]}>
                        <button
                            onClick={handleToggleModal}
                            className="mr-2 hidden md:flex h-full rounded-md ml-4 items-center bg-jumbleng-primary px-[1.18rem] py-[0.72125rem]"
                        >
                            <img src={PlusIcon} className="mr-1" alt="plus" />{' '}
                            <span className="font-semibold text-[0.78125rem] text-white">Add new product</span>
                        </button>
                        {/* <button
                            onClick={() => navigate('/jumblecatalogue')}
                            className="mr-2 hidden md:flex h-full rounded-md ml-4 items-center bg-jumbleng-primary px-[1.18rem] py-[0.72125rem]"
                        >
                            <img src={PlusIcon} className="mr-1" alt="plus" />{' '}
                            <span className="font-semibold text-[0.78125rem] text-white">Add from Catalogue</span>
                        </button> */}
                    </PermissionsGate>
                </div>
            </div>

            {activeTab === 'catalogue' ? (
                <Catalogue
                    fetchProducts={fetchProducts}
                    count={productCount}
                    handleToggleModal={handleToggleModal}
                    setModal={setModal}
                    categories={categories}
                    toggleAddproductModal={toggleAddproductModal}
                    products={my_catalogue.data}
                    pages={my_catalogue.pages}
                    records_per_page={my_catalogue.records_per_page}
                    loader={my_catalogue.loader}
                    current_page={my_catalogue.current_page}
                    total_pages={my_catalogue.total_pages}
                    toggleFilter={toggleFilter}
                    showFilter={showFilter}
                    toggleEditProductModal={toggleEditProductModal}
                />
            ) : activeTab === 'products' ? (
                <PurchasedProducts />
            ) : (
                <Stocks />
            )}
        </Wrapper>
    );
};

export default connect((state: any) => {
    const { my_catalogue } = state.ProductReducer;
    return { my_catalogue };
}, actions)(Product);
