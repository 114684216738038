import axios from 'axios';
import { isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import useGetRoles from '../hooks/useGetRoles';
import { getUserByToken } from '../redux/features/auth/authService';
import { RootState } from '../redux/store';
import { COMPANYPERMISSIONS, USERPERMISSIONS } from '../utils/permission';
import { hasPermission } from './PermissionGate';

const AuthRoute = ({ scopes }: any) => {
    const location = useLocation();
    const { user_role, company_type } = useGetRoles();

    //Check for Permissions to access certain routes
    const userpermissions = USERPERMISSIONS[user_role];
    const companypermissions = COMPANYPERMISSIONS[company_type];
    const userPermissionGranted = hasPermission({ permissions: userpermissions, scopes });
    const companyPermissionGranted = hasPermission({ permissions: companypermissions, scopes });

    const verifyTokenExpiry = useCallback(async () => {
        const source = axios.CancelToken.source();
        await getUserByToken(source);
    }, []);

    useEffect(() => {
        verifyTokenExpiry();
    }, [verifyTokenExpiry]);

    const { authorization } = useSelector((state: RootState) => state.authStore);

    if (isEmpty(authorization.access_token)) {
        return <Navigate to="/" state={{ from: location }} />;
    }

    if (!(userPermissionGranted && companyPermissionGranted)) {
        return <Navigate to="/unauthorized" state={{ from: location }} />;
    }

    return <Outlet />;
};

export default AuthRoute;
