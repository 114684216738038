import { ErrorMessage, Field, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import AuthNav from '../../../components/AuthNav/AuthNav';
import Button from '../../../components/Button';
import GuestRoute from '../../../HOC/GuestRoute';
import { IPasswordValue } from '../../../interfaces/schema-interface';
import { resetPassword } from '../../../redux/features/auth/authService';
import { Eaction } from '../../../redux/features/interfaces';
import { initialPasswordValues, passwordValidationSchema } from '../../../schema';

const PasswordResetConfirm = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [buttonLoad, setButtonLoad] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = () => {
        setShowPassword((prev) => !prev);
    };
    const dispatch = useDispatch();
    const { token } = useParams();

    const handleSignInSubmit = async (values: IPasswordValue) => {
        try {
            setButtonLoad(true);
            const response = await resetPassword({ new_password: values?.password, token }, token);
            dispatch({ type: Eaction.setResetOrChangePassword, payload: 'reset' });
            toast.success(response?.message);
            navigate('/reset-password-complete');
        } catch (err: any) {
            setError(err.response.data?.message);
            setButtonLoad(false);
        }
    };

    useEffect(() => {
        const timeId = setTimeout(() => {
            setError('');
        }, 3000);
        return () => {
            clearTimeout(timeId);
        };
    }, [error]);

    return (
        <GuestRoute>
            <div className="w-full bg-jumbleng-background min-h-screen">
                <AuthNav />
                <div className="w-[17rem] mx-auto text-center">
                    <Formik onSubmit={handleSignInSubmit} initialValues={initialPasswordValues} validationSchema={passwordValidationSchema}>
                        {({ handleSubmit }) => (
                            <>
                                <h2 className="font-semibold my-[3.12rem]  text-[2.375rem] text-jumbleng-gray-main  w-[23.5rem]">
                                    Create new password
                                </h2>
                                <div className="mb-[1.953rem] border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1] w-[23.5rem]">
                                    <p className="text-[0.7812rem] text-left font-normal text-jumbleng-gray-main">Enter new password</p>
                                    <div className="field__container">
                                        <img
                                            src="https://res.cloudinary.com/odd-developer/image/upload/v1655387139/lock-icon_tx1qlt.svg"
                                            alt=""
                                            className="field__img"
                                        />
                                        <Field
                                            onKeyDown={(e: any) => {
                                                e.key === 'Enter' && handleSubmit();
                                            }}
                                            type={`${showPassword ? 'text' : 'password'}`}
                                            name={'password'}
                                            placeholder={'XXXXXXXXX'}
                                            className={'w-[23.5rem] bg-jumbleng-background placeholder:text-[0.78125rem] focus:outline-none'}
                                        />
                                        {!error && (
                                            <i
                                                onClick={togglePassword}
                                                className={`text-gray-500 fa-solid fa-eye${showPassword ? '' : '-slash'}`}
                                            ></i>
                                        )}
                                        {error && <i className="fa-solid fa-circle-exclamation text-[20px] text-red-500"></i>}
                                    </div>
                                    <ErrorMessage
                                        name={'password'}
                                        render={(msg) => (
                                            <div className="text-[0.7812rem] text-red-600 text-left font-normal fon text-jumbleng-gray-main">
                                                {msg}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="mb-[1rem] border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1] w-[23.5rem]">
                                    <p className="text-[0.7812rem] text-left font-normal text-jumbleng-gray-main">Confirm new password</p>
                                    <div className="field__container">
                                        <img
                                            src="https://res.cloudinary.com/odd-developer/image/upload/v1655387139/lock-icon_tx1qlt.svg"
                                            alt=""
                                            className="field__img"
                                        />
                                        <Field
                                            onKeyDown={(e: any) => {
                                                e.key === 'Enter' && handleSubmit();
                                            }}
                                            type={`${showPassword ? 'text' : 'password'}`}
                                            name={'confirm_password'}
                                            placeholder={'XXXXXXXXX'}
                                            className={'w-[23.5rem] bg-jumbleng-background placeholder:text-[0.78125rem] focus:outline-none'}
                                        />
                                        {!error && (
                                            <i
                                                onClick={togglePassword}
                                                className={`text-gray-500 fa-solid fa-eye${showPassword ? '' : '-slash'}`}
                                            ></i>
                                        )}
                                        {error && <i className="fa-solid fa-circle-exclamation text-[20px] text-red-500"></i>}
                                    </div>
                                    <ErrorMessage
                                        name={'confirm_password'}
                                        render={(msg) => (
                                            <div className={'text-[0.7812rem] text-red-600 text-left font-normal text-jumbleng-gray-main'}>{msg}</div>
                                        )}
                                    />
                                </div>
                                {error && <div className="text-[0.7812rem] text-red-600 text-left font-normal">{error}</div>}
                                <div className="flex items-center justify-center w-[23.5rem] ">
                                    <Button
                                        type={'button'}
                                        onClick={handleSubmit}
                                        message={`Reset Password`}
                                        loading={buttonLoad}
                                        className={
                                            'w-[23.5rem] h-[3.125rem] mb-[1.953rem] text-white text-[0.78125rem] font-semibold rounded-[12px] bg-jumbleng-primary mt-[3.75rem]'
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </GuestRoute>
    );
};

export default PasswordResetConfirm;
