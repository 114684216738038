import { ReactNode } from 'react';

import AshArrow from '../../../../assets/images/svg/arrowleftAsh.svg';
import Cancel from '../../../../components/CancelButton/Cancel';
import Pagination from '../../../../components/Pagination/Pagination';
import { ISetState } from '../../../../redux/features/interfaces';
import EmptySearch from '../../EmptyContent/EmptySearch';
import { ProductQuery } from './ApplyDiscount';
import EmptyProducts from './EmptyProducst';

const Productslist = ({
    children,
    close,
    data,
    handleChange,
    query,
    setQuery,
    checks,
}: {
    children: ReactNode;
    close: () => void;
    data: any;
    handleChange: (e: any) => void;
    query: ProductQuery;
    setQuery: ISetState<ProductQuery>;
    checks?: boolean;
}) => {
    return (
        <>
            <div className="mt-4 mr-4">
                <Cancel close={close} />
            </div>
            <div className="px-[64px] py-[36px] w-[702px] h-[640px] ">
                <div className="flex gap-1 mb-4 cursor-pointer" onClick={close}>
                    <img src={AshArrow} alt="arrowleft" />
                    <p className="text-[#525171] text-[16px]"> Back</p>
                </div>
                <div className="h-[2.3rem] relative ">
                    <i className="fa fa-search icon absolute top-1/2 -translate-y-1/2 px-[0.79rem] " />
                    <input
                        className="w-full bg-[#F8F8FB] border-b-2 border-[#BAB9C5] focus:outline-none pl-[2.08rem] h-full placeholder:text-[16px] text-[16px] text-jumbleng-gray placeholder:text-[#525171]"
                        placeholder="Search"
                        onChange={(e: any) => {
                            handleChange(e);
                        }}
                    />
                </div>

                {data?.data.length === 0 && query.search_query == '' && <EmptyProducts />}
                <div className="h-[360px] overflow-auto scrollbar-hide ">
                    {data?.data.length === 0 && query.search_query !== '' ? <EmptySearch content={'products'} /> : children}
                    <Pagination query={query} setQuery={setQuery} data={data?.data} count={data?.count} />
                </div>

                <div className="flex justify-between gap-2 pt-8 shadow-2xl">
                    <div
                        onClick={close}
                        className="border border-[#5E5BA7] rounded-lg grid justify-center py-2 items-center w-[168px] h-[48px] cursor-pointer"
                    >
                        <p className="font-semibold text-[#5E5BA7] text-[16px] "> Cancel</p>
                    </div>
                    {checks ? (
                        <div className="bg-[#5E5BA7] rounded-lg grid justify-center py-2 items-center w-[168px] h-[48px] cursor-pointer">
                            <p className="font-semibold text-[#FFFFFF] text-[16px] "> Save Edits</p>
                        </div>
                    ) : (
                        <div
                            onClick={close}
                            className="bg-[#5E5BA7] rounded-lg grid justify-center py-2 items-center w-[168px] h-[48px] cursor-pointer"
                        >
                            <p className="font-semibold text-[#FFFFFF] text-[16px] "> Continue</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Productslist;
