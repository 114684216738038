import { ErrorMessage, Field, Formik } from 'formik';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import X from '../../assets/images/svg/x.svg';
import { updateCatalogue } from '../../redux/slices/company_profile';
import { RootState } from '../../redux/store';
import { BioSchema, IBioInterface, IinitialBio } from '../../schema/settings-schema';
import Button from '../Button';
import Spinner from '../UI/Spinner/Spinner';

interface IModal {
    setEditModal: Dispatch<SetStateAction<boolean>>;
}

const EditbioModal: FC<IModal> = ({ setEditModal }) => {
    const [selectedFile, setSelectedFile] = useState();
    const { loading } = useSelector((state: RootState) => state.companyProfileReducer);
    const [character, setCharacter] = useState('');
    const dispatch = useDispatch();
    const authStore = useSelector((state: RootState) => state.authStore);
    const { company } = authStore;
    const [preview, setPreview] = useState(company.banner);

    const handleUpdateBio = async (values: IBioInterface) => {
        dispatch(updateCatalogue({ ...values, private: company.private }));
    };

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }
        const objectUrl: any = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    useEffect(() => {
        setPreview(company.banner);
    }, [company.banner]);

    const handleChange = (e: any) => {
        setCharacter(e.target.value);
    };
    const handleDelete = () => {
        company.banner && dispatch(updateCatalogue({ bio: company.bio, banner: '', private: company.private }));
        setSelectedFile(undefined);
    };

    const onSelectFile = async (event: any, setFieldValue: any) => {
        if (!event.target.files) return;
        setFieldValue('banner', event.target.files[0]);
        setSelectedFile(event.target.files[0]);
    };
    return (
        <div>
            <Formik
                onSubmit={handleUpdateBio}
                initialValues={{ ...IinitialBio, bio: company.bio, banner: company.banner }}
                validationSchema={BioSchema}
            >
                {({ handleSubmit, setFieldValue, dirty }) => (
                    <form className="bg-white w-[45rem] h-[70%] mt-0 overflow-auto rounded-[0.585rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[56] px-[3.33rem]">
                        <div className="flex justify-between mt-[20px]">
                            <div />
                            <img src={X} alt="cancel" className="fixed right-5 hover:cursor-pointer" onClick={() => setEditModal(false)} />
                        </div>

                        <p className="font-semibold text-base mt-4 text-center text-[1.37rem]">Catalogue bio</p>
                        <div>
                            <p className="font-normal text-[0.78125rem] text-jumbleng-gray mt-6 mb-2">Catalogue banner</p>
                            <div>
                                <div className="w-full h-[100px]">
                                    {!selectedFile && !company.banner ? (
                                        <>
                                            <label htmlFor="imageUpload">
                                                <div className="flex flex-col  gap-1 p-2 items-center justify-center border-dashed border-jumbleng-primary border-[1px] h-[5rem]">
                                                    <p className="text-[10px] text-jumbleng-gray">Image should be in jpg or png format</p>
                                                    <p className="text-xs text-jumbleng-primary">
                                                        <i className="fa-solid fa-image mr-1"></i>
                                                        Upload Image{' '}
                                                    </p>
                                                </div>
                                            </label>
                                            <input
                                                id="imageUpload"
                                                type="file"
                                                className="w-0.1 h-0.1 overflow-hidden hidden"
                                                accept="image/*"
                                                onChange={(event) => {
                                                    onSelectFile(event, setFieldValue);
                                                }}
                                            />
                                            <ErrorMessage
                                                name={'banner'}
                                                render={(msg) => (
                                                    <div className="text-[0.7812rem] text-red-400 text-left font-normal">
                                                        {msg}
                                                    </div>
                                                )}
                                            />
                                        </>
                                    ) : (
                                        <div className="flex items-center justify-center w-full">
                                            <img src={preview} alt="profile_image" className="object-contain w-full h-[100px]" />
                                        </div>
                                    )}
                                </div>
                                {(selectedFile || company.banner) && (
                                    <div className="flex gap-6 justify-end mt-2">
                                        <div className="font-normal text-[1rem] text-jumbleng-primary ">
                                            <label htmlFor="imageUpload">
                                                <i className="fa fa-image mr-1"></i>Change Banner
                                            </label>
                                            <input
                                                id="imageUpload"
                                                type="file"
                                                className="w-0.1 h-0.1 overflow-hidden hidden"
                                                onChange={(event) => {
                                                    onSelectFile(event, setFieldValue);
                                                }}
                                            />
                                            <ErrorMessage
                                                name={'banner'}
                                                render={(msg) => (
                                                    <div className="text-[0.7812rem] text-red-400 text-left font-normal">
                                                        {msg}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        <div className="font-normal text-[1rem] text-[#9F0D0D] cursor-pointer" onClick={handleDelete}>
                                            <i className="fa fa-x mr-1"></i>Remove Banner
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col mb-[0.47rem] mt-6">
                                <label className="font-normal text-[0.78125rem] text-jumbleng-gray mb-2">Catalogue bio</label>
                                <Field
                                    as={'textarea'}
                                    name={'bio'}
                                    placeholder={'Type here...'}
                                    className="p-[0.585rem] text-jumbleng-gray border-b border-gray focus:outline-none border-b-jumbleng-gray"
                                    onChange={(e: any) => {
                                        handleChange(e);
                                        setFieldValue('bio', e.target.value);
                                    }}
                                    maxLength="250"
                                />
                                <ErrorMessage
                                    name={'bio'}
                                    render={(msg) => (
                                        <div className="text-[0.7812rem] text-red-400 text-left font-normal">{msg}</div>
                                    )}
                                />
                            </div>
                            <p className="text-right text-jumbleng-gray"> Max:{250 - character.length} characters</p>
                        </div>

                        <div className="flex justify-between mb-[2.34rem] mt-[1rem]">
                            <button
                                type="button"
                                onClick={() => setEditModal(false)}
                                className="px-[1.56rem] text-jumbleng-primary py-[0.78125rem] rounded-[0.585rem] border border-jumbleng-primary"
                            >
                                Cancel
                            </button>
                            {false ? (
                                <Spinner />
                            ) : (
                                <Button
                                    type={'submit'}
                                    onClick={handleSubmit}
                                    disabled={!dirty}
                                    message={'Update bio'}
                                    loading={loading}
                                    className={`px-[1.56rem] py-[0.78125rem] rounded-[0.585rem] ${
                                        !dirty ? 'bg-jumbleng-primary-light text-white' : 'bg-jumbleng-primary border-jumbleng-primary text-white'
                                    }  border `}
                                />
                            )}
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default EditbioModal;
