import { ErrorMessage, Field, Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import uploadFile from '../../../assets/images/svg/upload.svg';
import Button from '../../../components/Button';
import DatePickerField from '../../../components/DatePicker/DatePicker';
import { IUserInfo } from '../../../interfaces/schema-interface/company';
import { closeModal, openModal } from '../../../redux/modal/modalRedux';
import { useUpdateUserInfoMutation } from '../../../redux/services/complianceApi';
import { initialUserInfoValues, UserInfoSchema } from '../../../schema/company.schema';
import { phones } from '../../../utils/phone_number';
import { transformDate } from '../../../utils/utils';

const Form2 = ({ setStep, data, IdTypes }: any) => {
    const [updateUserInfo, { isLoading, isSuccess, isError, error }] = useUpdateUserInfoMutation();
    const [picturename, setPicturename] = useState(data.owner_id_image);
    const dispatch = useDispatch();
    const valuesRef = useRef<any>(null);
    const [country, setCountry] = useState<string>(data.owner_country);
    const [nationality, setNationality] = useState<string>(data.owner_nationality);
    const [region, setRegion] = useState<string>(data.owner_state);
    const [fieldError, setFieldError] = useState({ nationality: false, region: false, country: false });

    const selectCountry = (val: any) => {
        setCountry(val);
        setFieldError({ ...fieldError, country: false });
        setRegion('');
    };

    const selectRegion = (val: any) => {
        setRegion(val);
        setFieldError({ ...fieldError, region: false });
    };
    const selectNationality = (val: any) => {
        if (val) {
            setNationality(val);
            setFieldError({ ...fieldError, nationality: false });
        } else {
            setRegion('');
        }
    };
    const handleOnSubmit = (values: IUserInfo) => {
        if (!nationality) {
            setFieldError({ ...fieldError, nationality: true });
            return;
        } else if (!region) {
            setFieldError({ ...fieldError, region: true });
            return;
        } else if (!country) {
            setFieldError({ ...fieldError, country: true });
            return;
        } else {
            setFieldError({ ...fieldError, country: false, region: false, nationality: false });
            updateUserInfo({
                ...values,
                owner_phone: valuesRef.current?.values?.code + values.owner_phone,
                owner_dob: transformDate(values.owner_dob),
                owner_country: country,
                owner_state: region,
                owner_nationality: nationality,
            });
        }
    };

    const phone_numbers = useMemo(() => {
        return _.sortBy(phones.map((item: any) => item.code));
    }, []);

    useEffect(() => {
        if (isLoading) {
            dispatch(openModal());
        }
        if (isSuccess) {
            setStep(3);
            toast.success("Owner's Info saved");
            dispatch(closeModal());
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
            dispatch(closeModal());
        }
    }, [isLoading, isSuccess, isError, error, dispatch, setStep]);

    return (
        <div className="md:w-[50%] w-full">
            <div className="flex items-start">
                <Formik
                    onSubmit={handleOnSubmit}
                    innerRef={valuesRef}
                    initialValues={{
                        ...initialUserInfoValues,
                        ...data,
                        code: data.owner_phone.substring(0, data.owner_phone.length - 10),
                        owner_phone: data.owner_phone.slice(-10),
                        owner_dob: data.owner_dob && moment(data.owner_dob, 'DD/MM/YYYY').toDate(),
                    }}
                    validationSchema={UserInfoSchema}
                    enableReinitialize
                >
                    {({ handleSubmit, setFieldValue, values, setFieldTouched }) => (
                        <div className="w-full flex flex-col gap-6">
                            <div className="grid md:grid-cols-2 w-full gap-4">
                                <div>
                                    <label className="text-[#131221] opacity-50">Legal First Name</label>
                                    <div className="mt-1 relative flex ">
                                        <Field
                                            name={'owner_first_name'}
                                            onKeyUp={() => setFieldTouched('owner_first_name', true)}
                                            className="border w-full text-[#131221] h-[40px] border-jumbleng-gray opacity-50  px-2  focus:outline-none "
                                        />
                                    </div>
                                    <ErrorMessage
                                        name={'owner_first_name'}
                                        render={(msg) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                    />
                                </div>
                                <div>
                                    <label className="text-[#131221] opacity-50">Legal Last Name</label>
                                    <div className="mt-1 relative flex">
                                        <Field
                                            name={'owner_last_name'}
                                            onKeyUp={() => setFieldTouched('owner_last_name', true)}
                                            className="border w-full text-[#131221] h-[40px] border-jumbleng-gray opacity-50  px-2  focus:outline-none "
                                        />
                                    </div>
                                    <ErrorMessage
                                        name={'owner_last_name'}
                                        render={(msg) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="grid md:grid-cols-4 w-full items-center gap-4">
                                    <div>
                                        <div className="mt-1 relative flex ">
                                            <Field
                                                as={'select'}
                                                name={'code'}
                                                className={
                                                    'w-full placeholder:text-[0.78125rem] border border-[#030729] opacity-50  px-2  focus:outline-none'
                                                }
                                            >
                                                {phone_numbers?.map((item: any, index: number) => (
                                                    <option key={index} value={item}>
                                                        {item}
                                                    </option>
                                                ))}{' '}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="col-span-3 w-full">
                                        <div className="mt-1 relative flex ">
                                            <Field
                                                name={'owner_phone'}
                                                onKeyUp={() => setFieldTouched('owner_phone', true)}
                                                className="border w-full text-[#131221] h-[40px] border-jumbleng-gray opacity-50  px-2  focus:outline-none "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full">
                                    <ErrorMessage
                                        name={'owner_phone'}
                                        render={(msg) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="text-[#131221] opacity-50">Gender</label>
                                <div className="mt-1 relative flex">
                                    <Field
                                        as={'select'}
                                        name={'owner_gender'}
                                        onKeyUp={() => setFieldTouched('owner_gender', true)}
                                        placeholder={'Select gender'}
                                        className={
                                            ' w-full placeholder:text-[0.78125rem] border border-[#030729] opacity-50  px-2  focus:outline-none'
                                        }
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </Field>
                                </div>
                                <ErrorMessage
                                    name={'owner_gender'}
                                    render={(msg) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                />
                            </div>
                            <div>
                                <label className="text-[#131221] opacity-50">Date of Birth</label>
                                <div className="mt-1 relative flex">
                                    <DatePickerField name={'owner_dob'} value={values.owner_dob} onChange={setFieldValue} />
                                </div>
                                <ErrorMessage
                                    name={'owner_dob'}
                                    render={(msg) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                />
                            </div>
                            <div>
                                <label className="text-[#131221] opacity-50">Nationality</label>
                                <div className="mt-1 relative flex">
                                    <CountryDropdown
                                        defaultOptionLabel="Select Nationality"
                                        value={nationality}
                                        onChange={(val) => selectNationality(val)}
                                        classes={'w-full placeholder:text-[0.78125rem] border border-[#030729] opacity-50  px-2  focus:outline-none'}
                                    />
                                </div>{' '}
                                {fieldError.nationality && (
                                    <div className="text-[0.7812rem] text-red-400 text-left font-normal fon">Nationality field is required</div>
                                )}
                            </div>
                            <div>
                                <label className="text-[#131221] opacity-50">Residential Address </label>
                                <div className="mt-1 relative flex">
                                    <Field
                                        name={'owner_address'}
                                        onKeyUp={() => setFieldTouched('owner_address', true)}
                                        className="border w-full text-[#131221] h-[40px] border-jumbleng-gray opacity-50  px-2  focus:outline-none "
                                    />
                                </div>
                                <ErrorMessage
                                    name={'owner_address'}
                                    render={(msg) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                />
                            </div>
                            <div>
                                <label className="text-[#131221] opacity-50">Country</label>
                                <div className="mt-1 relative flex">
                                    <CountryDropdown
                                        value={country}
                                        onChange={(val) => selectCountry(val)}
                                        classes={'w-full placeholder:text-[0.78125rem] border border-[#030729] opacity-50  px-2  focus:outline-none'}
                                    />
                                </div>
                                {fieldError.country && (
                                    <div className="text-[0.7812rem] text-red-400 text-left font-normal fon">country field is required</div>
                                )}
                            </div>
                            <div className="grid md:grid-cols-2 w-full gap-4">
                                <div>
                                    <label className="text-[#131221] opacity-50">State</label>
                                    <div className="mt-1 relative flex">
                                        <RegionDropdown
                                            defaultOptionLabel="Select State"
                                            country={country}
                                            value={region}
                                            onChange={(val) => selectRegion(val)}
                                            classes={
                                                'w-full placeholder:text-[0.78125rem] border border-[#030729] opacity-50  px-2  focus:outline-none'
                                            }
                                        />
                                    </div>{' '}
                                    {fieldError.region && (
                                        <div className="text-[0.7812rem] text-red-400 text-left font-normal fon">state field is required</div>
                                    )}
                                </div>
                                <div>
                                    <label className="text-[#131221] opacity-50">City</label>
                                    <div className="mt-1 relative flex">
                                        <Field
                                            name={'owner_city'}
                                            onKeyUp={() => setFieldTouched('owner_city', true)}
                                            placeholder={'e.g Ikeja, Surulere'}
                                            className="border w-full text-[#131221] h-[40px] border-jumbleng-gray opacity-50  px-2  focus:outline-none "
                                        />
                                    </div>
                                    <ErrorMessage
                                        name={'owner_city'}
                                        render={(msg) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                    />
                                </div>
                            </div>
                            <h1 className="font-semibold text-[21px]">Identification</h1>
                            <div className="grid md:grid-cols-2 w-full gap-4">
                                <div>
                                    <label className="text-[#131221] opacity-50">ID Type</label>
                                    <div className="mt-1 relative flex ">
                                        <Field
                                            as={'select'}
                                            name={'owner_id_type'}
                                            onKeyUp={() => setFieldTouched('owner_id_type', true)}
                                            className={
                                                'w-full placeholder:text-[0.78125rem] border border-[#030729] opacity-50  px-2  focus:outline-none'
                                            }
                                        >
                                            {' '}
                                            <option value="">Select ID</option>
                                            {_.values(IdTypes)?.map((item: any, index: number) => (
                                                <option key={index} value={item}>
                                                    {item}
                                                </option>
                                            ))}{' '}
                                        </Field>
                                    </div>
                                    <ErrorMessage
                                        name={'owner_id_type'}
                                        render={(msg) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                    />
                                </div>
                                <div>
                                    <label className="text-[#131221] opacity-50">ID Number</label>
                                    <div className="mt-1 relative flex">
                                        <Field
                                            name={'owner_id_number'}
                                            onKeyUp={() => setFieldTouched('owner_id_number', true)}
                                            className="border w-full text-[#131221] h-[40px] border-jumbleng-gray opacity-50  px-2  focus:outline-none "
                                        />
                                    </div>
                                    <ErrorMessage
                                        name={'owner_id_number'}
                                        render={(msg) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="text-[#131221] opacity-50">ID Upload</label>
                                <div>
                                    <div className="w-full mt-2">
                                        <label htmlFor="imageUpload">
                                            {picturename ? (
                                                <>
                                                    {picturename !== data.owner_id_image ? (
                                                        <div className="flex flex-col gap-1 items-center justify-center border-[#DCDCDC] border-[1px] rounded-md  w-full p-8">
                                                            <div className="flex gap-4">{picturename}</div>
                                                        </div>
                                                    ) : (
                                                        <div className="w-full h-[150px]">
                                                            <img
                                                                src={picturename}
                                                                alt="self"
                                                                className="w-auto h-full object-cover pr-[0.78125rem]"
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <div className="flex flex-col gap-1 items-center justify-center border-[#DCDCDC] border-[1px] rounded-md  w-full p-8">
                                                    <div className="flex gap-4">
                                                        <img src={uploadFile} alt="profile_image" />
                                                        <div>
                                                            <p className="text-jumbleng-gray text-[18px]">Drag and drop document here or Browse</p>
                                                            <p className="text-[12px] text-jumbleng-gray">
                                                                Supported file types: JPEG, PNG, PDF. Max file size: 2mb
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </label>
                                        <input
                                            id="imageUpload"
                                            type="file"
                                            accept="image/*, application/pdf"
                                            onKeyUp={() => setFieldTouched('owner_id_image', true)}
                                            className="w-0.1 h-0.1 overflow-hidden hidden"
                                            onChange={(event) => {
                                                if (!event.target.files) return;
                                                setFieldValue('owner_id_image', event.target.files[0]);
                                                setPicturename(event.target.files[0]?.name);
                                            }}
                                        />
                                        <ErrorMessage
                                            name={'owner_id_image'}
                                            render={(msg) => <div className="text-[0.7812rem] text-red-400 text-left font-normal fon">{msg}</div>}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Button
                                type={'submit'}
                                onClick={handleSubmit}
                                loading={false}
                                message={`Save and continue`}
                                className="px-[1.56rem] text-white md:py-[0.78125rem]  py-[0.45rem] h-[56px] max-w-[200px] bg-[#2F2E54]"
                            ></Button>
                        </div>
                    )}
                </Formik>{' '}
            </div>
        </div>
    );
};

export default Form2;
