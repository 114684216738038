import { ErrorMessage, Field, Formik } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import uploadFile from '../../../assets/images/svg/upload.svg';
import Button from '../../../components/Button';
import { IDocumentation } from '../../../interfaces/schema-interface/company';
import { closeModal, openModal } from '../../../redux/modal/modalRedux';
import { useUpdateDocumentationMutation } from '../../../redux/services/complianceApi';
import { DocumentationSchema, initialDocumentationValues } from '../../../schema/company.schema';

const Form3 = ({ setStep, data, utilityTypes }: any) => {
    const [updateDocument, { isLoading, isSuccess, isError, error }] = useUpdateDocumentationMutation();
    const [picturename, setPicturename] = useState(data.business_utility_bill_image);
    const [picturename2, setPicturename2] = useState(data.business_cac);
    const dispatch = useDispatch();

    const handleOnSubmit = (values: IDocumentation) => {
        updateDocument(values);
    };

    useEffect(() => {
        if (isLoading) {
            dispatch(openModal());
        }
        if (isSuccess) {
            setStep(4);
            toast.success("Owner's Info saved");
            dispatch(closeModal());
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
            dispatch(closeModal());
        }
    }, [isLoading, isSuccess, isError, error, dispatch, setStep]);
    return (
        <div className="md:w-[50%] w-full">
            <div className="flex items-start">
                <Formik onSubmit={handleOnSubmit} initialValues={{ ...initialDocumentationValues, ...data }} validationSchema={DocumentationSchema}>
                    {({ handleSubmit, setFieldValue, setFieldTouched }) => (
                        <div className="w-full flex flex-col gap-6">
                            <div>
                                <label className="text-[#131221] opacity-50">RC Number </label>
                                <div className="mt-1 relative flex">
                                    <Field
                                        name={'business_rc_number'}
                                        onKeyUp={() => setFieldTouched('business_rc_number', true)}
                                        className="border w-full text-[#131221] h-[40px] border-jumbleng-gray opacity-50  px-2  focus:outline-none "
                                    />
                                </div>
                                <ErrorMessage
                                    name={'business_rc_number'}
                                    render={(msg) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                />
                            </div>
                            <div>
                                <label className="text-[#131221] opacity-50">Tax Identifier Number</label>
                                <div className="mt-1 relative flex">
                                    <Field
                                        name={'business_tin'}
                                        onKeyUp={() => setFieldTouched('business_tin', true)}
                                        className="border w-full text-[#131221] h-[40px] border-jumbleng-gray opacity-50  px-2  focus:outline-none "
                                    />
                                </div>
                                <ErrorMessage
                                    name={'business_tin'}
                                    render={(msg) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                />
                            </div>
                            <div>
                                <label className="text-[#131221] opacity-50">Utility Bill (not more than 3 months old)</label>
                                <div className="mt-1 relative flex">
                                    <Field
                                        as={'select'}
                                        name={'business_utility_bill_type'}
                                        onKeyUp={() => setFieldTouched('business_utility_bill_type', true)}
                                        className={
                                            ' w-full placeholder:text-[0.78125rem] border border-[#030729] opacity-50  px-2  focus:outline-none'
                                        }
                                    >
                                        <option value="">Select a Utility Bill</option>
                                        {_.values(utilityTypes)?.map((item: any, index: number) => (
                                            <option key={index} value={item}>
                                                {item}
                                            </option>
                                        ))}{' '}
                                    </Field>
                                </div>
                                <ErrorMessage
                                    name={'business_utility_bill_type'}
                                    render={(msg) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                />
                            </div>
                            <div>
                                <label className="text-[#131221] opacity-50">Copy of Utility bill</label>
                                <div>
                                    <div className="w-full mt-2">
                                        <label htmlFor="imageUpload">
                                            {picturename ? (
                                                <>
                                                    {picturename !== data.business_utility_bill_image ? (
                                                        <div className="flex flex-col gap-1 items-center justify-center border-[#DCDCDC] border-[1px] rounded-md  w-full p-8">
                                                            <div className="flex gap-4">{picturename}</div>
                                                        </div>
                                                    ) : (
                                                        <div className="w-full h-[150px]">
                                                            <img src={picturename} alt="self" className="w-auto h-full object-contain" />
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <div className="flex flex-col gap-1 items-center justify-center border-[#DCDCDC] border-[1px] rounded-md  w-full p-8">
                                                    <div className="flex gap-4">
                                                        <img src={uploadFile} alt="profile_image" />
                                                        <div>
                                                            <p className="text-jumbleng-gray text-[18px]">Drag and drop document here or Browse</p>
                                                            <p className="text-[12px] text-jumbleng-gray">
                                                                Supported file types: JPEG, PNG, PDF. Max file size: 2mb
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </label>
                                        <input
                                            id="imageUpload"
                                            type="file"
                                            accept="image/*, application/pdf"
                                            className="w-0.1 h-0.1 overflow-hidden hidden"
                                            onKeyUp={() => setFieldTouched('business_utility_bill_image', true)}
                                            onChange={(event) => {
                                                if (!event.currentTarget.files) return;
                                                setFieldValue('business_utility_bill_image', event.currentTarget.files[0]);
                                                setPicturename(event.currentTarget.files[0]?.name);
                                            }}
                                        />
                                        <ErrorMessage
                                            name={'business_utility_bill_image'}
                                            render={(msg) => <div className="text-[0.7812rem] text-red-400 text-left font-normal fon">{msg}</div>}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <label className="text-[#131221] opacity-50">CAC Document</label>
                                <div>
                                    <div className="w-full mt-2">
                                        <label htmlFor="fileUpload">
                                            {picturename2 ? (
                                                <>
                                                    {picturename2 !== data.business_cac ? (
                                                        <div className="flex flex-col gap-1 items-center justify-center border-[#DCDCDC] border-[1px] rounded-md  w-full p-8">
                                                            <div className="flex gap-4">{picturename2}</div>
                                                        </div>
                                                    ) : (
                                                        <div className="w-full  h-[150px]">
                                                            <img src={picturename2} alt="self" className="w-auto h-full object-contain" />
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <div className="flex flex-col gap-1 items-center justify-center border-[#DCDCDC] border-[1px] rounded-md  w-full p-8">
                                                    <div className="flex gap-4">
                                                        <div>
                                                            <p className="text-jumbleng-gray text-[18px]">Drag and drop document here or Browse</p>
                                                            <p className="text-[12px] text-jumbleng-gray">
                                                                Supported file types: JPEG, PNG, PDF. Max file size: 2mb
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </label>
                                        <input
                                            id="fileUpload"
                                            type="file"
                                            accept="image/*, application/pdf"
                                            className="w-0.1 h-0.1 overflow-hidden hidden"
                                            onKeyUp={() => setFieldTouched('business_cac', true)}
                                            onChange={(event) => {
                                                if (!event.currentTarget.files) return;
                                                setFieldValue('business_cac', event.currentTarget.files[0]);
                                                setPicturename2(event.currentTarget.files[0]?.name);
                                            }}
                                        />
                                        <ErrorMessage
                                            name={'business_cac'}
                                            render={(msg) => <div className="text-[0.7812rem] text-red-400 text-left font-normal fon">{msg}</div>}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Button
                                type={'submit'}
                                onClick={handleSubmit}
                                loading={false}
                                message={`Save and continue`}
                                className="px-[1.56rem] text-white md:py-[0.78125rem]  py-[0.45rem] h-[56px] max-w-[200px] bg-[#2F2E54]"
                            ></Button>
                        </div>
                    )}
                </Formik>{' '}
            </div>
        </div>
    );
};

export default Form3;
