import { Field, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';

import PlusIcon from '../../assets/images/svg/plus.svg';
import Button from '../../components/Button';
import Cancel from '../../components/CancelButton/Cancel';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import PermissionsGate from '../../HOC/PermissionGate';
import Wrapper from '../../HOC/Wrapper';
import useGetRoles from '../../hooks/useGetRoles';
import { IGroupForm } from '../../interfaces';
import { useCreateGroupMutation, useGetCustomersQuery } from '../../redux/services';
import { clearList } from '../../redux/slices/global';
import { toggleVendorListTab } from '../../redux/slices/tabs';
import { RootState } from '../../redux/store';
import { GroupFormSchema, initialGroupFormValues } from '../../schema/create-group-schema';
import { SCOPES } from '../../utils/permission';
import { managePermissions } from '../settings/Settings';
import Customer from './Customers';
import AddVendor from './Groups/AddVendor';
import Group from './Groups/Group';
import Supplier from './Suppliers';

export type CustomerQuery = {
    page: number;
    limit: number;
    addedToGroup: boolean;
    search_query: string;
};
export const initialCustomerQuery: CustomerQuery = {
    page: 1,
    limit: 10,
    addedToGroup: false,
    search_query: '',
};
const VendorList = () => {
    const { vendorListTab: activeTab } = useSelector((state: RootState) => state.tabsReducer);
    const [createGroup, { isLoading, isSuccess, isError, error }] = useCreateGroupMutation();
    const { customers } = useSelector((state: RootState) => state.globalReducer);
    const [query, setQuery] = useState<CustomerQuery>(initialCustomerQuery);
    const { data } = useGetCustomersQuery(query);
    const { user_role, company_type } = useGetRoles();
    const [toggle, setToggle] = useState(false);

    const handleChange = (e: any) => {
        setQuery({ ...query, search_query: e.target.value, page: 1 });
    };

    const dispatch = useDispatch();

    const toggleTab = (tabName: 'customers' | 'suppliers' | 'groups') => {
        dispatch(toggleVendorListTab(tabName));
        setToggle(true);
    };

    const handleOnSubmit = (values: IGroupForm, close: () => void) => {
        createGroup({ ...values, companies: customers });
        close();
    };
    useEffect(() => {
        if (!toggle) {
            if (managePermissions(user_role, company_type, [SCOPES.can_view_customers])) {
                dispatch(toggleVendorListTab('customers'));
                return;
            }
            if (managePermissions(user_role, company_type, [SCOPES.can_view_suppliers])) {
                dispatch(toggleVendorListTab('suppliers'));
                return;
            }
            dispatch(toggleVendorListTab('groups'));
            return;
        }
    }, [user_role, company_type, dispatch, toggle]);

    useEffect(() => {
        if (isSuccess) {
            toast.success('Group created successfully');
            dispatch(clearList());
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
        }
    }, [dispatch, error, isError, isLoading, isSuccess]);

    return (
        <Wrapper>
            <LoadingModal />
            <div className="flex items-center justify-between my-6 mb-[0.7rem]">
                <h3 className="font-semibold text-[1.25rem] text-jumbleng-gray">Vendor List</h3>
            </div>

            <div className="flex justify-between border-b py-1 mb-[1.5rem] overflow-x-auto">
                <ul className="flex text-[0.72125rem] md:text-[1rem]">
                    <PermissionsGate scopes={[SCOPES.can_view_customers]}>
                        <li className="flex items-center ">
                            <a
                                href="#"
                                onClick={() => toggleTab('customers')}
                                className={`${
                                    activeTab === 'customers' && 'font-bold border-b border-b-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full `}
                            >
                                Customers
                            </a>
                        </li>
                    </PermissionsGate>
                    <PermissionsGate scopes={[SCOPES.can_view_suppliers]}>
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => toggleTab('suppliers')}
                                className={`${
                                    activeTab === 'suppliers' && 'font-bold border-b border-b-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full ]`}
                            >
                                Suppliers
                            </a>
                        </li>
                    </PermissionsGate>
                    <li className="flex items-center">
                        <a
                            href="#"
                            onClick={() => toggleTab('groups')}
                            className={`${
                                activeTab === 'groups' && 'font-bold border-b border-b-jumbleng-primary'
                            } w-full px-4 py-[0.78125rem] h-full`}
                        >
                            Group
                        </a>
                    </li>
                </ul>
                <PermissionsGate scopes={[SCOPES.can_create_groups]}>
                    <Popup
                        trigger={
                            <div className="flex h-full rounded-md ml-4 items-center bg-jumbleng-primary px-[1.18rem] py-[0.42125rem] cursor-pointer mr-4">
                                <img src={PlusIcon} className="mr-1" alt="plus" />{' '}
                                <span className="font-semibold text-[0.78125rem] text-white">Create Group</span>
                            </div>
                        }
                        position="right center"
                        contentStyle={{ width: 'fit-content' }}
                        modal
                        nested
                    >
                        {(close: () => void) => (
                            <>
                                <div className="mt-4 mr-4">
                                    <Cancel close={close} />
                                </div>
                                <div className="px-[88px] py-[32px] w-[702px] h-[524px]">
                                    <Formik
                                        onSubmit={(values) => handleOnSubmit(values, close)}
                                        validationSchema={GroupFormSchema}
                                        initialValues={initialGroupFormValues}
                                    >
                                        {({ handleSubmit, errors, touched }) => (
                                            <>
                                                <p className="text-[#131221] font-black  text-[28px] grid justify-center mb-4 ">Create Group</p>
                                                <input type="text" className="hidden" />
                                                <p className="text-[#525171] text-[16px] mb-6">Group name</p>
                                                <div>
                                                    <Field
                                                        name={'name'}
                                                        className="w-full bg-[#FFFFFF] border-b-2 border-[#BAB9C5] focus:outline-none h-full placeholder:text-[16px] text-[16px] text-jumbleng-gray placeholder:text-[#525171]"
                                                        placeholder="Input Field Text"
                                                    />
                                                    {errors.name && touched.name ? (
                                                        <div className="text-[0.7812rem] text-red-600 text-left font-normal">{errors.name}</div>
                                                    ) : null}
                                                </div>
                                                <p className="text-[#525171] text-[16px] mt-2 mb-6">Group description (Optional)</p>
                                                <div>
                                                    <Field
                                                        name={'description'}
                                                        className="w-full bg-[#FFFFFF] border-b-2 border-[#BAB9C5] focus:outline-none h-full placeholder:text-[16px] text-[16px] text-jumbleng-gray placeholder:text-[#525171]"
                                                        placeholder="Input Field Text"
                                                    />
                                                    {errors.description ? (
                                                        <div className="text-[0.7812rem] text-red-600 text-left font-normal">
                                                            {errors.description}
                                                        </div>
                                                    ) : null}
                                                </div>
                                                <AddVendor data={data} handleChange={handleChange} query={query} setQuery={setQuery} />
                                                <div className="flex justify-between gap-2 mt-8">
                                                    <div
                                                        style={{ border: '1px solid #5E5BA7' }}
                                                        className=" rounded-lg grid justify-center py-2 w-[168px] h-[48px]  cursor-pointer "
                                                        onClick={close}
                                                    >
                                                        <p className="font-semibold text-[#5E5BA7] text-[16px] "> Cancel</p>
                                                    </div>
                                                    <Button
                                                        type={'submit'}
                                                        onClick={handleSubmit}
                                                        loading={false}
                                                        message={`Create group`}
                                                        className="bg-[#5E5BA7] text-white font-semibold rounded-lg flex items-center justify-center py-2 cursor-pointer w-[168px] h-[48px]"
                                                    ></Button>
                                                </div>
                                            </>
                                        )}
                                    </Formik>
                                </div>
                            </>
                        )}
                    </Popup>
                </PermissionsGate>
            </div>

            {activeTab === 'customers' ? <Customer /> : activeTab === 'suppliers' ? <Supplier /> : <Group />}
        </Wrapper>
    );
};

export default VendorList;
