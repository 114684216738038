import { useState } from 'react';
import { useSelector } from 'react-redux';

import EditbioModal from '../../components/EditBioModal/EditbioModal';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import Modal from '../../components/UI/Backdrop/Backdrop';
import { closeModal, openModal } from '../../redux/modal/modalRedux';
import { updateCatalogue } from '../../redux/slices/company_profile';
import { RootState, ThunkAppDispatch, useAppThunkDispatch } from '../../redux/store';

const Catalogue = () => {
    const [editModal, setEditModal] = useState<boolean>(false);
    const { company } = useSelector((state: RootState) => state.authStore);
    const dispatch: ThunkAppDispatch = useAppThunkDispatch();

    const setPrivate = async () => {
        dispatch(openModal());
        if (company.private) {
            await dispatch(updateCatalogue({ bio: company.bio, banner: company.banner, private: false }));
        } else {
            await dispatch(updateCatalogue({ bio: company.bio, banner: company.banner, private: true }));
        }
        dispatch(closeModal());
    };

    return (
        <>
            {editModal && <EditbioModal setEditModal={setEditModal} />}
            <LoadingModal />
            <div className="h-full mr-8">
                <p className="text-jumbleng-primary">Manage what other user can see on your catalogue</p>
                <div className="bg-white mt-[1.5rem] p-16">
                    <div className=" mb-[2.35rem] w-full">
                        <h3 className=" font-semibold text-[1rem] text-jumbleng-gray-main">Catalogue bio</h3>
                        <div className="flex mt-2 text-jumbleng-gray justify-between items-center">
                            <p className="w-[50rem]">
                                Help protect your account from unauthorized access by requiring a second authentication method in addition to your
                                password.
                            </p>
                            <div
                                onClick={() => setEditModal(true)}
                                className="flex rounded-xl cursor-pointer  items-center justify-center bg-jumbleng-primary  px-[1.18rem] py-[0.7rem] w-[10rem]"
                            >
                                <div className="font-normal text-[1rem] text-white ">
                                    <i className="fa fa-pencil mr-2"></i>Edit bio
                                </div>
                                <Modal showModal={editModal} setEditModal={setEditModal} />
                            </div>
                        </div>
                    </div>
                    <div className=" mb-[2.35rem] w-full">
                        <h3 className=" font-semibold text-[1rem] text-jumbleng-gray-main">Make catalogue private</h3>
                        <div className="flex mt-2 text-jumbleng-gray justify-between items-center">
                            <p className="w-[50rem]">
                                Restrict access to user viewing your catalogue when they are not connected to you on the platform.
                            </p>
                            {company.private ? (
                                <div onClick={setPrivate}>
                                    <i className="fa-solid fa-toggle-on text-[18px] cursor-pointer "></i>{' '}
                                </div>
                            ) : (
                                <div onClick={setPrivate}>
                                    <i className="fa-solid fa-toggle-off text-[18px] cursor-pointer"></i>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Catalogue;
