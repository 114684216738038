import { useSelector } from 'react-redux';
import Popup from 'reactjs-popup';

import { RootState } from '../../../redux/store';
import Customerslist from './Customerslist';

const AddVendor = ({ data, handleChange, query, setQuery }: any) => {
    const { customers } = useSelector((state: RootState) => state.globalReducer);
    const authStore = useSelector((state: RootState) => state.authStore);
    const { company } = authStore;

    return (
        <div className="flex justify-between">
            {customers.length !== 0 && (
                <div>
                    <div className="flex gap-2 items-center mt-[36px] mb-8 cursor-pointer text-jumbleng-gray ">
                        <p className=" font-semibold"> {customers.length} customers added</p>
                    </div>
                </div>
            )}
            {company.company_type !== 'retailer' && (
                <Popup
                    trigger={
                        <div className="flex gap-2 items-center mt-[36px] cursor-pointer text-[#5E5BA7] ">
                            <i className="fa-solid fa-user-plus"></i>
                            <p className=" font-semibold"> Add Customers</p>
                        </div>
                    }
                    position="right center"
                    modal
                    contentStyle={{ width: 'fit-content' }}
                >
                    {(close: () => void) => (
                        <Customerslist
                            close={close}
                            data={data}
                            handleChange={handleChange}
                            query={query}
                            setQuery={setQuery}
                            customers={customers}
                        />
                    )}
                </Popup>
            )}
        </div>
    );
};

export default AddVendor;
