import { ErrorMessage, Field, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { IinitialBank } from '../../interfaces/schema-interface/bank-payment';
import { fetchAccounts, fetchBanks } from '../../redux/features/bank-payment/bankRedux';
import { addAccount, resolveAccount } from '../../redux/features/bank-payment/bankService';
import { bankTypes } from '../../redux/features/interfaces';
import { closeModal, openModal } from '../../redux/modal/modalRedux';
import { RootState } from '../../redux/store';
import { InitialBankSchema, initialBankValues } from '../../schema/bank-payment.schema';
import Button from '../Button';
import LoadingModal from '../LoadingModal/LoadingModal';

interface IProps {
    close: () => void;
    setContent: Dispatch<SetStateAction<'Empty' | 'Filled' | 'Form'>>;
}

const AddAccountPaymentSettings: FC<IProps> = ({ close, setContent }) => {
    const { banks } = useSelector((state: RootState) => state.bankStore);
    const [buttonLoad, setButtonLoad] = useState(false);
    const valuesRef = useRef<any>(null);
    const [resolveError, setResolveError] = useState('');
    const [account_name, setAccountName] = useState('');
    const dispatch = useDispatch();

    const handleOnSubmit = async (values: IinitialBank) => {
        try {
            const value = values.bank_name.split(',');
            const bank_name = value[0];
            const bank_code = value[1];
            setButtonLoad(true);
            const response = await addAccount({ ...values, bank_name, bank_code, account_name });
            dispatch(fetchAccounts());
            setContent('Filled');
            toast.success(response.message);
            setButtonLoad(false);
        } catch (err: any) {
            toast.error(err.response.data?.message);
            setButtonLoad(false);
        }
    };

    useEffect(() => {
        dispatch(fetchBanks());
    }, [dispatch]);

    const ResolveAccount = async () => {
        const { bank_name, account_number } = valuesRef.current.values;
        const bank_code = bank_name.split(',')[1];
        try {
            dispatch(openModal());
            const response = await resolveAccount(`account_number=${account_number}&bank_code=${bank_code}`);
            setAccountName(response.data.account_name);
            dispatch(closeModal());
        } catch (err: any) {
            setResolveError(err.response?.data?.message);
            setAccountName('');
            dispatch(closeModal());
        }
    };

    useEffect(() => {
        const timeId = setTimeout(() => {
            setResolveError('');
        }, 3000);
        return () => {
            clearTimeout(timeId);
        };
    }, [resolveError]);
    return (
        <>
            <LoadingModal />
            <Formik onSubmit={handleOnSubmit} innerRef={valuesRef} initialValues={initialBankValues} validationSchema={InitialBankSchema}>
                {({ handleSubmit }) => (
                    <>
                        <p className="ml-8 text-[#131221] font-bold">Add new Account</p>
                        <div className="ml-8">
                            <p className="text-[#525171] text-[16px] mt-4">Tax identification number (TIN)</p>
                            <div className="w-[32.7rem] ">
                                <div className="border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]">
                                    <div className="field__container">
                                        <Field
                                            onKeyDown={(e: any) => {
                                                e.key === 'Enter' && handleSubmit();
                                            }}
                                            type={`text`}
                                            name={'tin'}
                                            placeholder={'Input your TIN'}
                                            className={'w-full  placeholder:text-[0.78125rem] -mt-2 focus:outline-none'}
                                        />
                                    </div>
                                </div>
                                <ErrorMessage
                                    name={'tin'}
                                    render={(msg) => <div className="text-[0.7812rem] text-red-600 text-left font-normal">{msg}</div>}
                                />
                            </div>
                        </div>

                        <div className="ml-8">
                            <p className="text-[#525171] text-[16px] mt-4">Bank name</p>
                            <div className="w-[32.7rem] ">
                                <div className="border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]">
                                    <div className="field__container">
                                        <Field
                                            as={'select'}
                                            onKeyDown={(e: any) => {
                                                e.key === 'Enter' && handleSubmit();
                                            }}
                                            type={`text`}
                                            name={'bank_name'}
                                            placeholder={'Select bank name'}
                                            className={'w-full  placeholder:text-[0.78125rem] -mt-2 focus:outline-none'}
                                        >
                                            <option value="">Select category </option>
                                            {banks &&
                                                banks.map((item: bankTypes) => (
                                                    <option key={item.code} value={[item.name, item.code]}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                        </Field>
                                    </div>
                                </div>
                                <ErrorMessage
                                    name={'bank_name'}
                                    render={(msg) => <div className="text-[0.7812rem] text-red-600 text-left font-normal">{msg}</div>}
                                />
                            </div>
                        </div>
                        <div className="ml-8">
                            <p className="text-[#525171] text-[16px] mt-4">Bank Account number</p>
                            <div className="w-[32.7rem]   ">
                                <div className="border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]">
                                    <div className="field__container">
                                        <Field
                                            onKeyDown={(e: any) => {
                                                e.key === 'Enter' && handleSubmit();
                                            }}
                                            onBlur={ResolveAccount}
                                            type={`text`}
                                            name={'account_number'}
                                            placeholder={'Enter account number'}
                                            className={'w-full  placeholder:text-[0.78125rem] -mt-2 focus:outline-none'}
                                        />
                                    </div>
                                </div>
                                {resolveError && (
                                    <div className="text-[12px] text-red-500">
                                        <i className="fa-solid  fa-circle-exclamation "></i> {resolveError}
                                    </div>
                                )}
                                <ErrorMessage
                                    name={'account_number'}
                                    render={(msg) => <div className="text-[0.7812rem] text-red-600 text-left font-normal">{msg}</div>}
                                />
                            </div>
                        </div>
                        <div className="ml-8">
                            <p className="text-[#525171] text-[16px] mt-4">Bank account name</p>
                            <div className="w-[32.7rem]   ">
                                <div className="border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]">
                                    <div className="field__container">
                                        <Field
                                            onKeyDown={(e: any) => {
                                                e.key === 'Enter' && handleSubmit();
                                            }}
                                            type={`text`}
                                            readOnly
                                            name={'account_name'}
                                            value={account_name}
                                            placeholder={'Enter account name'}
                                            className={
                                                'w-full  placeholder:text-[0.78125rem] bg-[#F2F2F2] color-[#C6C6C6] border border-1  h-[2rem] -mt-2 focus:outline-none'
                                            }
                                        />
                                    </div>
                                </div>
                                <ErrorMessage
                                    name={'account_name'}
                                    render={(msg) => <div className="text-[0.7812rem] text-red-600 text-left font-normal">{msg}</div>}
                                />
                            </div>
                        </div>
                        <div className="ml-8">
                            <div className="w-[32.7rem]  mt-3 ">
                                <div className="flex items-center justify-left gap-2">
                                    <Field type="checkbox" name={'main'} className={' placeholder:text-[0.78125rem] focus:outline-none'} />
                                    <p className="text-jumbleng-gray">Use as main account</p>
                                </div>
                                <ErrorMessage
                                    name={'main'}
                                    render={(msg) => <div className="text-[0.7812rem] text-red-600 text-left font-normal">{msg}</div>}
                                />
                            </div>
                        </div>

                        <div className=" ml-8 mt-8 flex justify-between gap-2 mr-12">
                            <div>
                                <div
                                    style={{ border: '1px solid #5E5BA7' }}
                                    className=" cursor-pointer rounded-lg grid justify-center py-2 w-[110px]"
                                    onClick={close}
                                >
                                    <p className="font-semibold text-[#5E5BA7] text-[16px] "> Cancel</p>
                                </div>
                            </div>
                            <Button
                                type={'button'}
                                disabled={isEmpty(account_name) ? true : false}
                                onClick={handleSubmit}
                                message={'Add Account'}
                                loading={buttonLoad}
                                className={`w-[10rem] ${
                                    !account_name && 'cursor-not-allowed opacity-50'
                                } text-[16px] h-[3.125rem] text-white font-semibold rounded-[12px] bg-jumbleng-primary`}
                            />
                        </div>
                    </>
                )}
            </Formik>
        </>
    );
};

export default AddAccountPaymentSettings;
