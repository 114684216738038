import axios from 'axios';
const { REACT_APP_BACKEND_API } = process.env;

export const getMyCatalogue = async (query: string): Promise<any> => {
    return (await axios.get(`${REACT_APP_BACKEND_API}/v1/catalogue?${query}`)).data;
};
export const getCategories = async (): Promise<any> => {
    return (await axios.get(`${REACT_APP_BACKEND_API}/v1/product/categories`)).data;
};
export const getuom = async (): Promise<any> => {
    return (await axios.get(`${REACT_APP_BACKEND_API}/v1/product/uom`)).data;
};
export const getNextSKU = async (): Promise<any> => {
    return (await axios.get(`${REACT_APP_BACKEND_API}/v1/product/sku`)).data;
};
export const getSubUOM = async (uom: string): Promise<any> => {
    return (await axios.get(`${REACT_APP_BACKEND_API}/v1/product/uom/${uom}/subtype`)).data;
};

export const createProduct = async (values: any): Promise<any> => {
    const instance = axios.create();
    delete instance.defaults.headers.common.authorization;
    const formData = new FormData();
    formData.append('file', values.picture);
    formData.append('upload_preset', 'jumbleng-test');
    let imageUrl: '';

    return instance
        .post('https://api.cloudinary.com/v1_1/dduma7jcf/image/upload', formData)
        .then(({ data }) => {
            imageUrl = data.secure_url;
        })
        .then(async () => {
            return await axios.post(`${REACT_APP_BACKEND_API}/v1/product`, {
                ...values,
                unit_price: Number(Number(values.unit_price).toFixed(2)),
                weight: Number(Number(values.weight).toFixed(2)),
                picture: imageUrl,
            });
        });
};

export const updateProduct = async (id: string, values: any): Promise<any> => {
    const instance = axios.create();
    delete instance.defaults.headers.common.authorization;
    const formData = new FormData();
    formData.append('file', values.picture);
    formData.append('upload_preset', 'jumbleng-test');
    let imageUrl: '';
    return await instance
        .post('https://api.cloudinary.com/v1_1/dduma7jcf/image/upload', formData)
        .then(({ data }) => {
            imageUrl = data.secure_url;
        })
        .then(async () => {
            return await axios.put(`${REACT_APP_BACKEND_API}/v1/product/${id}`, {
                ...values,
                unit_price: Number(Number(values.unit_price).toFixed(2)),
                weight: Number(Number(values.weight).toFixed(2)),
                picture: imageUrl,
            });
        });
};

export const deleteProduct = async (productID: string) => {
    return await axios.delete(`${REACT_APP_BACKEND_API}/v1/product/${productID}`);
};
