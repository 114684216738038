import { initialCartState } from '../dtos';
import { CartItem, IinitialCartInterface } from '../interfaces';
import { CART_ADD_ITEM, CART_CLEAR_ITEMS, CART_REMOVE_ITEM, DECREMENT_QTY, GET_CART_ITEM, INCREMENT_QTY, UPDATE_QTY } from './CartActionTypes';

export const cartReducer = (state: IinitialCartInterface = initialCartState, action: any) => {
    switch (action.type) {
        case CART_ADD_ITEM:
            const item: CartItem = action.payload;
            const existItem = state.cartItems
                .filter((item: any) => item.userId === action.payload.userId)
                .find((cartItem: CartItem) => cartItem.id === item.id);

            if (existItem) {
                const updatedItems = state.cartItems.map((cartItem: CartItem) =>
                    cartItem.id === existItem.id && cartItem.userId === existItem.userId
                        ? {
                              ...cartItem,
                              qty: (cartItem.qty += 1),
                          }
                        : cartItem,
                );
                return {
                    ...state,
                    cartItems: updatedItems,
                };
            } else {
                item.qty = 1;
                return {
                    ...state,
                    cartItems: [...state.cartItems, item],
                };
            }
        case DECREMENT_QTY:
            const existingItem = state.cartItems
                .filter((item: any) => item.userId === action.payload.userId)
                .find((cartItem: CartItem) => cartItem.id === action.payload.id);
            const newItems = state.cartItems.map((cartItem: CartItem) =>
                cartItem.id === existingItem?.id && cartItem.userId === existingItem.userId ? { ...cartItem, qty: (cartItem.qty -= 1) } : cartItem,
            );
            //remove item from cart if the quantity is equal to zero
            const newItem = newItems.filter((cartItem: CartItem) => cartItem.qty !== 0).map((cartItem: CartItem) => cartItem);
            return {
                ...state,
                cartItems: newItem,
            };

        case INCREMENT_QTY:
            const existedItem = state.cartItems
                .filter((item: any) => item.userId === action.payload.userId)
                .find((cartItem: CartItem) => cartItem.id === action.payload.id);
            const latestItems = state.cartItems.map((cartItem: CartItem) =>
                cartItem.id === existedItem?.id && cartItem.userId === existedItem.userId
                    ? {
                          ...cartItem,
                          qty: (cartItem.qty += 1),
                      }
                    : cartItem,
            );
            return {
                ...state,
                cartItems: latestItems,
            };

        case UPDATE_QTY:
            const itemExist = state.cartItems
                .filter((item: any) => item.userId === action.payload.userId)
                .find((cartItem: CartItem) => cartItem.id === action.payload.id);
            const Items = state.cartItems.map((cartItem: CartItem) =>
                cartItem.id === itemExist?.id && cartItem.userId === itemExist.userId
                    ? {
                          ...cartItem,
                          qty: Number(action.payload.value),
                      }
                    : cartItem,
            );
            return {
                ...state,
                cartItems: Items,
            };
        case CART_REMOVE_ITEM:
            const updatedItem = state.cartItems
                .filter((cartItem: CartItem) => cartItem.id !== action.payload.id || cartItem.userId !== action.payload.userId)
                .map((cartItem: CartItem) => cartItem);
            return {
                ...state,
                cartItems: updatedItem,
            };

        case CART_CLEAR_ITEMS:
            const items = state.cartItems
                .filter((cartItem: CartItem) => cartItem.userId !== action.payload.userId)
                .map((cartItem: CartItem) => cartItem);
            return {
                ...state,
                cartItems: items,
            };
        case GET_CART_ITEM:
            return {
                ...state,
                cartItems: action.payload,
            };
        default:
            return state;
    }
};
