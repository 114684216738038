import { FC, SyntheticEvent } from 'react';
import { connect } from 'react-redux';

import Cancel from '../../assets/images/svg/cancel.svg';
import Edit from '../../assets/images/svg/edit.svg';
import More from '../../assets/images/svg/more-horizontal.svg';
import Trash from '../../assets/images/svg/trash-2.svg';
import PermissionsGate from '../../HOC/PermissionGate';
import { actions, Iaction, selectedPublishProductFieldType } from '../../redux/features/product/productRedux';
import { SCOPES } from '../../utils/permission';
import { toLocaleFixed } from '../../utils/utils';

interface ProductCardProps {
    showMore: boolean;
    toggleMore: (e: SyntheticEvent, product: Record<string, unknown>) => void;
    setShowDeleteProduct: (productID: string, productName: string) => void;
    product: any;
    id: string;
    clickedProduct: any;
    showPublishProductModal: () => void;
    toggleViewProductDetails: (product: Record<string, unknown>, edit?: boolean) => void;
    setSelectedPublishProductId: (productId: string) => Iaction;
    setSelectedPublishProductField: (data: selectedPublishProductFieldType) => Iaction;
}

const ProductCard: FC<ProductCardProps> = (props) => {
    const {
        showMore,
        toggleMore,
        setShowDeleteProduct,
        product,
        clickedProduct,
        toggleViewProductDetails,
        showPublishProductModal,
        setSelectedPublishProductId,
        setSelectedPublishProductField,
    } = props;

    const setPublishProductCred = (productId: string) => {
        setSelectedPublishProductId(productId);
        showPublishProductModal();
    };

    const item = product.product;
    return (
        <>
            {/* <Backdrop showModal={clickedProduct} /> */}
            <div className="w-[15.55rem] relative h-[15rem]">
                <div className="w-full h-[7.4rem] relative">
                    <img src={item.picture} alt="product_name" className="w-full h-full object-cover" />
                    <div className="w-[102px] text-[#084F21] rounded-[14px] grid place-items-center h-[30px] absolute bottom-[0.78125rem] bg-[#DEEAE2] text-[0.68rem] right-[0.78125rem]">
                        {!item.published === true ? 'Published' : 'Unpublished'}
                    </div>
                    <PermissionsGate scopes={[SCOPES.can_create_products]}>
                        <div
                            onClick={(e) => toggleMore(e, item)}
                            className="cursor-pointer z-40 w-[1.9rem] grid place-items-center rounded-[10px] h-[1.9rem] absolute bg-[rgba(0,0,0,0.69)] top-[0.78165rem] right-[0.78165rem]"
                        >
                            <img src={More} alt="more" />
                        </div>
                    </PermissionsGate>
                </div>
                <div className="shadow-md p-[0.78125rem] w-full cursor-pointer" onClick={() => toggleViewProductDetails(item)}>
                    <p className="font-bold text-[0.78125rem] mb-[0.78125rem]">{item.name}</p>
                    {/* <div className="flex justify-between font-normal text-[0.68125rem] mb-[1.17rem]">
                        <p className="">
                            <span className="">Available:</span> 2,051,053 bags
                        </p>
                        <p className="">
                            <span className="">No sold:</span> 2,000 bags
                        </p>
                    </div> */}

                    <p className="font-black text-[1.36rem] text-jumbleng-gray">
                        ₦ {toLocaleFixed(item.unit_price, 2)} <span className="text-[0.78125rem] font-normal">/ {item.uom}</span>
                    </p>
                </div>
                {showMore && clickedProduct.id === item.id && (
                    <>
                        <div className="absolute w-full h-full z-[49] top-0 left-0" />
                        <div className="absolute shadow-lg shadow-[rgba(0,0,0,0.26)] h-[9.5rem] w-36 bg-white rounded-xl top-[10px] right-[10px] p-[1.17rem] z-50">
                            <div
                                className="w-24 cursor-pointer h-8 flex font-normal text-[0.78125rem] text-jumbleng-gray items-center"
                                onClick={() => {
                                    setSelectedPublishProductId(item.id);
                                    setSelectedPublishProductField({
                                        bar_code: item.bar_code,
                                        name: item.name,
                                        description: item.description,
                                        sku: item.sku,
                                        quantity: item.quantity,
                                        uom: item.uom,
                                        unit_price: item.unit_price,
                                        picture: item.picture,
                                        product_category: item.product_category,
                                        weight: item.weight,
                                        min_order: item.min_order,
                                        max_order: item.max_order,
                                        sub_uom: item.sub_uom,
                                        vat: item.vat,
                                        discount: item.discount,
                                    });
                                    toggleViewProductDetails(item, true);
                                }}
                            >
                                <img src={Edit} alt="_edit" className="w-[1.17rem] h-[1.17rem] mr-4" />
                                Edit
                            </div>
                            <div
                                onClick={() => setPublishProductCred(item.id)}
                                className="w-24 cursor-pointer h-8 flex font-normal text-[0.78125rem] text-jumbleng-gray items-center"
                            >
                                <img src={Cancel} alt="_edit" className="w-[1.17rem] h-[1.17rem] mr-4" />
                                Unpublish
                            </div>
                            <div
                                onClick={() => setShowDeleteProduct(item.id, item.name)}
                                className="w-24 cursor-pointer h-8 flex font-normal text-[0.78125rem] text-[#840B0B] items-center"
                            >
                                <img src={Trash} alt="_edit" className="w-[1.17rem] h-[1.17rem] mr-4" />
                                Delete
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default connect(null, actions)(ProductCard);
