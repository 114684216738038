import { ErrorMessage, Field, Formik } from 'formik';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import X from '../../../assets/images/svg/x.svg';
import { categoriesType, uomType } from '../../../pages/product/Product';
import { actions, Iaction, productFieldType, selectedPublishProductFieldType } from '../../../redux/features/product/productRedux';
import { getSubUOM } from '../../../redux/features/product/productService';
import { addProductSchema, initialAddProductValues, initialEditProductValues } from '../../../schema';
import { parseAndHandleChange } from '../../../utils/utils';
import Button from '../../Button';

interface Form1Props {
    product_field: productFieldType;
    nextSKU: any;
    setCreateProductField: (payload: any) => Iaction;
    setEditProductField: (payload: any) => Iaction;
    setAddProduct?: () => void;
    setEditProduct?: () => void;
    setProductForm: Dispatch<SetStateAction<string>>;
    setModal: Dispatch<SetStateAction<boolean>>;
    updateCatalogueList: (payload: productFieldType) => Iaction;
    fetchProducts?: () => Promise<void>;
    uom?: uomType[];
    categories?: categoriesType[];
    toggleEditProductModal?: () => void;
    selectedPublishProductField?: selectedPublishProductFieldType;
    isEdit?: boolean;
}

const Form1: FC<Form1Props> = ({
    uom,
    categories,
    setModal,
    setEditProductField,
    selectedPublishProductField,
    setEditProduct,
    isEdit,
    product_field,
    nextSKU,
    setAddProduct,
    setProductForm,
    setCreateProductField,
}) => {
    const [subUOM, setSubUOM] = useState([]);
    const valuesRef = useRef<any>(null);

    const submitHandler = (values: productFieldType) => {
        !isEdit ? setCreateProductField({ ...values }) : setEditProductField(values);
        setProductForm('FORM2');
    };
    const handleClose = () => {
        setCreateProductField({ initialAddProductValues, ...nextSKU });
        setAddProduct && setAddProduct();
        setEditProduct && setEditProduct();
        setProductForm('FORM1');
        setModal(false);
    };

    const fetchSubUOM = useCallback(
        async (uom: string, setFieldValue: any) => {
            setFieldValue('sub_uom', '');
            const response = await getSubUOM(uom);
            setSubUOM(response.data);
        },
        [],
    );
    useEffect(() => {
        const { uom } = valuesRef.current.values;
        if (uom) {
            getSubUOM(uom)
                .then((response) => {
                    setSubUOM(response.data);
                })
                .catch((error) => {
                    return error;
                });
        }
    }, [uom]);

    return (
        <>
            <Formik
                onSubmit={submitHandler}
                innerRef={valuesRef}
                initialValues={
                    !isEdit
                        ? { ...initialAddProductValues, ...product_field }
                        : {
                              ...initialEditProductValues,
                              ...selectedPublishProductField,
                          }
                }
                validationSchema={addProductSchema}
            >
                {({ values, handleSubmit, setFieldValue, errors, touched, setFieldTouched }) => (
                    <>
                        <form className="bg-white w-[90%] md:w-[29rem] px-7  h-[95%] my-0 overflow-auto rounded-[0.585rem] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[56] md:px-[3.33rem]">
                            <div className="flex justify-between mt-[10px]">
                                <div />
                                <img src={X} alt="cancel" className="fixed right-5 hover:cursor-pointer" onClick={handleClose} />
                            </div>
                            <p className="text-center font-black mt-4 text-[1.37rem]">{isEdit ? 'Edit Product' : 'Add Product'}</p>
                            <p className="font-semibold text-base mb-4">Product details</p>
                            <div>
                                <div className="dStepBox">
                                    <div className="dStepPurple" /> <div className="dStepAsh" />
                                </div>
                                <p className="dStep">Step 1 of 2</p>
                            </div>
                            <div className="flex flex-col mb-[0.87rem]">
                                <label className="font-normal text-[0.78125rem] text-jumbleng-gray">Barcode number</label>
                                <Field
                                    name={'bar_code'}
                                    placeholder={'Input barcode'}
                                    onKeyUp={() => setFieldTouched('bar_code', true)}
                                    className="p-[0.585rem] text-jumbleng-gray focus:outline-none border-b-2 border-b-jumbleng-gray"
                                />
                                <ErrorMessage
                                    name={'bar_code'}
                                    render={(msg) => (
                                        <div className="text-[0.7812rem] text-red-400 text-left font-normal">{msg}</div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col mb-[0.87rem]">
                                <label className="font-bold text-[0.78125rem] text-jumbleng-primary">SKU (Stock keeping unit)</label>
                                <Field
                                    name={'sku'}
                                    placeholder={'sku'}
                                    onKeyUp={() => setFieldTouched('sku', true)}
                                    className="bg-[#F8F8FB] p-[0.585rem] border-b-2 focus:outline-none border-b-jumbleng-gray"
                                />
                                {errors.sku && touched.sku ? (
                                    <div className="text-[0.7812rem] text-red-600 text-left font-normal">{errors.sku}</div>
                                ) : null}
                            </div>
                            <div className="flex flex-col mb-[0.87rem]">
                                <label className="font-normal text-[0.78125rem] text-jumbleng-gray">Product name</label>
                                <Field
                                    name={'name'}
                                    onKeyUp={() => setFieldTouched('name', true)}
                                    className="p-[0.585rem] text-jumbleng-gray border-b-2 focus:outline-none border-b-jumbleng-gray"
                                />
                                {errors.name && touched.name ? (
                                    <div className="text-[0.7812rem] text-red-600 text-left font-normal">{errors.name}</div>
                                ) : null}
                            </div>
                            <div className="flex flex-col mb-[0.87rem]">
                                <label className="font-normal text-[0.78125rem] text-jumbleng-gray">Product description</label>
                                <Field
                                    name={'description'}
                                    placeholder={'Type here...'}
                                    onKeyUp={() => setFieldTouched('description', true)}
                                    className="p-[0.585rem] text-jumbleng-gray border-b-2 focus:outline-none border-b-jumbleng-gray"
                                />
                                {errors.description && touched.description ? (
                                    <div className="text-[0.7812rem] text-red-600 text-left font-normal">{errors.description}</div>
                                ) : null}
                            </div>

                            <div className="flex flex-col mb-[0.87rem]">
                                <label className="font-normal text-[0.78125rem] text-jumbleng-gray">Product category</label>
                                <Field
                                    name={'product_category'}
                                    as={'select'}
                                    onKeyUp={() => setFieldTouched('product_category', true)}
                                    className="focus:outline-none  p-[0.649rem] border-b-2 border-b-jumbleng-gray"
                                >
                                    <option value="">Select category </option>
                                    {categories &&
                                        categories.map((item: categoriesType, index: number) => (
                                            <option key={index} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                </Field>
                                {errors.product_category && touched.product_category ? (
                                    <div className="text-[0.7812rem] text-red-600 text-left font-normal">{errors.product_category}</div>
                                ) : null}
                            </div>

                            <div className="grid grid-cols-2 mb-[0.87rem] gap-4">
                                <div className="flex flex-col">
                                    <label className="font-normal text-[0.78125rem] text-jumbleng-gray">Quantity available</label>
                                    <Field
                                        name={'quantity'}
                                        onKeyDownCapture={(e: any) => e.key === '.' && e.preventDefault()}
                                        value={values.quantity}
                                        onChange={(e: any) => parseAndHandleChange(e.target.value, setFieldValue, 'quantity')}
                                        placeholder={'00'}
                                        onKeyUp={() => setFieldTouched('quantity', true)}
                                        className="p-[0.585rem] text-jumbleng-gray border-b-2 focus:outline-none border-b-jumbleng-gray"
                                    />
                                    {errors.quantity && touched.quantity ? (
                                        <div className="text-[0.7812rem] text-red-600 text-left font-normal">{errors.quantity}</div>
                                    ) : null}
                                </div>

                                <div className="flex flex-col">
                                    <label className="font-normal text-[0.78125rem] text-jumbleng-gray">Unit of measurement</label>
                                    <Field
                                        name={'uom'}
                                        disabled={isEdit && true}
                                        as={'select'}
                                        onKeyUp={() => setFieldTouched('uom', true)}
                                        className="focus:outline-none p-[0.649rem] border-b-2 border-b-jumbleng-gray"
                                        onChange={(e: any) => (setFieldValue('uom', e.target.value), fetchSubUOM(e.target.value, setFieldValue))}
                                    >
                                        <option value="">Select uom </option>
                                        {uom &&
                                            uom.map((item: uomType, index: number) => (
                                                <option key={index} value={item.key}>
                                                    {item.value}
                                                </option>
                                            ))}
                                    </Field>
                                    {errors.uom && touched.uom ? (
                                        <div className="text-[0.7812rem] text-red-600 text-left font-normal">{errors.uom}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="grid grid-cols-2 gap-4 mb-[0.87rem]">
                                <div className="flex flex-col">
                                    <label className="font-normal text-[0.78125rem] text-jumbleng-gray">Product weight</label>
                                    <Field
                                        name={'weight'}
                                        onKeyUp={() => setFieldTouched('weight', true)}
                                        placeholder={'00.00'}
                                        className="p-[0.585rem] text-jumbleng-gray border-b-2 focus:outline-none border-b-jumbleng-gray"
                                    />
                                    {errors.weight && touched.weight ? (
                                        <div className="text-[0.7812rem] text-red-600 text-left font-normal">{errors.weight}</div>
                                    ) : null}
                                </div>
                                <div className="flex flex-col">
                                    <label className="font-normal text-[0.78125rem] text-jumbleng-gray">Sub-unit of measurement</label>
                                    <Field
                                        disabled={isEdit && true}
                                        name={'sub_uom'}
                                        as={'select'}
                                        onKeyUp={() => setFieldTouched('sub_uom', true)}
                                        className="focus:outline-none p-[0.649rem] border-b-2 border-b-jumbleng-gray"
                                    >
                                        <option value="">Select sub-uom </option>
                                        {subUOM &&
                                            subUOM.map((item: any, index: number) => (
                                                <option key={index} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                    </Field>
                                    {errors.sub_uom && touched.sub_uom ? (
                                        <div className="text-[0.7812rem] text-red-600 text-left font-normal">{errors.sub_uom}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="grid grid-cols-2 gap-4 mb-[0.87rem]">
                                <div className="flex flex-col">
                                    <label className="font-normal text-[0.78125rem] text-jumbleng-gray">Minimum order</label>
                                    <Field
                                        name={'min_order'}
                                        placeholder={'00'}
                                        onKeyUp={() => setFieldTouched('min_order', true)}
                                        onKeyDownCapture={(e: any) => e.key === '.' && e.preventDefault()}
                                        value={values.min_order}
                                        className="p-[0.585rem] text-jumbleng-gray border-b-2 focus:outline-none border-b-jumbleng-gray"
                                        onChange={(e: any) => parseAndHandleChange(e.target.value, setFieldValue, 'min_order')}
                                    />
                                    {errors.min_order && touched.min_order ? (
                                        <div className="text-[0.7812rem] text-red-600 text-left font-normal">{errors.min_order}</div>
                                    ) : null}
                                </div>
                                <div className="flex flex-col">
                                    <label className="font-normal text-[0.78125rem] text-jumbleng-gray">Maximum order</label>
                                    <Field
                                        onChange={(e: any) => parseAndHandleChange(e.target.value, setFieldValue, 'max_order')}
                                        value={values.max_order}
                                        onKeyDownCapture={(e: any) => e.key === '.' && e.preventDefault()}
                                        name={'max_order'}
                                        onKeyUp={() => setFieldTouched('max_order', true)}
                                        placeholder={'00'}
                                        className="p-[0.585rem] text-jumbleng-gray border-b-2 focus:outline-none border-b-jumbleng-gray"
                                    />
                                    {errors.max_order && touched.max_order ? (
                                        <div className="text-[0.7812rem] text-red-600 text-left font-normal">{errors.max_order}</div>
                                    ) : null}{' '}
                                </div>
                            </div>

                            <div className="flex justify-between mb-[2.34rem] mt-6">
                                <button
                                    onClick={handleClose}
                                    className="px-[1.56rem] text-jumbleng-primary md:py-[0.78125rem]  py-[0.45rem]  rounded-[0.585rem] border border-jumbleng-primary"
                                >
                                    Cancel
                                </button>
                                <Button
                                    type={'submit'}
                                    onClick={handleSubmit}
                                    message={'Continue'}
                                    loading={false}
                                    className={
                                        'px-[1.56rem] md:py-[0.78125rem] py-[0.45rem] rounded-[0.585rem] bg-jumbleng-primary text-white border border-jumbleng-primary'
                                    }
                                />
                            </div>
                        </form>
                    </>
                )}
            </Formik>
        </>
    );
};

export default connect((state: any) => {
    const { selectedPublishProductField, product_field, nextSKU } = state.ProductReducer;
    return { product_field, selectedPublishProductField, nextSKU };
}, actions)(Form1);
