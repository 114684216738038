import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import NotFound from '../../assets/images/png/no-image.jpg';
import EmptyRequest from '../../assets/images/svg/norequest.svg';
import PermissionsGate from '../../HOC/PermissionGate';
import { useApproveRequestMutation, useDeclineRequestMutation, useGetReceivedRequestsQuery } from '../../redux/services/supplyChainApi';
import { SCOPES } from '../../utils/permission';

const Requests = () => {
    const [approveRequest, { isLoading, isSuccess, isError, error }] = useApproveRequestMutation();
    const [declineRequest, { isLoading: loading, isSuccess: success, isError: iserror, error: err }] = useDeclineRequestMutation();
    const { data } = useGetReceivedRequestsQuery();

    const handleApprove = (id: string) => {
        approveRequest(id);
    };

    const handleDecline = (id: string) => {
        declineRequest(id);
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Request accepted successfully');
        }
        if (success) {
            toast.success('Request declined successfully');
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
        }
        if (iserror && err && 'status' in err) {
            toast.error(err?.data?.message);
        }
    }, [err, error, isError, isLoading, isSuccess, iserror, loading, success]);

    return (
        <div className="w-[600px]">
            {data.data.length === 0 && (
                <div data-testid="empty-message">
                    <div className="grid justify-center mt-4">
                        <img src={EmptyRequest} alt="empty" />
                    </div>
                    <div className="grid justify-center mt-4">
                        <p className="text-[28px]">No Request Received</p>
                    </div>
                    <div style={{ width: '458px' }} className="grid justify-center mt-4 text-center mx-auto">
                        <p>if you have not received a request yet, visit the feed or search to find a vendor to make a request</p>
                    </div>
                    <div>
                        <PermissionsGate scopes={[SCOPES.can_view_all_feed, SCOPES.can_view_connected_vendors_feed]}>
                            <Link to="/feed">
                                <div className="grid justify-center mt-4 cursor-pointer">
                                    <div style={{ backgroundColor: '#5E5BA7', borderRadius: '12px' }} className="px-[50px] py-[16px]">
                                        <p className="text-white">Visit Feed</p>
                                    </div>
                                </div>
                            </Link>
                        </PermissionsGate>
                    </div>
                </div>
            )}
            {data.data.length > 0 && (
                <div className="w-full flex justify-between text-[16px]  cursor-pointer">
                    <p className="text-jumbleng-gray">{data.data?.length} new requests</p>
                </div>
            )}
            <div className="flex flex-col gap-5 mt-4">
                {data.data.map((item: any) => (
                    <div className="flex items-center justify-between w-full" key={item.id}>
                        <div className="flex gap-2 items-center w-[14rem] ">
                            <div className="flex gap-2 items-center rounded-full min-w-[3.2rem]">
                                <img
                                    src={item.requesting_company.logo ? item.requesting_company.logo : NotFound}
                                    alt="avatar"
                                    className="h-[3.2rem] w-[3.2rem] object-contain"
                                />
                            </div>
                            <div className="max-w-[10.8rem] flex flex-col">
                                <p className="font-bold w-full break-words">{item.requesting_company.name}</p>
                                <p>{item.requesting_company.industry_type}</p>
                            </div>
                        </div>
                        <div className="flex gap-2">
                            <div
                                onClick={() => handleDecline(item.id)}
                                className="flex rounded-xl cursor-pointer items-center justify-center  border border-jumbleng-red text-jumbleng-red  px-[1.18rem] py-[0.7rem] w-[7rem]"
                            >
                                <div className="font-normal text-[1rem] ">
                                    <i className="fa fa-xmark mr-2"></i>Decline
                                </div>
                            </div>
                            <div
                                onClick={() => handleApprove(item.id)}
                                className="flex rounded-xl cursor-pointer text-white  items-center justify-center bg-jumbleng-primary  px-[1.18rem] py-[0.7rem] w-[7rem]"
                            >
                                <div className="font-normal text-[1rem] ">
                                    <i className="fa fa-user mr-2 text-white"></i>Accept
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Requests;
