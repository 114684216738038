import * as Yup from 'yup';

import { IinitialBank } from '../interfaces/schema-interface/bank-payment';

export const initialBankValues: IinitialBank = {
    tin: '',
    account_number: '',
    bank_name: '',
    main: false,
};

export const InitialBankSchema = Yup.object().shape({
    tin: Yup.string().required('tin field is required '),
    bank_name: Yup.string().required('bank_name field is required '),
    account_number: Yup.string().required('account_number field is required '),
    main: Yup.boolean().required('main field is required '),
});

const FILE_SIZE = 5072 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
export const ProofUploadSchema = Yup.object().shape({
    file: Yup.mixed()
        .nullable()
        .required('Image is required')
        .test('fileFormat', 'Unsupported File Format', (value) => value && SUPPORTED_FORMATS.includes(value.type))
        .test('fileSize', 'File is too large', (value) => value && value.size <= FILE_SIZE),
});
