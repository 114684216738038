import { Dispatch, FC, SetStateAction } from 'react';

import EmptyPayment from '../../assets/images/svg/emptyPayment.svg';

interface IProps {
    close: () => void;
    setContent: Dispatch<SetStateAction<'Empty' | 'Filled' | 'Form'>>;
}

const EmptyPaymentSettings: FC<IProps> = ({ setContent }) => {
    return (
        <>
            <div className="grid justify-center">
                <img src={EmptyPayment} alt="emptypay" />
            </div>
            <div className="grid justify-center text-[#131221] text-[28px] font-semibold mt-4 mb-4"> No account added yet</div>
            <div className="grid justify-center mb-4">
                <p className=" w-[376px] text-[#525171] "> You haven’t added any account to your account list.click</p>
                <p className=" w-[376px] text-[#525171] grid justify-center"> add an account to get started.</p>
            </div>
            <div className="grid justify-center">
                <div className="bg-[#5E5BA7] py-2 px-16 rounded-xl cursor-pointer" onClick={() => setContent('Form')}>
                    <p className="text-[white] font-semibold">Add an account</p>
                </div>
            </div>
            {/* <div className="grid justify-center">
                <Popup trigger={} modal contentStyle={{ width: '702px' }}>
                    {(close: ()=>void) => (
                        <div>
                            <AddAccountPaymentSettings close={close} />
                        </div>
                    )}
                </Popup>
            </div> */}
        </>
    );
};

export default EmptyPaymentSettings;
