import * as Yup from 'yup';

export interface IDiscountForm {
    unit: string;
    value: string;
    start_date: string;
    end_date: string;
    discount_type: string;
}

export const initialDiscountFormValues: IDiscountForm = {
    unit: '',
    value: '',
    start_date: '',
    end_date: '',
    discount_type: '',
};

const date = new Date();
export const DiscountFormSchema = Yup.object().shape({
    unit: Yup.string().required('Discount Unit field is a required field'),
    value: Yup.number()
        .required('Discount Value field is a required field')
        .when('unit', (val, _schema) => {
            if (val) {
                if (val === 'Percentage') {
                    return Yup.number()
                        .required('Discount value is a required field')
                        .max(100, 'Value must be less than 100')
                        .typeError('Only digits are allowed');
                } else {
                    return Yup.string().required('Discount value is a required field');
                }
            } else {
                return Yup.string().required('Discount value is a required field');
            }
        }),
    start_date: Yup.date()
        .required('start date field is a required field')
        .min(new Date(date.setDate(date.getDate() - 1)), "Start date must be not be less than today's date")
        .nullable(),
    end_date: Yup.date()
        .required('end date field is a required field')
        .when('start_date', (val, _schema) => {
            if (val) {
                return Yup.date().required('end date field is a required field').min(val, 'End date must be greater than start date');
            } else {
                return Yup.date().required('end date field is a required field');
            }
        })
        .nullable(),
    discount_type: Yup.string().required('Select at least one product'),
});
