import { Dispatch } from 'redux';

import { types } from './PaymentActionTypes';

export const listMyPayments = (currentStatus: string) => async (dispatch: Dispatch) => {
    dispatch({ type: types.setPStatus, payload: currentStatus });
};

export const listCustomerPayments = (currentStatus: string) => async (dispatch: Dispatch) => {
    dispatch({ type: types.setCustomerPStatus, payload: currentStatus });
};
