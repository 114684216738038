import Spinner from '../../../components/UI/Spinner/Spinner';

const Subscribe = ({ data, close, isSubscribe, isLoading }: any) => {

    return (
        <>
            <div>
                <button className="close" onClick={close}>
                    &times;
                </button>
                <div className="flex items-center justify-center flex-col mt-8 mx-4">
                    <p className="border rounded-[50%] bg-[#F5E7E7] w-[40px] h-[40px] p-1 flex items-center justify-center">
                        <i className="fa fa-triangle-exclamation text-[#9F0C0D] "></i>
                    </p>
                    <p className="text-[#5E5BA7] mt-8 text-center">
                        Are you sure you want to {isSubscribe ? 'subscribe' : 'upgrade'} to {data?.name} {data?.plan} plan
                    </p>
                </div>
            </div>
            <div className="grid grid-cols-2 my-8 gap-4 mx-4">
                <div className="grid justify-center py-4 border-2 border-[#5E5BA7] rounded-[12px]">
                    <p className="text-jumbleng-primary cursor-default" onClick={close}>
                        No, go back
                    </p>
                </div>
                <div className="grid justify-center py-4 bg-jumbleng-primary border rounded-[12px]" onClick={() => data?.action(data?.id, close)}>
                    {isLoading ? <Spinner /> : <p className="text-white cursor-default">Yes, {`${isSubscribe ? 'subscribe' : 'upgrade'}`}</p>}
                </div>
            </div>
        </>
    );
};

export default Subscribe;
