import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Loading from '../../../components/UI/Spinner/Loading';
import Wrapper from '../../../HOC/Wrapper';
import { useGetACustomerQuery, useGetDiscountUnitsQuery } from '../../../redux/services';
import { RootState } from '../../../redux/store';
import Discount from './Discount';
import ExpandedCustomerHeader from './Header';

export type customerQueryType = { page: number; limit: number; search_query: string; filter: string };
export const initialCustomerQuery: customerQueryType = {
    page: 1,
    limit: 10,
    search_query: '',
    filter: '',
};
const ExpandedCustomer = () => {
    const { id } = useParams();
    const [query, setQuery] = useState<customerQueryType>(initialCustomerQuery);
    const { data, isFetching } = useGetACustomerQuery(id as string);
    // const { data: buyingtrenddata, isLoading } = useGetCustomerBuyingTrendsQuery({ id: id as string, ...query });
    const { data: unitData, isLoading: unitsloading } = useGetDiscountUnitsQuery();

    const { customerVendorTab } = useSelector((state: RootState) => state.tabsReducer);

    if (isFetching || unitsloading) {
        return (
            <div className="min-h-[100vh] flex justify-center flex-col">
                <Loading />
            </div>
        );
    }
    return (
        <Wrapper>
            <ExpandedCustomerHeader data={data?.data} unitData={unitData?.data} />
            {
                // ) : customerVendorTab === 'currentInventory' ? (
                //     <CurrentInventory query={query} setQuery={setQuery} data={{ count: 0, data: [] }} />
                // ) : customerVendorTab === 'buyingTrend' ? (
                //     <BuyingTrend query={query} setQuery={setQuery} data={{ count: 0, data: [] }} buyingtrenddata={buyingtrenddata} />
                customerVendorTab === 'Discount' && (
                    <Discount query={query} setQuery={setQuery} data={{ count: 0, data: [] }} unitData={unitData?.data} />
                )
            }
        </Wrapper>
    );
};

export default ExpandedCustomer;
