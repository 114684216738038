import { FC } from 'react';

import DeleteIcon from '../../assets/images/svg/trash-2.svg';

interface DeleteProductProps {
    showDeleteProduct: boolean;
    toggleDeleteProduct: () => void;
    product: any;
    deleteProduct: (productID: string) => Promise<void>;
}

const DeleteProduct: FC<DeleteProductProps> = (props) => {
    const { showDeleteProduct, product, deleteProduct, toggleDeleteProduct } = props;

    if (!showDeleteProduct) return <></>;

    return (
        <div className="w-[453px] text-center p-[32px] bg-[#fff] rounded-2xl fixed z-[56] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="w-[40px] mx-auto mb-8 h-[40px] rounded-full bg-[#F5F5F6] grid place-content-center">
                <img src={DeleteIcon} alt="_delete" className="w-[1.17rem] h-[1.17rem]" />
            </div>

            <h3 className="text-xl font-semibold mb-4">Delete Product?</h3>

            <p className="text-base w-[235px] mx-auto mb-8">
                This action will permanently delete <span className="font-bold">{product.name}</span> fom your catalog
            </p>

            <div className="flex justify-around">
                <button onClick={toggleDeleteProduct} className="px-10 py-4 rounded-lg border border-jumbleng-primary">
                    No, keep it
                </button>
                <button onClick={() => deleteProduct(product.id)} className="px-10 py-4 bg-[#9F0D0D] text-white rounded-lg">
                    Yes, delete it
                </button>
            </div>
        </div>
    );
};

export default DeleteProduct;
