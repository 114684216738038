import { Dispatch, SetStateAction, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';

import avatar from '../assets/images/png/noImage.png';
import ArrowDown from '../assets/images/svg/arrow-down.svg';
import Avatar from '../assets/images/svg/avatar.svg';
import BellIcon from '../assets/images/svg/bell.svg';
import ClockIcon from '../assets/images/svg/clock.svg';
import HumanIcon from '../assets/images/svg/human.svg';
import LogoutIcon from '../assets/images/svg/logout.svg';
import Sliders from '../assets/images/svg/sliders.svg';
import LoadingModal from '../components/LoadingModal/LoadingModal';
import NotificationBox from '../components/Notification/NotificationBox';
import useGetCount from '../hooks/useGetCount';
import RequestModal from '../pages/requests/RequestModal';
import Kyc from '../pages/settings/Kyc';
import SettingsCompliance from '../pages/settings/SettingsCompliance';
import { actions } from '../redux/features/auth/authRedux';
import { useGetComplianceQuery } from '../redux/services/complianceApi';
import { setComplianceData } from '../redux/slices/global';
import { RootState } from '../redux/store';
import { SCOPES } from '../utils/permission';
import PermissionsGate from './PermissionGate';

type logoutType = () => void;

interface ILogout {
    children: any;
    logout: logoutType;
    query?: any;
    setQuery?: Dispatch<SetStateAction<any>>;
}
const WrapperVendor = (props: ILogout) => {
    const { children, logout, setQuery, query } = props;
    const { user, company } = useSelector((state: RootState) => state.authStore);
    const { complianceData } = useSelector((state: RootState) => state.globalReducer);
    const { unreadCount } = useGetCount();

    const navigate = useNavigate();
    const { data } = useGetComplianceQuery();
    const dispatch = useDispatch();
    const handleLogout = async () => {
        await logout();
        navigate('/');
    };
    const handleChange = (e: any) => {
        setQuery && setQuery({ ...query, search_query: e.target.value, page: 1 });
    };

    useEffect(() => {
        if (data) dispatch(setComplianceData(data?.data));
    }, [dispatch, data]);

    return (
        <div className="bg-jumbleng-background min-h-screen w-full px-[60px] pt-2">
            <LoadingModal />
            <div className="min-h-screen">
                <nav className="flex justify-between h-[3.8rem] items-center">
                    <Link to="/">
                        <img
                            src={!company.logo ? avatar : company.logo}
                            alt="company_logo"
                            className=" h-[2.9rem] w-[2.9rem] object-cover rounded-full"
                        />
                    </Link>
                    <div className="w-[22.6rem] h-[2.3rem] relative flex items-center gap-2">
                        <i className="fa fa-search icon absolute top-1/2 -translate-y-1/2 px-[0.79rem] text-xs" />
                        <input className="w-full text-input pl-8" placeholder="Search" onChange={handleChange} />
                        <div className="flex">
                            <span className="mr-2 text-base font-normal text-jumbleng-gray">Filter</span>
                            <img src={Sliders} alt="Filter" />
                        </div>
                    </div>

                    <Link to="/">
                        <div className="px-[11.5px] py-2 bg-black rounded-xl flex text-white font-semibold text-base">
                            <span className="pr-[14px]">Dashboard</span>
                            <img src={ArrowDown} alt="_" />
                        </div>
                    </Link>

                    <div className="flex items-center gap-4">
                        <Popup
                            trigger={
                                <div className="flex items-center cursor-pointer relative">
                                    <img src={BellIcon} alt="notification" className="w-[1.95rem] h-[1.0rem]" />
                                    <div className="rounded-full flex items-center justify-center absolute -top-2 -right-1 p-2  h-4 w-4 bg-jumbleng-primary text-white text-[12px]">
                                        {unreadCount}
                                    </div>
                                </div>
                            }
                            closeOnDocumentClick
                            arrow={false}
                            nested
                            position={'bottom center'}
                            contentStyle={{
                                padding: 0,
                                borderRadius: '12px',
                                fontSize: '0.8rem',
                                width: 'fit-content',
                                height: 'fit-content',
                                marginTop: '1.4rem',
                                overflow: 'hidden',
                            }}
                        >
                            <div className="w-[410px] max-h-[80vh]">
                                <div className="flex gap-2 justify-between sticky top-0 py-[1.5rem] px-[1.5rem] bg-[#f5f4f8] z-50 shadow-md">
                                    <div className="flex gap-4">
                                        <h2 className="font-bold">Notifications</h2>
                                        <div className="flex items-center gap-2">
                                            <p>All</p>
                                            <Popup
                                                trigger={
                                                    <div className="flex items-center justify-center cursor-pointer">
                                                        <img src={ArrowDown} alt="a-down" />
                                                    </div>
                                                }
                                                closeOnDocumentClick
                                                arrow={false}
                                                on={'hover' || 'click'}
                                                position={'bottom center'}
                                                contentStyle={{
                                                    padding: '1.5rem',
                                                    borderRadius: '12px',
                                                    fontSize: '0.8rem',
                                                    overflow: 'auto',
                                                }}
                                            >
                                                <></>
                                            </Popup>
                                        </div>
                                    </div>
                                    <Link to="/notifications" className="font-bold text-[#5E5BA7] focus:outline-none">
                                        View all
                                    </Link>
                                </div>
                                <div className="h-[70vh] overflow-scroll px-[1.5rem] pb-[1.5rem]">
                                    <div className="bg-[#EAE9F3] px-6 py-[18px] mb-2 shadow-md text-[#525171] rounded-[60px] flex gap-2 w-[204px] mt-10">
                                        <div className="flex items-center justify-center cursor-pointer relative">
                                            <img src={ClockIcon} alt="a-down" />
                                            <div className="rounded-full flex items-center justify-center absolute -top-2 -right-1 p-2  h-4 w-4 bg-jumbleng-primary text-white text-[12px]">
                                                2
                                            </div>
                                        </div>
                                        <p className="font-bold">View reminders</p>
                                    </div>
                                    <NotificationBox isvendor />
                                </div>
                            </div>
                        </Popup>
                        <PermissionsGate scopes={[SCOPES.can_manage_connections]}>
                            <Popup
                                trigger={<img src={HumanIcon} alt="self" className="w-[1.95rem] h-[1.95rem] cursor-pointer" />}
                                closeOnDocumentClick
                                arrow={false}
                                on={'hover' || 'click'}
                                position={'bottom center'}
                                contentStyle={{
                                    padding: '1.5rem',
                                    borderRadius: '12px',
                                    width: '500px',
                                    fontSize: '0.8rem',
                                    maxHeight: '500px',
                                    overflow: 'auto',
                                }}
                            >
                                <div>
                                    <RequestModal />
                                </div>
                            </Popup>{' '}
                        </PermissionsGate>
                        <Popup
                            trigger={
                                <div className="flex items-center justify-center cursor-pointer">
                                    <div className="w-[1.76rem] h-[1.76rem] ">
                                        <img
                                            src={!user?.image?.length ? Avatar : user.image}
                                            alt="self"
                                            className="w-full h-full object-cover pr-[0.78125rem]"
                                        />
                                    </div>
                                    <div className="pr-[0.78165rem]">
                                        <p className="font-bold text-[0.78125rem]">
                                            {user.first_name} {user.last_name}
                                        </p>
                                        <p className="font-normal text-[0.68rem]">{user.email}</p>
                                    </div>
                                    <img src={ArrowDown} alt="a-down" />
                                </div>
                            }
                            closeOnDocumentClick
                            arrow={false}
                            nested
                            position={'bottom center'}
                            contentStyle={{
                                padding: '1.5rem',
                                borderRadius: '12px',
                                fontSize: '0.8rem',
                                overflow: 'auto',
                            }}
                        >
                            <>
                                <div className="flex flex-col gap-4 items-start justify-center ">
                                    <div className="flex items-center cursor-pointer" onClick={handleLogout}>
                                        <img src={LogoutIcon} alt="self" className="w-[1.95rem] h-[1.0rem]" />
                                        <p className="text-[14px]">Logout</p>
                                    </div>
                                </div>
                            </>
                        </Popup>
                    </div>
                </nav>

                <main className="w-full h-full px-4 pb-[4.98rem]">
                    {' '}
                    {complianceData?.status === 'In Progress' || complianceData?.status === 'Declined' ? (
                        <Kyc />
                    ) : complianceData?.status === 'Not Submitted' || complianceData?.status === '' ? (
                        <SettingsCompliance />
                    ) : (
                        children
                    )}
                </main>
            </div>
        </div>
    );
};

export default connect(null, actions)(WrapperVendor);
