import moment from 'moment';
import React from 'react';

import { Tabled } from './styles';

const Table = ({
    theadData,
    tbodyData,
    setEditOpen,
    setDeleteOpen,
    setSelectedUser,
    ctaMemberId,
    setCtaMemberId,
}: {
    theadData?: any;
    tbodyData?: any;
    setEditOpen?: any;
    setDeleteOpen?: any;
    setSelectedUser?: any;
    ctaMemberId: any;
    setCtaMemberId: any;
}) => {
    const handleCtaClick = (index: any) => {
        if (typeof ctaMemberId == 'number') {
            setCtaMemberId(null);
        } else {
            setCtaMemberId(index);
        }
    };

    return (
        <Tabled>
            <table>
                <thead>
                    <tr>
                        {theadData?.map((h: any, i: number) => {
                            return (
                                <th key={i} className={`${h === 'Action' && 'pr-10'}`}>
                                    {h}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {tbodyData()?.map((item: any, index: number) => {
                        return (
                            <tr key={index} className={index % 2 == 0 ? 'even__row' : 'odd__row'}>
                                <td className="">
                                    <div className="flex gap-3 ">
                                        <img
                                            src="https://www.svgrepo.com/show/18074/avatar.svg"
                                            alt=""
                                            width="40px"
                                            height="40px"
                                            className="rounded-full ml-20"
                                        />
                                        <p className="flex flex-col w-fit items-start">
                                            {item.first_name} {item.last_name} <span>{item.email}</span>
                                        </p>
                                    </div>
                                </td>
                                <td style={{ textTransform: 'capitalize' }}>{item.role.replaceAll('_', ' ')}</td>
                                <td>{item.createdAt}</td>
                                <td>{moment(item.lastActive).format('MMM Do YYYY')}</td>
                                <td>
                                    <div className={item.suspended ? 'status_disabled' : item.pending ? 'status_pend' : 'status_active'}>
                                        <div className="dot_around">
                                            <div className="dot" />
                                        </div>
                                        {item.pending ? 'pending' : item.suspended ? 'suspended' : 'active'}
                                    </div>
                                </td>
                                <td className="pr-10">
                                    <div
                                        className="cta__dots flex items-center -mt-4 w-full justify-center text-center"
                                        onClick={() => handleCtaClick(index)}
                                    >
                                        ...
                                    </div>
                                    {ctaMemberId === index && (
                                        <div className="cta__menu">
                                            <p
                                                onClick={() => {
                                                    setEditOpen(true);
                                                    setCtaMemberId(null);
                                                    setSelectedUser(item);
                                                }}
                                            >
                                                Edit member
                                            </p>
                                            <p>Suspend member</p>
                                            <p>Assign to vendor</p>
                                            <p>Reset password</p>
                                            <p
                                                onClick={() => {
                                                    setDeleteOpen(true);
                                                    setCtaMemberId(null);
                                                    setSelectedUser(item);
                                                }}
                                            >
                                                Delete member
                                            </p>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Tabled>
    );
};

export default Table;
