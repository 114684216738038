import styled from 'styled-components';

export const Modaled = styled.div`
    .darkBG {
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100vh;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }

    .centered {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 80%;
        overflow: scroll;
    }

    .modal {
        position: relative;
        width: 100%;
        height: 100%;
        background: white;
        padding: 30px;
        color: white;
        z-index: 10;
        border-radius: 0px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
        overflow: scroll;

        &__cta {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
        }

        &__cancel {
            background-color: #cfcfd1;
            color: #706f77;
            padding: 10px 50px;
            border-radius: 8px;
            cursor: pointer;
        }
        &__continue {
            background-color: #2f2e54;
            color: #fff;
            padding: 10px 50px;
            border-radius: 8px;
            cursor: pointer;
        }

        .cta__grid {
            display: flex;
            gap: 30px;
            align-items: center;

            p {
                color: #131221;
                font-weight: 600;
                cursor: pointer;
            }
        }
    }

    .full__height {
        height: 100%;
    }

    .modalHeader {
        background: #f5f5f5;
        overflow: hidden;
        display: flex;
        gap: 18px;
        align-items: center;
        padding: 27px 40px;
        font-size: 14px;

        .step__id {
            p {
                display: flex;
                gap: 18px;
                align-items: center;
                color: #131221;

                span {
                    background-color: #5e5ba7;
                    border-radius: 50%;
                    padding: 4px 10px;
                    color: #fff;
                }
            }

            &__inactive {
                p {
                    display: flex;
                    gap: 18px;
                    align-items: center;
                    color: #131221;

                    span {
                        background-color: #e4e3f0;
                        border-radius: 50%;
                        padding: 4px 10px;
                        color: #131221;
                    }
                }
            }
        }
        .divider {
            border: 1px solid #131221;
            height: 1px;
            width: 60px;

            &__inactive {
                border: 1px dashed #131221;
                height: 1px;
                width: 60px;
            }
        }
    }

    .closeBtn {
        cursor: pointer;
        font-weight: 500;
        padding: 4px 8px;
        border-radius: 8px;
        border: none;
        font-size: 18px;
        color: #2c3e50;
        background: white;
        transition: all 0.25s ease;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
        position: absolute;
        right: 0;
        top: 0;
        align-self: flex-end;
        margin-top: 10px;
        margin-right: 10px;

        img {
            width: 15px;
        }
    }

    .closeBtn:hover {
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
        transform: translate(-4px, 4px);
    }

    .errored {
        color: red;
        font-size: 12px;
        text-align: left;
    }

    .section__intro {
        padding: 30px 0;

        h1 {
            font-size: 20px;
            color: #000;
        }
        p {
            color: #191919;
            font-size: 16px;
            margin-top: 20px;
        }
    }

    .option__container {
        margin-bottom: 16px;

        .option__trigger {
            display: flex;
            justify-content: space-between;
            text-transform: capitalize;
            font-size: 18px;
            font-weight: 600;
            background-color: #000;
            padding: 20px 10px;
        }

        .option__content {
            background-color: #fff;
            padding-top: 16px;

            .panel__header {
                width: 100%;
                text-align: left;
                color: #000;
                padding: 20px;
                border: 1px solid #000;
            }

            .panel__selection {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                align-items: center;

                .selected {
                    padding: 20px;
                    border: 1px solid #000;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 40%;

                    h3 {
                        color: #000;
                        font-weight: 600;
                        font-size: 18px;
                    }

                    p {
                        color: #00000060;
                        font-size: 12px;
                    }
                }
                .unselected {
                    align-items: center;
                    padding: 20px;
                    border: 1px solid #00000020;
                    display: flex;
                    justify-content: space-between;
                    width: 40%;
                    cursor: pointer;

                    h3 {
                        color: #00000050;
                        font-weight: 600;
                        font-size: 18px;
                    }

                    p {
                        color: #00000030;
                        font-size: 12px;
                    }
                }
            }

            .arrivals__selector {
                color: #000;
                align-self: flex-end;
                margin-left: 0;
                margin-right: auto;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 20px;

                label {
                    font-size: 14px;
                }

                select {
                    appearance: none;
                    background-color: transparent;
                    border: none;
                    padding: 0 1em 0 0;
                    margin: 0;
                    width: 100%;
                    font-family: 400;
                    font-size: 14px;
                    cursor: pointer;
                    line-height: inherit;

                    z-index: 1;

                    &::-ms-expand {
                        display: none;
                    }
                    outline: none;
                }

                .select {
                    display: grid;
                    grid-template-areas: 'select';
                    align-items: center;
                    position: relative;

                    select,
                    &::after {
                        grid-area: select;
                    }

                    min-width: 15ch;
                    max-width: 30ch;

                    border: 1px solid black;
                    border-radius: 0.25em;
                    padding: 0.4em 0.5em;

                    font-size: 1.25rem;
                    cursor: pointer;
                    line-height: 1.1;
                    background-color: #fff;
                    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);

                    // Custom arrow
                    // &:not(.select--multiple)::after {
                    //     content: '';
                    //     justify-self: end;
                    //     width: 0.4em;
                    //     height: 0.4em;
                    //     background-color: black;
                    //     clip-path: polygon(100% 0%, 0 0%, 50% 100%);
                    // }
                }

                select:focus + .focus {
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    right: -1px;
                    bottom: -1px;
                    border: 2px solid black;
                    border-radius: inherit;
                }
            }
        }

        .products__indicator {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                color: #191919;
            }

            input {
                width: 250px;
                padding: 12px;
                border: 1px solid #00000030;
                border-radius: 6px;
                color: #000000;
                outline: none;
                font-size: 13px;
            }
        }

        .produce__list {
            .list__header {
                color: #525171;
                display: flex;
                font-size: 18px;
                font-weight: 600;
                width: 100%;
                padding: 24px 0;
                border-bottom: 1px solid #00000010;

                .name__cta {
                    flex: 0.6;
                }
            }

            .list__item {
                color: #525171;
                display: flex;
                padding: 18px 0;
                border-bottom: 1px solid #00000010;
                font-size: 13px;
                font-weight: 600;
                text-align: left;
                align-items: center;
                cursor: pointer;

                .name__cta {
                    flex: 0.6;
                    display: flex;
                    align-items: center;
                    gap: 25px;

                    .product__img {
                        height: 48.75px;
                        width: 48.75px;
                        border-radius: 12px;
                        background-color: #d9d9d9;
                        overflow: hidden;

                        img {
                            object-fit: cover;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .form__container {
        width: 100%;
        margin-top: 16px;

        .form__input {
            margin-bottom: 20px;

            .field__label {
                color: #000000;
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                text-transform: capitalize;

                sup {
                    color: red;
                    font-size: 14px;
                    margin-bottom: auto;
                }
            }

            .link__copy {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .link__container {
                    flex: 0.9;
                }
                .clip__section {
                    flex: 0.1;
                    color: #5e5ba7;
                    cursor: pointer;
                    font-size: 14px;
                }
            }

            input,
            textarea {
                border: 1px solid #00000050;
                width: 100%;
                margin: 8px 0;
                padding: 15px;
                font-size: 12px;
                color: #000;

                &[type='file'] {
                    display: none;
                }
            }

            .upload__container {
                border: 1px dashed #00000050;
                width: 100%;
                margin: 8px 0;
                padding: 15px;
                font-size: 12px;
                color: #000;
            }

            .copy__container {
                display: flex;
                justify-content: space-between;

                .link__copy {
                    color: #000;
                    cursor: pointer;
                }
            }

            .link__container {
                display: flex;
                align-items: center;
                border: 1px solid #00000050;
                width: 100%;
                margin: 8px 0;
                padding: 15px;

                p {
                    font-size: 12px;
                    color: #00000050;
                }

                input {
                    border: none;
                    outline: none;
                    width: 100%;
                    margin: 0;
                    padding: 0px;
                }
            }

            label {
                cursor: pointer;
                width: 100%;
                color: #525171;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 13px;
                padding: 20px 0;

                img {
                    width: 24px;
                }
            }

            .sub__label {
                color: #00000050;
                font-size: 12px;
                text-align: left;
            }
        }
    }

    .radio__grid {
        color: #000000;
        display: flex;
        gap: 30px;
    }
    [type='radio'] {
        margin-right: 20px;
    }

    .loader__container {
        background-color: #5e5ba7;
        height: 44px;
        width: 100px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        cursor: not-allowed;

        .loader {
            width: 4px;
            aspect-ratio: 1;
            border-radius: 50%;
            box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
            transform: translateX(-38px);
            animation: d1 0.5s infinite alternate linear;
            margin: auto;
        }

        @keyframes d1 {
            50% {
                box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
            }
            100% {
                box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
            }
        }
    }
`;
