import EmptyRequest from '../../../assets/images/svg/emptyRequest.svg';

const EmptyVendor = () => {
    return (
        <div className="p-2 pt-0">
            <div className="grid justify-center">
                <img src={EmptyRequest} alt="empty" />
            </div>
            <div className="grid justify-center mt-4">
                <p className="text-[28px] grid justify-center text-center">You have no connected vendors</p>
                <div className="grid justify-center mt-4  md:w-[376px]">
                    <p className="grid justify-center text-center">
                        You are not connected to any vendors and can't create order yet. Send a connection request to have them on your list and start
                        creating order.
                    </p>
                </div>
            </div>

            <div></div>
        </div>
    );
};

export default EmptyVendor;
