import { BaseQueryFn, createApi, FetchArgs } from '@reduxjs/toolkit/query/react';

import { IBuyingTrend, IinitialCustomerStateInterface, IinitialSupplierStateInterface } from '../features/interfaces';
import { baseQueryWithReauth, createRequestWithParams, CustomError } from './shared';

const createRequest = (url: any) => ({ url });
export const customerVendorApi = createApi({
    reducerPath: 'customerVendorApi',
    baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, any>>,
    tagTypes: ['Customers', 'discounts', 'sales-reps'],
    endpoints: (builder) => ({
        getVendorCustomers: builder.query<IinitialCustomerStateInterface, { page: number; limit: number; search_query: string }>({
            query: ({ page, limit, search_query }) => createRequestWithParams(`vendors/customers`, { page, limit, search_query }),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'Customers' as const, id })), 'Customers'] : ['Customers'],
        }),
        getACustomer: builder.query<any, string>({
            query: (id) => createRequest(`vendors/customers/${id}`),
            providesTags: (_result, _error, id) => [{ type: 'Customers', id }],
        }),
        getCustomerBuyingTrends: builder.query<IBuyingTrend, { id: string; page: number; limit: number; search_query: string; filter: string }>({
            query: ({ id, page, limit, search_query, filter }) =>
                createRequestWithParams(`vendors/customers/${id}/buying-trends`, { page, limit, search_query, filter }),
            providesTags: ['Customers'],
            transformResponse: (result: { data: IBuyingTrend }) => {
                return result.data;
            },
        }),
        suspendCustomer: builder.mutation({
            query: (id) => {
                return {
                    url: `vendors/${id}/suspend`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'Customers', id }],
        }),
        reactivateCustomer: builder.mutation({
            query: (id) => {
                return {
                    url: `vendors/${id}/activate`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'Customers', id }],
        }),

        disconnectCustomer: builder.mutation({
            query: (id) => {
                return {
                    url: `vendors/${id}/disconnect`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'Customers', id }],
        }),

        getDiscounts: builder.query<any, { vendorId: string; page: number; limit: number; search_query: string }>({
            query: ({ vendorId, page, limit, search_query }) =>
                createRequestWithParams(`vendors/customers/${vendorId}/discounts`, { page, limit, search_query }),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ vendorId }: any) => ({ type: 'discounts' as const, vendorId })), 'discounts'] : ['discounts'],
        }),
        getDiscount: builder.query<any, string>({
            query: (vendorId) => createRequest(`vendors/customers/${vendorId}/discounts`),
            providesTags: (_result, _error, vendorId) => [{ type: 'discounts', vendorId }],
        }),
        applyDiscount: builder.mutation({
            query: ({ id, ...rest }) => {
                return {
                    url: `vendors/customers/${id}/discounts`,
                    method: 'post',
                    body: rest,
                };
            },
            invalidatesTags: ['discounts', 'Customers'],
        }),
        updateDiscount: builder.mutation({
            query: ({ id, ...rest }) => {
                return {
                    url: `vendors/customers/${id}/discounts`,
                    method: 'PATCH',
                    body: rest,
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'discounts', id }],
        }),

        closeDiscount: builder.mutation({
            query: (id) => {
                return {
                    url: `vendors/customers/${id}/close-discount`,
                    method: 'delete',
                };
            },
            invalidatesTags: (_result, _error, id) => [{ type: 'Customers', id }],
        }),
        getCustomerSalesRep: builder.query<any, string>({
            query: (id) => createRequest(`vendors/customers/${id}/sales-reps`),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ vendorId }: any) => ({ type: 'Customers' as const, vendorId })), 'Customers'] : ['Customers'],
        }),

        assignSalesRep: builder.mutation({
            query: ({ id, ...rest }) => {
                return {
                    url: `vendors/customers/${id}/sales-reps`,
                    method: 'post',
                    body: rest,
                };
            },
            invalidatesTags: ['Customers'],
        }),
        reassignSalesRep: builder.mutation({
            query: ({ id, repId }) => {
                return {
                    url: `vendors/customers/${id}/sales-reps/${repId}`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'Customers', id }],
        }),

        unassignSalesRep: builder.mutation({
            query: ({ id, repId }) => {
                return {
                    url: `vendors/customers/${repId}/sales-reps/${id}`,
                    method: 'delete',
                };
            },
            invalidatesTags: (_result, _error, id) => [{ type: 'Customers', id }],
        }),

        getDiscountUnits: builder.query<any, void>({
            query: () => createRequest(`misc/discount-units`),
        }),
        getDiscounttypes: builder.query<any, void>({
            query: () => createRequest(`misc/discount`),
        }),
        getCatalogue: builder.query<any, { page: number; limit: number; search_query: string }>({
            query: ({ page, limit, search_query }) => createRequestWithParams(`catalogue`, { page, limit, search_query }),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'Customers' as const, id })), 'Customers'] : ['Customers'],
        }),
        getVendorSuppliers: builder.query<IinitialSupplierStateInterface, { page: number; limit: number; search_query: string }>({
            query: ({ page, limit, search_query }) => createRequestWithParams(`vendors/suppliers`, { page, limit, search_query }),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'Customers' as const, id })), 'Customers'] : ['Customers'],
        }),
    }),
});

export const {
    useGetCustomerBuyingTrendsQuery,
    useDisconnectCustomerMutation,
    useGetVendorCustomersQuery,
    useAssignSalesRepMutation,
    useGetACustomerQuery,
    useSuspendCustomerMutation,
    useReactivateCustomerMutation,
    useReassignSalesRepMutation,
    useUnassignSalesRepMutation,
    useApplyDiscountMutation,
    useCloseDiscountMutation,
    useUpdateDiscountMutation,
    useGetDiscountsQuery,
    useGetDiscountQuery,
    useGetDiscountUnitsQuery,
    useGetDiscounttypesQuery,
    useGetCatalogueQuery,
    useGetCustomerSalesRepQuery,
    useGetVendorSuppliersQuery,
} = customerVendorApi;
