import './Catalogue.css';

import { FC } from 'react';
import { Link } from 'react-router-dom';

import Frame from '../../assets/images/svg/frame.svg';
import PermissionsGate from '../../HOC/PermissionGate';
import { SCOPES } from '../../utils/permission';

type CProps = {
    handleToggleModal: () => void;
};

const CataloagueSplashScreen: FC<CProps> = ({ handleToggleModal }) => {
    return (
        <div>
            <div className="w-auto md:w-[652px] text-center md:mx-auto md:px-[128px] mr-2">
                <img src={Frame} alt="frame" className="mx-auto" />

                <h5 className="text-center font-semibold mb-6 text-[28px] text-jumbleng-gray">You have no products.</h5>

                <p className="font-normal text-base text-jumbleng-gray mb-[60px]">
                    There are no products in your catalogue, add products to your catalogue and start selling.
                </p>

                <div className="flex justify-center gap-2 md:gap-12">
                    <PermissionsGate scopes={[SCOPES.can_view_all_feed, SCOPES.can_view_connected_vendors_feed]}>
                        <Link to="/feed">
                            <button className="md:px-[32.5px] md:py-4 border p-2 border-jumbleng-primary rounded-lg text-jumbleng-primary">
                                Shop from feed
                            </button>
                        </Link>
                    </PermissionsGate>

                    <PermissionsGate scopes={[SCOPES.can_create_products]}>
                        <button
                            onClick={handleToggleModal}
                            className="md:px-[32.5px] md:py-4 p-2 border border-jumbleng-primary rounded-lg text-white bg-jumbleng-primary"
                        >
                            Create product
                        </button>
                    </PermissionsGate>
                </div>
            </div>
            {/* <div className="grid grid-cols-3 ">
        <div className="pBox">
          <div className="pBox1">
            <img src={Rice} alt="rice" className="pImg" />
          
          </div>
          <div className="pBox2 p-3">
            <p className="pBoxTitle">Dangote Rice</p>
            <div className="flex gap-4 mb-2">
              <p className="flex pBoxText">
                Available: <p className="pBoxText2">2,051,053 bags</p>{" "}
              </p>
              <p className="flex pBoxText">
                No Sold: <p className="pBoxText2">2,000 bags</p>{" "}
              </p>
            </div>
            <div className="flex">
              <p className="flex pPrice">₦5,000.00 </p>
              <p className=" pPrice2">per kg</p>
              
            </div>
          </div>
        </div>

       

        



      </div>

      <div>
        <FullBoard />
      </div> */}
        </div>
    );
};

export default CataloagueSplashScreen;
