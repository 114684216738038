import './OrderCartModal.css';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';

import DeleteIcon from '../../assets/images/svg/delete.svg';
import EmptyRequest from '../../assets/images/svg/invoiceBrown.svg';
import Shopping from '../../assets/images/svg/shopping-cart2.svg';
import UserIcon from '../../assets/images/svg/users.svg';
import PermissionsGate from '../../HOC/PermissionGate';
import { clearCart, decrement, getCartItems, increment, removeFromCart, updateQty } from '../../redux/features/Cart/CartAction';
import { CART_CLEAR_ITEMS } from '../../redux/features/Cart/CartActionTypes';
import { CartItem } from '../../redux/features/interfaces';
import { useCreateOrderMutation } from '../../redux/services/orderApi';
import { RootState } from '../../redux/store';
import { SCOPES } from '../../utils/permission';
import { toLocaleFixed } from '../../utils/utils';
import Spinner from '../UI/Spinner/Spinner';
const _ = require('lodash');

const OrderCartModal = () => {
    const dispatch = useDispatch();
    const { cartItems } = useSelector((state: RootState) => state.cartReducer);
    const authStore = useSelector((state: RootState) => state.authStore);
    const { user } = authStore;
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [createOrder, { data, isLoading, isSuccess, isError, error }] = useCreateOrderMutation();

    const orderItems = cartItems
        .filter((item: any) => item.userId === user.id && item.qty !== 0)
        .map((item: CartItem) => ({
            catalogue_id: item?.catalogue_id,
            qty: item?.qty,
        }));

    const handleCreateOrder = async () => {
        createOrder(orderItems);
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success(data?.message);
            dispatch(clearCart(user.id));
            dispatch({
                type: CART_CLEAR_ITEMS,
                payload: data,
            });
            localStorage.removeItem('cartItems');
            navigate('/order');
            close();
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
            close();
        }
    }, [data, dispatch, error, isError, isLoading, isSuccess, navigate, user.id]);
    const cart = _(cartItems)
        .groupBy('company_name')
        .map((items: any, company_name: any) => ({ company: company_name, cartItems: items }))
        .value();

    useEffect(() => {
        dispatch(getCartItems());
    }, [dispatch]);

    return (
        <PermissionsGate scopes={[SCOPES.can_create_orders]}>
            <Popup
                trigger={
                    <div className="font-bold flex ml-auto bg-[#EAE9F3] items-center justify-center rounded-[60px]">
                        <div className="flex items-center justify-center  w-[8rem] gap-1 py-3 cursor-pointer" onClick={() => setIsOpen(true)}>
                            <div className="relative">
                                <img src={Shopping} height={16} width={16} className="" />

                                {cartItems.filter((item: any) => item.userId === user.id).length !== 0 && (
                                    <p className="w-4 h-4 bg-[#5E5BA7] absolute rounded-full -top-[10px] left-[6px] text-white flex items-center justify-center">
                                        {cartItems.filter((item: any) => item.userId === user.id).length === 0
                                            ? ''
                                            : // : cartItems.filter((item: any) => item.userId === user.id)[0].qty
                                              // ? cartItems.filter((item: any) => item.userId === user.id).reduce((acc: number, item: CartItem) => acc + item.qty, 0)
                                              cartItems.filter((item: any) => item.userId === user.id).length}
                                    </p>
                                )}
                            </div>

                            <p className="ml-2 pr-2">View Orders</p>
                        </div>{' '}
                    </div>
                }
                className={`ordermodal`}
                modal={isOpen}
                onOpen={() => dispatch(getCartItems())}
            >
                {(close: () => void) => (
                    <>
                        {cartItems.filter((item: any) => item.userId === user.id).length === 0 ? (
                            <>
                                <div className="grid justify-center">
                                    <img src={EmptyRequest} alt="empty" />
                                </div>
                                <div className="grid justify-center mt-4 mb-4">
                                    <div style={{ width: '376px' }} className="grid justify-center mt-4">
                                        <p className="grid justify-center text-[#131221] font-semibold text-[28px]">You have no Order</p>
                                        <p className="grid justify-center">Your Order is Empty</p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="mt-5 pb-8 ml-5 pr-5 overflow-y-auto scrollbar max-h-[500px]">
                                <p className="font-bold text-[28px] justify-center grid">Order</p>
                                <p className="grid justify-end text-jumbleng-primary cursor-pointer" onClick={() => dispatch(clearCart(user.id))}>
                                    Clear List
                                </p>
                                {/* List */}
                                <div>
                                    {cart.map((item: any, i: number) => (
                                        <div key={i} className="mb-6">
                                            <div className="text-jumbleng-primary flex">
                                                <img src={UserIcon} alt="usericon" />
                                                <p className="ml-2"> {item.company}</p>
                                            </div>
                                            {item.cartItems
                                                .filter((item: any) => item.userId === user.id)
                                                .map((item: CartItem, i: number) => (
                                                    <div className="flex justify-center items-center mt-4 gap-4" key={i}>
                                                        <div className="grid content-center">
                                                            <input type="checkbox" />
                                                        </div>
                                                        <div>
                                                            <img src={item.image} alt="image" className="h-[auto] w-[40px]" />
                                                        </div>
                                                        <div className="">
                                                            <div className="font-bold ">
                                                                <p className="w-[10rem] truncate">{item.name}</p>
                                                            </div>
                                                            <p>
                                                                <span style={{ color: '#525171' }}>Price:</span> ₦
                                                                {toLocaleFixed(item.qty * item.price, 2)}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <div className="flex">
                                                                <span
                                                                    className="cursor-default"
                                                                    onClick={() => dispatch(decrement(item.id, user.id))}
                                                                >
                                                                    -
                                                                </span>
                                                                <input
                                                                    onChange={(e) => dispatch(updateQty(item.id, user.id, e.target.value))}
                                                                    className="ml-4 mr-4 pl-2 pr-2 text-center w-12"
                                                                    style={{
                                                                        border: '1px solid #706F77',
                                                                    }}
                                                                    value={item.qty}
                                                                />

                                                                <span
                                                                    className="cursor-default"
                                                                    onClick={() => dispatch(increment(item.id, user.id))}
                                                                >
                                                                    +
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{ marginLeft: 'auto' }}
                                                            onClick={() => dispatch(removeFromCart(item.id, user.id))}
                                                        >
                                                            <img src={DeleteIcon} alt="deleteicon" />
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    ))}
                                </div>

                                <hr className="mt-4" />

                                <div className="mt-8">
                                    <div className="flex">
                                        <p className="text-[22px] font-semibold">Total</p>

                                        <div className="flex items-center " style={{ marginLeft: 'auto' }}>
                                            <p className="text-[22px] font-semibold">
                                                {' '}
                                                N{' '}
                                                {toLocaleFixed(
                                                    cartItems
                                                        .filter((item: any) => item.userId === user.id)
                                                        .reduce((total: number, item: CartItem) => item.qty * item.price + total, 0),
                                                    2,
                                                )}
                                            </p>

                                            <p className="grid content-center ml-2" style={{ color: '#706F77' }}>
                                                ({cartItems.filter((item: any) => item.userId === user.id).length}) Items
                                            </p>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-2 mt-8 gap-16">
                                        <div
                                            className="grid justify-center p-4"
                                            style={{
                                                border: '1px solid #5E5BA7',
                                                borderRadius: '12px',
                                                // width: 'fit-content',
                                            }}
                                        >
                                            <p className="text-jumbleng-primary cursor-default" onClick={close}>
                                                Continue Shopping
                                            </p>
                                        </div>
                                        <div
                                            className="grid justify-center p-4 cursor-pointer"
                                            style={{
                                                backgroundColor: '#5E5BA7',
                                                borderRadius: '12px',
                                                // width: 'fit-content',
                                            }}
                                            onClick={handleCreateOrder}
                                        >
                                            {isLoading ? (
                                                <div className="flex items-center justify-center w-full">
                                                    <Spinner />
                                                </div>
                                            ) : (
                                                <p className="text-white ">Complete Order</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </Popup>
        </PermissionsGate>
    );
};

export default OrderCartModal;
