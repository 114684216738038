import axios from 'axios';
const { REACT_APP_BACKEND_API } = process.env;

export async function getCompanyTypes() {
    return (await axios.get(`${REACT_APP_BACKEND_API}/v1/company/types`)).data.data;
}

export async function getCompanyIndustries() {
    return (await axios.get(`${REACT_APP_BACKEND_API}/v1/company/industries`)).data.data;
}

export async function getCompanySize() {
    return (await axios.get(`${REACT_APP_BACKEND_API}/v1//company/workforces`)).data.data;
}

export async function getUserByToken(source: any) {
    const { data } = (
        await axios.get(`${REACT_APP_BACKEND_API}/v1/user/me`, {
            cancelToken: source.token,
        })
    ).data;
    return data;
}

export async function activateUserAccount(data: any) {
    const { email, otp } = data;
    return (
        await axios.post(
            `${REACT_APP_BACKEND_API}/v1/company/verify`,
            {
                email,
                otp,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
    ).data;
}

export const resendOtp = async (email: any) => {
    return (await axios.patch(`${REACT_APP_BACKEND_API}/v1/user/otp`, { email })).data;
};
export const resendValidateOtp = async (email: any) => {
    return (await axios.post(`${REACT_APP_BACKEND_API}/v1/user/login/validate/resend`, { email })).data;
};

export const update2FA = async (data: any) => {
    return (await axios.patch(`${REACT_APP_BACKEND_API}/v1/user/me`, data)).data;
};

export async function register(payload: any) {
    const res = await axios.post(`${REACT_APP_BACKEND_API}/v1/company/register`, payload, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data.data;
}

export async function login(data: any) {
    return (
        await axios.post(
            `${process.env.REACT_APP_BACKEND_API}/v1/user/login`,
            {
                email: data.email.trim(),
                password: data.password,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
    ).data.data;
}

export async function logoutUser() {
    return await axios.get(`${process.env.REACT_APP_BACKEND_API}/v1/user/logout`);
}

export async function forgotPassword(data: any) {
    return (
        await axios.post(`${process.env.REACT_APP_BACKEND_API}/v1/user/password/forget`, data, {
            headers: { 'Content-Type': 'application/json' },
        })
    ).data.data;
}

export async function resetPassword(data: any, token?: any) {
    if (token) {
        return (
            await axios.post(`${process.env.REACT_APP_BACKEND_API}/v1/user/password/reset`, data, {
                headers: { 'Content-Type': 'application/json' },
            })
        ).data.data;
    } else {
        const Data = { old_password: data.old_password, new_password: data.password };
        return (await axios.post(`${process.env.REACT_APP_BACKEND_API}/v1/user/password/change`, Data)).data.data;
    }
}
