import React from 'react';

import { IButton } from '../../interfaces/component-interface';
import Spinner from '../UI/Spinner/Spinner';

class Button extends React.Component<IButton> {
    render() {
        const { type, onClick, className, loading, disabled, style } = this.props;
        return (
            <>
                {loading ? (
                    <Spinner />
                ) : (
                    <button type={type} onClick={onClick} disabled={disabled} className={className} style={style}>
                        {this.props.message}
                    </button>
                )}
            </>
        );
    }
}

export default Button;
