import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import PlusIcon from '../../assets/images/svg/plus.svg';
import PermissionsGate, { managePermissions } from '../../HOC/PermissionGate';
import Wrapper from '../../HOC/Wrapper';
import useGetRoles from '../../hooks/useGetRoles';
import { types } from '../../redux/features/invoice/InvoiceActionTypes';
import { RootState } from '../../redux/store';
import { SCOPES } from '../../utils/permission';
import CustomerInvoice from './CustomerInvoice';
import MyArchive from './MyArchive';
import MyInvoice from './MyInvoice';

const Invoice = () => {
    const { activeTab } = useSelector((state: RootState) => state.listCustomerInvoicesReducer);

    const dispatch = useDispatch();
    const toggleTab = (tabName: 'customerInvoice' | 'myInvoice' | 'myArchive') => {
        dispatch({ type: types.setActiveTab, payload: tabName });
    };

    const { user_role, company_type } = useGetRoles();

    useEffect(() => {
        if (managePermissions(user_role, company_type, SCOPES.can_manage_company_invoices, SCOPES.can_manage_customer_invoices)) {
            dispatch({ type: types.setIIsCustomer, payload: false });
            dispatch({ type: types.setActiveTab, payload: 'myInvoice' });
            return;
        }
    }, [user_role, company_type, dispatch]);
    return (
        <Wrapper>
            <div className="flex items-center justify-between my-6 mb-[0.7rem]">
                <h3 className="font-semibold text-[1.25rem] text-jumbleng-gray">Invoices</h3>
            </div>
            <div className="flex justify-between border-b py-1 mb-[1.5rem]">
                <ul className="flex">
                    <PermissionsGate scopes={[SCOPES.can_manage_customer_invoices]}>
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => (dispatch({ type: types.setIIsCustomer, payload: true }), toggleTab('customerInvoice'))}
                                className={`${
                                    activeTab === 'customerInvoice' && 'font-bold border-b border-b-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                            >
                                Customer Invoice
                            </a>
                        </li>
                    </PermissionsGate>
                    <PermissionsGate scopes={[SCOPES.can_manage_company_invoices]}>
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => (dispatch({ type: types.setIIsCustomer, payload: false }), toggleTab('myInvoice'))}
                                className={`${
                                    activeTab === 'myInvoice' && 'font-bold border-b border-b-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                            >
                                My Invoice
                            </a>
                        </li>
                    </PermissionsGate>
                    <li className="flex items-center">
                        <a
                            href="#"
                            onClick={() => (dispatch({ type: types.setIIsCustomer, payload: false }), toggleTab('myArchive'))}
                            className={`${
                                activeTab === 'myArchive' && 'font-bold border-b border-b-jumbleng-primary'
                            } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                        >
                            My Archive
                        </a>
                    </li>
                </ul>
                <PermissionsGate scopes={[SCOPES.can_create_orders, SCOPES.can_view_all_feed, SCOPES.can_view_connected_vendors_feed]}>
                    <Link to="/feed" className="flex h-full mr-2 rounded-md ml-4 items-center bg-jumbleng-primary px-[1.18rem] py-[0.42125rem]">
                        <img src={PlusIcon} className="mr-1" alt="plus" />{' '}
                        <span className="font-semibold text-[0.78125rem] text-white">Create Order</span>
                    </Link>
                </PermissionsGate>
            </div>

            {activeTab === 'myInvoice' ? <MyInvoice /> : activeTab === 'customerInvoice' ? <CustomerInvoice /> : <MyArchive />}
        </Wrapper>
    );
};

export default Invoice;
