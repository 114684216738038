import { Link } from 'react-router-dom';

import Main1 from '../../assets/images/svg/main1.svg';
import PurpleArrow from '../../assets/images/svg/purple-arrow-left.svg';
import Right from '../../assets/images/svg/purpleRight.svg';
import Side1 from '../../assets/images/svg/side1.svg';
import Side2 from '../../assets/images/svg/side2.svg';
import Side3 from '../../assets/images/svg/side3.svg';
import PermissionsGate from '../../HOC/PermissionGate';
import WrapperVendor from '../../HOC/WrapperVendor';
import { SCOPES } from '../../utils/permission';

const ExpandedVendorCatalogue = () => {
    return (
        <WrapperVendor>
            <div className="mt-8">
                <PermissionsGate scopes={[SCOPES.can_view_all_feed, SCOPES.can_view_connected_vendors_feed]}>
                    <Link to="/feed">
                        <p className="flex">
                            {' '}
                            <img src={PurpleArrow} alt="purple" /> <p className="ml-2 text-jumbleng-primary">Back</p>
                        </p>
                    </Link>
                </PermissionsGate>
            </div>
            <div className="grid grid-cols-2 gap-8 mt-8">
                <div className=" flex gap-8">
                    <div className="w-[70px] h-[200px] grid gap-8 justify-center mt-4">
                        <div>
                            <img src={Side1} alt="side1" />{' '}
                        </div>
                        <div>
                            <img src={Side2} alt="side2" />{' '}
                        </div>
                        <div>
                            {' '}
                            <img src={Side3} alt="side3" />{' '}
                        </div>
                    </div>
                    <div className="bg-white p-4">
                        <img src={Main1} alt="main1" />
                    </div>
                </div>
                <div>
                    <div className="flex mb-4">
                        <div className="text-[#131221] text-[28px] ">Dangote Rice</div>
                        <div className="flex align-baseline gap-1" style={{ marginLeft: 'auto' }}>
                            <p className="text-[#5E5BA7] text-[28px] font-bold">
                                {' '}
                                <span className="text-[#706F77] text-[21px] mr-2 font-normal " style={{ textDecoration: 'line-through' }}>
                                    {' '}
                                    ₦16,000{' '}
                                </span>{' '}
                                ₦25,000.00{' '}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="font-bold text-[16px] text-[#131221]">Description</div>
                        <div className="w-[570px] text-[16px] text-[#525171]">
                            Mama gold rice with a mixture of white rice. no branded bags to bag the rice. You can rebag if you like.
                        </div>
                    </div>
                    <hr className="mt-4 mb-8" />
                    <div className="mb-8">
                        <p className="font-bold text-[#131221] text-[16px]"> Color</p>
                        <div className="flex mt-2">
                            <div
                                style={{
                                    height: '32px',
                                    width: '32px',
                                    borderRadius: '50%',
                                    backgroundColor: '#FFE768',
                                    marginRight: '5px',
                                }}
                            />
                            <div
                                style={{
                                    height: '32px',
                                    width: '32px',
                                    borderRadius: '50%',
                                    backgroundColor: '#F662F1',
                                    marginRight: '5px',
                                }}
                            />
                            <div
                                style={{
                                    height: '32px',
                                    width: '32px',
                                    borderRadius: '50%',
                                    backgroundColor: '#2AC66F',
                                    marginRight: '5px',
                                }}
                            />
                            <div
                                style={{
                                    height: '32px',
                                    width: '32px',
                                    borderRadius: '50%',
                                    backgroundColor: '#2773E4',
                                    marginRight: '5px',
                                }}
                            />
                            <div
                                style={{
                                    height: '32px',
                                    width: '32px',
                                    borderRadius: '50%',
                                    backgroundColor: '#EB5757',
                                    marginRight: '5px',
                                }}
                            />
                        </div>

                        <div className="flex mt-2">
                            <div
                                style={{
                                    height: '32px',
                                    width: '32px',
                                    borderRadius: '50%',
                                    backgroundColor: '#0D0D0D',
                                    marginRight: '5px',
                                }}
                            />
                            <div
                                style={{
                                    height: '32px',
                                    width: '32px',
                                    borderRadius: '50%',
                                    backgroundColor: '#792020',
                                    marginRight: '5px',
                                }}
                            />
                        </div>
                        <div>
                            <div className="bg-red"></div>
                        </div>
                    </div>
                    <hr />
                    <div className="grid grid-cols-2 mt-4">
                        <div>
                            <p className="font-bold text-[#131221] text-[16px]">Quantity</p>
                            <div className="p-3 border-slate-400 border-2 w-1/2 grid grid-cols-3  gap-3 rounded-lg mt-2 mb-2">
                                <p className="grid justify-center text-[#706F77]">-</p>
                                <p className="grid justify-center">0</p> <p className="grid justify-center text-[#706F77]">+</p>
                            </div>
                            <p className="text-[#525171] text-[16px]">
                                Minimum Order: <span className="text-[black]">15</span>
                            </p>
                            <p className="text-[#525171] text-[16px]">
                                Maximum Order: <span className="text-[black]">20</span>{' '}
                            </p>
                        </div>
                        <div>
                            <p className="font-bold text-[#131221] text-[16px]">Weight</p>
                            <div className="flex gap-2 mt-4">
                                <div className="bg-white p-3 rounded-lg"> 5kg </div>
                                <div className="bg-white p-3 rounded-lg"> 10kg </div>
                                <div className="bg-white p-3 rounded-lg"> 20kg</div>
                                <div className="bg-white p-3 rounded-lg"> 25kg</div>
                            </div>
                            <div className="flex gap-2 mt-4">
                                <div className="bg-white p-3 rounded-lg"> 230kg </div>
                                <div className="bg-white p-3 rounded-lg"> 230kg </div>
                                <div className="bg-white p-3 rounded-lg"> 230kg</div>
                                <div style={{ color: '#5E5BA7' }} className="bg-white p-3 rounded-lg">
                                    {' '}
                                    •••
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-4 mb-8" />

                    <div className="grid grid-cols-2">
                        <div className="bg-[#5E5BA7] py-3 rounded-lg grid justify-center">
                            <p className="text-[#FFFFFF]">+ Add to Order</p>
                        </div>
                        <div style={{ marginLeft: 'auto' }} className="grid content-center">
                            <div className="flex ">
                                <div className="pr-2 text-[#5E5BA7]">More Information here </div>
                                <img src={Right} alt="rightpurple" width={6} height={12} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </WrapperVendor>
    );
};

export default ExpandedVendorCatalogue;
