import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import NotFound from '../../assets/images/png/no-image.jpg';
import useConnectSocket from '../../hooks/useWebSocket';
import { INotificationResponse, retrieveNotification, toggleNotificationStatusTab, toggleNotificationTab } from '../../redux/slices/notifications';
import { RootState } from '../../redux/store';
import { convertNotificationDate } from '../../utils/utils';
import Loading from '../UI/Spinner/Loading';

const NotificationBox = ({ isvendor }: { isvendor?: boolean }) => {
    const { notifications, loading, newNotification, notificationTab, notificationStatusTab } = useSelector(
        (state: RootState) => state.notificationReducer,
    );
    const [Notifications, setNotifications] = useState<INotificationResponse[]>([]);
    const { handleClickSendMessage } = useConnectSocket();

    useEffect(() => {
        setNotifications(notifications);
    }, [notifications]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (newNotification !== null) {
            if (
                (newNotification?.context_data?.context?.toLowerCase() === notificationTab && notificationStatusTab !== 'read') ||
                (newNotification?.context_data?.context?.toLowerCase() !== notificationTab &&
                    notificationTab === 'all' &&
                    notificationStatusTab !== 'read')
            ) {
                if (newNotification?.context_data?.id !== Notifications[0]?.id) {
                    setNotifications([newNotification?.context_data, ...Notifications]);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newNotification, notificationStatusTab, notificationTab]);

    useEffect(() => {
        if (isvendor) {
            dispatch(toggleNotificationTab('all'));
            dispatch(toggleNotificationStatusTab('all'));
            dispatch(retrieveNotification({ page: 1, limit: 10 }));
        }
    }, [dispatch, isvendor]);

    const handleMarkRead = async (context: string, id: string, notificationId: string) => {
        await handleClickSendMessage(notificationId);
        navigate(`/${context}/${id}`);
    };

    if (loading) {
        return (
            <div className="min-h-[calc(100vh-20rem)] flex justify-center flex-col">
                <Loading />
            </div>
        );
    }

    return (
        <>
            {Notifications?.length > 0 ? (
                Notifications?.map((item: INotificationResponse, index: number) => (
                    <div className="border-b border-b-[rgba(94,91,167,0.25)] py-4" key={index}>
                        {!item?.read && (
                            <div className="mb-1 flex items-center justify-between mx-4">
                                <div className="rounded-full flex items-center justify-center h-2 w-2 bg-jumbleng-primary text-white text-[12px]" />
                                <p className="font-bold text-[#5E5BA7]">Remind me</p>
                            </div>
                        )}
                        <div className="flex gap-4 mx-4">
                            <div className="flex gap-2 items-start ">
                                <div className="flex gap-2 items-center rounded-full min-w-[3.2rem]">
                                    <img src={NotFound} alt="avatar" className="h-[3.2rem] w-[3.2rem] object-contain" />
                                </div>
                            </div>
                            <div className="text-[16px] mt-2 ">
                                <p className="text-md text-[#525171]">{item?.title} </p>
                                <div
                                    className="flex gap-4 items-center pt-4"
                                    onClick={() => handleMarkRead(item?.context.toLowerCase(), item?.context_id, item?.id)}
                                >
                                    <div className="grid justify-center py-3 px-10 cursor-pointer bg-[#5E5BA7] rounded-[12px]">
                                        <p className="text-white ">View</p>
                                    </div>
                                    <div className="grid justify-center py-3 px-10 border-2 cursor-pointer border-[#5E5BA7] rounded-[12px]">
                                        <p className="text-jumbleng-primary">Reject</p>
                                    </div>
                                </div>
                                <p className="text-[#706F77] mt-4">{convertNotificationDate(item?.created_at)}</p>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="p-2 pt-0">
                    <div className="grid justify-center mt-24">
                        <i className="fa-solid fa-bell-slash text-[32px] text-jumbleng-gray"></i>
                    </div>
                    <div className="grid justify-center mt-4 pb-24">
                        <p className="text-[28px] grid justify-center text-center">You have no notifications here</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default NotificationBox;
