import EmptyRequest from '../../../assets/images/svg/emptyRequest.svg';

const EmptyCustomer = () => {
    return (
        <div className="p-2 pt-0">
            <div>
                <p className="text-[#5E5BA7] text-[16px] mt-4 mb-4">
                    <span className="font-bold">0</span> Customers Created
                </p>
            </div>
            {/* EMPTY CROUP */}
            <div className="grid justify-center">
                <img src={EmptyRequest} alt="empty" />
            </div>
            <div className="grid justify-center mt-4">
                <p className="text-[28px] grid text-center">You have no customers created</p>
                <div className="grid justify-center mt-4  md:w-[376px]">
                    <p className="grid justify-center text-center">
                        There are no customers in your vendor list, search for buyers using the Feed button above and send a connection request to
                        have them on your list.{' '}
                    </p>
                </div>
            </div>

            <div></div>
        </div>
    );
};

export default EmptyCustomer;
