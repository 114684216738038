import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import PurpleArrow from '../../assets/images/svg/purple-arrow-left.svg';
import Loading from '../../components/UI/Spinner/Loading';
import PermissionsGate from '../../HOC/PermissionGate';
import WrapperVendor from '../../HOC/WrapperVendor';
import { useGetReceivedRequestsQuery } from '../../redux/services/supplyChainApi';
import { toggleRequestTab } from '../../redux/slices/tabs';
import { RootState } from '../../redux/store';
import { SCOPES } from '../../utils/permission';
import Requests from './Requests';
import RequestSentVendor from './SentRequests';

const RequestPage = () => {
    const { requestTab: activeTab } = useSelector((state: RootState) => state.tabsReducer);

    const dispatch = useDispatch();

    const toggleTab = (tabName: 'vendor' | 'sent') => {
        dispatch(toggleRequestTab(tabName));
    };
    const { isFetching } = useGetReceivedRequestsQuery();

    if (isFetching && activeTab === 'vendor') {
        return (
            <div className="min-h-screen flex justify-center flex-col">
                <Loading />
            </div>
        );
    }

    return (
        <WrapperVendor>
            <PermissionsGate scopes={[SCOPES.can_manage_connections]}>
                <div className="grid justify-center mt-4 ">
                    <PermissionsGate scopes={[SCOPES.can_view_all_feed, SCOPES.can_view_connected_vendors_feed]}>
                        <Link to="/feed">
                            <div className="flex">
                                {' '}
                                <img src={PurpleArrow} alt="purple" /> <p className="ml-2 text-jumbleng-primary">Back</p>
                            </div>
                        </Link>
                    </PermissionsGate>

                    <div className="flex justify-between border-b  mb-[1.5rem] mt-4 w-[600px]">
                        <ul className="flex">
                            <li className="flex items-center">
                                <a
                                    href="#"
                                    onClick={() => toggleTab('vendor')}
                                    className={`${
                                        activeTab === 'vendor' && 'font-bold border-b border-b-jumbleng-primary'
                                    } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                                >
                                    Vendor Request
                                </a>
                            </li>
                            <li className="flex items-center">
                                <a
                                    href="#"
                                    onClick={() => toggleTab('sent')}
                                    className={`${
                                        activeTab === 'sent' && 'font-bold border-b border-b-jumbleng-primary'
                                    } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                                >
                                    Request Sent
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                {activeTab === 'vendor' ? (
                    <div className="flex justify-center items-center  w-full">
                        <Requests />
                    </div>
                ) : (
                    <div className="flex justify-center items-center  w-full">
                        <RequestSentVendor />
                    </div>
                )}
            </PermissionsGate>
        </WrapperVendor>
    );
};

export default RequestPage;
