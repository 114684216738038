import { BaseQueryFn, createApi, FetchArgs } from '@reduxjs/toolkit/query/react';

import { IPayments, PaymentType } from '../features/interfaces';
import { baseQueryWithReauth, createRequestWithParams, CustomError } from './shared';

const createRequest = (url: any) => ({ url });
export const paymentApi = createApi({
    reducerPath: 'paymentApi',
    baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, any>>,
    tagTypes: ['payment'],
    endpoints: (builder) => ({
        confirmPayment: builder.mutation({
            query: (id) => {
                return {
                    url: `payments/${id}/confirm`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'payment', id }],
        }),
        markPaid: builder.mutation({
            query: (id) => {
                return {
                    url: `payments/${id}/made`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'payment', id }],
        }),
        getMyPayments: builder.query<IPayments, { page: number; limit: number; search_query: string; status: string }>({
            query: ({ page, limit, search_query, status }) => createRequestWithParams(`payments/purchases`, { page, limit, search_query, status }),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'payment' as const, id })), 'payment'] : ['payment'],
        }),
        getCustomerPayments: builder.query<IPayments, { page: number; limit: number; search_query: string; status: string }>({
            query: ({ page, limit, search_query, status }) => createRequestWithParams(`payments/customers`, { page, limit, search_query, status }),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'payment' as const, id })), 'payment'] : ['payment'],
        }),
        getAPayment: builder.query<PaymentType, string>({
            query: (id) => createRequest(`/payments/${id}`),
            providesTags: (_result, _error, id) => [{ type: 'payment', id }],
            transformResponse: (results: { data: any }) => results.data,
        }),

        getAllPaymentCount: builder.query<any, string>({
            query: (type) => createRequest(`/payments/${type}?page=1&limit=10&status=`),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'payment' as const, id })), 'payment'] : ['payment'],
        }),
        getPendingPaymentCount: builder.query<any, string>({
            query: (type) => createRequest(`payments/${type}?page=1&limit=10&status=pending`),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'payment' as const, id })), 'payment'] : ['payment'],
        }),
        getConfirmedPaymentCount: builder.query<any, string>({
            query: (type) => createRequest(`payments/${type}?page=1&limit=10&status=confirmed`),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'payment' as const, id })), 'payment'] : ['payment'],
        }),
        getUnconfirmedPaymentCount: builder.query<any, string>({
            query: (type) => createRequest(`payments/${type}?page=1&limit=10&status=not_confirmed`),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'payment' as const, id })), 'payment'] : ['payment'],
        }),
    }),
});

export const {
    useGetAllPaymentCountQuery,
    useGetPendingPaymentCountQuery,
    useGetUnconfirmedPaymentCountQuery,
    useGetConfirmedPaymentCountQuery,
    useConfirmPaymentMutation,
    useMarkPaidMutation,
    useGetAPaymentQuery,
    useGetCustomerPaymentsQuery,
    useGetMyPaymentsQuery,
} = paymentApi;
