import React, { FC } from 'react';

interface IClose {
    close: () => void;
}
const Cancel: FC<IClose> = ({ close }) => {
    return (
        <div className="flex justify-end ">
            <p onClick={close} className="cursor-pointer">
                <i className="fa-solid fa-xmark text-[20px] text-jumbleng-gray"></i>{' '}
            </p>
        </div>
    );
};

export default Cancel;
