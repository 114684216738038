import { Dispatch, SetStateAction } from 'react';

import { ICompanyProfile } from '../../interfaces';
import { myCatalogueDataResponse, myCatalogueDataType } from './product/productRedux';

//auth
enum Eaction {
    login = '[login] Action',
    logout = '[logout] Action',
    setResetOrChangePassword = '[setResetOrChangePassword] Action',
    updateProfile = '[updateProfile] Action',
    updateUser = '[updateUser] Action',
    setAuthorization = '[setAuthorization] Action',
    requestUser = '[requestUser] Action',
    fulfillUser = '[fulfillUser] Action',
    setRegistrationData = '[setRegistrationData] Action',
    clearRegistrationData = '[clearRegistrationData] Action',
    setRegistrationStep = '[setRegistrationStep] Action',
    setAllUserDetails = '[setAllUserDetails] Action',
}

enum EregistrationSteps {
    PresonalDetails = 'PresonalDetails',
    CompanyDetails = 'CompanyDetails',
    Verification = 'Verification',
}

type authorizationType = {
    access_token?: string;
    refresh_token?: string;
    access_token_expiry_time?: string;
    refresh_token_expiry_time?: string;
};

type userType = {
    id: string;
    company_id: string;
    role: string;
    image?: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    suspended: boolean;
    permission_level: number;
    otp: string;
    otp_expires_at?: Date;
    google_oidc_sub?: string;
    created_at: Date;
    updated_at: Date;
    twoFA: boolean;
};

type companyType = {
    id: string;
    name: string;
    connection_id: string;
    email: string;
    catalogue: any[];
    description?: string;
    phone_number: string;
    country: string;
    address: string;
    logo?: string;
    image?: string;
    is_verified: boolean;
    company_type: string;
    certificates?: any;
    state: string;
    industry_type: string;
    work_force: string;
    created_at: Date;
    updated_at: Date;
    private: boolean;
    connection_type?: string;
    connection_status?: string;
    vendor_group_id?: string;
    banner?: string;
    bio?: string;
};

type registrationDataType = {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    phone_number: string;
    role: string;
};

interface Iaction {
    type: Eaction;
    payload?: any;
}

interface IinitialAuthState {
    registration_data: registrationDataType;
    reset_type: string;
    registration_step: string;
    user: userType;
    company: companyType;
    authorization: authorizationType;
}

//company
export type companyListType = {
    data: Array<companyType>;
    count: number;
};

export type companyCatalogueListType = {
    data: myCatalogueDataResponse;
    count: number;
};

interface IinitialFeedInterface {
    orderingCompanies: companyListType;
}

interface IinitialCompanyCatalogueInterface {
    companyCatalogues: companyCatalogueListType;
    loading: boolean;
}

//cart
type CartItem = {
    userId: string;
    company_name: string;
    catalogue_id: string;
    id: string;
    name: string;
    price: number;
    image: string;
    qty: number;
};

interface IinitialCartInterface {
    cartItems: CartItem[];
}

//invoices
type InvoiceType = {
    id: string;
    created_at: string;
    updated_at: string;
    order_no: string;
    invoice_id: string;
    ordering_company: companyType;
    vendor: companyType;
    invoice_no: string;
    buyer_id: string;
    seller_id: string;
    order_id: string;
    total_order_value: string;
    currency: string;
    paid_date: string;
    paid: string;
    receipt_no: string;
    order: OrderType;
    payment_date: string;
    fulfilled: boolean;
    fulfilled_date: string;
    expiry: string;
    created_by: '';
    payment_id: string;
    status: string;
    singleinvoiceloading: boolean;
    iscustomer: boolean;
    receipt_issued: boolean;
};

interface IInvoices {
    invoicesloading: boolean;
    iscustomer: boolean;
    activeTab: 'customerInvoice' | 'myInvoice' | 'myArchive';
    data: InvoiceType[];
    total_order_value: number;
    count: number;
    currentStatus: '' | 'paid' | 'unpaid' | 'cancelled';
}

//orders
type OrderItemType = {
    qty: number;
    catalogue_id: string;
};

type IinitialOrderType = {
    orderLoading: boolean;
    orderItems: OrderItemType[];
};

type OrderItemsType = {
    buying_price: number;
    catalogue: myCatalogueDataType;
    catalogue_id: string;
    vat_percent: number;
    vat_amount: number;
    sub_total: number;
    total: number;
    created_at: string;
    id: string;
    order_id: string;
    qty: number;
    updated_at: string;
};

type OrderType = {
    order_no: string;
    acceptance_date: string;
    buyer_id: string;
    cancel_reason: string;
    cancellation_date: string;
    created_at: string;
    fulfillment_date: string;
    id: string;
    order_items: OrderItemsType[];
    rejection_date: string;
    rejection_reason: string;
    seller_id: string;
    status: string;
    updated_at: string;
    ordering_company: companyType;
    vendor: companyType;
    singleorderloading?: boolean;
    iscustomer?: boolean;
    invoice_issued: boolean;
    invoice_id: string;
    invoice_no: string;
    invoice_status: string;
};

interface IOrders {
    iscustomer: boolean;
    activeTab: 'customerOrder' | 'myOrder';
    currentStatus: '' | 'pending' | 'fulfilled' | 'unfulfilled' | 'cancelled';
    data: OrderType[];
    count: number;
    total_order_value: number;
}

//payments
type PaymentType = {
    id: string;
    created_at: string;
    updated_at: string;
    buyer_id: string;
    seller_id: string;
    order_id: string;
    currency: string;
    order: OrderType;
    status: string;
    singlepaymentloading: boolean;
    iscustomer: boolean;
    amount: number;
    completed: boolean;
    reference: string;
    invoice_id: string;
    invoice: InvoiceType;
    receipt_issued: boolean;
    receipt_no: string;
    proof: string;
};

interface IPayments {
    iscustomer: boolean;
    activeTab: 'customerPayment' | 'myPayment';
    data: PaymentType[];
    count: number;
    total_order_value: number;
    currentStatus: '' | 'pending' | 'confirmed' | 'not_confirmed';
}

//bank
type bankTypes = { code: string; name: string };

type accountTypes = {
    id: string;
    created_at: string;
    updated_at: string;
    tin: string;
    bank_name: string;
    bank_code: string;
    resolver: string;
    account_number: string;
    account_name: string;
    owner: string;
    main: boolean;
};

enum Eaction {
    requestBank = '[requestBank] Action',
    fulfillBank = '[fulfillBank] Action',
    requestAccount = '[requestAccount] Action',
    fulfillAccount = '[fulfillAccount] Action',
    success = '[success] Action',
    failure = '[failure] Action',
}

interface IinitialBankState {
    banks: bankTypes[];
    accounts: accountTypes[];
    loading: boolean;
}

//profile

interface IinitalProfileStateInterface {
    data: ICompanyProfile;
    loading: boolean;
}

interface IComplianceResponse {
    id: string;
    created_at: string;
    updated_at: string;
    company_id: string;
    registered_business_name: string;
    business_about: string;
    business_address: string;
    business_city: string;
    business_state: string;
    business_country: string;
    business_email: string;
    owner_first_name: string;
    owner_last_name: string;
    owner_phone: string;
    owner_gender: string;
    owner_dob: string;
    owner_nationality: string;
    owner_address: string;
    owner_country: string;
    owner_state: string;
    owner_city: string;
    owner_id_type: string;
    owner_id_number: string;
    owner_id_image: string;
    business_rc_number: string;
    business_tin: string;
    business_utility_bill_type: string;
    business_utility_bill_image: string;
    business_cac: string;
    status: string;
    submission_date: string;
    declination_reason: {
        declination_types: string[];
        reason: string;
    };
    reason: string;
}

// groups

interface IGroup {
    company_id: string;
    created_at: string;
    description: string;
    id: string;
    locations: string;
    name: string;
    status: string;
    updated_at: string;
    vendorCount: string;
    vendors: companyType[];
}
export interface ICustomer {
    company_id: string;
    email: string;
    industry: string;
    last_purchase_date: string;
    has_sales_rep: boolean;
    name: string;
    state: string;
    value_of_items_bought: number;
}
interface IinitialGroupStateInterface {
    data: IGroup[];
    count: number;
}

interface IinitialCustomerStateInterface {
    data: ICustomer[];
    count: number;
}

interface ISubscriptionInterface {
    active: boolean;
    b2c_storefront: boolean;
    created_at: string;
    currency: string;
    description: string;
    erp_integration: boolean;
    id: string;
    monthly_purchase_order_limit: number;
    name: string;
    price_per_month: number;
    price_per_year: number;
    updated_at: string;
    vendor_stock_data: boolean;
}

type Preference = {
    theme: string;
    layout: string;
};
type Revenue = {
    Currency: string;
    amount: number;
    symbol: string;
};
type SocialLinks = {
    facebook: string;
    instagram: string;
    twitter: string;
    whatsapp: string[];
};
type ContactInfo = {
    email: string[];
    phone_number: string[];
};
export type StoreData = {
    brand_logo: string;
    id: string;
    company_type: string;
    created_at: string;
    description: string;
    new_arrival: string;
    preference: Preference;
    revenue: Revenue;
    company_id: string;
    contact_info: ContactInfo;
    social_link: SocialLinks;
    store_name: string;
    sub_domain: string;
    suspended: boolean;
    updated_at: string;
};
type PaginationType = {
    limit: number;
    prev: string;
    next: string;
};
type Product = {
    id: string;
    created_at: string;
    updated_at: string;
    company_id: string;
    product_id: string;
    name: string;
    price: number;
    images: string[] | null;
    currency: string;
    description: string;
    qty: number;
};

export type ProductData = {
    products: Product[];
    pagination: PaginationType;
};

export type Order = {
    created_at: string;
    ordering_company: {
        name: string;
    };
    order_no: string;
    order_items: {
        buying_price: string;
    }[];
    invoice_issued: string;
    status: 'fulfilled' | 'pending';
};

interface ISubscriptionPlan {
    amount: number;
    company: string;
    company_id: string;
    created_at: string;
    currency: string;
    id: string;
    plan_type: string;
    detail: string;
    paid: boolean;
    start_time: string;
    end_time: string;
    description?: string;
    status: string;
    state: string;
    plan: ISubscriptionInterface;
    subscription_plan_id: string;
    updated_at: string;
}
interface IBuyingTrend {
    total_order: string;
    total_paid_invoice: string;
    total_unpaid_invoice: string;
}

type ISetState<T> = Dispatch<SetStateAction<T>>;

interface ISingleCustomer {
    company: companyType;
    groups: any[];
    vendor_status: string;
}

export interface ISalesRep {
    email: string;
    first_name: string;
    id: string;
    image: string;
    invitation_id: string;
    last_logged_in: string;
    last_name: string;
    pending: boolean;
    role: string;
    suspended: boolean;
}

interface ISingleSupplier {
    company: companyType;
    groups: any[];
    vendor_status: string;
}

export interface ISupplier {
    company_id: string;
    email: string;
    industry: string;
    last_supply_date: string;
    has_sales_rep: boolean;
    name: string;
    state: string;
    value_of_items_supplied: number;
}

interface IinitialSupplierStateInterface {
    data: ISupplier[];
    count: number;
}
export type {
    accountTypes,
    authorizationType,
    bankTypes,
    CartItem,
    companyType,
    Iaction,
    IBuyingTrend,
    IComplianceResponse,
    IGroup,
    IinitalProfileStateInterface,
    IinitialAuthState,
    IinitialBankState,
    IinitialCartInterface,
    IinitialCompanyCatalogueInterface,
    IinitialCustomerStateInterface,
    IinitialFeedInterface,
    IinitialGroupStateInterface,
    IinitialOrderType,
    IinitialSupplierStateInterface,
    IInvoices,
    InvoiceType,
    IOrders,
    IPayments,
    ISetState,
    ISingleCustomer,
    ISingleSupplier,
    ISubscriptionInterface,
    ISubscriptionPlan,
    OrderItemsType,
    OrderItemType,
    OrderType,
    PaymentType,
    registrationDataType,
    userType,
};

export { Eaction, EregistrationSteps };
