import { Icon } from '@iconify/react';
import dayjs from 'dayjs';
import { ErrorMessage, Field, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';

import Button from '../../../../components/Button';
import Cancel from '../../../../components/CancelButton/Cancel';
import DatePickerField from '../../../../components/FormField/DatePickerField';
import InputField from '../../../../components/FormField/InputField';
import SelectField from '../../../../components/FormField/SelectField';
import { useApplyDiscountMutation, useGetCatalogueQuery } from '../../../../redux/services';
import { clearProductList } from '../../../../redux/slices/global';
import { RootState } from '../../../../redux/store';
import { DiscountFormSchema, IDiscountForm, initialDiscountFormValues } from '../../../../schema/discount-form';
import AddorRemoveProduct from './AddorRemoveProduct';

export type ProductQuery = {
    page: number;
    limit: number;
    search_query: string;
};
export const initialProductQuery: ProductQuery = {
    page: 1,
    limit: 12,
    search_query: '',
};
const ApplyDiscount = ({
    close,
    isEdit,
    discount,
    unitData,
    company_id,
}: {
    close: () => void;
    isEdit?: boolean;
    discount?: IDiscountForm;
    unitData: string[];
    company_id?: string;
}) => {
    const [query, setQuery] = useState<ProductQuery>(initialProductQuery);
    const { products } = useSelector((state: RootState) => state.globalReducer);
    const [selectProducts, setSelectProducts] = useState(false);
    const { data: productlist } = useGetCatalogueQuery(query);
    const [applyDiscount, { isLoading, isSuccess, isError, error }] = useApplyDiscountMutation();
    const dispatch = useDispatch();

    const handleChange = (e: any) => {
        setQuery({ ...query, search_query: e.target.value, page: 1 });
    };

    const handleSubmit = (values: IDiscountForm, setFieldError: any) => {
        if (values.discount_type === 'selected' && products.length === 0) {
            setFieldError('discount_type', 'Select at least one product');
            return;
        }
        applyDiscount({
            ...values,
            start_date: dayjs(values.start_date).add(1, 'day').toISOString(),
            end_date: dayjs(values.end_date).add(1, 'day').toISOString(),
            catalogues: values.discount_type === 'all' ? [] : products,
            id: company_id,
            value: Number(values.value),
        });
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Discount applied to products successfully');
            dispatch(clearProductList());
            close();
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
            close();
        }
    }, [isLoading, isSuccess, isError, error, close, dispatch]);

    return (
        <div>
            <div className="mt-4 mr-4">
                <Cancel close={close} />
            </div>
            <div className="px-[50px] pt-2 pb-[32px] w-full h-[524px]">
                <Formik
                    onSubmit={(values, { setFieldError }) => handleSubmit(values, setFieldError)}
                    validationSchema={DiscountFormSchema}
                    initialValues={{ ...initialDiscountFormValues, ...discount }}
                    enableReinitialize
                >
                    {({ handleSubmit, setFieldValue, values, setFieldTouched }) => (
                        <div>
                            <p className="text-[#131221] font-black  text-[28px] grid justify-center mb-4 ">Apply Discount </p>
                            <div className="flex flex-col">
                                <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-6 mb-6 -order-1">
                                    <SelectField name={'unit'} placeholder={'Amount'} options={unitData} label={'Discount unit'} />
                                    <InputField
                                        type={'text'}
                                        name={'value'}
                                        placeholder={'00'}
                                        label={'Discount value'}
                                        values={values.value}
                                        setFieldTouched={setFieldTouched}
                                    />
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-6">
                                    <div className="relative flex flex-col">
                                        <DatePickerField name="start_date" setFieldValue={setFieldValue} values={values} />
                                    </div>{' '}
                                    <div className="relative flex flex-col">
                                        <DatePickerField name="end_date" setFieldValue={setFieldValue} values={values} />
                                    </div>
                                </div>
                            </div>
                            <div role="group" aria-labelledby="my-radio-group" className="flex flex-col">
                                <div className="mt-5 mb-2"> Apply discount for </div>
                                <label className="mb-4 mr-4 w-fit">
                                    <Field
                                        type="radio"
                                        name="discount_type"
                                        onChange={() => {
                                            setFieldValue('discount_type', 'all');
                                            setSelectProducts(false);
                                        }}
                                        value="all"
                                        className={
                                            'checked:bg-jumbleng-primary mr-2 border-2 transition-all ease-in duration-200 border-white rounded-full shadow-[0_0_0_1px_#5E5BA7] w-3 h-3 bg-white  appearance-none'
                                        }
                                    />
                                    All products
                                </label>
                                <label className="mr-4 w-fit">
                                    <Field
                                        type="radio"
                                        name="discount_type"
                                        onChange={() => {
                                            setFieldValue('discount_type', 'selected');
                                            setSelectProducts(true);
                                        }}
                                        value="selected"
                                        className={
                                            'checked:bg-jumbleng-primary focus:outline-none  bg-[white] border-2 mr-2 transition-all ease-in duration-200 border-white rounded-full shadow-[0_0_0_1px_#5E5BA7] w-3 h-3 appearance-none'
                                        }
                                    />
                                    Selected Products
                                </label>
                                <ErrorMessage
                                    name={'discount_type'}
                                    render={(msg: any) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                />
                            </div>

                            {selectProducts && (
                                <div className="flex justify-between items-center">
                                    <p className="text-jumbleng-gray text-[16px] mt-6 mb-5 font-semibold">{products.length} product(s) selected</p>
                                    <div className="flex text-jumbleng-primary gap-2 items-center font-bold">
                                        <Icon icon="icon-park-outline:ad-product" />{' '}
                                        <Popup
                                            trigger={<p className="nav__text cursor-pointer">Add Products</p>}
                                            position="right center"
                                            modal
                                            contentStyle={{ width: 'fit-content' }}
                                            closeOnDocumentClick={false}
                                        >
                                            {(close: () => void) => (
                                                <AddorRemoveProduct
                                                    close={close}
                                                    data={productlist}
                                                    handleChange={handleChange}
                                                    query={query}
                                                    setQuery={setQuery}
                                                />
                                            )}
                                        </Popup>
                                    </div>
                                </div>
                            )}
                            <div className="flex justify-between gap-2 mt-8">
                                <div
                                    style={{ border: '1px solid #5E5BA7' }}
                                    className=" rounded-lg grid justify-center py-2 w-[168px] h-[48px]  cursor-pointer items-center "
                                    onClick={close}
                                >
                                    <p className="font-semibold text-[#5E5BA7] text-[16px] "> Cancel</p>
                                </div>
                                <Button
                                    type={'submit'}
                                    onClick={handleSubmit}
                                    loading={isLoading}
                                    message={`${isEdit ? 'Save Edits' : 'Apply'}`}
                                    className="bg-[#5E5BA7] text-white font-semibold rounded-lg flex items-center justify-center py-2 cursor-pointer w-[168px] h-[48px]"
                                ></Button>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ApplyDiscount;
