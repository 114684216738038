import { Link } from 'react-router-dom';

import EmptyRequest from '../../../assets/images/svg/emptyRequest.svg';

const EmptySupplier = () => {
    return (
        <div className="p-2 pt-0">
            <div>
                <p className="text-[#5E5BA7] text-[16px] mt-4 mb-4">
                    <span className="font-bold">0</span> Supliers Added
                </p>
            </div>
            <div className="grid justify-center">
                <img src={EmptyRequest} alt="empty" />
            </div>
            <div className="grid justify-center mt-4">
                <p className="text-[28px] grid text-center">You have no suppliers</p>
                <div className="grid justify-center mt-4  md:w-[376px]">
                    <p className="grid justify-center text-center">
                        There are no supplier in your vendor list, search for suppliers from feeds and send a connection request to have them on your
                        list.
                    </p>
                </div>
                <Link to="/feed">
                    <button className="md:px-[32.5px] md:py-4 border p-2 bg-jumbleng-primary rounded-lg text-white mx-auto w-full mt-[60px]">
                        Go to feed
                    </button>
                </Link>
            </div>

            <div></div>
        </div>
    );
};

export default EmptySupplier;
