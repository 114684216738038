import { Dispatch } from 'redux';

import { types } from './OrderActionTypes';

export const listMyOrders = (currentStatus: string) => async (dispatch: Dispatch) => {
    dispatch({ type: types.setStatus, payload: currentStatus });
};

export const listCustomerOrders = (currentStatus: string) => async (dispatch: Dispatch) => {
    dispatch({ type: types.setCustomerStatus, payload: currentStatus });
};

export const increment = (id: string) => (dispatch: Dispatch) => {
    dispatch({
        type: types.incrementItem,
        payload: id,
    });
};

export const decrement = (id: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: types.decrementItem,
        payload: id,
    });
};
export const updateqty = (id: string, value: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: types.updateQty,
        payload: { id, value },
    });
};

export const remove = (id: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: types.removeItem,
        payload: id,
    });
};
