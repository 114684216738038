import EmptyContent from '../../components/EmptyContent/EmptyContent';

const MyArchive = () => {
    return (
        <>
            <EmptyContent content="Archive" />
            {/* <div>
                <div className="mb-[1.5rem] flex justify-between items-center">
                    <p className="flex text-[0.78125rem] text-jumbleng-gray gap-4">
                        <p
                            style={{
                                border: '1px solid #7C7C7D',
                                borderRadius: '16px',
                                padding: '5px'
                            }}
                            className="flex gap-2"
                        >
                            Customer Invoice{' '}
                            <p
                                style={{
                                    backgroundColor: '#DFDEED',
                                    borderRadius: '33px'
                                }}
                                className="px-2"
                            >
                                16
                            </p>{' '}
                        </p>
                        <p
                            style={{
                                border: '1px solid #7C7C7D',
                                borderRadius: '16px',
                                padding: '5px'
                            }}
                            className="flex gap-2"
                        >
                            My Invoice{' '}
                            <p
                                style={{
                                    backgroundColor: '#DFDEED',
                                    borderRadius: '33px'
                                }}
                                className="px-2"
                            >
                                16
                            </p>
                        </p>
                    </p>
                    <div className="flex items-center relative">
                        <div className="w-[7.7rem] h-[2.3rem] relative mr-[45px]">
                            <i className="fa fa-search icon absolute top-1/2 -translate-y-1/2 px-[0.79rem]" />
                            <input className="w-full bg-[#F8F8FB] border-b-2 focus:outline-none pl-[2.08rem] h-full placeholder:text-[0.78165rem] text-[0.78125rem] text-jumbleng-gray placeholder:text-jumbleng-gray" placeholder="Search invoice" />
                        </div>

                        <Popup
                            trigger={
                                <div className="flex hover:cursor-pointer text-[0.78125rem] mr-[45px] font-normal text-jumbleng-gray items-center">
                                    <span className="mr-[9px] text-[16px]">Filter</span>
                                    <img src={Filter} className="w-[1.17rem] h-[1.17rem]" alt="Filter" />
                                </div>
                            }
                            on="hover"
                            closeOnDocumentClick
                            arrow={false}
                            contentStyle={{
                                padding: '10px',
                                borderRadius: '12px',
                                width: '167px'
                            }}
                        >
                            <div className=" grid justify-center">
                                <div className=" grid justify-center mt-2 mb-2 text-[#525171] font-semibold hover:bg-[#DFDEED] hover:rounded-xl hover:w-[101px]"> Fulfilled</div>
                                <div className="grid justify-center mt-2 mb-2 text-[#525171] font-semibold hover:bg-[#DFDEED] hover:rounded-xl hover:w-[101px]">Unfulfilled</div>
                            </div>
                        </Popup>
                    </div>
                </div>

                <div className=" p-5 mt-5">
                    <div className="grid grid-cols-7 pt-4 bg-white p-5   font-bold">
                        <div className="jumbleng-gray-main text-base ">Order date</div>
                        <div className="jumbleng-gray-main text-base grid justify-center">Ordering Company</div>
                        <div className="jumbleng-gray-main text-base grid justify-center">Invoice number</div>
                        <div className="jumbleng-gray-main text-base grid justify-center"> Date Issued</div>
                        <div className="jumbleng-gray-main text-base grid justify-center">Order Value</div>

                        <div className="jumbleng-gray-main text-base grid justify-center">Status</div>
                        <div className="jumbleng-gray-main text-base grid justify-center">Action</div>
                    </div>

                    <div className="grid grid-cols-7 pt-4 p-5  bg-white ">
                        <div className="text-sm ">
                            {' '}
                            <input type="checkbox" />
                            22 JUL, 2022{' '}
                        </div>
                        <div className="text-sm grid justify-center">Gravitas Enterprise</div>
                        <div className="text-sm grid justify-center"> INV-20322</div>
                        <div className="text-sm grid justify-center"> 22 Jul, 2022 8.00AM</div>
                        <div className="text-sm grid justify-center">₦ 2,000,000</div>

                        <div className=" grid justify-center">
                            <p style={{ color: '#D29C17' }} className="text-sm bg-[#FEF8E8] py-2 px-5 rounded-2xl">
                                Unpaid
                            </p>
                        </div>
                        <Popup
                            trigger={
                                <div className="grid justify-center">
                                    <img src={More} alt="more" />
                                </div>
                            }
                            on="hover"
                            closeOnDocumentClick
                            arrow={false}
                            contentStyle={{
                                padding: '10px',
                                borderRadius: '12px',
                                width: '167px'
                            }}
                        >
                            <div className=" grid justify-center">
                                <div className=" grid justify-center">
                                    {' '}
                                    <Link to="/unpaidinvoice">Print Invoice</Link>
                                </div>
                                <div className="grid justify-center pt-2 pb-2">Archive Invoice</div>
                                <div className="grid justify-center  pb-2">Cancel Invoice</div>
                            </div>
                        </Popup>
                    </div>

                    <div className="grid grid-cols-7 pt-4 p-5" style={{ backgroundColor: '#F9F9FA' }}>
                        <div className="text-sm ">
                            {' '}
                            <input type="checkbox" />
                            22 JUL, 2022{' '}
                        </div>
                        <div className="text-sm grid justify-center">Gravitas Enterprise</div>
                        <div className="text-sm grid justify-center"> INV-20322</div>
                        <div className="text-sm grid justify-center"> 22 Jul, 2022 8.00AM</div>
                        <div className="text-sm grid justify-center">₦ 2,000,000</div>

                        <div className=" grid justify-center">
                            <p style={{ color: '#084F21' }} className="text-sm bg-[#DEEAE2] py-2 px-5 rounded-2xl ">
                                Paid
                            </p>
                        </div>

                        <Popup
                            trigger={
                                <div className="grid justify-center">
                                    <img src={More} alt="more" />
                                </div>
                            }
                            on="hover"
                            closeOnDocumentClick
                            arrow={false}
                            contentStyle={{
                                padding: '10px',
                                borderRadius: '12px',

                                width: '167px'
                            }}
                        >
                            <div className=" grid justify-center">
                                <div className=" grid justify-center">
                                    {' '}
                                    <Link to="/paidinvoice">View Reciept</Link>
                                </div>
                                <div className="grid justify-center pt-2 pb-2">Archive Invoice</div>
                                <div className="grid justify-center  pb-2">Print Invoice</div>
                            </div>
                        </Popup>
                    </div>

                    <div className="grid grid-cols-7 pt-4 p-5  bg-white ">
                        <div className="text-sm ">
                            {' '}
                            <input type="checkbox" />
                            22 JUL, 2022{' '}
                        </div>
                        <div className="text-sm grid justify-center">Gravitas Enterprise</div>
                        <div className="text-sm grid justify-center"> INV-20322</div>
                        <div className="text-sm grid justify-center"> 22 Jul, 2022 8.00AM</div>
                        <div className="text-sm grid justify-center">₦ 2,000,000.00</div>

                        <div className=" grid justify-center">
                            <p style={{ color: '#840B0B' }} className="text-sm bg-[#F5E7E7] py-2 px-5 rounded-2xl">
                                Cancelled
                            </p>
                        </div>

                        <Popup
                            trigger={
                                <div className="grid justify-center">
                                    <img src={More} alt="more" />
                                </div>
                            }
                            on="hover"
                            closeOnDocumentClick
                            arrow={false}
                            contentStyle={{
                                padding: '10px',
                                borderRadius: '12px',
                                width: '167px'
                            }}
                        >
                            <div className=" grid justify-center">
                                <div className=" grid justify-center pt-2">
                                    {' '}
                                    <Link to="/cancelledinvoice">Send invoice</Link>
                                </div>
                                <div className="grid justify-center pt-4 pb-4">Order fulfilled</div>
                                <div className=" grid justify-center pb-2"> Cancel Order</div>
                            </div>
                        </Popup>
                    </div>
                    <div className="grid justify-center mt-5">
                        <div className="flex gap-4">
                            Showing Result page{' '}
                            <p
                                className="bg-white px-4 "
                                style={{
                                    border: '1px solid #9796A9',
                                    borderRadius: '4px'
                                }}
                            >
                                1
                            </p>{' '}
                            of 194 pages{' '}
                            <p style={{ color: '#5E5BA7' }} className="flex">
                                Next <img src={ArrowRight} alt="arrowright" />{' '}
                            </p>{' '}
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default MyArchive;
