import { BaseQueryFn, createApi, FetchArgs } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth, createRequest, CustomError } from './shared';

export const complianceApi = createApi({
    reducerPath: 'complianceApi',
    baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, any>>,
    tagTypes: ['compliance'],
    endpoints: (builder) => ({
        updateBusinessInfo: builder.mutation({
            query: (data) => {
                return {
                    url: `compliance/business-info`,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: ['compliance'],
        }),
        submitCompliance: builder.mutation({
            query: () => {
                return {
                    url: `compliance/submit`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: ['compliance'],
        }),
        getOwnerIdTypes: builder.query<any, void>({
            query: () => createRequest(`compliance/owner_id_types`),
        }),
        getPermissions: builder.query<any, void>({
            query: () => createRequest(`misc/permissions`),
        }),
        getUtilityBillTypes: builder.query<any, void>({
            query: () => createRequest(`compliance/business_utility_bill_types`),
        }),
        getCompliance: builder.query<any, void>({
            query: () => createRequest(`compliance/me`),
            providesTags: ['compliance'],
        }),
        getValidateCompliance: builder.query<any, void>({
            query: () => createRequest(`compliance/validate`),
            providesTags: ['compliance'],
        }),
        getDeclinationMaps: builder.query<any, void>({
            query: () => createRequest(`compliance/declination_maps`),
            providesTags: ['compliance'],
        }),
        updateUserInfo: builder.mutation({
            query: (data) => {
                const form_data = new FormData();
                for (const key in data) {
                    form_data.append(key, data[key]);
                }
                return {
                    url: `compliance/owner-info`,
                    method: 'PUT',
                    body: form_data,
                };
            },
            invalidatesTags: ['compliance'],
        }),
        updateDocumentation: builder.mutation({
            query: (data) => {
                const form_data = new FormData();
                for (const key in data) {
                    form_data.append(key, data[key]);
                }
                return {
                    url: `compliance/documentation`,
                    method: 'PUT',
                    body: form_data,
                };
            },
            invalidatesTags: ['compliance'],
        }),
    }),
});

export const {
    useGetPermissionsQuery,
    useGetDeclinationMapsQuery,
    useGetComplianceQuery,
    useSubmitComplianceMutation,
    useGetValidateComplianceQuery,
    useGetOwnerIdTypesQuery,
    useGetUtilityBillTypesQuery,
    useUpdateBusinessInfoMutation,
    useUpdateDocumentationMutation,
    useUpdateUserInfoMutation,
} = complianceApi;
