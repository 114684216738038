import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Requests from './Requests';
import SentRequests from './SentRequests';

const RequestModal = () => {
    const [activeTab, setActiveTab] = useState<'vendor' | 'sent' | 'all'>('vendor');

    const toggleTab = (tabName: 'vendor' | 'sent' | 'all') => {
        setActiveTab(tabName);
    };
    const navigate = useNavigate();

    return (
        <>
            <div className=" mt-4 w-full">
                <div className="flex justify-between border-b mb-[0.5rem] mt-4 w-[100%]">
                    <ul className="flex justify-between w-full">
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => toggleTab('vendor')}
                                className={`${
                                    activeTab === 'vendor' && 'font-bold border-b border-b-jumbleng-primary text-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full text-[1rem]`}
                            >
                                Vendor Request
                            </a>
                        </li>
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => toggleTab('sent')}
                                className={`${
                                    activeTab === 'sent' && 'font-bold border-b border-b-jumbleng-primary text-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full text-[1rem]`}
                            >
                                Request Sent
                            </a>
                        </li>
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => navigate('/requestsent')}
                                className={`${
                                    activeTab === 'all' && 'font-bold border-b border-b-jumbleng-primary text-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full text-[1rem]`}
                            >
                                View All{' '}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {activeTab === 'vendor' ? <Requests /> : <SentRequests />}
        </>
    );
};

export default RequestModal;
