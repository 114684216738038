import * as Yup from 'yup';

import { IBusinessInfo, IDocumentation, IUserInfo } from '../interfaces/schema-interface/company';

const FILE_SIZE = 5072 * 2048;
const DOC_SIZE = 5072 * 2048;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

export const initialBusinessInfoValues: IBusinessInfo = {
    business_address: '',
    business_city: '',
    registered_business_name: '',
    business_about: '',
    business_email: '',
};
export const initialDocumentationValues: IDocumentation = {
    business_rc_number: '',
    business_tin: '',
    business_utility_bill_type: '',
    business_utility_bill_image: null,
    business_cac: null,
};
export const initialUserInfoValues: IUserInfo = {
    owner_first_name: '',
    owner_last_name: '',
    owner_phone: '',
    owner_gender: '',
    owner_dob: '',
    owner_address: '',
    owner_city: '',
    owner_id_type: '',
    owner_id_number: '',
    owner_id_image: null,
    code: '+234',
};

export const DocumentationSchema = Yup.object().shape({
    business_rc_number: Yup.string().required('RC number field is a required field'),
    business_tin: Yup.string().required('Busincess TIN field is a required field'),
    business_utility_bill_type: Yup.string().required('Utility type field is a required field'),
    business_utility_bill_image: Yup.lazy((value) => {
        switch (typeof value) {
            case 'string':
                return Yup.mixed().test('url', (value) => value && value.includes('http')); // schema for image url
            case 'object':
                return Yup.mixed()
                    .test('fileFormat', 'Unsupported Image Format', (value) => value && SUPPORTED_FORMATS.includes(value.type))
                    .test('fileSize', 'File is too large', (value) => value && value.size <= DOC_SIZE); // schema for image file
            default:
                return Yup.mixed().test('url', (value) => value && value.includes('http')); // here you can decide what is the default
        }
    }),
    business_cac: Yup.lazy((value) => {
        switch (typeof value) {
            case 'string':
                return Yup.mixed().test('url', (value) => value && value.includes('http')); // schema for image url
            case 'object':
                return Yup.mixed()
                    .test('fileFormat', 'Unsupported Image Format', (value) => value && SUPPORTED_FORMATS.includes(value.type))
                    .test('fileSize', 'File is too large', (value) => value && value.size <= DOC_SIZE); // schema for image file
            default:
                return Yup.mixed().test('url', (value) => value && value.includes('http')); // here you can decide what is the default
        }
    }),
});

export const BusinessInfoSchema = Yup.object().shape({
    business_address: Yup.string().required('Address field is a required field'),
    business_email: Yup.string().email('Invalid email format').trim().required('Email is a required field'),
    business_city: Yup.string().required('City field is a required field'),
    registered_business_name: Yup.string().required('Name field is a required field'),
    business_about: Yup.string().required('About field is a required field'),
});

export const UserInfoSchema = Yup.object().shape({
    owner_first_name: Yup.string().required('First Name field is a required field'),
    owner_last_name: Yup.string().required('Last Name field is a required field'),
    owner_phone: Yup.number()
        .required('Phone number field is a required field')
        .typeError('Only digit(s) is allowed')
        .test('len', 'phone number must be 10 digits', (val: any) => val?.toString().length === 10),
    owner_gender: Yup.string().required('Gender field is a required field'),
    owner_dob: Yup.date().required('dob field is a required field').nullable(),
    owner_address: Yup.string().required('Address field is a required field'),
    owner_city: Yup.string().required('city field is a required field'),
    owner_id_type: Yup.string().required('id_type field is a required field'),
    owner_id_number: Yup.string().required('id_number field is a required field'),
    owner_id_image: Yup.lazy((value) => {
        switch (typeof value) {
            case 'string':
                return Yup.mixed().test('url', (value) => value && value.includes('http')); // schema for image url
            case 'object':
                return Yup.mixed()
                    .test('fileFormat', 'Unsupported Image Format', (value) => value && SUPPORTED_FORMATS.includes(value.type))
                    .test('fileSize', 'File is too large', (value) => value && value.size <= FILE_SIZE); // schema for image file
            default:
                return Yup.mixed().test('url', (value) => value && value.includes('http')); // here you can decide what is the default
        }
    }),
});

export type IinitialDoc = {
    file: any;
};
export const initialDoc: IinitialDoc = {
    file: null,
};

export const DocSchema = Yup.object().shape({
    file: Yup.lazy((value) => {
        switch (typeof value) {
            case 'string':
                return Yup.mixed().test('url', (value) => value && value.includes('http')); // schema for image url
            case 'object':
                return Yup.mixed()
                    .test('fileFormat', 'Unsupported Image Format', (value) => value && SUPPORTED_FORMATS.includes(value.type))
                    .test('fileSize', 'File is too large', (value) => value && value.size <= FILE_SIZE); // schema for image file
            default:
                return Yup.mixed().test('url', (value) => value && value.includes('http')); // here you can decide what is the default
        }
    }),
});
