import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from '../store';

interface CustomError {
    data: {
        code: number;
        error: any;
        message: string;
        status: boolean;
    };
    status: number;
}

const { REACT_APP_BACKEND_API } = process.env;

const baseUrl = `${REACT_APP_BACKEND_API}/v1/`;

export const teamsApi = createApi({
    reducerPath: 'teamsApi',
    tagTypes: ['teams'],
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).authStore.authorization.access_token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, unknown>>,

    endpoints: (builder) => ({
        getRoles: builder.query<any, void>({
            query: () => {
                return {
                    url: 'company/team/roles',
                    method: 'get',
                };
            },
        }),
        addMembers: builder.mutation({
            query: (values: any) => {
                return {
                    url: 'company/team',
                    method: 'post',
                    body: { team_members: values },
                };
            },
            invalidatesTags: ['teams'],
        }),
        getMembers: builder.query<any, void>({
            query: () => {
                return {
                    url: 'company/team',
                    method: 'get',
                };
            },
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'teams' as const, id })), 'teams'] : ['teams'],
        }),
        deleteMember: builder.mutation({
            query: (id) => {
                return {
                    url: `company/team/${id}`,
                    method: 'delete',
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'teams', id }],
        }),
        editMember: builder.mutation({
            query: ({ id, ...rest }) => {
                return {
                    url: `company/team/${id}`,
                    method: 'put',
                    body: rest,
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'teams', id }],
        }),
    }),
});

export const { useAddMembersMutation, useGetMembersQuery, useDeleteMemberMutation, useEditMemberMutation, useGetRolesQuery } = teamsApi;
