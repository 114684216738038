import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import NotFound from '../../../assets/images/png/no-image.jpg';
import avatar from '../../../assets/images/png/noImage.png';
import ArrowDown from '../../../assets/images/svg/arrow-down.svg';
import LeftArrow from '../../../assets/images/svg/arrow-left.svg';
import Pagination from '../../../components/Pagination/Pagination';
import PopUpModal from '../../../components/PopModal/PopUpModal';
import Loading from '../../../components/UI/Spinner/Loading';
import Wrapper from '../../../HOC/Wrapper';
import { initialSinglePaymentstate } from '../../../redux/features/dtos';
import { OrderItemsType } from '../../../redux/features/interfaces';
import { useGetAPaymentQuery } from '../../../redux/services';
import { RootState } from '../../../redux/store';
import { toLocaleFixed, transformedDateWithTime } from '../../../utils/utils';
import { IPaginationQuery, paginationQuery } from '../../invoice/ExpandedInvoice/ExpandedInvoice';
import OrderItemDetails from '../../order/OrderItemDetails';
import EmptySearch from '../../vendorlist/EmptyContent/EmptySearch';
import PaymentActions from './PaymentActions';

const ExpandedPayment = () => {
    const { iscustomer } = useSelector((state: RootState) => state.getPaymentReducer);

    const navigate = useNavigate();
    const id = useParams()?.id as string;
    const { data: paymentdetails = initialSinglePaymentstate, isLoading } = useGetAPaymentQuery(id);
    const { invoice, status, proof, order, created_at, receipt_issued } = paymentdetails;
    const [query, setQuery] = useState<IPaginationQuery>(paginationQuery);
    const [q, setQ] = useState('');

    const OrderItems = useMemo(() => {
        return order?.order_items?.filter((item) => {
            if (q === '') {
                return item;
            } else if (item.catalogue.product.name.toLowerCase().includes(q.toLowerCase())) {
                return item;
            }
        });
    }, [q, order?.order_items]);

    const paginatedOrderItems = useMemo(() => {
        const startIndex = query.page * query.limit - query.limit;
        const endIndex = startIndex + query.limit;
        return OrderItems.slice(startIndex, endIndex);
    }, [OrderItems, query.page, query.limit]);

    return isLoading ? (
        <div className="min-h-screen flex justify-center flex-col">
            <Loading />
        </div>
    ) : (
        <Wrapper>
            <div className="mt-8">
                <div onClick={() => navigate(-1)} className="cursor-pointer w-[5rem] ">
                    <div className="flex text-[#5E5BA7]">
                        <img src={LeftArrow} alt="leftarrow" className="pr-2" />
                        Back
                    </div>
                </div>
                <div className="flex items-center justify-between mt-[1.35rem] mb-12">
                    <h3 className="font-semibold text-[21px]"> {iscustomer ? 'Customer Payment' : 'My Payment'}</h3>
                </div>

                <div className="mt-8 mb-4 grid  grid-cols-5 gap-24">
                    <div className="col-span-1">
                        <div className="text-sm  flex gap-2 ">
                            <div className="flex space-x-2">
                                <div className=" w-12 h-12">
                                    <img
                                        className="rounded-full border border-gray-100 shadow-sm"
                                        src={
                                            iscustomer
                                                ? invoice.vendor.image
                                                    ? invoice.vendor.image
                                                    : `${avatar}`
                                                : invoice.ordering_company.image
                                                ? invoice.ordering_company.image
                                                : `${avatar}`
                                        }
                                        alt="user image"
                                    />{' '}
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="text-[#131221] text-[16px]">{iscustomer ? invoice.ordering_company.name : invoice.vendor.name}</div>
                                <div className="text-[14px] text-[#706F77]">
                                    {' '}
                                    {iscustomer ? invoice.ordering_company.company_type : invoice.vendor.company_type}
                                </div>
                                <div className="text-[14px] text-[#706F77] ">
                                    {' '}
                                    {iscustomer ? invoice.ordering_company.address : invoice.vendor.address}.
                                </div>
                                <div className="text-[14px] text-[#706F77]">
                                    {iscustomer ? invoice.ordering_company.phone_number : invoice.vendor.phone_number}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div>
                            <div className="font-bold text-[#525171] text-[16px]">Order date:</div>
                            <div className="text-[#525171] text-[16px]">{transformedDateWithTime(order.created_at)}</div>
                        </div>

                        <div className="mt-6">
                            <div className="font-bold text-[#525171] text-[16px]">Order number:</div>
                            <div className="text-[#525171] text-[16px]">
                                <span className="font-bold text-[#131221]">ORD</span>-{order.order_no}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div>
                            <div className="font-bold text-[#525171] text-[16px]">Invoice date:</div>
                            <div className="text-[#525171] text-[16px]">{transformedDateWithTime(invoice.created_at)}</div>
                        </div>

                        <div className="mt-6">
                            <div className="font-bold text-[#525171] text-[16px]">Invoice number:</div>
                            <div className="text-[#525171] text-[16px]">
                                <span className="font-bold text-[#131221]">INV</span>-{invoice.invoice_no}
                            </div>
                        </div>
                    </div>

                    <div className="col-span-1">
                        <div>
                            <div className="font-bold text-[#525171] text-[16px]">Total Order value:</div>
                            <div className="text-[#131221] text-[25px] font-black">N{toLocaleFixed(invoice.total_order_value, 2)}</div>
                            <div className="mt-3 w-[130px] flex flex-col items-center">
                                <div className="font-bold text-[#525171] text-[16px]">Total VAT value:</div>
                                <div className="text-[#131221] text-[16px] font-black">
                                    {' '}
                                    N{' '}
                                    {toLocaleFixed(
                                        order.order_items.reduce((total: number, item: OrderItemsType) => item.vat_amount + total, 0),
                                        2,
                                    )}{' '}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="font-bold text-[#525171] text-[16px]">Status</div>
                        <div className=" grid justify-start">
                            <div
                                className={`${
                                    status === 'not_confirmed'
                                        ? 'bg-amber-100 text-[#D29C17]'
                                        : status === 'confirmed'
                                        ? 'text-[#084F21] bg-green-200'
                                        : status === 'pending'
                                        ? 'bg-amber-100 text-[#D29C17]'
                                        : 'bg-red-100 text-[#840B0B]'
                                } py-2 px-5 rounded-2xl flex gap-1`}
                            >
                                <p className="text-sm ">{status === 'not_confirmed' ? 'unconfirmed' : status}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="mb-4" />
                <div className="mt-4 mb-4 flex justify-end">
                    <PaymentActions
                        payment_id={id}
                        status={status}
                        id={order.id}
                        iscustomer={iscustomer}
                        receipt_no={invoice.receipt_no}
                        vendor={invoice.vendor}
                        order={order}
                        invoice={invoice}
                        created_at={created_at}
                        image={proof}
                        ordering_company={order.ordering_company}
                        receipt_issued={receipt_issued}
                    />
                </div>
                <div className="flex">
                    <div className="text-[#5E5BA7] text-[16px] ">
                        You have <span className="font-bold">{order.order_items.length}</span> items
                    </div>

                    <div className="flex items-center relative" style={{ marginLeft: 'auto' }}>
                        <div className="w-[7.7rem] h-[2.3rem] relative mr-[45px]">
                            <i className="fa fa-search icon absolute top-1/2 -translate-y-1/2 px-[0.79rem]" />
                            <input
                                className="w-full bg-[#F8F8FB] border-b-2 focus:outline-none pl-[2.08rem] h-full placeholder:text-[0.78165rem] text-[0.78125rem] text-jumbleng-gray placeholder:text-jumbleng-gray"
                                placeholder="Search products"
                                value={q}
                                onChange={(e) => setQ(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className=" p-5 mt-5">
                    <div className="grid grid-cols-7 pt-4 bg-white p-5 font-bold">
                        <div className="jumbleng-gray-main text-base col-span-1 ">SKU</div>
                        <div className="jumbleng-gray-main text-base grid  col-span-1 ">Items Ordered</div>
                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1">Quantity</div>
                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1"> UOM</div>

                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1">Prices</div>
                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1">
                            <div className="flex gap-2 items-center">
                                <p> VAT</p>
                                <img src={ArrowDown} alt="a-down" />
                            </div>
                        </div>
                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1">Total Order Value</div>
                    </div>

                    {isEmpty(paginatedOrderItems) ? (
                        <EmptySearch content="Item" />
                    ) : (
                        paginatedOrderItems.map((item: OrderItemsType, index: number) => (
                            <div
                                className={`grid grid-cols-7 pt-4 p-5 gap-1 cursor-pointer  hover:bg-[#5D5BA7] hover:text-white items-center ${
                                    index % 2 == 0 ? 'bg-[#fff]' : 'bg-[#F9F9FA]'
                                }`}
                                key={item.id}
                            >
                                <div className="text-sm col-span-1 flex gap-2 ">
                                    <div className="grid content-center">
                                        <input type="checkbox" />
                                    </div>
                                    <div className="grid content-center">
                                        <div>{item.catalogue.product.sku}</div>
                                    </div>
                                </div>
                                <div className="text-sm col-span-1">
                                    <PopUpModal
                                        content={
                                            <div className="flex gap-2  items-center ">
                                                <img
                                                    src={item.catalogue.product.picture ? item.catalogue.product.picture : NotFound}
                                                    alt="avatar"
                                                    className="h-[2.2rem] w-[2.2rem] object-contain"
                                                />
                                                <span className="truncate w-[135px] "> {item.catalogue.product.name}</span>
                                            </div>
                                        }
                                        className="text-sm grid "
                                        popupcontent={true}
                                    >
                                        {(close: () => void) => (
                                            <div>
                                                <button className="close" onClick={close}>
                                                    &times;
                                                </button>
                                                <OrderItemDetails
                                                    Props={{
                                                        created_at: item.created_at,
                                                        order_no: order.order_no,
                                                        iscustomer: iscustomer,
                                                        id: order.id,
                                                        order_items: order.order_items,
                                                        qty: item.qty,
                                                        product: item.catalogue.product,
                                                        status: order.status,
                                                        images: item.catalogue.product.images,
                                                        picture: item.catalogue.product.picture,
                                                        ordering_company: order.ordering_company,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </PopUpModal>
                                </div>
                                <div className="text-sm grid justify-center col-span-1">{item.qty.toLocaleString()}</div>
                                <div className="text-sm grid justify-center col-span-1">{item.catalogue.product.uom}</div>
                                <div className="text-sm grid justify-center col-span-1">₦ {toLocaleFixed(item.buying_price, 2)}</div>
                                <div className="text-sm grid justify-center col-span-1">
                                    {item.vat_percent ? item.vat_percent + '%' : item.catalogue.product.vat ? item.catalogue.product.vat + '%' : '-'}
                                </div>
                                <div className="text-sm grid justify-center col-span-1">
                                    ₦ {toLocaleFixed(item.qty * Number(item.buying_price), 2)}
                                </div>
                            </div>
                        ))
                    )}
                </div>

                <Pagination query={query} setQuery={setQuery} data={OrderItems} count={OrderItems.length} />
            </div>
        </Wrapper>
    );
};

export default ExpandedPayment;
