import EmptyRequest from '../../../assets/images/svg/invoiceBrown.svg';

const EmptyBilling = () => {
    return (
        <div className="p-2 pt-0">
            <div>
                <p className="text-[#5E5BA7] text-[16px] mt-6 mb-4"></p>
            </div>
            <div className="grid justify-center">
                <img src={EmptyRequest} alt="empty" />
            </div>
            <div className="grid justify-center mt-4">
                <p className="text-[28px] grid text-center">You have no billing history</p>
                <div className="grid justify-center mt-4  md:w-[376px]">
                    <p className="grid justify-center text-center">
                        You haven’t subscribed yet. Click the above button to <b>subscribe to a plan.</b>
                    </p>
                </div>
            </div>

            <div></div>
        </div>
    );
};

export default EmptyBilling;
