import { BaseQueryFn, createApi, FetchArgs } from '@reduxjs/toolkit/query/react';

import { ISubscriptionInterface, ISubscriptionPlan } from '../features/interfaces';
import { baseQueryWithReauth, CustomError } from './shared';

const createRequest = (url: any) => ({ url });
export const subscriptionApi = createApi({
    reducerPath: 'subscriptionApi',
    baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, any>>,
    tagTypes: ['subscriptions'],
    endpoints: (builder) => ({
        subscribe: builder.mutation({
            query: (data) => {
                return {
                    url: `/subscription/company/initiate`,
                    method: 'post',
                    body: {},
                    params: { ...data },
                };
            },
            invalidatesTags: ['subscriptions'],
        }),

        getAllSubscriptions: builder.query<ISubscriptionInterface[], void>({
            query: () => createRequest(`subscription/plans`),
            transformResponse: (results: { data: any }) => results.data,
        }),
        getLastSubscriptions: builder.query<ISubscriptionPlan, void>({
            query: () => createRequest(`subscription/company/last`),
            providesTags: ['subscriptions'],
            transformResponse: (results: { data: any }) => results.data,
        }),
        getCurrentSubscriptions: builder.query<ISubscriptionPlan, void>({
            query: () => createRequest(`subscription/company/current`),
            providesTags: ['subscriptions'],
            transformResponse: (results: { data: any }) => results.data,
        }),
        getSubscriptionHistory: builder.query<ISubscriptionInterface[], void>({
            query: () => createRequest(`subscription/company/history`),
            providesTags: ['subscriptions'],
            transformResponse: (results: { data: any }) => results.data,
        }),
    }),
});

export const {
    useSubscribeMutation,
    useGetAllSubscriptionsQuery,
    useGetCurrentSubscriptionsQuery,
    useGetLastSubscriptionsQuery,
    useGetSubscriptionHistoryQuery,
} = subscriptionApi;
