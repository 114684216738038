import { useSelector } from 'react-redux';

import { RootState } from '../redux/store';

const useGetRoles = () => {
    const authStore = useSelector((state: RootState) => state.authStore);
    const { user, company } = authStore;
    return { user_role: user?.role, company_type: company?.company_type };
};

export default useGetRoles;
