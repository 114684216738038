import { ErrorMessage, Field, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '../../../components/Button';
import { IBusinessInfo } from '../../../interfaces/schema-interface/company';
import { closeModal, openModal } from '../../../redux/modal/modalRedux';
import { useUpdateBusinessInfoMutation } from '../../../redux/services/complianceApi';
import { BusinessInfoSchema, initialBusinessInfoValues } from '../../../schema/company.schema';

const Form1 = ({ setStep, data }: any) => {
    const [updateBusiness, { isLoading, isSuccess, isError, error }] = useUpdateBusinessInfoMutation();
    const valuesRef = useRef<any>(null);
    const dispatch = useDispatch();
    const [country, setCountry] = useState<any>(data.business_country);
    const [region, setRegion] = useState<any>(data.business_state);
    const [fieldError, setFieldError] = useState({ nationality: false, region: false, country: false });

    const handleOnSubmit = async (values: IBusinessInfo) => {
        if (!country) {
            setFieldError({ ...fieldError, country: true });
            return;
        } else if (!region) {
            setFieldError({ ...fieldError, region: true });
            return;
        } else {
            setFieldError({ ...fieldError, country: false, region: false });
            await updateBusiness({ ...values, business_country: country, business_state: region });
        }
    };
    const selectCountry = (val: any) => {
        setCountry(val);
        setFieldError({ ...fieldError, country: false });
        setRegion('');
    };
    const selectRegion = (val: any) => {
        if (val) {
            setRegion(val);
            setFieldError({ ...fieldError, region: false });
        } else {
            setRegion('');
        }
    };

    useEffect(() => {
        if (isLoading) {
            dispatch(openModal());
        }
        if (isSuccess) {
            setStep(2);
            toast.success('Business Info saved');
            dispatch(closeModal());
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
            dispatch(closeModal());
        }
    }, [isLoading, isSuccess, isError, error, dispatch, setStep]);

    return (
        <div className="md:w-[50%] w-full">
            <div className="flex items-start">
                <Formik
                    onSubmit={handleOnSubmit}
                    innerRef={valuesRef}
                    initialValues={{ ...initialBusinessInfoValues, ...data }}
                    validationSchema={BusinessInfoSchema}
                    enableReinitialize
                >
                    {({ handleSubmit, setFieldTouched }) => (
                        <div className="w-full flex flex-col gap-6">
                            <div>
                                <label className="text-[#131221] opacity-50">Registered Business Name</label>
                                <div className="mt-1 relative flex">
                                    <Field
                                        name={'registered_business_name'}
                                        placeholder={'Business name'}
                                        onKeyUp={() => setFieldTouched('registered_business_name', true)}
                                        className="border w-full text-[#131221] h-[40px] border-jumbleng-gray opacity-50  px-2  focus:outline-none "
                                    />
                                </div>

                                <ErrorMessage
                                    name={'registered_business_name'}
                                    render={(msg) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                />
                            </div>
                            <div>
                                <label className="text-[#131221] opacity-50"> Business Email</label>
                                <div className="mt-1 relative flex">
                                    <Field
                                        name={'business_email'}
                                        onKeyUp={() => setFieldTouched('business_email', true)}
                                        placeholder={'Enter Business Email'}
                                        className="border w-full text-[#131221] h-[40px] border-jumbleng-gray opacity-50  px-2  focus:outline-none "
                                    />
                                </div>
                                <ErrorMessage
                                    name={'business_email'}
                                    render={(msg) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                />
                            </div>
                            <div>
                                <label className="text-[#131221] opacity-50">About Business</label>
                                <div className="mt-1 relative flex">
                                    <Field
                                        as={'textarea'}
                                        name={'business_about'}
                                        onKeyUp={() => setFieldTouched('business_about', true)}
                                        style={{ resize: 'none' }}
                                        placeholder={'e.g What service does your business provide.'}
                                        className="border w-full  text-[#131221] py-2 min-h-[150px] border-jumbleng-gray opacity-50  px-2  focus:outline-none"
                                    />
                                </div>
                                <ErrorMessage
                                    name={'business_about'}
                                    render={(msg) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                />{' '}
                            </div>
                            <h1 className="font-semibold text-[21px]">Business Location</h1>
                            <div className="mt-2">
                                <label className="text-[#131221] opacity-50">Business Address</label>
                                <div className="mt-1 relative flex">
                                    <Field
                                        name={'business_address'}
                                        onKeyUp={() => setFieldTouched('business_address', true)}
                                        placeholder={'Enter address'}
                                        className="border w-full  text-[#131221] h-[40px] border-jumbleng-gray opacity-50  px-2  focus:outline-none "
                                    />
                                </div>
                                <ErrorMessage
                                    name={'business_address'}
                                    render={(msg) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>}
                                />
                            </div>
                            <div>
                                <label className="text-[#131221] opacity-50">City</label>
                                <div className="mt-1 relative flex">
                                    <Field
                                        name={'business_city'}
                                        onKeyUp={() => setFieldTouched('business_city', true)}
                                        placeholder={'Enter City'}
                                        className="border w-full  text-[#131221] h-[40px] border-jumbleng-gray opacity-50  px-2  focus:outline-none "
                                    />
                                </div>
                                <ErrorMessage
                                    name={'business_city'}
                                    render={(msg) => <div className={'text-[0.7812rem]  text-red-600 text-left font-normal'}>{msg}</div>}
                                />{' '}
                            </div>
                            <div className="grid md:grid-cols-2 w-full gap-4">
                                <div>
                                    <CountryDropdown
                                        value={country}
                                        onChange={(val) => selectCountry(val)}
                                        classes={'w-full placeholder:text-[0.78125rem] border border-[#030729] opacity-50  px-2  focus:outline-none'}
                                    />
                                    {fieldError.country && (
                                        <div className="text-[0.7812rem] text-red-400 text-left font-normal fon">country field is required</div>
                                    )}
                                </div>
                                <div>
                                    <RegionDropdown
                                        defaultOptionLabel="Select State"
                                        country={country}
                                        value={region}
                                        onChange={(val) => selectRegion(val)}
                                        classes={'w-full placeholder:text-[0.78125rem] border border-[#030729] opacity-50  px-2  focus:outline-none'}
                                    />
                                    {fieldError.region && (
                                        <div className="text-[0.7812rem] text-red-400 text-left font-normal fon">state field is required</div>
                                    )}
                                </div>
                            </div>
                            <Button
                                type={'submit'}
                                onClick={handleSubmit}
                                loading={false}
                                message={`Save and continue`}
                                className="px-[1.56rem] text-white md:py-[0.78125rem]  py-[0.45rem] h-[56px] max-w-[200px] bg-[#2F2E54]"
                            ></Button>
                        </div>
                    )}
                </Formik>{' '}
            </div>
        </div>
    );
};

export default Form1;
