import styled from 'styled-components';

export const Tabled = styled.div`
    overflow-x: scroll;
    .cta__section {
        display: flex;
        justify-content: space-between;
        padding-right: 24px;
        align-items: center;

        .status__cta {
            display: flex;
            font-size: 12px;
            gap: 24px;
            padding: 24px 0;

            .clicked {
                background-color: #dfdeed;
                border: 1px solid #5e5ba7;
                border-radius: 16px;
                padding: 5px 8px;
                display: flex;
                align-items: center;
                cursor: pointer;
                gap: 10px;

                span {
                    background-color: #5e5ba7;
                    color: #fff;
                    border-radius: 33px;
                    padding: 2px 8px;
                }
            }
            .unclicked {
                background-color: #f1f1f2;
                border: 1px solid #7c7c7d;
                border-radius: 16px;
                padding: 5px 8px;
                display: flex;
                align-items: center;
                gap: 10px;
                color: #525171;
                cursor: pointer;

                span {
                    background-color: #dfdeed;
                    color: #525171;
                    border-radius: 33px;
                    padding: 2px 8px;
                }
            }
        }

        .search__cta {
            border-bottom: 2px solid #00000050;
            padding: 9px 15px;
            display: flex;
            gap: 15px;

            input {
                background-color: transparent;
                font-weight: 400;
                font-size: 14px;
                outline: none;
                border: none;
            }
        }
    }

    table {
        width: 100%;

        thead {
            padding: 12px;
            height: 70px;

            tr {
                font-size: 12px;

                th {
                    font-weight: 700;
                    color: #131221;

                    p {
                        width: fit-content;
                    }
                }
            }
        }

        tbody {
            tr {
                height: 50px;
                text-align: center;
                font-weight: 400;
                font-size: 12px;

                td {
                    /* border-top: 1px solid #00000010; */

                    p {
                        width: fit-content;
                    }
                }

                .go {
                    p {
                        background-color: #deeae2;
                        color: #084f21;
                        padding: 5px 10px;
                        border-radius: 12px;
                        text-transform: capitalize;
                    }
                }
                .ready {
                    p {
                        background-color: #fef8e8;
                        color: #d29c17;
                        padding: 5px 10px;
                        border-radius: 12px;
                        text-transform: capitalize;
                    }
                }
                .stop {
                    p {
                        background-color: #f5e7e7;
                        color: #840b0b;
                        padding: 5px 10px;
                        border-radius: 12px;
                        text-transform: capitalize;
                    }
                }
                .naught {
                    color: red;
                    font-weight: bold;
                }
                .enbolden {
                    p {
                        font-weight: bold;
                        letter-spacing: 2px;
                        font-size: 20px;
                        text-align: center;
                        cursor: pointer;
                    }
                }
            }
        }
    }
`;
