import { FC, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import PurpleEnv from '../../../assets/images/svg/purpleEnv.svg';
import Share from '../../../assets/images/svg/sendWhite.svg';
import Cancel from '../../../components/CancelButton/Cancel';
import EmptyContent from '../../../components/EmptyContent/EmptyContent';

interface IProps {
    image: string;
    close: () => void;
}

const Proof: FC<IProps> = ({ image, close }) => {
    const componentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    return !image ? (
        <EmptyContent content={'proof'} />
    ) : (
        <div>
            <div className="mt-4 mr-4">
                <Cancel close={close} />
            </div>
            <div className="grid justify-center mb-4">
                <p className="grid justify-center text-[#131221] font-black text-[28px]">Proof of payment</p>
            </div>
            {image.split('.').pop() === 'jpg' || image.split('.').pop() === 'png' ? (
                <img ref={componentRef} src={image} alt="proof" height="500px" width="100%" />
            ) : (
                <embed ref={componentRef} src={image} height="500px" width="100%"></embed>
            )}
            <div className="flex justify-center gap-2 mt-6 mb-6">
                <div
                    onClick={handlePrint}
                    className="cursor-pointer flex gap-1 rounded-xl  py-[10px] px-[8px] text-[#5E5BA7] text-[16px]  w-[150px] justify-center"
                    style={{ border: '1px solid #5E5BA7' }}
                >
                    <img src={PurpleEnv} alt="reddeleteicon" width={16} height={16} /> Print proof
                </div>

                <div className="flex  gap-1 bg-[#5E5BA7] rounded-xl py-[10px] px-[8px] text-[#FFFFFF] text-[16px] w-[159px] justify-center">
                    <img src={Share} alt="usericon" width={16} height={16} /> Share proof
                </div>
            </div>
        </div>
    );
};

export default Proof;
