import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';

import PurpleSettings from '../../assets/images/svg/purpleSettings.svg';
import WhitePayment from '../../assets/images/svg/whitePayment.svg';
import PermissionsGate, { managePermissions } from '../../HOC/PermissionGate';
import Wrapper from '../../HOC/Wrapper';
import useGetRoles from '../../hooks/useGetRoles';
import { fetchAccounts } from '../../redux/features/bank-payment/bankRedux';
import { initialSinglePaymentstate } from '../../redux/features/dtos';
import { types } from '../../redux/features/payment/PaymentActionTypes';
import { useGetAPaymentQuery } from '../../redux/services';
import { RootState } from '../../redux/store';
import { SCOPES } from '../../utils/permission';
import CustomerPayment from './CustomerPayment';
import MyPayment from './MyPayment';
import AccountModal from './PaymentPopUp/AccountModal';
import PaymentModal from './PaymentPopUp/PaymentModal';

const Payment = () => {
    const { activeTab } = useSelector((state: RootState) => state.listCustomerPaymentsReducer);
    const [paymentID, setPaymentID] = useState('');
    const [skip, setSkip] = useState(true);
    const { data = initialSinglePaymentstate } = useGetAPaymentQuery(paymentID, { skip });
    const { user_role, company_type } = useGetRoles();
    const toggleTab = (tabName: 'customerPayment' | 'myPayment') => {
        dispatch({ type: types.setActiveTab, payload: tabName });
    };
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAccounts());
    }, [dispatch]);

    useEffect(() => {
        if (managePermissions(user_role, company_type, SCOPES.can_manage_company_payments, SCOPES.can_manage_customer_payments)) {
            dispatch({ type: types.setPIsCustomer, payload: false });
            dispatch({ type: types.setActiveTab, payload: 'myPayment' });
            return;
        }
    }, [user_role, company_type, dispatch]);

    return (
        <Wrapper>
            <div className="flex items-center justify-between my-6 mb-[0.7rem]">
                <h3 className="font-semibold text-[1.25rem] text-jumbleng-gray">Payments</h3>
            </div>

            <div className="flex justify-between border-b py-1 mb-[1.5rem]">
                <ul className="flex">
                    <PermissionsGate scopes={[SCOPES.can_manage_customer_payments]}>
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => (dispatch({ type: types.setPIsCustomer, payload: true }), toggleTab('customerPayment'))}
                                className={`${
                                    activeTab === 'customerPayment' && 'font-bold border-b border-b-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                            >
                                Customer Payment
                            </a>
                        </li>
                    </PermissionsGate>
                    <PermissionsGate scopes={[SCOPES.can_manage_company_payments]}>
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => (dispatch({ type: types.setPIsCustomer, payload: false }), toggleTab('myPayment'))}
                                className={`${
                                    activeTab === 'myPayment' && 'font-bold border-b border-b-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                            >
                                My Payment
                            </a>
                        </li>
                    </PermissionsGate>
                </ul>
                {activeTab === 'customerPayment' ? (
                    <div> </div>
                ) : (
                    <div>
                        <div className="flex">
                            <PermissionsGate scopes={[SCOPES.can_manage_payment_settings]}>
                                <Popup
                                    trigger={
                                        <div className="flex h-[2.5rem] cursor-pointer rounded-md ml-4 items-center border-[1px] border-[#5E5BA7] px-[1.18rem] py-[0.42125rem]">
                                            <img src={PurpleSettings} className="mr-1" alt="plus" />{' '}
                                            <span className="font-semibold text-[0.78125rem] text-[#5E5BA7]">Payment Settings</span>
                                        </div>
                                    }
                                    modal
                                    nested
                                    contentStyle={{
                                        width: '702px',
                                        maxHeight: '95%',
                                        overflow: 'auto',
                                    }}
                                >
                                    {(close: () => void) => <AccountModal close={close} />}
                                </Popup>
                            </PermissionsGate>
                            <Popup
                                trigger={
                                    <div className="flex h-[2.5rem] cursor-pointer rounded-md ml-4 items-center bg-jumbleng-primary px-[1.18rem] py-[0.42125rem] mr-2">
                                        <img src={WhitePayment} className="mr-1" alt="plus" />{' '}
                                        <span className="font-semibold text-[0.78125rem] text-white">Make Payment</span>
                                    </div>
                                }
                                modal
                                contentStyle={{
                                    width: '702px',
                                    overflowY: 'auto',
                                    maxHeight: 'calc(100vh - 80px)',
                                }}
                            >
                                {(close: () => void) => (
                                    <div>
                                        <PaymentModal close={close} invoice={data?.invoice} id={paymentID} status={data?.status} />
                                    </div>
                                )}
                            </Popup>
                        </div>
                    </div>
                )}
            </div>

            {activeTab === 'myPayment' ? <MyPayment setPaymentID={setPaymentID} paymentID={paymentID} setSkip={setSkip} /> : <CustomerPayment />}
        </Wrapper>
    );
};

export default Payment;
