import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';

import { setNewNotification } from '../redux/slices/notifications';
import { RootState } from '../redux/store';
const { REACT_APP_BASE_WS_URL } = process.env;

const useConnectSocket = () => {
    const dispatch = useDispatch();
    const { authorization } = useSelector((state: RootState) => state.authStore);
    const { newNotification, notificationId } = useSelector((state: RootState) => state.notificationReducer);
    const WSS_URL = `${REACT_APP_BASE_WS_URL}/v1/notifications/register?token=${authorization?.access_token}`;
    const { lastMessage, sendJsonMessage } = useWebSocket(WSS_URL, {
        share: true,
    });

    const handleClickSendMessage = useCallback(
        (id: string) =>
            sendJsonMessage({
                context: 'notification',
                context_action: 'markAsRead',
                context_data: { id },
            }),
        [sendJsonMessage],
    );

    useEffect(() => {
        if (lastMessage !== null) {
            const data = JSON.parse(lastMessage.data);
            dispatch(setNewNotification(data));
        }
    }, [dispatch, lastMessage, newNotification?.context_data?.id, notificationId]);

    return { lastMessage, handleClickSendMessage };
};

export default useConnectSocket;
