import { BaseQueryFn, createApi, FetchArgs } from '@reduxjs/toolkit/query/react';

import { IBuyingTrend } from '../features/interfaces';
import { baseQueryWithReauth, createRequestWithParams, CustomError } from './shared';

const createRequest = (url: any) => ({ url });
export const supplierVendorApi = createApi({
    reducerPath: 'supplierVendorApi',
    baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, any>>,
    tagTypes: ['Suppliers', 'discounts', 'sales-reps'],
    endpoints: (builder) => ({
        getASupplier: builder.query<any, string>({
            query: (id) => createRequest(`vendors/suppliers/${id}`),
            providesTags: (_result, _error, id) => [{ type: 'Suppliers', id }],
        }),
        getSupplierBuyingTrends: builder.query<IBuyingTrend, { id: string; page: number; limit: number; search_query: string; filter: string }>({
            query: ({ id, page, limit, search_query, filter }) =>
                createRequestWithParams(`vendors/suppliers/${id}/buying-trends`, { page, limit, search_query, filter }),
            providesTags: ['Suppliers'],
            transformResponse: (result: { data: IBuyingTrend }) => {
                return result.data;
            },
        }),

        getSupplierDiscounts: builder.query<any, { vendorId: string; page: number; limit: number; search_query: string }>({
            query: ({ vendorId, page, limit, search_query }) =>
                createRequestWithParams(`vendors/suppliers/${vendorId}/discounts`, { page, limit, search_query }),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ vendorId }: any) => ({ type: 'discounts' as const, vendorId })), 'discounts'] : ['discounts'],
        }),
        getSupplierDiscount: builder.query<any, string>({
            query: (vendorId) => createRequest(`vendors/suppliers/${vendorId}/discounts`),
            providesTags: (_result, _error, vendorId) => [{ type: 'discounts', vendorId }],
        }),
        applySupplierDiscount: builder.mutation({
            query: ({ id, ...rest }) => {
                return {
                    url: `vendors/suppliers/${id}/discounts`,
                    method: 'post',
                    body: rest,
                };
            },
            invalidatesTags: ['discounts', 'Suppliers'],
        }),
        updateSupplierDiscount: builder.mutation({
            query: ({ id, ...rest }) => {
                return {
                    url: `vendors/suppliers/${id}/discounts`,
                    method: 'PATCH',
                    body: rest,
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'discounts', id }],
        }),

        closeSupplierDiscount: builder.mutation({
            query: (id) => {
                return {
                    url: `vendors/suppliers/${id}/close-discount`,
                    method: 'delete',
                };
            },
            invalidatesTags: (_result, _error, id) => [{ type: 'Suppliers', id }],
        }),
        getSupplierSalesRep: builder.query<any, string>({
            query: (id) => createRequest(`vendors/suppliers/${id}/sales-reps`),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ vendorId }: any) => ({ type: 'Suppliers' as const, vendorId })), 'Suppliers'] : ['Suppliers'],
        }),

        assignSalesRepToSupplier: builder.mutation({
            query: ({ id, ...rest }) => {
                return {
                    url: `vendors/suppliers/${id}/sales-reps`,
                    method: 'post',
                    body: rest,
                };
            },
            invalidatesTags: ['Suppliers'],
        }),
        reassignSalesRepToSupplier: builder.mutation({
            query: ({ id, repId }) => {
                return {
                    url: `vendors/suppliers/${id}/sales-reps/${repId}`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'Suppliers', id }],
        }),

        unassignSalesRepFromSupplier: builder.mutation({
            query: ({ id, repId }) => {
                return {
                    url: `vendors/suppliers/${repId}/sales-reps/${id}`,
                    method: 'delete',
                };
            },
            invalidatesTags: (_result, _error, id) => [{ type: 'Suppliers', id }],
        }),
    }),
});

export const {
    useGetSupplierBuyingTrendsQuery,
    useGetASupplierQuery,
    useApplySupplierDiscountMutation,
    useAssignSalesRepToSupplierMutation,
    useReassignSalesRepToSupplierMutation,
    useUnassignSalesRepFromSupplierMutation,
    useCloseSupplierDiscountMutation,
    useGetSupplierDiscountQuery,
    useGetSupplierDiscountsQuery,
    useGetSupplierSalesRepQuery,
} = supplierVendorApi;
