import styled from 'styled-components';

export const ProductSearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 24px;
    align-items: center;
    margin: 20px 0;
`;

export const ProductItemParagraph = styled.div``;
export const ProductItemQuantity = styled.div``;
export const ProductSearchBox = styled.div`
    border-bottom: 2px solid #00000050;
    padding: 9px 15px;
    display: flex;
    gap: 15px;
`;

export const ProductSearchInput = styled.input`
    background-color: transparent;
    font-weight: 400;
    font-size: 14px;
    outline: none;
    border: none;
`;

export const ProductsGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const ProductItem = styled.div`
    margin-right: 16px;
    width: 368px;
    max-width: calc(100% - 30px);
    background: #fafafa;
    box-shadow: 0px -3px 9px rgba(0, 0, 0, 0.1);
`;

export const ProductItemImage = styled.img`
    width: 100%;
    height: 178px;
    object-fit: cover;
`;
export const ProductItemContent = styled.div`
    padding: 16px 16px 20px 16px;

    p:nth-child(1) {
        font-weight: 700;
        font-size: 14px;
        color: #131221;
    }
    p:nth-child(2) {
        font-weight: 400;
        font-size: 14px;
        color: #131221;

        span {
            color: #525171;
        }
    }
    p:nth-child(3) {
        font-weight: 900;
        font-size: 26px;
        color: #525171;
    }
`;
export const ProductContainer = styled.div``;
