import { FC, SyntheticEvent } from 'react';
import Popup from 'reactjs-popup';
interface Props {
    content: string | JSX.Element;
    className: string;
    popupcontent: boolean;
    invoicecontent?: boolean;
    onClose?: (event?: SyntheticEvent<Element, Event> | KeyboardEvent | TouchEvent | MouseEvent | undefined) => void;
}

const PopUpModal: FC<Props> = ({ content, children, className, onClose, popupcontent, invoicecontent }) => {
    return (
        <Popup
            trigger={
                <div>
                    <div className={`${className} cursor-pointer `}>{content}</div>
                </div>
            }
            className={`${popupcontent ? 'popmodal' : invoicecontent ? 'invoice-modal' : 'ordermodal'}`}
            modal
            nested
            onClose={onClose}
        >
            {children}
        </Popup>
    );
};

export default PopUpModal;
