import { useDispatch, useSelector } from 'react-redux';

import CircledRing from '../../assets/images/svg/circledRing.svg';
import Check from '../../assets/images/svg/greenCheck.svg';
import Logo from '../../assets/images/svg/jumbleLogo.svg';
import Button from '../../components/Button';
import Loading from '../../components/UI/Spinner/Loading';
import { useGetComplianceQuery } from '../../redux/services/complianceApi';
import { setComplianceScreen } from '../../redux/slices/global';
import { RootState } from '../../redux/store';

const Onboarding = () => {
    const dispatch = useDispatch();
    const authStore = useSelector((state: RootState) => state.authStore);
    const { company } = authStore;
    const { isLoading } = useGetComplianceQuery();

    if (isLoading)
        return (
            <div className="min-h-screen flex justify-center flex-col">
                <Loading />
            </div>
        );
    return (
        <>
            <div className="flex items-center justify-center pt-[100px]">
                <img src={Logo} alt="profile_image" />{' '}
            </div>
            <div className="mt-[150px] flex items-center flex-col justify-center mx-auto w-[542px]">
                <div className="w-full text-center">
                    <h2 className="font-clash-display text-[28px] font-semibold">Welcome</h2>
                    <h2 className="font-clash-display text-[28px] font-semibold">{company.name},</h2>
                </div>
                <p className="text-center text-jumbleng-gray mt-6 mb-12">
                    Thank you for signing up with Jumble, we can’t wait for you to enjoy amazing features we have to offer.{' '}
                </p>
                <div className="flex justify-between w-full mt-6 mb-12">
                    <div className="flex items-center gap-2">
                        <img src={Check} alt="profile_image" />
                        <p className="text-center text-jumbleng-gray">Create account </p>
                    </div>
                    <div className="flex items-center gap-2">
                        <img src={Check} alt="profile_image" />
                        <p className="text-center text-jumbleng-gray">Company details </p>
                    </div>
                    <div className="flex items-center gap-2">
                        <img src={CircledRing} alt="profile_image" />
                        <p className="text-center text-jumbleng-gray">KYB Verification </p>
                    </div>
                </div>
                <div className="flex items-center justify-center gap-[46px]">
                    <Button
                        type={'submit'}
                        onClick={() => dispatch(setComplianceScreen(true))}
                        message={'Continue to KYB'}
                        loading={false}
                        className={
                            'px-[1.56rem] md:py-[0.78125rem] py-[0.45rem] rounded-[0.585rem] bg-jumbleng-primary text-white border border-jumbleng-primary'
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default Onboarding;
