import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useEditMemberMutation } from '../../../../redux/services/teamsApi';
import { Modaled } from './styles';

const EditModal = ({ setEditOpen, selectedUser, roles, refetch }: { setEditOpen: any; selectedUser?: any; roles?: any; refetch?: any }) => {
    const schemaCheck = yup.object().shape({
        email: yup.string().email('Invalid email format'),
        first_name: yup.string(),
        last_name: yup.string(),
        role: yup.string(),
    });

    const initialValues = {
        id: selectedUser.id,
        email: selectedUser?.email,
        first_name: selectedUser?.first_name,
        last_name: selectedUser?.last_name,
        role: selectedUser?.role,
    };

    const [editMember, { isLoading, isSuccess, isError, error }] = useEditMemberMutation();

    const rolesArr = Object?.entries(roles?.data);

    const doSubmit = async (values: any) => {
        editMember(values);
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('team member updated successfully');
            refetch();
            setEditOpen(false);
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
        }
    }, [isSuccess, isError, error, setEditOpen, refetch]);

    return (
        <Modaled>
            <div className="darkBG" onClick={() => setEditOpen(false)} />
            <div className="centered">
                <div className="modal">
                    <div className="modalHeader">
                        <h5 className="heading">Edit member</h5>
                    </div>
                    <div className="closeBtn" onClick={() => setEditOpen(false)}>
                        <img src="https://www.svgrepo.com/show/66823/close.svg" alt="" />
                    </div>
                    <Formik
                        validationSchema={schemaCheck}
                        initialValues={initialValues}
                        enableReinitialize
                        onSubmit={(values) => {
                            doSubmit(values);
                        }}
                    >
                        {({ errors, touched, submitForm }) => (
                            <Form>
                                <div className="modalContent">
                                    <div className="form__container">
                                        <div className="form__input">
                                            <label htmlFor="">First Name</label>
                                            <Field name="first_name" type="first_name" placeholder="Input first name" />
                                            {errors.first_name && touched.first_name && <p className="errored">{errors.first_name}</p>}
                                        </div>
                                        <div className="form__input">
                                            <label htmlFor="">Last Name</label>
                                            <Field name="last_name" type="last_name" placeholder="Input last name" />
                                            {errors.last_name && touched.last_name && <p className="errored">{errors.last_name}</p>}
                                        </div>
                                        <div className="form__input">
                                            <label htmlFor="">Email Address</label>
                                            <Field name="email" type="email" placeholder="Input email address" />
                                            {errors.email && touched.email && <p className="errored">{errors.email}</p>}
                                        </div>
                                        <div className="form__input">
                                            <label htmlFor="">Role</label>
                                            <div className="select">
                                                <Field name="role" component="select">
                                                    <option value="" label="Select role">
                                                        Select role
                                                    </option>
                                                    {rolesArr.map((roleName, i) => (
                                                        <option key={i} value={roleName[0]} label={`${roleName[1]}`}>
                                                            {`${roleName[1]}`}
                                                        </option>
                                                    ))}
                                                </Field>
                                                <span className="focus"></span>
                                            </div>
                                            {errors.role && <p className="errored">{errors.role}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="actionsContainer">
                                    <button className="cancelBtn" onClick={() => setEditOpen(false)}>
                                        Cancel
                                    </button>

                                    {isLoading ? (
                                        <div className="loader__container">
                                            <div className="loader" />
                                        </div>
                                    ) : (
                                        <div className="sendBtn" onClick={submitForm}>
                                            Save Edit
                                        </div>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Modaled>
    );
};

export default EditModal;
