import { Dispatch, SetStateAction, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import NotFound from '../../assets/images/png/no-image.jpg';
import NoImage from '../../assets/images/png/noImage.png';
import Pagination from '../../components/Pagination/Pagination';
import Loading from '../../components/UI/Spinner/Loading';
import { companyType } from '../../redux/features/interfaces';
import { useApproveRequestMutation, useGetCompaniesQuery, useSendRequestMutation } from '../../redux/services/supplyChainApi';
import { FeedQuery } from '../feed/Feed';
import EmptySearch from '../vendorlist/EmptyContent/EmptySearch';

type Props = {
    query: FeedQuery;
    setQuery: Dispatch<SetStateAction<FeedQuery>>;
};

const VendorCard = ({ setQuery, query }: Props) => {
    const { data, isFetching } = useGetCompaniesQuery(query);
    const [sendRequest, { isLoading, isSuccess, isError, error }] = useSendRequestMutation();
    const [approveRequest, { isLoading: loader, isSuccess: success, isError: iserror, error: err }] = useApproveRequestMutation();

    const sendrequest = async (id: string) => {
        await sendRequest(id);
    };

    const acceptrequest = async (id: string) => {
        await approveRequest(id);
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Request sent successfully');
        } else if (success) {
            toast.success('Request accepted successfully');
        } else if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
        } else if (iserror && err && 'status' in err) {
            toast.error(err?.data?.message);
        }
    }, [isSuccess, isError, error, isLoading, loader, success, iserror, err]);

    if (isFetching) {
        return (
            <div className="min-h-[calc(100vh-20rem)] flex justify-center flex-col">
                <Loading />
            </div>
        );
    }
    return (
        <div>
            {data?.data.length === 0 && query.search_query !== '' ? (
                <EmptySearch content="company" />
            ) : (
                <>
                    <div className="flex lg:flex-row flex-col flex-wrap gap-8 mb-8 justify-between">
                        {data?.data.map((company: companyType) => (
                            <div className="lg:w-[47%] bg-white flex flex-col w-full" key={company.id}>
                                <div className="flex justify-between px-8 items-center py-6">
                                    <div className="flex">
                                        <div className="flex space-x-2 mr-3">
                                            <img
                                                src={company.logo ? company.logo : NoImage}
                                                alt="user image"
                                                className=" h-[2.9rem] w-[2.9rem] object-cover rounded-full  shadow-sm"
                                            />{' '}
                                        </div>

                                        <div>
                                            <p className="font-bold text-base text-jumbleng-gray">
                                                {company.name} {company.private && <i className="fa fa-lock ml-2"></i>}
                                            </p>
                                            <p className="font-normal text-sm">{company.industry_type}</p>
                                        </div>
                                    </div>

                                    <Link to={`/vendorcatalogue/${company.id}/catalogues`}>
                                        <span className="text-base font-bold text-jumbleng-gray cursor-pointer">View catalogue</span>
                                    </Link>
                                </div>
                                <div className="h-[25.81rem] flex justify-center items-center ">
                                    <img
                                        src={company.catalogue[0]?.product.picture ? company.catalogue[0]?.product.picture : NotFound}
                                        alt="_"
                                        className="w-[auto] h-[25rem] object-contain "
                                    />
                                </div>

                                <div className="p-8 flex justify-between">
                                    <div className="max-w-[364px]">
                                        <h3 className="font-semibold text-[21px] text-jumbleng-gray-main mb-2">
                                            {company.catalogue[0]?.product.name}
                                        </h3>
                                        <p className="font-normal text-base text-jumbleng-gray mb-6">{company.catalogue[0]?.product.description} </p>
                                    </div>
                                    {company.connection_type === '' ? (
                                        <button className="flex" onClick={() => sendrequest(company.id)}>
                                            <div className="flex items-center px-4  py-2 hover:text-white hover:bg-jumbleng-primary text-jumbleng-primary border border-jumbleng-primary rounded-[12px]">
                                                <i className="fa-solid fa-paper-plane mr-1"></i>
                                                <span>Send request</span>
                                            </div>
                                        </button>
                                    ) : company.connection_type === 'supplier' && company.connection_status !== 'accepted' ? (
                                        <button className="flex" onClick={() => acceptrequest(company.connection_id)}>
                                            <div className="flex items-center px-4  py-2 hover:text-white hover:bg-jumbleng-primary text-jumbleng-primary border border-jumbleng-primary rounded-[12px]">
                                                <i className="fa-solid fa-check mr-1"></i>
                                                <span>Accept request</span>
                                            </div>
                                        </button>
                                    ) : company.connection_status === 'pending' ? (
                                        <div className="flex px-4  py-2 text-[#5E5BA7]">
                                            <span>
                                                <i className="fa fa-check mr-2"></i>Request sent
                                            </span>
                                        </div>
                                    ) : company.connection_status === 'accepted' ? (
                                        <div className="flex px-4  py-2 text-[#5E5BA7]">
                                            <span>
                                                <i className="fa fa-bolt mr-2"></i>Connected
                                            </span>
                                        </div>
                                    ) : company.connection_status === 'declined' ? (
                                        <button className="flex cursor-auto">
                                            <div className="flex items-center  px-4 py-2 rounded-xl border border-jumbleng-red text-jumbleng-red">
                                                <i className="fa fa-xmark mr-1"></i> <span> Declined</span>
                                            </div>
                                        </button>
                                    ) : (
                                        <button className="flex" onClick={() => sendrequest(company.id)}>
                                            <div className="flex items-center px-4  py-2 hover:text-white hover:bg-jumbleng-primary text-jumbleng-primary border border-jumbleng-primary rounded-[12px]">
                                                <i className="fa-solid fa-paper-plane mr-1"></i>
                                                <span>Send request</span>
                                            </div>
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <Pagination query={query} setQuery={setQuery} data={data?.data} count={data?.count} />
                </>
            )}
        </div>
    );
};

export default VendorCard;
