import { ErrorMessage, Field, Formik } from 'formik';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PercentIcon from '../../../assets/images/svg/percent.svg';
import X from '../../../assets/images/svg/x.svg';
import { actions, Iaction, productFieldType, selectedPublishProductFieldType } from '../../../redux/features/product/productRedux';
import { createProduct, getNextSKU, updateProduct } from '../../../redux/features/product/productService';
import { addProductExtensionSchema, editProductExtensionSchema, initialAddProductValues, initialEditProductValues } from '../../../schema';
import Button from '../../Button';
import Spinner from '../../UI/Spinner/Spinner';

interface Form2Props {
    product_field: productFieldType;
    setCreateProductField: (payload: any) => Iaction;
    setEditProductField: (payload: productFieldType) => Iaction;
    setAddProduct?: () => void;
    setEditProduct?: () => void;
    setProductForm: Dispatch<SetStateAction<string>>;
    updateCatalogueList: (payload: productFieldType) => Iaction;
    fetchProducts?: () => Promise<void>;
    setModal: Dispatch<SetStateAction<boolean>>;
    selectedPublishProductField?: selectedPublishProductFieldType;
    selectedPublishProductId: string;
    isEdit?: boolean;
    nextSKU: any;
}
const Form2 = (props: Form2Props) => {
    const {
        setProductForm,
        setAddProduct,
        setEditProduct,
        nextSKU,
        setModal,
        setEditProductField,
        selectedPublishProductId,
        fetchProducts,
        selectedPublishProductField,
        setCreateProductField,
        isEdit,
        product_field,
    } = props;
    const [picturename, setPicturename] = useState('Upload Image');
    const [certificatename, setCertificatename] = useState('Upload Image');
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
    const valuesRef = useRef<any>(null);
    const dispatch = useDispatch();

    const addProductToDB = async (values: productFieldType) => {
        setLoadingBtn(true);
        try {
            if (isEdit) {
                await updateProduct(selectedPublishProductId, { ...values, vat: Number(values.vat), discount: Number(values.discount) });
            } else {
                const success = await createProduct({ ...values, vat: Number(values.vat), discount: Number(values.discount) });
                if (success) {
                    const response = await getNextSKU();
                    const res = { sku: response.data };
                    dispatch({ type: 'SET_SKU', payload: res });
                }
            }
            setModal(false);
            setCreateProductField({ initialAddProductValues, ...nextSKU });
            toast.success(`Product ${isEdit ? 'updated' : 'added'} Successfully`);
            fetchProducts && fetchProducts();
            setAddProduct && setAddProduct();
            setEditProduct && setEditProduct();
            setProductForm('FORM1');
        } catch (error: any) {
            setLoadingBtn(false);
            const message = error.response && error.response.data?.message ? error.response.data?.message : error.message;
            toast.error(message);
        }
    };
    const handleClose = () => {
        setCreateProductField({ ...initialAddProductValues, ...nextSKU });
        setAddProduct && setAddProduct();
        setEditProduct && setEditProduct();
        setProductForm('FORM1');
        setModal(false);
    };
    const handleSaveSection = () => {
        setProductForm('FORM1');
        !isEdit
            ? setCreateProductField({ ...initialAddProductValues, ...valuesRef.current.values })
            : setEditProductField({ ...initialEditProductValues, ...valuesRef.current.values });
    };

    return (
        <Formik
            innerRef={valuesRef}
            onSubmit={addProductToDB}
            initialValues={
                !isEdit ? { ...initialAddProductValues, ...product_field } : { ...initialEditProductValues, ...selectedPublishProductField }
            }
            validationSchema={!isEdit ? addProductExtensionSchema : editProductExtensionSchema}
        >
            {({ handleSubmit, setFieldValue, errors, values }) => (
                <form className="bg-white w-[90%] md:w-[29rem] px-7  h-[95%] my-0 overflow-auto rounded-[0.585rem] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[56] md:px-[3.33rem]">
                    <div className="flex justify-between mt-[10px]">
                        <div />
                        <img src={X} alt="cancel" className="fixed right-5 hover:cursor-pointer" onClick={handleClose} />
                    </div>
                    <p className="text-center mt-4 font-black text-[1.37rem]">{isEdit ? 'Edit Product' : 'Add Product'}</p>
                    <div>
                        <div className="dStepBox ">
                            <div className="dStepPurple" /> <div className="dStepPurple" />
                        </div>
                        <p className="dStep">Step 2 of 2</p>
                    </div>
                    <p className="font-semibold text-base">Pricing details</p>

                    <div className="flex flex-col">
                        <label className="font-normal text-[0.78125rem] mt-2 text-jumbleng-gray">Unit Price</label>
                        <Field
                            name={'unit_price'}
                            placeholder={'00.00'}
                            className="p-[0.585rem] text-jumbleng-gray focus:outline-none border-b-2 border-b-jumbleng-gray"
                        />
                        {errors.unit_price ? <div className="text-[0.7812rem] text-red-600 text-left font-normal">{errors.unit_price}</div> : null}
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-2">
                        <div className="flex flex-col relative">
                            <label className="font-normal text-[0.78125rem] text-jumbleng-gray">VAT (Optional)</label>
                            <Field
                                value={values.vat ? values.vat : ''}
                                name={'vat'}
                                placeholder={'0'}
                                className="p-[0.585rem] text-jumbleng-gray focus:outline-none border-b-2 border-b-jumbleng-gray"
                            />
                            <img src={PercentIcon} alt="%" className="w-[1.17rem] h-[1.17rem] absolute right-6 top-[50%]" />
                            {errors.vat ? <div className="text-[0.7812rem] text-red-600 text-left font-normal">{errors.vat}</div> : null}
                        </div>
                        <div className="flex flex-col relative">
                            <label className="font-normal text-[0.78125rem] text-jumbleng-gray">Discount (Optional)</label>
                            <Field
                                value={values.discount ? values.discount : ''}
                                name={'discount'}
                                placeholder={'0'}
                                className="p-[0.585rem] text-jumbleng-gray focus:outline-none border-b-2 border-b-jumbleng-gray"
                            />
                            <img src={PercentIcon} alt="%" className="w-[1.17rem] h-[1.17rem] absolute right-4 top-[50%]" />
                            {errors.discount ? <div className="text-[0.7812rem] text-red-600 text-left font-normal">{errors.discount}</div> : null}
                        </div>
                    </div>

                    <p className="font-semibold text-base mt-4">Uploads</p>
                    <div>
                        <p className="font-normal text-[0.78125rem] text-jumbleng-gray my-3">Product image</p>
                        <div>
                            <label htmlFor="imageUpload">
                                <div className="flex flex-col  gap-1 p-2 items-center justify-center border-dashed border-jumbleng-primary border-[1px] h-[5rem]">
                                    <p className="text-[10px] text-jumbleng-gray">Image should be in jpg or png format</p>
                                    <p className="text-xs text-jumbleng-primary">
                                        <i className="fa-solid fa-image mr-1"></i>
                                        {picturename}
                                    </p>
                                </div>
                            </label>
                            <input
                                id="imageUpload"
                                type="file"
                                className="w-0.1 h-0.1 overflow-hidden hidden"
                                onChange={(event) => {
                                    if (!event.target.files) return;
                                    setFieldValue('picture', event.target.files[0]);
                                    setPicturename(event.target.files[0]?.name);
                                }}
                            />
                            <ErrorMessage
                                name={'picture'}
                                render={(msg) => (
                                    <div className="text-[0.7812rem] text-red-400 text-left font-normal fon text-jumbleng-gray-main">{msg}</div>
                                )}
                            />
                        </div>
                    </div>
                    <div>
                        <p className="font-normal text-[0.78125rem] text-jumbleng-gray my-3">Product Certificate</p>
                        <div>
                            <label htmlFor="certUpload">
                                <div className="flex flex-col  gap-1 p-2 items-center justify-center border-dashed border-jumbleng-primary border-[1px] h-[5rem]">
                                    <p className="text-[10px] text-jumbleng-gray">Image should be in jpg or png format</p>
                                    <p className="text-xs text-jumbleng-primary">
                                        <i className="fa-solid fa-image mr-1"></i>
                                        {certificatename}
                                    </p>
                                </div>
                            </label>
                            <input
                                id="certUpload"
                                type="file"
                                className="w-0.1 h-0.1 overflow-hidden hidden"
                                onChange={(event) => {
                                    if (!event.target.files) return;
                                    setFieldValue('certificate', event.target.files[0]);
                                    setCertificatename(event.target.files[0]?.name);
                                }}
                            />
                            <ErrorMessage
                                name={'certificate'}
                                render={(msg) => (
                                    <div className="text-[0.7812rem] text-red-400 text-left font-normal fon text-jumbleng-gray-main">{msg}</div>
                                )}
                            />
                        </div>
                    </div>

                    <div className="flex justify-between mb-[2.34rem] mt-[2.34rem]">
                        <button
                            type="button"
                            onClick={handleSaveSection}
                            className="px-[1.56rem] text-jumbleng-primary md:py-[0.78125rem]  py-[0.45rem]  rounded-[0.585rem] border border-jumbleng-primary"
                        >
                            Back
                        </button>
                        {loadingBtn ? (
                            <Spinner />
                        ) : (
                            <Button
                                type={'submit'}
                                onClick={handleSubmit}
                                message={`${isEdit ? 'Update Product' : 'Create Product'}`}
                                loading={false}
                                className={
                                    'md:px-[1.56rem] px-4  md:py-[0.78125rem]  py-[0.45rem]  rounded-[0.585rem] bg-jumbleng-primary text-white border border-jumbleng-primary'
                                }
                            />
                        )}
                    </div>
                </form>
            )}
        </Formik>
    );
};

export default connect((state: any) => {
    const { product_field, selectedPublishProductId, selectedPublishProductField, nextSKU } = state.ProductReducer;
    return { product_field, selectedPublishProductId, selectedPublishProductField, nextSKU };
}, actions)(Form2);
