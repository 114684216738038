import { reduxBatch } from '@manaflair/redux-batch';
import { Action, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { rootReducer, rootSaga } from '../root-reducer';
import { dashboardApi, groupApi } from '../services';
import { storeApi } from '../services';
import { customerVendorApi, notificationApi, subscriptionApi } from '../services';
import { complianceApi } from '../services/complianceApi';
import { invoiceApi } from '../services/invoiceApi';
import { orderApi } from '../services/orderApi';
import { paymentApi } from '../services/paymentApi';
import { requestApi } from '../services/requestApi';
import { supplierVendorApi } from '../services/supplierVendorApi';
import { teamsApi } from '../services/teamsApi';

//production setup
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
            thunk: true,
        }),
        sagaMiddleware,
        groupApi.middleware,
        requestApi.middleware,
        teamsApi.middleware,
        complianceApi.middleware,
        invoiceApi.middleware,
        paymentApi.middleware,
        orderApi.middleware,
        dashboardApi.middleware,
        storeApi.middleware,
        subscriptionApi.middleware,
        customerVendorApi.middleware,
        supplierVendorApi.middleware,
        notificationApi.middleware,
    ],
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [reduxBatch],
});

export const persistor = persistStore(store);

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type RootState = ReturnType<typeof store.getState>;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();

//fire Saga
sagaMiddleware.run(rootSaga);
