import { companyCatalogueListType, companyListType, companyType } from '../features/interfaces';
import { requestApi } from './requestApi';
import { createRequest, createRequestWithParams } from './shared';

const supplyChainApi = requestApi.injectEndpoints({
    endpoints: (builder) => ({
        getCompanies: builder.query<
            companyListType,
            { page: number; limit: number; connected: boolean; addedToGroup: boolean; search_query: string }
        >({
            query: ({ page, limit, connected, addedToGroup, search_query }) =>
                createRequestWithParams(`company`, { page, limit, connected, addedToGroup, search_query }),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result.data.map(({ id }: any) => ({ type: 'company' as const, id })), 'company'] : ['company'],
        }),

        getCompanyCatalogue: builder.query<companyCatalogueListType, { id: string; page: number; limit: number; search_query: string }>({
            query: ({ id, page, limit, search_query }) => createRequestWithParams(`company/${id}/catalogue`, { page, limit, search_query }),
            providesTags: (result, _error, _arg) =>
                result?.data && result?.data.isPrivate === false
                    ? [...result?.data.catalogue.map(({ id }: any) => ({ type: 'catalogue' as const, id })), 'catalogue']
                    : ['catalogue'],
        }),

        getACompany: builder.query<companyType, string>({
            query: (id) => createRequest(`company/${id}`),
            providesTags: (_result, _error, id) => [{ type: 'company', id }],
            transformResponse: (results: { data: any }) => results.data,
        }),
    }),
    overrideExisting: false,
});

export const {
    useSendRequestMutation,
    useGetSentRequestsQuery,
    useGetReceivedRequestsQuery,
    useApproveRequestMutation,
    useGetApprovedRequestsQuery,
    useGetPendingRequestsQuery,
    useGetRejectedRequestsQuery,
    useDeclineRequestMutation,
    useGetCompaniesQuery,
    useGetCompanyCatalogueQuery,
    useGetACompanyQuery,
} = supplyChainApi;
