import { isEmpty } from 'lodash';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import AddAccountPaymentSettings from '../../../components/AccountModal/AddAccountSettings';
import EmptyPaymentSettings from '../../../components/AccountModal/EmptyAccountSettings';
import FilledPaymentSettings from '../../../components/AccountModal/FilledAccountSettings';
import Cancel from '../../../components/CancelButton/Cancel';
import { RootState } from '../../../redux/store';

interface IProps {
    close: () => void;
}

const AccountModal: FC<IProps> = ({ close }) => {
    const { accounts } = useSelector((state: RootState) => state.bankStore);
    const [content, setContent] = useState<'Empty' | 'Filled' | 'Form'>('Empty');

    return (
        <div className="p-8  pt-6">
            <Cancel close={close} />
            <div className="grid justify-center text-[#131221] font-black text-[28px] mb-4"> Payment Settings</div>
            <div className="grid justify-center">
                <p className="grid justify-center  w-[516px] text-[#525171] mb-4">
                    {' '}
                    Adding your account details allows easy payment for buyers. If you have more than one account details, you can add them and easily
                    switch between them
                </p>
            </div>
            {content == 'Form' ? (
                <AddAccountPaymentSettings close={close} setContent={setContent} />
            ) : isEmpty(accounts) && content === 'Empty' ? (
                <EmptyPaymentSettings close={close} setContent={setContent} />
            ) : accounts || content === 'Filled' ? (
                <FilledPaymentSettings accounts={accounts} setContent={setContent} />
            ) : null}
        </div>
    );
};

export default AccountModal;
