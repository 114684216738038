import styled from 'styled-components';

export const Tabled = styled.div`
    margin-top: 24px;
    margin-right: 24px;

    .table__cta {
        display: flex;
        justify-content: space-between;
        /* padding: 21px 33px; */
        height: 58px;
        font-size: 12px;
        border: 1px solid #00000010;

        .table__cta__left {
            font-size: 12px;
            font-weight: bold;
            color: #808080;
            padding: 21px 33px;
            background-color: #f2f3f4;
            flex: 1 1 auto;

            .search__input {
                display: flex;
                gap: 20px;
                align-items: center;
                height: 100%;
                flex: 1 0 auto;

                img {
                    width: 10.6px;
                    height: 10.6px;
                }

                input {
                    border: none;
                    outline: none;
                    background: transparent;

                    &::placeholder {
                        font-size: 11px;
                        color: #2f343150;
                    }
                }
            }
        }

        &__left2 {
            font-size: 12px;
            font-weight: bold;
            color: #808080;
            padding: 21px 33px;
            /* background-color: red; */

            .search__input {
                display: flex;
                gap: 20px;
                align-items: center;
                height: 100%;
                flex: 1 0 auto;

                img {
                    width: 10.6px;
                    height: 10.6px;
                }

                input {
                    border: none;
                    outline: none;
                    background: transparent;

                    &::placeholder {
                        font-size: 11px;
                        color: #2f343150;
                    }
                }
            }
        }

        &__right {
            display: flex;
            align-items: center;

            .select__container {
                padding: 0px 23px;

                .select {
                    border: 1px solid #00000020;
                    width: 170px;
                    border-radius: 5px;
                    padding: 9px 10px;
                }
            }

            .search__cta {
                height: 100%;
                border-left: 1px solid #00000020;
                border-right: 1px solid #00000020;
                flex: 0 1 auto;
                cursor: pointer;

                img {
                    padding: 21px;
                    width: 12px;
                }
            }

            .search__cta2 {
                border-left: 1px solid #00000020;
                border-right: 1px solid #00000020;
                flex: 0 1 auto;
                padding: 21px;
                font-weight: bold;
                color: #808080;
                cursor: pointer;
            }

            .export__cta {
                padding: 23px 29px 23px 29px;
                font-weight: bold;
                color: #808080;
            }
        }
    }

    table {
        width: 100%;
        border: 1px solid #00000010;

        thead {
            background-color: #fff;
            padding: 12px;
            height: 40px;
            border: 2px solid #fff;

            tr {
                font-size: 14px;

                th {
                    font-weight: bold;
                }
            }
        }

        tbody {
            tr {
                /* border: 3px solid red; */
                height: 67px;
                text-align: center;
                font-size: 13px;

                td {
                    border-top: 1px solid #fff;
                }
            }
        }
    }

    .name__container {
        display: flex;
        justify-content: center;
        gap: 8px;
        width: fit-content;
        margin: 0 auto;

        img {
            border-radius: 50%;
        }

       
    }

    .status_active {
        background-color: #deeae2;
        border-radius: 16px;
        padding: 5px 20px;
        width: fit-content;
        margin: 0 auto;
        color: #084f21;
        display: flex;
        align-items: center;
        gap: 10px;
        text-transform: capitalize;

        .dot_around {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            border: 1px solid #327a4b;
            object-fit: contain;

            .dot {
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background-color: #327a4b;
                margin: 1px auto;
            }
        }
    }

    .status_disabled {
        background-color: #ff000050;
        border-radius: 16px;
        padding: 5px 20px;
        width: fit-content;
        margin: 0 auto;
        color: #ff0000;
        display: flex;
        align-items: center;
        gap: 10px;
        text-transform: capitalize;

        .dot_around {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            border: 1px solid #ff0000;
            object-fit: contain;

            .dot {
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background-color: #ff0000;
                margin: 1px auto;
            }
        }
    }

    .status_pend {
        background-color: #ffc00050;
        border-radius: 16px;
        padding: 5px 20px;
        width: fit-content;
        margin: 0 auto;
        color: #ffc000;
        display: flex;
        align-items: center;
        gap: 10px;
        text-transform: capitalize;

        .dot_around {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            border: 1px solid #ffc000;
            object-fit: contain;

            .dot {
                height: 8px;
                width: 8px;
                border-radius: 50%;
                background-color: #ffc000;
                margin: 1px auto;
            }
        }
    }

    .cta__dots {
        font-size: 28px;
        color: #706f77;
        cursor: pointer;
    }

    .even__row {
        border: 1px solid #fff !important;
        background-color: #fff;
        position: relative;
    }

    .odd__row {
        background-color: #f9f9fa;
        border: 1px solid #fff !important;
        position: relative;
    }

    .cta__menu {
        position: absolute;
        z-index: 5;
        background-color: #fff;
        padding: 30px 35px;
        right: 2px;
        border-radius: 12px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

        p {
            margin-bottom: 25px;
            cursor: pointer;

            &:hover {
                color: #5e5ba7;
                font-weight: bold;
            }
        }

        p:nth-child(5) {
            margin-bottom: 0px;
            cursor: pointer;
        }
    }
`;
