import './style.css';

import { Dispatch, FC, SetStateAction, useState } from 'react';

import Edit from '../../assets/images/svg/edit.svg';
import Trash from '../../assets/images/svg/trash-2.svg';
import X from '../../assets/images/svg/x.svg';
import { categoriesType } from '../../pages/product/Product';
import { toLocaleFixed } from '../../utils/utils';
import PublishProductModal from '../PubishProductModal/PublishProductModal';
import Modal from '../UI/Backdrop/Backdrop';

interface ProductDetailsProps {
    clickedProduct: any;
    categories?: categoriesType[];
    toggleViewProductDetails: (product: Record<string, unknown>, edit?: boolean) => void;
    toggleAddproductModal: () => void;
    toggleEditProductModal: () => void;
    setModal?: Dispatch<SetStateAction<boolean>>;
    viewProductDetails: boolean;
}

const ProductDetails: FC<ProductDetailsProps> = (props) => {
    const { viewProductDetails, setModal, clickedProduct, categories, toggleViewProductDetails, toggleEditProductModal } = props;
    const [showPublishProductModal, setShowPublishProductModal] = useState(false);

    const toggleShowPublishProductModal = (value: boolean) => {
        setShowPublishProductModal(!value);
    };

    const hideProductDetailsAndShowEditProduct = () => {
        toggleViewProductDetails(clickedProduct, true);
        toggleEditProductModal();
        setModal && setModal(true);
    };

    const handleClose = () => {
        toggleViewProductDetails(clickedProduct, false);
        setModal && setModal(false);
    };

    if (!viewProductDetails) return <></>;
    return (
        <div
            className="bg-white w-[72.31rem] h-[95%] overflow-auto py-2 rounded-[0.585rem] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-8 modal__container"
            style={{ zIndex: 56 }}
        >
            {<Modal showModal={showPublishProductModal} />}
            <PublishProductModal showPublishProductModal={showPublishProductModal} setShowPublishProductModal={setShowPublishProductModal} />
            <div className="flex justify-between py-2">
                <div></div>
                <img onClick={handleClose} src={X} alt="_cancel" className="cursor-pointer" />
            </div>
            <div className="w-full gap-12 grid grid-cols-2">
                <div className="">
                    <div className="w-[477px] h-[477px]">
                        <img src={clickedProduct.picture} alt={clickedProduct.name} className="w-full h-full rounded-xl object-cover" />
                    </div>
                </div>
                <div className="">
                    <div className="w-full flex justify-between items-center">
                        <div className="font-normal text-sm text-[#084F21] bg-[#DEEAE2] px-5 rounded-2xl py-2">
                            {!clickedProduct.published === true ? 'Published' : 'Unpublished'}
                        </div>
                        <div className="flex items-center">
                            <div className="flex items-center cursor-pointer" onClick={hideProductDetailsAndShowEditProduct}>
                                <img className="w-4 h-4" src={Edit} alt="Edit Icon" />
                                <p className="text-[#5E5BA7] font-semibold ml-2">Edit</p>
                            </div>
                            <div className="flex items-center cursor-pointer ml-6">
                                <img className="w-4 h-4" src={Trash} alt="Edit Icon" />
                                <p className="text-[#840B0B] font-semibold ml-2">Delete</p>
                            </div>
                        </div>
                    </div>

                    <p className="font-semibold text-[28px] text-[#131221]">{clickedProduct.name}</p>

                    <p className="font-bold text-[51px] text-jumbleng-primary mb-2">₦ {toLocaleFixed(clickedProduct.unit_price, 2)}</p>

                    <p className="font-bold text-base mb-2">Description</p>

                    <p className="font-normal text-base text-jumbleng-gray">{clickedProduct.description}</p>

                    <p className="font-bold text-base my-2">Product details</p>

                    <div className="grid w-full grid-cols-2 font-normal text-base text-[#131221]">
                        <div className="col-span-1">
                            <p className="mb-2">Barcode number</p>
                            <p className="mb-2">SKU</p>
                            <p className="mb-2">Product category</p>
                            <p className="mb-2">Product weight</p>
                            <p className="mb-2">Quantity available</p>
                            <p className="mb-2">Minimum order</p>
                            <p className="mb-2">Maximum order</p>
                        </div>
                        <div className="col-span-1 grid grid-rows-6">
                            <p className="mb-2">{clickedProduct.bar_code}</p>
                            <p className="mb-2">{clickedProduct.sku}</p>
                            <p className="mb-2">
                                {categories?.filter((item: any) => item.id === clickedProduct.product_category).map((item: any) => item.name)}
                            </p>
                            <p className="mb-2">{clickedProduct.uom}</p>
                            <p className="mb-2">{clickedProduct.quantity?.toLocaleString()}</p>
                            <p className="mb-2">{clickedProduct.quantity?.min_order}</p>
                            <p className="mb-2">{clickedProduct.quantity?.max_order}</p>
                        </div>
                    </div>

                    {/* <p className="font-bold text-base mb-2">Pricing details</p> */}

                    {/* Wrong product data */}
                    {/* <div className="grid w-full grid-cols-2 font-normal text-base text-[#131221]">
                        <div>
                            <p>VAT</p>
                            <p>Discount</p>
                        </div>
                        <div>
                            <p>7.5%</p>
                            <p>Nil</p>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="flex justify-between my-3">
                <div />
                {clickedProduct.published === true ? (
                    <div className="px-8 cursor-pointer py-4 inline-block text-white bg-jumbleng-primary rounded-xl text-base font-semibold">
                        <span className="pr-3">x</span> Unpublish from marketplace
                    </div>
                ) : (
                    <div
                        className="px-8 cursor-pointer py-4 inline-block text-white bg-jumbleng-primary rounded-xl text-base font-semibold"
                        onClick={() => {
                            toggleShowPublishProductModal(showPublishProductModal);
                        }}
                    >
                        <span className="pr-3">x</span> Publish to marketplace
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductDetails;
