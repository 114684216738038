import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const { REACT_APP_BACKEND_API } = process.env;

interface IinitialState {
    notificationTab: 'all' | 'order' | 'payment' | 'invoice' | 'feed' | 'reminder';
    notificationStatusTab: 'all' | 'read' | 'unread';
    notifications: Array<INotificationResponse>;
    newNotification: { context_data: INotificationResponse };
    notificationId: string;
    loading: boolean;
    unreadOrder: number;
    unreadPayment: number;
    unreadInvoice: number;
    unreadCount: number;
    query: {
        page: number;
        limit: number;
    };
}

const initialState: IinitialState = {
    notificationTab: 'all',
    notificationStatusTab: 'all',
    notifications: [],
    newNotification: {
        context_data: { id: '', created_at: '', updated_at: '', company_id: '', title: '', context: '', context_id: '', read: false, image: '' },
    },
    notificationId: '',
    loading: false,
    unreadOrder: 0,
    unreadPayment: 0,
    unreadInvoice: 0,
    unreadCount: 0,
    query: {
        page: 1,
        limit: 10,
    },
};

export type INotificationResponse = {
    id: string;
    created_at: string;
    updated_at: string;
    company_id: string;
    title: string;
    context: string;
    context_id: string;
    read: boolean;
    image: string;
};

export const retrieveNotification = createAsyncThunk<{ response: INotificationResponse }, { page: number; limit: number }, { state: any }>(
    'notifications',
    async ({ page, limit }, thunkApi) => {
        try {
            const { notificationTab: context, notificationStatusTab: status } = thunkApi.getState()?.notificationReducer;
            const res = await axios.get(
                `${REACT_APP_BACKEND_API}/v1/notifications?page=${page}&limit=${limit}&context=${
                    context === 'all' ? '' : context.toUpperCase()
                }&read=${status === 'all' ? '' : status === 'read' ? true : false}`,
            );
            return res.data.data;
        } catch (error) {
            let errorMessage = 'Internal Server Error';
            if (error.response) {
                errorMessage = error.response.data?.message;
            }
            return thunkApi.rejectWithValue(errorMessage);
        }
    },
);

const notificationsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        toggleNotificationTab: (state, action) => {
            state.notificationTab = action.payload;
        },
        toggleNotificationStatusTab: (state, action) => {
            state.notificationStatusTab = action.payload;
        },
        setNewNotification: (state, action) => {
            state.newNotification = action.payload;
        },
        setQuery: (state, action) => {
            state.query = action.payload;
        },
        setUnreadCount: (state, action) => {
            state.unreadCount = action.payload;
        },
        incrementCount: (state) => {
            state.unreadCount = state.unreadCount + 1;
        },
        setNotificationId: (state, action) => {
            state.notificationId = action.payload;
        },
        incrementUnReadOrder: (state) => {
            state.unreadOrder = state.unreadOrder + 1;
        },
        setUnreadOrder: (state, action) => {
            state.unreadOrder = action.payload;
        },
        setUnreadInvoice: (state, action) => {
            state.unreadInvoice = action.payload;
        },
        setUnreadPayment: (state, action) => {
            state.unreadPayment = action.payload;
        },
        incrementUnreadPayment: (state) => {
            state.unreadPayment = state.unreadPayment + 1;
        },
        incrementUnreadInvoice: (state) => {
            state.unreadInvoice = state.unreadInvoice + 1;
        },
    },
    extraReducers: {
        [retrieveNotification.pending.type]: (state, _) => {
            state.loading = true;
        },
        [retrieveNotification.fulfilled.toString()]: (state, action) => {
            state.notifications = action.payload;
            state.loading = false;
        },
        [retrieveNotification.rejected.toString()]: (state) => {
            state.notifications = [];
            state.loading = false;
        },
    },
});

export const {
    toggleNotificationTab,
    toggleNotificationStatusTab,
    setNotificationId,
    setNewNotification,
    setQuery,
    incrementCount,
    setUnreadCount,
    incrementUnReadOrder,
    incrementUnreadInvoice,
    incrementUnreadPayment,
    setUnreadOrder,
    setUnreadInvoice,
    setUnreadPayment,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
