import { createSlice } from '@reduxjs/toolkit';
import storageSession from 'redux-persist/lib/storage/session';

interface IActiveTab {
    activeTab: 'profile' | 'security' | 'team' | 'subscriptions' | 'notifications' | 'catalogue' | 'compliance';
    vendorListTab: 'customers' | 'suppliers' | 'groups';
    feedTab: 'allVendor' | 'connectedVendor';
    requestTab: 'vendor' | 'sent';
    requestStatusTab: 'pending' | 'accepted' | 'declined';
    customerVendorTab: 'currentInventory' | 'buyingTrend' | 'Discount';
    supplierVendorTab: 'currentInventory' | 'buyingTrend' | 'Discount';
}

const initialState: IActiveTab = {
    activeTab: 'profile',
    vendorListTab: 'customers',
    feedTab: 'allVendor',
    requestTab: 'vendor',
    requestStatusTab: 'accepted',
    customerVendorTab: 'Discount',
    supplierVendorTab: 'Discount',
};

export const persistConfig = {
    storage: storageSession,
    key: 'root',
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        toggleActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
        toggleVendorListTab: (state, action) => {
            state.vendorListTab = action.payload;
        },
        toggleFeedTab: (state, action) => {
            state.feedTab = action.payload;
        },
        toggleRequestTab: (state, action) => {
            state.requestTab = action.payload;
        },
        toggleRequestStatusTab: (state, action) => {
            state.requestStatusTab = action.payload;
        },
        toggleCustomerVendorTab: (state, action) => {
            state.customerVendorTab = action.payload;
        },
        toggleSupplierVendorTab: (state, action) => {
            state.supplierVendorTab = action.payload;
        },
    },
});

export const {
    toggleSupplierVendorTab,
    toggleCustomerVendorTab,
    toggleActiveTab,
    toggleVendorListTab,
    toggleFeedTab,
    toggleRequestTab,
    toggleRequestStatusTab,
} = settingsSlice.actions;

export default settingsSlice.reducer;
