import { ErrorMessage, Field, Formik } from 'formik';
import _ from 'lodash';
import React, { useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from '../../../../components/Button';
import SignupSteps from '../../../../components/SignupSteps/SignupSteps';
import GuestRoute from '../../../../HOC/GuestRoute';
import { IPersonalDetails } from '../../../../interfaces';
import { actions } from '../../../../redux/features/auth/authRedux';
import { EregistrationSteps, Iaction, registrationDataType } from '../../../../redux/features/interfaces';
import { initialPersonalDetailsValues, PersonalDetailsSchema } from '../../../../schema';
import { phones } from '../../../../utils/phone_number';

interface IPersonalDetailsProps {
    setRegistrationData: (data: IPersonalDetails) => Iaction;
    setRegistrationStep: (data: EregistrationSteps) => Iaction;
    registration_data: registrationDataType;
}

const PersonalDetails: React.FC<IPersonalDetailsProps> = ({ setRegistrationData, setRegistrationStep, registration_data }) => {
    const valuesRef = useRef<any>(null);

    const submitHandler = (values: IPersonalDetails) => {
        setRegistrationData({ ...values, phone_number: valuesRef.current?.values?.code + values.phone_number });
        setRegistrationStep(EregistrationSteps.CompanyDetails);
    };

    const phone_numbers = useMemo(() => {
        return _.sortBy(phones.map((item: any) => item.code));
    }, []);

    return (
        <GuestRoute>
            <div className="w-full min-h-screen flex">
                <SignupSteps step={1} />

                <div className="w-[62%]">
                    <div className="flex justify-between px-[2.5rem] mb-[2.125rem]">
                        <div />
                        <p className="text-[0.78125rem] text-jumbleng-gray mt-[2rem]">
                            Already have an account?{' '}
                            <span>
                                <Link to="/">Log-in</Link>
                            </span>
                        </p>
                    </div>
                    <div className="w-[23.5rem] mx-auto">
                        <h2 className="font-semibold text-[1.85rem] mb-[0.78125rem]">Create account</h2>

                        <p className="text-[0.78125rem] font-normal text-jumbleng-gray mb-[2.343rem]">Please fill in the details below correctly</p>

                        <Formik
                            onSubmit={submitHandler}
                            innerRef={valuesRef}
                            enableReinitialize
                            initialValues={{
                                ...initialPersonalDetailsValues,
                                ...registration_data,
                                code: registration_data.phone_number.substring(0, registration_data.phone_number.length - 10),
                                phone_number: registration_data.phone_number.slice(-10).replace(/\s+/g, ''),
                            }}
                            validationSchema={PersonalDetailsSchema}
                        >
                            {({ handleSubmit, isValid, values, setFieldTouched }) => (
                                <>
                                    <div className="mb-[1.953rem]">
                                        <p className="text-[0.7812rem] font-normal text-jumbleng-gray-main">First name</p>
                                        <Field
                                            name={'first_name'}
                                            onKeyUp={() => setFieldTouched('first_name', true)}
                                            placeholder={'Firstname'}
                                            className="w-[23.5rem] placeholder:text-[0.78125rem] focus:outline-none py-2 pr-4 pl-2 border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]"
                                        />
                                        <ErrorMessage
                                            name={'first_name'}
                                            render={(msg) => <div className="text-[0.7812rem] text-red-400 text-left font-normal">{msg}</div>}
                                        />
                                    </div>

                                    <div className="mb-[1.953rem]">
                                        <p className="text-[0.7812rem] font-normal text-jumbleng-gray-main">Last name</p>
                                        <Field
                                            name={'last_name'}
                                            onKeyUp={() => setFieldTouched('last_name', true)}
                                            placeholder={'Lastname'}
                                            className="w-[23.5rem] placeholder:text-[0.78125rem] focus:outline-none pt-2 pr-4 pl-2 border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]"
                                        />
                                        <ErrorMessage
                                            name={'last_name'}
                                            render={(msg) => <div className="text-[0.7812rem] text-red-400 text-left font-normal">{msg}</div>}
                                        />
                                    </div>

                                    <div className="mb-[1.953rem]">
                                        <p className="text-[0.7812rem] font-normal text-jumbleng-gray-main">Email</p>
                                        <Field
                                            name={'email'}
                                            onKeyUp={() => setFieldTouched('email', true)}
                                            placeholder={'Your Email'}
                                            className="w-[23.5rem] placeholder:text-[0.78125rem] focus:outline-none pt-2 pr-4 pl-2 border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]"
                                        />
                                        <ErrorMessage
                                            name={'email'}
                                            render={(msg) => <div className="text-[0.7812rem] text-red-400 text-left font-normal">{msg}</div>}
                                        />
                                    </div>

                                    <div className="mb-[3.125rem]">
                                        <p className="text-[0.7812rem] font-normal text-jumbleng-gray-main">Create password</p>
                                        <Field
                                            name={'password'}
                                            onKeyUp={() => setFieldTouched('password', true)}
                                            type={'password'}
                                            placeholder={'Your Password'}
                                            className="w-[23.5rem] placeholder:text-[0.78125rem] focus:outline-none pt-2 pr-4 pl-2 border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]"
                                        />
                                        <ErrorMessage
                                            name={'password'}
                                            render={(msg) => <div className="text-[0.7812rem] text-red-400 text-left font-normal ">{msg}</div>}
                                        />
                                    </div>

                                    <div className="mb-[1.953rem]">
                                        <p className="text-[0.7812rem] font-normal text-jumbleng-gray-main">Phone Number</p>
                                        <div className="flex">
                                            <div className="mt-1 relative flex ">
                                                <Field
                                                    as={'select'}
                                                    name={'code'}
                                                    className="w-[6.5rem] placeholder:text-[0.78125rem] focus:outline-none pt-2 pr-4 pl-2 border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]"
                                                >
                                                    {phone_numbers?.map((item: any, index: number) => (
                                                        <option key={index} value={item}>
                                                            {item}
                                                        </option>
                                                    ))}{' '}
                                                </Field>
                                            </div>
                                            <Field
                                                name={'phone_number'}
                                                onKeyUp={() => setFieldTouched('phone_number', true)}
                                                type={'tel'}
                                                placeholder={'Phone Number'}
                                                className="w-[17rem] placeholder:text-[0.78125rem] focus:outline-none pt-2 pr-4 pl-2 border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]"
                                            />
                                        </div>
                                        <ErrorMessage
                                            name={'phone_number'}
                                            render={(msg) => <div className="text-[0.7812rem] text-red-400 text-left font-normal">{msg}</div>}
                                        />
                                    </div>

                                    <Button
                                        type={'button'}
                                        disabled={!isValid || !values?.first_name}
                                        onClick={handleSubmit}
                                        message={'Continue'}
                                        loading={false}
                                        className={`w-[23.5rem] h-[3.125rem] mb-[1.953rem] text-white  ${
                                            !isValid || !values?.first_name ? 'bg-[#CFCFD1]' : 'bg-jumbleng-primary'
                                        } text-[0.78125rem] font-semibold rounded-[12px]`}
                                    />
                                </>
                            )}
                        </Formik>
                        {/* <div className="w-[15.667rem]">
                            <p
                            className={`font-normal text-[0.78125rem] mb-[2.34rem] text-jumbleng-gray text-center ${classes.underlineSide}`}
                            >
                            Or create account with
                            </p>
                        </div>

                        <div className="w-[15.667rem] mx-auto flex justify-around">
                            <img src={FaceBookIcon} />
                            <img src={GoogleIcon} />
                        </div> 
                        */}
                    </div>
                </div>
            </div>
        </GuestRoute>
    );
};

export default connect((state: any) => {
    const { registration_data } = state.authStore;
    return { registration_data };
}, actions)(PersonalDetails);
