import styled from 'styled-components';

export const Container = styled.div`
    /* background-color: red; */
    position: relative;

    .team__header {
        font-size: 16px;
        display: flex;
        gap: 30px;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-right: 24px;
        align-items: center;

        p {
            color: #525171;
        }

        button {
            background-color: #5e5ba7;
            color: #fff;
            padding: 5px 12px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            gap: 13.3px;

            span {
                font-size: 26px;
            }
        }
    }

    .team__table__filt {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-right: 24px;
        gap: 30px;
        padding-top: 24px;

        .team__length {
            color: #525171;
            font-size: 16px;

            span {
                color: #5e5ba7;
                font-weight: bolder;
            }
        }

        .team__cta {
            display: flex;
            align-items: center;
            gap: 25px;

            .cta__search {
                display: flex;
                align-items: center;
                gap: 15px;
                padding: 5px 10px;
                border-bottom: 1px solid #00000050;
                width: 150px;

                input {
                    background-color: transparent;
                    border: none;
                    outline: none;

                    &::placeholder {
                        font-size: 13px;
                    }
                }

                img {
                    width: 16px;
                    height: 16px;
                }
            }

            .cta__filter {
                p {
                    display: flex;
                    align-items: center;
                    color: #525171;
                    gap: 7px;
                    font-size: 13px;

                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }

    .pagination__section {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        .pagination__tab {
            display: flex;
            color: #706f77;
            align-items: center;
            font-size: 13px;

            .pagination__input {
                margin: 0 5px;
                width: 30px;
                border: 1px solid #9796a9;
                display: flex;
                justify-content: center;
                border-radius: 4px;

                input {
                    width: 80%;
                    border: none;
                    outline: none;
                }
            }
        }

        .prev {
            color: #5e5ba7;
            font-weight: 700;
            font-size: 16px;
        }
        .next {
            color: #5e5ba7;
            font-weight: 700;
            font-size: 16px;
        }
        .disabled {
            pointer-events: none;
            cursor: not-allowed;
        }
    }
`;
