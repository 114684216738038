export const notificationContext = (context: 'all' | 'order' | 'payment' | 'invoice' | 'feed' | 'reminder', read: boolean) => {
    return {
        page: 1,
        limit: 10,
        read: read,
        context: context === 'all' ? '' : context.toUpperCase(),
    };
};

export type IstatusTab = 'all' | 'read' | 'unread';
export type IContextTab = 'all' | 'order' | 'payment' | 'invoice' | 'feed' | 'reminder';
