import { Link } from 'react-router-dom';

import AuthNav from '../../../components/AuthNav/AuthNav';
import GuestRoute from '../../../HOC/GuestRoute';

const PasswordResetDone = () => {
    return (
        <GuestRoute>
            <div className="w-full bg-jumbleng-background min-h-screen">
                <AuthNav />
                <div className="w-[24rem] mx-auto mt-[3.12rem]">
                    <h2 className="text-[1.85rem] font-semibold mb-[0.78125rem] text-center">Email sent</h2>

                    <p className="text-center text-[1rem] font-normal text-jumbleng-gray ">
                        {' '}
                        We have emailed you instructions to reset your password
                    </p>
                    <p className="text-center text-[0.78125rem] font-normal text-jumbleng-gray mb-[1.95rem]">
                        {' '}
                        If an account exists with the email you entered. You will receive the token.
                    </p>
                    <p className="text-[0.78125rem] text-center text-jumbleng-gray mb-[1.2rem]">
                        <i>If you don&apos;t receive an email. Check your spam folder or make sure you entered the correct email.</i>
                    </p>
                    <div className="15.67rem mx-auto text-center">
                        <p className="font-normal mt-[0.78125rem] text-[0.78125rem] text-jumbleng-gray">
                            Didn’t get any verification email?{' '}
                            <Link to="/forgot-password" className="font-semibold text-[0.78rem] text-jumbleng-primary">
                                Resend email
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </GuestRoute>
    );
};

export default PasswordResetDone;
