import { useState } from 'react';

import Radioed from '../../../assets/images/svg/radioed.svg';
import Unradioed from '../../../assets/images/svg/unradioed.svg';
import { Container } from './styles';

const Delivery = () => {
    const [mode, setMode] = useState('');

    return (
        <Container>
            <div className="panel__selection">
                <div
                    className={mode === 'pickup' ? 'selected' : 'unselected'}
                    onClick={() => {
                        setMode('pickup');
                    }}
                >
                    <div>
                        <h3>In Store Pickup</h3>
                        <p>Visit Nearest stores to pick up orders</p>
                    </div>
                    {mode === 'pickup' ? <img src={Radioed} alt="" /> : <img src={Unradioed} alt="" />}
                </div>

                <div
                    className={mode === 'courier' ? 'selected' : 'unselected'}
                    onClick={() => {
                        setMode('courier');
                    }}
                >
                    <div>
                        <h3>Courier</h3>
                        <p>Orders Fulfilled By Courier Company</p>
                    </div>
                    {mode === 'courier' ? <img src={Radioed} alt="" /> : <img src={Unradioed} alt="" />}
                </div>
            </div>
        </Container>
    );
};

export default Delivery;
