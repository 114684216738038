import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Inactive from '../../assets/images/svg/toggle-left.svg';
import Active from '../../assets/images/svg/toggle-right.svg';
import Wrapper from '../../HOC/Wrapper';
import { useDisclosure } from '../../hooks/useDisclosure';
import { useGetStoreQuery } from '../../redux/services';
import CreateModal from './CreateModal';
import Delivery from './Delivery';
import OrderTable from './OrdersTable';
import Products from './Products';
import Container, {
    EmptyContainer,
    EmptyText,
    PageCta,
    PageCtaContainer,
    PageHeader,
    PageTitle,
    StoreTableBody,
    StoreTableBodyRow,
    StoreTableContainer,
    StoreTableDataCell,
    StoreTableHeader,
    StoreTableHeaderCell,
    StoreTableHeaderRow,
    StoreToggleImage,
    Tab,
    TabContainer,
    TemplateContainer,
    TemplateHeader,
    TemplateList,
    TemplateListItem,
} from './styles';
import { templates } from './template';
import { TemplateType } from './types';

const StoreFront = () => {
    const navigate = useNavigate();

    const tableHeaders = ['Name', 'Orders', 'Revenue', 'Status', 'Link'];

    const { isOpen: isNewStoreDialogOpen, toggle: toggleNewStoreDialog } = useDisclosure(false);
    const { isOpen: isAddStoreModalOpen, setIsOpen: setIsAddStoreModalOpen, toggle: toggleAddStoreModal } = useDisclosure(false);

    const [category, setCategory] = useState<Lowercase<TemplateType> | null>(null);
    const resp = useGetStoreQuery();
    const { data: store, isSuccess, isLoading } = resp;

    const [view, setView] = useState(false);
    const [active, setActive] = useState('orders');

    const startProcess = (value: any) => {
        toggleAddStoreModal();
        toggleNewStoreDialog();
        setCategory(value);
    };

    return (
        <Wrapper>
            <Container>
                <PageHeader>
                    <PageTitle>Storefront</PageTitle>
                    {!isLoading && (
                        <PageCtaContainer>
                            <PageCta onClick={toggleNewStoreDialog} disabled={!!store}>
                                <span>+</span> New StoreFront
                            </PageCta>

                            {isNewStoreDialogOpen && (
                                <TemplateContainer>
                                    <TemplateHeader>Select Theme</TemplateHeader>
                                    <TemplateList>
                                        {templates.map((template, index) => (
                                            <TemplateListItem onClick={() => startProcess(template.value)} key={index}>
                                                {template.type}
                                            </TemplateListItem>
                                        ))}
                                    </TemplateList>
                                </TemplateContainer>
                            )}
                        </PageCtaContainer>
                    )}
                </PageHeader>
                {isSuccess ? (
                    <>
                        <StoreTableContainer>
                            <StoreTableHeader>
                                <StoreTableHeaderRow>
                                    {tableHeaders.map((header: string, index) => (
                                        <StoreTableHeaderCell key={index}>{header}</StoreTableHeaderCell>
                                    ))}
                                </StoreTableHeaderRow>
                            </StoreTableHeader>
                            <StoreTableBody>
                                <StoreTableBodyRow>
                                    <StoreTableDataCell onClick={() => setView(true)}>{store.data.store_name}</StoreTableDataCell>
                                    <StoreTableDataCell>{'Orders'}</StoreTableDataCell>
                                    <StoreTableDataCell>NGN {store.data.revenue.amount.toLocaleString()}</StoreTableDataCell>
                                    <StoreTableDataCell>
                                        <StoreToggleImage src={store.data.suspended ? Inactive : Active} alt="toggle" />
                                    </StoreTableDataCell>
                                    {store.data.sub_domain.length && (
                                        <StoreTableDataCell className="link" onClick={() => navigate('/storepreview')}>
                                            Preview
                                        </StoreTableDataCell>
                                    )}
                                </StoreTableBodyRow>
                            </StoreTableBody>
                        </StoreTableContainer>
                    </>
                ) : isLoading ? (
                    <EmptyContainer>
                        <EmptyText>Loading...</EmptyText>
                    </EmptyContainer>
                ) : (
                    <EmptyContainer>
                        <EmptyText>No storefront created</EmptyText>
                    </EmptyContainer>
                )}

                {view && (
                    <div className="store__expand">
                        <TabContainer className="nav__tab">
                            <Tab active={active === 'orders'} onClick={() => setActive('orders')}>
                                Orders
                            </Tab>
                            <Tab active={active === 'products'} onClick={() => setActive('products')}>
                                Products
                            </Tab>
                            <Tab active={active === 'delivery'} onClick={() => setActive('delivery')}>
                                Delivery
                            </Tab>
                        </TabContainer>
                        {active === 'orders' ? <OrderTable /> : active === 'products' ? <Products /> : <Delivery />}
                    </div>
                )}
                {/*TODO: come here and fix props*/}
                {isAddStoreModalOpen && category && <CreateModal setAddOpen={setIsAddStoreModalOpen} category={category} />}
            </Container>
        </Wrapper>
    );
};

export default StoreFront;
