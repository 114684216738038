import { Dispatch, FC, SetStateAction } from 'react';
import { useSelector } from 'react-redux';

import { Iaction } from '../../../redux/features/product/productRedux';
import { RootState } from '../../../redux/store';
import { initialAddProductValues } from '../../../schema';

interface ModalProps {
    showModal: boolean;
    setModal?: Dispatch<SetStateAction<boolean>>;
    setAddProduct?: Dispatch<SetStateAction<boolean>>;
    setProductForm?: Dispatch<SetStateAction<string>>;
    setCreateProductField?: (payload: any) => Iaction;
    setEditProduct?: Dispatch<SetStateAction<boolean>>;
    toggleEditProductModal?: Dispatch<SetStateAction<boolean>>;
    setEditModal?: Dispatch<SetStateAction<boolean>>;
}

const Modal: FC<ModalProps> = (props) => {
    const { showModal, setModal, setEditModal, setAddProduct, setProductForm, toggleEditProductModal, setEditProduct, setCreateProductField } = props;
    const { nextSKU } = useSelector((state: RootState) => state.ProductReducer);
    const handleClick = () => {
        setModal && setModal(false);
        setProductForm && setProductForm('FORM1');
        setAddProduct && setAddProduct(false);
        setEditProduct && setEditProduct(false);
        toggleEditProductModal && toggleEditProductModal(false);
        setEditModal && setEditModal(false);
        setCreateProductField && setCreateProductField({ initialAddProductValues, ...nextSKU });
    };
    if (!showModal) return <></>;

    return <div className="fixed h-screen w-screen top-0 left-0 bg-[rgba(0,0,0,0.5)] z-[55]" onClick={handleClick} />;
};

export default Modal;
