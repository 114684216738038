/* eslint-disable @typescript-eslint/no-unused-vars */
import { ErrorMessage, Field } from 'formik';
import React from 'react';

const InputField = ({
    label,
    type,
    name,
    placeholder,
    values,
    setFieldTouched,
}: {
    type: string;
    label?: string;
    name: string;
    placeholder: string;
    values?: string;
    setFieldTouched?: any;
}) => {
    return (
        <div className="flex flex-col">
            {label && <p className="text-[#525171] text-[16px] mt-4">{label}</p>}{' '}
            <Field
                type={type}
                name={name}
                values={values || ''}
                onKeyUp={() => setFieldTouched(name, true)}
                placeholder={placeholder}
                className="p-[0.585rem] text-jumbleng-gray border-b-2 focus:outline-none border-b-[#CFCFD1]"
            />{' '}
            <ErrorMessage name={name} render={(msg: any) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>} />
        </div>
    );
};

export default InputField;
