import persistReducer from 'redux-persist/es/persistReducer';
import storageSession from 'redux-persist/lib/storage/session';

import { initialOrdersState, initialSingleOrderState } from '../dtos';
import { IOrders, OrderItemsType, OrderType } from '../interfaces';
import { types } from './OrderActionTypes';

export const listMyOrdersReducer = (state: IOrders = initialOrdersState, action: any) => {
    switch (action.type) {
        case types.setStatus:
            return { ...state, currentStatus: action.payload };
        case types.setActiveTab:
            return { ...state, activeTab: action.payload };
        case types.setIsCustomer:
            return { ...state, iscustomer: action.payload };
        default:
            return state;
    }
};

export const listCustomerOrdersReducer = persistReducer(
    { storage: storageSession, key: 'order-activeTab', whitelist: ['iscustomer', 'activeTab'] },
    (state: IOrders = initialOrdersState, action: any) => {
        switch (action.type) {
            case types.setCustomerStatus:
                return { ...state, currentStatus: action.payload };
            case types.setActiveTab:
                return { ...state, activeTab: action.payload };
            case types.setIsCustomer:
                return { ...state, iscustomer: action.payload };

            default:
                return state;
        }
    },
);

export const getOrderReducer = persistReducer(
    { storage: storageSession, key: 'jumble-single-order', whitelist: ['iscustomer'] },
    (state: OrderType = initialSingleOrderState, action: any) => {
        switch (action.type) {
            case types.setIsCustomer:
                return { ...state, iscustomer: action.payload };

            case types.decrementItem:
                const existingItem = state.order_items.find((order_item: OrderItemsType) => order_item.id === action.payload);
                const newItems = state.order_items.map((order_item: OrderItemsType) =>
                    order_item.id === existingItem?.id ? { ...order_item, qty: order_item.qty - 1 } : order_item,
                );
                //remove item from cart if the quantity is equal to zero
                const newItem = newItems.filter((order_item: OrderItemsType) => order_item.qty !== 0).map((order_item: OrderItemsType) => order_item);
                return {
                    ...state,
                    order_items: newItem,
                };

            case types.incrementItem:
                const existedItem = state.order_items.find((order_item: OrderItemsType) => order_item.id === action.payload);
                const latestItems = state.order_items.map((order_item: OrderItemsType) =>
                    order_item.id === existedItem?.id
                        ? {
                              ...order_item,
                              qty: order_item.qty + 1,
                          }
                        : order_item,
                );
                return {
                    ...state,
                    order_items: latestItems,
                };

            case types.updateQty:
                const itemExist = state.order_items.find((order_item: OrderItemsType) => order_item.id === action.payload.id);
                const Items = state.order_items.map((order_item: OrderItemsType) =>
                    order_item.id === itemExist?.id
                        ? {
                              ...order_item,
                              qty: Number(action.payload.value),
                          }
                        : order_item,
                );
                return {
                    ...state,
                    order_items: Items,
                };

            case types.removeItem:
                const updatedItem = state.order_items
                    .filter((order_item: OrderItemsType) => order_item.id !== action.payload)
                    .map((order_item: OrderItemsType) => order_item);
                return {
                    ...state,
                    order_items: updatedItem,
                };

            case 'SET_ORDER_ITEMS':
                return {
                    ...state,
                    order_items: action.payload,
                };

            default:
                return state;
        }
    },
);

export const order = {
    listMyOrdersReducer,
    listCustomerOrdersReducer,
    getOrderReducer,
};
