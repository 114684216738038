export const setPStatus = 'setPStatus';
export const setPIsCustomer = 'setPIsCustomer';

export const setCustomerPStatus = 'setCustomerPStatus';

export const setActiveTab = 'setPaymentActiveTab';

export const types = {
    setPStatus,
    setPIsCustomer,
    setCustomerPStatus,
    setActiveTab,
};
