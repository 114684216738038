import * as Yup from 'yup';

import { ICompanyDetails, ICompanyProfile } from '../interfaces';

const FILE_SIZE = 5072 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const initialCompanyDetailsValues: ICompanyDetails = {
    email: '',
    country: '',
    industry_type: '',
    phone_number: '+234          ',
    state: '',
    work_force: '',
    address: '',
    company_type: '',
};
export const initialCompanyProfileValues: ICompanyProfile = {
    email: '',
    logo: '',
    industry_type: '',
    phone_number: '',
    name: '',
    address: '',
};

export const CompanyDetailsSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').trim().required('Company email is a required field'),
    industry_type: Yup.string().required('Industry type is a required field'),
    phone_number: Yup.number()
        .required('Phone number  is a required field')
        .typeError('Only digit(s) is allowed')
        .test('len', 'Phone number must 10 digits', (val: any) => val?.toString().length === 10),
    work_force: Yup.string().required('Work force is a required field'),
    address: Yup.string().required('Company address is a required field'),
    company_type: Yup.string().required('Company type is a required field'),
});

export const CompanyProfileSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').trim().required('Company email is a required field'),
    industry_type: Yup.string().required('Industry type is a required field'),
    name: Yup.string().required('Name is a required field'),
    phone_number: Yup.number().required('Phone number is a required field'),
    address: Yup.string().required('Company address is a required field'),
    logo: Yup.lazy((value) => {
        if (value) {
            switch (typeof value) {
                case 'string':
                    return Yup.mixed().test('url', (value) => value && value.includes('http')); // schema for image url
                case 'object':
                    return Yup.mixed()
                        .test('fileFormat', 'Unsupported Image Format', (value) => value && SUPPORTED_FORMATS.includes(value.type))
                        .test('fileSize', 'File is too large', (value) => value && value.size <= FILE_SIZE); // schema for image file
                default:
                    return Yup.mixed().test('url', (value) => value && value.includes('http')); // here you can decide what is the default
            }
        } else {
            return Yup.string().required('Logo cannot be blank');
        }
    }),
});
