import moment from 'moment';
import { useEffect, useState } from 'react';
import { EmptyContainer, EmptyText } from '../styles';
import { Order } from '../../../redux/features/interfaces';
import { Tabled } from './styles';

const MOCK_ORDERS: Order | null = null;

const OrderTable = () => {
    const [status, setStatus] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [ordersArr, setordersArr] = useState<any[]>([]);

    const orders = MOCK_ORDERS; //TODO: replace this

    useEffect(() => {
        setordersArr(orders && 'data' in orders ? orders['data'] : []);
    }, [orders]);

    const theadData = ['Order date', 'Ordering company', 'Order number', 'No of Order', 'Order value', 'Invoice issued', 'Order status', 'Action'];

    const [data, setData] = useState<any[]>([]);
    const [pending, setPending] = useState<any>();
    const [fulfilled, setFulfilled] = useState<any>();
    const [failed, setFailed] = useState<any>();

    useEffect(() => {
        const pendArr = ordersArr?.filter((arr: any) => arr?.status?.toLowerCase()?.includes('pending'));
        setPending(pendArr?.length);
        const fullArr = ordersArr?.filter((arr: any) => arr?.status?.toLowerCase()?.includes('fulfilled'));
        setFulfilled(fullArr?.length);
        const failArr = ordersArr?.filter((arr: any) => arr?.status?.toLowerCase()?.includes('unfulfilled'));
        setFailed(failArr?.length);
    }, [ordersArr, orders]);

    useEffect(() => {
        const filteredData = ordersArr?.filter(
            (name: any) =>
                name?.orderNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                name?.company?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                name?.status?.toLowerCase().includes(searchTerm.toLowerCase()),
        );
        setData(filteredData);
    }, [searchTerm, ordersArr, orders]);

    return (
        <Tabled>
            {orders ? (
                <>
                    <div className="cta__section">
                        <div className="status__cta">
                            <p
                                onClick={() => {
                                    setStatus('all');
                                    setSearchTerm('');
                                }}
                                className={status === 'all' ? 'clicked' : 'unclicked'}
                            >
                                All <span>{ordersArr?.length}</span>
                            </p>
                            <p
                                onClick={() => {
                                    setStatus('pending');
                                    setSearchTerm('pending');
                                }}
                                className={status === 'pending' ? 'clicked' : 'unclicked'}
                            >
                                Pending <span>{pending}</span>
                            </p>
                            <p
                                onClick={() => {
                                    setStatus('fulfilled');
                                    setSearchTerm('fulfilled');
                                }}
                                className={status === 'fulfilled' ? 'clicked' : 'unclicked'}
                            >
                                Fulfilled <span>{fulfilled}</span>
                            </p>
                            <p
                                onClick={() => {
                                    setStatus('failed');
                                    setSearchTerm('unfulfilled');
                                }}
                                className={status === 'failed' ? 'clicked' : 'unclicked'}
                            >
                                Not Fulfilled <span>{failed}</span>
                            </p>
                        </div>
                        <div className="search__cta">
                            <input type="text" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search order num, vendors" />
                        </div>
                    </div>

                    <table>
                        <thead>
                            <tr>
                                {theadData.map((h: any, i: number) => {
                                    return (
                                        <th key={i}>
                                            <p>{h}</p>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((item: any, index: number) => {
                                return (
                                    <tr key={index} style={index % 2 == 0 ? { backgroundColor: '#F9F9FA' } : { backgroundColor: '#fff' }}>
                                        <td>
                                            <p>{moment(item?.created_at).format('DD MMM YYYY HH:mm A')}</p>
                                        </td>
                                        <td>
                                            <p>{item?.ordering_company?.name}</p>
                                        </td>
                                        <td>
                                            <p>{item?.order_no}</p>
                                        </td>
                                        <td>
                                            <p>{item?.order_items?.length}</p>
                                        </td>
                                        <td>
                                            <p>
                                                ₦{' '}
                                                {item?.order_items
                                                    ?.map((item: any) => item?.buying_price)
                                                    ?.reduce((partialSum: any, a: any) => partialSum + a, 0)
                                                    .toLocaleString('en-US')}
                                            </p>
                                        </td>
                                        <td>
                                            <p>{item?.invoice_issued ? 'Yes' : 'No'}</p>
                                        </td>
                                        <td className={item?.status === 'fulfilled' ? 'go' : item?.status === 'pending' ? 'ready' : 'stop'}>
                                            <p>{item?.status}</p>
                                        </td>
                                        <td className="enbolden">
                                            <p>...</p>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </>
            ) : (
                <EmptyContainer>
                    <EmptyText>No orders processed</EmptyText>
                </EmptyContainer>
            )}
        </Tabled>
    );
};

export default OrderTable;
