import { BaseQueryFn, createApi, FetchArgs } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth, createRequestWithParams, CustomError } from './shared';

export const dashboardApi = createApi({
    reducerPath: 'dashboardApi',
    baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, any>>,
    tagTypes: ['dashboard'],
    endpoints: (builder) => ({
        getOrderByStatus: builder.query<any, { status: string; filter: string }>({
            query: ({ status, filter }) => createRequestWithParams(`company/order/${status}`, { filter }),
            transformResponse: (results: { data: any }) => results.data,
        }),
        getRevenueByStatus: builder.query<any, { status: string; filter: string }>({
            query: ({ status, filter }) => createRequestWithParams(`company/revenue/${status}`, { filter }),
            transformResponse: (results: { data: any }) => results.data,
        }),
        getSalesByStatus: builder.query<any, { status: string; filter: string }>({
            query: ({ status, filter }) => createRequestWithParams(`company/sales/${status}`, { filter }),
            transformResponse: (results: { data: any }) => results.data,
        }),
    }),
});

export const { useGetOrderByStatusQuery, useGetSalesByStatusQuery, useGetRevenueByStatusQuery } = dashboardApi;
