import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';

import CircledAvatar from '../../../assets/images/svg/circleAvatar.svg';
import More from '../../../assets/images/svg/More.svg';
import Filter from '../../../assets/images/svg/sliders.svg';
import Pagination from '../../../components/Pagination/Pagination';
import PopUpModal from '../../../components/PopModal/PopUpModal';
import Loading from '../../../components/UI/Spinner/Loading';
import Wrapper from '../../../HOC/Wrapper';
import {
    useDeleteGroupMemberMutation,
    useGetAGroupQuery,
    useGetGroupMembersQuery,
    useReactivateGroupMembersMutation,
    useSuspendGroupMembersMutation,
} from '../../../redux/services';
import { transformedDate } from '../../../utils/utils';
import EmptyMembers from '../EmptyContent/EmptyMembers';
import GroupAction from './GroupActionModal';
import ExpandedGroupHeader from './Header';

export type groupQueryType = { page: number; limit: number; search_query: string; filter: string };
export const initialGroupQuery: groupQueryType = {
    page: 1,
    limit: 10,
    search_query: '',
    filter: '',
};
const ExpandedGroup = () => {
    const { id } = useParams();
    const [query, setQuery] = useState<groupQueryType>(initialGroupQuery);
    const { data, isFetching } = useGetAGroupQuery(id as string);
    const [suspendVendor, { isLoading: suspendloading, isSuccess, isError, error }] = useSuspendGroupMembersMutation();
    const [reactivateVendor, { isLoading: reactivateloading, isSuccess: Success, isError: Iserror, error: Err }] =
        useReactivateGroupMembersMutation();
    const [deleteVendor, { isLoading: deleteloading, isSuccess: issuccess, isError: iserror, error: err }] = useDeleteGroupMemberMutation();
    const { data: membersdata, isLoading } = useGetGroupMembersQuery({ id: id as string, ...query });

    useEffect(() => {
        if (isSuccess) {
            toast.success('Member suspended successfully');
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
        }
    }, [error, isError, isSuccess, suspendloading]);

    useEffect(() => {
        if (issuccess) {
            toast.success('Group deleted successfully');
        }
        if (iserror && err && 'status' in err) {
            toast.error(err?.data?.message);
        }
    }, [err, iserror, deleteloading, issuccess]);

    useEffect(() => {
        if (Success) {
            toast.success('Member reactivated successfully');
        }
        if (Iserror && Err && 'status' in Err) {
            toast.error(Err?.data?.message);
        }
    }, [Err, Iserror, Success, reactivateloading]);

    if (isFetching || isLoading) {
        return (
            <div className="min-h-[100vh] flex justify-center flex-col">
                <Loading />
            </div>
        );
    }

    return (
        <Wrapper>
            <ExpandedGroupHeader data={data.data} />
            {membersdata.data.length === 0 ? (
                <EmptyMembers />
            ) : (
                <>
                    <div className="flex">
                        <div className="text-[#5E5BA7] text-[16px] ">
                            <span className="font-bold">{membersdata.count}</span> Vendors found
                        </div>

                        <div className="flex items-center relative" style={{ marginLeft: 'auto' }}>
                            <div className="w-[7.7rem] h-[2.3rem] relative mr-[45px]">
                                <i className="fa fa-search icon absolute top-1/2 -translate-y-1/2 px-[0.79rem]" />
                                <input
                                    className="w-full bg-[#F8F8FB] border-b-2 focus:outline-none pl-[2.08rem] h-full placeholder:text-[0.78165rem] text-[0.78125rem] text-jumbleng-gray placeholder:text-jumbleng-gray"
                                    placeholder="Search customers"
                                />
                            </div>

                            <div className="flex hover:cursor-pointer text-[0.78125rem] mr-[45px] font-normal text-jumbleng-gray items-center">
                                <span className="mr-[9px]">Filter</span>
                                <img src={Filter} className="w-[1.17rem] h-[1.17rem]" alt="Filter" />
                            </div>
                        </div>
                    </div>

                    <div className=" p-5 mt-5">
                        <div className="grid grid-cols-8 pt-4 bg-white p-5   font-bold">
                            <div className="jumbleng-gray-main text-base col-span-3 ">Name</div>
                            <div className="jumbleng-gray-main text-base grid justify-center col-span-1">Industry</div>
                            <div className="jumbleng-gray-main text-base grid justify-center col-span-1">Type</div>
                            <div className="jumbleng-gray-main text-base grid justify-center col-span-1"> Date Added</div>

                            <div className="jumbleng-gray-main text-base grid justify-center col-span-1">Status</div>
                            <div className="jumbleng-gray-main text-base grid justify-center col-span-1">Action</div>
                        </div>

                        {membersdata?.data.map((member: any) => (
                            <div className="grid grid-cols-8 pt-4 p-5 gap-1" style={{ backgroundColor: '#F9F9FA' }} key={member.id}>
                                <div className="text-sm col-span-3 flex gap-2 ">
                                    {' '}
                                    <div className="grid content-center">
                                        <input type="checkbox" />
                                    </div>
                                    <img src={CircledAvatar} alt="avatar" />
                                    <div>
                                        <div>{member.customer.name}</div>
                                        <div> {member.customer.email}</div>
                                    </div>
                                </div>
                                <div className="text-sm grid justify-center col-span-1"> {member.customer.industry_type}</div>
                                <div className="text-sm grid justify-center col-span-1 capitalize">{member.customer.company_type}</div>
                                <div className="text-sm grid justify-center col-span-1">{transformedDate(member.created_at)}</div>

                                <div className=" grid justify-center">
                                    {member.connection_info.vendor_status === 'Active' ? (
                                        <div className=" flex items-center gap-2 text-[#084F21] text-sm bg-[#DEEAE2] py-1 px-5 rounded-2xl h-[30px]">
                                            <div className="h-3 w-3 rounded-full border border-[#327a4b] object-contain">
                                                <div className="h-2 w-2 border rounded-full bg-[#327a4b] my-[1px] mx-auto capitalize" />
                                            </div>
                                            {member.connection_info.vendor_status}
                                        </div>
                                    ) : (
                                        <div className=" flex items-center gap-2 text-[#840B0B] text-sm bg-[#F5E7E7] py-1 px-5 rounded-2xl h-[30px] capitalize">
                                            {member.connection_info.vendor_status}
                                        </div>
                                    )}
                                </div>

                                <Popup
                                    trigger={
                                        <div className="grid justify-center col-span-1">
                                            <img src={More} alt="more" />
                                        </div>
                                    }
                                    on="hover"
                                    closeOnDocumentClick
                                    arrow={false}
                                    nested
                                    contentStyle={{
                                        padding: '10px',
                                        borderRadius: '12px',
                                        width: '167px',
                                    }}
                                >
                                    <div className=" grid justify-center text-jumbleng-primary">
                                        {member.connection_info.vendor_status !== 'Active' ? (
                                            <>
                                                <PopUpModal
                                                    content={
                                                        <div className="grid justify-center mt-2 hover:text-jumbleng-gray cursor-pointer">
                                                            Reactivate Vendor
                                                        </div>
                                                    }
                                                    className=""
                                                    popupcontent={false}
                                                    onClose={close}
                                                >
                                                    {(close: () => void) => (
                                                        <GroupAction
                                                            action={reactivateVendor}
                                                            member_id={member.id}
                                                            close={close}
                                                            actionName={'reactivate'}
                                                            actionTitle={'MEMBER'}
                                                            id={data?.data.id}
                                                            name={member.customer.name}
                                                            loader={reactivateloading}
                                                        />
                                                    )}
                                                </PopUpModal>
                                                <PopUpModal
                                                    content={
                                                        <div className="grid justify-center mt-2 mb-2 hover:text-jumbleng-gray cursor-pointer">
                                                            Delete Vendor
                                                        </div>
                                                    }
                                                    className=""
                                                    popupcontent={false}
                                                    onClose={close}
                                                >
                                                    {(close: () => void) => (
                                                        <GroupAction
                                                            action={deleteVendor}
                                                            member_id={member.id}
                                                            close={close}
                                                            actionName={'remove'}
                                                            actionTitle={'MEMBER'}
                                                            id={data?.data.id}
                                                            name={member.customer.name}
                                                            loader={deleteloading}
                                                        />
                                                    )}
                                                </PopUpModal>
                                            </>
                                        ) : (
                                            <>
                                                <PopUpModal
                                                    content={
                                                        <div className="grid justify-center mt-2 hover:text-jumbleng-gray cursor-pointer">
                                                            Suspend Vendor
                                                        </div>
                                                    }
                                                    className=""
                                                    popupcontent={false}
                                                    onClose={close}
                                                >
                                                    {(close: () => void) => (
                                                        <GroupAction
                                                            action={suspendVendor}
                                                            member_id={member.id}
                                                            close={close}
                                                            actionName={'suspend'}
                                                            actionTitle={'MEMBER'}
                                                            id={data?.data.id}
                                                            name={member.customer.name}
                                                            loader={suspendloading}
                                                        />
                                                    )}
                                                </PopUpModal>
                                                <PopUpModal
                                                    content={
                                                        <div className="grid justify-center mt-2 mb-2 hover:text-jumbleng-gray cursor-pointer">
                                                            Delete Vendor
                                                        </div>
                                                    }
                                                    className=""
                                                    popupcontent={false}
                                                    onClose={close}
                                                >
                                                    {(close: () => void) => (
                                                        <GroupAction
                                                            action={deleteVendor}
                                                            member_id={member.id}
                                                            close={close}
                                                            actionName={'remove'}
                                                            actionTitle={'MEMBER'}
                                                            id={data?.data.id}
                                                            name={member.customer.name}
                                                            loader={deleteloading}
                                                        />
                                                    )}
                                                </PopUpModal>
                                            </>
                                        )}
                                    </div>
                                </Popup>
                            </div>
                        ))}
                    </div>
                    {membersdata?.data.length > 0 && (
                        <Pagination query={query} setQuery={setQuery} data={membersdata?.data} count={membersdata?.count} />
                    )}
                </>
            )}
        </Wrapper>
    );
};

export default ExpandedGroup;
