import axios from 'axios';
import { Dispatch } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { call, put, takeLatest } from 'redux-saga/effects';

import { initialAuthState } from '../dtos';
import {
    authorizationType,
    companyType,
    Eaction,
    EregistrationSteps,
    Iaction,
    IinitialAuthState,
    registrationDataType,
    userType,
} from '../interfaces';
import { getUserByToken, logoutUser } from './authService';

export const AuthReducer = persistReducer(
    {
        storage,
        key: 'jumble-auth',
        whitelist: ['registration_data', 'registration_step', 'user', 'company', 'authorization', 'reset_type'],
    },
    (state: IinitialAuthState = initialAuthState, action: Iaction) => {
        switch (action.type) {
            case Eaction.setAllUserDetails:
                const { user, company, authorization } = action.payload;
                return { ...state, user, company, authorization };
            case Eaction.clearRegistrationData:
                return {
                    ...state,
                    registration_data: {
                        first_name: '',
                        last_name: '',
                        email: '',
                        password: '',
                        phone_number: '',
                        role: 'sales_officer',
                    },
                };
            case Eaction.updateProfile:
                return { ...state, company: { ...state.company, ...action.payload } };
            case Eaction.updateUser:
                return { ...state, user: { ...state.user, ...action.payload } };
            case Eaction.setRegistrationStep:
                return { ...state, registration_step: action.payload };
            case Eaction.setResetOrChangePassword:
                return { ...state, reset_type: action.payload };
            case Eaction.setRegistrationData:
                return { ...state, registration_data: action.payload };
            case Eaction.setAuthorization:
                return { ...state, authorization: action.payload };
            case Eaction.logout:
                return initialAuthState;
            case Eaction.fulfillUser:
                return { ...state, ...action.payload };
            default:
                return state;
        }
    },
);

export const actions = {
    setAllUserDetails: (payload: { user: userType; company: companyType; authorization: authorizationType }) => ({
        type: Eaction.setAllUserDetails,
        payload,
    }),
    updateProfile: (payload: { company: companyType }) => ({ type: Eaction.updateProfile, payload }),
    setRegistrationStep: (payload: EregistrationSteps) => ({ type: Eaction.setRegistrationStep, payload }),
    setResetOrChangePassword: (payload: string) => ({ type: Eaction.setResetOrChangePassword, payload }),
    clearRegistrationData: () => ({ type: Eaction.clearRegistrationData }),
    setRegistrationData: (payload: registrationDataType) => ({ type: Eaction.setRegistrationData, payload }),
    setTokenObj: (tokenObj: authorizationType) => ({ type: Eaction.setAuthorization, payload: tokenObj }),
    fulfillUser: (payload: userType) => ({ type: Eaction.fulfillUser, payload }),
    logout: () => async (dispatch: Dispatch) => {
        try {
            await logoutUser();
            dispatch({ type: Eaction.logout });
        } catch (error: any) {}
    },
};

// action creators
export const logout = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: Eaction.logout });
    } catch (error: any) {}
};

export const setAllUserDetails =
    (payload: { user: userType; company: companyType; authorization: authorizationType }) => async (dispatch: Dispatch) => {
        dispatch({ type: Eaction.setAllUserDetails, payload });
    };

export const requestUser = () => {
    return { type: Eaction.requestUser };
};

export function* authSaga() {
    yield takeLatest(Eaction.setAuthorization, function* requestUser() {
        try {
            const source = axios.CancelToken.source();
            const payload: IinitialAuthState = yield call(getUserByToken, source);
            yield put({ type: Eaction.fulfillUser, payload });
        } catch (e) {
            yield put({ type: Eaction.logout });
        }
    });
}
