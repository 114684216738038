export const setIStatus = 'setIStatus';
export const setIIsCustomer = 'setIIsCustomer';
export const setCustomerIStatus = 'setCustomerIStatus';
export const setActiveTab = 'setInvoiceActiveTab';

export const types = {
    setIIsCustomer,
    setCustomerIStatus,
    setIStatus,
    setActiveTab,
};
