import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SelectSearch from 'react-select-search';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import ArrowDown from '../../../../assets/images/svg/arrow-down.svg';
import Delete from '../../../../assets/images/svg/delete-icon.svg';
import Edit from '../../../../assets/images/svg/edit-icon.svg';
import Search from '../../../../assets/images/svg/search.svg';
import { useAddMembersMutation } from '../../../../redux/services/teamsApi';
import { Modaled } from './styles';

const schemaCheck = yup.object().shape({
    email: yup.string().email('Invalid email format').required('Please enter email'),
    first_name: yup.string().required('Please enter first name'),
    last_name: yup.string().required('Please enter last name'),
    role: yup.string().required('Please assign a role.'),
});

const initialValues = {
    email: '',
    first_name: '',
    last_name: '',
    role: '',
};

function renderCustomer(props: any, option: any, snapshot: any, className: any) {
    return (
        <button value={option.value.value} {...props} className={className} type="button">
            <span>
                <span>{option.name}</span>
            </span>
        </button>
    );
}
const AddModal = ({ setAddOpen, roles, refetch }: { setAddOpen: any; roles: any; refetch?: any }) => {
    const [invitees, setInvitees] = useState<any[]>([]);
    const [editMode, setEditMode] = useState(false);
    const [toEdit, setToEdit] = useState({});
    const [editEmail, setEditEmail] = useState<any>();
    const dispatch = useDispatch();
    const [assign, setAssign] = useState(false);
    const [salesReps, setSaleReps] = useState<any>([]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [addMembers, { isLoading, isSuccess, isError, error }] = useAddMembersMutation();

    const rolesArr = roles?.data && Object?.entries(roles?.data);

    const removeInvite = (index: any) => {
        const newInvite = [...invitees];
        newInvite.splice(index, 1);
        setInvitees(newInvite);
    };

    const removeRep = (id: any) => {
        const newReps = salesReps.filter((rep: any) => rep.id !== id);
        setSaleReps(newReps);
    };

    const editInvite = (invitee: any, _: any) => {
        setEditMode(true);
        setToEdit(invitee);
        setEditEmail(invitee.email);
    };

    const editForm = (values: any) => {
        const newState = invitees.map((obj) => {
            if (obj?.email === editEmail) {
                return { ...obj, email: values.email, first_name: values.first_name, role: values.role };
            }
            return obj;
        });

        setInvitees(newState);
        setEditMode(false);
    };

    const addInvitee = (values: any) => {
        let ids;
        if (assign === false) {
            ids = [];
        } else {
            ids = salesReps.map((item: any) => item.id);
        }
        const newInvite = [...invitees, { ...values, vendors: ids }];
        setInvitees(newInvite);
        setSaleReps([]);
    };
    const handleAddSalesRep = (args: any) => {
        salesReps?.some((rep: any) => rep?.id === args[0])
            ? toast.error('Vendor already added')
            : setSaleReps([...salesReps, { id: args[0], name: args[1] }]);
    };

    const doSubmit = async (values: any) => {
        addInvitee(values);
    };

    const sendInvite = async (invitees: any) => {
        if (invitees.length > 0) {
            await addMembers(invitees);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Invite(s) sent successfully');
            refetch();
            setAddOpen(false);
            setSaleReps([]);
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
            setSaleReps([]);
        }
    }, [isSuccess, isError, error, setAddOpen, dispatch, refetch]);

    const getRole = (value: any) => {
        const inviteeRole: any = rolesArr?.find((role: any) => role?.includes(value));
        if (inviteeRole !== undefined) {
            return inviteeRole[1];
        } else return null;
    };

    return (
        <Modaled>
            <div className="darkBG" onClick={() => setAddOpen(false)} />
            <div className="centered">
                <div className="modal">
                    <div className="modalHeader">
                        <h5 className="heading">Add team member</h5>
                    </div>
                    <div className="closeBtn" onClick={() => setAddOpen(false)}>
                        <img src="https://www.svgrepo.com/show/66823/close.svg" alt="" />
                    </div>

                    {invitees.map((invitee, i) => (
                        <div className="invite__list" key={i}>
                            <div className="name__mail">
                                <p className="invite__mail">{invitee.email}</p>
                                <p className="invite__name">
                                    {invitee.first_name} {invitee.last_name}
                                </p>
                            </div>
                            <div className="role__container">
                                {/* <p>{invitee.role.replaceAll('_', ' ')}</p> */}
                                <p>{getRole(invitee?.role)}</p>
                            </div>

                            <div className="invite__cta">
                                <img src={Edit} alt="" onClick={() => editInvite(invitee, i)} />
                                <img src={Delete} alt="" onClick={() => removeInvite(i)} />
                            </div>
                        </div>
                    ))}

                    {invitees.length && !editMode && (
                        <div className="team__index">
                            <p>Team Member {invitees.length + 1}</p>
                        </div>
                    )}
                    {!editMode ? (
                        <Formik
                            validationSchema={schemaCheck}
                            initialValues={initialValues}
                            enableReinitialize
                            onSubmit={(values, { resetForm }) => {
                                doSubmit(values), resetForm();
                            }}
                        >
                            {({ errors, touched, submitForm, values }) => (
                                <Form>
                                    <div className="modalContent">
                                        <div className="form__container">
                                            <div className="form__input">
                                                <label htmlFor="" className="mb-[10px]">
                                                    First Name
                                                </label>
                                                <Field name="first_name" type="first_name" placeholder="Input first name" />
                                                {errors.first_name && touched.first_name && <p className="errored">{errors.first_name}</p>}
                                            </div>
                                            <div className="form__input">
                                                <label htmlFor="" className="mb-[10px]">
                                                    Last Name
                                                </label>
                                                <Field name="last_name" type="last_name" placeholder="Input last name" />
                                                {errors.last_name && touched.last_name && <p className="errored">{errors.last_name}</p>}
                                            </div>
                                            <div className="form__input">
                                                <label htmlFor="" className="mb-[10px]">
                                                    Email Address
                                                </label>
                                                <Field name="email" type="email" placeholder="Input email address" />
                                                {errors.email && touched.email && <p className="errored">{errors.email}</p>}
                                            </div>
                                            <div className="form__input">
                                                <label htmlFor="" className="mb-[10px]">
                                                    Role
                                                </label>
                                                <div className="select">
                                                    <Field name="role" component="select">
                                                        <option value="" label="Select role">
                                                            Select role
                                                        </option>
                                                        {rolesArr.map((roleName: any, i: any) => (
                                                            <option key={i} value={roleName[0]} label={`${roleName[1]}`}>
                                                                {`${roleName[1]}`}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                    <span className="focus"></span>
                                                </div>
                                                {errors.role && <p className="errored">{errors.role}</p>}
                                            </div>
                                            {values.role === 'sales_rep' && (
                                                <>
                                                    {' '}
                                                    <p className="text-left text-jumbleng-gray">
                                                        Would you like to assign vendors to this sales rep?
                                                    </p>
                                                    <div role="group" aria-labelledby="my-radio-group" className="flex flex-col">
                                                        <label className="mb-4 mr-4 w-fit">
                                                            <Field
                                                                type="radio"
                                                                name="discount_type"
                                                                onChange={() => {
                                                                    setAssign(false);
                                                                }}
                                                                checked={assign === false}
                                                                value="all"
                                                                className={
                                                                    'checked:bg-jumbleng-primary mr-2 border-2 transition-all ease-in duration-200 border-white rounded-full shadow-[0_0_0_1px_#5E5BA7] w-3 h-3 bg-white  appearance-none'
                                                                }
                                                            />
                                                            No, i would assign later
                                                        </label>
                                                        <label className="mr-4 w-fit">
                                                            <Field
                                                                type="radio"
                                                                name="discount_type"
                                                                onChange={() => {
                                                                    setAssign(true);
                                                                }}
                                                                checked={assign === true}
                                                                value="selected"
                                                                className={
                                                                    'checked:bg-jumbleng-primary focus:outline-none  bg-[white] border-2 mr-2 transition-all ease-in duration-200 border-white rounded-full shadow-[0_0_0_1px_#5E5BA7] w-3 h-3 appearance-none'
                                                                }
                                                            />
                                                            Yes i would like to
                                                        </label>
                                                    </div>
                                                    {assign === true && (
                                                        <>
                                                            <div className="relative">
                                                                <SelectSearch
                                                                    options={[]}
                                                                    emptyMessage="No vendors found"
                                                                    className="select-search"
                                                                    renderOption={renderCustomer}
                                                                    getOptions={(_query) => {
                                                                        return new Promise((resolve, reject) => {
                                                                            axios
                                                                                .get(
                                                                                    `https://jumble-services-api.herokuapp.com/v1/vendors/customers?page=1&limit=10`,
                                                                                )
                                                                                .then(({ data }) => {
                                                                                    resolve(
                                                                                        data?.data?.map(({ company_id, name }: any) => ({
                                                                                            value: [company_id, name],
                                                                                            name: `${name}`,
                                                                                        })),
                                                                                    );
                                                                                })
                                                                                .catch(reject);
                                                                        });
                                                                    }}
                                                                    placeholder="Search Vendor"
                                                                    search
                                                                    onChange={(args: any) => {
                                                                        handleAddSalesRep(args);
                                                                    }}
                                                                />
                                                                <img src={Search} className="absolute left-0 top-0 mt-2 ml-2 z-50" />
                                                                <img src={ArrowDown} className="absolute right-0 top-0 mt-4 mr-12 z-50" />
                                                            </div>
                                                            <div className="flex gap-2 flex-wrap">
                                                                {salesReps.map((vendor: any, i: number) => (
                                                                    <div className="vendor__list" key={i}>
                                                                        <div className="name__mail">
                                                                            <p className="invite__name">{vendor.name}</p>
                                                                        </div>
                                                                        <div className="">
                                                                            <img
                                                                                src={Delete}
                                                                                alt=""
                                                                                onClick={() => removeRep(vendor.id)}
                                                                                className="w-2 cursor-pointer"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="add__another" onClick={submitForm}>
                                        <p>
                                            <span>+</span>Add another member
                                        </p>
                                    </div>

                                    <div className="actionsContainer">
                                        <button className="cancelBtn" onClick={() => setAddOpen(false)}>
                                            Cancel
                                        </button>

                                        {isLoading ? (
                                            <div className="loader__container">
                                                <div className="loader" />
                                            </div>
                                        ) : (
                                            <button className="sendBtn" onClick={() => sendInvite(invitees)}>
                                                {invitees.length > 0 ? 'Send invite' : 'Add a member'}
                                            </button>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    ) : (
                        <Formik
                            validationSchema={schemaCheck}
                            initialValues={toEdit}
                            enableReinitialize
                            onSubmit={(values, { resetForm }) => {
                                editForm(values), resetForm();
                            }}
                        >
                            {({ submitForm: submitEdit }) => (
                                <Form>
                                    <div className="modalContent">
                                        <div className="form__container">
                                            <div className="form__input">
                                                <label htmlFor="" className="mb-[10px]">
                                                    First Name
                                                </label>
                                                <Field name="first_name" type="first_name" placeholder="Input first name" />
                                                {/* {errors?.first_name && touched?.first_name && <p className="errored">{errors.first_name}</p>} */}
                                            </div>
                                            <div className="form__input">
                                                <label htmlFor="" className="mb-[10px]">
                                                    Last Name
                                                </label>
                                                <Field name="first_name" type="last_name" placeholder="Input last name" />
                                                {/* {errors?.first_name && touched?.first_name && <p className="errored">{errors.first_name}</p>} */}
                                            </div>
                                            <div className="form__input">
                                                <label htmlFor="" className="mb-[10px]">
                                                    Email Address
                                                </label>
                                                <Field name="email" type="email" placeholder="Input email address" />
                                                {/* {errors.email && touched.email && <p className="errored">{errors.email}</p>} */}
                                            </div>
                                            <div className="form__input">
                                                <label htmlFor="" className="mb-[10px]">
                                                    Role
                                                </label>
                                                <div className="select">
                                                    <Field name="role" component="select">
                                                        <option value="" label="Select role">
                                                            Select role
                                                        </option>
                                                        {rolesArr.map((roleName: any, i: any) => (
                                                            <option key={i} value={roleName[0]} label={`${roleName[1]}`}>
                                                                {`${roleName[1]}`}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                    <span className="focus"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-between px-8 mt-6">
                                        <button className="cancelBtn" onClick={() => setAddOpen(false)}>
                                            Cancel
                                        </button>
                                        <div
                                            className="text-white bg-jumbleng-primary flex items-center justify-center px-4 cursor-pointer rounded-xl"
                                            onClick={submitEdit}
                                        >
                                            Save Edit
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </div>
            </div>
        </Modaled>
    );
};

export default AddModal;
