import styled from 'styled-components';

export const Container = styled.div`
    .panel__selection {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 120px;
        align-items: center;
        width: 70%;

        .selected {
            padding: 20px;
            border: 1px solid #000;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 40%;

            h3 {
                color: #000;
                font-weight: 600;
                font-size: 18px;
            }

            p {
                color: #00000060;
                font-size: 12px;
            }
        }
        .unselected {
            align-items: center;
            padding: 20px;
            border: 1px solid #00000020;
            display: flex;
            justify-content: space-between;
            width: 40%;
            cursor: pointer;

            h3 {
                color: #00000050;
                font-weight: 600;
                font-size: 18px;
            }

            p {
                color: #00000030;
                font-size: 12px;
            }
        }
    }
`;
