import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';

import FileIcon from '../../assets/images/svg/stripedFile.svg';
import Button from '../../components/Button';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import { setComplianceScreen } from '../../redux/slices/global';
import { RootState } from '../../redux/store';

const Kyc = () => {
    const { complianceData } = useSelector((state: RootState) => state.globalReducer);
    const [fixIssue, setFixIssue] = useState(false);
    const dispatch = useDispatch();

    return (
        <div className="h-[calc(100vh-3.8rem)] flex items-center flex-col gap-4 justify-center mx-auto w-[542px]">
            <LoadingModal />
            <div className="flex items-center justify-center w-full gap-4">
                <h2 className="font-clash-display text-[28px] font-semibold">KYC Verification</h2>
                {complianceData.status === 'Re-Submitted' ? (
                    <p className=" flex items-center gap-2 text-sm bg-amber-100 text-[#D29C17] py-1 px-5 rounded-2xl h-[30px]">Resubmitted</p>
                ) : complianceData.status === 'Declined' ? (
                    <p className=" flex items-center gap-2 text-[#840B0B] text-sm bg-[#F5E7E7] py-1 px-5 rounded-2xl h-[30px]">Declined</p>
                ) : (
                    <p className=" flex items-center gap-2 text-sm bg-amber-100 text-[#D29C17] py-1 px-5 rounded-2xl h-[30px]">In Progress</p>
                )}{' '}
            </div>
            {complianceData.status === 'Re-Submitted' ? (
                <p className="text-center text-jumbleng-gray mt-6 mb-12">
                    Your KYC verification is currently under review after resubmitting. if after 24 hours your status still remains the same, kindly
                    contact support.{' '}
                </p>
            ) : complianceData.status === 'In Progress' ? (
                <p className="text-center text-jumbleng-gray mt-6 mb-12">
                    Your KYC verification is currently in progress. if after 24 hours your status still remains the same, kindly contact support.{' '}
                </p>
            ) : (
                !fixIssue && (
                    <p className="text-center text-jumbleng-gray mt-6 mb-12">
                        Some errors occured in your KYB Verification kindly fix these issues and reapply for verifiation.
                    </p>
                )
            )}
            {fixIssue && (
                <p className="text-center text-jumbleng-gray mt-6 mb-12">
                    We had some issues in approving your upload. To Continue, you will need to reupload previously submitted documents and resubmit.{' '}
                </p>
            )}
            {!fixIssue && (
                <div className="flex items-center justify-center gap-[46px]">
                    <Button
                        type={'submit'}
                        onClick={() => null}
                        message={'Contact Support'}
                        loading={false}
                        className={
                            'px-[1.56rem] md:py-[0.78125rem] py-[0.45rem] rounded-[0.585rem] bg-jumbleng-primary text-white border border-jumbleng-primary'
                        }
                    />
                    {complianceData.status === 'Declined' && (
                        <p className="text-[#840B0B] underline text-[16px] cursor-pointer" onClick={() => setFixIssue(true)}>
                            Fix issues
                        </p>
                    )}
                </div>
            )}

            {fixIssue &&
                complianceData?.declination_reason.declination_types?.map((item: any, index: number) => (
                    <div key={index} className="flex flex-col gap-4">
                        <div className="bg-[#F9F9FA] flex justify-between items-center p-5 border border-[#DFDEED] rounded-[6px] h-[88px] w-[516px]">
                            <div className="flex items-center justify-center gap-4">
                                <img src={FileIcon} alt="" />
                                <div>
                                    <p className="text-jumbleng-gray font-thin">File Upload</p>
                                    <p className="font-bold text-jumbleng-gray">{item}</p>
                                </div>
                            </div>
                            <Popup
                                trigger={
                                    <div
                                        onClick={() => dispatch(setComplianceScreen(true))}
                                        className=" flex items-center gap-2 cursor-pointer text-sm bg-amber-100 text-[#D29C17] py-1 px-5 rounded-2xl h-[40px]"
                                    >
                                        <p className="font-bold text-[16px]"> Action needed</p>
                                        <i className="fa-solid fa-triangle-exclamation"></i>{' '}
                                    </div>
                                }
                                closeOnDocumentClick
                                arrow={false}
                                on={'hover'}
                                contentStyle={{
                                    padding: '0.5rem',
                                    borderRadius: '10px',
                                    fontSize: '1rem',
                                    background: '#525171',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    boxShadow: 'none',
                                    color: 'white',
                                    width: '270px',
                                    textAlign: 'center',
                                }}
                            >
                                <div className="flex flex-col gap-4 items-start justify-center  mb-2">
                                    {complianceData?.declination_reason.reason}
                                </div>
                            </Popup>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default Kyc;
