import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';

import { ISetState } from '../../../../redux/features/interfaces';
import { myCatalogueDataType } from '../../../../redux/features/product/productRedux';
import { addProduct, removeProduct } from '../../../../redux/slices/global';
import { RootState } from '../../../../redux/store';
import { ProductQuery } from './ApplyDiscount';
import Productslist from './Productslist';

const AddorRemoveProduct = ({
    close,
    data,
    handleChange,
    query,
    setQuery,
}: {
    close: () => void;
    data: any;
    handleChange: (e: any) => void;
    query: ProductQuery;
    setQuery: ISetState<ProductQuery>;
}) => {
    const { products } = useSelector((state: RootState) => state.globalReducer);
    const dispatch = useDispatch();

    return (
        <Productslist close={close} data={data} handleChange={handleChange} query={query} setQuery={setQuery}>
            {data?.data.map((catalogue: myCatalogueDataType, index: number) => (
                <div className="grid grid-cols-6 gap-2 mt-4" key={index}>
                    <div className="text-sm flex gap-2 col-span-4  items-center">
                        <div className="grid content-center">
                            <input type="checkbox" />
                        </div>{' '}
                        <div>
                            <div>{catalogue.product.name}</div>
                        </div>
                    </div>
                    {products.includes(catalogue.id) ? (
                        <>
                            <div className={`grid content-center`}>
                                <div className="flex gap-1 text-[#5E5BA7] items-center">
                                    <i className="fa fa-check"></i>
                                    <p className="font-semibold  text-[16px]"> Added </p>
                                </div>
                            </div>

                            <div className="grid content-center cursor-pointer" onClick={() => dispatch(removeProduct(catalogue.id))}>
                                <div className="flex gap-1 text-jumbleng-red items-center">
                                    <i className="fa fa-xmark"></i>
                                    <p className="font-semibold text-[16px]"> Remove </p>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="col-span-2 flex justify-end ">
                            <div className="grid content-center cursor-pointer" onClick={() => dispatch(addProduct(catalogue.id))}>
                                <div className="flex items-center justify-center gap-1 py-[7px] px-[32px] border text-[#5E5BA7] hover:text-white rounded-xl border-jumbleng-primary hover:bg-jumbleng-primary">
                                    <Icon icon="icon-park-outline:ad-product" /> <p className="font-semibold  text-[16px] "> Add </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </Productslist>
    );
};

export default AddorRemoveProduct;
