import { FC } from 'react';
import { connect } from 'react-redux';

import Info from '../../assets/images/svg/info.svg';
import X from '../../assets/images/svg/x.svg';

interface PublishProductModalProps {
    showPublishProductModal: boolean;
    setShowPublishProductModal: any;
    selectedPublishProductId: string;
}

const PublishProductModal: FC<PublishProductModalProps> = (props) => {
    const { showPublishProductModal, setShowPublishProductModal } = props;
    if (!showPublishProductModal) {
        return <></>;
    }

    return (
        <div className="w-[43.875rem] px-16 rounded-xl bg-[#fff] absolute z-[56] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="flex justify-between mt-4" onClick={() => setShowPublishProductModal(false)}>
                <div />
                <img src={X} alt="cancel" className="hover:cursor-pointer" />
            </div>
            <h4 className="text-[#131221] font-black text-[28px] mb-4 text-center">Publish product</h4>
            <div className="flex items-center mb-[30px]">
                <span className="w-4 h-4 mr-2">
                    <img src={Info} alt="_info" className="w-full h-full object-cover" />
                </span>
                <p className="font-normal text-base text-jumbleng-gray">
                    Publishing a product allows other vendors to see the product on your catalogue
                </p>
            </div>
            <p className="font-normal text-base text-jumbleng-gray">Set selling price*</p>

            <div>
                <input type="text" placeholder="NGN 0.00" />
            </div>

            <h5 className="font-bold text-base text-jumbleng-gray mb-2">Set sales paremeters</h5>
            <p className="font-normal text-base text-jumbleng-gray mb-[34px]">
                Setting sales perameters for this product allows you to control who can see, add double pricing and purchase this product.
            </p>

            <p className="font-normal text-base text-jumbleng-gray">Who can see this product?</p>

            <div>
                <div className="flex items-center">
                    <input type="radio" name="non_connected_vendors" className="mr-[18px]" /> <span>Connected vendors</span>
                </div>
                <div className="flex items-center">
                    <input type="radio" name="non_connected_vendors" className="mr-[18px]" /> <span>Non connected vendors</span>
                </div>
                <div className="flex items-center">
                    <input type="radio" name="non_connected_vendors" className="mr-[18px]" /> <span>Location</span>
                </div>
                <div className="flex items-center">
                    <input type="radio" name="non_connected_vendors" className="mr-[18px]" /> <span>Group 1</span>
                </div>

                <div>
                    <p className="font-bold">+ set second selling price</p>
                    <p className="font-bold">+ Apply discounts</p>
                </div>

                {/*  EXPANDED */}
                {/* <div>
          <p>Set second selling price</p>
          <input  type="text" placeholder="NGN 00.0" />
          <p>Select who can see second selling price</p>
          <div> <input type="checkbox" />  <p>Connected vendors</p></div>
          <div> <input type="checkbox" />  <p>Non Connected vendors</p></div>
          <div> <input type="checkbox" />  <p>Location</p></div>
          <div className="grid grid-cols-2">
          <input  type="text" placeholder="Lagos" />
          <input  type="text" placeholder="Lagos Mainland" />
          </div>

          <div> <input type="checkbox" />  <p>  Company Size</p></div>
          <div className="grid grid-cols-2">
          <input  type="text" placeholder="0-2 staff" />

          <div>
            <p className="font-bold jumbleng-gray"> Groups</p>
            <div className="flex"><input type="checkbox" /> Group Name <p style={{marginLeft: "auto"}} > <p className="font-bold">33</p> members</p> </div>
            <div className="flex"><input type="checkbox" /> Group Name <p style={{marginLeft: "auto"}} > <p className="font-bold">12</p> members</p> </div>
            <div className="flex"><input type="checkbox" /> Group Name <p style={{marginLeft: "auto"}} > <p className="font-bold">33</p> members</p> </div>
          </div>
        
          </div>

        </div> */}

                <div className="flex justify-between mb-10 mt-8">
                    <button
                        className="font-semibold text-base border-2 rounded-lg py-4 px-16 border-jumbleng-primary text-jumbleng-primary"
                        onClick={() => setShowPublishProductModal(false)}
                    >
                        Cancel
                    </button>
                    <button className="font-semibold text-base border-2 rounded-lg py-4 px-[14px] border-jumbleng-primary text-white bg-jumbleng-primary">
                        Publish to catalogue
                    </button>
                </div>
            </div>
        </div>
    );
};

export default connect((state: any) => {
    const { selectedPublishProductId } = state.ProductReducer;
    return { selectedPublishProductId };
})(PublishProductModal);
