import { useState } from 'react';
import { useEffect } from 'react';
import Modal from 'react-modal';

import Loading from '../../../components/UI/Spinner/Loading';
import PermissionsGate from '../../../HOC/PermissionGate';
import { useGetMembersQuery, useGetRolesQuery } from '../../../redux/services/teamsApi';
import { SCOPES } from '../../../utils/permission';
import EmptySearch from '../../vendorlist/EmptyContent/EmptySearch';
import AddModal from './AddModal';
import DeleteModal from './DeleteModal';
import EditModal from './EditModal';
import { Container } from './styles';
import Table from './Table';

Modal.setAppElement('#root');

const Team = () => {
    const tableHeaders = ['Name', 'Role', 'Date Added', 'Last Active', 'Status', 'Action'];

    const [teamData, setTeamData] = useState<any[]>([]);
    const [ctaMemberId, setCtaMemberId] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pagesNo, setPagesNo] = useState(0);

    const [team, setTeam] = useState<any[]>([]);

    const { data, isLoading, isFetching, refetch } = useGetMembersQuery();
    const { data: roles } = useGetRolesQuery();

    useEffect(() => {
        setTeam(data?.data);
    }, [data, isFetching]);

    const triggerReturn = () => {
        setCurrentPage(1);
    };

    useEffect(() => {
        if (currentPage < 1) {
            setTimeout(triggerReturn, 1000);
        }
    }, [currentPage]);

    useEffect(() => {
        const filteredData = team?.filter(
            (name: any) =>
                name?.first_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
                name?.last_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()),
        );
        setTeamData(filteredData);
        setPagesNo(Math.ceil(filteredData?.length / 7));
    }, [searchTerm, team]);

    const [addOpen, setAddOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState();

    function goToNextPage() {
        setCurrentPage((page) => page + 1);
    }

    function goToPreviousPage() {
        setCurrentPage((page) => page - 1);
    }

    function changePage(event: any) {
        const pageNumber = Number(event.target.value);
        setCurrentPage(pageNumber);
    }

    const getPaginatedData = () => {
        const startIndex = currentPage * 7 - 7;
        const endIndex = startIndex + 7;
        return teamData?.slice(startIndex, endIndex);
    };

    // const getPaginationGroup = () => {
    //     let start = Math.floor((currentPage - 1) / 5) * 5;
    //     return new Array(5).fill(1).map((_, idx) => start + idx + 1);
    //     // return Array.from({ length: 5 }, (_, idx) => start + idx + 1);
    // };

    if (isLoading)
        return (
            <div className="min-h-[450px] flex justify-center flex-col">
                <Loading />
            </div>
        );

    return (
        <Container onClick={() => typeof ctaMemberId === 'number' && setCtaMemberId(null)}>
            <div className="team__header">
                <p>Manage your team members, their roles, responsibilties and permission here.</p>
                <PermissionsGate scopes={[SCOPES.can_manage_team_members]}>
                    <button
                        onClick={() => {
                            setAddOpen(true);
                            setCtaMemberId(null);
                        }}
                    >
                        <span>+</span> Add team member
                    </button>
                </PermissionsGate>
            </div>
            <div className="team__table__filt">
                <div className="team__length">
                    <span>{team?.length}</span> Team members found
                </div>
                <div className="team__cta">
                    <div className="cta__search">
                        <img src="https://www.svgrepo.com/show/50416/search.svg" alt="" />
                        <input type="text" placeholder="Search Team" onChange={(text) => setSearchTerm(text?.target?.value)} />
                    </div>
                    <div className="cta__filter">
                        <p>
                            Filter <img src="https://www.svgrepo.com/show/381200/sort-filter-filtering-filters.svg" alt="" />
                        </p>
                    </div>
                </div>
            </div>
            <Table
                theadData={tableHeaders}
                tbodyData={getPaginatedData}
                setEditOpen={setEditOpen}
                ctaMemberId={ctaMemberId}
                setCtaMemberId={setCtaMemberId}
                setDeleteOpen={setDeleteOpen}
                setSelectedUser={setSelectedUser}
            />
            {searchTerm && teamData?.length === 0 && <EmptySearch content={'team member'} />}
            {pagesNo > 1 && (
                <div className="pagination__section">
                    <div className="pagination__tab">
                        <p>Showing results page</p>
                        <div className="pagination__input">
                            <input type="text" onChange={changePage} value={currentPage} />
                        </div>
                        <p>of {pagesNo} pages</p>
                    </div>
                    {currentPage > 1 && (
                        <button onClick={goToPreviousPage} className={`${currentPage === 1 ? 'disabled' : 'prev'}`}>
                            Prev
                        </button>
                    )}
                    {currentPage === pagesNo && (
                        <button onClick={goToNextPage} className={`${currentPage === pagesNo ? 'disabled' : 'next'}`}>
                            <p>Next </p>
                        </button>
                    )}
                </div>
            )}
            {addOpen && <AddModal setAddOpen={setAddOpen} roles={roles} refetch={refetch} />}
            {editOpen && <EditModal setEditOpen={setEditOpen} selectedUser={selectedUser} roles={roles} refetch={refetch} />}
            {deleteOpen && <DeleteModal setDeleteOpen={setDeleteOpen} selectedUser={selectedUser} refetch={refetch} />}
        </Container>
    );
};

export default Team;
