import { ErrorMessage, Field, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import AuthNav from '../../../components/AuthNav/AuthNav';
import Button from '../../../components/Button';
import GuestRoute from '../../../HOC/GuestRoute';
import { IEmailValue } from '../../../interfaces';
import { forgotPassword } from '../../../redux/features/auth/authService';
import { Eaction } from '../../../redux/features/interfaces';
import { EmailSchema, initialEmailValue } from '../../../schema';

const ForgotPassword = () => {
    const [buttonLoad, setButtonLoad] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async (values: IEmailValue) => {
        try {
            setButtonLoad(true);
            const cleanedData = values.email.toLowerCase().trim();
            const response = await forgotPassword({ email: cleanedData });
            dispatch({ type: Eaction.setResetOrChangePassword, payload: 'reset' });
            toast.success(response?.message);
            navigate('/reset-password-done');
        } catch (err: any) {
            setError(err.response.data?.message);
            setButtonLoad(false);
        }
    };

    useEffect(() => {
        const timeId = setTimeout(() => {
            setError('');
        }, 3000);
        return () => {
            clearTimeout(timeId);
        };
    }, [error]);

    return (
        <GuestRoute>
            <div className="w-full bg-jumbleng-background min-h-screen">
                <AuthNav />
                <div className="w-full min-h-screen flex">
                    <div className="w-[17rem] mx-auto text-center">
                        <Formik onSubmit={handleSubmit} initialValues={initialEmailValue} validationSchema={EmailSchema}>
                            {({ handleSubmit }) => (
                                <>
                                    <h2 className="font-semibold mt-[3.12rem] mb-[1rem] text-[2.375rem] text-jumbleng-gray-main  w-[23.5rem]">
                                        Reset Password
                                    </h2>
                                    <p className="text-[0.78125] font-normal text-jumbleng-gray mb-[3.5rem] w-[23.5rem]">
                                        Enter your company email address used in creating account and we will send a reset link.
                                    </p>

                                    <div className="mb-[0.7rem] border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1] w-[23.5rem]">
                                        <div className="field__container">
                                            <i className={`fa-solid text-[20px] fa-envelope ${error ? 'text-red-500' : 'text-gray-500'}`}></i>
                                            <Field
                                                onKeyDown={(e: any) => {
                                                    e.key === 'Enter' && handleSubmit();
                                                }}
                                                type={'email'}
                                                name={'email'}
                                                placeholder={'example@example.com'}
                                                className={'w-[23.5rem] bg-jumbleng-background placeholder:text-[0.78125rem] focus:outline-none'}
                                            />
                                            {error && <i className="fa-solid fa-circle-exclamation text-[20px] text-red-500"></i>}
                                        </div>
                                    </div>
                                    <ErrorMessage
                                        name={'email'}
                                        render={(msg) => <div className="text-[0.7812rem] text-red-600 text-left font-normal">{msg}</div>}
                                    />
                                    {error && <div className="text-[0.7812rem] text-red-600 text-left font-normal">{error}</div>}
                                    <div className="flex items-center justify-center w-[23.5rem] gap-8 mt-12 ">
                                        <Link to="/" className="bg-tranparent">
                                            <Button
                                                type={'submit'}
                                                message={'Back to Login'}
                                                loading={false}
                                                className="w-[10.5rem] text-[0.78125rem] mx-auto h-[3.125rem] bg-transparent border border-jumbleng-primary text-jumbleng-primary rounded-lg"
                                            />
                                        </Link>
                                        <Button
                                            type={'submit'}
                                            onClick={handleSubmit}
                                            message={'Submit'}
                                            loading={buttonLoad}
                                            className="w-[10.5rem] text-[0.78125rem] mx-auto h-[3.125rem] bg-[#CFCFD1] text-white rounded-lg"
                                        />
                                    </div>
                                </>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </GuestRoute>
    );
};

export default ForgotPassword;
