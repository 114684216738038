import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EmptyContent from '../../components/EmptyContent/EmptyContent';
import Pagination from '../../components/Pagination/Pagination';
import InvoiceWrapper from '../../HOC/InvoiceWrapper';
import { initialInvoicesState } from '../../redux/features/dtos';
import { listCustomerInvoices } from '../../redux/features/invoice/InvoiceActions';
import {
    useGetAllInvoiceCountQuery,
    useGetCancelledInvoiceCountQuery,
    useGetCustomerInvoicesQuery,
    useGetPaidInvoiceCountQuery,
    useGetUnpaidInvoiceCountQuery,
} from '../../redux/services';
import { RootState } from '../../redux/store';
import { toLocaleFixed } from '../../utils/utils';

export type InvoiceQuery = {
    page: number;
    limit: number;
    status: string;
    search_query: string;
};

const CustomerInvoice = () => {
    const { data: total, isLoading: loadingtotal } = useGetAllInvoiceCountQuery('customers');
    const { data: unpaid, isLoading: loadingunpaid } = useGetUnpaidInvoiceCountQuery('customers');
    const { data: paid, isLoading: loadingpaid } = useGetPaidInvoiceCountQuery('customers');
    const { data: cancelled, isLoading: loadingcancelled } = useGetCancelledInvoiceCountQuery('customers');
    const { currentStatus } = useSelector((state: RootState) => state.listCustomerInvoicesReducer);
    const dispatch = useDispatch();
    const initialInvoiceQuery: InvoiceQuery = {
        page: 1,
        limit: 10,
        status: currentStatus,
        search_query: '',
    };
    const [query, setQuery] = useState<InvoiceQuery>(initialInvoiceQuery);
    const { data = initialInvoicesState, isFetching: isLoading } = useGetCustomerInvoicesQuery(query);

    const [customerActiveTab, setCustomerActiveTab] = useState<'' | 'paid' | 'unpaid' | 'cancelled'>(currentStatus);

    const toggleStatus = (status: '' | 'paid' | 'unpaid' | 'cancelled') => {
        setQuery({ ...query, status: status, page: 1 });
        dispatch(listCustomerInvoices(status));
        setCustomerActiveTab(status);
    };
    return (
        <div>
            <div className="mb-[1.5rem] flex justify-between items-center">
                <div className="flex text-[0.78125rem] text-jumbleng-gray gap-4">
                    <div
                        className={`${
                            customerActiveTab === '' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                        } flex gap-2  cursor-pointer border rounded-2xl p-1.5`}
                        onClick={() => toggleStatus('')}
                    >
                        All{' '}
                        <p
                            className={`px-2 ${
                                customerActiveTab === '' ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'
                            } border rounded-[33px]`}
                        >
                            {total?.count}
                        </p>{' '}
                    </div>
                    <div
                        className={`${
                            customerActiveTab === 'paid' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                        } flex gap-2 cursor-pointer  border  rounded-2xl p-1.5`}
                        onClick={() => toggleStatus('paid')}
                    >
                        Paid
                        <p
                            className={`px-2 ${
                                customerActiveTab === 'paid' ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'
                            }  border rounded-[33px]`}
                        >
                            {paid?.count}
                        </p>
                    </div>
                    <div
                        className={`${
                            customerActiveTab === 'unpaid' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                        }  flex gap-2 cursor-pointer  border  rounded-2xl p-1.5`}
                        onClick={() => toggleStatus('unpaid')}
                    >
                        Unpaid{' '}
                        <p
                            className={`px-2 ${
                                customerActiveTab === 'unpaid' ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'
                            } border rounded-[33px]`}
                        >
                            {unpaid?.count}
                        </p>
                    </div>
                    <div
                        className={`${
                            customerActiveTab === 'cancelled' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                        }  flex gap-2 cursor-pointer  border rounded-2xl p-1.5`}
                        onClick={() => toggleStatus('cancelled')}
                    >
                        Cancelled{' '}
                        <p
                            className={`px-2 ${
                                customerActiveTab === 'cancelled' ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'
                            }  border rounded-[33px]`}
                        >
                            {cancelled?.count}
                        </p>
                    </div>
                </div>
                <div className="hidden md:flex items-center relative gap-4">
                    {data?.total_order_value !== 0 && (
                        <div className="hidden md:flex items-center relative ">
                            <div className="h-[2.5rem] relative border-2 bg-jumbleng-gray text-white px-2 rounded-md my-auto font-bold text-[24px]">
                                &#8358;{toLocaleFixed(data?.total_order_value, 2)}
                            </div>
                        </div>
                    )}
                    <div className="w-[7.7rem] h-[2.3rem] relative mr-[45px]">
                        <i className="fa fa-search icon absolute top-1/2 -translate-y-1/2 px-[0.79rem]" />
                        <input
                            className="w-full bg-[#F8F8FB] border-b-2 focus:outline-none pl-[2.08rem] h-full placeholder:text-[0.78165rem] text-[0.78125rem] text-jumbleng-gray placeholder:text-jumbleng-gray"
                            placeholder="Search order nums,vendors"
                        />
                    </div>
                </div>
            </div>

            <div className=" p-5 mt-5">
                <div className={`grid grid-cols-7 pt-4 bg-white p-5   font-bold`}>
                    <div className="jumbleng-gray-main text-base grid justify-center ">Order date</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Ordering Company</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Invoice number</div>
                    <div className="jumbleng-gray-main text-base grid justify-center"> Date Issued</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Order Value</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Status</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Action</div>
                </div>
                <InvoiceWrapper
                    data={data?.data}
                    loading={isLoading || loadingtotal || loadingcancelled || loadingpaid || loadingunpaid}
                    currentStatus={currentStatus}
                >
                    {data?.count === 0 ? (
                        <EmptyContent content={currentStatus} invoice={true} />
                    ) : (
                        <Pagination count={data?.count} query={query} setQuery={setQuery} />
                    )}
                </InvoiceWrapper>{' '}
            </div>
        </div>
    );
};

export default CustomerInvoice;
