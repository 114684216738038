import LockIcon from '../../assets/images/svg/lock.svg';

const PrivateVendorCatalogue = () => {
    return (
        <div className="mt-8">
            <div className="bg-white py-[80px] grid justify-center">
                <div className="grid justify-center">
                    <img src={LockIcon} alt="lockicon" />
                </div>
                <p className="text-center">This account is private</p>
                <p className="text-center">Send a request to view this vendor’s catalogue</p>
            </div>
        </div>
    );
};

export default PrivateVendorCatalogue;
