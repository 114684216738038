import { FC } from 'react';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Popup from 'reactjs-popup';

import ArrowDown from '../../../assets/images/svg/arrow-down.svg';
import EmptyContent from '../../../components/EmptyContent/EmptyContent';
import PopUpModal from '../../../components/PopModal/PopUpModal';
import { OrderItemsType } from '../../../redux/features/interfaces';
import { companyType } from '../../../redux/features/interfaces';
import { toLocaleFixed, transformedDateFull } from '../../../utils/utils';
import OrderItemDetails from '../../order/OrderItemDetails';

interface CProps {
    id: string;
    order_no: string;
    name?: string;
    address?: string;
    phone?: string;
    close: () => void;
    reload?: boolean;
    ishome?: boolean;
    currentStatus?: string;
    ordering_company: companyType;
    vendor: companyType;
    created_at?: string;
    invoice_no?: string;
    status?: string;
    order_items: OrderItemsType[];
    invoice_id?: string;
    iscustomer?: boolean;
    invoice_issued?: boolean;
    isInvoicePage?: boolean;
}

const ViewInvoice: FC<CProps> = ({
    id,
    order_items,
    isInvoicePage,
    invoice_issued,
    ordering_company,
    invoice_no,
    created_at,
    vendor: company,
    iscustomer,
    order_no,
    ishome,
    name,
    address,
    phone,
}) => {
    const componentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return iscustomer || invoice_issued ? (
        <div className="py-2 overflow-y-auto overflow-x-hidden scrollbar h-[42rem]">
            <div ref={componentRef}>
                <div className="flex items-start justify-between  mt-4 mx-4 p-4">
                    <div className="flex gap-32 items-start">
                        <h3 className="font-semibold text-[28px]">{ishome ? company.name : name}</h3>
                        <div className="flex flex-col gap-1 w-[9rem]">
                            <div className="text-[#131221] text-[16px]">{ishome ? company.address : address}</div>
                            <div className="text-[14px] ">{ishome ? company.phone_number : phone}</div>
                        </div>
                    </div>
                    <div className="flex gap-4 flex-col">
                        <div className="flex flex-col gap-1">
                            <h4 className="font-semibold text-[20px]">Invoice</h4>
                            <div className="text-[14px] text-[#706F77]">INV-{invoice_no}</div>
                        </div>
                        <div className="flex flex-col gap-1">
                            <div className="text-[14px] text-[#706F77]">Date</div>
                            <div className="text-[#131221] text-[16px] font-bold">{transformedDateFull(created_at)}</div>
                        </div>
                    </div>
                </div>
                <hr className="mt-4 mb-4" />
                <div className="flex items-start justify-between mx-4 p-4">
                    <div className="flex flex-col gap-2 ">
                        <div className=" text-[16px] text-[#706F77]">Invoice to:</div>
                        <div className="text-[#131221] text-[16px]">
                            <div className="font-bold">{ordering_company.name}</div>
                            <div className="text-[#131221] text-[16px]">{ordering_company.address}</div>
                        </div>
                        <div className="text-[16px] text-[#706F77]">{ordering_company.phone_number}</div>
                    </div>
                    <div className="flex gap-24">
                        <div className="flex flex-col gap-2">
                            <div>
                                <div className="text-[#131221] text-[14px]">Subtotal</div>
                                <div className="font-semibold  text-[16px]">
                                    N
                                    {toLocaleFixed(
                                        order_items.reduce((total: number, item: OrderItemsType) => item.sub_total + total, 0),
                                        2,
                                    )}
                                </div>
                            </div>
                            <div>
                                <div className="text-[#131221] text-[14px]">Total VAT</div>
                                <div className="font-semibold  text-[16px] ">
                                    N
                                    {toLocaleFixed(
                                        order_items.reduce((total: number, item: OrderItemsType) => item.vat_amount + total, 0),
                                        2,
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="text-[#131221] text-[16px]">Total</div>
                            <h3 className="text-[28px] font-black">
                                N{' '}
                                {toLocaleFixed(
                                    order_items.reduce((total: number, item: OrderItemsType) => item.total + total, 0),
                                    2,
                                )}
                            </h3>
                        </div>
                    </div>
                </div>

                <div className=" mt-5">
                    <div className="grid grid-cols-8   pt-4 text-white bg-jumbleng-primary  hover:bg-[#5D5BA7] p-5 font-bold items-center">
                        <div className="jumbleng-gray-main text-sm grid  col-span-1">SKU</div>
                        <div className="jumbleng-gray-main text-sm grid  col-span-2">Items Ordered</div>
                        <div className="jumbleng-gray-main text-sm grid  col-span-1">Quantity</div>
                        <div className="jumbleng-gray-main text-sm grid  col-span-1"> UOM</div>
                        <div className="jumbleng-gray-main text-sm grid  col-span-1">Prices</div>
                        <div className="jumbleng-gray-main text-sm grid  col-span-1">
                            <div className="flex gap-2 items-center">
                                <p> VAT</p>
                                <Popup
                                    trigger={<img src={ArrowDown} alt="a-down" />}
                                    on="hover"
                                    closeOnDocumentClick
                                    arrow={false}
                                    contentStyle={{
                                        padding: '10px',
                                        borderRadius: '12px',
                                        width: '167px',
                                    }}
                                >
                                    <div className="text-jumbleng-primary">
                                        <div className=" grid justify-center gap-4">
                                            <div className=" flex gap-2 justify-start">
                                                <div>%</div>
                                                <div>Percentage</div>
                                            </div>
                                            <div className=" flex gap-2 justify-start">
                                                <div>&#8358;</div>
                                                <div>Currency</div>
                                            </div>
                                        </div>
                                    </div>
                                </Popup>
                            </div>
                        </div>
                        <div className="jumbleng-gray-main text-sm grid col-span-1 w-[150px]">Total Order Value</div>
                    </div>
                    {order_items.map((item: OrderItemsType, index: number) => (
                        <div
                            className={`grid grid-cols-8 pt-4  p-5 gap-1 text-jumbleng-primary cursor-pointer hover:bg-[#5D5BA7] hover:text-white  items-center ${
                                index % 2 == 0 ? 'bg-[#fff]' : 'bg-[#F9F9FA]'
                            } `}
                            key={item.id}
                        >
                            <div className="text-sm grid col-span-1">
                                <div className="flex gap-2  items-center ">
                                    <p>
                                        <span> {item.catalogue.product.sku}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="text-sm grid col-span-2">
                                <PopUpModal
                                    content={<span className="truncate w-[190px]">{item.catalogue.product.name}</span>}
                                    className="text-sm grid "
                                    popupcontent={true}
                                >
                                    {(close: () => void) => (
                                        <div>
                                            <button className="close" onClick={close}>
                                                &times;
                                            </button>
                                            <OrderItemDetails
                                                Props={{
                                                    created_at: item.created_at,
                                                    order_no: order_no,
                                                    iscustomer: iscustomer,
                                                    id: id,
                                                    order_items: order_items,
                                                    qty: item.qty,
                                                    product: item.catalogue.product,
                                                    images: item.catalogue.product.images,
                                                    picture: item.catalogue.product.picture,
                                                    ordering_company: ordering_company,
                                                }}
                                            />
                                        </div>
                                    )}
                                </PopUpModal>
                            </div>
                            <div className="text-sm grid col-span-1 ">{item.qty.toLocaleString()}</div>
                            <div className="text-sm grid col-span-1 ">{item.catalogue.product.uom}</div>
                            <div className="text-sm grid col-span-1 ">₦ {toLocaleFixed(item.buying_price, 2)}</div>
                            <div className={`text-sm grid col-span-1 ${!item.catalogue.product.vat && 'ml-3 '}`}>
                                {item.vat_percent ? item.vat_percent + '%' : item.catalogue.product.vat ? item.catalogue.product.vat + '%' : '-'}
                            </div>
                            <div className="text-sm grid col-span-1 ">₦ {toLocaleFixed(item.qty * Number(item.buying_price), 2)}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full flex items-center justify-center gap-6 mt-8">
                <div
                    className={`flex rounded-xl cursor-pointer items-center justify-center ${
                        isInvoicePage ? ' bg-jumbleng-primary text-white' : 'border border-jumbleng-primary  text-jumbleng-primary '
                    } px-[10px] py-[12px] w-[9rem]`}
                >
                    <div className="font-semibold text-[0.78125rem]" onClick={handlePrint}>
                        <i className="fa-solid fa-print mr-2"></i>Print invoice
                    </div>
                </div>
                {/* {iscustomer && !isInvoicePage && (
                    <div className="flex rounded-xl cursor-pointer items-center justify-center bg-jumbleng-primary px-[10px] py-[12px] w-[9rem]" onClick={() => handleViewInvoice(id, close)}>
                        {loading ? (
                            <div className="flex items-center justify-center w-full">
                                <Spinner />
                            </div>
                        ) : (
                            <div className={`font-semibold text-[0.78125rem] text-white`}>
                                <i className="fa-solid fa-file mr-2"></i>Send invoice
                            </div>
                        )}
                    </div>
                )} */}
            </div>
        </div>
    ) : (
        <EmptyContent content={`Invoice`} />
    );
};

export default ViewInvoice;
