import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';

export default function setupAxios(axios: AxiosInstance, store: any) {
    axios.interceptors.request.use(
        (config: AxiosRequestConfig) => {
            const {
                authStore: { authorization },
            } = store.getState();
            if (!isEmpty(authorization.access_token)) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                config.headers!.Authorization = `Bearer ${authorization.access_token}`;
            }
            return config;
        },
        (err: AxiosError): Promise<AxiosError> => Promise.reject(err),
    );
    axios.interceptors.response.use(
        (response: AxiosResponse): AxiosResponse => {
            return response;
        },
        (error: any) => {
            const {
                authStore: { authorization },
            } = store.getState();
            if (!isEmpty(error.response)) {
                if (error.response.status === 401 && !isEmpty(authorization.access_token)) {
                    //log user out if token expires
                    localStorage.clear();
                    sessionStorage.clear();
                    location.replace('/');
                }
            }
            return Promise.reject(error);
        },
    );
}
