import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { ProductData, StoreData } from '../features/interfaces';
import { RootState } from '../store';
interface CustomError {
    data: {
        code: number;
        error: any;
        message: string;
        status: boolean;
    };
    status: number;
}

type StoreDataResponse = {
    data: StoreData;
    code: number;
    status: boolean;
    message: string;
};

type ProductDataResponse = {
    data: ProductData;
    code: number;
    status: boolean;
    message: string;
};
const { REACT_APP_BACKEND_API } = process.env;
const access_token = localStorage?.getItem('token');

const requestHeaders = {
    Authorization: `Bearer ${JSON.parse(String(access_token))}`,
};

const createRequest = (url: any) => ({ url, headers: requestHeaders });

const baseUrl = `${REACT_APP_BACKEND_API}/v1/`;

export const storeApi = createApi({
    reducerPath: 'storeApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).authStore.authorization.access_token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, unknown>>,
    endpoints: (builder) => ({
        getStore: builder.query<StoreDataResponse, void>({
            query: () => createRequest(`storefront/accounts/companyAccount`),
        }),
        createStore: builder.mutation({
            query: (values) => {
                return {
                    url: 'storefront/accounts/createCompanyAccount',
                    method: 'post',
                    body: values,
                };
            },
        }),
        getProducts: builder.query<any, void>({
            query: () => {
                return {
                    url: 'catalogue',
                    method: 'get',
                };
            },
        }),
        getSingleProduct: builder.query<any, void>({
            query: (product_id) => {
                return {
                    url: `catalogue/${product_id}`,
                    method: 'get',
                };
            },
        }),
        getStoreProducts: builder.query<ProductDataResponse, void>({
            query: () => {
                return {
                    url: 'storefront/products',
                    method: 'get',
                };
            },
        }),
        updateStoreFront: builder.mutation({
            query: (values) => {
                return {
                    url: `storefront/accounts/companyAccount`,
                    method: 'put',
                    body: values,
                };
            },
        }),
        //incorrect endpoint
        getStoreOrders: builder.query<any, void>({
            query: () => {
                return {
                    url: `orders`,
                    method: 'get',
                };
            },
        }),
    }),
});

export const {
    useGetStoreQuery,
    useGetProductsQuery,
    useCreateStoreMutation,
    useGetStoreProductsQuery,
    useGetSingleProductQuery,
    useUpdateStoreFrontMutation,
    useGetStoreOrdersQuery,
} = storeApi;
