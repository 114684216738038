/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import axios from 'axios';
import { Field, Form, Formik, FormikValues, useFormikContext } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemState,
} from 'react-accessible-accordion';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import CaretDown from '../../assets/images/svg/caret-down.svg';
import CaretUp from '../../assets/images/svg/caret-up.svg';
import FB from '../../assets/images/svg/facebook-icon.svg';
import FileIcon from '../../assets/images/svg/file-icon.svg';
import Goto from '../../assets/images/svg/goto.svg';
import IG from '../../assets/images/svg/ig-logo.svg';
import Search from '../../assets/images/svg/search.svg';
import Send from '../../assets/images/svg/sendWhite.svg';
import Shopping from '../../assets/images/svg/shopping.svg';
import WH from '../../assets/images/svg/whatsapp-icon.svg';
import { ProductData } from '../../redux/features/interfaces';
import { useGetStoreProductsQuery, useGetStoreQuery, useUpdateStoreFrontMutation } from '../../redux/services/storeApi';
import Container from './styles';

const schemaCheck = yup.object().shape({
    description: yup.string(),
    brand_logo: yup.string(),
    email: yup.string().email(),
    whatsapp: yup.string(),
    instagram: yup.string(),
    facebook: yup.string(),
});

const StorePreview = () => {
    const [tab, setTab] = useState('home');
    const { data } = useGetStoreQuery();
    const submitRef = useRef<any>();
    const [selectedFile, setSelectedFile] = useState();

    const onSelectFile = async (event: any, setFieldValue: any) => {
        const instance = axios.create();
        delete instance.defaults.headers.common.authorization;
        const imgData = new FormData();
        imgData.append('file', event.target.files![0]);
        imgData.append('upload_preset', 'jumbleng-test');
        await instance.post('https://api.cloudinary.com/v1_1/dduma7jcf/image/upload', imgData).then(({ data }) => {
            setFieldValue('brand_logo', data.secure_url);
        });
        if (!event.target.files || event.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }
        setSelectedFile(event.target.files[0]);
    };

    const [updateStore, { isLoading: updateLoading, isSuccess: updateSuccess, isError: isUpdateError, error: updateError }] =
        useUpdateStoreFrontMutation();
    const { data: respData, isSuccess: isGetProductSuccessful } = useGetStoreProductsQuery();
    const storeProducts = useMemo(() => (isGetProductSuccessful ? respData.data.products : []), [isGetProductSuccessful, respData]);

    const [pickedLogo, setPickedLogo] = useState<any>();
    const storeName = pickedLogo?.name;
    const resp = useGetStoreQuery();
    const { data: store, isSuccess } = resp;
    const storeData = (store && store.data) || {
        store_name: '',
        description: '',
        brand_logo: '',
        store_link: '',
        contact_info: { email: '', phone_number: [''] },
        social_link: { whatsapp: ['s'], instagram: '', facebook: '', twitter: '' },
    };
    const initialValues = {
        description: storeData?.description,
        brand_logo: storeData?.brand_logo,
        email: storeData?.contact_info?.email[0],
        whatsapp: storeData?.social_link?.whatsapp[0],
        instagram: storeData?.social_link?.instagram,
        facebook: storeData?.social_link?.facebook,
    };

    const publishEdit = () => {
        if (submitRef.current) {
            submitRef.current.handleSubmit();
        }
    };

    const doSubmit = async (values: any) => {
        const updatedData = {
            store_name: storeData?.store_name,
            brand_logo: values?.brand_logo,
            description: values?.description,
            store_link:
                storeData.store_name.split(' ').join('').toLowerCase() + `.jumbleng.com.ng` ||
                storeData.store_name.split('-').join('').toLowerCase() + `.jumbleng.com.ng` ||
                storeData.store_name.split('_').join('').toLowerCase() + `.jumbleng.com.ng`,
            contact_info: {
                email: [values?.email],
                phone_number: [storeData.contact_info.phone_number[0]],
            },
            preference: {
                layout: 'cannot_be_blank',
                theme: 'cannot_be_blank',
            },
            social_link: {
                whatsapp: [values?.whatsapp],
                facebook: values?.facebook,
                instagram: values?.instagram,
                twitter: storeData?.social_link?.twitter,
            },
        };
        updateStore(updatedData);
    };

    useEffect(() => {
        if (updateSuccess) {
            toast.success('Store Successfully Created');
            window.location.reload();
        }
        if (isUpdateError && updateError && 'status' in updateError) {
            toast.error(updateError?.data?.message?.replaceAll('_', ' ')?.replaceAll(':', ' '));
        }
    }, [updateSuccess, isUpdateError, updateError]);

    return (
        <Container>
            <div className="preview__left">
                <div className="page__header">
                    <p className="page__title">
                        <img src={Goto} alt="" />
                    </p>
                    {updateLoading ? (
                        <div className="loader__container">
                            <div className="loader" />
                        </div>
                    ) : (
                        <button className="page__cta" onClick={publishEdit} form="publish">
                            <p>Publish</p>
                        </button>
                    )}
                </div>
                <div className="page__preview">
                    <div className="preview__header">
                        <div className="preview__header__left">
                            <div className="store__name">
                                <img src={storeData?.brand_logo} alt="" width={30} />
                                <p>{storeData?.store_name}</p>
                            </div>
                            <div className="store__tabs">
                                <p className={tab === 'home' ? 'active' : 'disabled'} onClick={() => setTab('home')}>
                                    home
                                </p>
                                <p className={tab === 'contact' ? 'active' : 'disabled'} onClick={() => setTab('contact')}>
                                    contact us
                                </p>
                                <p className={tab === 'other' ? 'active' : 'disabled'} onClick={() => setTab('other')}>
                                    new arrivals
                                </p>
                            </div>
                        </div>
                        <div className="preview__header__right">
                            <img src={Shopping} alt="" />
                        </div>
                    </div>
                    <div className="preview__body">
                        {tab === 'home' && (
                            <>
                                <div className="search">
                                    <img src={Search} alt="" />
                                    <input type="text" placeholder="Search Products" />
                                </div>
                                <div className="list__item">
                                    <div className="item__grid">
                                        {storeProducts?.map((item: any) => (
                                            <div className="item__container" key={item.id}>
                                                <div className="item__img">
                                                    <img src={item.image} alt="" />
                                                </div>
                                                <p>{item.name}</p>
                                                <h3>NGN {item.price}</h3>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                        {tab === 'contact' && (
                            <>
                                <h1 className="contact__header">Contact Us</h1>
                                <div className="form__section">
                                    <Formik
                                        validationSchema={schemaCheck}
                                        initialValues={initialValues}
                                        enableReinitialize
                                        onSubmit={(values) => {
                                            // doSubmit(values);
                                        }}
                                    >
                                        {({ submitForm }) => (
                                            <Form>
                                                <div className="form__container__grid">
                                                    <div className="form__input">
                                                        <label htmlFor="">Name</label>
                                                        <Field name="name" type="text" placeholder="" />
                                                        {/* {errors.name && touched.name && <p className="errored">{errors.name}</p>} */}
                                                    </div>
                                                    <div className="form__input">
                                                        <label htmlFor="">Email Address</label>
                                                        <Field name="email" type="email" placeholder="" />
                                                        {/* {errors.email && touched.email && <p className="errored">{errors.email}</p>} */}
                                                    </div>
                                                </div>
                                                <div className="form__container">
                                                    <div className="form__input__bottom">
                                                        <label htmlFor="">Your Message</label>
                                                        <Field component="textarea" name="message" type="text" placeholder="" />
                                                        <div className="form__cta">
                                                            <button onClick={submitForm}>
                                                                Send message <img src={Send} alt="" />
                                                            </button>
                                                        </div>

                                                        {/* {errors.message && touched.message && <p className="errored">{errors.message}</p>} */}
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>

                                <div className="socials__section">
                                    <p>or reach us via</p>

                                    <div className="links">
                                        {storeData?.social_link?.facebook?.length && (
                                            <a href={`https://${storeData?.social_link?.facebook}`} target="_blank" rel="noreferrer">
                                                <img src={FB} alt="" />
                                            </a>
                                        )}
                                        {storeData?.social_link?.whatsapp?.length && (
                                            <a
                                                href={`https://wa.me/${storeData?.social_link?.whatsapp[0]}?text=Hi%2C%20i%20saw%20your%20product%20on%20Jumble%F0%9F%99%82`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img src={WH} alt="" />
                                            </a>
                                        )}
                                        {storeData?.social_link?.instagram?.length && (
                                            <a href={`https://${storeData?.social_link?.instagram}`} target="_blank" rel="noreferrer">
                                                <img src={IG} alt="" />
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="preview__right">
                <h1 className="right__header">Customization</h1>
                <Formik
                    validationSchema={schemaCheck}
                    initialValues={initialValues}
                    innerRef={submitRef}
                    enableReinitialize
                    onSubmit={(values) => {
                        doSubmit(values);
                    }}
                    validateOnMount
                >
                    {({ handleChange, handleBlur, setFieldValue, errors, touched, submitForm }) => (
                        <Form>
                            <Accordion allowZeroExpanded className="accordion__container">
                                {tab === 'home' && (
                                    <AccordionItem className="option__container">
                                        <AccordionItemState>
                                            {({ expanded }) =>
                                                !expanded ? (
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton className="option__trigger">
                                                            <p>General</p>

                                                            <img src={CaretUp} alt="" width="9px" />
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                ) : (
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton className="option__trigger">
                                                            <p>General</p>

                                                            <img src={CaretDown} alt="" width="9px" />
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                )
                                            }
                                        </AccordionItemState>
                                        <AccordionItemPanel className="option__content">
                                            <div className="form__container">
                                                <div className="form__input">
                                                    <div className="field__label">
                                                        <p>
                                                            Description<sup>*</sup>
                                                        </p>
                                                    </div>
                                                    <Field
                                                        name="description"
                                                        component="textarea"
                                                        placeholder="Brand Description"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                    <p className="field__exp">Give your storefront a short and clear description to improve SEO</p>
                                                    {errors.description && touched.description && <p className="errored">{errors.description}</p>}
                                                </div>
                                                <div className="form__input">
                                                    <div className="field__label">
                                                        <p>
                                                            Brand Logo<sup>*</sup>
                                                        </p>
                                                    </div>
                                                    <div className="upload__container">
                                                        <label htmlFor="upload" className="file__label">
                                                            <input
                                                                id="upload"
                                                                name="brand_logo"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(event) => {
                                                                    if (!event.currentTarget.files) return;
                                                                    setFieldValue('brand_logo', event?.currentTarget.files[0]);
                                                                    onSelectFile(event, setFieldValue);
                                                                    setPickedLogo(event?.currentTarget.files![0]);
                                                                }}
                                                                onBlur={handleBlur}
                                                            />
                                                            <img src={FileIcon} alt="" />

                                                            <p>{storeName?.length ? storeName : 'Drop your images here or click to browse'}</p>
                                                        </label>
                                                    </div>

                                                    {errors.brand_logo && touched.brand_logo && <p className="errored">{errors.brand_logo}</p>}
                                                </div>
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                )}
                                {tab === 'contact' && (
                                    <>
                                        <AccordionItem className="option__container">
                                            <AccordionItemState>
                                                {({ expanded }) =>
                                                    !expanded ? (
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton className="option__trigger">
                                                                <p>Contact Email</p>

                                                                <img src={CaretUp} alt="" width="9px" />
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                    ) : (
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton className="option__trigger">
                                                                <p>Contact Email</p>

                                                                <img src={CaretDown} alt="" width="9px" />
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                    )
                                                }
                                            </AccordionItemState>
                                            <AccordionItemPanel className="option__content">
                                                <div className="form__container">
                                                    <div className="form__input">
                                                        <div className="field__label">
                                                            <p>
                                                                Contact Email Address<sup>*</sup>
                                                            </p>
                                                        </div>
                                                        <input
                                                            id="email"
                                                            name="email"
                                                            placeholder="Email"
                                                            type="email"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.email && touched.email && <p className="errored">{errors.email}</p>}
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem className="option__container">
                                            <AccordionItemState>
                                                {({ expanded }) =>
                                                    !expanded ? (
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton className="option__trigger">
                                                                <p>Social Links</p>

                                                                <img src={CaretUp} alt="" width="9px" />
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                    ) : (
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton className="option__trigger">
                                                                <p>Social Links</p>

                                                                <img src={CaretDown} alt="" width="9px" />
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                    )
                                                }
                                            </AccordionItemState>
                                            <AccordionItemPanel className="option__content">
                                                <div className="form__container">
                                                    <div className="form__input2">
                                                        <img src={WH} alt="" />
                                                        <Field
                                                            name="whatsapp"
                                                            placeholder="Whatsapp Number"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.whatsapp && touched.whatsapp && <p className="errored">{errors.whatsapp}</p>}
                                                    </div>
                                                    <div className="form__input2">
                                                        <img src={FB} alt="" />
                                                        <Field
                                                            name="facebook"
                                                            placeholder="Facebook Url"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.facebook && touched.facebook && <p className="errored">{errors.facebook}</p>}
                                                    </div>
                                                    <div className="form__input2">
                                                        <img src={IG} alt="" />
                                                        <Field
                                                            name="instagram"
                                                            placeholder="Instagram Url"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.instagram && touched.instagram && <p className="errored">{errors.instagram}</p>}
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </>
                                )}
                            </Accordion>
                        </Form>
                    )}
                </Formik>
            </div>
        </Container>
    );
};

export default StorePreview;
