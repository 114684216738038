import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { notificationContext } from '../pages/Notification/interface';
import { useGetNotificationQuery } from '../redux/services';
import {
    incrementCount,
    incrementUnreadInvoice,
    incrementUnReadOrder,
    incrementUnreadPayment,
    retrieveNotification,
    setNotificationId,
    setUnreadCount,
    setUnreadInvoice,
    setUnreadOrder,
    setUnreadPayment,
    toggleNotificationStatusTab,
    toggleNotificationTab,
} from '../redux/slices/notifications';
import { RootState } from '../redux/store';

const useGetCount = () => {
    const dispatch = useDispatch();

    const { unreadCount, newNotification, notificationId, unreadOrder, unreadInvoice, unreadPayment } = useSelector(
        (state: RootState) => state.notificationReducer,
    );
    const { data: unread } = useGetNotificationQuery(notificationContext('all', false), { refetchOnMountOrArgChange: true });
    const { data: unreadorder } = useGetNotificationQuery(notificationContext('order', false), { refetchOnMountOrArgChange: true });
    const { data: unreadinvoice } = useGetNotificationQuery(notificationContext('invoice', false), { refetchOnMountOrArgChange: true });
    const { data: unreadpayment } = useGetNotificationQuery(notificationContext('payment', false), { refetchOnMountOrArgChange: true });

    useEffect(() => {
        dispatch(toggleNotificationTab('all'));
        dispatch(toggleNotificationStatusTab('all'));
        dispatch(retrieveNotification({ page: 1, limit: 10 }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setUnreadCount(unread));
        dispatch(setUnreadOrder(unreadorder));
        dispatch(setUnreadInvoice(unreadinvoice));
        dispatch(setUnreadPayment(unreadpayment));
    }, [dispatch, unreadorder, unread, unreadinvoice, unreadpayment]);

    useEffect(() => {
        if (newNotification !== null && newNotification?.context_data?.id?.toLowerCase() !== notificationId) {
            if (newNotification?.context_data?.context?.toLowerCase() === 'order') {
                dispatch(incrementUnReadOrder());
            } else if (newNotification?.context_data?.context?.toLowerCase() === 'invoice') {
                dispatch(incrementUnreadInvoice());
            } else if (newNotification?.context_data?.context?.toLowerCase() === 'payment') {
                dispatch(incrementUnreadPayment());
            }
            dispatch(incrementCount());
            dispatch(setNotificationId(newNotification?.context_data?.id?.toLowerCase()));
        }
    }, [dispatch, newNotification, notificationId]);

    return { unreadCount, unreadOrder, unreadInvoice, unreadPayment };
};

export default useGetCount;
