import { useState } from 'react';
import { useSelector } from 'react-redux';

import check from '../../assets/images/svg/check.svg';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import Loading from '../../components/UI/Spinner/Loading';
import { useGetOwnerIdTypesQuery, useGetUtilityBillTypesQuery, useGetValidateComplianceQuery } from '../../redux/services/complianceApi';
import { RootState } from '../../redux/store';
import Form1 from './compliance/Form1';
import Form2 from './compliance/Form2';
import Form3 from './compliance/Form3';
import Review from './compliance/Review';

const Compliance = () => {
    const [step, setStep] = useState<number>(1);
    const { complianceData } = useSelector((state: RootState) => state.globalReducer);
    const { data: validation, isLoading: isloading } = useGetValidateComplianceQuery();
    const { data: utilityTypes, isFetching } = useGetUtilityBillTypesQuery();
    const { data: IDTypes, isLoading: Loader } = useGetOwnerIdTypesQuery();

    if (isFetching || Loader || isloading)
        return (
            <div className="min-h-[450px] flex justify-center flex-col">
                <Loading />
            </div>
        );

    return (
        <div className="pb-12 px-16">
            <LoadingModal />
            <h1 className="font-semibold text-[21px] mb-6">
                {step === 1
                    ? 'Company information'
                    : step === 2
                    ? "Owner's information"
                    : step === 3
                    ? 'Documentation'
                    : step === 4 && 'Review and submit'}
            </h1>
            <div className="flex gap-4 md:gap-16 md:flex-row flex-col">
                {step === 1 && <Form1 setStep={setStep} data={complianceData} />}
                {step === 2 && <Form2 setStep={setStep} data={complianceData} IdTypes={IDTypes?.data} />}
                {step === 3 && <Form3 setStep={setStep} data={complianceData} utilityTypes={utilityTypes?.data} />}
                {step === 4 && <Review setStep={setStep} validation={validation?.data} />}
                <div className="text-jumbleng-gray flex gap-2 flex-col order-first md:order-1 overflowX-auto">
                    <p className={`p-1 cursor-pointer md:p-2 ${step === 1 && 'border-l md:border-l-2'}`} onClick={() => setStep(1)}>
                        Company information
                    </p>
                    <p className={`p-1 cursor-pointer md:p-2 ${step === 2 && 'border-l md:border-l-2'}`} onClick={() => setStep(2)}>
                        Owner's information
                    </p>
                    <p className={`p-1 cursor-pointer md:p-2 ${step === 3 && 'border-l md:border-l-2'}`} onClick={() => setStep(3)}>
                        Documentation
                    </p>
                    <p className={`p-1 cursor-pointer md:p-2 ${step === 4 && 'border-l md:border-l-2'}`} onClick={() => setStep(4)}>
                        Review and submit
                    </p>
                    {step === 3 && (
                        <div className="bg-[#131221] text-white p-8 mt-6 flex flex-col  gap-4 rounded-xl">
                            <h3 className="font-semibold text-[1rem]">Upload Checklist</h3>
                            <div className="font-normal text-[1rem] ">
                                <div className="flex items-center gap-3">
                                    <img src={check} alt="check" />
                                    <p>File size is limited to 5MB.</p>
                                </div>{' '}
                            </div>
                            <div className="font-normal text-[1rem] ">
                                <div className="flex items-center gap-3">
                                    <img src={check} alt="check" />
                                    <p>Upload Documents in right category.</p>
                                </div>{' '}
                            </div>
                            <div className="font-normal text-[1rem] ">
                                <div className="flex items-center gap-3">
                                    <img src={check} alt="check" />
                                    <p>Convert documents to acceptable file types.</p>
                                </div>{' '}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Compliance;
