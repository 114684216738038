import { BaseQueryFn, createApi, FetchArgs } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth, createRequestWithParams, CustomError } from './shared';

export const notificationApi = createApi({
    reducerPath: 'notificationApi',
    baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, any>>,
    tagTypes: ['notification'],
    endpoints: (builder) => ({
        getNotification: builder.query<any, { read: boolean; context: string; page: number; limit: number }>({
            query: ({ read, context, page, limit }) => createRequestWithParams(`notifications`, { read, context, page, limit }),
            transformResponse: (results: { count: any }) => (results?.count ? results?.count : 0),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'notification' as const, id })), 'notification'] : ['notification'],
        }),
    }),
});

export const { useGetNotificationQuery } = notificationApi;
