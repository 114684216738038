import {
    companyType,
    EregistrationSteps,
    IinitialAuthState,
    IinitialBankState,
    IinitialCartInterface,
    IinitialCompanyCatalogueInterface,
    IinitialFeedInterface,
    IinitialOrderType,
    IInvoices,
    InvoiceType,
    IOrders,
    IPayments,
    ISubscriptionInterface,
    ISubscriptionPlan,
    OrderType,
    PaymentType,
} from './interfaces';

//auth

const initialAuthState: IinitialAuthState = {
    reset_type: 'reset',
    registration_data: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        phone_number: '+234          ',
        role: 'sales_officer',
    },
    registration_step: EregistrationSteps.PresonalDetails,
    user: {
        id: '',
        company_id: '',
        role: '',
        image: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        suspended: false,
        permission_level: 0,
        otp: '',
        otp_expires_at: new Date(),
        google_oidc_sub: '',
        created_at: new Date(),
        updated_at: new Date(),
        twoFA: false,
    },
    company: {
        id: '',
        name: '',
        email: '',
        connection_id: '',

        catalogue: [],
        phone_number: '',
        country: '',
        address: '',
        logo: '',
        image: '',
        is_verified: false,
        company_type: '',
        certificates: '',
        state: '',
        industry_type: '',
        work_force: '',
        created_at: new Date(),
        updated_at: new Date(),
        private: false,
    },
    authorization: {
        access_token: '',
        refresh_token: '',
        access_token_expiry_time: '',
        refresh_token_expiry_time: '',
    },
};

//feed
const initialFeedState: IinitialFeedInterface = {
    orderingCompanies: {
        count: 0,
        data: [],
    },
};

const initialCompanyState: companyType = {
    id: '',
    name: '',
    email: '',
    catalogue: [],
    phone_number: ' ',
    connection_id: '',
    description: '',
    country: '',
    address: '',
    logo: '',
    image: '',
    is_verified: false,
    company_type: '',
    certificates: '',
    state: '',
    industry_type: '',
    work_force: '',
    created_at: new Date(),
    updated_at: new Date(),
    private: false,
    connection_type: '',
    connection_status: '',
    vendor_group_id: '',
};

const initialCompanyCatalogueState: IinitialCompanyCatalogueInterface = {
    loading: false,
    companyCatalogues: {
        count: 0,
        data: { catalogue: [], isPrivate: false },
    },
};

//cart
const initialCartState: IinitialCartInterface = {
    cartItems: [],
};

//orders
const initialOrdersState: IOrders = {
    activeTab: 'customerOrder',
    iscustomer: true,
    data: [],
    count: 0,
    total_order_value: 0,
    currentStatus: '',
};

const initialOrderState: IinitialOrderType = {
    orderLoading: false,
    orderItems: [],
};
const initialSingleOrderState: OrderType = {
    order_no: '',
    acceptance_date: '',
    invoice_id: '',
    invoice_no: '',
    buyer_id: '',
    cancel_reason: '',
    cancellation_date: '',
    created_at: '',
    fulfillment_date: '',
    id: '',
    order_items: [],
    rejection_date: '',
    rejection_reason: '',
    seller_id: '',
    status: '',
    updated_at: '',
    invoice_issued: false,
    invoice_status: '',
    ordering_company: {
        id: '',
        name: '',
        email: '',
        catalogue: [],
        connection_id: '',

        phone_number: '',
        country: '',
        address: '',
        logo: '',
        image: '',
        is_verified: false,
        company_type: '',
        certificates: '',
        state: '',
        industry_type: '',
        work_force: '',
        created_at: new Date(),
        updated_at: new Date(),
        private: false,
    },
    vendor: {
        id: '',
        name: '',
        email: '',
        catalogue: [],
        connection_id: '',

        phone_number: '',
        country: '',
        address: '',
        logo: '',
        image: '',
        is_verified: false,
        company_type: '',
        certificates: '',
        state: '',
        industry_type: '',
        work_force: '',
        created_at: new Date(),
        updated_at: new Date(),
        private: false,
    },
    singleorderloading: false,
    iscustomer: true,
};

//invoices
const initialInvoicesState: IInvoices = {
    invoicesloading: false,
    activeTab: 'customerInvoice',
    iscustomer: true,
    data: [],
    count: 0,
    total_order_value: 0,
    currentStatus: '',
};

const initialSingleInvoicestate: InvoiceType = {
    id: '',
    created_at: '',
    receipt_no: '',
    updated_at: '',
    order_no: '',
    invoice_no: '',
    invoice_id: '',
    payment_id: '',
    receipt_issued: true,
    order: {
        order_no: '',
        acceptance_date: '',
        invoice_id: '',
        invoice_no: '',
        invoice_status: '',
        buyer_id: '',
        cancel_reason: '',
        cancellation_date: '',
        created_at: '',
        fulfillment_date: '',
        id: '',
        order_items: [],
        rejection_date: '',
        rejection_reason: '',
        seller_id: '',
        status: '',
        updated_at: '',
        invoice_issued: false,
        ordering_company: {
            id: '',
            name: '',
            connection_id: '',

            email: '',
            catalogue: [],
            phone_number: '',
            country: '',
            address: '',
            logo: '',
            image: '',
            is_verified: false,
            company_type: '',
            certificates: '',
            state: '',
            industry_type: '',
            work_force: '',
            created_at: new Date(),
            updated_at: new Date(),
            private: false,
        },
        vendor: {
            id: '',
            name: '',
            email: '',
            catalogue: [],
            phone_number: '',
            connection_id: '',

            country: '',
            address: '',
            logo: '',
            image: '',
            is_verified: false,
            company_type: '',
            certificates: '',
            state: '',
            industry_type: '',
            work_force: '',
            created_at: new Date(),
            updated_at: new Date(),
            private: false,
        },
        singleorderloading: false,
        iscustomer: true,
    },
    ordering_company: {
        id: '',
        name: '',
        email: '',
        catalogue: [],
        phone_number: ' ',
        country: '',
        address: '',
        logo: '',
        connection_id: '',

        image: '',
        is_verified: false,
        company_type: '',
        certificates: '',
        state: '',
        industry_type: '',
        work_force: '',
        created_at: new Date(),
        updated_at: new Date(),
        private: false,
    },
    vendor: {
        id: '',
        name: '',
        email: '',
        catalogue: [],
        phone_number: ' ',
        country: '',
        connection_id: '',

        address: '',
        logo: '',
        image: '',
        is_verified: false,
        company_type: '',
        certificates: '',
        state: '',
        industry_type: '',
        work_force: '',
        created_at: new Date(),
        updated_at: new Date(),
        private: false,
    },
    buyer_id: '',
    seller_id: '',
    order_id: '',
    total_order_value: '',
    currency: '',
    paid_date: '',
    paid: '',
    fulfilled: false,
    fulfilled_date: '',
    payment_date: '',
    expiry: '',
    created_by: '',
    status: '',
    singleinvoiceloading: false,
    iscustomer: true,
};

//payments
const initialPaymentsState: IPayments = {
    iscustomer: true,
    activeTab: 'customerPayment',
    data: [],
    count: 0,
    total_order_value: 0,
    currentStatus: '',
};

const initialSinglePaymentstate: PaymentType = {
    id: '',
    created_at: '',
    updated_at: '',
    receipt_no: '',
    proof: '',
    invoice: {
        id: '',
        receipt_issued: true,
        payment_id: '',
        receipt_no: '',
        created_at: '',
        updated_at: '',
        order_no: '',
        invoice_no: '',
        invoice_id: '',
        order: {
            order_no: '',
            invoice_id: '',
            invoice_no: '',
            invoice_issued: false,
            invoice_status: '',
            acceptance_date: '',
            buyer_id: '',
            cancel_reason: '',
            cancellation_date: '',
            created_at: '',
            fulfillment_date: '',
            id: '',
            order_items: [],
            rejection_date: '',
            rejection_reason: '',
            seller_id: '',
            status: '',
            updated_at: '',
            ordering_company: {
                id: '',
                name: '',
                email: '',
                catalogue: [],
                phone_number: '',
                connection_id: '',

                country: '',
                address: '',
                logo: '',
                image: '',
                is_verified: false,
                company_type: '',
                certificates: '',
                state: '',
                industry_type: '',
                work_force: '',
                created_at: new Date(),
                updated_at: new Date(),
                private: false,
            },
            vendor: {
                id: '',
                name: '',
                email: '',
                catalogue: [],
                phone_number: ' ',
                connection_id: '',
                country: '',
                address: '',
                logo: '',
                image: '',
                is_verified: false,
                company_type: '',
                certificates: '',
                state: '',
                industry_type: '',
                work_force: '',
                created_at: new Date(),
                updated_at: new Date(),
                private: false,
            },
        },
        ordering_company: {
            id: '',
            name: '',
            email: '',
            catalogue: [],
            phone_number: ' ',
            connection_id: '',

            country: '',
            address: '',
            logo: '',
            image: '',
            is_verified: false,
            company_type: '',
            certificates: '',
            state: '',
            industry_type: '',
            work_force: '',
            created_at: new Date(),
            updated_at: new Date(),
            private: false,
        },
        vendor: {
            id: '',
            name: '',
            email: '',
            connection_id: '',

            catalogue: [],
            phone_number: ' ',
            country: '',
            address: '',
            logo: '',
            image: '',
            is_verified: false,
            company_type: '',
            certificates: '',
            state: '',
            industry_type: '',
            work_force: '',
            created_at: new Date(),
            updated_at: new Date(),
            private: false,
        },
        buyer_id: '',
        seller_id: '',
        order_id: '',
        total_order_value: '',
        currency: '',
        paid_date: '',
        paid: '',
        fulfilled: false,
        fulfilled_date: '',
        payment_date: '',
        expiry: '',
        created_by: '',
        status: '',
        singleinvoiceloading: false,
        iscustomer: true,
    },
    order: {
        order_no: '',
        invoice_id: '',
        invoice_no: '',
        invoice_issued: false,
        invoice_status: '',
        acceptance_date: '',
        buyer_id: '',
        cancel_reason: '',
        cancellation_date: '',
        created_at: '',
        fulfillment_date: '',
        id: '',
        order_items: [],
        rejection_date: '',
        rejection_reason: '',
        seller_id: '',
        status: '',
        updated_at: '',
        ordering_company: {
            id: '',
            name: '',
            connection_id: '',

            email: '',
            catalogue: [],
            phone_number: ' ',
            country: '',
            address: '',
            logo: '',
            image: '',
            is_verified: false,
            company_type: '',
            certificates: '',
            state: '',
            industry_type: '',
            work_force: '',
            created_at: new Date(),
            updated_at: new Date(),
            private: false,
        },
        vendor: {
            id: '',
            name: '',
            email: '',
            catalogue: [],
            connection_id: '',
            phone_number: ' ',
            country: '',
            address: '',
            logo: '',
            image: '',
            is_verified: false,
            company_type: '',
            certificates: '',
            state: '',
            industry_type: '',
            work_force: '',
            created_at: new Date(),
            updated_at: new Date(),
            private: false,
        },
    },
    buyer_id: '',
    seller_id: '',
    order_id: '',
    currency: '',
    status: '',
    amount: 0,
    completed: false,
    reference: '',
    invoice_id: '',
    receipt_issued: false,
    singlepaymentloading: false,
    iscustomer: true,
};

const initialBankState: IinitialBankState = {
    banks: [],
    accounts: [],
    loading: false,
};

//subscription
export const intitialSubscriptionData: ISubscriptionInterface = {
    active: false,
    b2c_storefront: false,
    created_at: '',
    currency: '',
    description: '',
    erp_integration: false,
    id: '',
    monthly_purchase_order_limit: 0,
    name: '',
    price_per_month: 0,
    price_per_year: 0,
    updated_at: '',
    vendor_stock_data: false,
};

export const intitialSubscriptionPlanData: ISubscriptionPlan = {
    plan: {
        active: false,
        b2c_storefront: false,
        created_at: '',
        currency: '',
        description: '',
        erp_integration: false,
        id: '',
        monthly_purchase_order_limit: 0,
        price_per_month: 0,
        name: '',
        price_per_year: 0,
        updated_at: '',
        vendor_stock_data: false,
    },
    amount: 0,
    paid: false,
    company: '',
    company_id: '',
    state: '',
    created_at: '',
    detail: '',
    currency: '',
    id: '',
    plan_type: '',
    start_time: '',
    end_time: '',
    status: '',
    subscription_plan_id: '',
    updated_at: '',
};

export {
    initialAuthState,
    initialBankState,
    initialCartState,
    initialCompanyCatalogueState,
    initialCompanyState,
    initialFeedState,
    initialInvoicesState,
    initialOrdersState,
    initialOrderState,
    initialPaymentsState,
    initialSingleInvoicestate,
    initialSingleOrderState,
    initialSinglePaymentstate,
};
