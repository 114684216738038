import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import OrderCartModal from '../../components/OrderCartModal/OrderCartModal';
import PermissionsGate, { hasPermission } from '../../HOC/PermissionGate';
import WrapperVendor from '../../HOC/WrapperVendor';
import useGetRoles from '../../hooks/useGetRoles';
import { useGetCompaniesQuery } from '../../redux/services/supplyChainApi';
import { toggleFeedTab } from '../../redux/slices/tabs';
import { RootState } from '../../redux/store';
import { COMPANYPERMISSIONS, SCOPES, USERPERMISSIONS } from '../../utils/permission';
import ConnectedVendor from '../requests/ConnectedVendor';
import VendorCard from '../requests/VendorCard';

export type FeedQuery = {
    page: number;
    limit: number;
    connected: boolean;
    search_query: string;
    addedToGroup: boolean;
};
export const initialFeedQuery: FeedQuery = {
    page: 1,
    limit: 12,
    connected: false,
    search_query: '',
    addedToGroup: false,
};

const manageFeedPermission = (user_role: any, company_type: any) => {
    const userpermissions = USERPERMISSIONS[user_role];
    const companypermissions = COMPANYPERMISSIONS[company_type];
    const userPermissionGranted = hasPermission({ permissions: userpermissions, scopes: [SCOPES.can_view_connected_vendors_feed] });
    const companyPermissionGranted = hasPermission({ permissions: companypermissions, scopes: [SCOPES.can_view_connected_vendors_feed] });
    const userPermissionGranted2 = hasPermission({ permissions: userpermissions, scopes: [SCOPES.can_view_all_feed] });
    const companyPermissionGranted2 = hasPermission({ permissions: companypermissions, scopes: [SCOPES.can_view_all_feed] });
    return userPermissionGranted && companyPermissionGranted && !(userPermissionGranted2 && companyPermissionGranted2);
};

const Feed: FC = () => {
    const { feedTab: activeTab } = useSelector((state: RootState) => state.tabsReducer);
    const [query, setQuery] = useState<FeedQuery>(initialFeedQuery);
    const { isLoading } = useGetCompaniesQuery(query);
    const { user_role, company_type } = useGetRoles();
    const dispatch = useDispatch();
    useEffect(() => {
        if (manageFeedPermission(user_role, company_type)) {
            setQuery({ ...query, page: 1, connected: true });
            dispatch(toggleFeedTab('connectedVendor'));
            return;
        }
        if (activeTab === 'allVendor') {
            setQuery({ ...query, page: 1, connected: false });
        } else {
            setQuery({ ...query, page: 1, connected: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setQuery, activeTab, isLoading, user_role, company_type, dispatch]);
    const toggleTab = (tabName: 'allVendor' | 'connectedVendor') => {
        dispatch(toggleFeedTab(tabName));
    };

    return (
        <WrapperVendor setQuery={setQuery} query={query}>
            <div className="mt-6">
                <div>
                    <div className="flex text-[0.78125rem] text-jumbleng-gray gap-4 mb-8">
                        <PermissionsGate scopes={[SCOPES.can_view_all_feed]}>
                            <p
                                className={`${
                                    activeTab === 'allVendor' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                                } border-[1px] rounded-[16px] p-1.5 px-5 h-8`}
                            >
                                <a href="#" onClick={() => toggleTab('allVendor')} className={` w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}>
                                    All
                                </a>
                            </p>
                        </PermissionsGate>
                        <PermissionsGate scopes={[SCOPES.can_view_connected_vendors_feed]}>
                            <p
                                className={` ${
                                    activeTab === 'connectedVendor' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                                } border-[1px]  rounded-[16px] p-1.5 h-8`}
                            >
                                <a
                                    href="#"
                                    onClick={() => toggleTab('connectedVendor')}
                                    className={` w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                                >
                                    Connected Vendors
                                </a>
                            </p>
                        </PermissionsGate>
                        <OrderCartModal />
                    </div>
                </div>
                {activeTab === 'allVendor' ? <VendorCard setQuery={setQuery} query={query} /> : <ConnectedVendor setQuery={setQuery} query={query} />}
            </div>
        </WrapperVendor>
    );
};

export default Feed;
