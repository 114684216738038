import { BaseQueryFn, createApi, FetchArgs } from '@reduxjs/toolkit/query/react';

import { IInvoices, InvoiceType } from '../features/interfaces';
import { baseQueryWithReauth, createRequestWithParams, CustomError } from './shared';

const createRequest = (url: any) => ({ url });
export const invoiceApi = createApi({
    reducerPath: 'invoiceApi',
    baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, any>>,
    tagTypes: ['invoice'],
    endpoints: (builder) => ({
        cancelInvoice: builder.mutation({
            query: (id) => {
                return {
                    url: `invoices/${id}/cancel`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'invoice', id }],
        }),
        approveInvoice: builder.mutation({
            query: (id) => {
                return {
                    url: `invoices/${id}/approve`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'invoice', id }],
        }),
        getMyInvoices: builder.query<IInvoices, { page: number; limit: number; search_query: string; status: string }>({
            query: ({ page, limit, search_query, status }) => createRequestWithParams(`invoices/purchases`, { page, limit, search_query, status }),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'invoice' as const, id })), 'invoice'] : ['invoice'],
        }),

        getCustomerInvoices: builder.query<IInvoices, { page: number; limit: number; search_query: string; status: string }>({
            query: ({ page, limit, search_query, status }) => createRequestWithParams(`invoices/customers`, { page, limit, search_query, status }),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'invoice' as const, id })), 'invoice'] : ['invoice'],
        }),
        getAnInvoice: builder.query<InvoiceType, string>({
            query: (id) => createRequest(`/invoices/${id}`),
            providesTags: (_result, _error, id) => [{ type: 'invoice', id }],
            transformResponse: (results: { data: any }) => results.data,
        }),

        getAllInvoiceCount: builder.query<any, string>({
            query: (type) => createRequest(`/invoices/${type}?page=1&limit=10&status=`),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'invoice' as const, id })), 'invoice'] : ['invoice'],
        }),
        getPaidInvoiceCount: builder.query<any, string>({
            query: (type) => createRequest(`invoices/${type}?page=1&limit=10&status=paid`),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'invoice' as const, id })), 'invoice'] : ['invoice'],
        }),
        getUnpaidInvoiceCount: builder.query<any, string>({
            query: (type) => createRequest(`invoices/${type}?page=1&limit=10&status=unpaid`),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'invoice' as const, id })), 'invoice'] : ['invoice'],
        }),
        getCancelledInvoiceCount: builder.query<any, string>({
            query: (type) => createRequest(`invoices/${type}?page=1&limit=10&status=cancelled`),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'invoice' as const, id })), 'invoice'] : ['invoice'],
        }),
    }),
});

export const {
    useGetAllInvoiceCountQuery,
    useGetCancelledInvoiceCountQuery,
    useGetPaidInvoiceCountQuery,
    useGetUnpaidInvoiceCountQuery,
    useApproveInvoiceMutation,
    useCancelInvoiceMutation,
    useGetAnInvoiceQuery,
    useGetCustomerInvoicesQuery,
    useGetMyInvoicesQuery,
} = invoiceApi;
