import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

import { CART_ADD_ITEM, CART_CLEAR_ITEMS, CART_REMOVE_ITEM, DECREMENT_QTY, GET_CART_ITEM, INCREMENT_QTY, UPDATE_QTY } from './CartActionTypes';

export const addToCart =
    (userId: string, id: string, catalogue_id: string, company_name: string, price: number, name: string, image: string) =>
    async (dispatch: Dispatch, getState: any) => {
        dispatch({
            type: CART_ADD_ITEM,
            payload: {
                userId: userId,
                company_name: company_name,
                catalogue_id: catalogue_id,
                id: id,
                name: name,
                price: price,
                image: image,
                qty: 0,
            },
        });
        localStorage.setItem('cartItems', JSON.stringify(getState().cartReducer.cartItems));
        toast.success('item added to cart');
    };

export const increment = (id: string, userId: string) => async (dispatch: Dispatch, getState: any) => {
    dispatch({
        type: INCREMENT_QTY,
        payload: { id, userId },
    });
    localStorage.setItem('cartItems', JSON.stringify(getState().cartReducer.cartItems));
};
export const updateQty = (id: string, userId: string, value: string) => async (dispatch: Dispatch, getState: any) => {
    dispatch({
        type: UPDATE_QTY,
        payload: { id, userId, value },
    });
    localStorage.setItem('cartItems', JSON.stringify(getState().cartReducer.cartItems));
};
export const decrement = (id: string, userId: string) => async (dispatch: Dispatch, getState: any) => {
    dispatch({
        type: DECREMENT_QTY,
        payload: { id, userId },
    });
    localStorage.setItem('cartItems', JSON.stringify(getState().cartReducer.cartItems));
};

export const getCartItems = () => async (dispatch: Dispatch) => {
    dispatch({
        type: GET_CART_ITEM,
        payload: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems') || '') : [],
    });
};

export const removeFromCart = (id: string, userId: string) => async (dispatch: Dispatch, getState: any) => {
    dispatch({
        type: CART_REMOVE_ITEM,
        payload: { id, userId },
    });
    localStorage.setItem('cartItems', JSON.stringify(getState().cartReducer.cartItems));
};

export const clearCart = (userId: string) => async (dispatch: Dispatch, getState: any) => {
    dispatch({
        type: CART_CLEAR_ITEMS,
        payload: { userId },
    });
    localStorage.setItem('cartItems', JSON.stringify(getState().cartReducer.cartItems));
};
