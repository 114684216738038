import './styles.css';

import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import NotFound from '../../../assets/images/png/no-image.jpg';
import avatar from '../../../assets/images/png/noImage.png';
import ArrowDown from '../../../assets/images/svg/arrow-down.svg';
import LeftArrow from '../../../assets/images/svg/arrow-left.svg';
import LoadingModal from '../../../components/LoadingModal/LoadingModal';
import Pagination from '../../../components/Pagination/Pagination';
import PopUpModal from '../../../components/PopModal/PopUpModal';
import Loading from '../../../components/UI/Spinner/Loading';
import Wrapper from '../../../HOC/Wrapper';
import { initialSingleInvoicestate, initialSinglePaymentstate } from '../../../redux/features/dtos';
import { OrderItemsType } from '../../../redux/features/interfaces';
import { useGetAnInvoiceQuery, useGetAPaymentQuery } from '../../../redux/services';
import { RootState } from '../../../redux/store';
import { toLocaleFixed, transformedDateWithTime } from '../../../utils/utils';
import OrderItemDetails from '../../order/OrderItemDetails';
import EmptySearch from '../../vendorlist/EmptyContent/EmptySearch';
import InvoiceStatusAction from './InvoiceActions';

export interface IPaginationQuery {
    page: number;
    limit: number;
    search_query: '';
}
export const paginationQuery: IPaginationQuery = {
    page: 1,
    limit: 10,
    search_query: '',
};

const ExpandedInvoice = () => {
    const dispatch = useDispatch();
    const { iscustomer } = useSelector((state: RootState) => state.getInvoiceReducer);
    const navigate = useNavigate();
    const [skip, setSkip] = useState(true);
    const id = useParams()?.id as string;
    const { data: invoicedetails = initialSingleInvoicestate, isLoading } = useGetAnInvoiceQuery(id);
    const { order_id, vendor, ordering_company, payment_id, created_at, status, invoice_no, order_no, order } = invoicedetails;
    const { data: paymentdetails = initialSinglePaymentstate, isFetching } = useGetAPaymentQuery(payment_id, { skip });
    const { invoice, invoice_id, created_at: createdPayment, receipt_issued } = paymentdetails;
    const [query, setQuery] = useState<IPaginationQuery>(paginationQuery);
    const [q, setQ] = useState('');

    const OrderItems = useMemo(() => {
        return order?.order_items?.filter((item) => {
            if (q === '') {
                return item;
            } else if (item.catalogue.product.name.toLowerCase().includes(q.toLowerCase())) {
                return item;
            }
        });
    }, [q, order?.order_items]);

    const paginatedOrderItems = useMemo(() => {
        const startIndex = query.page * query.limit - query.limit;
        const endIndex = startIndex + query.limit;
        return OrderItems.slice(startIndex, endIndex);
    }, [OrderItems, query.page, query.limit]);

    useEffect(() => {
        status === 'paid' && setSkip((prev) => !prev);
    }, [dispatch, payment_id, status]);

    return isLoading || isFetching ? (
        <div className="min-h-screen flex justify-center flex-col">
            <Loading />
        </div>
    ) : (
        <Wrapper>
            <LoadingModal />
            <div className="mt-6 pb-4 px-4">
                <div onClick={() => navigate(-1)} className="cursor-pointer w-[5rem] ">
                    <div className="flex text-[#5E5BA7]">
                        <img src={LeftArrow} alt="leftarrow" className="pr-2" />
                        Back
                    </div>
                </div>

                <div className="flex items-center justify-between mt-[1.35rem] mb-12">
                    <h3 className="font-semibold text-[21px]"> {iscustomer ? 'Customer Invoice' : 'My Invoice'}</h3>
                </div>

                <div className="order__header__container">
                    <div className="col-span-2">
                        <div className="text-sm  flex flex-start gap-2">
                            <div className="flex space-x-2">
                                <div className=" w-12 h-12">
                                    <img
                                        className="rounded-full border border-gray-100 shadow-sm"
                                        src={
                                            iscustomer
                                                ? vendor.image
                                                    ? vendor.image
                                                    : `${avatar}`
                                                : ordering_company.image
                                                ? ordering_company.image
                                                : `${avatar}`
                                        }
                                        alt="user image"
                                    />{' '}
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 ">
                                <div className="text-[#131221] text-[16px]">{ordering_company.name}</div>
                                <div className="text-[14px] text-[#706F77]"> {ordering_company.company_type}</div>
                                <div className="text-[14px] text-[#706F77]"> {ordering_company.address}.</div>
                                <div className="text-[14px] text-[#706F77]">{ordering_company.phone_number}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div>
                            <div className="font-bold text-[#525171] text-[16px]">Date created:</div>
                            <div className="text-[#525171] text-[16px]">{transformedDateWithTime(created_at)}</div>
                        </div>

                        <div className="mt-6">
                            <div className="font-bold text-[#525171] text-[16px]">Invoice number:</div>
                            <div className="text-[#525171] text-[16px]">
                                <span className="font-bold text-[#131221]">INV</span>-{invoice_no}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2 min-w-[300px]">
                        <div>
                            <div className="font-bold text-[#525171] text-[16px]">Total Order value:</div>
                            <div className="text-[#131221] text-[25px] font-black">
                                N{' '}
                                {toLocaleFixed(
                                    order.order_items.reduce((total: number, item: OrderItemsType) => item.qty * item.buying_price + total, 0),
                                    2,
                                )}
                            </div>
                        </div>
                        <div className="mt-7 flex flex-col ">
                            <div className="font-bold text-[#525171] text-[16px]">Total VAT value:</div>
                            <div className="text-[#131221] text-[25px] font-black">
                                {' '}
                                N{' '}
                                {toLocaleFixed(
                                    order.order_items.reduce((total: number, item: OrderItemsType) => item.vat_amount + total, 0),
                                    2,
                                )}{' '}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className="font-bold text-[#525171] text-[16px]">Status</div>
                        <div className=" grid justify-start">
                            <div
                                className={`${
                                    status === 'cancelled'
                                        ? 'bg-red-100 text-[#840B0B]'
                                        : status === 'paid'
                                        ? 'text-[#084F21] bg-green-200'
                                        : status === 'unpaid'
                                        ? 'bg-amber-100 text-[#D29C17]'
                                        : 'bg-red-100 text-[#840B0B]'
                                } py-2 px-5 rounded-2xl flex gap-1`}
                            >
                                <p className="text-sm ">{status}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="mb-4" />
                <div className="mt-4 mb- flex justify-end">
                    <InvoiceStatusAction
                        order={order}
                        invoice_issued={order.invoice_issued}
                        status={status}
                        payment_id={payment_id}
                        id={id}
                        iscustomer={iscustomer}
                        ordering_company={ordering_company}
                        invoice_no={invoice_no}
                        order_items={order.order_items}
                        order_id={order_id}
                        order_no={order.order_no}
                        receipt_no={invoice.receipt_no}
                        vendor={vendor}
                        invoice_id={invoice_id}
                        created_at={createdPayment}
                        receipt_issued={receipt_issued}
                    />
                </div>
                <div className="flex">
                    <div className="text-[#5E5BA7] text-[16px] ">
                        You have <span className="font-bold">{order.order_items.length}</span> items
                    </div>

                    <div className="flex items-center relative" style={{ marginLeft: 'auto' }}>
                        <div className="w-[7.7rem] h-[2.3rem] relative mr-[45px]">
                            <i className="fa fa-search icon absolute top-1/2 -translate-y-1/2 px-[0.79rem]" />
                            <input
                                className="w-full bg-[#F8F8FB] border-b-2 focus:outline-none pl-[2.08rem] h-full placeholder:text-[0.78165rem] text-[0.78125rem] text-jumbleng-gray placeholder:text-jumbleng-gray"
                                placeholder="Search products"
                                onChange={(e) => setQ(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className=" p-5 mt-5">
                    <div className="grid grid-cols-9 pt-4 bg-white p-5 font-bold">
                        <div className="jumbleng-gray-main text-base col-span-1 ">SKU</div>
                        <div className="jumbleng-gray-main text-base grid  col-span-2 ">Items Ordered</div>
                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1">Quantity</div>
                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1"> UOM</div>
                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1">Prices</div>
                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1">
                            <div className="flex gap-2 items-center">
                                <p> VAT</p>
                                <img src={ArrowDown} alt="a-down" />
                            </div>
                        </div>
                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1">Total Order Value</div>
                        <div className="jumbleng-gray-main text-base grid justify-center col-span-1"></div>
                    </div>
                    {isEmpty(paginatedOrderItems) ? (
                        <EmptySearch content="Item" />
                    ) : (
                        paginatedOrderItems?.map((item: OrderItemsType, index: number) => (
                            <div
                                className={`grid grid-cols-9 pt-4 p-5 gap-1 cursor-pointer  hover:bg-[#5D5BA7] hover:text-white items-center ${
                                    index % 2 == 0 ? 'bg-[#fff]' : 'bg-[#F9F9FA]'
                                }`}
                                key={item.id}
                            >
                                <div className="text-sm col-span-1 flex gap-2 ">
                                    <div className="grid content-center">
                                        <input type="checkbox" />
                                    </div>
                                    <div className="grid content-center">
                                        <div>{item.catalogue.product.sku}</div>
                                    </div>
                                </div>
                                <div className="text-sm col-span-2">
                                    <PopUpModal
                                        content={
                                            <div className="flex gap-2  items-center ">
                                                <img
                                                    src={item.catalogue.product.picture ? item.catalogue.product.picture : NotFound}
                                                    alt="avatar"
                                                    className="h-[2.2rem] w-[2.2rem] object-contain"
                                                />
                                                <span className="truncate w-[135px] "> {item.catalogue.product.name}</span>
                                            </div>
                                        }
                                        className="text-sm grid "
                                        popupcontent={true}
                                    >
                                        {(close: () => void) => (
                                            <div>
                                                <button className="close" onClick={close}>
                                                    &times;
                                                </button>
                                                <OrderItemDetails
                                                    Props={{
                                                        created_at: item.created_at,
                                                        order_no: order_no,
                                                        iscustomer: iscustomer,
                                                        id: id,
                                                        order_items: order.order_items,
                                                        qty: item.qty,
                                                        product: item.catalogue.product,
                                                        status: order.status,
                                                        images: item.catalogue.product.images,
                                                        picture: item.catalogue.product.picture,
                                                        ordering_company: ordering_company,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </PopUpModal>
                                </div>
                                <div className="text-sm grid justify-center col-span-1">
                                    <div className="flex">
                                        <p className="ml-3 mr-3 pl-2 pr-2 border border-[#706F77]">{item.qty.toLocaleString()}</p>
                                    </div>
                                </div>
                                <div className="text-sm grid justify-center col-span-1">{item.catalogue.product.uom}</div>
                                <div className="text-sm grid justify-center col-span-1">₦ {toLocaleFixed(item.buying_price, 2)}</div>
                                <div className="text-sm grid justify-center col-span-1">
                                    {item.vat_percent ? item.vat_percent + '%' : item.catalogue.product.vat ? item.catalogue.product.vat + '%' : '-'}
                                </div>
                                <div className="text-sm grid justify-center col-span-1">
                                    ₦ {toLocaleFixed(item.qty * Number(item.buying_price), 2)}
                                </div>
                                <div className="text-sm grid justify-center col-span-1">
                                    <i className="fa fa-circle-minus fa-outline text-white "></i>
                                </div>
                            </div>
                        ))
                    )}
                    <Pagination query={query} setQuery={setQuery} data={OrderItems} count={OrderItems.length} />
                </div>
            </div>
        </Wrapper>
    );
};

export default ExpandedInvoice;
