export const ROLES = {
    roles: {
        buying_officer: 'buying_officer',
        finance_officer: 'finance_officer',
        sales_officer: 'sales_officer',
        super_admin: 'super_admin',
        jubmle_admin: 'jubmle_admin',
    },
    company_type: {
        distributor: 'distributor',
        manufacturer: 'manufacturer',
        retailer: 'retailer',
    },
};

export const SCOPES = {
    can_create_groups: 'can_create_groups',
    can_create_orders: 'can_create_orders',
    can_manage_cart_orders: 'can_manage_cart_orders',
    can_manage_catalogue: 'can_manage_catalogue',
    can_manage_company_invoices: 'can_manage_company_invoices',
    can_manage_company_orders: 'can_manage_company_orders',
    can_manage_company_payments: 'can_manage_company_payments',
    can_manage_company_profile: 'can_manage_company_profile',
    can_manage_company_subscriptions: 'can_manage_company_subscriptions',
    can_manage_connections: 'can_manage_connections',
    can_manage_customer_invoices: 'can_manage_customer_invoices',
    can_manage_customer_orders: 'can_manage_customer_orders',
    can_manage_customer_payments: 'can_manage_customer_payments',
    can_manage_notifications: 'can_manage_notifications',
    can_manage_payment_settings: 'can_manage_payment_settings',
    can_manage_storefront: 'can_manage_storefront',
    can_manage_team_members: 'can_manage_team_members',
    can_view_all_feed: 'can_view_all_feed',
    can_manage_security: 'can_manage_security',
    can_manage_compliance: 'can_manage_compliance',
    can_view_company_order_analytics: 'can_view_company_order_analytics',
    can_view_company_purchase_analytics: 'can_view_company_purchase_analytics',
    can_view_company_revenue_analytics: 'can_view_company_revenue_analytics',
    can_view_company_sales_analytics: 'can_view_company_sales_analytics',
    can_view_connected_vendors_feed: 'can_view_connected_vendors_feed',
    can_view_team_members: 'can_view_team_members',
    can_create_products: 'can_create_products',
    can_view_company_profile: 'can_view_company_profile',
    can_manage_company_catalogue: 'can_manage_company_catalogue',
    can_view_suppliers: 'can_view_suppliers',
    can_view_customers: 'can_view_customers',
};

export const USERPERMISSIONS = {
    [ROLES.roles.finance_officer]: [
        SCOPES.can_manage_company_payments,
        SCOPES.can_manage_customer_payments,
        SCOPES.can_view_company_order_analytics,
        SCOPES.can_view_company_revenue_analytics,
        SCOPES.can_view_company_sales_analytics,
    ],
    [ROLES.roles.buying_officer]: [
        SCOPES.can_create_orders,
        SCOPES.can_manage_cart_orders,
        SCOPES.can_manage_company_invoices,
        SCOPES.can_manage_company_orders,
        SCOPES.can_view_company_purchase_analytics,
        SCOPES.can_view_connected_vendors_feed,
        SCOPES.can_view_team_members,
        SCOPES.can_view_suppliers,
    ],
    [ROLES.roles.sales_officer]: [
        SCOPES.can_manage_customer_invoices,
        SCOPES.can_manage_customer_orders,
        SCOPES.can_view_company_order_analytics,
        SCOPES.can_view_company_revenue_analytics,
        SCOPES.can_view_company_sales_analytics,
        SCOPES.can_view_team_members,
        SCOPES.can_view_customers,
    ],
    [ROLES.roles.jubmle_admin]: [
        SCOPES.can_create_groups,
        SCOPES.can_create_orders,
        SCOPES.can_create_products,
        SCOPES.can_manage_cart_orders,
        SCOPES.can_manage_catalogue,
        SCOPES.can_manage_company_catalogue,
        SCOPES.can_manage_company_invoices,
        SCOPES.can_manage_company_orders,
        SCOPES.can_manage_company_payments,
        SCOPES.can_manage_company_profile,
        SCOPES.can_manage_compliance,
        SCOPES.can_manage_security,
        SCOPES.can_manage_company_profile,
        SCOPES.can_manage_company_subscriptions,
        SCOPES.can_manage_connections,
        SCOPES.can_manage_customer_invoices,
        SCOPES.can_manage_customer_orders,
        SCOPES.can_manage_customer_payments,
        SCOPES.can_manage_notifications,
        SCOPES.can_manage_payment_settings,
        SCOPES.can_manage_storefront,
        SCOPES.can_manage_team_members,
        SCOPES.can_view_all_feed,
        SCOPES.can_view_company_order_analytics,
        SCOPES.can_view_company_profile,
        SCOPES.can_view_company_purchase_analytics,
        SCOPES.can_view_company_revenue_analytics,
        SCOPES.can_view_company_sales_analytics,
        SCOPES.can_view_connected_vendors_feed,
        SCOPES.can_view_team_members,
        SCOPES.can_view_suppliers,
        SCOPES.can_view_customers,
    ],
    [ROLES.roles.super_admin]: [
        SCOPES.can_create_groups,
        SCOPES.can_create_orders,
        SCOPES.can_create_products,
        SCOPES.can_manage_cart_orders,
        SCOPES.can_manage_catalogue,
        SCOPES.can_manage_company_catalogue,
        SCOPES.can_manage_company_invoices,
        SCOPES.can_manage_company_orders,
        SCOPES.can_manage_company_payments,
        SCOPES.can_manage_company_profile,
        SCOPES.can_manage_company_subscriptions,
        SCOPES.can_manage_connections,
        SCOPES.can_manage_customer_invoices,
        SCOPES.can_manage_customer_orders,
        SCOPES.can_manage_customer_payments,
        SCOPES.can_manage_compliance,
        SCOPES.can_manage_security,
        SCOPES.can_manage_notifications,
        SCOPES.can_manage_payment_settings,
        SCOPES.can_manage_storefront,
        SCOPES.can_manage_team_members,
        SCOPES.can_view_all_feed,
        SCOPES.can_view_company_order_analytics,
        SCOPES.can_view_company_profile,
        SCOPES.can_view_company_purchase_analytics,
        SCOPES.can_view_company_revenue_analytics,
        SCOPES.can_view_company_sales_analytics,
        SCOPES.can_view_connected_vendors_feed,
        SCOPES.can_view_team_members,
        SCOPES.can_view_suppliers,
        SCOPES.can_view_customers,
    ],
};

export const COMPANYPERMISSIONS = {
    [ROLES.company_type.distributor]: [
        SCOPES.can_create_groups,
        SCOPES.can_create_orders,
        SCOPES.can_manage_cart_orders,
        SCOPES.can_manage_catalogue,
        SCOPES.can_manage_company_invoices,
        SCOPES.can_manage_company_orders,
        SCOPES.can_manage_company_payments,
        SCOPES.can_manage_company_profile,
        SCOPES.can_manage_compliance,
        SCOPES.can_manage_security,
        SCOPES.can_manage_company_subscriptions,
        SCOPES.can_manage_connections,
        SCOPES.can_manage_customer_invoices,
        SCOPES.can_manage_customer_orders,
        SCOPES.can_manage_customer_payments,
        SCOPES.can_manage_notifications,
        SCOPES.can_manage_payment_settings,
        SCOPES.can_manage_storefront,
        SCOPES.can_manage_team_members,
        SCOPES.can_view_all_feed,
        SCOPES.can_view_company_order_analytics,
        SCOPES.can_view_company_purchase_analytics,
        SCOPES.can_view_company_revenue_analytics,
        SCOPES.can_view_company_sales_analytics,
        SCOPES.can_view_connected_vendors_feed,
        SCOPES.can_view_team_members,
        SCOPES.can_view_suppliers,
        SCOPES.can_view_customers,
    ],
    [ROLES.company_type.manufacturer]: [
        SCOPES.can_create_groups,
        SCOPES.can_create_orders,
        SCOPES.can_create_products,
        SCOPES.can_manage_cart_orders,
        SCOPES.can_manage_catalogue,
        SCOPES.can_manage_company_invoices,
        SCOPES.can_manage_company_orders,
        SCOPES.can_manage_company_payments,
        SCOPES.can_manage_company_profile,
        SCOPES.can_manage_company_subscriptions,
        SCOPES.can_manage_compliance,
        SCOPES.can_manage_security,
        SCOPES.can_manage_connections,
        SCOPES.can_manage_customer_invoices,
        SCOPES.can_manage_customer_orders,
        SCOPES.can_manage_customer_payments,
        SCOPES.can_manage_notifications,
        SCOPES.can_manage_payment_settings,
        SCOPES.can_manage_storefront,
        SCOPES.can_manage_team_members,
        SCOPES.can_view_all_feed,
        SCOPES.can_view_company_order_analytics,
        SCOPES.can_view_company_purchase_analytics,
        SCOPES.can_view_company_revenue_analytics,
        SCOPES.can_view_company_sales_analytics,
        SCOPES.can_view_connected_vendors_feed,
        SCOPES.can_view_team_members,
        SCOPES.can_view_suppliers,
        SCOPES.can_view_customers,
    ],

    [ROLES.company_type.retailer]: [
        SCOPES.can_create_groups,
        SCOPES.can_create_orders,
        SCOPES.can_manage_cart_orders,
        SCOPES.can_manage_company_invoices,
        SCOPES.can_manage_company_orders,
        SCOPES.can_manage_connections,
        SCOPES.can_manage_company_payments,
        SCOPES.can_manage_company_profile,
        SCOPES.can_manage_security,
        SCOPES.can_view_team_members,
        SCOPES.can_manage_company_subscriptions,
        SCOPES.can_manage_notifications,
        SCOPES.can_manage_compliance,
        SCOPES.can_manage_catalogue,
        SCOPES.can_manage_customer_orders,
        SCOPES.can_manage_customer_payments,
        SCOPES.can_manage_payment_settings,
        SCOPES.can_manage_team_members,
        SCOPES.can_manage_storefront,
        SCOPES.can_view_all_feed,
        SCOPES.can_view_company_order_analytics,
        SCOPES.can_view_company_purchase_analytics,
        SCOPES.can_view_company_revenue_analytics,
        SCOPES.can_view_company_sales_analytics,
        SCOPES.can_view_connected_vendors_feed,
        SCOPES.can_view_suppliers,
    ],
};
