import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';

import RedDelete from '../../assets/images/svg/redDelete.svg';
import WhiteDelete from '../../assets/images/svg/whiteDelete.svg';
import { fetchAccounts } from '../../redux/features/bank-payment/bankRedux';
import { markMain } from '../../redux/features/bank-payment/bankService';
import { accountTypes } from '../../redux/features/interfaces';
import { closeModal, openModal } from '../../redux/modal/modalRedux';
import { ThunkAppDispatch, useAppThunkDispatch } from '../../redux/store';
import LoadingModal from '../LoadingModal/LoadingModal';
import RemoveAccountModal from './RemoveAccountModal';

interface FProps {
    accounts: accountTypes[];
    setContent: Dispatch<SetStateAction<'Empty' | 'Filled' | 'Form'>>;
}

const FilledPaymentSettings: FC<FProps> = ({ accounts, setContent }) => {
    const dispatch: ThunkAppDispatch = useAppThunkDispatch();

    const MarkasMain = async (id: string) => {
        try {
            dispatch(openModal());
            const response = await markMain(id);
            dispatch(fetchAccounts());
            dispatch(closeModal());
            toast.success(response.message);
        } catch (error: any) {
            dispatch(closeModal());
            toast.error(error.response.data?.message);
        }
    };

    const sortedAccounts = useMemo(() => {
        const sortedAccounts = accounts.sort((account) => (account.main ? -1 : 1));
        return sortedAccounts;
    }, [accounts]);

    return (
        <>
            <LoadingModal />
            {sortedAccounts.map((account, i) => (
                <div key={i}>
                    <div className={`ml-8 grid grid-cols-2  p-4  mt-4 ${account.main && 'bg-[#7976B6] rounded-lg'}`}>
                        <div>
                            {account.main ? (
                                <div className="font-bold text-[#FFFFFF] mb-2">Main account</div>
                            ) : (
                                <div
                                    className="font-semibold text-[#5E5BA7] mb-2 cursor-pointer hover:text-jumbleng-gray"
                                    onClick={() => MarkasMain(account.id)}
                                >
                                    Use as main account
                                </div>
                            )}
                            <div className={`${account.main ? 'text-[#FFFFFF]' : 'text-[#5E5BA7]'}`}> Account name</div>
                            <div className={`${account.main ? 'text-[#FFFFFF]' : 'text-[#5E5BA7]'}`}> Account Number</div>
                            <div className={`${account.main ? 'text-[#FFFFFF]' : 'text-[#5E5BA7]'}`}> Bank name</div>
                        </div>

                        <div className="grid justify-end">
                            <Popup
                                trigger={
                                    <div
                                        className={`flex justify-end font-semibold ${
                                            account.main ? 'text-[#FFFFFF]' : 'text-[#9F0D0D]'
                                        } mb-2 cursor-pointer`}
                                    >
                                        <img src={account.main ? WhiteDelete : RedDelete} alt="delete" className="mr-1" width={20} height={20} />{' '}
                                        Remove
                                    </div>
                                }
                                modal
                                contentStyle={{ width: '500px' }}
                            >
                                {(close: () => void) => (
                                    <div>
                                        <RemoveAccountModal
                                            account_number={account.account_number}
                                            id={account.id}
                                            close={close}
                                            setContent={setContent}
                                        />
                                    </div>
                                )}
                            </Popup>

                            <div className={`grid justify-end ${account.main ? 'text-[#FFFFFF]' : 'text-[#131221]'}`}>{account.account_name}.</div>
                            <div className={`grid justify-end ${account.main ? 'text-[#FFFFFF]' : 'text-[#131221]'}`}>{account.account_number}</div>
                            <div className={`grid justify-end ${account.main ? 'text-[#FFFFFF]' : 'text-[#131221]'}`}>{account.bank_name} </div>
                        </div>
                    </div>
                </div>
            ))}

            <p className="cursor-pointer font-semibold text-[#5E5BA7] ml-8 mt-4" onClick={() => setContent('Form')}>
                {' '}
                + Add new account
            </p>
            <p className="text-[#5E5BA7] ml-8 mt-4 italic">You can add up to 3 account details</p>
        </>
    );
};

export default FilledPaymentSettings;
