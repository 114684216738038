import './styles.css';

import { Dispatch, FC, SetStateAction, SyntheticEvent, useState } from 'react';
import { toast } from 'react-toastify';

import ArrowLeft from '../../assets/images/svg/arrow-left.svg';
import ArrowRight from '../../assets/images/svg/arrow-right.svg';
import Grid from '../../assets/images/svg/grid2.svg';
import Filter from '../../assets/images/svg/sliders.svg';
import { categoriesType } from '../../pages/product/Product';
import { myCatalogueDataType } from '../../redux/features/product/productRedux';
import { deleteProduct } from '../../redux/features/product/productService';
import CataloagueSplashScreen from '../CataloagueSplashScreen/CataloagueSplashScreen';
import DeleteProduct from '../DeleteProduct/DeleteProduct';
import ProductCard from '../ProductCard/ProductCard';
import ProductDetails from '../ProductDetails/ProductDetails';
import PublishProductModal from '../PubishProductModal/PublishProductModal';
import Modal from '../UI/Backdrop/Backdrop';
import Loading from '../UI/Spinner/Loading';

interface CatalogueProps {
    showFilter: boolean;
    toggleFilter: () => void;
    products: myCatalogueDataType[];
    pages: number[];
    records_per_page: number;
    categories?: categoriesType[];
    setModal?: Dispatch<SetStateAction<boolean>>;
    loader: boolean;
    fetchProducts: (current_page?: number, records_per_page?: number) => Promise<void>;
    toggleAddproductModal: () => void;
    toggleEditProductModal: () => void;
    count: number;
    handleToggleModal: () => void;
    current_page: number;
    total_pages: number;
}

const Catalogue: FC<CatalogueProps> = (props) => {
    const {
        showFilter,
        toggleFilter,
        setModal,
        categories,
        current_page,
        total_pages,
        products,
        count,
        handleToggleModal,
        fetchProducts,
        records_per_page,
        loader,
        toggleAddproductModal,
        toggleEditProductModal,
    } = props;
    const [showDeleteProduct, setShowDeleteProduct] = useState(false);
    const [showPublishProductModal, setShowPublishProductModal] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [clickedProduct, setClickedProduct] = useState<Record<string, unknown>>({});
    const [viewProductDetails, setViewProductDetails] = useState(false);

    const toggleMore = (e: SyntheticEvent, product: Record<string, unknown>) => {
        e.stopPropagation();
        setShowMore(true);
        setClickedProduct(product);
    };
    const deleteProductFromDB = async (productID: string) => {
        try {
            const response = await deleteProduct(productID);
            toast.success(response.data?.message);
            toggleDeleteProduct();
            fetchProducts();
        } catch (error: any) {
            toast.error(error.response.data?.message);
        }
    };

    const toggleShowPublishProductModal = () => {
        setShowPublishProductModal((prevState) => !prevState);
    };

    const toggleDeleteProduct = () => {
        setShowDeleteProduct((prevState) => !prevState);
    };

    const toggleViewProductDetails = (product: Record<string, unknown>, edit?: boolean) => {
        setClickedProduct(product);
        setModal && setModal(true);
        edit ? toggleEditProductModal() : setViewProductDetails((prevState) => !prevState);
    };

    return (
        <div>
            <div onClick={() => setShowMore(false)} className="pb-8">
                <Modal
                    showModal={showDeleteProduct || viewProductDetails || showPublishProductModal}
                    toggleEditProductModal={setViewProductDetails}
                    setModal={setModal}
                />
                <DeleteProduct
                    product={clickedProduct}
                    deleteProduct={deleteProductFromDB}
                    showDeleteProduct={showDeleteProduct}
                    toggleDeleteProduct={toggleDeleteProduct}
                />
                <ProductDetails
                    setModal={setModal}
                    categories={categories}
                    toggleViewProductDetails={toggleViewProductDetails}
                    toggleAddproductModal={toggleAddproductModal}
                    viewProductDetails={viewProductDetails}
                    clickedProduct={clickedProduct}
                    toggleEditProductModal={toggleEditProductModal}
                />
                <PublishProductModal showPublishProductModal={showPublishProductModal} setShowPublishProductModal={setShowPublishProductModal} />
                {loader ? (
                    <div className="min-h-[500px] flex justify-center flex-col">
                        <Loading />
                    </div>
                ) : (
                    <>
                        <p className="font-bold text-[0.78125rem] text-jumbleng-gray mb-2 -mt-2 md:hidden"> You have {count ? count : 0} products </p>
                        <div className="mb-[1.5rem] flex justify-between items-center">
                            <p className="font-bold text-[0.78125rem] text-jumbleng-gray -mt-2  hidden md:block">
                                {' '}
                                You have {count ? count : 0} products{' '}
                            </p>
                            <div className="flex items-center relative -mt-2">
                                <div className="w-[7.7rem] h-[2.3rem] relative mr-[45px]">
                                    <i className="fa fa-user icon absolute top-1/2 -translate-y-1/2 px-[0.79rem]" />
                                    <input
                                        className="w-full bg-[#F8F8FB] border-b-2 focus:outline-none pl-[2.08rem] h-full placeholder:text-[0.78165rem] text-[0.78125rem] text-jumbleng-gray placeholder:text-jumbleng-gray"
                                        placeholder="Search products"
                                    />
                                </div>
                                <div
                                    onClick={toggleFilter}
                                    className="flex hover:cursor-pointer text-[0.78125rem] mr-[45px] font-normal text-jumbleng-gray items-center"
                                >
                                    <span className="mr-[9px]">Filter</span>
                                    <img src={Filter} className="w-[1.17rem] h-[1.17rem]" alt="Filter" />
                                </div>
                                <div className="flex hover:cursor-pointer mr-2 text-[0.78125rem] font-normal text-jumbleng-gray items-center view__container">
                                    <span className="mr-[9px]">View</span>
                                    <img src={Grid} className="w-[1.17rem] h-[1.17rem]" alt="Grid" />
                                </div>

                                {showFilter && (
                                    <div className="absolute bg-white grid place-content-center text-[#525171] rounded-xl shadow-2xl shadow-[rgba(0,0,0,0.25)] w-[196px] h-[152px] right-[20%] top-[130%] p-[24px] z-[51]">
                                        <div>
                                            <div className="px-4 py-2 cursor-pointer" onClick={toggleFilter}>
                                                Unpublished
                                            </div>
                                            <div className="px-4 py-2 cursor-pointer" onClick={toggleFilter}>
                                                Published
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {products.length === 0 ? (
                            <CataloagueSplashScreen handleToggleModal={handleToggleModal} />
                        ) : (
                            <>
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(auto-fill, minmax(15.55rem, 1fr))',
                                        gridTemplateRows: 'auto',
                                    }}
                                >
                                    {products.map((product) => {
                                        return (
                                            <ProductCard
                                                showPublishProductModal={toggleShowPublishProductModal}
                                                toggleViewProductDetails={toggleViewProductDetails}
                                                clickedProduct={clickedProduct}
                                                id={product.id}
                                                key={product.id}
                                                product={product}
                                                setShowDeleteProduct={toggleDeleteProduct}
                                                showMore={showMore}
                                                toggleMore={toggleMore}
                                            />
                                        );
                                    })}
                                </div>

                                <div className="grid justify-center mt-5 text-[12px] md:text-[16px]">
                                    <div className="flex gap-2 md:gap-4 items-center">
                                        <span className="md:block hidden">Showing Result page </span>
                                        {current_page > 1 && (
                                            <p
                                                style={{ color: '#5E5BA7' }}
                                                className="flex items-center cursor-pointer "
                                                onClick={() => fetchProducts(current_page - 1, records_per_page)}
                                            >
                                                <img src={ArrowLeft} alt="arrowright" />
                                                <button>Prev</button>
                                            </p>
                                        )}
                                        <p
                                            className="bg-white px-2 md:px-4 "
                                            style={{
                                                border: '1px solid #9796A9',
                                                borderRadius: '4px',
                                            }}
                                        >
                                            {current_page}
                                        </p>
                                        <p> of</p>
                                        {total_pages} pages
                                        {current_page < total_pages && (
                                            <p
                                                style={{ color: '#5E5BA7' }}
                                                className="flex items-center cursor-pointer "
                                                onClick={() => fetchProducts(current_page + 1, records_per_page)}
                                            >
                                                <button>Next</button>
                                                <img src={ArrowRight} alt="arrowright" />
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Catalogue;
