import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import SaleDataService from '../services/SaleService';

const initialState: any = {};

export const retrieveCreditDue = createAsyncThunk('sales/credit', async () => {
    const res = await SaleDataService.getCreditDue();
    return res.data;
});

const saleSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {},
    extraReducers: {
        [retrieveCreditDue.fulfilled.toString()]: (state, action) => {
            return [action.payload];
        },
    },
});
const { reducer } = saleSlice;
export default reducer;
