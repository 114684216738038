import './styles.css';

import Popup from 'reactjs-popup';

import Filter from '../../assets/images/svg/funnel.svg';
import InfoIcon from '../../assets/images/svg/info.svg';
import ScaleDown from '../../assets/images/svg/scale-down.svg';
import ScaleUp from '../../assets/images/svg/trending-up.svg';
import { orderCountType } from '../../pages/dashboard/interface';
import { toLocaleFixed } from '../../utils/utils';

interface IProps {
    orderCount: orderCountType;
    filter: any[];
    orderPeriod: string;
    updateCountByStatus: (items: any, close: () => void) => void;
}

const DashboardOrders = ({ orderCount, filter, updateCountByStatus, orderPeriod }: IProps): JSX.Element => {
    return (
        <div className="w-full rounded-md bg-white mt-[0.78125rem] p-[0.78125rem]">
            <div className="w-full flex justify-between mb-[1.8rem]">
                <p className="font-semibold text-[1rem] text-jumbleng-gray">Orders</p>
                <div className="text-[0.78125rem] text-jumbleng-gray flex items-center gap-2 mr-4">
                    <img src={Filter} />
                    <Popup
                        trigger={
                            <div className="flex items-center cursor-pointer">
                                Filter: {orderPeriod} <i className="ml-2 fa-solid fa-chevron-down"></i>{' '}
                            </div>
                        }
                        closeOnDocumentClick
                        arrow={false}
                        contentStyle={{
                            padding: '10px',
                            borderRadius: '12px',
                            width: '120px',
                            fontSize: '0.8rem',
                        }}
                    >
                        {(close: () => void) => (
                            <div className=" flex items-center justify-center flex-col gap-2">
                                {filter.map((items: any) => (
                                    <div
                                        className="cursor-pointer hover:text-jumbleng-primary"
                                        key={items[0]}
                                        onClick={() => updateCountByStatus(items, close)}
                                    >
                                        {items[1]}
                                    </div>
                                ))}
                            </div>
                        )}
                    </Popup>
                </div>
            </div>

            <div className="order__grid">
                <div>
                    <p className="text-[0.78125rem]">Total value of sale orders</p>
                    <p className="font-semibold text-[1.855rem]">₦ {toLocaleFixed(orderCount.sale_total, 2)}</p>
                </div>
                <div>
                    <p className="text-[0.78125rem] flex">
                        Total actual orders <img src={InfoIcon} alt="info" className="pl-[0.73rem]" />
                    </p>
                    <p className="font-semibold text-[1.855rem]">{orderCount.actual}</p>
                    <div className="inline-block">
                        <span className="h-[1.26rem] flex items-center justify-around py-1 px-2 bg-[#F5E7E7] rounded-[1.2rem]">
                            <p className="text-[0.58rem] text-[#840B0B] font-semibold">-0.5%</p>
                            <img src={ScaleDown} alt="_" className="w-[16px] h-[16px]" />
                        </span>
                    </div>
                </div>
                <div>
                    <p className="text-[0.78125rem]">Total credit orders</p>
                    <p className="font-semibold text-[1.855rem]">{orderCount.credit}</p>
                    <div className="inline-block">
                        <span className="h-[1.26rem] flex items-center justify-around py-1 px-2 bg-[#DEEAE2] rounded-[1.2rem]">
                            <p className="text-[0.58rem] text-[#084F21] font-semibold">-0.5%</p>
                            <img src={ScaleUp} alt="_" className="w-[16px] h-[16px]" />
                        </span>
                    </div>
                </div>
                <div>
                    <p className="text-[0.78125rem]">Total fullfilled orders</p>
                    <p className="font-semibold text-[1.855rem]">{orderCount.fulfilled}</p>
                    <div className="inline-block">
                        <span className="h-[1.26rem] flex items-center justify-around py-1 px-2 bg-[#DEEAE2] rounded-[1.2rem]">
                            <p className="text-[0.58rem] text-[#084F21] font-semibold">-0.5%</p>
                            <img src={ScaleUp} alt="_" className="w-[16px] h-[16px]" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardOrders;
