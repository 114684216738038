import Cancel from '../../../../components/CancelButton/Cancel';
import PopUpModal from '../../../../components/PopModal/PopUpModal';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import { ICustomer, ISalesRep } from '../../../../redux/features/interfaces';
import { useGetCustomerSalesRepQuery } from '../../../../redux/services';
import CustomerAction from '../CustomerActionModal';
import EmptyCustomer from '../EmptyCustomer';

const CustomersSalesRep = ({
    close,
    customer,
    loader,
    unassignSalesRep,
}: {
    close: () => void;
    customer: ICustomer;
    loader: boolean;
    unassignSalesRep: (id: string, repId: string, close: () => void) => Promise<void>;
}) => {
    const { data, isLoading, refetch } = useGetCustomerSalesRepQuery(customer.company_id);

    return (
        <>
            <div className="mt-4 mr-4">
                <Cancel close={close} />
            </div>
            <div className="px-[24px] pb-[24px] h-auto w-[500px] ">
                <h2 className="text-center text-[24px] text-jumbleng-gray-main w-full font-bold mb-2">Unassign Sales Rep</h2>
                {data?.data.length === 0 && <EmptyCustomer />}
                {isLoading ? (
                    <Spinner />
                ) : (
                    <>
                        <p className="text-jumbleng-primary"> {data?.data.length} sales rep found</p>
                        <div>
                            {data?.data.map((salesRep: ISalesRep, index: number) => (
                                <div className="flex w-full justify-between mt-2 bg-grey-100 p-4" key={index}>
                                    <div className="text-sm flex gap-2 col-span-4 items-center">
                                        <div className="text-jumbleng-gray text-[16px] font-semibold">
                                            {salesRep.first_name} {salesRep.last_name}
                                        </div>
                                    </div>
                                    <PopUpModal
                                        content={
                                            <div className="grid content-center cursor-pointer">
                                                <div className="flex gap-1 items-center text-white rounded-lg bg-jumbleng-primary px-4 py-2">
                                                    <i className="fa fa-check"></i>
                                                    <p className="text-[14px]"> unassign </p>
                                                </div>
                                            </div>
                                        }
                                        className=""
                                        popupcontent={false}
                                        onClose={close}
                                    >
                                        <CustomerAction
                                            action={unassignSalesRep}
                                            id={customer.company_id}
                                            close={close}
                                            actionName={'unassign'}
                                            actionTitle={''}
                                            refetch={refetch}
                                            name={customer.name}
                                            loader={loader}
                                            repId={salesRep.id}
                                        />
                                    </PopUpModal>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default CustomersSalesRep;
