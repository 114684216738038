import { Progress } from '@material-tailwind/react';
import axios from 'axios';
import { Formik } from 'formik';
import { ChangeEvent, FC, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import HandIcon from '../../../assets/images/svg/hand.svg';
import JumPay from '../../../assets/images/svg/jumPay.svg';
import PurplePay from '../../../assets/images/svg/PurplePay.svg';
import Button from '../../../components/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { getSellerBankDetails } from '../../../redux/features/bank-payment/bankService';
import { closeModal, openModal } from '../../../redux/modal/modalRedux';
import { useMarkPaidMutation } from '../../../redux/services';
import { ProofUploadSchema } from '../../../schema/bank-payment.schema';
import { toLocaleFixed } from '../../../utils/utils';
const { REACT_APP_BACKEND_API, REACT_APP_FRONTEND_URL } = process.env;

interface IProps {
    company_name: string;
    amount: string;
    payment_id: string;
    invoice_no: string;
    email: string;
    close: () => void;
    reload?: boolean;
    invoice_id: string;
}

type IbankDetails = {
    id: string;
    created_at: string;
    updated_at: string;
    bank_name: string;
    bank_code: string;
    account_number: string;
    account_name: string;
    main: boolean;
};

const MakePaymentFilled: FC<IProps> = ({ payment_id, close, company_name, amount, invoice_no, email, invoice_id }) => {
    const [loading, setLoading] = useState(false);
    const [bankTransfer, setBankTransfer] = useState(false);
    const [errr, setError] = useState('');
    const [selected, setSelected] = useState<any>();
    const [file, setFile] = useState<any>();
    const [uploading, setUpLoading] = useState(0);
    const [uploadDone, setUploadDone] = useState(false);
    const [bankDetails, setbankDetails] = useState<IbankDetails>();
    const [err, setErr] = useState('');
    const dispatch = useDispatch();
    const [markPaid, { data: confirmpayment, isLoading, isSuccess, isError, error }] = useMarkPaidMutation();

    const handleOnSubmit = async () => {
        setSelected(file);
        const data = new FormData();
        data.append('proof', file);
        try {
            await axios.post(`${REACT_APP_BACKEND_API}/v1/payments/${payment_id}/pay/direct`, data, {
                onUploadProgress: (ProgressEvent) => {
                    setUpLoading((ProgressEvent.loaded / ProgressEvent.total) * 100);
                },
            });
        } catch (error) {
            setSelected(null);
            setUploadDone(false);
            setError(error?.response?.data?.message);
            toast.error(error?.response?.data?.message);
        }
    };

    const handleJumblePay = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(`${REACT_APP_BACKEND_API}/v1/payments/${payment_id}/pay/jumble?redirect_url=${REACT_APP_FRONTEND_URL}`);
            setLoading(false);
            location.href = data.data.payment_link;
        } catch (error) {
            setLoading(false);
            toast.error(error);
            close();
        }
        return true;
    };

    useEffect(() => {
        if (isLoading) {
            dispatch(openModal());
        }
        if (isSuccess) {
            toast.success(confirmpayment?.message);
            dispatch(closeModal());
            close();
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
            dispatch(closeModal());
            close();
        }
    }, [close, confirmpayment?.message, dispatch, error, isError, isLoading, isSuccess]);

    useEffect(() => {
        let timeId: any;
        if (Math.round(uploading) === 100 && !errr) {
            timeId = setTimeout(() => {
                setUpLoading(0);
                setUploadDone(true);
            }, 1000);
        }
        return () => {
            clearTimeout(timeId);
        };
    }, [uploading, errr]);

    useEffect(() => {
        if (invoice_id) {
            getSellerBankDetails(invoice_id)
                .then((response) => {
                    setbankDetails(response?.data);
                })
                .catch((err) => {
                    setErr(err?.response?.data?.message);
                });
        }
    }, [invoice_id]);

    const handleChange = async (event: ChangeEvent<HTMLInputElement>, setFieldValue: any, handleSubmit: any) => {
        if (!event.target.files) return;
        await setFieldValue('file', event.target.files[0]);
        setFile(event.target.files[0]);
        handleSubmit();
    };

    return (
        <div className="p-6">
            <div className="grid justify-center ">
                <div className="mb-2">
                    <p className="text-[#5E5BA7]">
                        <span className="font-bold">1</span> pending payment found
                    </p>
                </div>
                <div className="grid grid-cols-4 gap-10 mt-4 mb-4 justify-center  ">
                    <div className="col-span-2 flex gap-2 content-center">
                        <div className="grid content-center">
                            <input type="checkbox" />
                        </div>
                        <img src={HandIcon} alt="avatar" />
                        <div>
                            <p className="text-[#525171]">{company_name}</p>
                            <p className="text-[#706F77]"> {email}</p>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <p className="text-[#706F77] font-bold">Invoice number </p>
                        <p className="text-[#131221] font-bold">
                            INV <span className="text-[#706F77] font-normal">-{invoice_no}</span>
                        </p>
                    </div>
                    <div className="col-span-1">
                        <p className="text-[#706F77] font-bold">Total order value </p>
                        <p className="text-[#706F77] font-normal">N {toLocaleFixed(amount, 2)}</p>
                    </div>
                </div>
                {bankTransfer && (
                    <>
                        <div className={`grid grid-cols-2 mb-6 text-white p-4 bg-[#7976B6] rounded-lg`}>
                            <div>
                                <div className={'text-[#FFFFF]'}> Account name</div>
                                <div className={'text-[#FFFFF]'}> Accont Number</div>
                                <div className={'text-[#FFFFF]'}> Bank name</div>
                            </div>
                            <div className="grid justify-end">
                                <div className={`grid justify-end 'text-[#FFFFF]'}`}>{bankDetails?.account_name}</div>
                                <div className={`grid justify-end 'text-[#FFFFF]'}`}>{bankDetails?.account_number}</div>
                                <div className={`grid justify-end 'text-[#FFFFF]'}`}>{bankDetails?.bank_name} </div>
                            </div>
                        </div>
                    </>
                )}
                {selected ? (
                    <div className="w-[80%] mx-auto flex items-center gap-4">
                        <div className="w-full mx-auto flex items-center gap-2">
                            <i className="fa-solid fa-file text-jumbleng-gray"></i>
                            <div className="w-full">
                                <p className="text-[12px] text-jumbleng-gray">{selected.name}</p>
                                <p className="text-xs text-jumbleng-primary mb-2">
                                    {selected.size.toString().split('').length > 6
                                        ? toLocaleFixed(selected.size / 1000000, 1) + 'Mb'
                                        : toLocaleFixed(selected.size / 1000, 3) + 'kb'}{' '}
                                </p>
                                {!uploadDone && (
                                    <div className="form-group w-full">
                                        <Progress value={uploading} color="indigo" style={{ height: '2px' }} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="w-[10%] flex justify-end flex-col items-end">
                            {uploadDone ? (
                                <div>
                                    <p onClick={() => setSelected(null)} className="cursor-pointer">
                                        <i className="fa-solid fa-trash-can text-[20px] text-[#9F0D0D]"></i>
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <div>
                                        <p onClick={() => setSelected(null)} className="cursor-pointer mb-2">
                                            {Math.round(uploading) < 100 ? (
                                                <i className="fa-solid fa-xmark text-[20px] text-jumbleng-gray"></i>
                                            ) : (
                                                <i className="fa-solid fa-circle-check text-[20px] text-[green]"></i>
                                            )}{' '}
                                        </p>
                                    </div>
                                    <p>{Math.round(uploading)}%</p>
                                </>
                            )}
                        </div>
                    </div>
                ) : bankTransfer ? (
                    <Formik onSubmit={handleOnSubmit} initialValues={{ file: null }} validationSchema={ProofUploadSchema}>
                        {({ setFieldValue, handleBlur, errors, handleSubmit }) => (
                            <div className="w-[80%] mx-auto">
                                <p className="text-jumbleng-gray text-[14px]">Please upload the proof of your payment</p>
                                <label htmlFor="imageUpload">
                                    <div className="flex flex-col  gap-1 p-2 items-center justify-center border-dashed border-jumbleng-primary border-[1px] h-[6rem]">
                                        <p className="text-[12px] text-jumbleng-gray">Document should be in pdf or jpeg format</p>
                                        <p className="text-xs text-jumbleng-primary">
                                            <i className="fa-solid fa-file mr-1"></i>
                                            Upload file{' '}
                                        </p>
                                    </div>
                                </label>
                                <input
                                    name="file"
                                    onBlur={handleBlur}
                                    id="imageUpload"
                                    type="file"
                                    className="w-0.1 h-0.1 overflow-hidden hidden"
                                    onChange={(event: any) => {
                                        handleChange(event, setFieldValue, handleSubmit);
                                    }}
                                />
                                {errors.file && <div className="text-[0.7812rem] text-red-400 text-left font-normal">{errors.file}</div>}
                            </div>
                        )}
                    </Formik>
                ) : err ? (
                    <div className="mb-2">
                        <div className={'text-jumbleng-red italic'}> - {err}, You can't initiate bank transfer yet!</div>
                    </div>
                ) : (
                    <div className="flex justify-center gap-8">
                        <div
                            className="rounded-md cursor-pointer border-[1px] border-[#5E5BA7] px-[1.18rem] py-[0.42125rem] w-[222px] flex gap-1"
                            onClick={() => setBankTransfer(true)}
                        >
                            <img src={PurplePay} alt="purplepay" />
                            <p className="text-[#5E5BA7] font-semibold">Direct bank payment</p>{' '}
                        </div>

                        <div
                            className="rounded-md cursor-pointer border-[1px] border-[#5E5BA7] px-[1.18rem] py-[0.42125rem] w-[212px] flex gap-1"
                            onClick={handleJumblePay}
                        >
                            {loading ? (
                                <div className="flex items-center justify-center w-full">
                                    <Spinner />
                                </div>
                            ) : (
                                <>
                                    <img src={JumPay} alt="jumpay" />
                                    <p className="text-[#5E5BA7] font-semibold"> Pay with JumblePay</p>
                                </>
                            )}
                        </div>
                    </div>
                )}
                <div className={`${uploadDone ? 'mt-8' : 'mt-16'} w-full  mb-4`}>
                    <div className="flex flex-row justify-between ">
                        <div
                            className="cursor-pointer rounded-md  items-center grid  justify-center border-[1px] border-[#5E5BA7] px-[1.18rem] py-[0.52125rem] w-[168px]"
                            onClick={close}
                        >
                            <p className="font-semibold text-[0.78125rem] text-[#5E5BA7]">Cancel</p>{' '}
                        </div>
                        <Button
                            type={'button'}
                            disabled={!uploadDone ? true : false}
                            onClick={() => markPaid(payment_id)}
                            message={'I have made Payment'}
                            loading={isLoading}
                            className={` px-[1.18rem] py-[0.82125rem] w-[212px] ${
                                !uploadDone && 'cursor-not-allowed opacity-50'
                            }  h-[3.125rem] text-white font-semibold rounded-md bg-jumbleng-primary`}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MakePaymentFilled;
