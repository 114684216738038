import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';

import NotFound from '../../../assets/images/png/no-image.jpg';
import LeftArrow from '../../../assets/images/svg/arrow-left.svg';
import PopUpModal from '../../../components/PopModal/PopUpModal';
import { ISingleCustomer } from '../../../redux/features/interfaces';
import {
    useAssignSalesRepMutation,
    useDisconnectCustomerMutation,
    useReactivateCustomerMutation,
    useReassignSalesRepMutation,
    useSuspendCustomerMutation,
    useUnassignSalesRepMutation,
} from '../../../redux/services';
import { toggleCustomerVendorTab } from '../../../redux/slices/tabs';
import { RootState } from '../../../redux/store';
import { transformedDateWithTime } from '../../../utils/utils';
import CustomerAction from './CustomerActionModal';
import ApplyDiscount from './Popups/ApplyDiscount';
import AssignSalesRep from './Popups/AssignSalesRep';

interface ICustomerData {
    data: ISingleCustomer;
    unitData: string[];
}

const ExpandedCustomerHeader = ({ data, unitData }: ICustomerData) => {
    const [suspendCustomer, { isLoading, isSuccess, isError, error }] = useSuspendCustomerMutation();
    const [disconnectCustomer, { isLoading: loading, isSuccess: issuccess, isError: iserror, error: err }] = useDisconnectCustomerMutation();
    const [assignSalesRep, { isLoading: assignLoading, isSuccess: assignSuccess, isError: isAssignError, error: assignError }] =
        useAssignSalesRepMutation();
    const [reassignSalesRep, { isLoading: reassignLoading, isSuccess: reassignSuccess, isError: isreAssignError, error: reassignError }] =
        useReassignSalesRepMutation();
    const [unassignSalesRep, { isLoading: unassignLoading, isSuccess: unassignSuccess, isError: isunAssignError, error: unassignError }] =
        useUnassignSalesRepMutation();
    const [reactivateVendor, { isLoading: loader, isSuccess: Success, isError: Iserror, error: Err }] = useReactivateCustomerMutation();

    const { customerVendorTab } = useSelector((state: RootState) => state.tabsReducer);
    const dispatch = useDispatch();
    const toggleTab = (tabName: 'currentInventory' | 'buyingTrend' | 'Discount') => {
        dispatch(toggleCustomerVendorTab(tabName));
    };
    const navigate = useNavigate();

    const handleOnSubmit = async (values: any, close: () => void) => {
        await assignSalesRep({ id: data?.company.id, sales_reps: values.fields.map((item: any) => item.id) });
        close();
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Customer suspended successfully');
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
        }
    }, [error, isError, isLoading, isSuccess]);

    useEffect(() => {
        if (issuccess) {
            toast.success('Disconnected customer successfully');
            navigate(-1);
        }
        if (iserror && err && 'status' in err) {
            toast.error(err?.data?.message);
        }
    }, [err, iserror, issuccess, loading, navigate]);

    useEffect(() => {
        if (Success) {
            toast.success('Customer reactivated successfully');
        }
        if (Iserror && Err && 'status' in Err) {
            toast.error(Err?.data?.message);
        }
    }, [Err, Iserror, Success, loader]);

    useEffect(() => {
        if (assignSuccess) {
            toast.success('Customers assigned sales Rep successfully');
        }
        if (isAssignError && assignError && 'status' in assignError) {
            toast.error(assignError?.data?.message);
        }
    }, [assignError, assignSuccess, isAssignError, assignLoading]);

    useEffect(() => {
        if (unassignSuccess) {
            toast.success('Customer unassigned from sales Rep successfully');
        }
        if (isunAssignError && unassignError && 'status' in unassignError) {
            toast.error(unassignError?.data?.message);
        }
    }, [isunAssignError, unassignError, unassignLoading, unassignSuccess]);

    useEffect(() => {
        if (reassignSuccess) {
            toast.success('Customer reassigned sales Rep successfully');
        }
        if (isreAssignError && reassignError && 'status' in reassignError) {
            toast.error(reassignError?.data?.message);
        }
    }, [isreAssignError, reassignError, reassignSuccess, reassignLoading]);

    return (
        <div className="mt-8">
            <Link to="/vendorlist">
                <div className="flex text-[#5E5BA7]">
                    <img src={LeftArrow} alt="leftarrow" className="pr-2" />
                    Back
                </div>
            </Link>

            <div className="mt-8 mb-4 grid  grid-cols-4 w-[80%]">
                <div className="col-span-1">
                    <div className="text-sm flex gap-2 col-span-4 rounded-full">
                        <div className="flex gap-2 items-center  rounded-full">
                            <img src={NotFound} alt="avatar" className="h-[3.2rem] w-[3.2rem] rounded-full object-contain" />
                        </div>{' '}
                        <div className="text-[#525171]  text-[18px] flex items-center">{data.company.name}</div>
                    </div>
                </div>
                <div className="col-span-1">
                    <div className="font-bold text-[#525171] text-[16px]">Industry</div>
                    <div className="text-[#525171] text-[16px]">{data.company.industry_type}</div>
                </div>
                <div className="col-span-1">
                    <div className="font-bold text-[#525171] text-[16px]">Group</div>
                    {data?.groups.map((item, index) => (
                        <div className="text-[#525171] text-[16px]" key={index}>
                            {transformedDateWithTime(item?.created_at)}
                        </div>
                    ))}
                </div>

                <div className="col-span-1">
                    <div className="font-bold text-[#525171] text-[16px]">Status</div>
                    <div className=" grid justify-start">
                        {data.vendor_status === 'Active' ? (
                            <div className=" flex items-center capitalize gap-2 text-[#084F21] text-sm bg-[#DEEAE2] py-1 px-5 rounded-2xl h-[30px]">
                                <div className="h-3 w-3 rounded-full border border-[#327a4b] object-contain">
                                    <div className="h-2 w-2 border rounded-full bg-[#327a4b] my-[1px] mx-auto" />
                                </div>
                                {data.vendor_status}
                            </div>
                        ) : (
                            <div className=" flex items-center gap-2 text-[#840B0B] text-sm bg-[#F5E7E7] py-1 px-5 rounded-2xl h-[30px]">
                                Suspended
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <hr className="mb-4" />
            <div className="flex justify-between">
                <ul className="flex">
                    {/* <li className="flex items-center">
                        <a
                            href="#"
                            onClick={() => toggleTab('currentInventory')}
                            className={`${
                                customerVendorTab === 'currentInventory' && 'font-bold border-b border-b-jumbleng-primary'
                            } w-full px-4 py-[0.78125rem] text-[0.78125]`}
                        >
                            Current inventory{' '}
                        </a>
                    </li>
                    <li className="flex items-center">
                        <a
                            href="#"
                            onClick={() => toggleTab('buyingTrend')}
                            className={`${
                                customerVendorTab === 'buyingTrend' && 'font-bold border-b border-b-jumbleng-primary'
                            } w-full px-4 py-[0.78125rem] text-[0.78125]`}
                        >
                            Buying trends{' '}
                        </a>
                    </li> */}
                    <li className="flex items-center">
                        <a
                            href="#"
                            onClick={() => toggleTab('Discount')}
                            className={`${
                                customerVendorTab === 'Discount' && 'font-bold border-b border-b-jumbleng-primary'
                            } w-full px-4 py-[0.78125rem] text-[0.78125]`}
                        >
                            Discount
                        </a>
                    </li>
                </ul>
                <div className="mt-4 grid justify-end mb-4">
                    <div className="flex gap-4 items-center mr-4">
                        <Popup
                            trigger={
                                <div
                                    className="flex gap-1 items-center rounded-xl  py-[10px] px-[8px] text-[#5E5BA7] text-[16px] cursor-pointer"
                                    style={{ border: '1px solid #5E5BA7' }}
                                >
                                    <i className="fa-solid fa-user-plus mr-1"></i>Assign sales rep
                                </div>
                            }
                            position="right center"
                            contentStyle={{ width: 'fit-content', overflow: 'auto' }}
                            modal
                            nested
                        >
                            {(close: () => void) => (
                                <AssignSalesRep close={close} handleSubmit={handleOnSubmit} loading={assignLoading} customer={data} />
                            )}
                        </Popup>
                        <Popup
                            trigger={
                                <div className="flex gap-1 rounded-xl bg-jumbleng-primary items-center py-[10px] px-[2rem] text-[#fff] text-[16px] mr-2 cursor-pointer">
                                    <i className="mr-1 fa-solid fa-gear"></i> Settings
                                </div>
                            }
                            on="hover"
                            closeOnDocumentClick
                            arrow={false}
                            nested
                            contentStyle={{
                                padding: '10px',
                                borderRadius: '12px',
                                width: '167px',
                            }}
                        >
                            {(close: () => void) => (
                                <div className=" grid justify-center text-jumbleng-primary">
                                    <Popup
                                        trigger={
                                            <div className="grid justify-center mt-2 mb-2 hover:text-jumbleng-gray cursor-pointer">
                                                Apply discount
                                            </div>
                                        }
                                        onClose={close}
                                        closeOnDocumentClick={false}
                                        arrow={false}
                                        modal
                                        contentStyle={{ width: '700px', padding: '0px', border: 'none' }}
                                    >
                                        <ApplyDiscount close={close} unitData={unitData} company_id={data?.company.id} />
                                    </Popup>
                                    {data.vendor_status === 'Active' ? (
                                        <PopUpModal
                                            content={
                                                <div className="grid justify-center mt-2 mb-2 hover:text-jumbleng-gray cursor-pointer">
                                                    Suspend vendor
                                                </div>
                                            }
                                            className=""
                                            popupcontent={false}
                                            onClose={close}
                                        >
                                            <CustomerAction
                                                action={suspendCustomer}
                                                id={data.company.id}
                                                close={close}
                                                actionName={'suspend'}
                                                actionTitle={''}
                                                name={data.company.name}
                                                loader={isLoading}
                                            />
                                        </PopUpModal>
                                    ) : (
                                        <PopUpModal
                                            content={
                                                <div className="grid justify-center mt-2 mb-2 hover:text-jumbleng-gray cursor-pointer">
                                                    Reactivate vendor
                                                </div>
                                            }
                                            className=""
                                            popupcontent={false}
                                            onClose={close}
                                        >
                                            <CustomerAction
                                                action={reactivateVendor}
                                                id={data.company.id}
                                                close={close}
                                                actionName={'reactivate'}
                                                actionTitle={''}
                                                name={data.company.name}
                                                loader={loader}
                                            />
                                        </PopUpModal>
                                    )}
                                    <PopUpModal
                                        content={
                                            <div className="grid justify-center mt-2 mb-2 hover:text-jumbleng-gray cursor-pointer">
                                                Disconnect vendor
                                            </div>
                                        }
                                        className=""
                                        popupcontent={false}
                                        onClose={close}
                                    >
                                        <CustomerAction
                                            action={disconnectCustomer}
                                            id={data.company.id}
                                            close={close}
                                            actionName={'disconnect'}
                                            actionTitle={''}
                                            name={data.company.name}
                                            loader={loading}
                                        />
                                    </PopUpModal>
                                    <PopUpModal
                                        content={
                                            <div className="grid justify-center mt-2 mb-2 hover:text-jumbleng-gray cursor-pointer">
                                                Reassign sales rep
                                            </div>
                                        }
                                        className=""
                                        popupcontent={false}
                                        onClose={close}
                                    >
                                        <CustomerAction
                                            action={reassignSalesRep}
                                            id={data?.company.id}
                                            close={close}
                                            actionName={'reassign sales rep to'}
                                            actionTitle={''}
                                            name={data.company.name}
                                            loader={reassignLoading}
                                        />
                                    </PopUpModal>
                                    <PopUpModal
                                        content={
                                            <div className="grid justify-center mt-2 mb-2 hover:text-jumbleng-gray cursor-pointer">
                                                Unassign sales rep
                                            </div>
                                        }
                                        className=""
                                        popupcontent={false}
                                        onClose={close}
                                    >
                                        <CustomerAction
                                            action={unassignSalesRep}
                                            id={data?.company.id}
                                            close={close}
                                            actionName={'unassign sales rep from'}
                                            actionTitle={''}
                                            name={data.company.name}
                                            loader={reassignLoading}
                                        />
                                    </PopUpModal>
                                </div>
                            )}
                        </Popup>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExpandedCustomerHeader;
