import { Iaction } from '../../redux/features/product/productRedux';

export interface DProps {
    setCreateProductField: (payload: any) => Iaction;
}
export type uomTypes = { key: string; value: string };
export type categoriesType = { id: string; created_at: string; updated_at: string; name: string };
export type orderCountType = { actual: any; credit: any; fulfilled: any; sale_total: any };
export type salesCountType = { completed: any; credit: any; uncompleted: any };
export type revenueCountType = { actual: any; credit: any; total: any };
export type dashboardFilters = { last_7_days: string; last_week: string; this_week: string; today: string; yesterday: string };

export type dashboardParams = {
    type: string;
    status: string;
    filter: string;
};

export const initialOrderParams: dashboardParams = {
    type: 'order',
    status: 'actual',
    filter: 'this_week',
};
export const initialRevenueParams: dashboardParams = {
    type: 'revenue',
    status: 'actual',
    filter: 'this_week',
};
export const initialSalesParams: dashboardParams = {
    type: 'sales',
    status: 'credit',
    filter: 'this_week',
};
