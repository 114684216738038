import styled from 'styled-components';

export const Modaled = styled.div`
    .darkBG {
        background-color: rgba(0, 0, 0, 0.2);
        width: 250vh;
        height: 250vh;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: fixed;
    }

    .centered {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 12;
    }

    .modal {
        width: 502px;
        height: fit-content;
        background: white;
        padding: 30px;
        color: white;
        z-index: 10;
        border-radius: 16px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .modalContent {
        padding: 10px;
        font-size: 14px;
        color: #2c3e50;
        text-align: center;
        display: flex;
        flex-direction: column;
        margin: 10px auto;
        gap: 25px;

        img {
            width: 40px;
            height: 40px;
            align-self: center;
        }
    }

    .actionsContainer {
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
        padding: 0 20px;
        align-items: center;
        flex-wrap: wrap;
        gap: 30px;
    }
    .loader__container {
        background-color: #5e5ba7;
        height: 50px;
        width: 180px;
        border-radius: 12px;
        display: flex;
        cursor: not-allowed;
        justify-content: center;
        align-items: center;

        .loader {
            width: 4px;
            aspect-ratio: 1;
            border-radius: 50%;
            box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
            transform: translateX(-38px);
            animation: d1 0.5s infinite alternate linear;
            margin: auto;
        }

        @keyframes d1 {
            50% {
                box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
            }
            100% {
                box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
            }
        }
    }

    .closeBtn {
        cursor: pointer;
        font-weight: 500;
        padding: 4px 8px;
        border-radius: 8px;
        border: none;
        font-size: 18px;
        color: #2c3e50;
        background: white;
        transition: all 0.25s ease;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
        position: absolute;
        right: 0;
        top: 0;
        align-self: flex-end;
        margin-top: 10px;
        margin-right: 10px;

        img {
            width: 15px;
        }
    }

    .closeBtn:hover {
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
        transform: translate(-4px, 4px);
    }

    .cancelBtn {
        cursor: pointer;
        font-weight: 500;
        padding: 14px 20px;
        border-radius: 12px;
        font-size: 0.8rem;
        border: none;
        color: #5e5ba7;
        font-weight: bold;
        background: transparent;
        transition: all 0.25s ease;
        border: 1px solid #5e5ba7;
        width: 180px;
    }

    .cancelBtn:hover {
        box-shadow: none;
        transform: none;
        background: whitesmoke;
    }
    .sendBtn {
        cursor: pointer;
        font-weight: 500;
        padding: 14px 20px;
        border-radius: 12px;
        font-size: 0.8rem;
        border: none;
        font-weight: bold;
        color: #fff;
        background: #9f0d0d;
        transition: all 0.25s ease;
        width: 180px;
    }

    .sendBtn:hover {
        box-shadow: none;
        transform: none;
        background: #5e5ba750;
    }
`;
