import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getCompanyIndustries, getCompanySize, getCompanyTypes } from '../../../redux/features/auth/authService';
import { EregistrationSteps } from '../../../redux/features/interfaces';
import CompanyDetails from './companyDetails/CompanyDetails';
import PersonalDetails from './PersonalDetails';
import Verification from './verification/Verification';

interface Iregistration {
    registration_step: EregistrationSteps;
}

const Registration: React.FC<Iregistration> = ({ registration_step }) => {
    const [industryTypes, setIndustryTypes] = useState<
        {
            key: string;
            value: string;
        }[]
    >([]);
    const [companyTypes, setCompanyTypes] = useState<
        {
            key: string;
            value: string;
        }[]
    >([]);
    const [companySize, setCompanySize] = useState<
        {
            key: string;
            value: string;
        }[]
    >([]);

    const [credentialsLoad, setCredentialsLoad] = useState<boolean>(true);

    useEffect(() => {
        try {
            const getCredentials = async () => {
                const response = await Promise.all([getCompanyIndustries(), getCompanyTypes(), getCompanySize()]);
                setIndustryTypes(response[0]);
                setCompanyTypes(response[1]);
                setCompanySize(response[2]);
                setCredentialsLoad(false);
            };
            getCredentials();
        } catch (e) {
            //flash the reason here
        }
    }, []);

    switch (registration_step) {
        case EregistrationSteps.CompanyDetails:
            return (
                <CompanyDetails
                    industryTypes={industryTypes}
                    credentialsLoad={credentialsLoad}
                    companyTypes={companyTypes}
                    companySize={companySize}
                />
            );
        case EregistrationSteps.Verification:
            return <Verification />;
        default:
            return <PersonalDetails />;
    }
};

export default connect((state: any): { registration_step: EregistrationSteps } => {
    const { registration_step } = state.authStore;
    return { registration_step };
})(Registration);
