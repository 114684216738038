import './Signin.css';

import { ErrorMessage, Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import AuthNav from '../../../components/AuthNav/AuthNav';
import Button from '../../../components/Button';
import GuestRoute from '../../../HOC/GuestRoute';
import { ISignInValues } from '../../../interfaces/schema-interface';
import { actions } from '../../../redux/features/auth/authRedux';
import { login } from '../../../redux/features/auth/authService';
import { authorizationType, companyType, Iaction, userType } from '../../../redux/features/interfaces';
import { initialSignInValues, SignInSchema } from '../../../schema';

interface ISignIn {
    setAllUserDetails: (payload: { user: userType; company: companyType; authorization: authorizationType }) => Iaction;
}

const Signin: React.FC<ISignIn> = ({ setAllUserDetails }) => {
    const navigate = useNavigate();
    const [buttonLoad, setButtonLoad] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const togglePassword = () => {
        setShowPassword((prev) => !prev);
    };

    const handleSignInSubmit = async (values: ISignInValues) => {
        try {
            setButtonLoad(true);
            const cleanedData = values.email.toLowerCase();
            const response = await login({ ...values, email: cleanedData });
            setAllUserDetails({ ...response, authorization: response.authorization ? response.authorization : {} });
            response.user.twoFA ? navigate('/verification') : navigate('/dashboard');
        } catch (err: any) {
            if (err.response?.data?.message === 'company has not been verified. please complete verification.') {
                navigate('/verify-company');
            }
            setError(err.response?.data?.message);
            setButtonLoad(false);
        }
    };

    useEffect(() => {
        const timeId = setTimeout(() => {
            setError('');
        }, 3000);
        return () => {
            clearTimeout(timeId);
        };
    }, [error]);
    return (
        <GuestRoute>
            <div className="w-full bg-jumbleng-background min-h-screen">
                <AuthNav />
                <div className="form__container ">
                    <Formik onSubmit={handleSignInSubmit} initialValues={initialSignInValues} validationSchema={SignInSchema}>
                        {({ handleSubmit }) => (
                            <>
                                <h2 className="font-semibold mt-[4rem] md:mt-[3.12rem] md:mb-[1rem] text-[2.375rem] text-jumbleng-gray-main  input__group">
                                    Welcome back
                                </h2>
                                <p className="text-[0.78125] font-normal text-jumbleng-gray mb-[3.5rem] input__group">We missed you around here.</p>

                                <div className="mb-[1.953rem] border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1] input__group">
                                    <p className="text-[0.7812rem] text-left font-normal text-jumbleng-gray-main">Enter Email</p>
                                    <div className="field__container">
                                        <img
                                            src="https://res.cloudinary.com/odd-developer/image/upload/v1655387139/mail-icon_ri83ug.svg"
                                            alt=""
                                            className="field__img"
                                        />
                                        <Field
                                            onKeyDown={(e: any) => {
                                                e.key === 'Enter' && handleSubmit();
                                            }}
                                            type={'email'}
                                            name={'email'}
                                            placeholder={'example@example.com'}
                                            className={'input__container bg-jumbleng-background placeholder:text-[0.78125rem] focus:outline-none'}
                                        />
                                    </div>
                                    <ErrorMessage
                                        name={'email'}
                                        render={(msg) => (
                                            <div className="text-[0.7812rem] text-red-400 text-left font-normal fon text-jumbleng-gray-main">
                                                {msg}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="mb-[1rem] border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1] input__group">
                                    <p className="text-[0.7812rem] text-left font-normal text-jumbleng-gray-main">Enter Password</p>
                                    <div className="field__container">
                                        <img
                                            src="https://res.cloudinary.com/odd-developer/image/upload/v1655387139/lock-icon_tx1qlt.svg"
                                            alt=""
                                            className="field__img"
                                        />
                                        <Field
                                            onKeyDown={(e: any) => {
                                                e.key === 'Enter' && handleSubmit();
                                            }}
                                            type={`${showPassword ? 'text' : 'password'}`}
                                            name={'password'}
                                            placeholder={'XXXXXXXXX'}
                                            className={'input__container bg-jumbleng-background placeholder:text-[0.78125rem] focus:outline-none'}
                                        />
                                        {error && <i className="fa-solid fa-circle-exclamation text-[20px] text-red-500"></i>}
                                        {!error && (
                                            <i
                                                onClick={togglePassword}
                                                className={`text-gray-500 fa-solid fa-eye${showPassword ? '' : '-slash'}`}
                                            ></i>
                                        )}
                                    </div>
                                    <ErrorMessage
                                        name={'password'}
                                        render={(msg) => (
                                            <div className={'text-[0.7812rem] text-red-400 text-left font-normal text-jumbleng-gray-main'}>{msg}</div>
                                        )}
                                    />
                                </div>
                                {error && <div className="text-[0.7812rem] text-red-600 text-left font-normal">{error}</div>}
                                <div className="flex text-jumbleng-primary justify-end input__group">
                                    <Link to="/forgot-password">Forgot Password?</Link>
                                </div>

                                <div className={`flex items-center justify-center input__group  ${buttonLoad && 'mt-12 mb-4'}`}>
                                    <Button
                                        type={'button'}
                                        onClick={handleSubmit}
                                        message={'Login'}
                                        loading={buttonLoad}
                                        className={`w-[23.5rem] h-[3.125rem]   mb-[1rem] md:mb-[1.953rem] text-white text-[0.78125rem] font-semibold rounded-[12px] bg-jumbleng-primary md:mt-[4.75rem] mt-[2.6rem]`}
                                    />
                                </div>
                                <p className="text-[0.78125rem] font-normal text-jumbleng-gray-main md:hidden">
                                    Don&apos;t have an account?
                                    <span className="ml-2 text-jumbleng-primary">
                                        <Link to="/register">Create account</Link>
                                    </span>
                                </p>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
        </GuestRoute>
    );
};

export default connect(null, actions)(Signin);
