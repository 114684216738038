import { BaseQueryFn, createApi, FetchArgs } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth, createRequest, createRequestWithParams, CustomError } from './shared';

export const requestApi = createApi({
    reducerPath: 'requestApi',
    baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, any>>,
    tagTypes: ['sent', 'received', 'company', 'catalogue'],
    endpoints: (builder) => ({
        sendRequest: builder.mutation({
            query: (id) => {
                return {
                    url: `connections`,
                    method: 'post',
                    body: { company_id: id },
                };
            },
            invalidatesTags: ['sent', 'company'],
        }),

        getReceivedRequests: builder.query<any, void>({
            query: () => createRequest(`connections/requests`),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result.data.map(({ id }: any) => ({ type: 'received' as const, id })), 'received'] : ['received'],
        }),

        getSentRequests: builder.query<any, { status: string; page: number; limit: number }>({
            query: ({ status, page, limit }) => createRequestWithParams('connections/sent', { status, page, limit }),
            providesTags: ['sent'],
        }),

        approveRequest: builder.mutation({
            query: (id) => {
                return {
                    url: `connections/${id}/accept`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'received', id }, 'company'],
        }),

        declineRequest: builder.mutation({
            query: (id) => {
                return {
                    url: `connections/${id}/decline`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'received', id }, 'company'],
        }),

        getPendingRequests: builder.query<any, void>({
            query: () => createRequest(`connections/sent?status=pending&page=1&limit=10`),
            providesTags: ['sent'],
        }),

        getRejectedRequests: builder.query<any, void>({
            query: () => createRequest(`connections/sent?status=declined&page=1&limit=10`),
            providesTags: ['sent'],
        }),

        getApprovedRequests: builder.query<any, void>({
            query: () => createRequest(`connections/sent?status=accepted&page=1&limit=10`),
            providesTags: ['sent'],
        }),
    }),
});
