import { Dispatch } from 'redux';

const initialState = false;

const modalReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case 'MODAL':
            return action.payload;
        default:
            return state;
    }
};

export default modalReducer;

//action creators

export const closeModal = () => (dispatch: Dispatch) => {
    dispatch({ type: 'MODAL', payload: false });
};
export const openModal = () => (dispatch: Dispatch) => {
    dispatch({ type: 'MODAL', payload: true });
};
