import { ErrorMessage, Field } from 'formik';

const SelectField = ({ label, name, placeholder, options }: { label?: string; name: string; placeholder: string; options: any[] }) => {
    return (
        <div className="flex flex-col">
            {label && <p className="text-[#525171] text-[16px] mt-4">{label}</p>}{' '}
            <Field as={'select'} name={name} placeholder={placeholder} className="focus:outline-none p-[0.649rem] border-b-2 border-b-[#CFCFD1]">
                <option value="">Select {label} </option>
                {options?.map((item: any, index: number) => (
                    <option key={index} value={item}>
                        {item}
                    </option>
                ))}{' '}
            </Field>
            <ErrorMessage name={name} render={(msg: any) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>} />
        </div>
    );
};

export default SelectField;
