import { useMemo, useState } from 'react';

import { useGetStoreProductsQuery } from '../../../redux/services/storeApi';
import {
    ProductContainer,
    ProductItem,
    ProductItemContent,
    ProductItemImage,
    ProductItemParagraph,
    ProductItemQuantity,
    ProductSearchBox,
    ProductSearchContainer,
    ProductSearchInput,
    ProductsGrid,
} from './styles';

const Products = () => {
    const resp = useGetStoreProductsQuery();
    const { data: productsResponseData, isSuccess } = resp;
    const [searchValue, setSearchValue] = useState('');
    const searchedProducts = useMemo(() => {
        console.log(productsResponseData);
        return isSuccess && productsResponseData
            ? productsResponseData.data.products.filter((product) => product.name.toLowerCase().includes(searchValue.toLowerCase()))
            : [];
    }, [searchValue, productsResponseData]);

    return (
        <ProductContainer>
            <ProductSearchContainer>
                <ProductSearchBox>
                    <ProductSearchInput type="text" onChange={(e) => setSearchValue(e.target.value)} placeholder="Search products" />
                </ProductSearchBox>
            </ProductSearchContainer>
            {isSuccess && (
                <ProductsGrid>
                    {searchedProducts.map((product, index) =>
                        product ? (
                            <ProductItem key={index}>
                                {product.images && product.images.length > 0 && <ProductItemImage src={product.images[0]} alt="" />}
                                <ProductItemContent>
                                    <ProductItemParagraph>{product.name}</ProductItemParagraph>
                                    <ProductItemParagraph>
                                        Available: <ProductItemQuantity>{product.qty}</ProductItemQuantity>
                                    </ProductItemParagraph>
                                    <ProductItemParagraph>₦ {product.price}</ProductItemParagraph>
                                </ProductItemContent>
                            </ProductItem>
                        ) : null,
                    )}
                </ProductsGrid>
            )}
        </ProductContainer>
    );
};

export default Products;
