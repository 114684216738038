import { useState } from 'react';

export const useDisclosure = (initialValue: boolean) => {
    const [isOpen, setIsOpen] = useState(initialValue);

    const toggle = () => {
        setIsOpen((isOpen) => !isOpen);
    };

    return {
        isOpen,
        toggle,
        setIsOpen,
    };
};
