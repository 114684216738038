import * as Yup from 'yup';

import { IPersonalDetails } from '../interfaces';

export const initialPersonalDetailsValues: IPersonalDetails = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone_number: '+234',
    role: 'sales_officer',
};

export const PersonalDetailsSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is a required field'),
    last_name: Yup.string().required('Last name is a required field'),
    email: Yup.string().email('Invalid email format').trim().required('Email is a required field'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'minimum of 8 characters')
        .matches(/[@$%#]+/, 'Must contain at least one of @, $, #, %'),
    phone_number: Yup.number()
        .required('Phone number  is a required field')
        .typeError('Only digit(s) is allowed')
        .test('len', 'Phone number must be 10 digits', (val: any) => val?.toString().length === 10),
    role: Yup.string().required(),
});
