import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import AuthNav from '../../../components/AuthNav/AuthNav';
import GuestRoute from '../../../HOC/GuestRoute';
import { RootState } from '../../../redux/store';

const PasswordResetComplete = () => {
    const { reset_type } = useSelector((state: RootState) => state.authStore);

    return (
        <GuestRoute>
            <div className="w-full bg-jumbleng-background min-h-screen">
                <AuthNav />
                <div className="w-[24rem] mx-auto mt-[3.12rem]">
                    <div className="flex items-center justify-center mb-[1rem]">
                        <span>
                            <i className="fas fa-check-circle text-[4em] text-jumbleng-primary"></i>
                        </span>
                    </div>
                    <h2 className="text-[1.85rem] font-semibold mb-[0.78125rem] text-center">
                        {reset_type === 'reset' ? 'Password Reset Complete!' : 'Password Changed!'}
                    </h2>
                    <div className="15.67rem mx-auto text-center">
                        <p className="mb-5">You have successfully{reset_type === 'reset' ? ' reset ' : ' changed '} your password</p>
                        <p>
                            You can proceed to{' '}
                            <Link to="/" className="cursor-pointer text-jumbleng-primary">
                                sign in{' '}
                            </Link>
                            now
                        </p>
                    </div>
                </div>
            </div>
        </GuestRoute>
    );
};

export default PasswordResetComplete;
