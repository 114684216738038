import EmptyRequest from '../../../assets/images/svg/emptyPayment.svg';

const MakePaymentEmpty = () => {
    return (
        <>
            <div className="grid justify-center mt-4">
                <img src={EmptyRequest} alt="empty" />
            </div>
            <div className="grid justify-center ">
                <div style={{ width: '376px' }} className="grid justify-center mt-4">
                    {/* <p className="grid justify-center text-[#131221] font-semibold text-[28px] mb-2">No pending payment found.</p> */}
                    <p className="grid justify-center text-[#131221] font-semibold text-[28px] mb-2">No pending payment selected.</p>
                    {/* <p className="grid justify-center mb-8">You have no pending payment to make.</p> */}
                    <p className="grid justify-center mb-8">Select an order item you want to pay for.</p>
                </div>
            </div>
        </>
    );
};

export default MakePaymentEmpty;
