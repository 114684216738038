import './index.css';

import axios from 'axios';
import React from 'react';
import { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import setUpAxios from './redux/setup-axios';
import { store } from './redux/store';

setUpAxios(axios, store);

const ProviderFixed = Provider as unknown as React.FC<PropsWithChildren<{ store: any }>>;

ReactDOM.render(
    <React.StrictMode>
        <ProviderFixed store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ProviderFixed>
    </React.StrictMode>,
    document.getElementById('root'),
);
