import axios from 'axios';
import { FC, FormEvent, useState } from 'react';
const { REACT_APP_BACKEND_API } = process.env;
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import BackArrow from '../../../../assets/images/svg/arrow-left.svg';
import OTPInput from '../../../../components/OTPInput/Index';
import SignupSteps from '../../../../components/SignupSteps/SignupSteps';
import Spinner from '../../../../components/UI/Spinner/Spinner';
import { actions } from '../../../../redux/features/auth/authRedux';
import { resendOtp } from '../../../../redux/features/auth/authService';
import { userType } from '../../../../redux/features/interfaces';

const Verification: FC<{ user: userType; logout: () => void }> = ({ user, logout }) => {
    const [loading, setLoading] = useState(false);
    const [resendOtpLoader, setResendOtpLoader] = useState(false);
    const [otp, setOtp] = useState('');

    const navigate = useNavigate();

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (otp.length === 6) {
            setLoading(true);
            const { email } = user;
            try {
                const { data } = await axios.post(`${REACT_APP_BACKEND_API}/v1/company/verify`, {
                    email,
                    otp,
                });
                toast.success(data?.message);
                setLoading(false);
                navigate('/');
                logout();
            } catch (error: any) {
                setLoading(false);
                toast.error(error?.response?.data?.message);
            }
        }
    };

    const handleOtpResend = async () => {
        try {
            setResendOtpLoader(true);
            const { email } = user;
            const response = await resendOtp(email);
            toast.success(response.message);
            setResendOtpLoader(false);
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
            setResendOtpLoader(false);
        }
    };

    const handleClick = () => {
        logout();
        navigate(-1);
    };

    return (
        <div className="w-full min-h-screen flex ">
            <SignupSteps step={3} />

            <div className="w-full px-[2.93rem] mb-[5.22rem]">
                <button onClick={handleClick}>
                    <div className="mt-[2.146rem] mb-[5.4rem] flex items-center">
                        <img src={BackArrow} /> <span className="text-[0.781rem] ml-[4px] text-jumbleng-primary">Back</span>
                    </div>
                </button>

                <form onSubmit={submitHandler} className="md:w-[25rem] mx-auto">
                    <h2 className="text-[1.45rem] md:text-[1.85rem]  text-center font-semibold mb-[0.78125rem]">Verification email sent</h2>

                    <p className="text-center text-[0.78125rem] font-normal text-jumbleng-gray mb-[1.95rem]">
                        Please enter the verification code sent to your email.
                    </p>
                    <OTPInput
                        autoFocus
                        isNumberInput
                        length={6}
                        className="mb-[1.46rem] flex items-center  justify-center"
                        inputClassName=" border-b-2 text-[0.78125rem] font-normal text-jumbleng-gray-main outline-none border-b-jumbleng-primary text-center w-[1.3335rem]  md:w-[3.3335rem] text-4xl mr-[0.83rem]"
                        onChangeOTP={(otp) => setOtp(otp)}
                    />
                    <div className="15.67rem mx-auto text-center">
                        {loading ? (
                            <Spinner />
                        ) : (
                            <button className="w-[6.3335rem] md:w-[15.67rem] mx-auto h-[3.125rem] bg-jumbleng-primary text-white rounded-lg">
                                Verify
                            </button>
                        )}

                        <p className="font-normal mt-[0.78125rem] text-[0.78125rem] text-jumbleng-gray">
                            Didn’t get any verification email?{' '}
                            {resendOtpLoader ? (
                                <span className="font-semibold text-[0.78rem] text-jumbleng-primary">Loading...</span>
                            ) : (
                                <button onClick={handleOtpResend} className="font-semibold text-[0.78rem] text-jumbleng-primary">
                                    Resend email
                                </button>
                            )}
                        </p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default connect((state: any) => {
    const { user } = state.authStore;
    return { user };
}, actions)(Verification);
