import { Link } from 'react-router-dom';

import NotFound from '../../../assets/images/png/no-image.jpg';
import LoaderIcon from '../../../assets/images/svg/loader.svg';
import EmptyRequest from '../../../assets/images/svg/norequest.svg';
import Loading from '../../../components/UI/Spinner/Loading';
import PermissionsGate from '../../../HOC/PermissionGate';
import { useGetPendingRequestsQuery } from '../../../redux/services/supplyChainApi';
import { SCOPES } from '../../../utils/permission';
import { transformedDateFull } from '../../../utils/utils';

const SentRequests = () => {
    const { data, isLoading } = useGetPendingRequestsQuery();

    return (
        <div className="w-full" data-testid="sent-request-cont">
            <div className="grid justify-center " data-testid="empty-message">
                {isLoading ? (
                    <Loading />
                ) : data.data?.length === 0 ? (
                    <div>
                        <div className="grid justify-center mt-4">
                            <img src={EmptyRequest} alt="empty" />
                        </div>
                        <div className="grid justify-center mt-4">
                            <p className="text-[28px]">No pending request</p>
                        </div>
                        <div style={{ width: '458px' }} className="grid justify-center mt-4 text-center">
                            <p>
                                You have no pending request, if you have not sent out a request yet, visit the feed or search to find a vendor to make
                                a request
                            </p>
                        </div>
                        <div data-testid="feed-button">
                            <PermissionsGate scopes={[SCOPES.can_view_all_feed, SCOPES.can_view_connected_vendors_feed]}>
                                <Link to="/feed">
                                    <div className="grid justify-center mt-4 cursor-pointer">
                                        <div style={{ backgroundColor: '#5E5BA7', borderRadius: '12px' }} className="px-[50px] py-[16px]">
                                            <p className="text-white">Visit Feed</p>
                                        </div>
                                    </div>
                                </Link>
                            </PermissionsGate>
                        </div>
                    </div>
                ) : (
                    data.data?.map((item: any, i: number) => (
                        <div className="flex gap-8 pb-2 items-center w-full mt-4 justify-between " data-testid="sent-requests" key={i}>
                            <div className="flex gap-2 items-center w-[10rem] ">
                                <div className="flex gap-2 items-center rounded-full min-w-[3.2rem]">
                                    <img
                                        src={item.requested_company.logo ? item.requested_company.logo : NotFound}
                                        alt="avatar"
                                        className="h-[3.2rem] w-[3.2rem] object-contain"
                                    />
                                </div>
                                <div className="max-w-[6.8rem] flex flex-col">
                                    <p className="font-bold w-full break-words">{item.requested_company.name}</p>
                                    <p>{item.requested_company.industry_type}</p>
                                </div>
                            </div>
                            <div className="flex gap-8">
                                <div>
                                    <p className="text-[#525171]">request sent </p>
                                    <p className="text-[#525171]">on-{transformedDateFull(item.created_at)}</p>
                                </div>
                            </div>
                            <div className="grid content-center">
                                <p className="font-bold text-[#D29C17] flex">
                                    <img src={LoaderIcon} alt="loader" className="mr-2" /> Pending
                                </p>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default SentRequests;
