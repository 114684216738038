import { useState } from 'react';

import Loading from '../../components/UI/Spinner/Loading';
import { intitialSubscriptionPlanData } from '../../redux/features/dtos';
import { useGetCurrentSubscriptionsQuery, useGetSubscriptionHistoryQuery } from '../../redux/services';
import { transformedDate, transformedDateWithTime } from '../../utils/utils';
import BillingHistory from './BillingHistory';
import EmptyBilling from './SettingsPopup/EmptyBilling';
import SubscribeToPlan from './SubscribeToPlan';

const SubscriptionHistory = () => {
    const [activeTab, setActiveTab] = useState<'history' | 'subscribe'>('history');
    const { data: currentPlan = intitialSubscriptionPlanData, isLoading: isloading } = useGetCurrentSubscriptionsQuery();
    const { data = [], isLoading } = useGetSubscriptionHistoryQuery();
    const [isSubscribe, setIsSubscribe] = useState(false);
    const toggleTab = (tabName: 'history' | 'subscribe') => {
        setActiveTab(tabName);
    };
    if (isloading || isLoading)
        return (
            <div className="min-h-[450px] flex justify-center flex-col">
                <Loading />
            </div>
        );

    return (
        <div className="h-full mr-2 md:mr-8">
            <p className="text-jumbleng-primary">Manage your subscription to get access to your account</p>
            {activeTab === 'history' ? (
                <div className="bg-white mt-[1.5rem] p-4 md:p-16">
                    {currentPlan.plan_type && currentPlan.status === 'active' ? (
                        <div className="flex gap-2 items-start">
                            <div className="rounded-full mt-2  text-white w-8 h-8 bg-jumbleng-primary hidden md:flex items-center justify-center">
                                <i className="fa-solid fa-layer-group "></i>
                            </div>
                            <div className=" mb-[1.35rem] w-full">
                                <div className="flex gap-2 items-center justify-center md:justify-start">
                                    <div className="rounded-full md:hidden text-white w-8 h-8 bg-jumbleng-primary flex items-center justify-center">
                                        <i className="fa-solid fa-layer-group "></i>
                                    </div>
                                    <h3 className=" font-semibold text-[1rem] text-jumbleng-gray-main">{currentPlan?.plan.name} Plan</h3>
                                    <p className="text-[#084F21] bg-[#DEEAE2] py-2 px-4 rounded-full">{currentPlan.status ? 'Active' : 'Inactive'}</p>
                                </div>
                                <div className="flex flex-col md:flex-row  text-jumbleng-gray justify-between items-start">
                                    <div className="flex flex-col w-full items-center md:items-start ">
                                        <div className="w-full flex md:flex-row flex-col mt-4  justify-center md:justify-between space-y-4 md:space-y-0 items-center md:items-start">
                                            <p className="text-center">
                                                {' '}
                                                You are currently on the monthly basic plan which expires on {transformedDate(currentPlan.end_time)}.
                                            </p>

                                            <div
                                                onClick={() => toggleTab('subscribe')}
                                                className="md:flex rounded-xl cursor-pointer  items-center justify-center bg-jumbleng-primary  px-[1.18rem] py-[0.7rem] w-[10rem]"
                                            >
                                                <div className="font-normal text-[1rem] text-white ">
                                                    <i className="fa fa-angles-up mr-2"></i>Upgrade Plan
                                                </div>
                                            </div>
                                        </div>
                                        <div className="md:max-w-[25rem]">
                                            <div className="grid grid-cols-1 md:grid-cols-2 mt-3 justify-items-center md:justify-items-start ">
                                                <div className="col-span-1 font-semibold">Last payment date:</div>
                                                <div className="col-span-1">{transformedDateWithTime(currentPlan.start_time)}</div>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-2 mt-3 justify-items-center md:justify-items-start">
                                                <div className="col-span-1 font-semibold">Next payment date:</div>
                                                <div className="col-span-1">{transformedDateWithTime(currentPlan.end_time)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full flex md:flex-row flex-col mt-4  justify-center md:justify-between space-y-4 md:space-y-0 items-center md:items-start">
                            <p className="text-center text-red-600"> You are currently not on any plan.</p>

                            <div
                                onClick={() => {
                                    toggleTab('subscribe');
                                    setIsSubscribe(true);
                                }}
                                className="md:flex rounded-xl cursor-pointer  items-center justify-center bg-jumbleng-primary  px-[1.18rem] py-[0.7rem] w-[12rem]"
                            >
                                <div className="font-normal text-[1rem] text-white ">Click here to Subscribe</div>
                            </div>
                        </div>
                    )}
                    <div className=" mb-[2.35rem] w-full mt-8">
                        <div className="flex justify-between">
                            <p className="font-semibold text-[1rem] text-jumbleng-gray-main">Billing History</p>
                            <div className="flex gap-2 items-center  text-jumbleng-primary cursor-pointer">
                                <i className="fa-solid fa-download "></i>
                                <p>Export date</p>
                            </div>
                        </div>
                        <div className="md:mt-5 w-full ">
                            <div
                                className={`grid grid-cols-1 md:grid-cols-6 pt-4  px-2 bg-white justify-items-center md:justify-items-start font-bold`}
                            >
                                <div className="jumbleng-gray-main text-base">Date</div>
                                <div className="jumbleng-gray-main text-base md:col-span-3">Details</div>
                                <div className="jumbleng-gray-main text-base ">Amount</div>
                                <div className="jumbleng-gray-main text-base ">Status</div>
                            </div>
                            {data?.length === 0 ? <EmptyBilling /> : <BillingHistory data={data} />}
                        </div>
                    </div>
                </div>
            ) : (
                <SubscribeToPlan toggleTab={toggleTab} currentPlan={currentPlan} isSubscribe={isSubscribe} />
            )}
        </div>
    );
};

export default SubscriptionHistory;
