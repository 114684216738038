import { BaseQueryFn, createApi, FetchArgs } from '@reduxjs/toolkit/query/react';

import { IinitialCustomerStateInterface, IinitialGroupStateInterface } from '../features/interfaces';
import { baseQueryWithReauth, createRequestWithParams, CustomError } from './shared';

const createRequest = (url: any) => ({ url });
export const groupApi = createApi({
    reducerPath: 'groupApi',
    baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, any>>,
    tagTypes: ['Groups', 'members'],
    endpoints: (builder) => ({
        createGroup: builder.mutation({
            query: (data) => {
                return {
                    url: `vendors/groups`,
                    method: 'post',
                    body: data,
                };
            },
            invalidatesTags: ['Groups'],
        }),
        addCompanyToGroup: builder.mutation({
            query: ({ id, ...rest }) => {
                return {
                    url: `vendors/groups/${id}/members`,
                    method: 'post',
                    body: rest,
                };
            },
            invalidatesTags: ['members'],
        }),
        getGroups: builder.query<IinitialGroupStateInterface, { page: number; limit: number; search_query: string }>({
            query: ({ page, limit, search_query }) => createRequestWithParams(`vendors/groups`, { page, limit, search_query }),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'Groups' as const, id })), 'Groups'] : ['Groups'],
            // transformResponse: (results: { data: any }) => results,
        }),
        getCustomers: builder.query<IinitialCustomerStateInterface, { page: number; limit: number; search_query: string }>({
            query: ({ page, limit, search_query }) => createRequestWithParams(`vendors/customers`, { page, limit, search_query }),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'Groups' as const, id })), 'Groups'] : ['Groups'],
            // transformResponse: (results: { data: any }) => results,
        }),

        getCompanyByGroup: builder.query<any, void>({
            query: () => createRequest(`vendors/groups/companies`),
        }),

        getAGroup: builder.query<any, string>({
            query: (id) => createRequest(`vendors/groups/${id}`),
            providesTags: (_result, _error, id) => [{ type: 'Groups', id }],
        }),

        suspendGroup: builder.mutation({
            query: (id) => {
                return {
                    url: `vendors/groups/${id}/suspend`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'Groups', id }],
        }),

        reactivateGroup: builder.mutation({
            query: (id) => {
                return {
                    url: `vendors/groups/${id}/reactivate`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'Groups', id }],
        }),

        getGroupMembers: builder.query<any, { id: string; page: number; limit: number; search_query: string; filter: string }>({
            query: ({ id, page, limit, search_query, filter }) =>
                createRequestWithParams(`vendors/groups/${id}/members`, { page, limit, search_query, filter }),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ member_id }: any) => ({ type: 'members' as const, member_id })), 'members'] : ['members'],
            // transformResponse: (results: { data: any }) => results,
        }),
        suspendGroupMembers: builder.mutation({
            query: ({ id, member_id }) => {
                return {
                    url: `vendors/groups/${id}/members/${member_id}/suspend`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { member_id }) => [{ type: 'members', member_id }],
        }),
        reactivateGroupMembers: builder.mutation({
            query: ({ id, member_id }) => {
                return {
                    url: `vendors/groups/${id}/members/${member_id}/reactivate`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { member_id }) => [{ type: 'members', member_id }],
        }),
        deleteGroupMember: builder.mutation({
            query: ({ id, member_id }) => {
                return {
                    url: `vendors/groups/${id}/members/${member_id}`,
                    method: 'delete',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { member_id }) => [{ type: 'members', member_id }],
        }),
        deleteGroup: builder.mutation({
            query: (id) => {
                return {
                    url: `vendors/groups/${id}`,
                    method: 'delete',
                };
            },
            invalidatesTags: (_result, _error, id) => [{ type: 'Groups', id }],
        }),
    }),
});

export const {
    useGetGroupMembersQuery,
    useGetCustomersQuery,
    useCreateGroupMutation,
    useGetAGroupQuery,
    useAddCompanyToGroupMutation,
    useGetCompanyByGroupQuery,
    useGetGroupsQuery,
    useSuspendGroupMutation,
    useSuspendGroupMembersMutation,
    useReactivateGroupMutation,
    useReactivateGroupMembersMutation,
    useDeleteGroupMutation,
    useDeleteGroupMemberMutation,
} = groupApi;
