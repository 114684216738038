import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import Spinner from '../../../components/UI/Spinner/Spinner';
import { paymentApi } from '../../../redux/services';
import { useCancelOrderMutation } from '../../../redux/services/orderApi';

interface CProps {
    id: string;
    order_no: string;
    close: () => void;
}

const CancelContent: FC<CProps> = ({ id, order_no, close }) => {
    const [nextModal, setNextModal] = useState<boolean>(true);
    const [openTextInput, setOpenTextInput] = useState<boolean>(false);
    const textInput = useRef<HTMLInputElement>(null);
    const selectInput = useRef<HTMLSelectElement>(null);
    const [cancelOrder, { data, isLoading, isSuccess, isError, error }] = useCancelOrderMutation();
    const dispatch = useDispatch();

    const goBack = (close: () => void) => {
        setNextModal(true);
        setOpenTextInput(false);
        close();
    };

    const handleSelectChange = () => {
        const message = selectInput.current?.value;
        if (message === 'other') {
            setOpenTextInput(true);
        }
    };

    const handleCancelOrder = async (id: string) => {
        let message = selectInput.current?.value;
        if (message === 'other') {
            setOpenTextInput(true);
            message = textInput.current?.value;
        }
        setNextModal(false);
        if (!nextModal) {
            cancelOrder({ id: id, message: message });
        }
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success(data?.message);
            dispatch(paymentApi.util.invalidateTags(['payment']));
            close();
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
            close();
        }
    }, [close, data?.message, dispatch, error, isError, isLoading, isSuccess]);

    return (
        <>
            {nextModal ? (
                <div>
                    <button className="close" onClick={close}>
                        &times;
                    </button>
                    <div className="flex items-center justify-center flex-col mt-8 mx-4">
                        <p className="border rounded-[50%] bg-[#F5E7E7] w-[40px] h-[40px] p-1 flex items-center justify-center">
                            <i className="fa fa-triangle-exclamation text-[#9F0C0D] "></i>
                        </p>
                        <p className="text-[#5E5BA7] mt-8 text-center">Are you sure you want to cancel ORD-{order_no}</p>
                    </div>
                </div>
            ) : (
                <div>
                    {' '}
                    <button className="close" onClick={close}>
                        &times;
                    </button>
                    <div className="flex items-center justify-center flex-col mt-8 mx-4">
                        <p className="text-[#5E5BA7] mt-8 text-center font-bold mb-2">Please give a reason you want to cancel</p>
                        <select
                            ref={selectInput}
                            name="input2"
                            defaultValue={'Select reason'}
                            onChange={handleSelectChange}
                            className="w-full text-input placeholder:text-[0.78125rem] text-base focus:outline-none py-1 pr-4 pl-2 border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1]"
                        >
                            <option value="I want to review my order">I want to review my order</option>
                            <option value="Wrong Products Ordered">Wrong Products Ordered</option>
                            <option value="other">Other</option>
                        </select>
                        {openTextInput && <input className="w-full text-input pl-2 mt-8" ref={textInput} name="input1" />}
                    </div>
                </div>
            )}

            <div className="grid grid-cols-2 my-8 gap-4 mx-4">
                <div className="grid justify-center py-4 border-2 border-[#5E5BA7] rounded-[12px]">
                    <p className="text-jumbleng-primary cursor-default" onClick={() => goBack(close)}>
                        No, go back
                    </p>
                </div>
                <div className="grid justify-center py-4 bg-[#9F0C0D] border rounded-[12px]" onClick={() => handleCancelOrder(id)}>
                    {isLoading ? (
                        <div className="flex items-center justify-center w-full">
                            <Spinner />
                        </div>
                    ) : (
                        <p className="text-white cursor-default">Yes, cancel order</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default CancelContent;
