import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import PlusIcon from '../../assets/images/svg/plus.svg';
import PermissionsGate, { managePermissions } from '../../HOC/PermissionGate';
import Wrapper from '../../HOC/Wrapper';
import useGetRoles from '../../hooks/useGetRoles';
import { types } from '../../redux/features/order/OrderActionTypes';
import { RootState } from '../../redux/store';
import { SCOPES } from '../../utils/permission';
import CustomerOrder from './CustomerOrder';
import MyOrder from './MyOrder';

const Order = () => {
    const { activeTab } = useSelector((state: RootState) => state.listCustomerOrdersReducer);
    const dispatch = useDispatch();
    const { user_role, company_type } = useGetRoles();
    const toggleTab = (tabName: 'customerOrder' | 'myOrder') => {
        dispatch({ type: types.setActiveTab, payload: tabName });
    };

    useEffect(() => {
        if (managePermissions(user_role, company_type, SCOPES.can_manage_company_orders, SCOPES.can_manage_customer_orders)) {
            dispatch({ type: types.setIsCustomer, payload: false });
            dispatch({ type: types.setActiveTab, payload: 'myOrder' });
            return;
        }
    }, [user_role, company_type, dispatch]);

    return (
        <Wrapper>
            <div className="flex items-center justify-between my-6 mb-[0.7rem]">
                <h3 className="font-semibold text-[1.25rem] text-jumbleng-gray">Orders</h3>
                <PermissionsGate scopes={[SCOPES.can_create_orders, SCOPES.can_view_all_feed, SCOPES.can_view_connected_vendors_feed]}>
                    <Link
                        to="/feed"
                        className="md:hidden flex mr-2 h-full rounded-md ml-4 items-center bg-jumbleng-primary px-[1.18rem] py-[0.42125rem]"
                    >
                        <img src={PlusIcon} className="mr-1" alt="plus" />{' '}
                        <span className="font-semibold text-[0.78125rem] text-white">Create Order</span>
                    </Link>
                </PermissionsGate>
            </div>

            <div className="flex justify-between border-b py-1 mb-[1.5rem] overflow-x-auto">
                <ul className="flex">
                    <PermissionsGate scopes={[SCOPES.can_manage_customer_orders]}>
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => (dispatch({ type: types.setIsCustomer, payload: true }), toggleTab('customerOrder'))}
                                className={`${
                                    activeTab === 'customerOrder' && 'font-bold border-b border-b-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                            >
                                Customer Order
                            </a>
                        </li>
                    </PermissionsGate>
                    <PermissionsGate scopes={[SCOPES.can_manage_company_orders]}>
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => (dispatch({ type: types.setIsCustomer, payload: false }), toggleTab('myOrder'))}
                                className={`${
                                    activeTab === 'myOrder' && 'font-bold border-b border-b-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                            >
                                My Order
                            </a>
                        </li>
                    </PermissionsGate>
                </ul>
                <PermissionsGate scopes={[SCOPES.can_create_orders, SCOPES.can_view_all_feed, SCOPES.can_view_connected_vendors_feed]}>
                    <Link
                        to="/feed"
                        className=" hidden md:flex mr-2 h-full rounded-md ml-4 items-center bg-jumbleng-primary px-[1.18rem] py-[0.42125rem]"
                    >
                        <img src={PlusIcon} className="mr-1" alt="plus" />{' '}
                        <span className="font-semibold text-[0.78125rem] text-white">Create Orders</span>
                    </Link>
                </PermissionsGate>
            </div>

            {activeTab === 'myOrder' ? <MyOrder /> : <CustomerOrder />}
        </Wrapper>
    );
};

export default Order;
