import React from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useDeleteMemberMutation } from '../../../../redux/services/teamsApi';
import { Modaled } from './styles';

const DeleteModal = ({ setDeleteOpen, selectedUser, refetch }: { setDeleteOpen: any; selectedUser?: any; refetch?: any }) => {
    const [deleteMember, { isLoading, isSuccess, isError, error }] = useDeleteMemberMutation();

    const userId = selectedUser.id;

    const handleDelete = async (userId: any) => {
        await deleteMember(userId);
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Member deleted successfully');
            refetch();
            setDeleteOpen(false);
        }
        if (isError && error && 'data' in error) {
            toast.error(error?.data?.message);
        }
    }, [isSuccess, isError, error, setDeleteOpen, refetch]);

    return (
        <Modaled>
            <div className="darkBG" onClick={() => setDeleteOpen(false)} />
            <div className="centered">
                <div className="modal">
                    <div className="closeBtn" onClick={() => setDeleteOpen(false)}>
                        <img src="https://www.svgrepo.com/show/66823/close.svg" alt="" />
                    </div>
                    <div className="modalContent">
                        <div className="w-[40px] mx-auto mb-8 h-[40px] rounded-full bg-[#fff3f3] grid place-content-center">
                            <i className="fas fa-exclamation-triangle text-jumbleng-red"></i>
                        </div>
                        <p>Are you sure you want to delete {selectedUser?.email}?</p>
                    </div>

                    <div className="actionsContainer">
                        <button className="cancelBtn" onClick={() => setDeleteOpen(false)}>
                            No, go back
                        </button>
                        {isLoading ? (
                            <div className="loader__container">
                                <div className="loader" />
                            </div>
                        ) : (
                            <button className="sendBtn" onClick={() => handleDelete(userId)}>
                                Yes, delete member
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Modaled>
    );
};

export default DeleteModal;
