import styled from 'styled-components';

const Container = styled.div`
    display: flex;

    .preview__left {
        flex: 0.8;

        .page__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 50px;

            .page__title {
                color: #525171;
                font-size: 18px;
                font-weight: 600;
                display: flex;
                gap: 10px;
                font-size: 14px;
            }
            .loader__container {
                background-color: #131221;
                height: 44px;
                width: 100px;
                border-radius: 12px;
                display: flex;
                justify-content: center;
                margin-top: 10px;
                cursor: not-allowed;

                .loader {
                    width: 4px;
                    aspect-ratio: 1;
                    border-radius: 50%;
                    box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
                    transform: translateX(-38px);
                    animation: d1 0.5s infinite alternate linear;
                    margin: auto;
                    color: #fff;
                    background-color: #fff;
                }

                @keyframes d1 {
                    50% {
                        box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;
                    }
                    100% {
                        box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;
                    }
                }
            }
            .page__cta {
                background-color: #131221;
                border-radius: 6px;
                padding: 10px 35px;
                color: #fff;
                cursor: pointer;

                p {
                    display: flex;
                    align-items: center;
                    gap: 13px;
                    span {
                        font-size: 24px;
                    }
                }
            }
        }
        .page__preview {
            margin-top: 10px;

            .preview__header {
                padding: 0 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top: 3px solid #f5f5f5;

                &__left {
                    display: flex;
                    gap: 68px;

                    .store__name {
                        display: flex;
                        align-items: center;
                        gap: 15px;

                        .logo {
                            width: 20px;
                            height: 20px;
                            background: black;
                        }
                    }

                    .store__tabs {
                        display: flex;

                        .active {
                            padding: 12px 30px;
                            text-transform: capitalize;
                            border-bottom: 3px solid #000000;
                            cursor: pointer;
                        }
                        .disabled {
                            padding: 12px 30px;
                            cursor: pointer;
                            text-transform: capitalize;
                        }
                    }
                }
            }

            .preview__body {
                background-color: #f5f5f5;
                height: 100vh;
                position: relative;
                overflow: scroll;
                padding: 50px 0;

                .search {
                    width: 600px;
                    max-width: calc(100% - 20px);
                    display: flex;
                    gap: 15px;
                    background-color: #fff;
                    padding: 13px 10px;
                    margin: 0 auto;
                    margin-bottom: 26px;

                    input {
                        border: none;
                        outline: none;
                        font-size: 14px;
                    }
                }

                .list__item {
                    display: flex;
                    /* flex-direction: column; */
                    justify-content: center;
                    align-items: center;

                    .item__grid {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 16px;
                        align-items: center;
                        width: 90%;
                        /* justify-content: center; */

                        .item__container {
                            background-color: #fff;
                            width: 236px;
                            max-width: calc(100% - 20px);

                            .item__img {
                                width: 100%;
                                height: 237px;
                                background-color: #00000050;
                            }
                            h3 {
                                padding: 5px 0 10px 0;
                                color: #840b0b;
                                font-weight: 600;
                                font-size: 14px;
                            }
                        }
                    }
                }

                .contact__header {
                    text-align: center;
                    color: #131221;
                    font-weight: 600;
                    font-size: 25px;
                }

                .form__section {
                    width: 70%;
                    margin: 60px auto;

                    .form__container__grid {
                        color: #131221;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .form__input {
                            display: flex;
                            flex-direction: column;
                            margin: 0 auto;

                            label {
                                font-weight: 600;
                                font-size: 18px;
                            }

                            input {
                                color: #131221;
                                border: none;
                                outline: none;
                                padding: 15px;
                                width: 326px;
                            }
                        }
                    }

                    .form__container {
                        color: #131221;
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 30px;
                        width: 80%;

                        .form__input {
                            display: flex;
                            flex-direction: column;
                            margin: 0 auto;
                            width: 100%;

                            label {
                                font-weight: 600;
                                font-size: 18px;
                                margin-bottom: 10px;
                            }

                            input {
                                color: #131221;
                                border: none;
                                outline: none;
                                padding: 15px;
                                width: 326px;
                                height: 160px;
                            }

                            &__bottom {
                                display: flex;
                                flex-direction: column;
                                margin: 0 auto;
                                width: 100%;

                                label {
                                    font-weight: 600;
                                    font-size: 18px;
                                    text-align: left;
                                }

                                input {
                                    color: #131221;
                                    border: none;
                                    outline: none;
                                    padding: 15px;
                                    width: 100%;
                                    height: 160px;
                                }

                                .form__cta {
                                    background-color: white;
                                    border-top: 1px solid #00000010;
                                    padding: 10px;
                                    display: flex;
                                    justify-content: flex-end;

                                    button {
                                        background-color: #2f2e54;
                                        color: #fff;
                                        padding: 10px 20px;
                                        font-size: 14px;
                                        display: flex;
                                        align-items: center;
                                        gap: 10px;
                                    }
                                }
                            }
                        }
                    }
                }

                .socials__section {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 16px;

                    .links {
                        gap: 30px;
                        display: flex;

                        img {
                            width: 32px;
                            height: 32px;
                        }
                    }
                }
            }
        }
    }

    .preview__right {
        flex: 0.2;

        .btnHidden {
            display: none;
            background-color: red;
        }

        .right__header {
            border-bottom: 2px solid #000000;
            padding: 11px;
            font-size: 16px;
            margin-bottom: 20px;
        }

        .option__container {
            margin-bottom: 16px;

            .option__trigger {
                display: flex;
                justify-content: space-between;
                text-transform: capitalize;
                font-size: 18px;
                font-weight: 600;
                background-color: #000;
                padding: 14px 12px;
                color: #fff;
            }

            .option__content {
                background-color: #fff;
                padding-top: 16px;
                padding-left: 12px;
                padding-right: 12px;

                .panel__header {
                    width: 100%;
                    text-align: left;
                    color: #000;
                    padding: 20px;
                    border: 1px solid #000;
                }

                .panel__selection {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;
                    align-items: center;

                    .selected {
                        padding: 20px;
                        border: 1px solid #000;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 40%;

                        h3 {
                            color: #000;
                            font-weight: 600;
                            font-size: 18px;
                        }

                        p {
                            color: #00000060;
                            font-size: 12px;
                        }
                    }
                    .unselected {
                        align-items: center;
                        padding: 20px;
                        border: 1px solid #00000020;
                        display: flex;
                        justify-content: space-between;
                        width: 40%;
                        cursor: pointer;

                        h3 {
                            color: #00000050;
                            font-weight: 600;
                            font-size: 18px;
                        }

                        p {
                            color: #00000030;
                            font-size: 12px;
                        }
                    }
                }
            }

            .products__indicator {
                display: flex;
                justify-content: space-between;
                align-items: center;

                p {
                    color: #191919;
                }

                input {
                    width: 250px;
                    padding: 12px;
                    border: 1px solid #00000030;
                    border-radius: 6px;
                    color: #000000;
                    outline: none;
                    font-size: 13px;
                }
            }

            .produce__list {
                .list__header {
                    color: #525171;
                    display: flex;
                    font-size: 18px;
                    font-weight: 600;
                    width: 100%;
                    padding: 24px 0;
                    border-bottom: 1px solid #00000010;

                    .name__cta {
                        flex: 0.6;
                    }
                }

                .list__item {
                    color: #525171;
                    display: flex;
                    padding: 18px 0;
                    border-bottom: 1px solid #00000010;
                    font-size: 13px;
                    font-weight: 600;
                    text-align: left;
                    align-items: center;
                    cursor: pointer;

                    .name__cta {
                        flex: 0.6;
                        display: flex;
                        align-items: center;
                        gap: 25px;

                        .product__img {
                            height: 48.75px;
                            width: 48.75px;
                            border-radius: 12px;
                            background-color: #d9d9d9;
                        }
                    }
                }
            }
        }

        .form__container {
            width: 100%;
            margin-top: 16px;

            .form__input {
                margin-bottom: 20px;

                .field__label {
                    color: #000000;
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    text-transform: capitalize;

                    sup {
                        color: red;
                        font-size: 14px;
                        margin-bottom: auto;
                    }
                }

                .field__exp {
                    color: #00000040;
                    font-size: 9px;
                    text-align: left;
                }

                input,
                textarea {
                    border: 1px solid #00000050;
                    width: 100%;
                    margin: 8px 0;
                    padding: 15px;
                    font-size: 12px;
                    color: #000;
                    border-radius: 6px;

                    &[type='file'] {
                        display: none;
                    }
                }

                .upload__container {
                    border: 1px dashed #00000050;
                    width: 100%;
                    margin: 8px 0;
                    border-radius: 6px;
                    padding: 15px;
                    font-size: 12px;
                    color: #000;
                }

                .link__container {
                    display: flex;
                    align-items: center;
                    border: 1px solid #00000050;
                    width: 100%;
                    margin: 8px 0;
                    padding: 15px;

                    p {
                        font-size: 12px;
                        color: #00000050;
                    }

                    input {
                        border: none;
                        outline: none;
                        width: 100%;
                        margin: 0;
                        padding: 0px;
                    }
                }

                label {
                    cursor: pointer;
                    width: 100%;
                    color: #525171;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 13px;
                    padding: 20px 0;

                    img {
                        width: 24px;
                    }
                }

                .sub__label {
                    color: #00000050;
                    font-size: 12px;
                    text-align: left;
                }
            }

            .form__input2 {
                display: flex;
                align-items: center;
                gap: 10px;
                border: 1px solid #00000050;
                width: 100%;
                margin: 8px 0;
                padding: 15px;
                font-size: 12px;
                color: #000;
                border-radius: 6px;

                img {
                    width: 24px;
                }
            }
        }
    }
`;
export default Container;
