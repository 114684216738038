import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../components/UI/Spinner/Loading';
import {
    useGetApprovedRequestsQuery,
    useGetPendingRequestsQuery,
    useGetRejectedRequestsQuery,
    useGetSentRequestsQuery,
} from '../../redux/services/supplyChainApi';
import { toggleRequestStatusTab } from '../../redux/slices/tabs';
import { RootState } from '../../redux/store';
import AcceptedRequest from './AcceptedRequest';

export type queryType = { status: string; page: number; limit: number };
const initialRequestQuery = {
    status: 'accepted',
    limit: 10,
    page: 1,
};
const SentRequests = () => {
    const { requestStatusTab: activeTab } = useSelector((state: RootState) => state.tabsReducer);
    const dispatch = useDispatch();

    const toggleTab = (tabName: 'accepted' | 'pending' | 'declined') => {
        dispatch(toggleRequestStatusTab(tabName));
    };

    const { data, isFetching } = useGetPendingRequestsQuery();
    const { data: fetchedData, isFetching: loading } = useGetApprovedRequestsQuery();
    const { data: declinedRequests, isFetching: requestloading } = useGetRejectedRequestsQuery();
    const [query, setQuery] = useState<queryType>({ ...initialRequestQuery, status: activeTab });
    const { isFetching: loader } = useGetSentRequestsQuery(query);

    useEffect(() => {
        if (activeTab === 'pending' && data?.data) {
            setQuery({ ...query, page: 1, status: 'pending' });
        } else if (activeTab === 'accepted' && fetchedData?.data) {
            setQuery({ ...query, page: 1, status: 'accepted' });
        } else {
            setQuery({ ...query, page: 1, status: 'declined' });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, setQuery, loader, data?.data, fetchedData?.data]);

    if (isFetching || loading || requestloading || loader) {
        return (
            <div className="min-h-[calc(100vh-20rem)] flex justify-center flex-col">
                <Loading />
            </div>
        );
    }
    return (
        <div className="w-[600px]">
            <div className="flex text-[0.78125rem] text-jumbleng-gray gap-4 mb-8">
                <div
                    className={`${
                        activeTab === 'accepted' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                    }  flex gap-2 cursor-pointer  border  rounded-2xl p-1.5`}
                    onClick={() => toggleTab('accepted')}
                >
                    Accepted{' '}
                    <p className={`px-2 ${activeTab === 'accepted' ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'} border rounded-[33px]`}>
                        {fetchedData?.count ? fetchedData?.count : 0}
                    </p>
                </div>
                <div
                    className={`${
                        activeTab === 'pending' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                    }  flex gap-2 cursor-pointer  border  rounded-2xl p-1.5`}
                    onClick={() => toggleTab('pending')}
                >
                    Pending{' '}
                    <p className={`px-2 ${activeTab === 'pending' ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'} border rounded-[33px]`}>
                        {data?.count ? data?.count : 0}
                    </p>
                </div>
                <div
                    className={`${
                        activeTab === 'declined' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                    }  flex gap-2 cursor-pointer  border  rounded-2xl p-1.5`}
                    onClick={() => toggleTab('declined')}
                >
                    Declined{' '}
                    <p className={`px-2 ${activeTab === 'declined' ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'} border rounded-[33px]`}>
                        {declinedRequests?.count ? declinedRequests?.count : 0}
                    </p>
                </div>
            </div>
            <AcceptedRequest activeTab={activeTab} query={query} />
        </div>
    );
};

export default SentRequests;
