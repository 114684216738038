import axios from 'axios';

const { REACT_APP_BACKEND_API } = process.env;

const updateCompanyProfile = (formData: any): Promise<any> => {
    return axios.put(`${REACT_APP_BACKEND_API}/v1/company/profile`, formData);
};
const deleteLogo = (): Promise<any> => {
    return axios.delete(`${REACT_APP_BACKEND_API}/v1/company/profile/logo`);
};

const updateCatalogue = async (values: any): Promise<any> => {
    const instance = axios.create();
    delete instance.defaults.headers.common.authorization;
    const formData = new FormData();
    formData.append('file', values.banner);
    formData.append('upload_preset', 'jumbleng-test');
    let imageUrl: '';
    if (values.banner) {
        return await instance
            .post('https://api.cloudinary.com/v1_1/dduma7jcf/image/upload', formData)
            .then(({ data }) => {
                imageUrl = data.secure_url;
            })
            .then(async () => {
                return await axios.put(`${REACT_APP_BACKEND_API}/v1/company/catalogue/`, {
                    ...values,
                    banner: imageUrl,
                });
            });
    } else {
        return await axios.put(`${REACT_APP_BACKEND_API}/v1/company/catalogue/`, values);
    }
};

const ProfileService = {
    updateCompanyProfile,
    deleteLogo,
    updateCatalogue,
};

export default ProfileService;
