import './SignupSteps.css';

import Logo from '../../assets/images/svg/jumbleng-logo.svg';

const SignupSteps = (step: any) => {
    return (
        <>
            <div className="w-[38%] min-h-screen bg-jumbleng-primary px-[60px]">
                <div className="mb-[78px] -ml-6">
                    <img src={Logo} alt="_" />
                </div>

                <h2 className="font-semibold text-[1.75rem] text-white mb-[3.125rem]">Welcome to Jumble, your B2B market place</h2>

                <h3 className="font-semibold text-[1.025rem] text-white mb-[1.953rem]">Steps to sign up</h3>
                <div className="steps__container">
                    <p className={step.step === 1 || 2 ? 'steps__activeItem' : 'steps__item'}>
                        <img
                            src={
                                step.step === 1 || 2
                                    ? 'https://res.cloudinary.com/odd-developer/image/upload/v1655383983/present_sbzkht.svg'
                                    : 'https://res.cloudinary.com/odd-developer/image/upload/v1655383982/absent_zpyeya.svg'
                            }
                            alt=""
                        />
                        Create account
                    </p>
                    <p className={step.step === 2 || 3 ? 'steps__activeItem' : 'steps__item'}>
                        <img
                            src={
                                step.step === 2 || 3
                                    ? 'https://res.cloudinary.com/odd-developer/image/upload/v1655383983/present_sbzkht.svg'
                                    : 'https://res.cloudinary.com/odd-developer/image/upload/v1655383982/absent_zpyeya.svg'
                            }
                            alt=""
                        />
                        Company details
                    </p>
                    <p className={step.step === 3 ? 'steps__activeItem' : 'steps__item'}>
                        <img
                            src={
                                step.step === 3
                                    ? 'https://res.cloudinary.com/odd-developer/image/upload/v1655383983/present_sbzkht.svg'
                                    : 'https://res.cloudinary.com/odd-developer/image/upload/v1655383982/absent_zpyeya.svg'
                            }
                            alt=""
                        />
                        Verify email
                    </p>
                </div>
            </div>
        </>
    );
};

export default SignupSteps;
