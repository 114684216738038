import { Dispatch, FC, SetStateAction } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { fetchAccounts } from '../../redux/features/bank-payment/bankRedux';
import { removeAccount } from '../../redux/features/bank-payment/bankService';
import Spinner from '../UI/Spinner/Spinner';

interface CProps {
    id: string;
    account_number: string;
    close: () => void;
    setContent: Dispatch<SetStateAction<'Empty' | 'Filled' | 'Form'>>;
}

const RemoveAccountModal: FC<CProps> = ({ id, close, account_number, setContent }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const removeaccount = async (id: string) => {
        try {
            setLoading(true);
            await removeAccount(id);
            toast.success('Account removed successfully');
            dispatch(fetchAccounts());
            setContent('Filled');
            setLoading(false);
            close();
        } catch (error) {
            toast.error(error.response.data?.message);
            setLoading(false);
            close();
        }
        return true;
    };

    return (
        <>
            <div>
                <button className="close" onClick={close}>
                    &times;
                </button>
                <div className="flex items-center justify-center flex-col mt-8 mx-4">
                    <p className="border rounded-[50%] bg-[#F5E7E7] w-[40px] h-[40px] p-1 flex items-center justify-center">
                        <i className="fa fa-triangle-exclamation text-[#9F0C0D] "></i>
                    </p>
                    <p className="text-[#5E5BA7] mt-8 text-center">Are you sure you want to remove your account-{account_number}</p>
                </div>
            </div>
            <div className="grid grid-cols-2 my-8 gap-4 mx-4">
                <div className="grid justify-center py-4 border-2 border-[#5E5BA7] rounded-[12px]">
                    <p className="text-jumbleng-primary cursor-default" onClick={close}>
                        No, go back
                    </p>
                </div>
                <div className="grid justify-center py-4 bg-[#9F0C0D] border rounded-[12px]" onClick={() => removeaccount(id)}>
                    {loading ? (
                        <div className="flex items-center justify-center w-full">
                            <Spinner />
                        </div>
                    ) : (
                        <p className="text-white cursor-default">Yes, remove account</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default RemoveAccountModal;
