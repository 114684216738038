import * as Yup from 'yup';

import { IGroupForm } from '../interfaces';

export const initialGroupFormValues: IGroupForm = {
    name: '',
    description: '',
};

export const GroupFormSchema = Yup.object().shape({
    name: Yup.string().required('Group name is a required field'),
    description: Yup.string().notRequired(),
});
