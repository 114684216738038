import { FC } from 'react';

import Cancel from '../../../components/CancelButton/Cancel';
import { InvoiceType } from '../../../redux/features/interfaces';
import MakePaymentEmpty from './MakePaymentEmpty';
import MakePaymentFilled from './MakePaymentFilled';

interface IProps {
    close: () => void;
    invoice: InvoiceType;
    status: string;
    id: string;
}

const PaymentModal: FC<IProps> = ({ close, invoice, status, id }) => {
    return (
        <div className="p-4">
            <Cancel close={close} />
            <div className="grid justify-center w-full mt-1 mb-1">
                <p className="grid justify-center text-[#131221] font-black text-[28px]">Make payment</p>
            </div>
            {!id || status === 'confirmed' ? (
                <MakePaymentEmpty />
            ) : (
                <MakePaymentFilled
                    payment_id={id}
                    close={close}
                    company_name={invoice.vendor.name}
                    invoice_no={invoice.invoice_no}
                    amount={invoice.total_order_value}
                    email={invoice.vendor.email}
                    invoice_id={invoice.id}
                />
            )}{' '}
        </div>
    );
};

export default PaymentModal;
