import EmptyRequest from '../../../assets/images/svg/emptyRequest.svg';

const EmptyGroup = () => {
    return (
        <div className="p-2 pt-0">
            <div>
                <p className="text-[#5E5BA7] text-[16px] mt-4 mb-4">
                    <span className="font-bold">0</span> Groups Created
                </p>
            </div>
            {/* EMPTY CROUP */}
            <div className="grid justify-center">
                <img src={EmptyRequest} alt="empty" />
            </div>
            <div className="grid justify-center mt-4">
                <p className="text-[28px] grid text-center">You have no groups created</p>
                <div className="grid justify-center mt-4  md:w-[376px]">
                    <p className="grid justify-center text-center">
                        You haven’t created a group for your vendors. Click on create group to get started.
                    </p>
                </div>
            </div>

            <div></div>
        </div>
    );
};

export default EmptyGroup;
