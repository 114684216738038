import axios from 'axios';

const { REACT_APP_BACKEND_API } = process.env;

export async function addAccount(payload: any) {
    return (await axios.post(`${REACT_APP_BACKEND_API}/v1/accounts`, payload)).data;
}
export async function resolveAccount(payload: any) {
    return (await axios.get(`${REACT_APP_BACKEND_API}/v1/accounts/resolve?${payload}`)).data;
}
export async function getBanks() {
    return (await axios.get(`${REACT_APP_BACKEND_API}/v1/accounts/banks`)).data;
}
export async function getAccounts() {
    return (await axios.get(`${REACT_APP_BACKEND_API}/v1/accounts`)).data;
}
export async function removeAccount(id: string) {
    return (await axios.delete(`${REACT_APP_BACKEND_API}/v1/accounts/${id}`)).data;
}
export async function markMain(id: string) {
    return (await axios.patch(`${REACT_APP_BACKEND_API}/v1/accounts/${id}`)).data;
}

export async function getSellerBankDetails(id: string) {
    return (await axios.get(`${REACT_APP_BACKEND_API}/v1/invoices/${id}/bank_details`)).data;
}
