import { FC } from 'react';

import NotFound from '../../assets/images/png/image-not-found.png';
import avatar from '../../assets/images/svg/Nopicture.svg';
import { companyType, OrderItemType } from '../../redux/features/interfaces';
import { toLocaleFixed, transformedDateFull } from '../../utils/utils';

interface Iprops {
    Props: {
        product: any;
        qty: number;
        created_at: string;
        id: string;
        status?: string;
        iscustomer?: boolean;
        order_items: OrderItemType[];
        order_no: string;
        images?: string[];
        picture: string;
        ordering_company: companyType;
    };
}

const OrderItemDetails: FC<Iprops> = ({ Props }) => {
    const { product, qty, created_at, status, images, ordering_company, picture } = Props;
    return (
        <div className="px-6 py-2 overflow-y-auto scrollbar h-[42rem]  ">
            <div className="grid grid-cols-2 ">
                <div className=" flex flex-col gap-8 justify-start">
                    <div className="bg-white py-4 pr-4 flex items-center justify-center mr-12">
                        <img src={picture ? picture : NotFound} alt="main1" className="h-[20rem] w-auto  object-contain" />
                    </div>
                    <div className="grid grid-cols-3 w-1/2 justify-start items-center">
                        {images &&
                            images.map((image: string, i: number) => (
                                <div className="mr-0" key={i}>
                                    <img src={image} alt="side1" className="h-[4rem]" />
                                </div>
                            ))}
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <div className=" grid justify-start ">
                        <p
                            className={`text-sm  py-2 px-5 rounded-2xl 
                                    ${
                                        status === 'unfulfilled'
                                            ? 'text-[#D29C17] bg-red-100'
                                            : status === 'fulfilled'
                                            ? 'text-[#084F21] bg-green-200'
                                            : status === 'pending'
                                            ? 'bg-amber-100 text-[#D29C17]'
                                            : '  bg-red-100 text-[#840B0B]'
                                    }`}
                        >
                            {status}
                        </p>
                    </div>
                    <p>
                        <strong>Order from </strong>
                    </p>
                    <div className="text-sm  flex gap-2 ">
                        <div className="flex space-x-2">
                            <div className=" w-12 h-12  overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                                <img
                                    className="rounded-full border border-gray-100 shadow-sm"
                                    src={ordering_company.image ? ordering_company.image : `${avatar}`}
                                    alt="user image"
                                />{' '}
                            </div>
                        </div>

                        <div className="flex flex-col gap-2">
                            <div className="text-[#131221] text-[16px]">{ordering_company.name}</div>
                            <div className="text-[14px] text-[#706F77]">{ordering_company.phone_number}</div>
                        </div>
                    </div>
                    <p>
                        <strong>Order Details </strong>
                    </p>
                    <div className="flex gap-5">
                        <div className="text-[14px] text-[#706F77] w-1/3">
                            <p>Quantity</p>
                            <p>Date</p>
                        </div>
                        <div>
                            <p className="text-[#131221] text-[16px]">{qty}</p>
                            <p className="text-[#131221] text-[16px]"> {transformedDateFull(created_at)}</p>
                        </div>
                    </div>
                    <p className="mt-2">
                        <strong>Product Details </strong>
                    </p>
                    <div className="flex flex-col gap-2">
                        <div className="flex gap-5">
                            <p className="text-[14px] w-1/3 text-[#706F77]">Product name</p>
                            <p className="text-[#131221] text-[16px]">{product.name}</p>
                        </div>
                        <div className="flex gap-5">
                            <p className="text-[14px] w-1/3  text-[#706F77]">SKU</p>
                            <p className="text-[#131221] text-[16px]">{product.sku}</p>
                        </div>
                        <div className="flex gap-5">
                            <p className="text-[14px] w-1/3 text-[#706F77] ">Product description</p>
                            <p className="text-[#131221] text-[16px] w-[20rem]">{product.description}</p>
                        </div>
                        <div className="flex gap-5">
                            <p className="text-[14px] w-1/3 text-[#706F77]">Product price</p>
                            <p className="text-[#131221] text-[16px]">₦ {toLocaleFixed(product.unit_price, 2)}</p>
                        </div>
                        <div className="flex gap-5">
                            <p className="text-[14px] w-1/3 text-[#706F77]">Product weight</p>
                            <p className="text-[#131221] text-[16px]">
                                {product.uom}
                                {/* /bag */}
                            </p>
                        </div>
                        <div className="flex gap-5">
                            <p className="text-[14px] w-1/3 text-[#706F77]">Quantity available</p>
                            <p className="text-[#131221] text-[16px]">
                                {product.quantity.toLocaleString()}
                                {product.uom}
                            </p>
                        </div>
                        <div className="flex gap-5">
                            <p className="text-[14px] w-1/3 text-[#706F77]">Minimum order</p>
                            <p className="text-[#131221] text-[16px]">
                                {product?.min_order}
                                {product.uom}
                            </p>{' '}
                        </div>
                        <div className="flex gap-5">
                            <p className="text-[14px] w-1/3 text-[#706F77]">Maximum order</p>
                            <p className="text-[#131221] text-[16px]">
                                {product?.max_order}
                                {product.uom}
                            </p>
                        </div>
                        {/* Wrong product data */}
                        {/* <div className="flex gap-5">
                            <p className="text-[14px] w-1/3 text-[#706F77]">Discount</p>
                            <p className="text-[#131221] text-[16px]">Nil</p>
                        </div>
                        <div className="flex gap-5">
                            <p className="text-[14px] w-1/3 text-[#706F77]">VAT</p>
                            <p className="text-[#131221] text-[16px]">VAT 7.5% </p>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderItemDetails;
