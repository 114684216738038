import persistReducer from 'redux-persist/es/persistReducer';
import storageSession from 'redux-persist/lib/storage/session';
import { call, put, takeEvery } from 'redux-saga/effects';

import { initialBankState } from '../dtos';
import { Eaction, Iaction, IinitialBankState } from '../interfaces';
import { getAccounts, getBanks } from './bankService';

export const BankReducer = persistReducer(
    {
        storage: storageSession,
        key: 'jumble-bank',
        whitelist: ['banks'],
    },
    (state: IinitialBankState = initialBankState, action: Iaction) => {
        switch (action.type) {
            case Eaction.fulfillBank:
                return { ...state, banks: action.payload, loading: false };
            case Eaction.fulfillAccount:
                return { ...state, accounts: action.payload, loading: false };
            case Eaction.requestBank:
                return { ...state, loading: true };
            case Eaction.requestAccount:
                return { ...state, loading: true };
            case Eaction.failure:
                return { ...state, loading: false, error: action.payload };
            default:
                return state;
        }
    },
);

// Action Creators
export const fetchBanks = () => {
    return { type: Eaction.requestBank };
};
export const fetchAccounts = () => {
    return { type: Eaction.requestAccount };
};

//sagas
export function* bankSaga() {
    yield takeEvery(Eaction.requestBank, function* fetchBanks() {
        try {
            const { data } = yield call(getBanks);
            yield put({ type: Eaction.fulfillBank, payload: data });
        } catch (error) {
            yield put({ type: Eaction.failure, payload: error.message });
        }
    });
}

export function* accountSaga() {
    yield takeEvery(Eaction.requestAccount, function* fetchAccounts() {
        try {
            const { data } = yield call(getAccounts);
            yield put({ type: Eaction.fulfillAccount, payload: data });
        } catch (error) {
            yield put({ type: Eaction.failure, payload: error.message });
        }
    });
}
