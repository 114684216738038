import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';

import PlusIcon from '../../../assets/images/svg/cancel.svg';
import WhitePayment from '../../../assets/images/svg/whitePayment.svg';
import PopUpModal from '../../../components/PopModal/PopUpModal';
import { OrderType } from '../../../redux/features/interfaces';
import { companyType } from '../../../redux/features/interfaces';
import { InvoiceType } from '../../../redux/features/interfaces';
import { useConfirmPaymentMutation } from '../../../redux/services';
import { RootState } from '../../../redux/store';
import CancelContent from '../../order/OrderPopUp/CancelContent';
import ConfirmPayment from '../PaymentPopUp/ConfirmPayment';
import MakePaymentFilled from '../PaymentPopUp/MakePaymentFilled';
import Proof from '../PaymentPopUp/Proof';
import ViewReceipt from '../PaymentPopUp/ViewReceipt';

interface Iprops {
    status: string;
    id: string;
    payment_id: string;
    iscustomer: boolean;
    receipt_no: string;
    vendor: companyType;
    invoice: InvoiceType;
    order: OrderType;
    created_at: string;
    image: string;
    ordering_company: companyType;
    receipt_issued: boolean;
}

const PaymentActions: FC<Iprops> = ({ payment_id, id, status, invoice, image, receipt_no, order, created_at, iscustomer, receipt_issued }) => {
    const dispatch = useDispatch();
    const { reference } = useSelector((state: RootState) => state.getPaymentReducer);
    const reorder = () => {
        //   dispatch(createOrder(orderItems));
    };

    const [confirmPayment, { data: confirmpayment, isLoading, isSuccess, isError, error }] = useConfirmPaymentMutation();

    useEffect(() => {
        if (isSuccess) {
            toast.success(confirmpayment?.message);
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data.message);
        }
    }, [confirmpayment?.message, dispatch, error, isError, isLoading, isSuccess]);
    return (
        <div className="w-full flex justify-end mr-2">
            {status === 'pending' ? (
                !iscustomer ? (
                    <Popup
                        trigger={
                            <div className="flex cursor-pointer h-full rounded-md ml-4 items-center bg-jumbleng-primary px-[1.18rem] py-[0.52125rem]">
                                <img src={WhitePayment} className=" mr-1" alt="plus" />{' '}
                                <span className="font-semibold text-[0.78125rem] text-white">Make Payment</span>
                            </div>
                        }
                        modal
                        nested
                        contentStyle={{
                            width: '702px',
                            overflowY: 'auto',
                            maxHeight: 'calc(100vh - 80px)',
                        }}
                    >
                        {(close: () => void) => (
                            <div>
                                <MakePaymentFilled
                                    payment_id={payment_id}
                                    close={close}
                                    company_name={invoice.ordering_company.name}
                                    invoice_no={invoice.invoice_no}
                                    amount={invoice.total_order_value}
                                    email={invoice.ordering_company.email}
                                    reload={true}
                                    invoice_id={invoice.id}
                                />
                            </div>
                        )}
                    </Popup>
                ) : (
                    <div className="flex gap-2 mr-2">
                        <PopUpModal
                            content={
                                <div className="flex rounded-xl cursor-pointer items-center  justify-center bg-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                                    <img src={PlusIcon} className="mr-1" alt="cancel" />
                                    <div className="font-normal text-[1rem] text-white">Cancel order</div>
                                </div>
                            }
                            className="w-[9rem]"
                            popupcontent={false}
                        >
                            {(close: () => void) => <CancelContent id={id} order_no={order.order_no} close={close} />}
                        </PopUpModal>
                    </div>
                )
            ) : status === 'confirmed' ? (
                <>
                    <div className="flex gap-8 mr-2">
                        {!reference && (
                            <Popup
                                trigger={
                                    <div className="flex rounded-xl cursor-pointer items-center justify-center border border-jumbleng-primary text-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                                        <div className="font-normal text-[1rem] ">
                                            <i className="fa-solid fa-box-archive mr-2"></i>View proof
                                        </div>
                                    </div>
                                }
                                modal
                                nested
                                contentStyle={{ width: '500px' }}
                            >
                                {(close: () => void) => <Proof image={image} close={close} />}
                            </Popup>
                        )}
                        <Popup
                            trigger={
                                <div className="flex rounded-xl cursor-pointer items-center justify-center bg-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[9rem]">
                                    <div className="font-normal text-[1rem] text-white">
                                        <i className="fa fa-check mr-2"></i>View receipt
                                    </div>
                                </div>
                            }
                            modal
                            nested
                            contentStyle={{ width: receipt_issued || iscustomer ? '70%' : '376px' }}
                        >
                            <ViewReceipt
                                id={payment_id}
                                status={status}
                                receipt_no={receipt_no}
                                vendor={order.vendor}
                                order={order}
                                invoice_id={invoice.id}
                                created_at={created_at}
                                reload={true}
                                ordering_company={order.ordering_company}
                                iscustomer={iscustomer}
                                receipt_issued={receipt_issued}
                            />
                        </Popup>
                    </div>
                </>
            ) : status === 'not_confirmed' ? (
                <>
                    {iscustomer ? (
                        <div className="flex gap-6">
                            {!reference && (
                                <Popup
                                    trigger={
                                        <div className="flex rounded-xl cursor-pointer items-center justify-center border border-jumbleng-primary text-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                                            <div className="font-normal text-[1rem] ">
                                                <i className="fa-solid fa-box-archive mr-2"></i>View proof
                                            </div>
                                        </div>
                                    }
                                    modal
                                    nested
                                    contentStyle={{ width: '500px' }}
                                >
                                    {(close: () => void) => <Proof image={image} close={close} />}
                                </Popup>
                            )}
                            <div
                                className="flex rounded-xl text-white cursor-pointer items-center justify-center bg-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[12rem]"
                                onClick={() => confirmPayment(payment_id)}
                            >
                                <PopUpModal
                                    onClose={close}
                                    content={
                                        <div className="font-normal text-[1rem] text-white ">
                                            <i className="fa-solid fa-check mr-2"></i>Confirm payment
                                        </div>
                                    }
                                    className=""
                                    popupcontent={false}
                                >
                                    {(close: () => void) => (
                                        <ConfirmPayment
                                            action={confirmPayment}
                                            isLoading={isLoading}
                                            close={close}
                                            id={payment_id}
                                            invoice_no={invoice?.invoice_no}
                                        />
                                    )}
                                </PopUpModal>
                            </div>
                        </div>
                    ) : (
                        <div className="flex gap-8 ">
                            {!reference && (
                                <Popup
                                    trigger={
                                        <div className="flex rounded-xl cursor-pointer items-center justify-center border border-jumbleng-primary text-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                                            <div className="font-normal text-[1rem] ">
                                                <i className="fa-solid fa-box-archive mr-2"></i>View proof
                                            </div>
                                        </div>
                                    }
                                    modal
                                    nested
                                    contentStyle={{ width: '500px' }}
                                >
                                    {(close: () => void) => <Proof image={image} close={close} />}
                                </Popup>
                            )}
                            <PopUpModal
                                content={
                                    <div className="flex gap-2">
                                        <div className="flex rounded-xl cursor-pointer items-center justify-center bg-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                                            <div className="font-normal text-[1rem] text-white">
                                                <i className="fa fa-comment mr-2"></i>Chat Seller
                                            </div>
                                        </div>
                                    </div>
                                }
                                className="w-[9rem]"
                                popupcontent={false}
                            ></PopUpModal>
                        </div>
                    )}
                </>
            ) : (
                !iscustomer &&
                status === 'cancelled' && (
                    <div>
                        <div
                            className="flex mr-4 rounded-xl cursor-pointer items-center justify-center bg-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]"
                            onClick={() => reorder()}
                        >
                            <span className="font-normal text-[1rem] text-white">
                                <i className="fa text-white mr-2 fa-repeat"></i>Reorder
                            </span>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default PaymentActions;
