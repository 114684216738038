import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';

import More from '../assets/images/svg/More.svg';
import MoreWhite from '../assets/images/svg/More-White.svg';
import PopUpModal from '../components/PopModal/PopUpModal';
import Loading from '../components/UI/Spinner/Loading';
import ApproveInvoice from '../pages/invoice/InvoicePopUp/ApproveInvoice';
import CancelInvoice from '../pages/invoice/InvoicePopUp/CancelInvoice';
import ViewInvoice from '../pages/invoice/InvoicePopUp/ViewInvoice';
import ViewReceipt from '../pages/payment/PaymentPopUp/ViewReceipt';
import { InvoiceType } from '../redux/features/interfaces';
import { RootState } from '../redux/store';
import { convertDate, toLocaleFixed } from '../utils/utils';

interface InvoiceProps {
    data: InvoiceType[];
    loading: boolean;
    currentStatus: string;
}
const InvoiceWrapper: FC<InvoiceProps> = ({ currentStatus, children, data, loading }) => {
    const navigate = useNavigate();
    const [checkedState, setCheckedState] = useState<boolean[]>(new Array(data.length).fill(false));
    const { iscustomer } = useSelector((state: RootState) => state.listCustomerInvoicesReducer);

    const handleClick = (id: string) => {
        navigate(`/invoice/${id}`);
    };

    useEffect(() => {
        setCheckedState(new Array(data.length).fill(false));
    }, [data]);

    if (loading)
        return (
            <div className="min-h-[450px] flex justify-center flex-col">
                <Loading />
            </div>
        );
    return (
        <div>
            {data.map((invoice: InvoiceType, index: number) => (
                <div
                    className={`grid grid-cols-7 pt-4 p-5  items-center hover:bg-[#5D5BA7] hover:text-white cursor-pointer`}
                    key={invoice.id}
                    onClick={() => handleClick(invoice.id)}
                >
                    <>
                        <div className="text-sm grid justify-center ">{convertDate(invoice.order.created_at)}</div>
                        <Popup
                            trigger={
                                <div className={`grid justify-center text-center `}>
                                    {iscustomer ? invoice.ordering_company.name : invoice.vendor.name}
                                </div>
                            }
                            on="hover"
                            closeOnDocumentClick
                            arrow={false}
                            mouseLeaveDelay={50}
                            mouseEnterDelay={0}
                            nested
                            position="top center"
                            contentStyle={{
                                padding: '10px',
                                textAlign: 'center',
                                borderRadius: '12px',
                                width: 'auto',
                                color: '#5E5BA7',
                            }}
                        >
                            {iscustomer ? invoice.ordering_company.name : invoice.vendor.name}
                        </Popup>
                        <div className="text-sm grid justify-center"> INV-{invoice.invoice_no}</div>
                        <div className="text-sm grid justify-center"> {convertDate(invoice.created_at)}</div>
                        <div className="text-sm grid justify-center">₦{toLocaleFixed(invoice.total_order_value, 2)}</div>
                        <div className=" grid justify-center">
                            <p
                                className={`text-sm  py-2 px-5 rounded-2xl 
                                    ${
                                        invoice.status === 'cancelled'
                                            ? ' bg-red-100 text-[#840B0B]'
                                            : invoice.status === 'paid'
                                            ? 'text-[#084F21] bg-green-200'
                                            : invoice.status === 'unpaid'
                                            ? 'bg-amber-100 text-[#D29C17]'
                                            : '  bg-red-100 text-[#840B0B]'
                                    }`}
                            >
                                {invoice.status}
                            </p>
                        </div>
                        <Popup
                            trigger={
                                <div className={`grid justify-center`}>
                                    <img src={checkedState[index] ? MoreWhite : More} alt="more" />
                                </div>
                            }
                            on="hover"
                            closeOnDocumentClick
                            arrow={false}
                            nested
                            mouseLeaveDelay={50}
                            mouseEnterDelay={0}
                            contentStyle={{
                                padding: '10px',
                                borderRadius: '12px',
                                width: '167px',
                                color: '#5E5BA7',
                            }}
                        >
                            {(close: () => void) => (
                                <div className=" grid justify-center">
                                    <div className="grid justify-center pt-2 pb-2">
                                        {invoice.status === 'paid' ? (
                                            <>
                                                <Popup
                                                    onClose={close}
                                                    trigger={
                                                        <div>
                                                            <div className={`mb-3 cursor-pointer `}>View receipt</div>
                                                        </div>
                                                    }
                                                    modal
                                                    contentStyle={{ width: invoice.receipt_issued || iscustomer ? '70%' : '376px' }}
                                                >
                                                    <ViewReceipt
                                                        id={invoice.payment_id}
                                                        receipt_no={invoice.receipt_no}
                                                        ordering_company={invoice.ordering_company}
                                                        vendor={invoice.vendor}
                                                        order={invoice.order}
                                                        iscustomer={iscustomer}
                                                        receipt_issued={invoice.receipt_issued}
                                                    />
                                                </Popup>
                                                {/* <PopUpModal onClose={close} content={'Archive invoice'} className="mb-3" popupcontent={false}></PopUpModal> */}
                                                <Popup
                                                    onClose={close}
                                                    trigger={
                                                        <div>
                                                            <div className={`mb-2 cursor-pointer text-center `}>Print invoice</div>
                                                        </div>
                                                    }
                                                    modal
                                                    contentStyle={{ width: invoice.order.invoice_issued || iscustomer ? '70%' : '376px' }}
                                                >
                                                    {(close: () => void) => (
                                                        <ViewInvoice
                                                            ishome={true}
                                                            isInvoicePage={true}
                                                            id={invoice.id}
                                                            close={close}
                                                            order_no={invoice.order.order_no}
                                                            currentStatus={currentStatus}
                                                            ordering_company={invoice.ordering_company}
                                                            vendor={invoice.vendor}
                                                            created_at={invoice.order.created_at}
                                                            invoice_no={invoice.order.invoice_no}
                                                            invoice_issued={invoice.order.invoice_issued}
                                                            iscustomer={iscustomer}
                                                            status={invoice.order.status}
                                                            order_items={invoice.order.order_items}
                                                            invoice_id={invoice.order.invoice_id}
                                                        />
                                                    )}
                                                </Popup>
                                            </>
                                        ) : invoice.status === 'unpaid' ? (
                                            <>
                                                {iscustomer ? (
                                                    <>
                                                        <Popup
                                                            onClose={close}
                                                            trigger={
                                                                <div>
                                                                    <div className={`mb-3 text-center cursor-pointer `}>Print invoice</div>
                                                                </div>
                                                            }
                                                            modal
                                                            contentStyle={{ width: invoice.order.invoice_issued || iscustomer ? '70%' : '376px' }}
                                                        >
                                                            {(close: () => void) => (
                                                                <ViewInvoice
                                                                    ishome={true}
                                                                    isInvoicePage={true}
                                                                    id={invoice.id}
                                                                    close={close}
                                                                    order_no={invoice.order.order_no}
                                                                    currentStatus={currentStatus}
                                                                    ordering_company={invoice.ordering_company}
                                                                    vendor={invoice.vendor}
                                                                    created_at={invoice.order.created_at}
                                                                    invoice_no={invoice.order.invoice_no}
                                                                    invoice_issued={invoice.order.invoice_issued}
                                                                    iscustomer={iscustomer}
                                                                    status={invoice.order.status}
                                                                    order_items={invoice.order.order_items}
                                                                    invoice_id={invoice.order.invoice_id}
                                                                />
                                                            )}
                                                        </Popup>
                                                        {/* <PopUpModal onClose={close} content={'Archive invoice'} className="mb-3" popupcontent={false}></PopUpModal> */}
                                                        <PopUpModal onClose={close} content={'Cancel invoice'} className="mb-2" popupcontent={false}>
                                                            {(close: () => void) => (
                                                                <CancelInvoice id={invoice.id} close={close} invoice_no={invoice.invoice_no} />
                                                            )}
                                                        </PopUpModal>
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* <PopUpModal onClose={close} content={'Archive invoice'} className="mb-3" popupcontent={false}></PopUpModal> */}
                                                        <Popup
                                                            onClose={close}
                                                            trigger={
                                                                <div>
                                                                    <div className={`mb-2 cursor-pointer text-center `}>Print invoice</div>
                                                                </div>
                                                            }
                                                            modal
                                                            contentStyle={{ width: invoice.order.invoice_issued || iscustomer ? '70%' : '376px' }}
                                                        >
                                                            {(close: () => void) => (
                                                                <ViewInvoice
                                                                    ishome={true}
                                                                    isInvoicePage={true}
                                                                    id={invoice.id}
                                                                    close={close}
                                                                    order_no={invoice.order.order_no}
                                                                    currentStatus={currentStatus}
                                                                    ordering_company={invoice.ordering_company}
                                                                    vendor={invoice.vendor}
                                                                    created_at={invoice.order.created_at}
                                                                    invoice_no={invoice.order.invoice_no}
                                                                    invoice_issued={invoice.order.invoice_issued}
                                                                    iscustomer={iscustomer}
                                                                    status={invoice.order.status}
                                                                    order_items={invoice.order.order_items}
                                                                    invoice_id={invoice.order.invoice_id}
                                                                />
                                                            )}
                                                        </Popup>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {iscustomer ? (
                                                    <>
                                                        <Popup
                                                            onClose={close}
                                                            trigger={
                                                                <div>
                                                                    <div className={`mb-2 cursor-pointer text-center `}>Print invoice</div>
                                                                </div>
                                                            }
                                                            modal
                                                            contentStyle={{ width: invoice.order.invoice_issued || iscustomer ? '70%' : '376px' }}
                                                        >
                                                            {(close: () => void) => (
                                                                <ViewInvoice
                                                                    ishome={true}
                                                                    isInvoicePage={true}
                                                                    id={invoice.id}
                                                                    close={close}
                                                                    order_no={invoice.order.order_no}
                                                                    currentStatus={currentStatus}
                                                                    ordering_company={invoice.ordering_company}
                                                                    vendor={invoice.vendor}
                                                                    created_at={invoice.order.created_at}
                                                                    invoice_no={invoice.order.invoice_no}
                                                                    invoice_issued={invoice.order.invoice_issued}
                                                                    iscustomer={iscustomer}
                                                                    status={invoice.order.status}
                                                                    order_items={invoice.order.order_items}
                                                                    invoice_id={invoice.order.invoice_id}
                                                                />
                                                            )}
                                                        </Popup>
                                                        {/* <PopUpModal onClose={close} content={'Archive invoice'} className="mb-3" popupcontent={false}></PopUpModal>{' '} */}
                                                        {invoice.order.status !== 'cancelled' && (
                                                            <PopUpModal
                                                                onClose={close}
                                                                content={'Approve invoice'}
                                                                className="mb-2"
                                                                popupcontent={false}
                                                            >
                                                                {(close: () => void) => (
                                                                    <ApproveInvoice id={invoice.id} close={close} invoice_no={invoice.invoice_no} />
                                                                )}
                                                            </PopUpModal>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* <PopUpModal onClose={close} content={'Archive invoice'} className="mb-3" popupcontent={false}></PopUpModal> */}
                                                        <Popup
                                                            onClose={close}
                                                            trigger={
                                                                <div>
                                                                    <div className={`mb-2 cursor-pointer text-center `}>Print invoice</div>
                                                                </div>
                                                            }
                                                            modal
                                                            contentStyle={{ width: invoice.order.invoice_issued || iscustomer ? '70%' : '376px' }}
                                                        >
                                                            {(close: () => void) => (
                                                                <ViewInvoice
                                                                    ishome={true}
                                                                    isInvoicePage={true}
                                                                    id={invoice.id}
                                                                    close={close}
                                                                    order_no={invoice.order.order_no}
                                                                    currentStatus={currentStatus}
                                                                    ordering_company={invoice.ordering_company}
                                                                    vendor={invoice.vendor}
                                                                    created_at={invoice.order.created_at}
                                                                    invoice_no={invoice.order.invoice_no}
                                                                    invoice_issued={invoice.order.invoice_issued}
                                                                    iscustomer={iscustomer}
                                                                    status={invoice.order.status}
                                                                    order_items={invoice.order.order_items}
                                                                    invoice_id={invoice.order.invoice_id}
                                                                />
                                                            )}
                                                        </Popup>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </>
                </div>
            ))}
            {children}
        </div>
    );
};

export default InvoiceWrapper;
