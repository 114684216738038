import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EmptyContent from '../../components/EmptyContent/EmptyContent';
import Pagination from '../../components/Pagination/Pagination';
import OrderWrapper from '../../HOC/OrderWrapper';
import { initialOrdersState } from '../../redux/features/dtos';
import { listMyOrders } from '../../redux/features/order/OrderActions';
import {
    useGetAllOrderCountQuery,
    useGetCancelledOrderCountQuery,
    useGetFulfilledOrderCountQuery,
    useGetMyOrdersQuery,
    useGetPendingOrderCountQuery,
    useGetUnfulfilledOrderCountQuery,
} from '../../redux/services/orderApi';
import { RootState } from '../../redux/store';
import { toLocaleFixed } from '../../utils/utils';

export type OrderQuery = {
    page: number;
    limit: number;
    status: string;
    search_query: string;
};

const MyOrder = () => {
    const dispatch = useDispatch();
    const { data: total, isLoading: loadingtotal } = useGetAllOrderCountQuery('purchases');
    const { data: pending, isLoading: loadingpending } = useGetPendingOrderCountQuery('purchases');
    const { data: fulfilled, isLoading: loadingfulfilled } = useGetFulfilledOrderCountQuery('purchases');
    const { data: unfulfiled, isLoading: loadingunfulfilled } = useGetUnfulfilledOrderCountQuery('purchases');
    const { data: cancelled, isLoading: loadingcancelled } = useGetCancelledOrderCountQuery('purchases');
    const { currentStatus } = useSelector((state: RootState) => state.listMyOrdersReducer);
    const initialInvoiceQuery: OrderQuery = {
        page: 1,
        limit: 10,
        status: currentStatus,
        search_query: '',
    };
    const [query, setQuery] = useState<OrderQuery>(initialInvoiceQuery);
    const { data = initialOrdersState, isFetching: isLoading } = useGetMyOrdersQuery(query);
    const [myActiveTab, setMyActiveTab] = useState<'' | 'pending' | 'fulfilled' | 'unfulfilled' | 'cancelled'>(currentStatus);

    const toggleStatus = (status: '' | 'pending' | 'fulfilled' | 'unfulfilled' | 'cancelled') => {
        setQuery({ ...query, status: status, page: 1 });
        dispatch(listMyOrders(status));
        setMyActiveTab(status);
    };

    return (
        <div>
            <div className="mb-[1.5rem] flex justify-between items-center ">
                <div className="flex text-[0.78125rem] text-jumbleng-gray gap-2 md:gap-4 flex-wrap items-center justify-center">
                    <div
                        className={`${
                            myActiveTab === '' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                        } flex gap-2  cursor-pointer border rounded-2xl p-1.5`}
                        onClick={() => toggleStatus('')}
                    >
                        All{' '}
                        <p className={`px-2 ${myActiveTab === '' ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'} border rounded-[33px]`}>
                            {total?.count}
                        </p>{' '}
                    </div>
                    <div
                        className={`${
                            myActiveTab === 'pending' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                        } flex gap-2 cursor-pointer  border  rounded-2xl p-1.5`}
                        onClick={() => toggleStatus('pending')}
                    >
                        Pending
                        <p
                            className={`px-2 ${
                                myActiveTab === 'pending' ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'
                            }  border rounded-[33px]`}
                        >
                            {pending?.count}
                        </p>
                    </div>
                    <div
                        className={`${
                            myActiveTab === 'fulfilled' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                        }  flex gap-2 cursor-pointer  border  rounded-2xl p-1.5`}
                        onClick={() => toggleStatus('fulfilled')}
                    >
                        Fulfiled{' '}
                        <p
                            className={`px-2 ${
                                myActiveTab === 'fulfilled' ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'
                            } border rounded-[33px]`}
                        >
                            {fulfilled?.count}
                        </p>
                    </div>
                    <div
                        className={`${
                            myActiveTab === 'unfulfilled' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                        }  flex gap-2 cursor-pointer  border rounded-2xl p-1.5`}
                        onClick={() => toggleStatus('unfulfilled')}
                    >
                        Not Fulfilled{' '}
                        <p
                            className={`px-2 ${
                                myActiveTab === 'unfulfilled' ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'
                            }  border rounded-[33px]`}
                        >
                            {unfulfiled?.count}
                        </p>
                    </div>
                    <div
                        className={`${
                            myActiveTab === 'cancelled' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                        }  flex gap-2 cursor-pointer  border rounded-2xl p-1.5`}
                        onClick={() => toggleStatus('cancelled')}
                    >
                        Cancelled{' '}
                        <p
                            className={`px-2 ${
                                myActiveTab === 'cancelled' ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'
                            }  border rounded-[33px]`}
                        >
                            {cancelled?.count}
                        </p>
                    </div>
                </div>
                <div className="hidden md:flex items-center relative gap-4">
                    {data?.total_order_value !== 0 && (
                        <div className="hidden md:flex items-center relative ">
                            <div className="h-[2.5rem]  relative border-2 bg-jumbleng-gray text-white px-2 rounded-md my-auto font-bold text-[24px]">
                                &#8358;{toLocaleFixed(data?.total_order_value, 2)}
                            </div>
                        </div>
                    )}
                    <div className="w-[7.7rem] h-[2.3rem] relative mr-[45px]">
                        <i className="fa fa-search icon absolute top-1/2 -translate-y-1/2 px-[0.79rem]" />
                        <input
                            className="w-full bg-[#F8F8FB] border-b-2 focus:outline-none pl-[2.08rem] h-full placeholder:text-[0.78165rem] text-[0.78125rem] text-jumbleng-gray placeholder:text-jumbleng-gray"
                            placeholder="Search order nums,vendors"
                        />
                    </div>
                </div>
            </div>
            <div className="md:hidden flex items-center justify-center relative  mr-6 ">
                <div className="w-[7.7rem] h-[2.3rem] relative ">
                    <i className="fa fa-search icon absolute top-1/2 -translate-y-1/2 px-[0.79rem]" />
                    <input
                        className="w-full bg-[#F8F8FB] border-b-2 focus:outline-none pl-[2.08rem] h-full placeholder:text-[0.78165rem] text-[0.78125rem] text-jumbleng-gray placeholder:text-jumbleng-gray"
                        placeholder="Search order nums,vendors"
                    />
                </div>
            </div>
            <div className=" p-5 mt-5">
                <div className={`grid grid-cols-1 md:grid-cols-8  pt-4 bg-white p-5 justify-items-center  font-bold`}>
                    <div className="jumbleng-gray-main text-base  ">Order date</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Vendor</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Order number</div>
                    <div className="jumbleng-gray-main text-base grid justify-center"> Number of items</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Order Value</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Invoice Issued</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Order Status</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Action</div>
                </div>
                <OrderWrapper
                    customertab={myActiveTab}
                    data={data?.data}
                    loading={isLoading || loadingtotal || loadingcancelled || loadingfulfilled || loadingpending || loadingunfulfilled}
                    currentStatus={currentStatus}
                >
                    {data?.count === 0 ? (
                        <EmptyContent content={currentStatus} />
                    ) : (
                        <Pagination count={data?.count} query={query} setQuery={setQuery} />
                    )}
                </OrderWrapper>{' '}
            </div>
        </div>
    );
};

export default MyOrder;
