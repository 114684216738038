import * as Yup from 'yup';

import { productFieldType } from '../redux/features/product/productRedux';

//product's image validation param
const FILE_SIZE = 5072 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const initialAddProductValues: productFieldType = {
    bar_code: '',
    sku: '',
    name: '',
    description: '',
    vat: '',
    discount: '',
    quantity: '',
    uom: '',
    unit_price: '',
    picture: null,
    product_category: '',
    weight: '',
    min_order: '',
    max_order: '',
    sub_uom: '',
};

export const addProductSchema = Yup.object().shape(
    {
        bar_code: Yup.string().when('bar_code', (val, _schema) => {
            if (val) {
                if (val.length > 0) {
                    return Yup.mixed()
                        .nullable()
                        .notRequired()
                        .typeError('Only digit(s) is allowed')
                        .test('len', 'Maximum is 13 digits', (val: any) => val.toString().length <= 13);
                } else {
                    return Yup.string().notRequired();
                }
            } else {
                return Yup.string().notRequired();
            }
        }),
        sku: Yup.string().required('The sku field is required'),
        name: Yup.string().required('The name field is required'),
        description: Yup.string().required('The description field is required'),
        quantity: Yup.number().typeError('Only digit(s) is allowed').required('The quantity field is required'),
        uom: Yup.string().required('The unit of measurement field is required'),
        sub_uom: Yup.string().notRequired(),
        product_category: Yup.string().required('The product_category field is required'),
        weight: Yup.number().typeError('Only digit(s) is allowed').required('The weight field is required'),
        min_order: Yup.number().typeError('Only digit(s) is allowed').required('The min_order field is required'),
        max_order: Yup.number().typeError('Only digit(s) is allowed').required('The max_order field is required'),
    },
    [['bar_code', 'bar_code']],
);

export const addProductExtensionSchema = Yup.object().shape({
    unit_price: Yup.number().typeError('Only digit(s) is allowed').required('The unit price field is required'),
    vat: Yup.number().typeError('Only digit(s) is allowed'),
    discount: Yup.number().typeError('Only digit(s) is allowed'),
    picture: Yup.mixed()
        .required('The product image is required')
        .test('fileFormat', 'Unsupported Image Format', (value) => value && SUPPORTED_FORMATS.includes(value.type))
        .test('fileSize', 'File is too large', (value) => value && value.size <= FILE_SIZE),
});

export const editProductExtensionSchema = Yup.object().shape({
    unit_price: Yup.number().typeError('Only digit(s) is allowed').required('The unit price field is required'),
    vat: Yup.number().typeError('Only digit(s) is allowed'),
    discount: Yup.number().typeError('Only digit(s) is allowed'),
    picture: Yup.lazy((value) => {
        switch (typeof value) {
            case 'string':
                return Yup.mixed().test('url', (value) => value && value.includes('http')); // schema for image url
            case 'object':
                return Yup.mixed()
                    .test('fileFormat', 'Unsupported Image Format', (value) => value && SUPPORTED_FORMATS.includes(value.type))
                    .test('fileSize', 'File is too large', (value) => value && value.size <= FILE_SIZE); // schema for image file
            default:
                return Yup.mixed().test('url', (value) => value && value.includes('http')); // here you can decide what is the default
        }
    }),
});

export const initialEditProductValues: productFieldType = {
    bar_code: '',
    name: '',
    description: '',
    sku: '',
    quantity: '',
    uom: '',
    unit_price: '',
    picture: null,
    product_category: '',
    weight: '',
    min_order: '',
    max_order: '',
    vat: '',
    sub_uom: '',
    discount: '',
};
