import EmptyRequest from '../../../assets/images/svg/emptyRequest.svg';

interface IProps {
    content: string;
}

const EmptySearch = ({ content }: IProps) => {
    return (
        <div className="p-2 pt-0">
            <div>
                <p className="text-[#5E5BA7] text-[16px] mt-4 mb-4"></p>
            </div>
            {/* EMPTY CROUP */}
            <div className="grid justify-center mt-24">
                <img src={EmptyRequest} alt="empty" />
            </div>
            <div className="grid justify-center mt-4">
                <p className="text-[28px] grid justify-center text-center"> No {content} matches the query</p>
            </div>

            <div></div>
        </div>
    );
};

export default EmptySearch;
