import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ErrorMessage } from 'formik';

const DatePickerField = ({ name, setFieldValue, values }: { name: string; setFieldValue: any; values: any }) => {
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    InputAdornmentProps={{ position: 'end' }}
                    className="w-full"
                    inputFormat="DD/MM/YYYY"
                    disableMaskedInput={true}
                    value={values[name] || ''}
                    onChange={(value) => setFieldValue(name, value)}
                    renderInput={(params: any) => (
                        <TextField {...params} variant="standard" inputProps={{ ...params.inputProps, placeholder: 'DD/MM/YYYY' }} />
                    )}
                />
            </LocalizationProvider>
            <ErrorMessage name={name} render={(msg: any) => <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>} />
        </>
    );
};

export default DatePickerField;
