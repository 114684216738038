import React from 'react';

const AccordionLayout = ({ title, children, index, activeIndex, setActiveIndex, current, planType }: any) => {
    const handleSetIndex = (index: any) => activeIndex !== index && setActiveIndex(index);

    return (
        <div className="w-full">
            <div
                onClick={() => handleSetIndex(index)}
                className={`${
                    activeIndex !== index ? 'rounded-xl bg-[#F8F8FB]' : 'rounded-t-xl bg-[#2F2E54]'
                } flex justify-between px-10 py-5  mt-2 `}
            >
                <div className="flex">
                    <div className="flex gap-4 items-center ">
                        <p className={`${activeIndex === index ? 'text-white' : 'text-[#2F2E54]'}`}>
                            <i className="fa-solid fa-layer-group"></i>
                        </p>
                        <p className={`text-[28px] ${activeIndex === index ? 'text-white' : 'text-black'} font-semibold`}>{title}</p>
                        {current && planType && (
                            <p
                                className={`px-[8px] py-[6px] text-[10px] ${
                                    activeIndex !== index ? 'bg-[#2F2E54] text-white' : 'bg-white text-[#2F2E54]'
                                }  rounded-lg`}
                            >
                                Current Plan
                            </p>
                        )}
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    {activeIndex === index ? (
                        <div className="w-8 h-8 bg-white flex items-center rounded-lg border border-[#2F2E54] justify-center">
                            <i className="fa fa-angle-up text-black"></i>
                        </div>
                    ) : (
                        <div className="w-8 h-8 bg-black flex items-center rounded-lg justify-center">
                            <i className="fa fa-angle-down text-white"></i>
                        </div>
                    )}
                </div>
            </div>

            <div className={`shadow-3xl bg-[#2F2E54] w-full pb-5 px-10 mb-6 ${activeIndex === index ? 'block rounded-b-xl' : 'hidden rounded-xl'}`}>
                {children}
            </div>
        </div>
    );
};

export default AccordionLayout;
