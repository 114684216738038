import { useSelector } from 'react-redux';

import { RootState } from '../../redux/store';
import Compliance from './Compliance';

const SettingsCompliance = () => {
    const { activeTab } = useSelector((state: RootState) => state.tabsReducer);

    return (
        <>
            <div className="flex items-center justify-between my-6 mb-[0.7rem]">
                <h3 className="font-semibold text-[1.25rem] text-jumbleng-gray">Settings</h3>
            </div>

            <div className="flex justify-between border-b pt-1 mb-[1.5rem]">
                <ul className="flex">
                    <li className="flex items-center">
                        <a
                            href="#"
                            className={`${
                                activeTab === 'compliance' && 'font-bold border-b border-b-jumbleng-primary'
                            } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                        >
                            Compliance{' '}
                        </a>
                    </li>
                </ul>
            </div>
            <Compliance />
        </>
    );
};

export default SettingsCompliance;
