import './style.css';

import { ErrorMessage, Field, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '../../components/Button';
import ButtonLoad from '../../components/ButtonLoad/ButtonLoad';
import { IPasswordValue } from '../../interfaces/schema-interface';
import { resetPassword, update2FA } from '../../redux/features/auth/authService';
import { Eaction } from '../../redux/features/interfaces';
import { RootState } from '../../redux/store';
import { initialPasswordValues, passwordValidationSchema } from '../../schema';

const Security = () => {
    const [error, setError] = useState('');
    const [buttonLoad, setButtonLoad] = useState(false);
    const [loader, setloader] = useState(false);
    const { user } = useSelector((state: RootState) => state.authStore);
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [enableField, setEnableField] = useState(false);

    const togglePassword = () => {
        setShowPassword((prev) => !prev);
    };
    const handleOnSubmit = async (values: IPasswordValue, { resetForm }: any) => {
        try {
            setButtonLoad(true);
            await resetPassword(values);
            dispatch({ type: Eaction.setResetOrChangePassword, payload: 'change' });
            toast.success('Password changed successfully');
            setEnableField(false);
            resetForm({ values: '' });
            setButtonLoad(false);
        } catch (err: any) {
            setError(err.response.data?.message);
            setButtonLoad(false);
        }
    };

    const Update2FA = async () => {
        try {
            setloader(true);
            let response, message;
            if (user.twoFA === true) {
                response = await update2FA({ twoFA: false });
                message = '2FA disabled successfully';
            } else {
                response = await update2FA({ twoFA: true });
                message = '2FA enabled successfully';
            }
            dispatch({ type: Eaction.updateUser, payload: response.data });
            toast.success(message);
            setloader(false);
        } catch (error: any) {
            toast.error(error.message);
            setloader(false);
        }
    };

    useEffect(() => {
        const timeId = setTimeout(() => {
            setError('');
        }, 3000);
        return () => {
            clearTimeout(timeId);
        };
    }, [error]);

    return (
        <div className="h-full mr-8">
            <p className="text-jumbleng-primary">Manage your password and authentications</p>
            <div className="bg-white mt-[1.5rem] p-16">
                <div className=" mb-[2.35rem] w-full">
                    <h3 className=" font-semibold text-[1rem] text-jumbleng-gray-main">Two Factor Authentication</h3>
                    <div className="flex mt-3 text-jumbleng-gray justify-between items-center">
                        <p className="w-[50rem]">
                            Help protect your account from unauthorized access by requiring a second authentication method in addition to your
                            password.
                        </p>
                        <ButtonLoad
                            onClick={Update2FA}
                            loading={loader}
                            content={
                                <>
                                    <i className="fa fa-check mr-2"></i>
                                    {user.twoFA ? 'Disable' : 'Enable'}
                                </>
                            }
                        />
                    </div>
                </div>

                <Formik onSubmit={handleOnSubmit} initialValues={initialPasswordValues} validationSchema={passwordValidationSchema}>
                    {({ handleSubmit, errors, setFieldTouched, touched }) => (
                        <div className="flex tems-center justify-between mb-[1.35rem] gap-32 ">
                            <div className="basis-[55%] ">
                                <h3 className=" font-semibold text-[1rem] text-jumbleng-gray-main">Password</h3>
                                <p className=" text-jumbleng-gray mt-2">Manage access to the jumble platform. </p>
                                <div className="flex mt-6">
                                    <div className="w-full text-center    ">
                                        <div className="mb-[3.75rem]">
                                            <div className="mb-[1.953rem] border  border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1] w-full">
                                                <p className="text-[0.7812rem] text-left font-normal text-jumbleng-gray-main">Current password</p>
                                                <div className="field__container">
                                                    <img
                                                        src="https://res.cloudinary.com/odd-developer/image/upload/v1655387139/lock-icon_tx1qlt.svg"
                                                        alt=""
                                                        className="field__img"
                                                    />
                                                    <Field
                                                        onKeyDown={(e: any) => {
                                                            e.key === 'Enter' && handleSubmit();
                                                        }}
                                                        type={`${showPassword ? 'text' : 'password'}`}
                                                        name={'old_password'}
                                                        disabled={!enableField}
                                                        placeholder={'XXXXXXXXX'}
                                                        className={'w-full  placeholder:text-[0.78125rem] focus:outline-none'}
                                                    />
                                                    {!error && (
                                                        <i
                                                            onClick={togglePassword}
                                                            className={`text-gray-500 fa-solid fa-eye${showPassword ? '' : '-slash'}`}
                                                        ></i>
                                                    )}
                                                    {error && <i className="fa-solid fa-circle-exclamation text-[20px] text-red-500"></i>}
                                                </div>
                                                <ErrorMessage
                                                    name={'old_password'}
                                                    render={(msg) => (
                                                        <div className="text-[0.7812rem] text-red-600 text-left font-normal ">{msg}</div>
                                                    )}
                                                />
                                            </div>

                                            <div className="mb-[1.953rem] border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1] ">
                                                <p className="text-[0.7812rem] text-left font-normal text-jumbleng-gray-main">Enter new password</p>
                                                <div className="field__container">
                                                    <img
                                                        src="https://res.cloudinary.com/odd-developer/image/upload/v1655387139/lock-icon_tx1qlt.svg"
                                                        alt=""
                                                        className="field__img"
                                                    />
                                                    <Field
                                                        onKeyDown={(e: any) => {
                                                            e.key === 'Enter' && handleSubmit();
                                                        }}
                                                        type={`${showPassword ? 'text' : 'password'}`}
                                                        name={'password'}
                                                        disabled={!enableField}
                                                        onKeyUp={() => setFieldTouched('password', true)}
                                                        placeholder={'XXXXXXXXX'}
                                                        className={'w-full  placeholder:text-[0.78125rem] focus:outline-none'}
                                                    />
                                                    {!error && (
                                                        <i
                                                            onClick={togglePassword}
                                                            className={`text-gray-500 fa-solid fa-eye${showPassword ? '' : '-slash'}`}
                                                        ></i>
                                                    )}
                                                    {error && <i className="fa-solid fa-circle-exclamation text-[20px] text-red-500"></i>}
                                                </div>
                                                {errors.password && touched.password ? (
                                                    <div className="text-[0.7812rem] text-red-600 text-left font-normal">{errors.password}</div>
                                                ) : null}
                                            </div>
                                            <div className="mb-[1rem] border border-t-transparent border-l-transparent border-r-transparent border-b-[#CFCFD1] ">
                                                <p className="text-[0.7812rem] text-left font-normal text-jumbleng-gray-main">Confirm new password</p>
                                                <div className="field__container">
                                                    <img
                                                        src="https://res.cloudinary.com/odd-developer/image/upload/v1655387139/lock-icon_tx1qlt.svg"
                                                        alt=""
                                                        className="field__img"
                                                    />
                                                    <Field
                                                        onKeyDown={(e: any) => {
                                                            e.key === 'Enter' && handleSubmit();
                                                        }}
                                                        type={`${showPassword ? 'text' : 'password'}`}
                                                        name={'confirm_password'}
                                                        disabled={!enableField}
                                                        onKeyUp={() => setFieldTouched('confirm_password', true)}
                                                        placeholder={'XXXXXXXXX'}
                                                        className={'w-full  placeholder:text-[0.78125rem] focus:outline-none'}
                                                    />
                                                    {!error && (
                                                        <i
                                                            onClick={togglePassword}
                                                            className={`text-gray-500 fa-solid fa-eye${showPassword ? '' : '-slash'}`}
                                                        ></i>
                                                    )}
                                                    {error && <i className="fa-solid fa-circle-exclamation text-[20px] text-red-500"></i>}
                                                </div>
                                                {errors.confirm_password && touched.confirm_password ? (
                                                    <div className="text-[0.7812rem] text-red-600 text-left font-normal">
                                                        {errors.confirm_password}
                                                    </div>
                                                ) : null}
                                            </div>
                                            {error && <div className="text-[0.7812rem] text-red-600 text-left font-normal">{error}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-8 items-end">
                                {enableField ? (
                                    <Button
                                        type={'button'}
                                        onClick={handleSubmit}
                                        message={
                                            <>
                                                <i className="fa fa-floppy-disk mr-2"></i> Save
                                            </>
                                        }
                                        loading={buttonLoad}
                                        className={'w-[8rem] h-[3.125rem] text-white font-semibold rounded-[12px] bg-jumbleng-primary'}
                                    />
                                ) : (
                                    <button
                                        type={'button'}
                                        onClick={() => setEnableField(true)}
                                        className={
                                            'flex rounded-xl cursor-pointer items-center justify-center bg-jumbleng-primary text-white  px-[1.18rem] py-[0.7rem] w-[14rem]'
                                        }
                                    >
                                        <i className="fa fa-lock mr-2"></i> Change Password
                                    </button>
                                )}

                                <div className="bg-[#E7EDF9] text-[#0E3185] p-8 flex flex-col  gap-4 rounded-xl">
                                    <h3 className="font-semibold text-[1rem]">Password Rules</h3>
                                    <p>To create a new password, your password choice must follow this set rules</p>
                                    <div className="font-normal text-[1rem] ">
                                        <p>
                                            <i className="fa fa-check mr-2"></i>Password should be a minimum of 8 characters Password
                                        </p>{' '}
                                        <p>
                                            <i className="fa fa-check mr-2"></i> should consist of alphanumeric characters and symbols @, $, #, % only
                                        </p>{' '}
                                        <p>
                                            <i className="fa fa-check mr-2"></i> Password can’t be same as previous
                                        </p>{' '}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default Security;
