import './Wrapper.css';

import { Icon } from '@iconify/react';
import { FC, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';

import avatar from '../assets/images/png/noImage.png';
import ArrowDown from '../assets/images/svg/arrow-down.svg';
import Avatar from '../assets/images/svg/avatar.svg';
import BellIcon from '../assets/images/svg/bell.svg';
import ClockIcon from '../assets/images/svg/clock.svg';
import HumanIcon from '../assets/images/svg/human.svg';
import LogoutIcon from '../assets/images/svg/logout.svg';
import ShoppingCart from '../assets/images/svg/shopping-cart.svg';
import NotificationBox from '../components/Notification/NotificationBox';
import useGetCount from '../hooks/useGetCount';
import Onboarding from '../pages/Onboarding/Onboarding';
import RequestModal from '../pages/requests/RequestModal';
import Kyc from '../pages/settings/Kyc';
import SettingsCompliance from '../pages/settings/SettingsCompliance';
import { actions } from '../redux/features/auth/authRedux';
import { useGetComplianceQuery, useGetPermissionsQuery } from '../redux/services/complianceApi';
import { setComplianceData } from '../redux/slices/global';
import { RootState } from '../redux/store';
import { SCOPES } from '../utils/permission';
import PermissionsGate from './PermissionGate';

type logoutType = () => void;

interface ILogout {
    logout: logoutType;
}
const Wrapper: FC<ILogout> = ({ logout, children }) => {
    const authStore = useSelector((state: RootState) => state.authStore);
    const { data } = useGetComplianceQuery();
    const { data: fetchedData } = useGetPermissionsQuery();
    const dispatch = useDispatch();
    const { complianceData, complianceScreen } = useSelector((state: RootState) => state.globalReducer);

    const { unreadCount, unreadOrder, unreadInvoice, unreadPayment } = useGetCount();

    const { user, company } = authStore;
    const location = useLocation();
    const navigate = useNavigate();

    const handleLogout = async () => {
        await logout();
        navigate('/');
    };

    useEffect(() => {
        if (data) dispatch(setComplianceData(data?.data));
    }, [dispatch, data, fetchedData]);

    return (
        <div className="bg-jumbleng-background min-h-screen w-full  ">
            {(complianceData?.status === 'Not Submitted' || complianceData?.status === '') && !complianceScreen ? (
                <Onboarding />
            ) : (
                <>
                    <nav className="nav__container left-0 top-0 h-screen ">
                        <div className="flex items-center px-2 md:px-4 self-start justify-between nav__header__container">
                            <Link to="/">
                                <img
                                    src={!company.logo ? avatar : company.logo}
                                    alt="company_logo"
                                    className=" h-[2.9rem] w-[2.9rem] bg-jumbleng-primary-light object-cover rounded-full"
                                />{' '}
                            </Link>
                        </div>
                        <ul className="flex flex-col  px-2 md:px-4 nav__list">
                            <PermissionsGate
                                scopes={[
                                    SCOPES.can_view_company_order_analytics,
                                    SCOPES.can_view_company_sales_analytics,
                                    SCOPES.can_view_company_revenue_analytics,
                                    SCOPES.can_view_company_purchase_analytics,
                                ]}
                            >
                                <li
                                    className={`${
                                        location.pathname.split(' ')[0].includes('/dashboard')
                                            ? 'bg-jumbleng-primary hover:bg-jumbleng-primary text-white hover:text-jumbleng-gray'
                                            : 'text-jumbleng-gray'
                                    } flex rounded-lg  px-[0.39rem] py-[0.78125rem] hover:bg-jumbleng-primary-light`}
                                >
                                    <NavLink to="/dashboard" className={`flex gap-2 items-center`}>
                                        <Icon icon="akar-icons:grid" /> <p className="nav__text">Dashboard</p>
                                    </NavLink>
                                </li>
                            </PermissionsGate>
                            <li
                                className={`${
                                    location.pathname.split(' ')[0].includes('/product')
                                        ? 'bg-jumbleng-primary hover:bg-jumbleng-primary text-white hover:text-jumbleng-gray'
                                        : 'text-jumbleng-gray'
                                } flex rounded-lg  px-[0.39rem] py-[0.78125rem] hover:bg-jumbleng-primary-light`}
                            >
                                <NavLink to="/product" className={`flex gap-2 items-center`}>
                                    <Icon icon="icon-park-outline:ad-product" /> <p className="nav__text">Product</p>
                                </NavLink>
                            </li>
                            <PermissionsGate scopes={[SCOPES.can_manage_customer_orders, SCOPES.can_manage_company_orders]}>
                                <li
                                    className={`${
                                        location.pathname.split(' ')[0].includes('/order')
                                            ? 'bg-jumbleng-primary hover:bg-jumbleng-primary text-white hover:text-jumbleng-gray'
                                            : 'text-jumbleng-gray'
                                    } flex rounded-lg  px-[0.39rem] py-[0.78125rem] hover:bg-jumbleng-primary-light`}
                                >
                                    <NavLink to="/order" className={`flex gap-2 items-center`}>
                                        <Icon icon="eva:shopping-bag-outline" />
                                        <div className="nav__text flex gap-2 items-center">
                                            Orders{' '}
                                            {unreadOrder > 0 && (
                                                <div
                                                    className={`rounded-full flex items-center justify-center   p-2  h-4 w-4 ${
                                                        location.pathname.split(' ')[0].includes('/order')
                                                            ? 'bg-white text-jumbleng-primary '
                                                            : 'bg-jumbleng-primary text-white '
                                                    } text-[12px]`}
                                                >
                                                    {unreadOrder}
                                                </div>
                                            )}
                                        </div>
                                    </NavLink>
                                </li>
                            </PermissionsGate>
                            <li
                                className={`${
                                    location.pathname.split(' ')[0].includes('/vendorlist')
                                        ? 'bg-jumbleng-primary hover:bg-jumbleng-primary text-white hover:text-jumbleng-gray'
                                        : 'text-jumbleng-gray'
                                } flex rounded-lg  px-[0.39rem] py-[0.78125rem] hover:bg-jumbleng-primary-light`}
                            >
                                <NavLink to="/vendorlist" className={`flex gap-2 items-center`}>
                                    <Icon icon="lucide:users" /> <p className="nav__text">Vendors List</p>
                                </NavLink>
                            </li>
                            <PermissionsGate scopes={[SCOPES.can_manage_storefront]}>
                                <li
                                    className={`${
                                        location.pathname.split(' ')[0].includes('/storefront')
                                            ? 'bg-jumbleng-primary hover:bg-jumbleng-primary text-white hover:text-jumbleng-gray'
                                            : 'text-jumbleng-gray'
                                    } flex rounded-lg  px-[0.39rem] py-[0.78125rem] hover:bg-jumbleng-primary-light`}
                                >
                                    <NavLink to="/storefront" className={`flex gap-2 items-center`}>
                                        <Icon icon="ant-design:shopping-cart-outlined" />
                                        <p className="nav__text">Storefronts</p>
                                    </NavLink>
                                </li>
                            </PermissionsGate>
                            <li className="w-full">
                                <div style={{ borderBottom: '1px solid #DFDEED' }} className="mt-2 w-full mb-2" />
                            </li>
                            <PermissionsGate scopes={[SCOPES.can_manage_customer_invoices, SCOPES.can_manage_company_invoices]}>
                                <li
                                    className={`${
                                        location.pathname.split(' ')[0].includes('/invoice')
                                            ? 'bg-jumbleng-primary hover:bg-jumbleng-primary text-white hover:text-jumbleng-gray'
                                            : 'text-jumbleng-gray'
                                    } flex rounded-lg  px-[0.39rem] py-[0.78125rem] hover:bg-jumbleng-primary-light`}
                                >
                                    <NavLink to="/invoice" className={`flex gap-2 items-center `}>
                                        <Icon icon="ant-design:file-twotone" />{' '}
                                        <div className="nav__text flex gap-2 items-center">
                                            Invoice{' '}
                                            {unreadInvoice > 0 && (
                                                <div
                                                    className={`rounded-full flex items-center justify-center   p-2  h-4 w-4 ${
                                                        location.pathname.split(' ')[0].includes('/invoice')
                                                            ? 'bg-white text-jumbleng-primary '
                                                            : 'bg-jumbleng-primary text-white '
                                                    } text-[12px]`}
                                                >
                                                    {unreadInvoice}
                                                </div>
                                            )}
                                        </div>
                                    </NavLink>
                                </li>
                            </PermissionsGate>
                            <PermissionsGate scopes={[SCOPES.can_manage_customer_payments, SCOPES.can_manage_company_payments]}>
                                <li
                                    className={`${
                                        location.pathname.split(' ')[0].includes('/payment')
                                            ? 'bg-jumbleng-primary hover:bg-jumbleng-primary text-white hover:text-jumbleng-gray'
                                            : 'text-jumbleng-gray'
                                    } flex rounded-lg  px-[0.39rem] py-[0.78125rem] hover:bg-jumbleng-primary-light`}
                                >
                                    <NavLink to="/payment" className={`flex gap-2 items-center`}>
                                        <Icon icon="ic:round-payment" />{' '}
                                        <div className="nav__text flex gap-2 items-center">
                                            Payments{' '}
                                            {unreadPayment > 0 && (
                                                <div
                                                    className={`rounded-full flex items-center justify-center   p-2  h-4 w-4 ${
                                                        location.pathname.split(' ')[0].includes('/payment')
                                                            ? 'bg-white text-jumbleng-primary '
                                                            : 'bg-jumbleng-primary text-white '
                                                    } text-[12px]`}
                                                >
                                                    {unreadPayment}
                                                </div>
                                            )}
                                        </div>
                                    </NavLink>
                                </li>
                            </PermissionsGate>
                            <PermissionsGate
                                scopes={[
                                    SCOPES.can_manage_company_profile,
                                    SCOPES.can_manage_company_subscriptions,
                                    SCOPES.can_manage_notifications,
                                    SCOPES.can_view_team_members,
                                    SCOPES.can_manage_security,
                                    SCOPES.can_manage_catalogue,
                                    SCOPES.can_manage_team_members,
                                ]}
                            >
                                <li
                                    className={`${
                                        location.pathname.split(' ')[0].includes('/settings')
                                            ? 'bg-jumbleng-primary hover:bg-jumbleng-primary text-white hover:text-jumbleng-gray'
                                            : 'text-jumbleng-gray'
                                    } flex rounded-lg  px-[0.39rem] py-[0.78125rem] hover:bg-jumbleng-primary-light`}
                                >
                                    <NavLink to="/settings" className={`flex gap-2 items-center`}>
                                        <Icon icon="ant-design:setting-filled" /> <p className="nav__text">Settings</p>
                                    </NavLink>
                                </li>
                            </PermissionsGate>
                        </ul>
                    </nav>

                    <div className="min-h-screen wrapper__content">
                        <nav className="flex justify-between h-[3.8rem] items-center">
                            <div className="w-[10.6rem] h-[2.3rem] relative search__container"></div>
                            <PermissionsGate scopes={[SCOPES.can_view_all_feed, SCOPES.can_view_connected_vendors_feed]}>
                                <Link to="/feed" className="feed__container">
                                    <button className="flex bg-black text-white w-[7rem] h-[2.35rem] rounded-[6px] justify-center items-center text-[0.78125rem] ml-8 mr-5">
                                        <span className="mr-2">
                                            <img src={ShoppingCart} alt="cart" />
                                        </span>
                                        Feed
                                    </button>
                                </Link>
                            </PermissionsGate>

                            <div className="flex items-center  pr-[1.125rem] gap-4">
                                <Popup
                                    trigger={
                                        <div className="flex items-center cursor-pointer relative">
                                            <img src={BellIcon} alt="notification" className="w-[1.95rem] h-[1.0rem]" />
                                            <div className="rounded-full flex items-center justify-center absolute -top-2 -right-1 p-2  h-4 w-4 bg-jumbleng-primary text-white text-[12px]">
                                                {unreadCount}
                                            </div>
                                        </div>
                                    }
                                    closeOnDocumentClick
                                    arrow={false}
                                    nested
                                    position={'bottom center'}
                                    contentStyle={{
                                        padding: 0,
                                        borderRadius: '12px',
                                        fontSize: '0.8rem',
                                        width: 'fit-content',
                                        height: 'fit-content',
                                        marginTop: '1.4rem',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <div className="w-[410px] max-h-[80vh]">
                                        <div className="flex gap-2 justify-between sticky top-0 py-[1.5rem] px-[1.5rem] bg-[#f5f4f8] z-50 shadow-md">
                                            <div className="flex gap-4">
                                                <h2 className="font-bold">Notifications</h2>
                                            </div>
                                            <Link to="/notifications" className="font-bold text-[#5E5BA7] focus:outline-none">
                                                View all
                                            </Link>
                                        </div>
                                        <div className="h-[70vh] overflow-scroll px-[1.5rem] pb-[1.5rem]">
                                            <div className="bg-[#EAE9F3] px-6 py-[18px] mb-2 shadow-md text-[#525171] rounded-[60px] flex gap-2 w-[204px] mt-10">
                                                <div className="flex items-center justify-center cursor-pointer relative">
                                                    <img src={ClockIcon} alt="a-down" />
                                                    <div className="rounded-full flex items-center justify-center absolute -top-2 -right-1 p-2  h-4 w-4 bg-jumbleng-primary text-white text-[12px]">
                                                        2
                                                    </div>
                                                </div>
                                                <p className="font-bold">View reminders</p>
                                            </div>
                                            <NotificationBox isvendor />
                                        </div>
                                    </div>
                                </Popup>
                                <PermissionsGate scopes={[SCOPES.can_manage_connections]}>
                                    <Popup
                                        trigger={<img src={HumanIcon} alt="self" className="w-[1.95rem] h-[1.95rem] cursor-pointer" />}
                                        closeOnDocumentClick
                                        arrow={false}
                                        position={'bottom center'}
                                        contentStyle={{
                                            padding: '1.5rem',
                                            borderRadius: '12px',
                                            width: '500px',
                                            fontSize: '0.8rem',
                                            maxHeight: '500px',
                                            overflow: 'auto',
                                        }}
                                    >
                                        <div>
                                            <RequestModal />
                                        </div>
                                    </Popup>{' '}
                                </PermissionsGate>
                                <Popup
                                    trigger={
                                        <div className="flex items-center justify-center cursor-pointer">
                                            <div className="w-[1.76rem] h-[1.76rem] ">
                                                <img
                                                    src={!user?.image?.length ? Avatar : user.image}
                                                    alt="self"
                                                    className="w-full h-full object-cover pr-[0.78125rem]"
                                                />
                                            </div>
                                            <div className="pr-[0.78165rem]">
                                                {/* <p className="font-bold text-[0.78125rem]">{`${user.first_name} ${user.last_name}`}</p> */}
                                                <p className="font-bold text-[0.78125rem]">
                                                    {user.first_name} {user.last_name}
                                                </p>
                                                <p className="font-normal text-[0.68rem]">{user.email}</p>
                                            </div>
                                            <img src={ArrowDown} alt="a-down" />
                                        </div>
                                    }
                                    closeOnDocumentClick
                                    arrow={false}
                                    on={'hover' || 'click'}
                                    position={'bottom center'}
                                    contentStyle={{
                                        padding: '1.5rem',
                                        borderRadius: '12px',
                                        fontSize: '0.8rem',
                                        overflow: 'auto',
                                    }}
                                >
                                    <>
                                        <div className="flex flex-col gap-4 items-start justify-center ">
                                            <div className="flex items-center cursor-pointer" onClick={handleLogout}>
                                                <img src={LogoutIcon} alt="self" className="w-[1.95rem] h-[1.0rem]" />
                                                <p className="text-[14px]">Logout</p>
                                            </div>
                                        </div>
                                    </>
                                </Popup>
                            </div>
                        </nav>
                        <main className="w-full main__container">
                            {complianceData?.status === 'In Progress' ||
                            complianceData?.status === 'Re-Submitted' ||
                            (complianceData?.status === 'Declined' && !complianceScreen) ? (
                                <Kyc />
                            ) : complianceData?.status === 'Not Submitted' || complianceData?.status === '' || complianceScreen ? (
                                <SettingsCompliance />
                            ) : (
                                <div className="pr-8 pb-8"> {children}</div>
                            )}
                        </main>
                    </div>
                </>
            )}
        </div>
    );
};

export default connect(null, actions)(Wrapper);
