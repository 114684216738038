import axios from 'axios';

const { REACT_APP_BACKEND_API } = process.env;

const getTotalInventory = (): Promise<any> => {
    return axios.get(`${REACT_APP_BACKEND_API}/v1/product/count`);
};

const getStockAvailable = (): Promise<any> => {
    return axios.get(`${REACT_APP_BACKEND_API}/v1/product/count?status=available`);
};

const StockService = {
    getTotalInventory,
    getStockAvailable,
};

export default StockService;
