import moment from 'moment';

export const transformedDate = (date_created: any) => moment(date_created).format('DD MMM, YYYY');
export const convertDate = (date_created: string) => {
    const dbDate = moment(date_created);
    const currentDate = moment();
    if (currentDate.isSame(dbDate, 'day')) return dbDate.format('h:mm a');
    else if (currentDate.isSame(dbDate, 'year')) return dbDate.format('MMM DD');
    else return dbDate.format('MM/DD/YYYY');
};
export const convertNotificationDate = (date_created: string) => {
    const dbDate = moment(date_created);
    const currentDate = moment();
    if (currentDate.isSame(dbDate, 'day')) return 'Today at ' + dbDate.format('h:mm A');
    else if (currentDate.isSame(dbDate.add(+1, 'days'), 'day')) return 'Yesterday at ' + dbDate.format('h:mm A');
    else if (currentDate.isSame(dbDate, 'week')) return dbDate.format('dddd') + ' at ' + dbDate.format('h:mm A');
    else if (currentDate.isSame(dbDate.add(+1, 'weeks'), 'week')) return 'Last ' + dbDate.format('dddd') + ' at ' + dbDate.format('h:mm A');
    else if (currentDate.isSame(dbDate.add(+1, 'months'), 'month')) return 'Last Month' + ' at ' + dbDate.format('h:mm A');
    else if (currentDate.isSame(dbDate, 'year')) return dbDate.format('MMM DD') + ' at ' + dbDate.format('h:mm A');
    else return dbDate.format('MM/DD/YYYY');
};

export const transformedDateFull = (date_created: any) => moment(date_created).format('Do MMMM, YYYY');
export const transformedDateWithTime = (date_created: any) => moment(date_created).format('Do MMM, YYYY h:mm a');
export const transformDate = (date_created: any) => moment(date_created).format('DD/MM/YYYY');

export const parseAndHandleChange = (value: any, setFieldValue: any, id: any) => {
    if (value == '' && typeof value === 'string') setFieldValue(id, value);
    else {
        const parsed = parseInt(value, 10);
        if (parsed) {
            setFieldValue(id, parsed);
        }
    }
};

export const toLocaleFixed = (num: any, n: any) => {
    return num?.toLocaleString(undefined, {
        minimumFractionDigits: n,
        maximumFractionDigits: n,
    });
};

export const getPaginationData = (structure: {
    data: Array<any>;
    total_records: number;
    records_per_page: number;
}): {
    total_pages: number;
    pages: number[];
} => {
    const { total_records, records_per_page } = structure;
    const total_pages = Math.ceil(total_records / records_per_page);
    const pages = [];
    for (let i = 1; i <= total_pages; i++) {
        pages.push(i);
    }
    return { total_pages, pages };
};
