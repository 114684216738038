import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';

import NotFound from '../../../assets/images/png/no-image.jpg';
import ArrowDown from '../../../assets/images/svg/arrow-down.svg';
import More from '../../../assets/images/svg/More.svg';
import Filter from '../../../assets/images/svg/sliders.svg';
import Pagination from '../../../components/Pagination/Pagination';
import PopUpModal from '../../../components/PopModal/PopUpModal';
import { useCloseDiscountMutation } from '../../../redux/services';
import { transformedDate } from '../../../utils/utils';
import CustomerAction from './CustomerActionModal';
import ApplyDiscount from './Popups/ApplyDiscount';

const Discount = ({ data, query, setQuery, unitData }: any) => {
    const [closeDiscount, { isLoading: isloading, isSuccess: issuccess, isError: iserror, error: err }] = useCloseDiscountMutation();

    useEffect(() => {
        if (issuccess) {
            toast.success('Group deleted successfully');
        }
        if (iserror && err && 'status' in err) {
            toast.error(err?.data?.message);
        }
    }, [err, iserror, isloading, issuccess]);

    return (
        <>
            {' '}
            <div className="flex">
                <div className="text-[#5E5BA7] text-[16px] ">
                    <span className="font-bold">{data.count}</span> discounted items
                </div>

                <div className="flex items-center relative" style={{ marginLeft: 'auto' }}>
                    <div className="w-[7.7rem] h-[2.3rem] relative mr-[45px]">
                        <i className="fa fa-search icon absolute top-1/2 -translate-y-1/2 px-[0.79rem]" />
                        <input
                            className="w-full bg-[#F8F8FB] border-b-2 focus:outline-none pl-[2.08rem] h-full placeholder:text-[0.78165rem] text-[0.78125rem] text-jumbleng-gray placeholder:text-jumbleng-gray"
                            placeholder="Search products"
                        />
                    </div>

                    <div className="flex hover:cursor-pointer text-[0.78125rem] mr-[45px] font-normal text-jumbleng-gray items-center">
                        <span className="mr-[9px]">Filter</span>
                        <img src={Filter} className="w-[1.17rem] h-[1.17rem]" alt="Filter" />
                    </div>
                </div>
            </div>
            <div className=" p-5 mt-5">
                <div className="grid grid-cols-5 pt-4 bg-white p-5   font-bold">
                    {' '}
                    <div className="jumbleng-gray-main text-base col-span-1 flex items-center gap-2 ">
                        <div className="grid content-center">
                            <input type="checkbox" />
                        </div>
                        <p> Discount applied on</p>
                    </div>
                    <div className="jumbleng-gray-main text-base col-span-1 flex  items-center gap-2 ">
                        {' '}
                        <div className="flex gap-2 items-center">
                            <p> Discount value</p>
                            <img src={ArrowDown} alt="a-down" />
                        </div>
                    </div>
                    <div className="jumbleng-gray-main text-base grid col-span-1">Start date</div>
                    <div className="jumbleng-gray-main text-base grid col-span-1">End date</div>
                    <div className="jumbleng-gray-main text-base grid col-span-1 justify-center">Action</div>
                </div>

                {data?.data.map((item: any) => (
                    <div className="grid grid-cols-5 pt-4 p-5 gap-1" style={{ backgroundColor: '#F9F9FA' }} key={item.id}>
                        <div className="text-sm col-span-1 flex items-center gap-2 ">
                            {' '}
                            <div className="grid  content-center">
                                <input type="checkbox" />
                            </div>
                            <img src={NotFound} alt="avatar" className="h-[2.2rem] w-[2.2rem] object-contain" />
                            <div>{item.customer.name}</div>
                        </div>
                        <div className="text-sm grid items-center col-span-1"> {item.customer.industry_type}</div>
                        <div className="text-sm grid  items-center col-span-1 capitalize">{item.customer.company_type}</div>
                        <div className="text-sm grid  items-center col-span-1">{transformedDate(item.created_at)}</div>
                        <Popup
                            trigger={
                                <div className="grid items-center justify-center col-span-1">
                                    <img src={More} alt="more" />
                                </div>
                            }
                            on="hover"
                            closeOnDocumentClick
                            arrow={false}
                            nested
                            contentStyle={{
                                padding: '10px',
                                borderRadius: '12px',
                                width: '167px',
                            }}
                        >
                            <div className=" grid justify-center text-jumbleng-primary">
                                <Popup
                                    trigger={<div className="grid justify-center mt-2 hover:text-jumbleng-gray cursor-pointer">Edit Discount</div>}
                                    onClose={close}
                                    closeOnDocumentClick={false}
                                    arrow={false}
                                    modal
                                    contentStyle={{ width: '700px', padding: '0px', border: 'none' }}
                                >
                                    <ApplyDiscount close={close} unitData={unitData} />
                                </Popup>
                                <PopUpModal
                                    content={
                                        <div className="grid justify-center mt-2 mb-2 hover:text-jumbleng-gray cursor-pointer">Close Discount</div>
                                    }
                                    className=""
                                    popupcontent={false}
                                    onClose={close}
                                >
                                    {(close: () => void) => (
                                        <CustomerAction
                                            action={closeDiscount}
                                            close={close}
                                            actionName={'close discount'}
                                            actionTitle={''}
                                            id={data?.data.id}
                                            name={item.customer.name}
                                        />
                                    )}
                                </PopUpModal>
                            </div>{' '}
                        </Popup>
                    </div>
                ))}
                <div className="grid grid-cols-5 pt-4 p-5 gap-1" style={{ backgroundColor: '#F9F9FA' }}>
                    <div className="text-sm col-span-1 flex items-center gap-2 ">
                        {' '}
                        <div className="grid  content-center">
                            <input type="checkbox" />
                        </div>
                        <img src={NotFound} alt="avatar" className="h-[2.2rem] w-[2.2rem] object-contain" />
                        <div>{'member.customer.name'}</div>
                    </div>
                    <div className="text-sm grid items-center col-span-1"> {'member.customer.industry_type'}</div>
                    <div className="text-sm grid  items-center col-span-1 capitalize">{'member.customer.company_type'}</div>
                    <div className="text-sm grid  items-center col-span-1">{'transformedDate(member.created_at'}</div>
                    <Popup
                        trigger={
                            <div className="grid items-center justify-center col-span-1">
                                <img src={More} alt="more" />
                            </div>
                        }
                        on="hover"
                        closeOnDocumentClick
                        arrow={false}
                        nested
                        contentStyle={{
                            padding: '10px',
                            borderRadius: '12px',
                            width: '167px',
                        }}
                    >
                        {(close: () => void) => (
                            <div className=" grid justify-center text-jumbleng-primary">
                                <Popup
                                    trigger={<div className="grid justify-center mt-2 hover:text-jumbleng-gray cursor-pointer">Edit Discount</div>}
                                    closeOnDocumentClick={false}
                                    arrow={false}
                                    modal
                                    contentStyle={{ width: '700px', padding: '0px', border: 'none' }}
                                >
                                    <ApplyDiscount close={close} unitData={unitData} />
                                </Popup>
                                <PopUpModal
                                    content={
                                        <div className="grid justify-center mt-2 mb-2 hover:text-jumbleng-gray cursor-pointer">Close Discount</div>
                                    }
                                    className=""
                                    popupcontent={false}
                                    onClose={close}
                                >
                                    <CustomerAction
                                        action={closeDiscount}
                                        close={close}
                                        actionName={'close the discount'}
                                        actionTitle={''}
                                        id={data?.data.id}
                                        name={'member.customer.name'}
                                    />
                                </PopUpModal>
                            </div>
                        )}
                    </Popup>
                </div>
            </div>
            {data?.data.length > 0 && <Pagination query={query} setQuery={setQuery} data={data?.data} count={data?.count} />}
        </>
    );
};

export default Discount;
