import Pagination from '../../components/Pagination/Pagination';
import { ISubscriptionPlan } from '../../redux/features/interfaces';
import { transformedDate } from '../../utils/utils';

const BillingHistory = ({ data }: any) => {
    return (
        <div>
            {data.map((item: ISubscriptionPlan, index: number) => (
                <div
                    className={`grid grid-cols-1 md:grid-cols-6  px-2  py-4 justify-items-center  md:justify-items-start  hover:bg-[#5D5BA7] hover:text-white ${
                        index % 2 == 0 ? 'bg-[#fff]' : 'bg-[#F9F9FA]'
                    } `}
                    key={index}
                >
                    <>
                        <div className="text-sm items-center  justify-center grid ">{transformedDate(item.created_at)}</div>
                        <div className="text-sm items-center grid md:col-span-3 text-center font-bold md:font-normal  ">{item?.detail}</div>
                        <div className="text-sm items-center grid font-bold md:font-normal ">₦{item?.amount}</div>
                        <div className="grid">
                            {/* ? 'bg-amber-100 text-[#D29C17]' : ' bg-red-100 text-[#840B0B]'" */}
                            <p className="bg-amber-100 text-[#D29C17] py-2 px-4 rounded-full">{item.status}</p>
                        </div>
                    </>
                </div>
            ))}
            <Pagination count={10} query={{ page: 1, limit: 10 }} setQuery={() => null} />
        </div>
    );
};

export default BillingHistory;
