import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import StockDataService from '../services/StockService';

const initialState: any = {};

export const retrieveTotalInventory = createAsyncThunk('stock/total', async () => {
    const res = await StockDataService.getTotalInventory();
    return res.data;
});

export const retrieveStockAvailable = createAsyncThunk('stock/available', async () => {
    const res = await StockDataService.getStockAvailable();
    return res.data;
});

const stockSlice = createSlice({
    name: 'stocks',
    initialState,
    reducers: {},
    extraReducers: {
        [retrieveTotalInventory.fulfilled.toString()]: (state, action) => {
            return [action.payload];
        },
        [retrieveStockAvailable.fulfilled.toString()]: (state, action) => {
            return [action.payload];
        },
    },
});
const { reducer } = stockSlice;
export default reducer;
