import { FC } from 'react';
import Popup from 'reactjs-popup';

import PopUpModal from '../../../components/PopModal/PopUpModal';
import { companyType } from '../../../redux/features/interfaces';
import { OrderItemsType, OrderType } from '../../../redux/features/interfaces';
import ViewReceipt from '../../payment/PaymentPopUp/ViewReceipt';
import ApproveInvoice from '../InvoicePopUp/ApproveInvoice';
import CancelInvoice from '../InvoicePopUp/CancelInvoice';
import ViewInvoice from '../InvoicePopUp/ViewInvoice';

interface Iprops {
    status: string;
    id: string;
    invoice_no: string;
    iscustomer: boolean;
    invoice_issued: boolean;
    order_id: string;
    payment_id: string;
    ordering_company: companyType;
    order_no: string;
    receipt_no: string;
    vendor: companyType;
    invoice_id: string;
    created_at: string;
    receipt_issued: boolean;
    order: OrderType;
    order_items: OrderItemsType[];
}

const InvoiceStatusAction: FC<Iprops> = ({
    status,
    invoice_no,
    ordering_company,
    order_items,
    order,
    invoice_issued,
    payment_id,
    id,
    iscustomer,
    receipt_no,
    vendor,
    invoice_id,
    created_at,
    receipt_issued,
}) => {
    return (
        <div className="w-full flex justify-end">
            {status === 'unpaid' ? (
                <div className="flex gap-2 items-start">
                    <>
                        {/* <PopUpModal
                            content={
                                <div className="flex gap-2">
                                    <div className="flex rounded-xl cursor-pointer items-center justify-center border border-jumbleng-primary text-jumbleng-primary  px-[1.18rem] py-[0.7rem] w-[10rem]">
                                        <div className="font-normal text-[1rem] ">
                                            <i className="fa-solid fa-box-archive"></i>Archive
                                        </div>
                                    </div>
                                </div>
                            }
                            className="mb-4"
                            popupcontent={false}
                            invoicecontent={true}
                        ></PopUpModal> */}
                        <Popup
                            trigger={
                                <div className="flex rounded-xl cursor-pointer items-center justify-center border border-jumbleng-primary text-jumbleng-primary px-[1.18rem] py-[0.7rem]  w-[10rem]">
                                    <div className="font-normal text-[1rem] ">
                                        <i className="fa-solid fa-print mr-2"></i>Print
                                    </div>
                                </div>
                            }
                            className="w-[9rem]"
                            modal
                            contentStyle={{ width: invoice_issued || iscustomer ? '70%' : '376px' }}
                        >
                            {(close: () => void) => (
                                <ViewInvoice
                                    id={id}
                                    order_items={order_items}
                                    close={close}
                                    order_no={order.order_no}
                                    name={vendor.name}
                                    address={vendor.address}
                                    phone={vendor.phone_number}
                                    ordering_company={ordering_company}
                                    invoice_no={invoice_no}
                                    vendor={vendor}
                                    iscustomer={iscustomer}
                                    invoice_issued={invoice_issued}
                                />
                            )}
                        </Popup>
                        {iscustomer && (
                            <PopUpModal
                                content={
                                    <div className="flex gap-2 ">
                                        <div className="flex rounded-xl cursor-pointer items-center justify-center bg-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                                            <div className="font-normal text-[1rem] text-white">
                                                <i className="fa fa-file mr-2"></i>Cancel Invoice
                                            </div>
                                        </div>
                                    </div>
                                }
                                className="mb-4"
                                popupcontent={false}
                            >
                                {(close: () => void) => <CancelInvoice id={id} close={close} invoice_no={invoice_no} />}
                            </PopUpModal>
                        )}
                    </>
                </div>
            ) : status === 'paid' ? (
                <div className="flex gap-2">
                    {/* <PopUpModal
                        content={
                            <div className="flex gap-2 ">
                                <div className="flex rounded-xl cursor-pointer items-center justify-center border border-jumbleng-primary text-jumbleng-primary  px-[1.18rem] py-[0.7rem] w-[10rem]">
                                    <div className="font-normal text-[1rem] ">
                                        <i className="fa-solid fa-box-archive"></i>Archive
                                    </div>
                                </div>
                            </div>
                        }
                        className="w-[9rem]"
                        popupcontent={false}
                    ></PopUpModal> */}
                    <Popup
                        trigger={
                            <div className="flex gap-2 ">
                                <div className="flex rounded-xl cursor-pointer items-center justify-center border border-jumbleng-primary text-jumbleng-primary  px-[1.18rem] py-[0.7rem] w-[10rem]">
                                    <div className="font-normal text-[1rem] ">
                                        <i className="fa fa-print mr-2"></i>Print
                                    </div>
                                </div>
                            </div>
                        }
                        className="w-[9rem]"
                        modal
                        contentStyle={{ width: invoice_issued || iscustomer ? '70%' : '376px' }}
                    >
                        {(close: () => void) => (
                            <ViewInvoice
                                id={id}
                                order_items={order_items}
                                close={close}
                                order_no={order.order_no}
                                name={vendor.name}
                                address={vendor.address}
                                phone={vendor.phone_number}
                                ordering_company={ordering_company}
                                invoice_no={invoice_no}
                                vendor={vendor}
                                iscustomer={iscustomer}
                                invoice_issued={invoice_issued}
                            />
                        )}
                    </Popup>
                    <Popup
                        trigger={
                            <div className="flex gap-2 ">
                                <div className="flex rounded-xl cursor-pointer items-center justify-center bg-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                                    <div className="font-normal text-[1rem] text-white">
                                        <i className="fa fa-file mr-2"></i>View Receipt
                                    </div>
                                </div>
                            </div>
                        }
                        className="w-[9rem]"
                        modal
                        contentStyle={{ width: receipt_issued || iscustomer ? '70%' : '376px' }}
                    >
                        <ViewReceipt
                            id={payment_id}
                            status={status}
                            receipt_no={receipt_no}
                            vendor={vendor}
                            order={order}
                            reload={true}
                            invoice_id={invoice_id}
                            created_at={created_at}
                            ordering_company={ordering_company}
                            iscustomer={iscustomer}
                            receipt_issued={receipt_issued}
                        />
                    </Popup>
                </div>
            ) : status === 'cancelled' ? (
                <>
                    <div className={`flex ${iscustomer && order.status !== 'cancelled' && 'w-[justify-end]'} gap-6 mr-6`}>
                        {/* <PopUpModal
                            content={
                                <div className="flex rounded-xl cursor-pointer items-center justify-center border border-jumbleng-primary text-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                                    <div className="font-normal text-[1rem] ">
                                        <i className="fa-solid fa-box-archive mr-2"></i>Archive
                                    </div>
                                </div>
                            }
                            className="w-[9rem]"
                            popupcontent={false}
                        ></PopUpModal> */}
                        <Popup
                            trigger={
                                <div className="flex rounded-xl cursor-pointer items-center justify-center border border-jumbleng-primary text-jumbleng-primary px-[1.18rem] py-[0.7rem] w-[10rem]">
                                    <div className="font-normal text-[1rem] ">
                                        <i className="fa-solid fa-print mr-2"></i>Print
                                    </div>
                                </div>
                            }
                            className="w-[9rem]"
                            modal
                            contentStyle={{ width: invoice_issued || iscustomer ? '70%' : '376px' }}
                        >
                            {(close: () => void) => (
                                <ViewInvoice
                                    id={id}
                                    order_items={order_items}
                                    close={close}
                                    order_no={order.order_no}
                                    name={vendor.name}
                                    address={vendor.address}
                                    phone={vendor.phone_number}
                                    ordering_company={ordering_company}
                                    invoice_no={invoice_no}
                                    vendor={vendor}
                                    iscustomer={iscustomer}
                                    invoice_issued={invoice_issued}
                                />
                            )}
                        </Popup>
                        {iscustomer && order.status !== 'cancelled' && (
                            <PopUpModal
                                content={
                                    <div className="flex rounded-xl cursor-pointer items-center justify-center bg-jumbleng-primary px-[1rem] py-[0.7rem] w-[11rem]">
                                        <div className="font-normal text-[1rem] text-white">
                                            <i className="fa fa-check mr-2"></i>Approve invoice
                                        </div>
                                    </div>
                                }
                                className="w-[9rem]"
                                popupcontent={false}
                            >
                                {(close: () => void) => <ApproveInvoice id={id} close={close} invoice_no={invoice_no} />}
                            </PopUpModal>
                        )}
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

export default InvoiceStatusAction;
