import { Link } from 'react-router-dom';

import NotFound from '../../assets/images/png/no-image.jpg';
import LoaderIcon from '../../assets/images/svg/loader.svg';
import EmptyRequest from '../../assets/images/svg/norequest.svg';
import PermissionsGate from '../../HOC/PermissionGate';
import { useGetSentRequestsQuery } from '../../redux/services/supplyChainApi';
import { SCOPES } from '../../utils/permission';
import { transformedDateFull } from '../../utils/utils';
import { queryType } from './SentRequests';

type Props = {
    activeTab: string;
    query: queryType;
};
const AcceptedRequest = ({ activeTab, query }: Props) => {
    const { data } = useGetSentRequestsQuery(query);

    return (
        <div className="grid justify-center">
            {data?.data.length === 0 ? (
                <>
                    <div className="grid justify-center">
                        <img src={EmptyRequest} alt="empty" />
                    </div>
                    <div className="grid justify-center mt-4">
                        <p className="text-[28px]">No {activeTab} request</p>
                    </div>
                    <div style={{ width: '458px' }} className="grid justify-center mt-4 text-center mx-auto">
                        {activeTab === 'accepted' ? (
                            <p>
                                No vendor has accepted any request to connect with them, if you have not sent out a request yet, visit the feed or
                                search to find a vendor to make a request
                            </p>
                        ) : activeTab === 'pending' ? (
                            <p>
                                You have no pending request. All request has either been accepted or declined by the vendor. Visit the feed or search
                                to finda vendor to connect with
                            </p>
                        ) : (
                            <p>
                                No vendor has declined any of your request to connect with them, if you have not sent out a request, visit the feed or
                                search to find a vendor to connect with
                            </p>
                        )}
                    </div>
                    <PermissionsGate scopes={[SCOPES.can_view_all_feed, SCOPES.can_view_connected_vendors_feed]}>
                        <Link to="/feed">
                            <div className="grid justify-center mt-4 cursor-pointer">
                                <div style={{ backgroundColor: '#5E5BA7', borderRadius: '12px' }} className="px-[50px] py-[16px]">
                                    <p className="text-white">Visit Feed</p>
                                </div>
                            </div>
                        </Link>
                    </PermissionsGate>
                </>
            ) : (
                data?.data.map((item: any, i: number) => (
                    <div className="flex gap-6 pb-8 w-[600px] justify-between" key={i}>
                        <div className="flex gap-2 items-center w-[14rem] ">
                            <div className="flex gap-2 items-center rounded-full min-w-[3.2rem]">
                                <img
                                    src={item.requested_company.logo ? item.requested_company.logo : NotFound}
                                    alt="avatar"
                                    className="h-[3.2rem] w-[3.2rem] object-contain"
                                />
                            </div>
                            <div className="max-w-[10.8rem] flex flex-col">
                                <p className="font-bold w-full break-words">{item.requested_company.name}</p>
                                <p>{item.requested_company.industry_type}</p>
                            </div>
                        </div>
                        <div>
                            <p className="text-[#525171] ">
                                {item.status == 'pending'
                                    ? 'request sent'
                                    : item.status === 'accepted'
                                    ? 'accepted your request'
                                    : 'Request declined'}
                            </p>
                            <p className="text-[#525171]">on-{transformedDateFull(item.updated_at)}</p>
                        </div>
                        {item.status === 'accepted' ? (
                            <Link to={`/vendorcatalogue/${item.requested_company.id}/catalogues`}>
                                <div className="grid content-center">
                                    <p className="font-bold text-[#5E5BA7]">View catalogue</p>
                                </div>
                            </Link>
                        ) : item.status === 'pending' ? (
                            <div className="grid content-center">
                                <p className="font-bold text-[#D29C17] flex">
                                    <img src={LoaderIcon} alt="loader" className="mr-2" /> Pending
                                </p>
                            </div>
                        ) : null}
                    </div>
                ))
            )}
        </div>
    );
};

export default AcceptedRequest;
