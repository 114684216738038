import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import { all } from 'redux-saga/effects';

import { AuthReducer, authSaga } from './features/auth/authRedux';
import { accountSaga, BankReducer, bankSaga } from './features/bank-payment/bankRedux';
import { cartReducer } from './features/Cart/CartReducer';
import { Eaction } from './features/interfaces';
import { invoice } from './features/invoice/InvoiceReducer';
import { order } from './features/order/OrderReducer';
import { payment } from './features/payment/PaymentReducer';
import { ProductReducer } from './features/product/productRedux';
import modalReducer from './modal/modalRedux';
import { customerVendorApi, dashboardApi, groupApi, invoiceApi, paymentApi, requestApi, subscriptionApi } from './services';
import { complianceApi } from './services/complianceApi';
import { inviteApi } from './services/inviteApi';
import { notificationApi } from './services/notificationApi';
import { orderApi } from './services/orderApi';
import { storeApi } from './services/storeApi';
import { supplierVendorApi } from './services/supplierVendorApi';
import { teamsApi } from './services/teamsApi';
import companyProfileReducer from './slices/company_profile';
import globalReducer from './slices/global';
import notificationReducer from './slices/notifications';
import salesReducer from './slices/sales';
import stocksReducer from './slices/stocks';
import tabsReducer, { persistConfig } from './slices/tabs';

export const reducers = combineReducers({
    authStore: AuthReducer,
    ProductReducer,
    cartReducer,
    getOrderReducer: order.getOrderReducer,
    listMyOrdersReducer: order.listMyOrdersReducer,
    listCustomerOrdersReducer: order.listCustomerOrdersReducer,
    listMyInvoicesReducer: invoice.listMyInvoicesReducer,
    listCustomerInvoicesReducer: invoice.listCustomerInvoicesReducer,
    getInvoiceReducer: invoice.getInvoiceReducer,
    getPaymentReducer: payment.getPaymentReducer,
    listMyPaymentsReducer: payment.listMyPaymentsReducer,
    listCustomerPaymentsReducer: payment.listCustomerPaymentsReducer,
    modalReducer,
    salesReducer,
    stocksReducer,
    notificationReducer,
    globalReducer: persistReducer(persistConfig, globalReducer),
    companyProfileReducer,
    bankStore: BankReducer,
    tabsReducer: persistReducer(persistConfig, tabsReducer),
    [teamsApi.reducerPath]: teamsApi.reducer,
    [inviteApi.reducerPath]: inviteApi.reducer,
    [storeApi.reducerPath]: storeApi.reducer,
    [groupApi.reducerPath]: groupApi.reducer,
    [requestApi.reducerPath]: requestApi.reducer,
    [complianceApi.reducerPath]: complianceApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [customerVendorApi.reducerPath]: customerVendorApi.reducer,
    [supplierVendorApi.reducerPath]: supplierVendorApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
});

export const rootReducer = (state: any, action: any) => {
    if (action.type === Eaction.logout) {
        sessionStorage.clear();
        const { authStore } = state;
        state = { authStore };
    }
    return reducers(state, action);
};

export function* rootSaga() {
    yield all([authSaga(), bankSaga(), accountSaga()]);
}
