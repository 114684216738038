import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import NotFound from '../../assets/images/png/noImage.png';
import AddBg from '../../assets/images/svg/addBGImage.svg';
import LeftArrow from '../../assets/images/svg/arrow-left.svg';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import OrderCartModal from '../../components/OrderCartModal/OrderCartModal';
import Pagination from '../../components/Pagination/Pagination';
import Loading from '../../components/UI/Spinner/Loading';
import PermissionsGate from '../../HOC/PermissionGate';
import WrapperVendor from '../../HOC/WrapperVendor';
import { addToCart } from '../../redux/features/Cart/CartAction';
import { initialCompanyState } from '../../redux/features/dtos';
import {
    useApproveRequestMutation,
    useGetACompanyQuery,
    useGetCompanyCatalogueQuery,
    useSendRequestMutation,
} from '../../redux/services/supplyChainApi';
import { RootState } from '../../redux/store';
import { SCOPES } from '../../utils/permission';
import { toLocaleFixed } from '../../utils/utils';
import PrivateVendorCatalogue from './PrivateVendorCatalogue';

type CatalogueQuery = {
    page: number;
    id: string;
    limit: number;
    search_query: string;
};

const VendorCatalogue = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const initialCatalogueQuery: CatalogueQuery = {
        page: 1,
        limit: 12,
        id: id as string,
        search_query: '',
    };
    const navigate = useNavigate();
    const [sendRequest, { isLoading, isSuccess, isError, error }] = useSendRequestMutation();
    const [query, setQuery] = useState<CatalogueQuery>(initialCatalogueQuery);
    const { data, isLoading: isFetching } = useGetCompanyCatalogueQuery(query);
    const { data: company = initialCompanyState, isLoading: loading } = useGetACompanyQuery(id as string);
    const [approveRequest, { isLoading: loader, isSuccess: success, isError: iserror, error: err }] = useApproveRequestMutation();

    const authStore = useSelector((state: RootState) => state.authStore);
    const { user } = authStore;

    const sendrequest = async (id: string) => {
        await sendRequest(id);
    };
    const acceptrequest = async (id: string) => {
        await approveRequest(id);
    };
    const handleChange = (e: any) => {
        setQuery({ ...query, search_query: e.target.value, page: 1 });
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Request sent successfully');
        } else if (success) {
            toast.success('Request accepted successfully');
        } else if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
        } else if (iserror && err && 'status' in err) {
            toast.error(err?.data?.message);
        }
    }, [isSuccess, isError, error, isLoading, loader, success, iserror, err]);

    return loading || isFetching ? (
        <div className="min-h-screen flex justify-center flex-col">
            <Loading />
        </div>
    ) : (
        <WrapperVendor>
            <div className="mt-8 ">
                <div className="flex  text-jumbleng-gray gap-4 mb-8 justify-between cursor-default">
                    <div onClick={() => navigate(-1)} className="cursor-pointer">
                        <div className="flex text-[#5E5BA7]">
                            <img src={LeftArrow} alt="leftarrow" className="pr-2" />
                            Back
                        </div>
                    </div>
                    <div className="text-[0.78125rem]">
                        <OrderCartModal />
                    </div>
                </div>

                <div className="mt-4">
                    <p className="font-semibold mb-4 text-[#131221] text-[21px]">Vendor&apos;s Catalogue</p>
                    <div className="bg-[#F1F1F2] flex items-center flex-col justify-center h-[100px] w-full">
                        {!company.banner ? (
                            <>
                                {' '}
                                <div className="grid justify-center">
                                    <img src={AddBg} alt="addimg" />
                                </div>
                                <p className="text-[#706F77] text-[14px]">No background Image available</p>
                            </>
                        ) : (
                            <img src={company.banner} alt="addimg" className="h-[100px] w-[100%] object-cover" />
                        )}
                    </div>
                </div>
                <div className="flex mt-8 gap-2">
                    <div className="flex space-x-2 mr-3">
                        <div className="w-24 h-24">
                            <img className="w-24 h-24 object-cover rounded-full" src={company.logo ? company.logo : NotFound} alt="user image" />{' '}
                        </div>
                    </div>
                    <div>
                        <p className="text-[#131221] font-semibold  text-[28px]">{company.name}</p>
                        <div className="flex gap-1 text-[#706F77]">
                            <p>{company.company_type}.</p>
                            <p className="font-semibold"> {company?.address}</p>
                        </div>
                        <p className="w-[500px] text-[#525171] mt-1 mb-4">{company.bio}</p>
                        {company.connection_type === '' ? (
                            <button className="flex" onClick={() => sendrequest(company.id)}>
                                <div className="flex items-center px-4  py-2 rounded-xl bg-jumbleng-primary text-white">
                                    <i className="fa-solid fa-paper-plane mr-1"></i>
                                    <span>Send request</span>
                                </div>
                            </button>
                        ) : company.connection_type === 'supplier' && company.connection_status !== 'accepted' ? (
                            <button className="flex" onClick={() => acceptrequest(company.id)}>
                                <div className="flex items-center px-4  py-2 text-white bg-jumbleng-primary rounded-[12px]">
                                    <i className="fa-solid fa-check mr-1"></i>
                                    <span>Accept request</span>
                                </div>
                            </button>
                        ) : company.connection_status === 'pending' ? (
                            <div className="flex">
                                <div className="flex items-center  px-4  py-2 rounded-xl border border-jumbleng-primary text-jumbleng-primary">
                                    <i className="fa fa-check mr-1"></i> <span>Request sent</span>
                                </div>
                            </div>
                        ) : company.connection_status === 'accepted' ? (
                            <div className="flex">
                                <div className="flex items-center  px-4  py-2 rounded-xl border border-jumbleng-primary text-jumbleng-primary">
                                    <i className="fa fa-bolt mr-1"></i>
                                    <span>Connected</span>
                                </div>
                            </div>
                        ) : company.connection_status === 'received' ? (
                            <div className="flex">
                                <div className="flex items-center  px-4  py-2 rounded-xl border border-jumbleng-primary text-jumbleng-primary">
                                    <i className="fa fa-check mr-1"></i> <span>Accept Request</span>
                                </div>
                            </div>
                        ) : company.connection_status === 'declined' ? (
                            <div className="flex">
                                <div className="flex items-center  px-4  py-2 rounded-xl border border-jumbleng-red text-jumbleng-red">
                                    <i className="fa fa-xmark mr-1"></i> <span>Declined</span>
                                </div>
                            </div>
                        ) : (
                            <button className="flex" onClick={() => sendrequest(company.id)}>
                                <div className="flex items-center px-4  py-2 hover:text-white hover:bg-jumbleng-primary text-jumbleng-primary border border-jumbleng-primary rounded-[12px]">
                                    <i className="fa-solid fa-paper-plane mr-1"></i>
                                    <span>Send request</span>
                                </div>
                            </button>
                        )}
                    </div>
                </div>

                <hr className="mt-4 mb-4" />

                {!data?.data.isPrivate && (
                    <div className="mt-8 mb-4 flex">
                        <div className="text-[#525171]">
                            <span className="text-[#5E5BA7]">{data?.data.catalogue.length}</span> items found{' '}
                        </div>
                        <div className="flex relative" style={{ marginLeft: 'auto' }}>
                            <i className="fa fa-search icon absolute top-1/2 -translate-y-1/2 px-[0.79rem] " />
                            <input
                                className="w-full bg-[#F8F8FB] border-b-2 focus:outline-none pl-[2.08rem] h-full placeholder:text-[0.78165rem] text-[0.78125rem] text-jumbleng-gray placeholder:text-jumbleng-gray"
                                placeholder="Search products"
                                onChange={handleChange}
                            />{' '}
                        </div>
                    </div>
                )}
            </div>
            {data?.data.isPrivate ? (
                <PrivateVendorCatalogue />
            ) : (
                <div className="flex w-full gap-4 flex-wrap">
                    {data?.data.catalogue.length === 0 ? (
                        <div className="w-full justify-end">
                            <EmptyContent content={'Catalogue'} />
                        </div>
                    ) : (
                        data?.data.catalogue.map((item: any) => (
                            <div className="basis-[32.5%] bg-white  mb-3" key={item.id}>
                                <div className="h-[10rem] flex items-center justify-center">
                                    <img
                                        src={item.product.picture ? item.product.picture : NotFound}
                                        alt="sidetable"
                                        className="object-contain w-[auto] h-[10rem]"
                                    />
                                </div>

                                <div className="bg-white pt-4 pl-4 pb-4">
                                    <p className="text-[#525171] text-[16px] ">{item.product.name}</p>
                                    <p className="text-[#525171] text-[28px] font-bold">
                                        ₦{toLocaleFixed(item.product.unit_price, 2)}{' '}
                                        <span className="text-[#525171] text-[16px]">/ {item.product.uom}</span>
                                    </p>
                                    <PermissionsGate scopes={[SCOPES.can_create_orders]}>
                                        {company.connection_status === 'accepted' && (
                                            <div
                                                style={{
                                                    border: '1px solid #5E5BA7',
                                                    borderRadius: '12px',
                                                }}
                                                className="mr-4 mb-4 mt-4 text-[#5E5BA7] text-[16px] grid justify-center py-2 hover:bg-[#5E5BA7] hover:text-[#fff] cursor-pointer"
                                                onClick={() =>
                                                    dispatch(
                                                        addToCart(
                                                            user.id,
                                                            item.product.id,
                                                            item.pricing.catalogue_id,
                                                            company.name,
                                                            item.product.unit_price,
                                                            item.product.name,
                                                            item.product.picture,
                                                        ),
                                                    )
                                                }
                                            >
                                                <p>+ Add to Order</p>
                                            </div>
                                        )}
                                    </PermissionsGate>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            )}
            {!data?.data.isPrivate && data?.data.catalogue.length !== 0 && (
                <Pagination query={query} setQuery={setQuery} data={data?.data} count={data?.count} />
            )}
        </WrapperVendor>
    );
};

export default VendorCatalogue;
