import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { ICompanyProfile } from '../../interfaces';
import { initialCompanyProfileValues } from '../../schema/company-details.schema';
import { IBioInterface } from '../../schema/settings-schema';
import { actions } from '../features/auth/authRedux';
import { IinitalProfileStateInterface } from '../features/interfaces';
import { closeModal, openModal } from '../modal/modalRedux';
import ProfileService from '../services/ProfileService';

const initialState: IinitalProfileStateInterface = {
    data: initialCompanyProfileValues,
    loading: false,
};

export const deleteLogo = createAsyncThunk('settings/deletelogo', async (_, thunkAPI) => {
    try {
        thunkAPI.dispatch(openModal());
        const res = await ProfileService.deleteLogo();
        thunkAPI.dispatch(actions.updateProfile(res.data.data));
        thunkAPI.dispatch(closeModal());
        return res.data;
    } catch (error) {
        thunkAPI.dispatch(closeModal());
        let errorMessage = 'Internal Server Error';
        if (error.response) {
            errorMessage = error.response.data?.message;
        }
        return thunkAPI.rejectWithValue(errorMessage);
    }
});

export const updateCompanyProfile = createAsyncThunk('settings/updateProfile', async (formData: ICompanyProfile, thunkAPI) => {
    try {
        const res = await ProfileService.updateCompanyProfile(formData);
        thunkAPI.dispatch(actions.updateProfile(res.data.data));
        return res.data;
    } catch (error) {
        let errorMessage = 'Internal Server Error';
        if (error.response) {
            errorMessage = error.response.data?.message;
        }
        return thunkAPI.rejectWithValue(errorMessage);
    }
});

export const updateCatalogue = createAsyncThunk('settings/updatecatalogue', async (formData: IBioInterface, thunkAPI) => {
    try {
        const res = await ProfileService.updateCatalogue(formData);
        thunkAPI.dispatch(actions.updateProfile(res.data.data));
        return res.data;
    } catch (error) {
        let errorMessage = 'Internal Server Error';
        if (error.response) {
            errorMessage = error.response.data?.message;
        }
        return thunkAPI.rejectWithValue(errorMessage);
    }
});

const companySlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(updateCompanyProfile.pending, (state, _) => {
                state.loading = true;
            })
            .addCase(updateCompanyProfile.fulfilled, (state, action) => {
                state.loading = false;
                toast.success(action.payload.message);
            })
            .addCase(updateCompanyProfile.rejected, (state, action: any) => {
                toast.error(action.payload);
                state.loading = false;
            })
            .addCase(deleteLogo.pending, (state, _) => {
                state.loading = true;
            })
            .addCase(deleteLogo.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
                toast.success(action.payload.message);
            })
            .addCase(deleteLogo.rejected, (state, action: any) => {
                toast.error(action.payload);
                state.loading = false;
            })
            .addCase(updateCatalogue.pending, (state, _) => {
                state.loading = true;
            })
            .addCase(updateCatalogue.fulfilled, (state, action) => {
                state.loading = false;
                toast.success(action.payload.message);
            })
            .addCase(updateCatalogue.rejected, (state, action: any) => {
                toast.error(action.payload);
                state.loading = false;
            });
    },
});

export const {} = companySlice.actions;

export default companySlice.reducer;
