const Notifications = () => {
    return (
        <div className="h-full mr-8">
            <p className="text-jumbleng-primary">Manage notifications or alerts on actions and remainders</p>
            <div className="bg-white mt-[1.5rem] p-16 pl-64 pr-64">
                <div className=" mb-[1.35rem] w-full">
                    <h3 className=" font-semibold text-[1rem] text-jumbleng-gray-main">Push notifications</h3>
                    <div className="flex text-jumbleng-gray justify-between items-center">
                        <p className="w-[50rem]">Receive notification even when app is idle or closed.</p>
                        <div className="flex rounded-xl cursor-pointer  items-center justify-center bg-jumbleng-primary  px-[1.18rem] py-[0.7rem] w-[10rem]">
                            <div className="font-normal text-[1rem] text-white ">
                                <i className="fa fa-check mr-2"></i>Enable
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" mb-[2.35rem] w-full">
                    <h3 className=" font-semibold text-[1rem] text-jumbleng-gray-main">News and Update</h3>
                    <div className="flex  mt-2 text-jumbleng-gray justify-between items-center">
                        <p className="w-[50rem]">Receive latest news and updates from jumble.</p>
                    </div>
                    <div className="w-[50rem] mt-4 gap-3 text-[#525171]">
                        <div className="flex ">
                            <div>
                                <input type="checkbox" name="non_connected_vendors" className="mr-[18px]" />
                            </div>
                            <div className="flex flex-col">
                                <span className="font-semibold">News and updates.</span>
                                <span> News about product and feature updates</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-[50rem] mt-4 gap-3 text-[#525171]">
                        <div className="flex ">
                            <div>
                                <input type="checkbox" name="non_connected_vendors" className="mr-[18px]" />
                            </div>
                            <div className="flex flex-col">
                                <span className="font-semibold">Tips and tutorials.</span>
                                <span>Tips and tutorials on how to use or navigate features .</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" mb-[2.35rem] w-full">
                    <h3 className=" font-semibold text-[1rem] text-jumbleng-gray-main">Feed posts</h3>
                    <div className="flex  mt-2 text-jumbleng-gray justify-between items-center">
                        <p className="w-[50rem]">Notifications received when new posts are posted on feed by connected vendors.</p>
                    </div>
                    <div className="flex flex-col mt-4 gap-3 text-[#525171]">
                        <p className="w-[50rem]">
                            <input type="radio" name="non_connected_vendors" className="mr-[18px]" />
                            Do not notify me.
                        </p>
                        <div className="w-[50rem]">
                            <div className="flex ">
                                <div>
                                    <input type="radio" name="non_connected_vendors" className="mr-[18px]" />
                                </div>
                                <div className="flex flex-col">
                                    <span className="font-semibold">Connected vendors only.</span>
                                    <span> Only receive notifcations from vendors i am connected to</span>
                                </div>
                            </div>
                        </div>
                        <div className="w-[50rem]">
                            <div className="flex ">
                                <div>
                                    <input type="radio" name="non_connected_vendors" className="mr-[18px]" />
                                </div>
                                <div className="flex flex-col">
                                    <span className="font-semibold">All posts.</span>
                                    <span>Receive notifications on all new posts on my feeds.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" mb-[2.35rem] w-full">
                    <h3 className=" font-semibold text-[1rem] text-jumbleng-gray-main">Reminders</h3>
                    <div className="flex mt-2 text-jumbleng-gray justify-between items-center">
                        <p className="w-[50rem]">Notifications received on activities requiring your attention or actions.</p>
                    </div>
                    <div className="flex flex-col mt-4 gap-3 text-[#525171]">
                        <p className="w-[50rem]">
                            <input type="radio" name="non_connected_vendors" className="mr-[18px]" />
                            Do not notify me.
                        </p>
                        <div className="w-[50rem]">
                            <div className="flex ">
                                <div>
                                    <input type="radio" name="non_connected_vendors" className="mr-[18px]" />
                                </div>
                                <div className="flex flex-col">
                                    <span className="font-semibold">All reminders </span>
                                    <span> Receive notifications on all reminders set</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" mb-[2.35rem] w-full">
                    <h3 className=" font-semibold text-[1rem] text-jumbleng-gray-main">Notification Channels</h3>
                    <div className="flex mt-2 text-jumbleng-gray justify-between items-center">
                        <p className="w-[50rem]">Choose the channels you want to recieve notifications.</p>
                    </div>
                    <div className="flex flex-col mt-4 gap-3 text-[#525171] max-w-[708px]">
                        <div className="flex justify-between items-center py-[1.5rem] border-t border-t-jumbleng-primary-light">
                            <div className="flex items-center gap-4 text-jumbleng-gray text-[18px] font-bold">
                                <img src="/icons/whatsapp.svg" alt="" />
                                <p>Whatsapp</p>
                            </div>
                            <div className="flex items-center">
                                <input className="border border-jumbleng-primary-light font-semibold rounded-lg leading-[2.5rem] min-w-[18rem] h-[2.5rem] px-2 outline-none" />

                                <div className="ml-[1.5rem]">
                                    <i className="fa-solid fa-toggle-on text-[29px] text-[#095F27] cursor-pointer "></i>{' '}
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between items-center py-[1.5rem] border-t border-t-jumbleng-primary-light">
                            <div className="flex items-center gap-4 text-jumbleng-gray text-[18px] font-bold">
                                <img src="/icons/sms.svg" alt="" />
                                <p>SMS</p>
                            </div>
                            <div className="flex items-center">
                                <input className="border border-jumbleng-primary-light font-semibold rounded-lg leading-[2.5rem] min-w-[18rem] h-[2.5rem] px-2 outline-none" />
                                <div className="ml-[1.5rem]">
                                    <i className="fa-solid fa-toggle-on text-[29px] text-[#095F27] cursor-pointer "></i>{' '}
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between items-center py-[1.5rem] border-y border-y-jumbleng-primary-light">
                            <div className="flex items-center gap-4 text-jumbleng-gray text-[18px] font-bold">
                                <div className="border border-jumbleng-primary-light h-[32px] w-[32px] flex items-center justify-center rounded-lg">
                                    <img src="/icons/google.svg" alt="" />
                                </div>
                                <p>Email</p>
                            </div>
                            <div className="flex items-center w-full justify-end">
                                <input className="border border-jumbleng-primary-light font-semibold rounded-lg leading-[2.5rem] min-w-[18rem] h-[2.5rem] px-2 outline-none" />

                                <div className="ml-[1.5rem]">
                                    <i className="fa-solid fa-toggle-on text-[29px] text-[#095F27] cursor-pointer "></i>{' '}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Notifications;
