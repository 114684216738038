import axios from 'axios';
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import SelectSearch from 'react-select-search';
import * as yup from 'yup';

import ArrowDown from '../../../../assets/images/svg/arrow-down.svg';
import Search from '../../../../assets/images/svg/search.svg';
import Button from '../../../../components/Button';
import Cancel from '../../../../components/CancelButton/Cancel';

const AssignSalesRep = ({ close, handleSubmit, loading, customer }: any) => {
    const initialData = {
        id: '',
    };

    const schema = yup.object().shape({
        fields: yup.array().required('Must select a customer'),
    });

    function renderCustomer(props: any, option: any, snapshot: any, className: any) {
        return (
            <button value={option.value.value} {...props} className={className} type="button">
                <span>
                    <span>{option.name}</span>
                </span>
            </button>
        );
    }
    return (
        <>
            <div className="mt-4 mr-4">
                <Cancel close={close} />
            </div>
            <div className="px-[88px] pt-2 pb-[32px] w-[702px] h-[524px]">
                <Formik
                    onSubmit={(values) => handleSubmit(values, customer.company_id, close)}
                    validationSchema={schema}
                    initialValues={{ fields: [initialData] }}
                >
                    {({ handleSubmit, values, setFieldValue }) => (
                        <>
                            <p className="text-[#131221] font-black  text-[28px] grid justify-center mb-4 ">Assign sales rep </p>
                            <input type="text" className="hidden" />
                            <FieldArray name="fields">
                                {({ remove, push }) => (
                                    <div>
                                        {values.fields.map((_, i) => (
                                            <div key={i}>
                                                <p className="text-[#525171] text-[16px] my-2 font-semibold">
                                                    Sales rep {i + 1}
                                                    {i > 0 && (
                                                        <i
                                                            className="fa-solid text-red-600 fa-circle-minus ml-4 cursor-pointer"
                                                            onClick={() => remove(i)}
                                                        ></i>
                                                    )}
                                                </p>
                                                <p className="text-[#525171] text-[16px] mb-2">Which sales rep would you like to assign to vendor?</p>
                                                <div className="relative">
                                                    <SelectSearch
                                                        options={[]}
                                                        className="select-search"
                                                        renderOption={renderCustomer}
                                                        getOptions={(_query) => {
                                                            return new Promise((resolve, reject) => {
                                                                axios
                                                                    .get(
                                                                        `https://jumble-services-api.herokuapp.com/v1/company/team?page=1&limit=10&role=sales_rep`,
                                                                    )
                                                                    .then(({ data }) => {
                                                                        resolve(
                                                                            data?.data?.map(({ id, first_name, last_name }: any) => ({
                                                                                value: id,
                                                                                name: `${first_name} ${last_name}`,
                                                                            })),
                                                                        );
                                                                    })
                                                                    .catch(reject);
                                                            });
                                                        }}
                                                        emptyMessage="No sales rep found"
                                                        placeholder="Search Sales rep"
                                                        search
                                                        onChange={(args) => {
                                                            setFieldValue(`fields[${i}].id`, args);
                                                        }}
                                                    />
                                                    <img src={Search} className="absolute left-0 top-0 mt-2 ml-2 z-50" />
                                                    <img src={ArrowDown} className="absolute right-0 top-0 mt-4 mr-12 z-50" />
                                                </div>

                                                <div>
                                                    <Field
                                                        name={`fields.${i}.id`}
                                                        type="text"
                                                        list={`fields.${i}.id`}
                                                        value={values.fields[i].id || ''}
                                                        className="hidden"
                                                        placeholder="Search Customer"
                                                        id={`fields.${i}.id`}
                                                    />
                                                    <ErrorMessage
                                                        name={`fields.${i}.id`}
                                                        render={(msg: any) => (
                                                            <div className={'text-[0.7812rem] text-red-600 text-left font-normal'}>{msg}</div>
                                                        )}
                                                    />{' '}
                                                </div>
                                            </div>
                                        ))}
                                        <div className="flex gap-2 items-center mt-[36px] cursor-pointer text-[#5E5BA7] ">
                                            <i className="fa-solid fa-plus"></i>
                                            <p className=" font-semibold" onClick={() => push(initialData)}>
                                                {' '}
                                                Assign another sales rep
                                            </p>
                                        </div>
                                        <div className="flex justify-between gap-2 mt-8 pb-5">
                                            <div
                                                style={{ border: '1px solid #5E5BA7' }}
                                                className=" rounded-lg grid justify-center py-2 w-[168px] h-[48px]  cursor-pointer "
                                                onClick={close}
                                            >
                                                <p className="font-semibold text-[#5E5BA7] text-[16px] "> Cancel</p>
                                            </div>
                                            <Button
                                                type={'submit'}
                                                onClick={handleSubmit}
                                                disabled={values.fields.some((field: any) => !field.id)}
                                                loading={loading}
                                                message={`Assign`}
                                                className={`${
                                                    values.fields.some((field: any) => !field.id) && 'cursor-not-allowed opacity-50'
                                                } bg-[#5E5BA7] text-white font-semibold rounded-lg flex items-center justify-center py-2 cursor-pointer w-[168px] h-[48px]`}
                                            ></Button>
                                        </div>
                                    </div>
                                )}
                            </FieldArray>
                        </>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default AssignSalesRep;
