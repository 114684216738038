/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';

import More from '../assets/images/svg/More.svg';
import MoreWhite from '../assets/images/svg/More-White.svg';
import LoadingModal from '../components/LoadingModal/LoadingModal';
import PopUpModal from '../components/PopModal/PopUpModal';
import Loading from '../components/UI/Spinner/Loading';
import CancelContent from '../pages/order/OrderPopUp/CancelContent';
import ConfirmPayment from '../pages/payment/PaymentPopUp/ConfirmPayment';
import PaymentModal from '../pages/payment/PaymentPopUp/PaymentModal';
import Proof from '../pages/payment/PaymentPopUp/Proof';
import ViewReceipt from '../pages/payment/PaymentPopUp/ViewReceipt';
import { PaymentType } from '../redux/features/interfaces';
import { closeModal, openModal } from '../redux/modal/modalRedux';
import { useConfirmPaymentMutation } from '../redux/services';
import { RootState } from '../redux/store';
import { convertDate, toLocaleFixed } from '../utils/utils';
const _ = require('lodash');

interface PaymentProps {
    data: PaymentType[];
    loading: boolean;
    setPaymentID?: any;
    paymentID?: string;
    setSkip?: any;
}
const PaymentWrapper: FC<PaymentProps> = ({ children, data, loading, setPaymentID, setSkip, paymentID }) => {
    const [classname, setClassname] = useState<string>('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [checkedState, setCheckedState] = useState<boolean[]>([]);
    const { iscustomer } = useSelector((state: RootState) => state.listCustomerPaymentsReducer);
    const [paymentId, setPaymentId] = useState('');

    useEffect(() => {
        setCheckedState(new Array(data.length).fill(false));
    }, [data]);

    const handleChecked = (position: number, id: string) => {
        const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : false));
        setClassname('bg-[#5D5BA7] text-white');
        setCheckedState(updatedCheckedState);
        setCheckedState((state) => {
            if (_.isEqual(state, new Array(data.length).fill(false))) {
                setPaymentId('');
            } else {
                setPaymentId(id);
            }
            return state;
        });
    };

    useEffect(() => {
        if (setSkip) {
            if (paymentId !== paymentID && paymentId != '') {
                setPaymentID(paymentId);
                setSkip(false);
            } else {
                setSkip(true);
                setPaymentID('');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentId, setPaymentID, setSkip]);

    const [confirmPayment, { data: confirmpayment, isLoading, isSuccess, isError, error }] = useConfirmPaymentMutation();

    useEffect(() => {
        // if (isLoading) {
        //     dispatch(openModal());
        // }
        if (isSuccess) {
            toast.success(confirmpayment?.message);
            // dispatch(closeModal());
            close();
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
            // dispatch(closeModal());
            close();
        }
    }, [confirmpayment?.message, dispatch, error, isError, isLoading, isSuccess]);

    const handleClick = (id: string) => {
        navigate(`/payment/${id}`);
    };
    if (loading)
        return (
            <div className="min-h-[450px] flex justify-center flex-col">
                <Loading />
            </div>
        );

    return (
        <div>
            <LoadingModal />
            {data.map((payment: PaymentType, index: number) => (
                <div
                    className={`grid grid-cols-8 pt-4 p-5  justify-items-center hover:bg-[#5D5BA7] hover:text-white cursor-pointer ${
                        checkedState[index] ? classname : 'bg-white'
                    }`}
                    key={payment.id}
                >
                    <>
                        <div className="text-sm flex items-start ">
                            {!iscustomer && (
                                <input
                                    type="checkbox"
                                    onChange={() => handleChecked(index, payment.id)}
                                    checked={checkedState[index] || false}
                                    onClick={(e) => e.stopPropagation()}
                                    className="mt-1"
                                />
                            )}
                            <span className={`${!iscustomer && 'ml-1'}`}> {convertDate(payment.order.created_at)} </span>
                        </div>
                        <div className="text-sm grid justify-center w-full text-center" onClick={() => handleClick(payment.id)}>
                            {iscustomer ? payment.order.ordering_company.name : payment.order.vendor.name}
                        </div>
                        <div className="text-sm grid justify-center w-full" onClick={() => handleClick(payment.id)}>
                            ORD-{payment.order.order_no}
                        </div>
                        <div className="text-sm grid justify-center w-full" onClick={() => handleClick(payment.id)}>
                            {' '}
                            INV-{payment.invoice.invoice_no}
                        </div>
                        <div className="text-sm grid justify-center w-full" onClick={() => handleClick(payment.id)}>
                            ₦{toLocaleFixed(payment.invoice.total_order_value, 2)}
                        </div>
                        <div className="text-sm grid justify-center w-full" onClick={() => handleClick(payment.id)}>
                            {' '}
                            {payment.receipt_issued ? 'Yes' : 'No'}
                        </div>

                        <div className=" grid justify-center w-full" onClick={() => handleClick(payment.id)}>
                            <p
                                className={`text-sm  py-2 px-5 rounded-2xl 
                                    ${
                                        payment.status === 'not_confirmed'
                                            ? 'bg-amber-100 text-[#D29C17]'
                                            : payment.status === 'confirmed'
                                            ? 'text-[#084F21] bg-green-200'
                                            : payment.status === 'pending'
                                            ? 'bg-amber-100 text-[#D29C17]'
                                            : '  bg-red-100 text-[#840B0B]'
                                    }`}
                            >
                                {payment.status === 'not_confirmed' ? 'unconfirmed' : payment.status}
                            </p>
                        </div>
                        <Popup
                            trigger={
                                payment.status == 'cancelled' && iscustomer ? (
                                    <></>
                                ) : (
                                    <div className={`grid justify-center`}>
                                        <img src={checkedState[index] ? MoreWhite : More} alt="more" />
                                    </div>
                                )
                            }
                            on={'click'}
                            closeOnDocumentClick
                            arrow={false}
                            nested
                            contentStyle={{
                                padding: '10px',
                                borderRadius: '12px',
                                width: '167px',
                                color: '#5E5BA7',
                            }}
                        >
                            {(close: () => void) => (
                                <div className=" grid justify-center">
                                    <div className="grid justify-center pt-2 pb-2">
                                        {payment.status === 'pending' ? (
                                            <div className="flex flex-col gap-2">
                                                {!iscustomer && (
                                                    <Popup
                                                        trigger={<div className="cursor-pointer">Make Payment</div>}
                                                        modal
                                                        contentStyle={{
                                                            width: '702px',
                                                            overflowY: 'auto',
                                                            maxHeight: 'calc(100vh - 80px)',
                                                        }}
                                                        onClose={close}
                                                    >
                                                        {(close: () => void) => (
                                                            <div>
                                                                <PaymentModal
                                                                    invoice={payment.invoice}
                                                                    close={close}
                                                                    status={payment.status}
                                                                    id={payment.id}
                                                                />
                                                            </div>
                                                        )}
                                                    </Popup>
                                                )}
                                                <PopUpModal
                                                    onClose={close}
                                                    content={
                                                        <div className="flex flex-col  gap-2">
                                                            <div>Cancel Order</div>
                                                        </div>
                                                    }
                                                    className=""
                                                    popupcontent={false}
                                                >
                                                    {(close: () => void) => (
                                                        <CancelContent id={payment.order.id} order_no={payment.order.order_no} close={close} />
                                                    )}
                                                </PopUpModal>
                                            </div>
                                        ) : payment.status === 'confirmed' ? (
                                            <>
                                                <PopUpModal onClose={close} content={'View Proof'} className="mb-3" popupcontent={false}>
                                                    {(close: () => void) => <Proof image={payment.proof} close={close} />}
                                                </PopUpModal>
                                                <PopUpModal
                                                    onClose={close}
                                                    content={'View Receipt'}
                                                    className="mb-3"
                                                    popupcontent={false}
                                                    invoicecontent={true}
                                                >
                                                    <ViewReceipt
                                                        id={payment.id}
                                                        status={payment.status}
                                                        receipt_no={payment.invoice.receipt_no}
                                                        vendor={payment.order.vendor}
                                                        order={payment.order}
                                                        invoice_id={payment.invoice_id}
                                                        created_at={payment.created_at}
                                                        ordering_company={payment.order.ordering_company}
                                                        iscustomer={iscustomer}
                                                        receipt_issued={payment.receipt_issued}
                                                    />
                                                </PopUpModal>
                                            </>
                                        ) : payment.status === 'not_confirmed' ? (
                                            <>
                                                {iscustomer ? (
                                                    <div className="flex flex-col items-center">
                                                        <PopUpModal onClose={close} content={'View Proof'} className="mb-3" popupcontent={false}>
                                                            {(close: () => void) => <Proof image={payment.proof} close={close} />}
                                                        </PopUpModal>
                                                        <PopUpModal onClose={close} content={'Confirm Payment'} className="mb-3" popupcontent={false}>
                                                            {(close: () => void) => (
                                                                <ConfirmPayment
                                                                    action={confirmPayment}
                                                                    isLoading={isLoading}
                                                                    close={close}
                                                                    id={payment?.id}
                                                                    invoice_no={payment.invoice?.invoice_no}
                                                                />
                                                            )}
                                                        </PopUpModal>

                                                        {/* <p onClick={() => confirmPayment(payment.id)} className="cursor-pointer">
                                                            Confirm payment
                                                        </p> */}
                                                    </div>
                                                ) : (
                                                    <>
                                                        <PopUpModal onClose={close} content={'View Proof'} className="mb-3" popupcontent={false}>
                                                            {(close: () => void) => <Proof image={payment.proof} close={close} />}
                                                        </PopUpModal>
                                                        <PopUpModal
                                                            onClose={close}
                                                            content={'Chat Seller'}
                                                            className=""
                                                            popupcontent={false}
                                                        ></PopUpModal>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </>
                </div>
            ))}
            {children}
        </div>
    );
};

export default PaymentWrapper;
