import * as Yup from 'yup';

import { IEmailValue, IPasswordValue, ISignInValues } from '../interfaces/schema-interface';

export const initialSignInValues: ISignInValues = {
    email: '',
    password: '',
};

export const initialEmailValue: IEmailValue = {
    email: '',
};

export const SignInSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').trim().required('Email is required'),
    password: Yup.string().required('Password is required'),
});

export const EmailSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email format').trim().required('Email is required'),
});

export const initialPasswordValues: IPasswordValue = {
    old_password: '',
    password: '',
    confirm_password: '',
};
export const passwordValidationSchema = Yup.object().shape(
    {
        old_password: Yup.string().when('old_password', (val, _) => {
            if (val) {
                if (val.length > 0) {
                    return Yup.string().required('Password is required');
                } else {
                    return Yup.string().notRequired();
                }
            } else {
                return Yup.string().notRequired();
            }
        }),
        password: Yup.string()
            .required('Password is required')
            .min(8, 'minimum of 8 characters')
            .matches(/[@$%#]+/, 'Must contain at least one of @, $, #, %'),
        confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    },
    [['old_password', 'old_password']],
);
