import { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import NotFound from '../../assets/images/png/no-image.jpg';
import NoImage from '../../assets/images/png/noImage.png';
import Pagination from '../../components/Pagination/Pagination';
import Loading from '../../components/UI/Spinner/Loading';
import PermissionsGate from '../../HOC/PermissionGate';
import { addToCart } from '../../redux/features/Cart/CartAction';
import { companyType } from '../../redux/features/interfaces';
import { useGetCompaniesQuery } from '../../redux/services/supplyChainApi';
import { RootState } from '../../redux/store';
import { SCOPES } from '../../utils/permission';
import { FeedQuery } from '../feed/Feed';
import EmptySearch from '../vendorlist/EmptyContent/EmptySearch';
import EmptyVendor from '../vendorlist/EmptyContent/EmptyVendor';

type Props = {
    query: FeedQuery;
    setQuery: Dispatch<SetStateAction<FeedQuery>>;
};
const ConnectedVendor = ({ query, setQuery }: Props) => {
    const dispatch = useDispatch();
    const authStore = useSelector((state: RootState) => state.authStore);
    const { user } = authStore;
    const { data, isFetching } = useGetCompaniesQuery(query);

    if (isFetching) {
        return (
            <div className="min-h-[calc(100vh-20rem)] flex justify-center flex-col">
                <Loading />
            </div>
        );
    }
    return (
        <div>
            {data?.data.length === 0 && query.search_query !== '' ? (
                <EmptySearch content="company" />
            ) : data?.data.length === 0 ? (
                <EmptyVendor />
            ) : (
                <div className="flex lg:flex-row flex-col flex-wrap gap-8 mb-8 justify-between">
                    {data?.data.map((company: companyType) => (
                        <div className="lg:w-[47%] bg-white flex flex-col w-full" key={company.id}>
                            <div className="flex justify-between px-8 items-center py-6">
                                <div className="flex">
                                    <div className="flex space-x-2 mr-3">
                                        <img
                                            src={company.logo ? company.logo : NoImage}
                                            alt="user image"
                                            className=" h-[2.9rem] w-[2.9rem] object-cover rounded-full  shadow-sm"
                                        />{' '}
                                    </div>

                                    <div>
                                        <p className="font-bold text-base text-jumbleng-gray">{company.name}</p>
                                        <p className="font-normal text-sm">{company.industry_type}</p>
                                    </div>
                                </div>

                                <Link to={`/vendorcatalogue/${company.id}/catalogues`}>
                                    <span className="text-base font-bold text-jumbleng-gray cursor-pointer">View catalogue</span>
                                </Link>
                            </div>
                            <div className="h-[25.81rem] flex justify-center items-center ">
                                <img
                                    src={company.catalogue[0]?.product.picture ? company.catalogue[0]?.product.picture : NotFound}
                                    alt="_"
                                    className="w-[auto] h-[25rem] object-contain "
                                />
                            </div>

                            <div className="p-8 flex justify-between">
                                <div className="max-w-[364px]">
                                    <h3 className="font-semibold text-[21px] text-jumbleng-gray-main mb-2">{company.catalogue[0]?.product.name}</h3>
                                    <p className="font-normal text-base text-jumbleng-gray mb-6">{company.catalogue[0]?.product.description} </p>
                                    <p className="font-normal text-base">{company.catalogue[0]?.product.createdAt}</p>
                                </div>

                                <PermissionsGate scopes={[SCOPES.can_create_orders]}>
                                    {company.catalogue.length > 0 && (
                                        <button className="flex">
                                            <div
                                                className="flex px-4  py-2 hover:bg-jumbleng-primary hover:text-white text-[#5E5BA7]"
                                                style={{
                                                    border: '1px solid #5E5BA7',
                                                    borderRadius: '12px',
                                                }}
                                                onClick={() =>
                                                    dispatch(
                                                        addToCart(
                                                            user.id,
                                                            company.catalogue[0]?.product.id,
                                                            company.catalogue[0]?.id,
                                                            company.name,
                                                            company.catalogue[0]?.product.unit_price,
                                                            company.catalogue[0]?.product.name,
                                                            company.catalogue[0]?.product.picture,
                                                        ),
                                                    )
                                                }
                                            >
                                                <span className="flex ">
                                                    {' '}
                                                    <p className="mr-2">+ </p> Add to order
                                                </span>
                                            </div>
                                        </button>
                                    )}
                                </PermissionsGate>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {data?.data.length !== 0 && <Pagination query={query} setQuery={setQuery} data={data?.data} count={data?.count} />}{' '}
        </div>
    );
};

export default ConnectedVendor;
