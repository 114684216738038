import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import storageSession from 'redux-persist/lib/storage/session';

import { IComplianceResponse } from '../features/interfaces';

type intialStateType = {
    customers: string[];
    products: string[];
    searchTerm: string;
    complianceData: IComplianceResponse;
    complianceScreen: boolean;
};
const initialState: intialStateType = {
    customers: [],
    products: [],
    searchTerm: '',
    complianceScreen: false,
    complianceData: {
        id: '',
        created_at: '',
        updated_at: '',
        company_id: '',
        registered_business_name: '',
        business_about: '',
        business_address: '',
        business_city: '',
        business_state: '',
        business_country: '',
        business_email: '',
        owner_first_name: '',
        owner_last_name: '',
        owner_phone: '',
        owner_gender: '',
        owner_dob: '',
        owner_nationality: '',
        owner_address: '',
        owner_country: '',
        owner_state: '',
        owner_city: '',
        owner_id_type: '',
        owner_id_number: '',
        owner_id_image: '',
        business_rc_number: '',
        business_tin: '',
        business_utility_bill_type: '',
        business_utility_bill_image: '',
        business_cac: '',
        status: '',
        submission_date: '',
        declination_reason: {
            declination_types: [],
            reason: '',
        },
        reason: '',
    },
};

export const persistConfig = {
    storage: storageSession,
    key: 'root',
};

const globalSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {
        removeCustomer: (state, action) => {
            const item = action.payload;
            state.customers = state.customers.filter((company_id) => company_id !== item);
        },
        addToList: (state, { payload }) => {
            const isExist = state.customers.find((item) => item === payload);
            !isExist ? (state.customers = [...state.customers, payload]) : state.customers;
        },
        addAllToList: (state, { payload }) => {
            state.customers = _.union(state.customers, payload);
        },
        clearList: (state) => {
            state.customers = [];
        },
        setSearchTerm: (state, { payload }) => {
            state.searchTerm = payload;
        },
        setComplianceData: (state, { payload }) => {
            state.complianceData = payload;
        },
        setComplianceScreen: (state, { payload }) => {
            state.complianceScreen = payload;
        },
        removeProduct: (state, action) => {
            const item = action.payload;
            state.products = state.products.filter((id) => id !== item);
        },
        addProduct: (state, { payload }) => {
            const isExist = state.products.find((item) => item === payload);
            !isExist ? (state.products = [...state.products, payload]) : state.products;
        },
        clearProductList: (state) => {
            state.products = [];
        },
    },
});

export const {
    addProduct,
    clearProductList,
    removeProduct,
    setComplianceData,
    setComplianceScreen,
    addToList,
    addAllToList,
    removeCustomer,
    clearList,
    setSearchTerm,
} = globalSlice.actions;
const { reducer } = globalSlice;
export default reducer;
