import { Dispatch, FC, SetStateAction } from 'react';

import { categoriesType, uomType } from '../../pages/product/Product';
import Form1 from './Form1/Form1';
import Form2 from './Form2/Form2';

interface AddProductFormProps {
    showAddProduct?: boolean;
    setAddProduct?: () => void;
    fetchProducts?: () => Promise<void>;
    uom?: uomType[];
    categories?: categoriesType[];
    isEdit?: boolean;
    showEditProduct?: boolean;
    setEditProduct?: () => void;
    toggleEditProductModal?: () => void;
    productForm: string;
    setProductForm: Dispatch<SetStateAction<string>>;
    setModal: Dispatch<SetStateAction<boolean>>;
}

const AddProductForm: FC<AddProductFormProps> = (props) => {
    const {
        uom,
        categories,
        showAddProduct,
        setModal,
        productForm,
        setProductForm,
        setAddProduct,
        isEdit,
        showEditProduct,
        setEditProduct,
        fetchProducts,
    } = props;
    if (!showAddProduct && !showEditProduct) return <></>;

    if (productForm === 'FORM1') {
        return (
            <Form1
                isEdit={isEdit}
                setModal={setModal}
                uom={uom}
                setEditProduct={setEditProduct}
                categories={categories}
                setProductForm={setProductForm}
                fetchProducts={fetchProducts}
                setAddProduct={setAddProduct}
            />
        );
    }

    return (
        <Form2
            setModal={setModal}
            isEdit={isEdit}
            setEditProduct={setEditProduct}
            setProductForm={setProductForm}
            fetchProducts={fetchProducts}
            setAddProduct={setAddProduct}
        />
    );
};

export default AddProductForm;
