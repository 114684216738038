import React from 'react';
import DatePicker from 'react-datepicker';

const DatePickerField = ({ name, value, onChange }: any) => {
    return (
        <DatePicker
            selected={(value && new Date(value)) || null}
            onChange={(val: any) => {
                onChange(name, val);
            }}
            dateFormat="dd/MM/yyyy"
            placeholderText="Click to select a date"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            className="border w-full text-[#131221] h-[40px] border-jumbleng-gray opacity-50  px-2  focus:outline-none "
        />
    );
};

export default DatePickerField;
