import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EmptyContent from '../../components/EmptyContent/EmptyContent';
import Pagination from '../../components/Pagination/Pagination';
import PaymentWrapper from '../../HOC/PaymentWrapper';
import { initialPaymentsState } from '../../redux/features/dtos';
import { listCustomerPayments } from '../../redux/features/payment/PaymentActions';
import {
    useGetAllPaymentCountQuery,
    useGetConfirmedPaymentCountQuery,
    useGetCustomerPaymentsQuery,
    useGetPendingPaymentCountQuery,
    useGetUnconfirmedPaymentCountQuery,
} from '../../redux/services';
import { RootState } from '../../redux/store';
import { toLocaleFixed } from '../../utils/utils';

export type PaymentQuery = {
    page: number;
    limit: number;
    status: string;
    search_query: string;
};
const Customerpayment = () => {
    const { currentStatus } = useSelector((state: RootState) => state.listCustomerPaymentsReducer);
    const { data: total, isLoading: loadingtotal } = useGetAllPaymentCountQuery('customers');
    const { data: pending, isLoading: loadingpending } = useGetPendingPaymentCountQuery('customers');
    const { data: confirmed, isLoading: loadingconfirmed } = useGetConfirmedPaymentCountQuery('customers');
    const { data: unconfirmed, isLoading: loadingunconfirmed } = useGetUnconfirmedPaymentCountQuery('customers');
    const initialInvoiceQuery: PaymentQuery = {
        page: 1,
        limit: 10,
        status: currentStatus,
        search_query: '',
    };
    const [query, setQuery] = useState<PaymentQuery>(initialInvoiceQuery);
    const { data = initialPaymentsState, isFetching:isLoading } = useGetCustomerPaymentsQuery(query);
    const dispatch = useDispatch();
    const [customerActiveTab, setCustomerActiveTab] = useState<'' | 'pending' | 'confirmed' | 'not_confirmed'>(currentStatus);

    const toggleStatus = (status: '' | 'pending' | 'confirmed' | 'not_confirmed') => {
        setQuery({ ...query, status: status, page: 1 });
        dispatch(listCustomerPayments(status));
        setCustomerActiveTab(status);
    };

    return (
        <div>
            <div className="mb-[1.5rem] flex justify-between items-center">
                <div className="flex text-[0.78125rem] text-jumbleng-gray gap-4">
                    <div
                        className={`${
                            customerActiveTab === '' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                        } flex gap-2  cursor-pointer border rounded-2xl p-1.5`}
                        onClick={() => toggleStatus('')}
                    >
                        All{' '}
                        <p
                            className={`px-2 ${
                                customerActiveTab === '' ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'
                            } border rounded-[33px]`}
                        >
                            {total?.count}
                        </p>{' '}
                    </div>
                    <div
                        className={`${
                            customerActiveTab === 'pending' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                        } flex gap-2 cursor-pointer  border  rounded-2xl p-1.5`}
                        onClick={() => toggleStatus('pending')}
                    >
                        Pending
                        <p
                            className={`px-2 ${
                                customerActiveTab === 'pending' ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'
                            }  border rounded-[33px]`}
                        >
                            {pending?.count}
                        </p>
                    </div>
                    <div
                        className={`${
                            customerActiveTab === 'confirmed' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                        }  flex gap-2 cursor-pointer  border  rounded-2xl p-1.5`}
                        onClick={() => toggleStatus('confirmed')}
                    >
                        Confirmed{' '}
                        <p
                            className={`px-2 ${
                                customerActiveTab === 'confirmed' ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'
                            } border rounded-[33px]`}
                        >
                            {confirmed?.count}
                        </p>
                    </div>
                    <div
                        className={`${
                            customerActiveTab === 'not_confirmed' ? 'font-bold bg-[#DFDEED] border-[#5E5BA7]' : 'border-[#7C7C7D]'
                        }  flex gap-2 cursor-pointer  border rounded-2xl p-1.5`}
                        onClick={() => toggleStatus('not_confirmed')}
                    >
                        Not Confirmed{' '}
                        <p
                            className={`px-2 ${
                                customerActiveTab === 'not_confirmed' ? 'font-bold text-white bg-[#5E5BA7]' : 'bg-[#DFDEED]'
                            }  border rounded-[33px]`}
                        >
                            {unconfirmed?.count}
                        </p>
                    </div>
                </div>
                <div className="hidden md:flex items-center relative gap-4">
                    {data?.total_order_value !== 0 && (
                        <div className="hidden md:flex items-center relative ">
                            <div className="h-[2.5rem] relative border-2 bg-jumbleng-gray text-white px-2 rounded-md my-auto font-bold text-[24px]">
                                &#8358;{toLocaleFixed(data?.total_order_value, 2)}
                            </div>
                        </div>
                    )}
                    <div className="w-[7.7rem] h-[2.3rem] relative mr-[45px]">
                        <i className="fa fa-search icon absolute top-1/2 -translate-y-1/2 px-[0.79rem]" />
                        <input
                            className="w-full bg-[#F8F8FB] border-b-2 focus:outline-none pl-[2.08rem] h-full placeholder:text-[0.78165rem] text-[0.78125rem] text-jumbleng-gray placeholder:text-jumbleng-gray"
                            placeholder="Search order nums,vendors"
                        />
                    </div>
                </div>
            </div>

            <div className=" p-5 mt-5">
                <div className={`grid grid-cols-8 justify-items-center pt-4 bg-white p-5 font-bold`}>
                    <div className="jumbleng-gray-main text-base grid justify-center">Order date</div>
                    <div className="jumbleng-gray-main text-base grid justify-center text-center">Ordering Company</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Order number</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Invoice number</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Order Value</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Receipt Issued</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Payment Status</div>
                    <div className="jumbleng-gray-main text-base grid justify-center">Action</div>
                </div>
                <PaymentWrapper data={data?.data} loading={isLoading || loadingtotal || loadingpending || loadingconfirmed || loadingunconfirmed}>
                    {data?.count === 0 ? (
                        <EmptyContent content={currentStatus === 'not_confirmed' ? 'unconfirmed' : currentStatus} payment={true} />
                    ) : (
                        <Pagination count={data?.count} query={query} setQuery={setQuery} />
                    )}
                </PaymentWrapper>{' '}
            </div>
        </div>
    );
};

export default Customerpayment;
