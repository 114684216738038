import 'reactjs-popup/dist/index.css';
import './Dashboard.css';

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { FC, useState } from 'react';
import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';

import Filter from '../../assets/images/svg/funnel.svg';
import PlusIcon from '../../assets/images/svg/plus.svg';
import AddProductForm from '../../components/AddProductForm/AddProductForm';
import DashboardOrders from '../../components/dashboardOrders/dashboardOrders';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import Modal from '../../components/UI/Backdrop/Backdrop';
import Loading from '../../components/UI/Spinner/Loading';
import PermissionsGate from '../../HOC/PermissionGate';
import Wrapper from '../../HOC/Wrapper';
import { actions } from '../../redux/features/product/productRedux';
import { getCategories, getNextSKU, getuom } from '../../redux/features/product/productService';
import { useGetOrderByStatusQuery, useGetRevenueByStatusQuery, useGetSalesByStatusQuery } from '../../redux/services';
import { getDashboardFilters } from '../../redux/services/OrderService';
import { RootState, ThunkAppDispatch, useAppThunkDispatch } from '../../redux/store';
import { initialAddProductValues } from '../../schema';
import { SCOPES } from '../../utils/permission';
import { toLocaleFixed } from '../../utils/utils';
import {
    categoriesType,
    DProps,
    initialOrderParams,
    initialRevenueParams,
    initialSalesParams,
    orderCountType,
    revenueCountType,
    salesCountType,
    uomTypes,
} from './interface';

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard: FC<DProps> = ({ setCreateProductField }) => {
    const dispatch: ThunkAppDispatch = useAppThunkDispatch();
    const [addProduct, setAddProduct] = useState(false);
    const [modal, setModal] = useState<boolean>(false);
    const [uom, setUom] = useState<Array<uomTypes>>([]);
    const [categories, setCategories] = useState<Array<categoriesType>>([]);
    const [productForm, setProductForm] = useState('FORM1');
    const [filter, setFilter] = useState<any>([]);
    const [orderCount, setOrderCount] = useState<orderCountType>({ actual: 0, credit: 0, fulfilled: 0, sale_total: 0 });
    const [revenueCount, setRevenueCount] = useState<revenueCountType>({ actual: 0, credit: 0, total: 0 });
    const [salesCount, setSalesCount] = useState<salesCountType>({ completed: 0, credit: 0, uncompleted: 0 });
    const [orderPeriod, setOrderPeriod] = useState('This week');
    const [revenuePeriod, setRevenuePeriod] = useState('This week');
    const [salesPeriod, setSalesPeriod] = useState('This week');
    const [loader, setLoader] = useState(false);
    const loadingmodal = useSelector((state: RootState) => state.modalReducer);
    const [orderParams, setOrderParams] = useState(initialOrderParams);
    const [revenueParams, setRevenueParams] = useState(initialRevenueParams);
    const [salesParams, setSalesParams] = useState(initialSalesParams);
    const { data: actualOrder, isLoading: fetchActualOrder, isSuccess: fetchActualOrderSuccess } = useGetOrderByStatusQuery(orderParams);
    const {
        data: creditOrder,
        isLoading: fetchCreditOrder,
        isSuccess: fetchCreditOrderSuccess,
    } = useGetOrderByStatusQuery({ ...orderParams, status: 'credit' });
    const {
        data: fulfilledOrder,
        isLoading: fetchFullfiledOrder,
        isSuccess: fetchFullfiledOrderSuccess,
    } = useGetOrderByStatusQuery({ ...orderParams, status: 'fulfilled' });
    const {
        data: totalOrder,
        isLoading: fetchTotalOrder,
        isSuccess: fetchTotalOrderSuccess,
    } = useGetOrderByStatusQuery({ ...orderParams, status: 'sale/total' });
    const { data: actualRevenue, isLoading: fetchActualRevenue, isSuccess: fetchActualRevenueSuccess } = useGetRevenueByStatusQuery(revenueParams);
    const {
        data: creditRevenue,
        isLoading: fetchCreditRevenue,
        isSuccess: fetchCreditRevenueSuccess,
    } = useGetRevenueByStatusQuery({ ...revenueParams, status: 'credit' });
    const {
        data: totalRevenue,
        isLoading: fetchTotalRevenue,
        isSuccess: fetchTotalRevenueSuccess,
    } = useGetRevenueByStatusQuery({ ...revenueParams, status: 'total' });
    const { data: creditSales, isLoading: fetchCreditSales, isSuccess: fetchCreditSalesSuccess } = useGetSalesByStatusQuery(salesParams);
    const {
        data: completedSales,
        isLoading: fetchCompletedSales,
        isSuccess: fetchCompletedSalesSuccess,
    } = useGetSalesByStatusQuery({ ...salesParams, status: 'completed' });
    const {
        data: unCompletedSales,
        isLoading: fetchUncompletedSales,
        isSuccess: fetchUncompletedSalesSuccess,
    } = useGetSalesByStatusQuery({ ...salesParams, status: 'uncompleted' });

    const toggleAddproductModal = () => {
        setAddProduct((prevState) => !prevState);
    };
    const dashboardFilters = async () => {
        const response = await getDashboardFilters();
        setFilter(Object.entries(response.data));
    };

    const FilterOrder = async (items: string, close: () => void) => {
        const period = items[0];
        setOrderParams({ ...orderParams, filter: period });
        setOrderCount({
            ...orderCount,
            actual: actualOrder,
            credit: creditOrder,
            fulfilled: fulfilledOrder,
            sale_total: totalOrder,
        });
        setOrderPeriod(items[1]);
        close();
    };
    const FilterRevenue = async (items: string, close: () => void) => {
        const period = items[0];
        setRevenueParams({ ...revenueParams, filter: period });
        setRevenueCount({ ...revenueCount, actual: actualRevenue, credit: creditRevenue, total: totalRevenue });
        setRevenuePeriod(items[1]);
        close();
    };
    const FilterSales = async (items: string, close: () => void) => {
        const period = items[0];
        setSalesParams({ ...salesParams, filter: period });
        setSalesCount({ ...salesCount, completed: completedSales, credit: creditSales, uncompleted: unCompletedSales });
        setSalesPeriod(items[1]);
        close();
    };

    useEffect(() => {
        const getUOM = async () => {
            try {
                const response = await Promise.all([getCategories(), getuom(), getNextSKU()]);
                await dashboardFilters();
                setUom(response[1].data);
                setCategories(response[0].data);
                const data = { sku: response[2].data };
                dispatch({ type: 'SET_SKU', payload: data });
                setCreateProductField({ initialAddProductValues, ...data });
            } catch (error) {}
        };
        getUOM();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        modal ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset');
        return () => {
            document.body.style.overflow = 'unset';
            document.body.style.overflow = 'auto';
        };
    }, [modal]);

    useEffect(() => {
        if (
            fetchActualOrder ||
            fetchCreditOrder ||
            fetchFullfiledOrder ||
            fetchTotalOrder ||
            fetchActualRevenue ||
            fetchCreditRevenue ||
            fetchTotalRevenue ||
            fetchCreditSales ||
            fetchCompletedSales ||
            fetchUncompletedSales
        ) {
            setLoader(true);
        }
        if (
            fetchActualOrderSuccess ||
            fetchCreditOrderSuccess ||
            fetchFullfiledOrderSuccess ||
            fetchActualRevenueSuccess ||
            fetchCreditRevenueSuccess ||
            fetchTotalRevenueSuccess ||
            fetchCreditSalesSuccess ||
            fetchCompletedSalesSuccess ||
            fetchUncompletedSalesSuccess ||
            fetchTotalOrderSuccess
        ) {
            setOrderCount({
                ...orderCount,
                actual: actualOrder,
                credit: creditOrder,
                fulfilled: fulfilledOrder,
                sale_total: totalOrder,
            });
            setRevenueCount({ ...revenueCount, actual: actualRevenue, credit: creditRevenue, total: totalRevenue });
            setSalesCount({ ...salesCount, completed: completedSales, credit: creditSales, uncompleted: unCompletedSales });
            setLoader(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        fetchActualOrderSuccess,
        fetchCreditOrderSuccess,
        fetchFullfiledOrderSuccess,
        fetchActualRevenueSuccess,
        fetchCreditRevenueSuccess,
        fetchTotalRevenueSuccess,
        fetchCreditSalesSuccess,
        fetchCompletedSalesSuccess,
        fetchUncompletedSalesSuccess,
        fetchTotalOrderSuccess,
        fetchActualOrder,
        fetchCreditOrder,
        fetchFullfiledOrder,
        fetchTotalOrder,
        fetchActualRevenue,
        fetchCreditRevenue,
        fetchTotalRevenue,
        fetchCreditSales,
        fetchCompletedSales,
        fetchUncompletedSales,
        actualOrder,
        creditOrder,
        fulfilledOrder,
        totalOrder,
        actualRevenue,
        creditRevenue,
        totalRevenue,
        creditSales,
        completedSales,
        unCompletedSales,
    ]);

    const handleToggleModal = () => {
        toggleAddproductModal();
        setModal(true);
    };

    if (loader) {
        return (
            <div className="min-h-screen flex justify-center flex-col">
                <Loading />
            </div>
        );
    }
    return (
        <Wrapper>
            {loadingmodal && <LoadingModal />}
            {modal && (
                <Modal
                    setProductForm={setProductForm}
                    showModal={addProduct}
                    setCreateProductField={setCreateProductField}
                    setModal={setModal}
                    setAddProduct={setAddProduct}
                />
            )}
            <AddProductForm
                setModal={setModal}
                setProductForm={setProductForm}
                productForm={productForm}
                uom={uom}
                categories={categories}
                showAddProduct={addProduct}
                setAddProduct={toggleAddproductModal}
            />
            <div className="w-[full] flex justify-between items-center h-[2.34rem] my-6 mb-[0.7rem]">
                <h3 className="font-semibold text-[1.25rem] text-jumbleng-gray">Dashboard</h3>
                <div className="flex mr-2">
                    <PermissionsGate scopes={[SCOPES.can_create_products]}>
                        <button
                            onClick={handleToggleModal}
                            className="flex h-full rounded-md ml-4 items-center bg-jumbleng-primary px-[1.18rem] py-[0.42125rem]"
                        >
                            <img src={PlusIcon} className="mr-1" alt="plus" />{' '}
                            <span className="font-semibold text-[0.78125rem] text-white">Add product</span>
                        </button>
                    </PermissionsGate>
                </div>
            </div>
            {/* ORDERS */}
            <DashboardOrders orderCount={orderCount} filter={filter} updateCountByStatus={FilterOrder} orderPeriod={orderPeriod} />
            {/* SALES */}
            <div className="sales__section w-full">
                <div className="w-full mr-[1.3rem] rounded-md p-[0.78125rem] bg-white sales__container">
                    <div className="w-full flex justify-between ">
                        <p className="font-semibold text-[1rem] text-jumbleng-gray">Sales</p>
                        <div className="text-[0.78125rem] text-jumbleng-gray flex items-center gap-2 mr-4 mb-[1.8rem]">
                            <img src={Filter} />
                            <Popup
                                trigger={
                                    <div className="flex items-center cursor-pointer">
                                        Filter: {salesPeriod}
                                        <i className="ml-2 fa-solid fa-chevron-down"></i>{' '}
                                    </div>
                                }
                                closeOnDocumentClick
                                arrow={false}
                                contentStyle={{
                                    padding: '10px',
                                    borderRadius: '12px',
                                    width: '120px',
                                    fontSize: '0.8rem',
                                }}
                            >
                                {(close: () => void) => (
                                    <div className=" flex items-center justify-center flex-col gap-2">
                                        {filter.map((items: any) => (
                                            <div
                                                className="cursor-pointer hover:text-jumbleng-primary"
                                                key={items[0]}
                                                onClick={() => FilterSales(items, close)}
                                            >
                                                {items[1]}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Popup>
                        </div>
                    </div>
                    <div className="flex justify-around text-center mb-[2rem]">
                        <div>
                            <p className="font-normal text-[0.78125rem]">Completed sales</p>
                            <p className="font-semibold text-[1.85rem]">{salesCount.completed}</p>
                        </div>
                        <div>
                            <p className="font-normal text-[0.78125rem]">Uncompleted sales</p>
                            <p className="font-semibold text-[1.85rem]">{salesCount.uncompleted}</p>
                        </div>
                        <div>
                            <p className="font-normal text-[0.78125rem]">Credit sales</p>
                            <p className="font-semibold text-[1.85rem]">{salesCount.credit}</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* INVENTORY */}
            <div className="sales__section w-full">
                <div className="w-full mr-[1.3rem] rounded-md p-[0.78125rem] bg-white sales__container">
                    <div className="w-full flex justify-between ">
                        <p className="font-semibold text-[1rem] text-jumbleng-gray">Revenue</p>
                        <div className="text-[0.78125rem] text-jumbleng-gray flex items-center gap-2 mr-4 mb-[1.8rem]">
                            <img src={Filter} />
                            <Popup
                                trigger={
                                    <div className="flex items-center cursor-pointer">
                                        Filter: {revenuePeriod}
                                        <i className="ml-2 fa-solid fa-chevron-down"></i>{' '}
                                    </div>
                                }
                                closeOnDocumentClick
                                arrow={false}
                                contentStyle={{
                                    padding: '10px',
                                    borderRadius: '12px',
                                    width: '120px',
                                    fontSize: '0.8rem',
                                }}
                            >
                                {(close: () => void) => (
                                    <div className=" flex items-center justify-center flex-col gap-2">
                                        {filter.map((items: any) => (
                                            <div
                                                className="cursor-pointer hover:text-jumbleng-primary"
                                                key={items[0]}
                                                onClick={() => FilterRevenue(items, close)}
                                            >
                                                {items[1]}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Popup>
                        </div>{' '}
                    </div>
                    <div className="flex justify-around text-center mb-[2rem]">
                        <div>
                            <p className="font-normal text-[0.78125rem]">Actual revenue</p>
                            <p className="font-semibold text-[1.85rem]">₦ {toLocaleFixed(revenueCount.actual, 2)}</p>
                        </div>
                        <div>
                            <p className="font-normal text-[0.78125rem]">Credit revenue</p>
                            <p className="font-semibold text-[1.85rem]">₦ {toLocaleFixed(revenueCount.credit, 2)}</p>
                        </div>
                        <div>
                            <p className="font-normal text-[0.78125rem]">Total revenue</p>
                            <p className="font-semibold text-[1.85rem]">₦ {toLocaleFixed(revenueCount.total, 2)}</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="w-full rounded-md bg-white mt-[0.78125rem] p-[0.78125rem]">
                <div className="w-full flex justify-between mb-[1.8rem] ">
                    <p className="font-semibold text-[1rem] text-jumbleng-gray">Inventory</p>
                <div className="text-[0.78125rem] text-jumbleng-gray">Filter by week</div> 
                </div>
                <div className="w-full flex inventory__section" style={{ gap: '69px' }}>
                    <div className="w-[11.5rem] h-[11.5rem] invetory__chart">
                        <Doughnut data={data} options={options} />
                    </div>
                    <div className="w-full  md:ml-[2.8rem] flex items-center flex-col inventory__content md:block">
                        <div className="px-[1rem] mb-[3.9rem] inline-block rounded-[48rem] py-[0.38125rem] bg-[#EAE9F3] md:mt-6 text-jumbleng-gray text-[0.78125rem]">0 Customer with low stocks. View all</div>

                        <div className="flex w-full justify-between sector__grid">
                            <div className="sector__grid__item text-center w-[138px]">
                                <div className="flex items-center">
                                    <div className="w-[0.56rem] h-[0.56rem] mr-2 rounded-[50%] bg-jumbleng-primary" />
                                    <p className="text-[0.78125rem] text-jumbleng-gray font-normal">Stocks available</p>
                                </div>
                                <p className="font-semibold text-[1.85rem]">{availableStock}</p>
                            </div>
                            <div className="sector__grid__item text-center w-[138px]">
                                <div className="flex items-center">
                                    <div className="w-[0.56rem] h-[0.56rem] mr-2 rounded-[50%] bg-[#FCCC00]" />
                                    <p className="text-[0.78125rem] text-jumbleng-gray font-normal">Stocks running low</p>
                                </div>
                                <p className="font-semibold text-[1.85rem]">--</p>
                            </div>
                            <div className="sector__grid__item text-center w-[138px]">
                                <div className="flex items-center">
                                    <div className="w-[0.56rem] h-[0.56rem] mr-2 rounded-[50%] bg-[#44E6EE]" />
                                    <p className="text-[0.78125rem] text-jumbleng-gray font-normal">Stocks unavailable</p>
                                </div>
                                <p className="font-semibold text-[1.85rem]">--</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </Wrapper>
    );
};

export default connect((state: RootState) => {
    const { my_catalogue } = state.ProductReducer;
    return { my_catalogue };
}, actions)(Dashboard);
