import { Dispatch, SetStateAction } from 'react';

import ArrowLeft from '../../assets/images/svg/arrow-left.svg';
import ArrowRight from '../../assets/images/svg/arrow-right.svg';
import { getPaginationData } from '../../utils/utils';

type Props = {
    query: any;
    setQuery: Dispatch<SetStateAction<any>>;
    data: any[];
    count: number;
};

const Pagination: any = ({ query, setQuery, data, count }: Props) => {
    const { total_pages } = getPaginationData({ data: data, total_records: count, records_per_page: query.limit });

    return count < 10 ? null : (
        <div className="grid justify-center mt-5 text-[12px] md:text-[16px]">
            <div className="flex gap-2 md:gap-4 items-center">
                <span className="md:block hidden">Showing Result page </span>
                {query.page > 1 && (
                    <div
                        style={{ color: '#5E5BA7' }}
                        className="flex items-center cursor-pointer "
                        onClick={() => setQuery({ ...query, page: query.page - 1 })}
                    >
                        <img src={ArrowLeft} alt="arrowright" />
                        <button>Prev</button>
                    </div>
                )}
                <p
                    className="bg-white px-2 md:px-4 "
                    style={{
                        border: '1px solid #9796A9',
                        borderRadius: '4px',
                    }}
                >
                    {query.page}
                </p>
                <p> of</p>
                {total_pages} pages
                {query.page < total_pages && (
                    <div
                        style={{ color: '#5E5BA7' }}
                        className="flex items-center cursor-pointer "
                        onClick={() => setQuery({ ...query, page: query.page + 1 })}
                    >
                        <button>Next</button>
                        <img src={ArrowRight} alt="arrowright" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Pagination;
