import styled, { css } from 'styled-components';

export const EmptyContainer = styled.div`
    width: 100%;
    display: grid;
    place-items: center;
    min-height: 400px;
`;
export const EmptyText = styled.p`
    text-align: center;
`;

export const PageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 37px 24px;
`;

export const PageTitle = styled.div`
    color: #131221;
    font-size: 18px;
    font-weight: 600;
`;

export const PageCtaContainer = styled.div`
    position: relative;
`;

export const PageCta = styled.button`
    background-color: #5e5ba7;
    border-radius: 12px;
    padding: 5px 16px;
    color: white;
    cursor: pointer;
    & > p {
        display: flex;
        align-items: center;
        gap: 13px;
        span {
            font-size: 24px;
        }
    }
    &:disabled {
        background-color: #bab9c6;
        border-radius: 12px;
        padding: 5px 16px;
        color: #fff;
        pointer-events: none;
        cursor: not-allowed;

        & > p {
            display: flex;
            align-items: center;
            gap: 13px;
            span {
                font-size: 24px;
            }
        }
    }
`;

export const TemplateContainer = styled.div`
    position: absolute;
    margin-top: 10px;
    right: 5px;
    background-color: white;
    padding-bottom: 20px;
    border-radius: 8px;
    width: 13.75rem;
    box-shadow: 0px -2px 40px rgba(19, 18, 33, 0.1);
    -webkit-box-shadow: 0px -2px 40px rgba(19, 18, 33, 0.1);
    -moz-box-shadow: 0px -2px 40px rgba(19, 18, 33, 0.1);
`;

export const TemplateList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.625em;
    font-size: 1rem;
    padding: 0.625em 1.875rem;
    list-style-type: none;
`;

export const TemplateHeader = styled.h3`
    font-size: 1rem;
    padding-top: 1.25em;
    padding-inline: 0.625em;
    color: #706f77;
    font-weight: 600;
`;

export const TemplateListItem = styled.p`
    color: #706f77;
    line-height: 1.5;
    font-size: 0.875em;

    &:hover {
        cursor: pointer;
        color: black;
    }
`;

export const StoreTableContainer = styled.table`
    width: 100%;
    background-color: #fff;
    padding: 90px;
`;

export const StoreTableHeader = styled.thead`
    padding: 12px;
    height: 40px;
    border: 2px solid #fff;
`;

export const StoreTableHeaderRow = styled.tr`
    font-size: 14px;
    border-bottom: 2px solid #231f2010;

    text-align: left;
    width: 100%;
`;
export const StoreTableHeaderCell = styled.th`
    font-weight: lighter;
    width: 20%;
    padding-block: 1.875em;

    &:nth-child(1) {
        padding-inline: 1.875em;
    }
`;

export const StoreTableBodyRow = styled.tr`
    height: 67px;
    font-size: 13px;

    cursor: pointer;
    font-weight: 600;
    text-transform: capitalize;
    text-align: left;

    &:hover {
        color: #5e5ba7;
    }
`;
export const StoreTableDataCell = styled.th`
    border-top: 1px solid #fff;
    &.link {
        color: #7976b6;
        text-decoration: underline;
        cursor: pointer;
    }
    &:nth-child(1) {
        padding-left: 30px;
    }
`;

export const StoreTableBody = styled.tbody`
    border-top: 1px solid #231f20;
    padding-top: 50px;
`;

export const StoreToggleImage = styled.img`
    transform: rotate(180deg);
    width: 25px;
`;
export const TabContainer = styled.div`
    display: flex;
    border-bottom: 1px solid #00000050;
`;
const activeBorderBottom = css`
    border-bottom: 3px solid #5e5ba7;
`;
export const Tab = styled.div<{ active?: boolean }>`
    padding: 16px 24px;
    cursor: pointer;
    ${({ active }) => active && activeBorderBottom}
`;
const Container = styled.div`
    & > * {
        color: #525171;
    }

    .store__expand {
        .nav__tab {
            display: flex;
            border-bottom: 1px solid #00000050;

            .linked {
                padding: 16px 24px;
                border-bottom: 3px solid #5e5ba7;
                cursor: pointer;
            }

            .unlinked {
                padding: 16px 24px;
                cursor: pointer;
            }
        }
    }
`;
export default Container;
