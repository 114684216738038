/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';

import NotFound from '../../../assets/images/png/no-image.jpg';
import More from '../../../assets/images/svg/More.svg';
import Filter from '../../../assets/images/svg/sliders.svg';
import Pagination from '../../../components/Pagination/Pagination';
import PopUpModal from '../../../components/PopModal/PopUpModal';
import Loading from '../../../components/UI/Spinner/Loading';
import { ICustomer } from '../../../redux/features/interfaces';
import {
    useAssignSalesRepMutation,
    useDisconnectCustomerMutation,
    useGetCatalogueQuery,
    useGetDiscountUnitsQuery,
    useGetVendorCustomersQuery,
    useReactivateCustomerMutation,
    useSuspendCustomerMutation,
    useUnassignSalesRepMutation,
} from '../../../redux/services';
import { toLocaleFixed, transformedDateFull } from '../../../utils/utils';
import EmptyCustomer from '../EmptyContent/EmptyCustomer';
import EmptySearch from '../EmptyContent/EmptySearch';
import CustomerAction from './CustomerActionModal';
import ApplyDiscount, { initialProductQuery, ProductQuery } from './Popups/ApplyDiscount';
import AssignSalesRep from './Popups/AssignSalesRep';
import ProductPermission from './Popups/CheckProductPermission';
import CustomersSalesRep from './Popups/CustomersSalesRep';

export type customerQueryType = { page: number; limit: number; search_query: string };
export const initialCustomerQuery: customerQueryType = {
    page: 1,
    limit: 10,
    search_query: '',
};

const Customer = () => {
    const [query, setQuery] = useState<customerQueryType>(initialCustomerQuery);
    const { data, isFetching, refetch: Refetch } = useGetVendorCustomersQuery(query);
    const [productQuery, setProdctQuery] = useState<ProductQuery>(initialProductQuery);
    const [suspendCustomer, { isLoading, isSuccess, isError, error }] = useSuspendCustomerMutation();
    const [assignSalesRep, { isLoading: assignLoading, isSuccess: assignSuccess, isError: isAssignError, error: assignError }] =
        useAssignSalesRepMutation();
    const [disconnectCustomer, { isLoading: loading, isSuccess: issuccess, isError: iserror, error: err }] = useDisconnectCustomerMutation();
    const [activateCustomer, { isLoading: loader, isSuccess: Success, isError: Iserror, error: Err }] = useReactivateCustomerMutation();
    const { data: unitData, isLoading: unitsloading } = useGetDiscountUnitsQuery();
    const [unassignSalesRep, { isLoading: unassignLoading, isSuccess: unassignSuccess, isError: isunassignError, error: unassignError }] =
        useUnassignSalesRepMutation();
    const { data: productlist } = useGetCatalogueQuery(productQuery);

    const handleChange = (e: any) => {
        setQuery({ ...query, search_query: e.target.value, page: 1 });
    };

    const handleProductSearch = (e: any) => {
        setProdctQuery({ ...productQuery, search_query: e.target.value, page: 1 });
    };
    const handleOnSubmit = async (values: any, id: string, close: () => void) => {
        const ids = new Set(values.fields.map((item: any) => item.id));
        await assignSalesRep({ id: id, sales_reps: Array.from(ids) });
        close();
    };

    const handleUnassignSalesRep = async (repId: string, id: string, close: () => void, refetch?: any) => {
        await unassignSalesRep({ id, repId });
        refetch();
        Refetch();
        close();
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Customer suspended successfully');
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
        }
    }, [error, isError, isLoading, isSuccess]);

    useEffect(() => {
        if (issuccess) {
            toast.success('Disconnected customer successfully');
        }
        if (iserror && err && 'status' in err) {
            toast.error(err?.data?.message);
        }
    }, [err, iserror, issuccess, loading]);

    useEffect(() => {
        if (Success) {
            toast.success('Customer reactivated successfully');
        }
        if (Iserror && Err && 'status' in Err) {
            toast.error(Err?.data?.message);
        }
    }, [Err, Iserror, Success, loader]);

    useEffect(() => {
        if (assignSuccess) {
            toast.success('Customers assigned sales Rep successfully');
        }
        if (isAssignError && assignError && 'status' in assignError) {
            toast.error(assignError?.data?.message);
        }
    }, [assignError, assignSuccess, isAssignError, assignLoading]);

    useEffect(() => {
        if (unassignSuccess) {
            toast.success('Customers unassigned sales Rep successfully');
        }
        if (isunassignError && unassignError && 'status' in unassignError) {
            toast.error(unassignError?.data?.message);
        }
    }, [unassignError, unassignSuccess, isunassignError, unassignLoading]);

    if (query.search_query == '' && (isFetching || unitsloading)) {
        return (
            <div className="min-h-[calc(100vh-20rem)] flex justify-center flex-col">
                <Loading />
            </div>
        );
    }

    return data?.data?.length === 0 && query.search_query === '' ? (
        <EmptyCustomer />
    ) : (
        <div>
            <div className="flex">
                <div className="text-[#5E5BA7] text-[16px] ">
                    You have <span className="font-bold">{data?.count}</span> Customers
                </div>

                <div className="flex items-center relative" style={{ marginLeft: 'auto' }}>
                    <div className="w-[7.7rem] h-[2.3rem] relative mr-[45px]">
                        <i className="fa fa-search icon absolute top-1/2 -translate-y-1/2 px-[0.79rem]" />
                        <input
                            className="w-full bg-[#F8F8FB] border-b-2 focus:outline-none pl-[2.08rem] h-full placeholder:text-[0.78165rem] text-[0.78125rem] text-jumbleng-gray placeholder:text-jumbleng-gray"
                            placeholder="Search customer"
                            onChange={handleChange}
                        />
                    </div>

                    <div className="flex hover:cursor-pointer text-[0.78125rem] mr-[45px] font-normal text-jumbleng-gray items-center">
                        <span className="mr-[9px]">Filter</span>
                        <img src={Filter} className="w-[1.17rem] h-[1.17rem]" alt="Filter" />
                    </div>
                </div>
            </div>

            <div className=" p-5 mt-5">
                <div className="grid grid-cols-9 pt-4 bg-white p-5   font-bold">
                    <div className="jumbleng-gray-main text-base col-span-2 flex items-center gap-2 ">
                        {' '}
                        <div className="grid content-center">
                            <input type="checkbox" />
                        </div>
                        <p> Name</p>
                    </div>
                    <div className="jumbleng-gray-main text-base grid justify-center col-span-1">Industry</div>
                    <div className="jumbleng-gray-main text-base grid justify-center col-span-2"> Value of items bought</div>
                    <div className="jumbleng-gray-main text-base grid justify-center col-span-2">Date of Last Purchase</div>
                    <div className="jumbleng-gray-main text-base grid justify-center col-span-1">State</div>
                    <div className="jumbleng-gray-main text-base grid justify-center col-span-1">Action</div>
                </div>

                {data?.data?.length === 0 && query.search_query !== '' ? (
                    <EmptySearch content={'groups'} />
                ) : (
                    data?.data.map((customer: ICustomer, i: number) => (
                        <div className={`grid grid-cols-9 pt-4 p-5 gap-1  ${i % 2 == 0 ? 'bg-[#fff]' : 'bg-[#F9F9FA]'} items-center`} key={i}>
                            <div className="text-sm col-span-2 flex gap-2 ">
                                <div className="grid content-center">
                                    <input type="checkbox" />
                                </div>
                                <div className="flex gap-2 items-center rounded-full">
                                    <img src={NotFound} alt="avatar" className="h-[3.2rem] w-[3.2rem] object-contain" />
                                </div>{' '}
                                <div className="w-[13rem] flex flex-col break-words">
                                    <div className="font-bold">{customer.name}</div>
                                    <div> {customer.email}</div>
                                </div>
                            </div>

                            <div className="text-sm grid justify-center col-span-1">{customer.industry}</div>

                            <div className="text-sm grid justify-center col-span-2">₦{toLocaleFixed(customer.value_of_items_bought, 2)}</div>
                            <div className="text-sm grid justify-center col-span-2">{transformedDateFull(customer.last_purchase_date)}</div>

                            <div className=" grid justify-center col-span-1">
                                {customer.state === 'Active' ? (
                                    <div className=" flex items-center capitalize gap-2 text-[#084F21] text-sm bg-[#DEEAE2] py-1 px-5 rounded-2xl h-[30px]">
                                        <div className="h-3 w-3 rounded-full border border-[#327a4b] object-contain">
                                            <div className="h-2 w-2 border rounded-full bg-[#327a4b] my-[1px] mx-auto" />
                                        </div>
                                        {customer.state}
                                    </div>
                                ) : (
                                    <div className=" flex items-center gap-2 text-[#840B0B] text-sm bg-[#F5E7E7] py-1 px-5 rounded-2xl h-[30px]">
                                        Suspended
                                    </div>
                                )}
                            </div>

                            <Popup
                                trigger={
                                    <div className="grid justify-center col-span-1">
                                        <img src={More} alt="more" />
                                    </div>
                                }
                                on="hover"
                                closeOnDocumentClick
                                nested
                                arrow={false}
                                contentStyle={{
                                    padding: '10px',
                                    borderRadius: '12px',
                                    width: '167px',
                                }}
                            >
                                {(close: () => void) => (
                                    <div className=" grid justify-center text-jumbleng-primary ">
                                        {/* <div className=" grid justify-center pt-2 hover:text-jumbleng-gray cursor-pointer overflow-hidden">
                                            <p onClick={() => navigate(`/vendorlist/customer/${customer.company_id}`)}>View details</p>
                                        </div> */}

                                        {customer.state === 'Active' ? (
                                            <PopUpModal
                                                content={
                                                    <div className="grid justify-center mt-3 hover:text-jumbleng-gray cursor-pointer">
                                                        Suspend customer
                                                    </div>
                                                }
                                                className=""
                                                popupcontent={false}
                                                onClose={close}
                                            >
                                                {(close: () => void) => (
                                                    <CustomerAction
                                                        action={suspendCustomer}
                                                        id={customer.company_id}
                                                        close={close}
                                                        actionName={'suspend'}
                                                        actionTitle={''}
                                                        name={customer.name}
                                                        loader={isLoading}
                                                    />
                                                )}
                                            </PopUpModal>
                                        ) : (
                                            <PopUpModal
                                                content={
                                                    <div className="grid justify-center mt-3 hover:text-jumbleng-gray cursor-pointer">
                                                        Reactivate customer
                                                    </div>
                                                }
                                                className=""
                                                popupcontent={false}
                                                onClose={close}
                                            >
                                                {(close: () => void) => (
                                                    <CustomerAction
                                                        action={activateCustomer}
                                                        id={customer.company_id}
                                                        close={close}
                                                        actionName={'reactivate'}
                                                        actionTitle={''}
                                                        name={customer.name}
                                                        loader={loader}
                                                    />
                                                )}
                                            </PopUpModal>
                                        )}
                                        <PopUpModal
                                            content={
                                                <div className="grid justify-center mt-3 hover:text-jumbleng-gray cursor-pointer">
                                                    Disconnect customer
                                                </div>
                                            }
                                            className=""
                                            popupcontent={false}
                                            onClose={close}
                                        >
                                            {(close: () => void) => (
                                                <CustomerAction
                                                    action={disconnectCustomer}
                                                    id={customer.company_id}
                                                    close={close}
                                                    actionName={'disconnect'}
                                                    actionTitle={''}
                                                    name={customer.name}
                                                    loader={loading}
                                                />
                                            )}
                                        </PopUpModal>

                                        {/* <Popup
                                            trigger={
                                                <div className=" grid justify-center pt-3 hover:text-jumbleng-gray cursor-pointer overflow-hidden">
                                                    <p>Manage products</p>
                                                </div>
                                            }
                                            position="right center"
                                            modal
                                            contentStyle={{ width: 'fit-content' }}
                                            closeOnDocumentClick={false}
                                        >
                                            {(close: () => void) => (
                                                <ProductPermission
                                                    close={close}
                                                    data={productlist}
                                                    handleChange={handleProductSearch}
                                                    query={productQuery}
                                                    setQuery={setProdctQuery}
                                                />
                                            )}
                                        </Popup> */}
                                        {/* <Popup
                                            trigger={
                                                <div className="grid justify-center mt-3 hover:text-jumbleng-gray cursor-pointer">Apply discount</div>
                                            }
                                            closeOnDocumentClick={false}
                                            arrow={false}
                                            modal
                                            contentStyle={{ width: '700px', padding: '0px', border: 'none' }}
                                        >
                                            <ApplyDiscount close={close} unitData={unitData?.data} company_id={customer.company_id} />
                                        </Popup> */}
                                        {!customer.has_sales_rep ? (
                                            <Popup
                                                trigger={
                                                    <div className="grid justify-center mt-3 mb-2 hover:text-jumbleng-gray cursor-pointer">
                                                        Assign sales rep{' '}
                                                    </div>
                                                }
                                                position="right center"
                                                contentStyle={{ width: 'fit-content', overflow: 'auto' }}
                                                modal
                                                nested
                                            >
                                                {(close: () => void) => (
                                                    <AssignSalesRep
                                                        close={close}
                                                        handleSubmit={handleOnSubmit}
                                                        loading={assignLoading}
                                                        customer={customer}
                                                    />
                                                )}
                                            </Popup>
                                        ) : (
                                            <>
                                                <Popup
                                                    trigger={
                                                        <div className="grid justify-center mt-3 hover:text-jumbleng-gray cursor-pointer">
                                                            Unassign sales rep{' '}
                                                        </div>
                                                    }
                                                    position="right center"
                                                    contentStyle={{ width: 'fit-content', overflow: 'auto' }}
                                                    modal
                                                    nested
                                                >
                                                    <CustomersSalesRep
                                                        unassignSalesRep={handleUnassignSalesRep}
                                                        customer={customer}
                                                        close={close}
                                                        loader={unassignLoading}
                                                    />
                                                </Popup>
                                                <Popup
                                                    trigger={
                                                        <div className="grid justify-center mt-3 mb-2 hover:text-jumbleng-gray cursor-pointer">
                                                            Reassign sales rep{' '}
                                                        </div>
                                                    }
                                                    position="right center"
                                                    contentStyle={{ width: 'fit-content', overflow: 'auto' }}
                                                    modal
                                                    nested
                                                >
                                                    {(close: () => void) => (
                                                        <AssignSalesRep
                                                            close={close}
                                                            handleSubmit={handleOnSubmit}
                                                            loading={assignLoading}
                                                            customer={customer}
                                                        />
                                                    )}
                                                </Popup>
                                            </>
                                        )}
                                    </div>
                                )}
                            </Popup>
                        </div>
                    ))
                )}
                {data?.data.length !== 0 && <Pagination query={query} setQuery={setQuery} data={data?.data} count={data?.count} />}
            </div>
        </div>
    );
};

export default Customer;
