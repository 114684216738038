export const setStatus = 'setStatus';
export const setCustomerStatus = 'setCustomerStatus';
export const setIsCustomer = 'setIsCustomer';
export const decrementItem = 'decrementItem';
export const incrementItem = 'incrementItem';
export const updateQty = 'updateQty';
export const removeItem = 'removeItem';

export const setActiveTab = 'setOrderActiveTab';

export const types = {
    setStatus,
    setIsCustomer,
    setCustomerStatus,
    decrementItem,
    incrementItem,
    removeItem,
    updateQty,
    setActiveTab,
};
