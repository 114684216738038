import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import PermissionsGate, { hasPermission } from '../../HOC/PermissionGate';
import Wrapper from '../../HOC/Wrapper';
import useGetRoles from '../../hooks/useGetRoles';
import { toggleActiveTab } from '../../redux/slices/tabs';
import { RootState } from '../../redux/store';
import { COMPANYPERMISSIONS, SCOPES, USERPERMISSIONS } from '../../utils/permission';
import Catalogue from './Catalogue';
import Compliance from './Compliance';
import Notifications from './Notifications';
import Profile from './Profile';
import Security from './Security';
import Subscription from './SubscriptionHistory';
import Team from './Team';
const { REACT_APP_BACKEND_API } = process.env;

export const managePermissions = (user_role: any, company_type: any, tab: any) => {
    const userpermissions = USERPERMISSIONS[user_role];
    const companypermissions = COMPANYPERMISSIONS[company_type];
    const userPermissionGranted = hasPermission({ permissions: userpermissions, scopes: tab });
    const companyPermissionGranted = hasPermission({ permissions: companypermissions, scopes: tab });
    return userPermissionGranted && companyPermissionGranted;
};
const Settings = () => {
    const { activeTab } = useSelector((state: RootState) => state.tabsReducer);
    const [toggle, setToggle] = useState(false);

    const dispatch = useDispatch();
    const toggleTab = useCallback(
        (tabName: 'profile' | 'security' | 'team' | 'subscriptions' | 'notifications' | 'catalogue' | 'compliance') => {
            dispatch(toggleActiveTab(tabName));
            setToggle(true);
        },
        [dispatch],
    );

    const { user_role, company_type } = useGetRoles();

    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('reference')) {
            dispatch(toggleActiveTab('subscriptions'));
            axios.post(`${REACT_APP_BACKEND_API}/v1/subscription/company/verify?reference=${searchParams.get('reference')}`, {}).then(() => {
                return;
            });
        } else if (!toggle) {
            if (managePermissions(user_role, company_type, [SCOPES.can_manage_company_profile])) {
                dispatch(toggleActiveTab('profile'));
                return;
            }
            if (managePermissions(user_role, company_type, [SCOPES.can_manage_security])) {
                dispatch(toggleActiveTab('security'));
                return;
            }
            if (managePermissions(user_role, company_type, [SCOPES.can_view_team_members, SCOPES.can_manage_team_members])) {
                dispatch(toggleActiveTab('team'));
                return;
            }
            if (managePermissions(user_role, company_type, [SCOPES.can_manage_company_subscriptions])) {
                dispatch(toggleActiveTab('subscriptions'));
                return;
            }
            if (managePermissions(user_role, company_type, [SCOPES.can_manage_notifications])) {
                dispatch(toggleActiveTab('notifications'));
                return;
            }
            if (managePermissions(user_role, company_type, [SCOPES.can_manage_catalogue])) {
                dispatch(toggleActiveTab('catalogue'));
                return;
            }
            if (managePermissions(user_role, company_type, [SCOPES.can_manage_compliance])) {
                dispatch(toggleActiveTab('compliance'));
                return;
            }
        }
    }, [user_role, company_type, dispatch, toggle, searchParams]);

    return (
        <Wrapper>
            <div className="flex items-center justify-between my-6 mb-[0.7rem]">
                <h3 className="font-semibold text-[1.25rem] text-jumbleng-gray">Settings</h3>
            </div>

            <div className="flex justify-between border-b pt-1 mb-[1.5rem]">
                <ul className="flex">
                    <PermissionsGate scopes={[SCOPES.can_manage_company_profile]}>
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => toggleTab('profile')}
                                className={`${
                                    activeTab === 'profile' && 'font-bold border-b border-b-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                            >
                                Profile
                            </a>
                        </li>
                    </PermissionsGate>
                    <PermissionsGate scopes={[SCOPES.can_manage_security]}>
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => toggleTab('security')}
                                className={`${
                                    activeTab === 'security' && 'font-bold border-b border-b-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                            >
                                Security{' '}
                            </a>
                        </li>
                    </PermissionsGate>
                    <PermissionsGate scopes={[SCOPES.can_manage_team_members, SCOPES.can_view_team_members]}>
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => toggleTab('team')}
                                className={`${
                                    activeTab === 'team' && 'font-bold border-b border-b-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                            >
                                Team
                            </a>
                        </li>
                    </PermissionsGate>
                    <PermissionsGate scopes={[SCOPES.can_manage_company_subscriptions]}>
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => toggleTab('subscriptions')}
                                className={`${
                                    activeTab === 'subscriptions' && 'font-bold border-b border-b-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                            >
                                Subscriptions{' '}
                            </a>
                        </li>
                    </PermissionsGate>
                    <PermissionsGate scopes={[SCOPES.can_manage_notifications]}>
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => toggleTab('notifications')}
                                className={`${
                                    activeTab === 'notifications' && 'font-bold border-b border-b-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                            >
                                Notifications{' '}
                            </a>
                        </li>
                    </PermissionsGate>
                    <PermissionsGate scopes={[SCOPES.can_manage_catalogue]}>
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => toggleTab('catalogue')}
                                className={`${
                                    activeTab === 'catalogue' && 'font-bold border-b border-b-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                            >
                                Catalogue{' '}
                            </a>
                        </li>
                    </PermissionsGate>
                    <PermissionsGate scopes={[SCOPES.can_manage_compliance]}>
                        <li className="flex items-center">
                            <a
                                href="#"
                                onClick={() => toggleTab('compliance')}
                                className={`${
                                    activeTab === 'compliance' && 'font-bold border-b border-b-jumbleng-primary'
                                } w-full px-4 py-[0.78125rem] h-full text-[0.78125]`}
                            >
                                Compliance{' '}
                            </a>
                        </li>
                    </PermissionsGate>
                </ul>
            </div>

            {activeTab === 'profile' ? (
                <Profile />
            ) : activeTab === 'security' ? (
                <Security />
            ) : activeTab === 'team' ? (
                <Team />
            ) : activeTab === 'subscriptions' ? (
                <Subscription />
            ) : activeTab === 'notifications' ? (
                <Notifications />
            ) : activeTab === 'catalogue' ? (
                <Catalogue />
            ) : (
                activeTab === 'compliance' && <Compliance />
            )}
        </Wrapper>
    );
};

export default Settings;
