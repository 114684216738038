import { BaseQueryFn, createApi, FetchArgs } from '@reduxjs/toolkit/query/react';

import { IOrders, OrderType } from '../features/interfaces';
import { baseQueryWithReauth, createRequestWithParams, CustomError } from './shared';

const createRequest = (url: any) => ({ url });
export const orderApi = createApi({
    reducerPath: 'orderApi',
    baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomError, Record<string, any>>,
    tagTypes: ['order'],
    endpoints: (builder) => ({
        createOrder: builder.mutation({
            query: (data) => {
                return {
                    url: `orders`,
                    method: 'post',
                    body: { order_items: data },
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'order', id }],
        }),
        cancelOrder: builder.mutation({
            query: ({ id, message }) => {
                return {
                    url: `orders/${id}/cancel`,
                    method: 'PATCH',
                    body: { cancel_reason: message },
                };
            },
            invalidatesTags: ['order'],
        }),
        acceptOrder: builder.mutation({
            query: (id) => {
                return {
                    url: `orders/${id}/accept`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'order', id }],
        }),
        fulfillOrder: builder.mutation({
            query: (id) => {
                return {
                    url: `orders/${id}/fulfill`,
                    method: 'PATCH',
                    body: {},
                };
            },
            invalidatesTags: (_result, _error, { id }) => [{ type: 'order', id }],
        }),
        getMyOrders: builder.query<IOrders, { page: number; limit: number; search_query: string; status: string }>({
            query: ({ page, limit, search_query, status }) => createRequestWithParams(`orders/purchases`, { page, limit, search_query, status }),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'order' as const, id })), 'order'] : ['order'],
        }),

        getCustomerOrders: builder.query<IOrders, { page: number; limit: number; search_query: string; status: string }>({
            query: ({ page, limit, search_query, status }) => createRequestWithParams(`orders/customers`, { page, limit, search_query, status }),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'order' as const, id })), 'order'] : ['order'],
        }),
        getAnOrder: builder.query<OrderType, string>({
            query: (id) => createRequest(`/orders/${id}`),
            providesTags: (_result, _error, id) => [{ type: 'order', id }],
            transformResponse: (results: { data: any }) => results.data,
        }),

        getAllOrderCount: builder.query<any, string>({
            query: (type) => createRequest(`/orders/${type}?page=1&limit=10&status=`),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'order' as const, id })), 'order'] : ['order'],
        }),
        getPendingOrderCount: builder.query<any, string>({
            query: (type) => createRequest(`orders/${type}?page=1&limit=10&status=pending`),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'order' as const, id })), 'order'] : ['order'],
        }),
        getFulfilledOrderCount: builder.query<any, string>({
            query: (type) => createRequest(`orders/${type}?page=1&limit=10&status=fulfilled`),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'order' as const, id })), 'order'] : ['order'],
        }),
        getUnfulfilledOrderCount: builder.query<any, string>({
            query: (type) => createRequest(`orders/${type}?page=1&limit=10&status=unfulfilled`),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'order' as const, id })), 'order'] : ['order'],
        }),
        getCancelledOrderCount: builder.query<any, string>({
            query: (type) => createRequest(`orders/${type}?page=1&limit=10&status=cancelled`),
            providesTags: (result, _error, _arg) =>
                result?.data ? [...result?.data.map(({ id }: any) => ({ type: 'order' as const, id })), 'order'] : ['order'],
        }),
    }),
});

export const {
    useGetAllOrderCountQuery,
    useGetCancelledOrderCountQuery,
    useGetPendingOrderCountQuery,
    useGetFulfilledOrderCountQuery,
    useGetUnfulfilledOrderCountQuery,
    useGetAnOrderQuery,
    useGetCustomerOrdersQuery,
    useGetMyOrdersQuery,
    useCancelOrderMutation,
    useAcceptOrderMutation,
    useCreateOrderMutation,
    useFulfillOrderMutation,
} = orderApi;
