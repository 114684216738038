import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import BackArrow from '../../assets/images/svg/arrow-left.svg';
import AccordionLayout from '../../components/AccordionLayout/AccordionLayout';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import PopUpModal from '../../components/PopModal/PopUpModal';
import Loading from '../../components/UI/Spinner/Loading';
import { ISubscriptionInterface } from '../../redux/features/interfaces';
import { useGetAllSubscriptionsQuery, useSubscribeMutation } from '../../redux/services/subscriptionApi';
import Subscribe from './SettingsPopup/Subscribe';
const { REACT_APP_FRONTEND_URL } = process.env;

const SubscribeToPlan = ({ toggleTab, currentPlan, isSubscribe }: any) => {
    const [plan, setPlan] = useState<'monthly' | 'yearly'>('monthly');
    const { data = [], isFetching } = useGetAllSubscriptionsQuery();
    const [subscribe, { data: subscribeResponse, isLoading, isSuccess, isError, error }] = useSubscribeMutation();
    const dispatch = useDispatch();
    const [activeIndex, setActiveIndex] = useState(0);

    const switchPlan = (plan: 'monthly' | 'yearly') => {
        setPlan(plan);
    };

    const handleSubscribe = async (id: string, close: () => void) => {
        await subscribe({ plan_id: id, plan_type: plan, redirect_url: `${REACT_APP_FRONTEND_URL}/settings` });
        close();
    };

    useEffect(() => {
        if (isSuccess) {
            if (subscribeResponse?.data.authorization_url) {
                location.href = subscribeResponse?.data.authorization_url;
            } else {
                toast.success(subscribeResponse?.message);
            }
        }
        if (isError && error && 'status' in error) {
            toast.error(error?.data?.message);
        }
    }, [dispatch, error, isError, isLoading, isSuccess, subscribeResponse]);

    const myplan = useMemo(() => {
        const otherPlan = data.filter((item: ISubscriptionInterface) => item.name !== currentPlan.plan.name || currentPlan.state === 'cancelled');
        const current = data.filter((item: ISubscriptionInterface) => item.name === currentPlan.plan.name && currentPlan.state !== 'cancelled');
        return [...current, ...otherPlan];
    }, [currentPlan, data]);

    if (isFetching)
        return (
            <div className="min-h-[450px] flex justify-center flex-col">
                <Loading />
            </div>
        );

    return (
        <>
            <LoadingModal />
            <button>
                <div className="mt-[2.146rem] flex items-center" onClick={() => toggleTab('history')}>
                    <img src={BackArrow} /> <span className="text-[1rem] ml-[4px] text-jumbleng-primary">Back</span>
                </div>
            </button>

            <div className="bg-white mt-[1.5rem] p-4 lg:px-80 py-4 pb-40 text-jumbleng-gray">
                <div className="h-full ">
                    <div className="bg-jumbleng-primary-light rounded-md md:rounded-xl p-1 text-center text-jumbleng-primary flex items-center justify-center w-[10rem] md:w-[20rem] mx-auto">
                        <p
                            className={`${plan === 'monthly' && 'bg-white'} w-full cursor-pointer px-2 py-1 rounded-md md:py-4 md:px-3 md:rounded-xl`}
                            onClick={() => {
                                switchPlan('monthly');
                                setActiveIndex(0);
                            }}
                        >
                            Monthly
                        </p>
                        <p
                            className={`${plan === 'yearly' && 'bg-white'} w-full cursor-pointer px-2 py-1 rounded-md md:py-4 md:px-3 md:rounded-xl`}
                            onClick={() => {
                                switchPlan('yearly');
                                setActiveIndex(0);
                            }}
                        >
                            Annually
                        </p>
                    </div>
                    {myplan.map((item: ISubscriptionInterface, index: number) => (
                        <div className="flex md:flex-row flex-col justify-between items-start mt-4" key={index}>
                            <AccordionLayout
                                title={`${item.name} Plan`}
                                index={index}
                                activeIndex={activeIndex}
                                setActiveIndex={setActiveIndex}
                                planType={currentPlan.plan_type === plan}
                                current={item.name === currentPlan.plan.name && currentPlan.status === 'active'}
                            >
                                <div className="flex flex-col gap-3 text-white">
                                    <p>
                                        <i className="fa fa-check mr-2"></i>Tips and tutorials on how to use or navigate features.{' '}
                                    </p>
                                    <p>
                                        <i className="fa fa-check mr-2"></i>Tips and tutorials on how to use or navigate features.
                                    </p>
                                    <div className="mt-4 flex justify-between">
                                        <p>
                                            <span className="text-[20px]">
                                                ₦
                                                <span className="text-[40px] font-semibold">
                                                    {plan === 'monthly' ? item.price_per_month : item.price_per_year}
                                                </span>
                                                /user
                                            </span>
                                        </p>

                                        {!(
                                            item.name === currentPlan.plan.name &&
                                            currentPlan.status === 'active' &&
                                            currentPlan.plan_type === plan
                                        ) && (
                                            <PopUpModal
                                                content={
                                                    <div className="md:flex rounded-xl cursor-pointer  items-center justify-center bg-white text-[#2F2E54]  px-[1.18rem] py-[0.7rem] w-[10rem]">
                                                        <div className="text-[1rem] font-semibold ">{isSubscribe ? 'Subscribe' : 'Upgrade'} Plan</div>
                                                    </div>
                                                }
                                                className=""
                                                popupcontent={false}
                                                onClose={close}
                                            >
                                                {(close: () => void) => (
                                                    <Subscribe
                                                        close={close}
                                                        data={{ id: item.id, plan, name: item.name, action: handleSubscribe }}
                                                        isSubscribe={isSubscribe}
                                                        isLoading={isLoading}
                                                    />
                                                )}
                                            </PopUpModal>
                                        )}
                                    </div>
                                </div>
                            </AccordionLayout>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default SubscribeToPlan;
