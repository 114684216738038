import { Dispatch } from 'redux';

import { types } from './InvoiceActionTypes';

export const listMyInvoices = (currentStatus: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: types.setIStatus,
        payload: currentStatus,
    });
};

export const listCustomerInvoices = (currentStatus: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: types.setCustomerIStatus,
        payload: currentStatus,
    });
};
